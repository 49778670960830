define("esis-ui/components/areas/admin/contract-type/nz/view/nz-collateral-profile-page-base", ["exports", "esis-ui/components/areas/admin/admin-page-base", "lodash", "ember-concurrency"], function (_exports, _adminPageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adminPageBase.default.extend({
    saveCollateralProfile: (0, _emberConcurrency.task)(function* () {
      try {
        let valid = this.get('model').validate();
        if (!(0, _lodash.isEmpty)(this.get('model.defaults'))) {
          (0, _lodash.forEach)(this.get('model.defaults'), item => {
            valid = valid && item.validate();
          });
        }
        if (valid) {
          let result = yield this.get('adminRepo.saveNzCollateralProfile').perform(this.get('model'));
          if (!(0, _lodash.isEmpty)(this.get('onSave'))) {
            this.get('onSave')(result);
          }
          this.get('_dialog').hide();
        }
      } catch (e) {
        const constants = this.get('constants');
        if (e.errorSeverity === constants.backendErrorSeverity.error || e.errorSeverity === constants.backendErrorSeverity.warning) {
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', e.errorMessage);
        } else {
          this.get('logger').logError('nz-edit-collateral-profile-page-base.saveCollateralProfile', e);
        }
      }
    })
  });
  _exports.default = _default;
});