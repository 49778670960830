define("esis-ui/components/areas/report/au/view/grantor-org-noname-by-entity/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION
    _businessEntitiesList: null,
    _selectedEntity: null,
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
      this.setProperties({
        _businessEntitiesList: null,
        _selectedEntity: null
      });
    },
    //endregion

    //region TASKS
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let entityPicklist = yield this.get('adminRepo.loadBusinessEntitiesFlat').perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('_businessEntitiesList', entityPicklist);
      } catch (error) {
        this.get('logger').logError('grantor-org-noname-by-entity._loadPicklist', error);
      }
    }),
    _downloadReport: (0, _emberConcurrency.task)(function* () {
      const self = this;
      try {
        yield self.get('reportRepo.downloadGrantorOrgNoNameByEntityExtract').linked().perform(this.get('_selectedEntity.guid'));
        if (Ember.isPresent(self.get('hideWaitingModal'))) {
          self.get('hideWaitingModal')();
        }
        self.notifySuccess('Organisation grantor without name extract report has been downloaded');
      } catch (error) {
        this.notifyError(`Failed to download file - ${error.payload.message}`);
        if (Ember.isPresent(self.get('hideWaitingModal'))) {
          self.get('hideWaitingModal')();
        }
      }
    }).drop(),
    //endregion

    //region ACTIONS
    actions: {
      _downloadReport: function _downloadReport() {
        if ((0, _lodash.isEmpty)(this.get('_selectedEntity'))) {
          this.set('entityErrorMsg', 'Please choose an option');
        } else {
          this.get('_downloadReport').perform();
        }
      },
      _entityChanged: function _entityChanged(option) {
        this.set('_selectedEntity', option);
        if (!(0, _lodash.isEmpty)(option)) this.set('entityErrorMsg', '');
      }
    }
  });
  _exports.default = _default;
});