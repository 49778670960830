define("esis-ui/components/areas/spg/au/view/au-sp-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // areas/spg/au/view/au-sp-modal/component.js
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null,
        '_validationErrorMessage': null,
        '_contactPanel': `contactPanel${this.get('app').guid()}`,
        '_mailingPanel': `mailingPanel${this.get('app').guid()}`
      });
    },
    //endregion

    //region COMPUTED PROPERTIES
    _partyValidation: Ember.computed('model.{contactName,contactPhone,emailAddress}', function () {
      const party = this.get('model');
      if (!(0, _lodash.isEmpty)(party)) {
        if (!party.validate()) {
          return "required";
        }
      }
      return "";
    }),
    _mailingValidation: Ember.computed('model.mailingAddress.{line1,postcode}', function () {
      const party = this.get('model');
      if (!(0, _lodash.isEmpty)(party)) {
        if (!(0, _lodash.isEmpty)(this.get('model.mailingAddress'))) {
          if (!this.get('model.mailingAddress').validate()) {
            return "required";
          }
        }
      }
      return "";
    }),
    _addressComponent: Ember.observer('_mailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_mailingAddress'))) {
        this.get('_mailingAddress').loadPicklistData();
      }
      return null;
    }),
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _validationErrorMessage: null
      });
    },
    show(model) {
      this._reset();
      this.mapObject(model, this.set('model', this.createModel('secured-party')));
      if (!(0, _lodash.isEmpty)(model.mailingAddress)) {
        let addressModel = this.mapObject(model.mailingAddress, this.createModel('address'));
        this.set('model.mailingAddress', addressModel);
      } else {
        this.set('model.mailingAddress', this.createModel('address'));
      }
      this.get('model.mailingAddress').save();
      this.get('model').save();
      this.get('_dialog').show();
    },
    //endregion

    //region ACTIONS
    actions: {
      _onConfirm() {
        let valid = this.get('model').validate() && this.get('model.mailingAddress').validate();
        if (valid && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('model'));
        } else {
          this.set('_validationErrorMessage', "Please fill all the required fields");
        }
        return valid;
      }
    }
    //endregion
  });
  _exports.default = _default;
});