define("esis-ui/repositories/report-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    downloadRegistrationByEntityExtractReport: (0, _emberConcurrency.task)(function* (args) {
      return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadRegistrationExtractReport'), args, true);
    }),
    downloadRegistrationBySpgExtractReport: (0, _emberConcurrency.task)(function* (args) {
      return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadRegistrationBySpgExtractReport'), args, true);
    }),
    downloadGrantorOrgNoNameByEntityExtract: (0, _emberConcurrency.task)(function* (EntityGuid) {
      // expect id as EntityGuid
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadGrantorWithNoOrgNameExtract'), EntityGuid), true);
    }),
    downloadSearchGrantorDiffExtractReport: (0, _emberConcurrency.task)(function* (sourceId, compareId) {
      // expect search header esis id for both source and compare
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchGrantorDiffExtractReport'), sourceId, compareId), true);
    }),
    downloadAccountEntityOverrideByEntityExtract: (0, _emberConcurrency.task)(function* (EntityGuid) {
      // expect id as EntityGuid
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadAccountEntityOverrideExtract'), EntityGuid), true);
    }),
    downloadAuRegistrationCountExtractReport: (0, _emberConcurrency.task)(function* (args) {
      return yield this.downloadFile(this.get('constants.apiEndpoint.downloadAuRegistrationCountExtractReport'), args, true);
    })
  });
  _exports.default = _default;
});