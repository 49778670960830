define("esis-ui/components/shared/command-bar-top/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: 'span',
    tabModel: null,
    hasButton: null,
    commandBarClass: Ember.computed('hasButton', function () {
      if (Ember.isPresent(this.get('hasButton')) && this.get('hasButton')) {
        return Ember.String.htmlSafe('commandBorder');
      }
      return Ember.String.htmlSafe('');
    })
  });
  _exports.default = _default;
});