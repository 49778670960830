define("esis-ui/components/areas/registration/au/view/au-collateral-edit-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Edit Collateral',
    onConfirm: function onConfirm() {},
    init() {
      this._super(...arguments);
    },
    _showVehicleRegistrationNumber: Ember.computed('_collateral.serialNumberType', function () {
      return this.get('_collateral.serialNumberType') === this.get('constants.serialNumberType.chassisNumber') || this.get('_collateral.serialNumberType') === this.get('constants.serialNumberType.VIN') || this.get('_collateral.serialNumberType') === this.get('constants.serialNumberType.motorVehicleManufacturersNumber');
    }),
    _reset() {
      this.setProperties({
        _collateral: null
      });
    },
    show(collateral) {
      this._reset();
      const colModel = this.mapObject(collateral, this.createModel('collateral'));
      this.set('_collateral', colModel);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm: function _onConfirm() {
        const collateral = this.get('_collateral');
        if (collateral.get('areProceedsClaimed') !== true && !Ember.isBlank(collateral.get('proceedsClaimedDescription'))) {
          collateral.set('proceedsClaimedDescription', null);
        } else if (collateral.get('areProceedsClaimed') === true && Ember.isBlank(collateral.get('proceedsClaimedDescription'))) {
          collateral.set('proceedsClaimedDescription', this.get('constants.defaultAreProceedClaimedDescription'));
        }
        this.get('onConfirm')(collateral);
      },
      _onAreProceedsClaimedChange(value) {
        const collateral = this.get('_collateral');
        collateral.set('areProceedsClaimed', value);
        if (value === true && Ember.isBlank(collateral.get('proceedsClaimedDescription'))) {
          collateral.set('proceedsClaimedDescription', this.get('constants.defaultAreProceedClaimedDescription'));
        }
      }
    }
  });
  _exports.default = _default;
});