define("esis-ui/components/areas/shared/audit-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mfXvgfSg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"listGridStyle\"],[8],[0,\"\\n  \"],[1,[28,\"shared/kendo/grid/grid-local-ds\",null,[[\"options\",\"registerAs\",\"autoSelectFirstRow\",\"autoFocus\"],[[24,[\"_auditGridOption\"]],[24,[\"_auditGrid\"]],false,false]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/audit-display/template.hbs"
    }
  });
  _exports.default = _default;
});