define("esis-ui/helpers/merged-hash", ["exports", "ember-froala-editor/helpers/merged-hash"], function (_exports, _mergedHash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _mergedHash.default;
    }
  });
  Object.defineProperty(_exports, "mergedHash", {
    enumerable: true,
    get: function get() {
      return _mergedHash.mergedHash;
    }
  });
});