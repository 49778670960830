define("esis-ui/services/app-service", ["exports", "ember-cli-uuid", "ember-ajax/request", "moment", "lodash", "ember-lifeline"], function (_exports, _emberCliUuid, _request, _moment, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    //region property injection
    constants: Ember.inject.service('app-constants'),
    logger: Ember.inject.service('logging-service'),
    credential: Ember.inject.service('credential-service'),
    event: Ember.inject.service('event-service'),
    //endregion

    //region initialise
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this._defaultSystemSettings = Ember.Object.create();
      this._defaultSystemSettings.set(constants.systemSetting.timeDiscrepancy, 0);
      this._defaultSystemSettings.set(constants.systemSetting.connectionStatusPollingInterval, 10);
      this._screens = {};
    },
    //endregion

    //region system settings
    getSetting(name) {
      const currentUserProfile = this.get('credential.currentUser');
      if (Ember.isPresent(currentUserProfile) && Ember.isPresent(currentUserProfile[name])) {
        return currentUserProfile[name];
      }
      if (Ember.isPresent(this.get(`_defaultSystemSettings.${name}`))) {
        return this.get(`_defaultSystemSettings.${name}`);
      }
      return null;
    },
    updateTimeDiscrepancy(serverTimeUtc) {
      const constants = this.get('constants');
      const timeDiff = Math.floor(_moment.default.duration(_moment.default.utc(_moment.default.utc()).diff(serverTimeUtc)).asSeconds());
      if (timeDiff !== this.get(`_defaultSystemSettings.${constants.systemSetting.timeDiscrepancy}`)) {
        //this.get('logger').logDebug('Update client => server time discrepancy', timeDiff);
        this.set(`_defaultSystemSettings.${constants.systemSetting.timeDiscrepancy}`, timeDiff);
      }
    },
    currentLocalTime() {
      const timeDiff = this.getSetting(this.get('constants').systemSetting.timeDiscrepancy);
      let now = (0, _moment.default)();
      if (timeDiff !== 0) {
        return now.add(-timeDiff, 's').toDate();
      }
      return now.toDate();
    },
    currentUtcTime() {
      const timeDiff = this.getSetting(this.get('constants').systemSetting.timeDiscrepancy);
      let now = _moment.default.utc();
      if (timeDiff !== 0) {
        return now.add(-timeDiff, 's').toDate();
      }
      return now.toDate();
    },
    //OLD ESIS
    getCurrentSystemTime: function getCurrentSystemTime() {
      if (Ember.isPresent(this.get('currentSystemTime'))) {
        let diff = Math.floor(_moment.default.duration((0, _moment.default)((0, _moment.default)()).diff(this.get('lastSystemTimeRetrival'))).asSeconds());
        if (diff > 1200) {
          this.get('logger').logDebug('return client time as server time is too old');
          return this.getCurrentLocalTime();
        }
        this.get('logger').logDebug('return cached server time', this.get('currentSystemTime').format('DD/MM/YYYY HH:mm'));
        return (0, _moment.default)(this.get('currentSystemTime'));
      } else {
        this.get('logger').logDebug('return client time as server time is not available / set');
        return this.getCurrentLocalTime(); // this is temporary arrangement until figure out how to get moment timezone work
      }
    },

    getCurrentLocalTime: function getCurrentLocalTime() {
      /*
      return local computer time in australian timezone time.
       */
      const now = _moment.default.utc();
      return (0, _moment.default)(`${now.format('YYYY-MM-DD')}T${now.format('HH:mm:ss')}+10:00`).add(10, 'h');
    },
    setCurrentSystemTime: function setCurrentSystemTime() {
      // this.getAjaxCors('servertime').done(function (result) {
      this.getAjaxAnonymous('servertime').then(response => {
        // if (response.isSuccess) {
        //     let dateStr = response.data;
        let dateStr = response;
        if (dateStr.indexOf('Z') > 0) {
          dateStr = dateStr.substring(0, dateStr.length - 1);
        }
        if (dateStr.indexOf('+') > 0) {
          dateStr = dateStr.substring(0, dateStr.indexOf('+'));
        }
        this.set('currentSystemTime', (0, _moment.default)(dateStr));
        this.set('lastSystemTimeRetrival', (0, _moment.default)());
        this.get('logger').logDebug('set server time', this.get('currentSystemTime').format('DD/MM/YYYY HH:mm'));
        // }
      });
    },

    //
    //endregion

    //region GUID helper
    guidEmpty: Ember.computed.alias('constants.emptyGuid'),
    guid: function guid() {
      return (0, _emberCliUuid.uuid)();
    },
    //endregion

    //region anonymous server communication, system message
    getApiUrl: function getApiUrl(endpoint) {
      const constants = this.get('constants');
      return `${constants.get('apiBaseUrl')}/${constants.get('apiRoutePath')}/${endpoint}`;
    },
    getOdataApiUrl: function getOdataApiUrl(endpoint) {
      const constants = this.get('constants');
      return `${constants.get('apiBaseUrl')}/${constants.get('apiOdataRoutePath')}/${endpoint}`;
    },
    getAjaxAnonymous: function getAjaxAnonymous(url) {
      try {
        return new Ember.RSVP.Promise((resolve, reject) => {
          (0, _request.default)(this.getApiUrl(url), this.anonymousRequestOptions('GET')).then(function (response) {
            if (response.isSuccess) {
              resolve(response.data);
            } else {
              console.error('Server communication error', response.error);
              reject(response.errors);
            }
          }, function (error) {
            console.error('Server communication error', error.errors || error);
            reject(error.errors || error);
          });
        });
      } catch (e) {
        console.error('App Service - Cannot post error log back to server', e);
      }
    },
    postAjaxAnonymous: function postAjaxAnonymous(url, data) {
      try {
        return new Ember.RSVP.Promise((resolve, reject) => {
          (0, _request.default)(this.getApiUrl(url), this.anonymousRequestOptions('POST', {
            data: data
          })).then(function (response) {
            if (response.isSuccess) {
              resolve(response.data);
            } else {
              console.error('Server communication error', response.error);
              reject(response.errors);
            }
          }, function (error) {
            console.error('Server communication error', error.errors || error);
            reject(error.errors || error);
          });
        });
      } catch (e) {
        console.error('App Service - Cannot post error log back to server', e);
      }
    },
    anonymousRequestOptions: function anonymousRequestOptions(method, args) {
      let hash = {};
      /*
      use withCredentials option to true to allow server response to set cookie value
      https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
       */
      hash.crossDomain = true;
      hash.xhrFields = {
        withCredentials: true
      };
      hash.method = method || "GET";
      if (method === "POST" && args) {
        hash.contentType = "application/json";
        hash.data = JSON.stringify(args);
      }
      let {
        beforeSend
      } = hash;
      hash.beforeSend = xhr => {
        if (beforeSend) {
          beforeSend(xhr);
        }
      };
      return hash;
    },
    //endregion

    //region Screen Stack
    tabContainerHeight: 600,
    tabContainerWidth: 800,
    currentDynamicTabId: null,
    addScreen(newTab) {
      this.get('_screens')[newTab.get('tabId')] = [{
        name: newTab.get('name').length > 0 ? newTab.get('name') : newTab.get('title'),
        type: this.get('constants.screenElementType.dynamicTab'),
        uuid: newTab.get('tabId'),
        p0: newTab.get('p0'),
        p1: newTab.get('p1'),
        options: newTab.get('options'),
        hasHelpTopic: true
      }];
      // this.get('logger').logDebug('addScreen', newTab, this.get('_screens')[newTab.get('tabId')]);
    },

    switchScreen(tabId) {
      this.set('currentDynamicTabId', tabId);
      (0, _emberLifeline.runTask)(this, () => {
        this.get('event').trigger(this.get('constants.events.screenElementChanged'));
      }, 20);
      // this.get('logger').logDebug('switchScreen', tabId);
    },

    removeScreen(tabId) {
      delete this.get('_screens')[tabId];
      if (this.get('currentDynamicTabId') === tabId) {
        this.set('currentDynamicTabId', null);
      }
      // this.get('logger').logDebug('removeScreen', tabId, this.get('_screens'));
    },

    pushScreenElement(element) {
      let stack = this.get('_screens')[this.get('currentDynamicTabId')];
      if ((0, _lodash.isEmpty)(stack)) {
        stack = [];
      }
      stack.push({
        name: element.name,
        type: element.type,
        uuid: element.uuid,
        hasHelpTopic: element.hasHelpTopic
      });
      (0, _emberLifeline.runTask)(this, () => {
        this.get('event').trigger(this.get('constants.events.screenElementChanged'));
      }, 20);
      //this.get('logger').logDebug('app-service.pushScreenElement', element, this.get('_screens')[this.get('currentDynamicTabId')]);
    },

    pushStaticTabElement(element) {
      let stack = this.get('_screens')[element.screenId];
      if ((0, _lodash.isEmpty)(stack)) {
        stack = [];
      }
      stack.push({
        name: element.name,
        type: element.type,
        uuid: element.uuid,
        hasHelpTopic: element.hasHelpTopic
      });
      (0, _emberLifeline.runTask)(this, () => {
        this.get('event').trigger(this.get('constants.events.screenElementChanged'));
      }, 20);
      //this.get('logger').logDebug('app-service.pushStaticTabElement', element, stack, this.get('_screens'));
    },

    popStaticTabElement(screenId, uuid) {
      const stack = this.get('_screens')[screenId];
      if (!(0, _lodash.isEmpty)(stack)) {
        (0, _lodash.remove)(stack, n => {
          return n.uuid === uuid;
        });
        //this.get('logger').logDebug('app-service.popStaticTabElement', screenId, uuid, stack, this.get('_screens'));
      }
    },

    popScreenElement(type) {
      const stack = this.get('_screens')[this.get('currentDynamicTabId')];
      if (!(0, _lodash.isEmpty)(stack)) {
        const lastElement = (0, _lodash.last)(stack);
        if (lastElement.type === type) {
          const result = stack.pop();
          //this.get('logger').logDebug('app-service.popScreenElement', result);
          return result;
        }
      }
      return null;
    },
    getScreenStack(typeFilter) {
      let stack = this.get('_screens')[this.get('currentDynamicTabId')];
      if (!(0, _lodash.isEmpty)(stack)) {
        if (Ember.isPresent(typeFilter)) {
          return (0, _lodash.filter)(stack, {
            type: typeFilter
          });
        }
        return stack;
      }
      return [];
    },
    resetScreenStack() {
      this.set('_screens', {});
    },
    /*
    get current active dynamic tab
     */
    getRootScreen() {
      let stack = this.get('_screens')[this.get('currentDynamicTabId')];
      if (!(0, _lodash.isEmpty)(stack)) {
        const dynamicTab = (0, _lodash.filter)(stack, {
          type: this.get('constants.screenElementType.dynamicTab')
        });
        if (dynamicTab.length > 0) {
          return (0, _lodash.head)(dynamicTab);
        }
      }
      return null;
    },
    /*
    check by uuid, if a screen panel is part of the current stack
     */
    screenStackContains(screenId, uuid) {
      if ((0, _lodash.isNil)(screenId)) {
        return false;
      }
      if (screenId !== this.get('currentDynamicTabId')) {
        return false;
      }
      let stack = this.get('_screens')[screenId];
      if (!(0, _lodash.isEmpty)(stack)) {
        const tab = (0, _lodash.filter)(stack, {
          uuid: uuid
        });
        return tab.length > 0;
      }
      //console.log('app-service current stack not found return false', uuid);
      return false;
    },
    /*
    given the UUID of a tab, find the dynamnic tab screen (root screen) tabId
     */
    getRootScreenTabIdByUuid(uuid) {
      const screens = this.get('_screens');
      let result = null;
      (0, _lodash.forIn)(screens, (stack, key) => {
        if (!(0, _lodash.isEmpty)(stack)) {
          if ((0, _lodash.filter)(stack, {
            uuid: uuid
          }).length > 0) {
            result = key;
            return false; // breaking forIn loop
          }
        }
      });

      return result;
    },
    /*
    We need this to disable home page business entity picklist if there is any open tab
    */
    getHasActiveScreen() {
      return Object.keys(this.get('_screens')).length > 1;
    }
    //endregion
  });
  _exports.default = _default;
});