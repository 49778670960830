define("esis-ui/components/shared/power-datepicker/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "moment"], function (_exports, _sharedComponentBase, _lodash, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION

    value: null,
    maxYearOffset: 2,
    minYearOffset: 100,
    // literalDate: false,
    dateAsUtc: false,
    disableDatePicker: false,
    verticalPosition: "below",
    isDoB: false,
    placeholder: 'e.g. 01/01/1990',
    hasClearBtn: false,
    onDateChanged: function onDateChanged() {},
    init() {
      this._super(...arguments);
      this.months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      let dateNow = new Date();
      this.maxYear = dateNow.getFullYear() + this.maxYearOffset;
      this.groupedYears = Array(...Array(this.minYearOffset)).map((_, i) => `${this.maxYear - i}`);
      this._center = this.get('isDoB') ? new Date(1990, 0, 1, 0, 0, 0, 0) : new Date();
    },
    //endregion

    //region HOOKS
    didInsertElement() {
      this._super(...arguments);
      if (!(0, _lodash.isNil)(this.get('value'))) {
        this.setDateOnlyInCalendar(this.get('value') /*, true*/);
      }
    },

    //endregion

    //region METHODS
    _selectDateInCalender(date /*, init = false*/) {
      this.set('_selected', date);
      this._changeDate(date /*, this.get('literalDate'), init*/);
      this.set('_center', date);
    },
    setDateOnlyInCalendar(date) {
      let formattedDate = date;
      if (!(0, _lodash.isNil)(date)) {
        formattedDate = new Date(date);
      }
      this.set('_selected', formattedDate);
      this.set('_center', formattedDate);
    },
    clear() {
      this.set('_selected', null);
      this.set('_center', this.get('isDoB') ? new Date(1990, 0, 1, 0, 0, 0, 0) : new Date());
    },
    _changeDate(date /*, flag, init = false*/) {
      this.set('_validationError', '');
      if (this.get('dateAsUtc')) {
        // this.get('onDateChanged')(this.dateAsIsoStrUtc(date, flag), init);
        this.get('onDateChanged')(date);
      } else {
        // this.get('onDateChanged')(this.dateAsIsoStr(date, flag), init);
        this.get('onDateChanged')(date);
      }
    },
    //endregion

    //region ACTIONS
    actions: {
      _onSelectChange: function _onSelectChange(date) {
        this._selectDateInCalender(new Date(date));
      },
      _moveCenter(calendar, val, unit) {
        let newCenter = null;
        newCenter = (0, _moment.default)(calendar.center).add(val, unit);
        if (val > 0 && newCenter.year() <= this.get('maxYear')) {
          calendar.actions.moveCenter(val, unit);
        } else if (val < 0 && newCenter.year() > this.get('maxYear') - this.get('minYearOffset')) {
          calendar.actions.moveCenter(val, unit);
        }
      },
      _changeCenter(unit, calendar, val) {
        let newCenter = null;
        if (unit === 'year') {
          newCenter = (0, _moment.default)(calendar.center).year(val);
        } else if (unit === 'month') {
          newCenter = (0, _moment.default)(calendar.center).month(val);
        }
        let yearVal = newCenter.year();
        if (yearVal > this.get('maxYear') - this.get('minYearOffset') && yearVal <= this.get('maxYear')) {
          calendar.actions.changeCenter(newCenter);
        }
      },
      _setValue: function _setValue(calendar, event) {
        let value = event.target.value;
        if (!(0, _lodash.isEmpty)(value)) {
          let validFormat = false;
          let regDateFormat = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
          validFormat = regDateFormat.test(value);
          if (validFormat) {
            this.set('_validationError', '');
            let dateArry = value.split('/');
            let dateObj = new Date(+dateArry[2], +dateArry[1] - 1, +dateArry[0]);
            if ((0, _moment.default)(dateObj).isValid()) {
              this._selectDateInCalender(dateObj);
            } else {
              this.get('onDateChanged')(null);
            }
          } else {
            this.get('onDateChanged')(null);
            this.clear();
            this.set('_validationError', 'Invalid date format (e.g. 01/01/1990)');
          }
        } else {
          this.set('_validationError', '');
          this.get('onDateChanged')(null);
        }
      },
      _clear() {
        this.clear();
        this._changeDate(null);
      }
    }
    //endregion
  });
  _exports.default = _default;
});