define("esis-ui/components/areas/spg/nz/wizard/nz-spg-email-notification-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash"], function (_exports, _wizardStepBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region INITIALIZATION
    validateStep: function validateStep() {
      return true;
    },
    //endregion

    //region COMPUTED PROPERTIES
    _emailList: Ember.computed('model.securedParties.{@each,@each.email,@each.personActingOnBehalf}', function () {
      let emailList = [];
      (0, _lodash.forEach)(this.get('model.securedParties'), function (item) {
        emailList.pushObject(item.email);
        if (item.isOrganisation) {
          emailList.pushObject(item.personActingOnBehalf.email);
        }
      });
      return (0, _lodash.uniq)(emailList);
    }),
    //endregion

    //region ACTIONS
    actions: {}
    //endregion
  });
  _exports.default = _default;
});