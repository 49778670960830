define("esis-ui/components/froala-content", ["exports", "ember-froala-editor/components/froala-content"], function (_exports, _froalaContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _froalaContent.default;
    }
  });
});