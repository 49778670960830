define("esis-ui/components/areas/spg/nz/view/nz-wizard-sp-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null,
        '_indexId': null,
        '_detailHasMailingAddress': null,
        '_personOnBehalfHasMailingAddress': null,
        '_organisationSearchText': null
      });
    },
    //endregion

    //region COMPUTED PROPERTIES
    _manageDetailMailingAddressButtonTitle: Ember.computed('_detailHasMailingAddress', function () {
      return this.get('_detailHasMailingAddress') ? "Remove Mailing Address" : "Add Mailing Address";
    }),
    _managePersonOnBehalfMailingAddressButtonTitle: Ember.computed('_personOnBehalfHasMailingAddress', function () {
      return this.get('_personOnBehalfHasMailingAddress') ? "Remove Mailing Address" : "Add Mailing Address";
    }),
    _mailingAddressComponent: Ember.observer('_mailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_mailingAddress'))) {
        this.get('_mailingAddress').loadPicklistData();
      }
      return null;
    }),
    _contactAddressComponent: Ember.observer('_contactAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_contactAddress'))) {
        this.get('_contactAddress').loadPicklistData();
      }
      return null;
    }),
    _personActingOnBehalfMailingAddressComponent: Ember.observer('_personActingOnBehalfMailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_personActingOnBehalfMailingAddress'))) {
        this.get('_personActingOnBehalfMailingAddress').loadPicklistData();
      }
      return null;
    }),
    _personActingOnBehalfContactAddressComponent: Ember.observer('_personActingOnBehalfContactAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_personActingOnBehalfContactAddress'))) {
        this.get('_personActingOnBehalfContactAddress').loadPicklistData();
      }
      return null;
    }),
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _indexId: null,
        _detailHasMailingAddress: null,
        _personOnBehalfHasMailingAddress: null,
        _organisationSearchText: null
      });
    },
    _loadModel(model, isNew) {
      if (isNew) {
        this.set('model', this.mapJsonObjectToModel(model, 'secured-party-nz'));
        this.set('model.contactAddress', this.createModel('address-nz'));
        if (this.get('model.isOrganisation')) {
          this.set('model.personActingOnBehalf', this.createModel('secured-party-person-on-behalf-nz'));
          this.set('model.personActingOnBehalf.contactAddress', this.createModel('address-nz'));
        }
      } else {
        this.set('model', this.mapObject(model, this.createModel('secured-party-nz')));
        this.set('model.contactAddress', this.mapObject(model.contactAddress, this.createModel('address-nz')));
        if (model.hasMailingAddress) {
          this.set('_detailHasMailingAddress', true);
          this.set('model.mailingAddress', this.mapObject(model.mailingAddress, this.createModel('address-nz')));
        }
        if (model.isOrganisation) {
          let personOnBehalfModel = this.createModel('secured-party-person-on-behalf-nz');
          this.set('model.personActingOnBehalf', this.mapObject(model.personActingOnBehalf, personOnBehalfModel));
          if (model.personActingOnBehalf.hasMailingAddress) {
            this.set('_personOnBehalfHasMailingAddress', true);
            let address = model.personActingOnBehalf.mailingAddress;
            this.set('model.personActingOnBehalf.mailingAddress', this.mapObject(address, this.createModel('address-nz')));
          }
        }
        this.set('_indexId', model.indexId);
      }
    },
    show(model, isNew) {
      this._reset();
      this._loadModel(model, isNew);
      this.get('model').saveModel();
      this.get('_dialog').show();
    },
    _loadOrgModelToRequestModel(response) {
      let personActingOnBehalf = this.get('model.personActingOnBehalf');
      this.set('model', this.mapJsonObjectToModel(response, 'secured-party-nz'));
      this.set('model.securedPartyType', this.get('constants.nzSecuredPartyType.organisation'));
      if (!(0, _lodash.isEmpty)(response.mailingAddress)) {
        this.set('model.mailingAddress', this.mapJsonObjectToModel(response.mailingAddress, 'address-nz'));
        if (this.get('_detailHasMailingAddress')) {
          let selectedCountry = this.get('_mailingAddress').setSelectedCountry(response.mailingAddress.countryCode);
          if (!(0, _lodash.isEmpty)(selectedCountry)) {
            this.set('model.mailingAddress.countryDescription', selectedCountry.title);
          }
        } else {
          this.set('_detailHasMailingAddress', true);
        }
      } else if (this.get('_detailHasMailingAddress')) {
        this.set('model.mailingAddress', this.createModel('address-nz'));
      }
      if (!(0, _lodash.isEmpty)(response.contactAddress)) {
        this.set('model.contactAddress', this.mapJsonObjectToModel(response.contactAddress, 'address-nz'));
        let selectedCountry = this.get('_contactAddress').setSelectedCountry(response.contactAddress.countryCode);
        if (!(0, _lodash.isEmpty)(selectedCountry)) {
          this.set('model.contactAddress.countryDescription', selectedCountry.title);
        }
      } else {
        this.set('model.contactAddress', this.createModel('address-nz'));
        let selectedCountry = this.get('_contactAddress').setSelectedCountry('NZ');
        this.set('model.contactAddress.countryDescription', selectedCountry.title);
        this.set('model.contactAddress.countryCode', 'NZ');
      }
      this.set('model.personActingOnBehalf', personActingOnBehalf);
    },
    //endregion

    //region TASKS
    _validateEmail: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('validationRepo.validateEmail').perform({
          emailAddress: this.get('model.email')
        });
        if (response.isValid) {
          this.set('model.errors.email', '');
        } else {
          this.set('model.errors.email', response.validationErrorMessage);
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validateEmail', error);
      }
    }),
    _validatePhone: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.phoneNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.phoneNumber')
          });
          if (response.isValid) {
            this.set('_phoneNumberError', '');
          } else {
            this.set('_phoneNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_phoneNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePhone', error);
      }
    }),
    _validateFax: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.faxNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.faxNumber')
          });
          if (response.isValid) {
            this.set('_faxNumberError', '');
          } else {
            this.set('_faxNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_faxNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validateFax', error);
      }
    }),
    _validatePAOBEmail: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('validationRepo.validateEmail').perform({
          emailAddress: this.get('model.personActingOnBehalf.email')
        });
        if (response.isValid) {
          this.set('model.personActingOnBehalf.errors.email', '');
        } else {
          this.set('model.personActingOnBehalf.errors.email', response.validationErrorMessage);
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePAOBEmail', error);
      }
    }),
    _validatePAOBPhone: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.personActingOnBehalf.phoneNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.personActingOnBehalf.phoneNumber')
          });
          if (response.isValid) {
            this.set('_paobPhoneNumberError', '');
          } else {
            this.set('_paobPhoneNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_paobPhoneNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePAOBPhone', error);
      }
    }),
    _validatePAOBFax: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.personActingOnBehalf.faxNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.personActingOnBehalf.faxNumber')
          });
          if (response.isValid) {
            this.set('_paobFaxNumberError', '');
          } else {
            this.set('_paobFaxNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_paobFaxNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePAOBFax', error);
      }
    }),
    _save: (0, _emberConcurrency.task)(function* () {
      let modelValid = this.get('model').validate();
      modelValid = this.get('model.contactAddress').validate() && modelValid;
      if (this.get('_detailHasMailingAddress')) {
        modelValid = this.get('model.mailingAddress').validate() && modelValid;
      }
      let personActingOnBehalfValid = true;
      if (this.get('model.isOrganisation')) {
        personActingOnBehalfValid = this.get('model.personActingOnBehalf').validate() && personActingOnBehalfValid;
        personActingOnBehalfValid = this.get('model.personActingOnBehalf.contactAddress').validate() && personActingOnBehalfValid;
      }
      if (this.get('model.isOrganisation') && this.get('_personOnBehalfHasMailingAddress')) {
        personActingOnBehalfValid = this.get('model.personActingOnBehalf.mailingAddress').validate() && personActingOnBehalfValid;
      }
      if (!(0, _lodash.isNil)(this.get('_indexId'))) {
        this.set('model.indexId', this.get('_indexId'));
      }
      yield this._validateEmail.perform();
      if (this.get('model.isOrganisation')) {
        yield this._validatePAOBEmail.perform();
      }
      let emailHasError = !(0, _lodash.isEmpty)(this.get('model.errors.email')) || !(0, _lodash.isEmpty)(this.get('model.personActingOnBehalf.errors.email'));
      if (modelValid && personActingOnBehalfValid && !emailHasError && !(0, _lodash.isEmpty)(this.get('onSave'))) {
        this.get('onSave')(this.get('model'));
        this.get('_dialog').hide();
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region BUTTON ACTIONS
      _onManageDetailMailingAddressClick() {
        this.toggleProperty('_detailHasMailingAddress');
        if (this.get('_detailHasMailingAddress')) {
          this.set('model.mailingAddress', this.createModel('address-nz'));
        } else {
          this.set('model.mailingAddress', null);
        }
      },
      _onManagePersonOnBehalfMailingAddressClick() {
        this.toggleProperty('_personOnBehalfHasMailingAddress');
        if (this.get('_personOnBehalfHasMailingAddress')) {
          this.set('model.personActingOnBehalf.mailingAddress', this.createModel('address-nz'));
        } else {
          this.set('model.personActingOnBehalf.mailingAddress', null);
        }
      },
      _copyAddress: function _copyAddress() {
        let model = this.get('model');
        this.get('_mailingAddress').copyAddress(model.contactAddress);
      },
      _copyPersonOnBehalfAddress: function _copyPersonOnBehalfAddress() {
        let model = this.get('model.personActingOnBehalf');
        this.get('_personActingOnBehalfMailingAddress').copyAddress(model.contactAddress);
      },
      _onSave: function _onSave() {
        this._save.perform();
      },
      _onOrgLookedup(response) {
        this._loadOrgModelToRequestModel(response);
      },
      //endregion

      //region email phone fax validations
      //person
      _validateEmail() {
        this._validateEmail.perform();
      },
      _validatePhone() {
        this._validatePhone.perform();
      },
      _validateFax() {
        this._validateFax.perform();
      },
      //PAOB
      _validatePAOBEmail() {
        this._validatePAOBEmail.perform();
      },
      _validatePAOBPhone() {
        this._validatePAOBPhone.perform();
      },
      _validatePAOBFax() {
        this._validatePAOBFax.perform();
      }
      //endregion
    }
  });
  _exports.default = _default;
});