define("esis-ui/components/areas/spg/nz/wizard/nz-spg-information-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region INITIALIZATION
    isFirstStep: true,
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
    },
    validateStep: function validateStep() {
      const model = this.get('model');
      model.set('errors', []);
      let errors = model.get('errors');
      let valid = true;
      if (!model.get('authorisedToCreate')) {
        errors.set('authorisedToCreate', "You must declare before continuing");
        valid = false;
      } else {
        errors.set('authorisedToCreate', '');
      }
      if ((0, _lodash.isEmpty)(model.get('spgName'))) {
        errors.set('spgName', 'Name cannot be blank');
        valid = false;
      } else {
        errors.set('spgName', '');
      }
      return valid;
    },
    //endregion

    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('model.esisEntityGuid')
        }));
      } catch (error) {
        this.get('logger').logError('nz-edit-branch-code-modal._loadPicklist', error);
      }
    }),
    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('model.esisEntityGuid', businessEntity.value);
      }
    }
    //endregion
  });
  _exports.default = _default;
});