define("esis-ui/components/areas/registration/au/wizard/au-registration-udf-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    _error: null,
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
    },
    onActivate() {
      this.set('_error', null);
      if (!(0, _lodash.isNil)(this.get('model.categoryGuid'))) {
        this.set('_selectedCategory', (0, _lodash.find)(this.get('_categoryList'), {
          'value': this.get('model.categoryGuid')
        }));
      }
    },
    validateStep() {
      if (this.credential.currentBusinessEntity.item.enforceCategoryCode) {
        if ((0, _lodash.isEmpty)(this.get('model.categoryGuid')) || (0, _lodash.isEmpty)(this.get('model.categoryTitle'))) {
          this.set('_error', "can't be blank");
          return false;
        } else {
          this.set('_error', null);
          return true;
        }
      } else {
        return true;
      }
    },
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //CATEGORIES
        let categoryPicklist = yield this.get('picklistRepo.loadCategories').perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('_categoryList', categoryPicklist);
        this.set('_error', null);
      } catch (error) {
        this.get('logger').logError('au-registration-udf-step._loadPicklist', error);
      }
    }),
    actions: {
      _onChangeCategory(selected) {
        this.set('_selectedCategory', selected);
        this.set('model.categoryGuid', selected.value);
        this.set('model.categoryTitle', selected.title);
        this.set('_error', null);
      },
      _onContractDateChanged(value) {
        this.set('model.contractDate', value);
      }
    }
  });
  _exports.default = _default;
});