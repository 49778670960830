define("esis-ui/models/organisation-search-criteria", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelBase.Model.extend({
    abn: (0, _modelBase.attr)(),
    abnSelected: (0, _modelBase.attr)(),
    acn: (0, _modelBase.attr)(),
    acnSelected: (0, _modelBase.attr)(),
    arbn: (0, _modelBase.attr)(),
    arbnSelected: (0, _modelBase.attr)(),
    arsn: (0, _modelBase.attr)(),
    arsnSelected: (0, _modelBase.attr)(),
    searchName: (0, _modelBase.attr)(),
    searchNameSelected: (0, _modelBase.attr)(),
    verifiedName: (0, _modelBase.attr)(),
    verifiedNameSelected: (0, _modelBase.attr)(),
    isOrganisationSearch: true,
    criteriaSelected: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        criteriaSelected: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('abnSelected') || model.get('acnSelected') || model.get('arbnSelected') || model.get('arsnSelected') || model.get('searchNameSelected')) {
                return true;
              } else {
                return false;
              }
            },
            message: 'At least one criteria should be selected'
          }
        },
        abn: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('abnSelected')) {
                let abnValue = model.get('abn');
                return abnValue.length === 11;
              }
              return true;
            },
            message: 'ABN should be 11 digits'
          }
        },
        acn: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('acnSelected')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'ACN cannot be blank'
          }
        },
        arbn: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('arbnSelected')) {
                let arbnValue = model.get('arbn');
                return arbnValue.length === 9;
              }
              return true;
            },
            message: 'ARBN should be 9 digits'
          }
        },
        arsn: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('arsnSelected')) {
                let arsnValue = model.get('arsn');
                return arsnValue.length === 9;
              }
              return true;
            },
            message: 'ARSN should be 9 digits'
          }
        },
        searchName: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('searchNameSelected')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Search name cannot be blank'
          }
        }
      };
    }
  });
  _exports.default = _default;
});