define("esis-ui/components/areas/registration/au/view/au-registration-renew-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "W0UGdoMi",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"registerAs\",\"modalTitle\",\"onConfirm\",\"submitForm\",\"name\",\"confirmBtnLabel\",\"dismiss\"],[[24,[\"_dialog\"]],\"Renew Registration\",[28,\"action\",[[23,0,[]],\"_onConfirm\"],null],false,\"collateralExpiryDateRenewModal\",[24,[\"_confirmBtnLabel\"]],false]],{\"statements\":[[4,\"if\",[[24,[\"_renewRequest\",\"displayException\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"_renewRequest\",\"showValidationErrorMessage\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"mb-3 validationErrorMessage\"],[8],[7,\"span\",true],[8],[1,[23,1,[]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mb-2\"],[8],[0,\"\\n    Current Expiry Date : \"],[1,[22,\"_currentExpiryDate\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[28,\"areas/registration/au/view/au-registration-expiry-options\",null,[[\"renewRequest\"],[[24,[\"_renewRequest\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[1,[28,\"areas/shared/info-modal\",null,[[\"registerAs\",\"onConfirm\"],[[24,[\"_renewConfirmDlg\"]],[28,\"action\",[[23,0,[]],\"_onConfirmRenew\"],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/au/view/au-registration-renew-modal/template.hbs"
    }
  });
  _exports.default = _default;
});