define("esis-ui/models/secured-party-group", ["exports", "esis-ui/utils/model-base", "lodash", "esis-ui/models/request-base"], function (_exports, _modelBase, _lodash, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    id: (0, _modelBase.attr)(),
    guid: (0, _modelBase.attr)(),
    esisId: (0, _modelBase.attr)(),
    entityGuid: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    addressForServiceAddressee: (0, _modelBase.attr)(),
    addressForServiceEmailAddress: (0, _modelBase.attr)(),
    addressForServiceFaxNumber: (0, _modelBase.attr)(),
    addressForServiceMailingAddress: (0, _modelBase.attr)(),
    addressForServicePhysicalAddress: (0, _modelBase.attr)(),
    ppsrTransactionId: (0, _modelBase.attr)(),
    ppsrSecuredPartyGroupNumber: (0, _modelBase.attr)(),
    ppsrSecuredPartyGroupAccessCode: (0, _modelBase.attr)(),
    ppsrDateProcessed: (0, _modelBase.attr)(),
    status: (0, _modelBase.attr)(),
    statusMessage: (0, _modelBase.attr)(),
    userCreated: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    userEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    transactionType: (0, _modelBase.attr)(),
    ppsrSecuredPartyGroupVersionId: (0, _modelBase.attr)(),
    securedPartyGroupName: (0, _modelBase.attr)(),
    readyForProcessing: (0, _modelBase.attr)(),
    reference: (0, _modelBase.attr)(),
    billingCode1: (0, _modelBase.attr)(),
    billingCode2: (0, _modelBase.attr)(),
    dateConvertedToDelegate: (0, _modelBase.attr)(),
    securedParties: (0, _modelBase.attr)(null, 'array'),
    canAmend: (0, _modelBase.attr)(),
    b2GAccount: (0, _modelBase.attr)(),
    isActive: (0, _modelBase.attr)(),
    validationErrors: (0, _modelBase.attr)(),
    itemDeleted: (0, _modelBase.attr)(),
    spgType: (0, _modelBase.attr)(),
    copiedSecuredPartyGroup: (0, _modelBase.attr)(),
    canConvert: (0, _modelBase.attr)(),
    isGoniRequired: (0, _modelBase.attr)(),
    hasSecuredParty: Ember.computed('securedParties', function () {
      return !(0, _lodash.isEmpty)(this.get('securedParties'));
    }),
    saveModel: function saveModel() {
      this.save();
      if (Ember.isPresent(this.get('addressForServiceMailingAddress'))) {
        this.get('addressForServiceMailingAddress').save();
      }
      if (Ember.isPresent(this.get('addressForServicePhysicalAddress'))) {
        this.get('addressForServicePhysicalAddress').save();
      }
      const sp = this.get('securedParties');
      if (!(0, _lodash.isEmpty)(sp)) {
        sp.forEach(function (item) {
          item.save();
          item.mailingAddress.save();
        });
      }
    },
    init() {
      this._super(...arguments);
      this.validations = {
        ppsrSecuredPartyGroupNumber: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('status') === 19) {
                let pat = /[^0-9]/g;
                return Ember.isPresent(value) && !pat.test(value);
              }
              return true;
            },
            message: 'Number should be 9 digits'
          }
        },
        securedPartyGroupName: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'SPG Name can not be blank'
          }
        },
        addressForServiceEmailAddress: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('status') !== 19) {
                return Ember.isPresent(value) ? model.validateEmailAddress(value) : false;
              }
              return true;
            },
            message: 'Not a valid email'
          }
        }
      };
      this.setProperties({
        spgType: 1,
        copiedSecuredPartyGroup: null
      });
    }
  });
  _exports.default = _default;
});