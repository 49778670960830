define("esis-ui/components/shared/checkbox-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8lj4acfl",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pretty p-icon p-smooth mr-0\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"change\",\"disabled\"],[\"checkbox\",[24,[\"property\"]],[28,\"action\",[[23,0,[]],\"_onChange\"],[[\"value\"],[\"target.checked\"]]],[24,[\"disabled\"]]]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"state p-primary\"],[8],[0,\"\\n      \"],[7,\"i\",true],[10,\"class\",\"icon checkBoxIcon\"],[8],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],[[\"class\",\"fixedWidth\",\"size\"],[\"pb-3 pr-4\",true,\"2x\"]]],false],[9],[0,\"\\n      \"],[7,\"label\",true],[8],[7,\"span\",true],[11,\"class\",[29,[\"pl-2 \",[28,\"if\",[[24,[\"required\"]],\"required\",\"\"],null]]]],[8],[1,[22,\"title\"],false],[9],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"helpText\"]]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[8],[1,[28,\"shared/help-icon\",null,[[\"helpText\",\"renderInPlace\"],[[24,[\"helpText\"]],[24,[\"renderInPlace\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/checkbox-action/template.hbs"
    }
  });
  _exports.default = _default;
});