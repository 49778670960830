define("esis-ui/models/contract-type-details-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    entityGuid: (0, _modelBase.attr)(),
    contractTypeCode: (0, _modelBase.attr)(),
    title: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    collateralType: (0, _modelBase.attr)(),
    validationErrors: (0, _modelBase.attr)(),
    isActive: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        contractTypeCode: {
          presence: true
        },
        title: {
          presence: true
        }
      };
    }
  });
  _exports.default = _default;
});