define("esis-ui/components/areas/search/au/view/shared/au-retrieving-result/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "G+YhvGHr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"mb-1 pt-1\"],[8],[1,[22,\"title\"],false],[0,\"\\n\"],[4,\"if\",[[24,[\"showProgress\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"progress\"],false],[0,\"%\\n\"]],\"parameters\":[]},null],[0,\"      \"],[5,\"fa-icon\",[],[[\"@icon\",\"@class\"],[\"sync-alt\",\"retrieveCertIcon\"]]],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/search/au/view/shared/au-retrieving-result/template.hbs"
    }
  });
  _exports.default = _default;
});