define("esis-ui/components/areas/shared/waiting-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ti2oGEqR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"modalTitle\",\"registerAs\",\"confirmButton\",\"closeButton\",\"renderInPlace\"],[[24,[\"modalTitle\"]],[24,[\"_dialog\"]],false,false,[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[22,\"message\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showSpinner\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"spinner\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/waiting-modal/template.hbs"
    }
  });
  _exports.default = _default;
});