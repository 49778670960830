define("esis-ui/components/areas/spg/nz/view/nz-transfer-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    spgGuid: null,
    spgId: null,
    model: null,
    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null
      });
    },
    show() {
      this._reset();
      this.set('model', this.createModel('transfer-financing-statement-request'));
      this.get('model').setProperties({
        esisSpgGuid: this.get('spgGuid'),
        authorisedToTransfer: false,
        transferAll: true
      });
      this.get('model').save();
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _transferSpg: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('spgRepo.nzTransferFinanceStatements').perform(this.get('model'));
        this.get('_infoDlg').show();
        // this.notifySuccess(`Financing statements have been transferred`);
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')();
        }
        // this.get('_dialog').hide();
      } catch (error) {
        // this.notifyError('Failed to transfer Secured Party Group');
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', error.errorMessage);
        this.get('logger').logError('nz-transfer-modal._transferSpg', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onFinanceStatementRangeChange: function _onFinanceStatementRangeChange(value) {
        this.set('model.transferAll', value);
      },
      //region BUTTON ACTIONS
      _onAddFinanceStatement() {
        this.set('modalTitle', "Select Financing Statement from SPG ID " + this.get('spgId'));
        this.get('_financeStatementPickerModal').show(this.get('spgId'));
      },
      _onSelectFinanceStatement(items) {
        if (!(0, _lodash.isEmpty)(items)) {
          let selectedFsList = (0, _lodash.union)(this.get('model.financingStatementsToTransfer'), (0, _lodash.map)(items, 'registrationNumber'));
          this.set('model.financingStatementsToTransfer', selectedFsList);
        }
      },
      _onRemoveFinanceStatement(item) {
        this.get('model.financingStatementsToTransfer').removeObject(item);
      },
      _onConfirm: function _onConfirm() {
        if (this.get('model').validate()) {
          this.get('_transferSpg').perform();
        }
      },
      _onInfoConfirm: function _onInfoConfirm() {
        this.get('_dialog').hide();
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});