define("esis-ui/components/areas/admin/transaction-history/au/list/au-transaction-history-list/component", ["exports", "esis-ui/components/areas/shared/page-base", "lodash", "ember-concurrency", "ember-lifeline"], function (_exports, _pageBase, _lodash, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    //region INITIALIZATION
    _auTansactionHistoryGrid: null,
    _selectedTansactionHistory: null,
    _selectedBusinessEntity: null,
    _periodValidationError: '',
    init() {
      this._super(...arguments);
      this.set('_isRecursive', true);
      const app = this.get('app');
      this._loadPicklistMethod();

      //region CATEGORY GRID
      this.set('_gridOption', {
        autoBind: false,
        height: "100%",
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('au-transactions')
            },
            parameterMap: (options, operation) => {
              options.businessEntityGuid = this.get('_selectedBusinessEntity.value');
              let keyword = this.get('_filterCriteria');
              options.recursive = this.get('_isRecursive');
              if (!(0, _lodash.isNil)(keyword)) {
                options.filterKeyword = keyword;
              }
              if (!(0, _lodash.isNil)(this.get('_startDate'))) {
                options.startDate = this.get('_startDate');
              }
              if (!(0, _lodash.isNil)(this.get('_endDate'))) {
                options.endDate = this.get('_endDate');
              }
              options._ = app.guid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                realData[i].transactionDate = this.dateTimeAsStr(realData[i].transactionDateTime);
              }
              return realData;
            }
          },
          sort: [{
            field: "transactionDateTime",
            dir: "desc"
          }]
        },
        columns: [{
          field: "transactionDateTime",
          title: 'Trans Date',
          filterable: false,
          width: "90px",
          template: '#: transactionDate #'
        }, {
          field: "entityName",
          title: 'Entity Name',
          filterable: false,
          width: "120px"
        }, {
          field: "securedPartyGroupName",
          title: 'SPG Name',
          filterable: false,
          width: "110px"
        }, {
          field: "productDescription",
          title: 'Description',
          filterable: false,
          width: "100px"
        }, {
          field: "ppsrReference",
          title: 'PPSR Reference',
          filterable: false,
          width: "90px"
        }, {
          field: "clientReference",
          title: 'Client Reference',
          filterable: false,
          width: "140px"
        }, {
          field: "nameReference",
          title: 'Grantor Reference',
          filterable: false,
          width: "120px"
        }, {
          field: "categoryCode",
          title: 'Category',
          filterable: false,
          width: "70px"
        }, {
          field: "displayName",
          title: 'User',
          filterable: false,
          width: "120px"
        }]
      });
      //endregion
    },

    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _downloadReport: (0, _emberConcurrency.task)(function* (isDownloadDetail) {
      try {
        this.get('_waitingModal').show('Please wait while ESIS is generating data extract');
        let filterRequest = {
          esisEntityGuid: this.get('_selectedBusinessEntity.value'),
          recursive: this.get('_isRecursive'),
          filterKeyword: this.get('_filterCriteria'),
          startDate: this.get('_startDate'),
          endDate: this.get('_endDate')
        };
        if (isDownloadDetail) {
          yield this.get('adminRepo.downloadAuTransHistoryDetail').linked().perform(filterRequest);
        } else {
          yield this.get('adminRepo.downloadAuTransHistoryInvoiceSummary').linked().perform(filterRequest);
        }
        this.notifySuccess(`${isDownloadDetail ? 'Transaction details' : 'Invoice summary'} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-transaction-history._downloadReport', error);
        this.notifyError(`${isDownloadDetail ? 'Transaction details' : 'Invoice summary'} failed to downloaded, please try again`);
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid() {
      this.set('_selectedTansactionHistory', null);
      this.get('_auTansactionHistoryGrid').refreshGrid(true);
    },
    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    _downloadReports(isDownloadDetail) {
      if (this._validatePeriod(true)) {
        this.get('_downloadReport').perform(isDownloadDetail);
      }
    },
    _validatePeriod(requiredRange = false) {
      let startDateValid = !(0, _lodash.isNil)(this.get('_startDate'));
      let endDateValid = !(0, _lodash.isNil)(this.get('_endDate'));
      if (requiredRange && (!startDateValid || !endDateValid)) {
        this.notifyWarn(`Date range is required`);
        return false;
      } else if (startDateValid && endDateValid && this.get('_startDate') > this.get('_endDate')) {
        this.set('_periodValidationError', 'Start Date cannot be later than End Date');
        return false;
      }
      this.set('_periodValidationError', '');
      return true;
    },
    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if (!(0, _lodash.isEmpty)(this.get('_selectedTansactionHistory')) && !(0, _lodash.isEmpty)(data)) {
          this.set('_selectedTansactionHistory', data);
        }
      },
      //region FILTER ACTIONS
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this._refreshGrid(true);
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      },
      _onCheckboxChange(value) {
        this.set('_isRecursive', value);
        this._refreshGrid(true);
      },
      _onStartDateChanged: function _onStartDateChanged(value) {
        this.set('_startDate', this.dateAsIsoStr(value, true));
        if (this._validatePeriod()) {
          this._refreshGrid(true);
        }
      },
      _onEndDateChanged: function _onEndDateChanged(value) {
        this.set('_endDate', this.dateAsIsoStr(value, true));
        if (this._validatePeriod()) {
          this._refreshGrid(true);
        }
      },
      //endregion

      //region BUTTON ACTIONS
      _onRefreshData() {
        this._refreshGrid();
      },
      _onDownloadDetail() {
        this._downloadReports(true);
      },
      _onDownloadSummary() {
        this._downloadReports(false);
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});