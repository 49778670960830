define("esis-ui/components/shared/checkbox-action/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    title: 'Title not set',
    showHelp: false,
    disabled: false,
    icon: "check",
    //region ACTIONS
    actions: {
      _onChange(value) {
        if (!(0, _lodash.isEmpty)(this.get('onChange'))) {
          this.get('onChange')(value);
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});