define("esis-ui/components/areas/spg/au/wizard/au-spg-information-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency", "ember-lifeline"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region INITIALIZATION
    isFirstStep: true,
    _spgNameErrorMessage: null,
    _emailErrorMessage: null,
    init() {
      this._super(...arguments);
      this._loadPicklistTask();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _goniCheckId: Ember.computed('app', function () {
      return `goni${!(0, _lodash.isEmpty)(this.get('app')) && this.get('app').guid()}`;
    }),
    //endregion

    //region METHODS
    _loadPicklistTask() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('model.entityGuid'))) {
          this._loadPicklist.perform();
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._loadPicklistTask();
          }, 20);
        }
      });
    },
    validateStep: function validateStep() {
      const model = this.get('model');
      model.set('errors', []);
      let errors = model.get('errors');
      let valid = true;
      if ((0, _lodash.isEmpty)(model.get('securedPartyGroupName'))) {
        errors.set('securedPartyGroupName', 'Name cannot be blank');
        valid = false;
      } else {
        errors.set('securedPartyGroupName', '');
      }
      if ((0, _lodash.isEmpty)(model.get('addressForServiceEmailAddress')) || !this.validateEmailAddress(model.get('addressForServiceEmailAddress'))) {
        let errorMessage = (0, _lodash.isEmpty)(model.get('addressForServiceEmailAddress')) ? 'Email cannot be blank' : 'Email is not valid';
        errors.set('addressForServiceEmailAddress', errorMessage);
        valid = false;
      } else {
        errors.set('addressForServiceEmailAddress', '');
      }
      return valid;
    },
    //endregion

    //region TASKS
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('model.entityGuid')
        }));
        let selectedBusinessEntityB2GAccount = this.get('_selectedBusinessEntity.item.b2GAccount');
        this.set('model.b2GAccount', `${selectedBusinessEntityB2GAccount.description} (${selectedBusinessEntityB2GAccount.customerNumber})`);
        this.get('model').saveModel();
      } catch (error) {
        this.get('logger').logError('au-spg-information-step._loadPicklist', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('model.entityGuid', businessEntity.value);
        this.set('model.b2GAccount', `${businessEntity.item.b2GAccount.description} (${businessEntity.item.b2GAccount.customerNumber})`);
      }
    }
    //endregion
  });
  _exports.default = _default;
});