define("esis-ui/mixins/esis-utils", ["exports", "moment", "lodash", "libphonenumber-js/max"], function (_exports, _moment, _lodash, _max) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    //region Initialise Services
    session: Ember.inject.service('session'),
    toast: Ember.inject.service('toast'),
    constants: Ember.inject.service('app-constants'),
    credential: Ember.inject.service('credential-service'),
    cacheService: Ember.inject.service('cache-service'),
    app: Ember.inject.service('app-service'),
    event: Ember.inject.service('event-service'),
    //endregion

    //region Toastr Notification
    //region private properties
    /*
    https://api.emberjs.com/ember/release/classes/Mixin
     */
    _longToastrOption: null,
    init() {
      this._super(...arguments);
      this.set('_longToastrOption', {
        closeButton: true,
        onclick: null,
        showDuration: '20000',
        hideDuration: '1000',
        timeOut: '20000',
        extendedTimeOut: '1000',
        newestOnTop: true,
        progressBar: true,
        positionClass: "toast-bottom-right"
      });
      this.set('_fixedToastrOption', {
        'closeButton': true,
        'debug': false,
        'newestOnTop': true,
        'progressBar': false,
        'positionClass': 'toast-bottom-right',
        'preventDuplicates': true,
        'onclick': null,
        'showDuration': '0',
        'hideDuration': '0',
        'timeOut': '0',
        'extendedTimeOut': '0',
        'showEasing': 'swing',
        'hideEasing': 'linear',
        'showMethod': 'fadeIn',
        'hideMethod': 'fadeOut',
        'tapToDismiss': false
      });
    },
    //endregion

    notifyError(msg) {
      this.get('toast').error(msg, '', this.get('_longToastrOption'));
    },
    notifyWarn(msg) {
      this.get('toast').warning(msg);
    },
    notifyInfo(msg) {
      this.get('toast').info(msg);
    },
    notifySuccess(msg) {
      this.get('toast').success(msg);
    },
    notifySuccessSticky(msg) {
      this.get('toast').success(msg, '', this.get('_fixedToastrOption'));
    },
    notifyWarnSticky(msg) {
      this.get('toast').warning(msg, '', this.get('_fixedToastrOption'));
    },
    //endregion

    //region tab notification
    tabNotifyError(msg, tabId = null, timeoutInSeconds = 0) {
      return this._tabNotifyCommon(msg, tabId, this.get('constants.tabNotificationSeverity.error'), timeoutInSeconds);
    },
    tabNotifyWarn(msg, tabId = null, timeoutInSeconds = 0) {
      return this._tabNotifyCommon(msg, tabId, this.get('constants.tabNotificationSeverity.warning'), timeoutInSeconds);
    },
    tabNotifyInfo(msg, tabId = null, timeoutInSeconds = 0) {
      return this._tabNotifyCommon(msg, tabId, this.get('constants.tabNotificationSeverity.info'), timeoutInSeconds);
    },
    _tabNotifyCommon(msg, tabId, severity, timeoutInSeconds = 0) {
      const tabNotification = this.createModel('tab-notification');
      let dynamicTabId = null;
      if (Ember.isPresent(tabId)) {
        dynamicTabId = this.get('app').getRootScreenTabIdByUuid(tabId);
        if ((0, _lodash.isNil)(dynamicTabId)) {
          console.error('Minerva Utils _tabNotifyCommon : Cannot find root root screen id', tabId);
        }
      }
      tabNotification.setProperties({
        message: msg,
        messageId: this.newGuid(),
        severity: severity,
        tabId: dynamicTabId,
        timeout: timeoutInSeconds // timeout should be specified in seconds, not milliseconds
      });

      this.get('event').trigger(this.get('constants.events.newTabNotification'), tabNotification);
      return tabNotification;
    },
    tabNotifyDismiss(notification) {
      this.get('event').trigger(this.get('constants.events.dismissTabNotification'), notification);
    },
    //endregion

    //region Guid / UUID helper
    newGuid() {
      return this.get('app').guid();
    },
    emptyGuid() {
      return this.get('constants.emptyGuid');
    },
    //endregion

    //region Current session And Authentication
    isAuthenticated() {
      return Ember.isPresent(this.get('session')) && this.get('session.isAuthenticated');
    },
    getCurrentAccessToken() {
      const authSession = this.get('session');
      if (authSession.get('isAuthenticated')) {
        return authSession.get('data.authenticated.access_token');
      }
      return '';
    },
    currentUserGuid() {
      if (Ember.isPresent(this.get('credential.currentUser'))) {
        return this.get('credential.currentUser.guid');
      }
      return null;
    },
    //endregion

    //region Date Object Helper
    /*
    return the local now object
    should be used only as local client side timestamp
    this date should not be submitted to the server side
     */
    now() {
      return (0, _moment.default)();
    },
    /*
        return a date for input field of type = date
     */
    serialiseDateOnly: function serialiseDateOnly(dateObj) {
      let d = (0, _moment.default)(dateObj);
      return d.format('YYYY-MM-DDT00:00:00');
    },
    dateAsStr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultDateFormat'));
    },
    dateTimeAsStr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultDateTimeFormat'));
    },
    timeAsStr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultTimeFormat'));
    },
    dateTimeFormatCommon(dateStr, format) {
      if (Ember.isBlank(dateStr)) {
        return '';
      }
      if (typeof dateStr === 'string') {
        if (dateStr.indexOf('Z') > 0) {
          dateStr = dateStr.substring(0, dateStr.length - 1);
        }
        if (dateStr.indexOf('+') > 0) {
          dateStr = dateStr.substring(0, dateStr.indexOf('+'));
        }
      }
      const m = (0, _moment.default)(dateStr);
      return m.format(format);
    },
    timeAsAmPmStr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultAmPmTimeFormat'));
    },
    yearAsStr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultYearFormat'));
    },
    monthAsStr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultMonthFormat'));
    },
    dateWithMonthAbbr(dateStr) {
      return this.dateTimeFormatCommon(dateStr, this.get('constants.defaultDateWithMonthAbbrFormat'));
    },
    /*
       Return date as a string in format: "2019-03-19T11:00:00.000Z"
     */
    dateAsIsoStrUtc(date, literalDate) {
      if (!(0, _lodash.isNil)(date)) {
        const m = (0, _moment.default)(date);
        if (m.isValid()) {
          if (literalDate) {
            return m.startOf('day').toISOString();
          }
          return m.toISOString();
        }
      }
      return '';
    },
    /*
       Return date as a string in format: "2019-03-29T00:00:00+13:00"
     */
    dateAsIsoStr(date, literalDate) {
      if (!(0, _lodash.isNil)(date)) {
        const m = (0, _moment.default)(date);
        if (m.isValid()) {
          if (literalDate) {
            return m.format('YYYY-MM-DDTHH:mm:ss');
          } else {
            return m.format();
          }
        }
      }
      return '';
    },
    dateAsDateObj(date) {
      const m = (0, _moment.default)(date);
      return m.toDate();
    },
    getTodayAsStr() {
      const m = (0, _moment.default)();
      return m.format(this.get('constants.defaultDateFormat'));
    },
    addYearToDateStr(dateStr, number) {
      let m = (0, _moment.default)(dateStr);
      if (m.isValid()) {
        return this.dateAsStr(m.add(number, 'y'));
      }
      m = (0, _moment.default)();
      return this.dateAsStr(m.add(number, 'y'));
    },
    currentTimestamp() {
      return (0, _moment.default)().toISOString(true);
    },
    utcTimestamp() {
      return _moment.default.utc();
    },
    timeDiffAsYears(localTime) {
      return Math.floor(_moment.default.duration((0, _moment.default)((0, _moment.default)()).diff(localTime)).asYears());
    },
    timeDiffAsSeconds(localTime) {
      return Math.floor(_moment.default.duration((0, _moment.default)((0, _moment.default)()).diff(localTime)).asSeconds());
    },
    timeDiffAsSecondsUtc(utcTime) {
      return Math.floor(_moment.default.duration(_moment.default.utc(_moment.default.utc()).diff(utcTime)).asSeconds());
    },
    timeDuration(timeAsTotalSeconds) {
      return _moment.default.duration(timeAsTotalSeconds, 'seconds');
    },
    calculateDurationAsStr(fromDate, toDate, adjustment) {
      if ((0, _lodash.isNil)(adjustment)) {
        adjustment = 0;
      }
      let fromDateMoment = (0, _moment.default)(fromDate);
      let toDateMoment = (0, _moment.default)(toDate);
      const duration = _moment.default.duration(toDateMoment.diff(fromDateMoment)).subtract(adjustment, 'seconds');
      return this.displayWaitTimeDuration(duration);
    },
    getTimeDiscrepancy() {
      const constants = this.get('constants');
      return this.get(`app._defaultSystemSettings.${constants.systemSetting.timeDiscrepancy}`);
    },
    getLocale() {
      let offset = new Date().getTimezoneOffset() / -60;
      // let language = (navigator.language || navigator.browserLanguage).split('-')[0];
      // return { timeZoneOffset: offset, language: language };
      return offset;
    },
    getLocaleAsStr() {
      return JSON.stringify(this.getLocale());
    },
    getAge(dob) {
      let today = new Date();
      let formattedDob = new Date(dob);
      let years = today.getFullYear() - formattedDob.getFullYear();
      if (today.getMonth() < formattedDob.getMonth() || today.getMonth() === formattedDob.getMonth() && today.getDate() < formattedDob.getDate()) {
        years--;
      }
      return years;
    },
    displayWaitTime(waitTime) {
      const duration = _moment.default.duration(waitTime, 'seconds');
      return this.displayDuration(duration);
    },
    displayDuration(duration) {
      const months = duration.months();
      const days = duration.days();
      const hours = this.formatInt(duration.hours(), 2);
      if (months > 0) {
        return this.fmt("{0}m {1}d {2}h", months, days, hours);
      }
      const minutes = this.formatInt(duration.minutes(), 2);
      if (days > 0) {
        return this.fmt("{0}d {1}h {2}m", days, hours, minutes);
      }
      const seconds = this.formatInt(duration.seconds(), 2);
      return this.fmt("{0}h {1}m {2}s", hours, minutes, seconds);
    },
    displayWaitTimeDuration(duration) {
      const hours = this.formatInt(duration.hours(), 2);
      const minutes = this.formatInt(duration.minutes(), 2);
      return this.fmt("{0}h {1}m", hours, minutes);
    },
    //endregion

    //region Utility Methods

    /*
        Format an ID by padding it out with leading zeros to be more readable
     */
    formatId(id) {
      return this.formatInt(id, 6);
    },
    /*
        Format an integer to the specified number of digits
     */
    formatInt(num, length) {
      let r = "" + num;
      while (r.length < length) {
        r = "0" + r;
      }
      return r;
    },
    fmt(format) {
      let args = Array.prototype.slice.call(arguments, 1);
      return format.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] !== 'undefined' ? args[number] : match;
      });
    },
    currentHost() {
      const fullHost = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
      return fullHost;
    },
    getCacheKey(cacheType, name) {
      if (cacheType === this.get('constants.cacheType.lookupCache')) {
        let key = `picklist-${name}`;
        return key;
      } else if (cacheType === this.get('constants.cacheType.settingCache')) {
        return name;
      }
    },
    formatBytes(bytes, decimals) {
      //console.log('code & bytes: ', code, bytes);
      if (bytes === 0) return '0 Bytes';
      var k = 1024,
        //1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    formatMonetaryValue(value) {
      return `$${parseFloat(Math.round(value * 100) / 100).toFixed(2)}`;
    },
    //endregion

    //region Ember Object / JSON Object Utils
    /* source object has to be Ember.Object.extend
    * the destination object must extend model-base
    * */
    mapObject(source, destination) {
      const properties = destination.properties();
      properties.forEach(key => {
        destination.set(key, source.get(key));
      });
      return destination;
    },
    mapOnlySourceProps(source, destination) {
      const properties = source.properties();
      properties.forEach(key => {
        destination.set(key, source.get(key));
      });
      return destination;
    },
    /*
    Mapping a plain object to a ember model object,
    plainObject : should be any javascript object (plain json ideally)
    modelType : type of model ember object (in model folder)
     */
    mapJsonObjectToModel(plainObject, modelType, forceOverrideModel = false) {
      if ((0, _lodash.isNil)(plainObject) || (0, _lodash.isNil)(modelType)) {
        return this.createModel(modelType);
      }
      let modelObject = Ember.getOwner(this).lookup(`models:${modelType}`);
      if ((0, _lodash.isNil)(modelObject)) {
        return null;
      }
      let properties = [];
      if (typeof modelObject.properties === 'function') {
        properties = modelObject.properties();
      } else {
        properties = (0, _lodash.filter)(Object.keys(modelObject.constructor.prototype), item => {
          return !item.startsWith('_');
        });
      }
      (0, _lodash.forIn)(plainObject, (value, key) => {
        if ((0, _lodash.indexOf)(properties, key) > -1) {
          if (!(0, _lodash.isNil)(value) || forceOverrideModel) {
            modelObject.set(key, value);
          }
        }
      });
      return modelObject;
    },
    mapJsonObjectArrayToModelArray(plainObjectArray, modelType) {
      if ((0, _lodash.isEmpty)(plainObjectArray)) {
        return [];
      }
      const result = [];
      (0, _lodash.forEach)(plainObjectArray, item => {
        if (!(0, _lodash.isNil)(item)) {
          result.pushObject(this.mapJsonObjectToModel(item, modelType));
        }
      });
      return result;
    },
    createModel(modelType) {
      if ((0, _lodash.isNil)(modelType)) {
        return null;
      }
      let modelObject = Ember.getOwner(this).lookup(`models:${modelType}`);
      return modelObject;
    },
    convertToEmberObject(obj) {
      /*
      only convert if we know it is a javascript object
      http://api.emberjs.com/ember/3.9/functions/@ember%2Futils/typeOf
       */
      if (Ember.typeOf(obj) === 'object') {
        return Ember.Object.create(obj);
      }
      return obj;
    },
    //endregion

    //region Validation
    validateEmailAddress: function validateEmailAddress(emailAddress) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(emailAddress);
    },
    //endregion

    //region SMS
    smsPartCount(message) {
      if (!message) {
        return 0;
      }
      let length = message.length;
      if (length <= 160) {
        return 1;
      }
      return Math.ceil(length / 153);
    },
    textAreaStringLength(text) {
      if (!text) {
        return 0;
      }
      if (this.detectIE()) {
        return text.length;
      }
      let newLines = text.match(/(\r\n|\n|\r)/g);
      let addition = 0;
      if (newLines != null) {
        addition = newLines.length;
      }
      return text.length + addition;
    },
    detectIE() {
      let ua = window.navigator.userAgent;
      let msie = ua.indexOf('MSIE ');
      let trident = ua.indexOf('Trident/');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      }
      if (trident > 0) {
        // IE 11 (or newer) => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      // other browser
      return false;
    },
    //endregion

    //region Phone number formatter
    formatPhoneNumber(phoneNumber) {
      if (Ember.isPresent(phoneNumber)) {
        const defaultCountryCode = this.get('app').getSetting(this.get('constants.systemSetting.countryCode'));
        const number = (0, _max.parsePhoneNumberFromString)(`+${phoneNumber}`, defaultCountryCode);
        if (Ember.isPresent(number)) {
          return number.formatNational();
        }
      }
      return phoneNumber;
    },
    formatPhoneNumberForEdit(phoneNumber) {
      /*
      this method assume the input phone number is in MHI SudoE164 form i.e. E164 minus the + sign
       */
      if (Ember.isPresent(phoneNumber)) {
        const defaultCountryCode = this.get('app').getSetting(this.get('constants.systemSetting.countryCode'));
        const number = (0, _max.parsePhoneNumberFromString)(`+${phoneNumber}`, defaultCountryCode);
        if (Ember.isPresent(number)) {
          if (number.country === defaultCountryCode) {
            return number.formatNational();
          } else {
            return number.number;
          }
        } else {
          return `+${phoneNumber}`;
        }
      }
      return phoneNumber;
    },
    //endregion

    getDateFromNow: function getDateFromNow(years) {
      const currentTime = this.get('app').getCurrentSystemTime();
      const result = currentTime.endOf('day').add(years, 'y');
      return result.toDate();
    },
    paddingToLength: function paddingToLength(value, len) {
      const pad = '0000000000';
      if (Ember.isPresent(value)) {
        value = value.toString();
        if (value.length < len) {
          value = pad + value;
          value = value.slice(-len, value.length);
        }
      }
      return value;
    },
    formatBoolean: function formatBoolean(val) {
      try {
        if (Ember.isBlank(val)) {
          return 'Not Stated';
        }
        return val ? 'Yes' : 'No';
      } catch (error) {
        return '';
      }
    },
    // getTabIconName: function (status) {
    //     const constants = this.get('constants');
    //     if (status === constants.statusValue.pending || status === constants.statusValue.review || status === constants.statusValue.creating || status === constants.statusValue.validated) {
    //         return 'play';
    //     } else if (status === constants.statusValue.error || status === constants.statusValue.rejected) {
    //         return 'exclamation-triangle';
    //     }
    //     return 'check';
    // },
    validateVin: function validateVin(vin) {
      const requiredLength = 17;
      const regexPattern = '[^a-hj-npr-zA-HJ-NPR-Z0-9]';
      const regexPatternWords = 'CHASSIS|SERIAL|NOVIN|NOCHASSIS|NOSERIAL|UNKNOWN|NOTKNOWN|NUMBER|DONTKNOW|APPLICABLE|MANUF';
      const regexPatternAlpha = '[a-hj-npr-zA-HJ-NPR-Z]';
      const regexPatternNumber = '[0-9]';
      if (Ember.isBlank(vin)) {
        return false;
      }
      if (vin.length !== requiredLength) {
        return false;
      }
      if (vin.search(regexPattern) !== -1) {
        return false;
      }
      if (vin.search(new RegExp(regexPatternWords, "i")) !== -1) {
        return false;
      } else if (vin.search(regexPatternAlpha) === -1 || vin.search(regexPatternNumber) === -1) {
        return false;
      }
      return true;
    },
    validateHin: function validateHin(hin) {
      const alpha = '[^a-zA-Z]';
      //const numeric = '[^0-9]';
      if (Ember.isBlank(hin)) {
        //console.log('vin validation : vin is blank');
        return false;
      }
      if (hin.length !== 12 && hin.length !== 14) {
        //console.log('vin validation : vin is not 12');
        return false;
      }
      const hinArray = hin.split('');
      let valid = true;
      hinArray.forEach(function (item, index) {
        if (valid) {
          if (index < hin.length - 9 || index === hin.length - 4) {
            if (item.search(alpha) !== -1) {
              valid = false;
            }
          }
        }
      });
      return valid;
    },
    validateOfficialNumber: function validateOfficialNumber(officialNumber) {
      const requiredLength = 6;
      const regexPattern = '[^0-9]';
      if (Ember.isBlank(officialNumber)) {
        //console.log('vin validation : vin is blank');
        return false;
      }
      if (officialNumber.length !== requiredLength) {
        //console.log('vin validation : vin is not 12');
        return false;
      }
      if (officialNumber.search(regexPattern) !== -1) {
        //console.log('vin validation : vin contains invalid characters');
        return false;
      }
      return true;
    }
  });
  _exports.default = _default;
});