define("esis-ui/components/areas/shared/waiting-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    renderInPlace: true,
    modalTitle: 'Processing',
    showSpinner: true,
    isShowing: false,
    message: 'Please be patient while we are submitting your request...',
    show(message) {
      if (!(0, _lodash.isEmpty)(message)) {
        this.set('message', Ember.String.htmlSafe(message));
      }
      this.get('_dialog').show();
      this.set('isShowing', true);
    },
    hide() {
      this.get('_dialog').hide();
      this.set('isShowing', false);
    }
  });
  _exports.default = _default;
});