define("esis-ui/components/areas/spg/nz/view/nz-spg-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // areas/spg/nz/view/spg-modal/component.js
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null
      });
    },
    //endregion

    //region COMPUTED PROPERTIES
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null
      });
    },
    _createModel(model) {
      this.mapObject(model, this.set('model', this.createModel('secured-party-group-nz')));
      let configSettingsModel = this.mapObject(model.configSettings, this.createModel('secured-party-group-config-setting-nz'));
      this.set('model.configSettings', configSettingsModel);
      this.get('model').saveModel();
    },
    show(model) {
      this._reset();
      this._createModel(model);
      this.get('_dialog').show();
    },
    //endregion

    //region ACTIONS
    actions: {
      _onConfirm() {
        let valid = this.get('model').validate();
        if (valid && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('model'));
        }
        return valid;
      }
    }
    //endregion
  });
  _exports.default = _default;
});