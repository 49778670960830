define("esis-ui/components/areas/registration/au/wizard/au-registration-spg-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    isFirstStep: true,
    // isCompleted: true,//????????
    init() {
      this._super(...arguments);
      this._stepGuid = this.newGuid();
      this._loadPicklist.perform();
    },
    _collateralsAdded: Ember.computed('model.collaterals.@each', function () {
      return !(0, _lodash.isEmpty)(this.get('model.collaterals'));
    }),
    validateStep: function validateStep() {
      const model = this.get('model');
      return model.validate();
      // return true;
    },

    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let spgPicklist = yield this.get('picklistRepo.loadSpgListForRegistration').linked().perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('_spgList', spgPicklist);
        if (!(0, _lodash.isEmpty)(this.get('model.guid'))) {
          this.set('_selectedSpg', (0, _lodash.find)(spgPicklist, {
            'item': {
              guid: this.get('model.securedPartyGroupGuid')
            }
          }));
        } else {
          this.set('_selectedSpg', spgPicklist[0]);
          this.set('model.securedPartyGroupGuid', spgPicklist[0].item.guid);
          this.set('model.securedPartyGroupName', spgPicklist[0].title);
          this.set('model.isGoniRequired', spgPicklist[0].item.isGoniRequired);
          this.get('model').save();
        }
      } catch (error) {
        this.get('logger').logError('au-registration-spg-step._loadPicklist', error);
      }
    }),
    actions: {
      _onChangeSpg(selected) {
        this.set('_selectedSpg', selected);
        this.set('model.securedPartyGroupGuid', selected.item.guid);
        this.set('model.securedPartyGroupName', selected.title);
        this.set('model.isGoniRequired', selected.item.isGoniRequired);
      },
      _onCollateralTypeChange(value) {
        this.set('model.collateralType', value);
      }
    }
  });
  _exports.default = _default;
});