define("esis-ui/components/areas/registration/au/wizard/au-add-collateral-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _collaterals: null,
    init() {
      this._super(...arguments);
      this._modalGuid = this.newGuid();
    },
    show(pendingCollaterals, contractType) {
      this.set('_collaterals', []);
      const collaterals = this.get('_collaterals');
      (0, _lodash.forEach)(pendingCollaterals, item => {
        let collateral = this.mapObject(item, this.createModel('collateral'));
        collateral.setProperties({
          indexId: item.indexId,
          tabId: this.newGuid()
        });
        this._setExpiryDate(collateral);
        collaterals.pushObject(collateral);
      });
      if (!(0, _lodash.isEmpty)(contractType)) {
        this.set('_modalTitle', `Add Collateral Type by Contract Type: ${contractType.title}`);
      } else {
        let collateral = collaterals[0];
        this.set('_modalTitle', (0, _lodash.isNil)(collateral.indexId) ? 'Add Collateral Type' : 'Edit Collateral Type');
      }
      this.get('_dialog').show();
    },
    _dismiss(collateral) {
      const collaterals = this.get('_collaterals');
      let removedCollateral = (0, _lodash.find)(collaterals, {
        tabId: collateral.tabId
      });
      if (!(0, _lodash.isEmpty)(removedCollateral)) {
        collaterals.removeObject(removedCollateral);
        if (collaterals.length === 0) {
          this.get('_dialog').hide();
        }
      }
    },
    _validate(collateral) {
      const constants = this.get('constants');
      let validated = true;
      if (collateral.showSerialNumber) {
        if ((0, _lodash.isEmpty)(collateral.serialNumber)) {
          collateral.set('serialNumberError', 'Serial number cannot be blank');
          validated = false;
        } else if (collateral.serialNumberType === constants.serialNumberType.VIN) {
          const vinValid = this.validateVin(collateral.serialNumber);
          if (!vinValid) {
            collateral.set('serialNumberError', 'VIN must be 17 characters and not cannot contain I, O, Q');
            validated = false;
          }
        } else if (collateral.serialNumberType === constants.serialNumberType.HIN) {
          const hinNumber = collateral.serialNumber.replace('-', '');
          const hinValid = this.validateHin(hinNumber);
          if (!hinValid) {
            collateral.set('serialNumberError', 'HIN must be 12 or 14 characters and first 3 or 5 characters, 9 or 11 positions alpha');
            validated = false;
          } else {
            collateral.set('serialNumber', hinNumber);
          }
        } else if (collateral.serialNumberType === constants.serialNumberType.officialNumber) {
          const officeNumber = this.paddingToLength(collateral.serialNumber, 6);
          const officialValid = this.validateOfficialNumber(officeNumber);
          if (!officialValid) {
            collateral.set('serialNumberError', 'Official Number must be 6 numeric');
            validated = false;
          } else {
            collateral.set('serialNumber', officeNumber);
          }
        }
        if ((0, _lodash.isEmpty)(collateral.serialNumberTypeTitle)) {
          let type = (0, _lodash.find)(this.get('constants.serialNumberTypeOptions'), {
            'id': collateral.serialNumberType
          });
          if (!(0, _lodash.isEmpty)(type)) {
            collateral.set('serialNumberTypeTitle', type.title);
          }
        }
      }
      if (validated) {
        collateral.set('serialNumberError', '');
      }
      if (collateral.collateralClassType === constants.searchCollateralClassType.motorVehicle && collateral.isSerialised) {
        if (collateral.registrationEndTimeOption === constants.renewRegTimeOption.custom && this.timeDiffAsYears(collateral.registrationEndTime) < -7) {
          collateral.set('registrationEndTimeError', 'Registration end time cannot be more than 7 years');
          validated = false;
        }
      } else if (collateral.registrationEndTimeOption === constants.renewRegTimeOption.custom && this.timeDiffAsYears(collateral.registrationEndTime) < -25) {
        collateral.set('registrationEndTimeError', 'Registration end time cannot be more than 25 years');
        validated = false;
      }
      if (collateral.registrationEndTimeOption === constants.renewRegTimeOption.custom && (0, _lodash.isNil)(collateral.registrationEndTime)) {
        collateral.set('registrationEndTimeError', 'Registration end time cannot be empty');
        validated = false;
      }
      if (validated) {
        collateral.set('registrationEndTimeError', '');
      }
      return validated;
    },
    _setExpiryDate(collateral) {
      const constants = this.get('constants');
      let renewRequest;
      if ((0, _lodash.isNil)(collateral.indexId)) {
        renewRequest = this.mapObject(collateral, this.createModel('renew-reg-request'));
        renewRequest.set('guid', this.newGuid());
        if (collateral.collateralClassType === constants.searchCollateralClassType.motorVehicle && collateral.isSerialised) {
          renewRequest.set('disableTwentyFiveYear', true);
          renewRequest.set('disableNoDuration', true);
        }
        if (collateral.contractDefaultDurationYears === 7) {
          renewRequest.set('option', constants.renewRegTimeOption.sevenYear);
        } else if (collateral.contractDefaultDurationYears === 25) {
          renewRequest.set('option', constants.renewRegTimeOption.twentyFiveYear);
        } else if (collateral.isRegistrationEndTimeNotStated === true) {
          renewRequest.set('option', constants.renewRegTimeOption.noDuration);
        } else if (!(0, _lodash.isNil)(collateral.registrationEndTime)) {
          renewRequest.set('option', constants.renewRegTimeOption.custom);
          renewRequest.set('customDate', collateral.registrationEndTime);
        }
      } else {
        renewRequest = this.createModel('renew-reg-request');
        renewRequest.setProperties({
          option: collateral.registrationEndTimeOption,
          customDate: collateral.registrationEndTimeOption === constants.renewRegTimeOption.custom ? collateral.registrationEndTime : null,
          disableTwentyFiveYear: collateral.collateralClassType === constants.searchCollateralClassType.motorVehicle && collateral.isSerialised,
          disableNoDuration: collateral.collateralClassType === constants.searchCollateralClassType.motorVehicle && collateral.isSerialised
        });
      }
      renewRequest.save();
      collateral.set('renewRequest', renewRequest);
    },
    _applyExpiryDateTask: (0, _emberConcurrency.task)(function* (collateral) {
      const constants = this.get('constants');
      if (!(0, _lodash.isNil)(collateral.renewRequest)) {
        if (collateral.renewRequest.option === constants.renewRegTimeOption.noDuration) {
          collateral.setProperties({
            registrationEndTimeOption: collateral.renewRequest.option,
            isRegistrationEndTimeNotStated: true,
            registrationEndTime: null
          });
          return;
        }
        const result = yield this.get('registrationRepo.getRegistrationExpiryInfo').linked().perform(collateral.renewRequest);
        collateral.setProperties({
          registrationEndTimeOption: collateral.renewRequest.option,
          registrationEndTime: result.endTime,
          expiryDateMessage: result.expiryDateMessage,
          isRegistrationEndTimeNotStated: null
        });
      }
    }),
    actions: {
      _dismissAll() {
        this.set('_collaterals', null);
      },
      _onConfirmDetails(collateral) {
        this.get('_applyExpiryDateTask').perform(collateral).then(() => {
          if (this._validate(collateral)) {
            Ember.set(collateral, 'isConfirmed', true);
            if (!(0, _lodash.isEmpty)(this.get('onConfirmCollateral'))) {
              this.get('onConfirmCollateral')(collateral);
            }
            this._dismiss(collateral);
          }
        });
      },
      _updateCollateral(collateral) {
        this.get('_applyExpiryDateTask').perform(collateral).then(() => {
          if (this._validate(collateral)) {
            if (!(0, _lodash.isEmpty)(this.get('onUpdate'))) {
              this.get('onUpdate')(collateral);
            }
            this._dismiss(collateral);
          }
        });
      },
      _dismiss(collateral) {
        this._dismiss(collateral);
      },
      _onChangeTab(newId /*previousId*/) {
        this.set('_activeTabId', newId);
      },
      _onSerialNumberTypeChange(collateral, value) {
        Ember.set(collateral, 'serialNumberType', value);
        if (!(0, _lodash.isEmpty)(collateral.serialNumberType)) {
          let type = (0, _lodash.find)(this.get('constants.serialNumberTypeOptions'), {
            'id': collateral.serialNumberType
          });
          if (!(0, _lodash.isEmpty)(type)) {
            Ember.set(collateral, 'serialNumberTypeTitle', type.title);
          }
        }
      },
      _onRegOrDescValueChange(collateral, value) {
        Ember.set(collateral, 'regOrDescValue', value);
      },
      _expiryDateChanged(collateral, value) {
        Ember.set(collateral, 'registrationEndTime', value);
        this._setExpiryDate(collateral);
      },
      _onIsRegEndTimeNotStatedChange(collateral, value) {
        Ember.set(collateral, 'isRegistrationEndTimeNotStated', value);
        if (!(0, _lodash.isNil)(collateral.isRegistrationEndTimeNotStated) && collateral.isRegistrationEndTimeNotStated) {
          Ember.set(collateral, 'registrationEndTime', null);
        }
        this._setExpiryDate(collateral);
      },
      _onIsSubordinateChange(collateral, value) {
        Ember.set(collateral, 'isSubordinate', value);
      },
      _onIsPmsiChange(collateral, value) {
        Ember.set(collateral, 'isPmsi', value);
      },
      _onIsInventoryChange(collateral, value) {
        Ember.set(collateral, 'isInventory', value);
      },
      _onAreAssetsSubjectToControlChange(collateral, value) {
        Ember.set(collateral, 'areAssetsSubjectToControl', value);
      },
      _onAreProceedsClaimedChange(collateral, value) {
        collateral.set('areProceedsClaimed', value);
        let desc = collateral.areProceedsClaimed === true ? this.get('constants.defaultAreProceedClaimedDescription') : null;
        Ember.set(collateral, 'proceedsClaimedDescription', desc);
      }
    }
  });
  _exports.default = _default;
});