define("esis-ui/routes/route-base", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-lifeline"], function (_exports, _authenticatedRouteMixin, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    _isLogout: false,
    beforeModel() {
      this.get('app').resetScreenStack();
      const constants = this.get('constants');
      this.get('event').on(constants.events.logout, this, '_onLogoutEvent');
      this._super(...arguments);
    },
    model() {
      this._super(...arguments);
      if (Ember.isNone(this.get('credential.currentUser'))) {
        // load user profile when not available
        this.get('accountRepo.loadProfile').perform().then(() => {
          this._checkPermission();
        }).catch(reason => {
          this.notifyError(`Load user profile failed with reason : ${reason}`);
          this.get('session').invalidate();
        });
      } else {
        this._checkPermission();
      }
    },
    _checkPermission() {
      const credential = this.get('credential');
      if (!Ember.isNone(credential.get('currentUser'))) {
        const constants = this.get('constants');
        if (credential.get('currentRegion') === constants.region.au && credential.get('currentUser.hasAuPermission') !== true) {
          this.get('session').invalidate();
        } else if (credential.get('currentRegion') === constants.region.nz && credential.get('currentUser.hasNzPermission') !== true) {
          this.get('session').invalidate();
        }
      }
    },
    destroy() {
      this.get('event').off(this.get('constants.events.logout'), this, '_onLogoutEvent');
      (0, _emberLifeline.runDisposables)(this);
      this._super(...arguments);
    },
    _onLogoutEvent() {
      this.set('_isLogout', true);
      this.get('session').invalidate();
    },
    actions: {
      error(error) {
        // Manage your errors
        Ember.onerror(error);
        // substate implementation when returning `true`
        return true;
      }
    }
  });
  _exports.default = _default;
});