define("esis-ui/components/areas/search/nz/nz-search-base", ["exports", "esis-ui/components/areas/shared/nz-page-base", "lodash", "ember-concurrency", "ember-lifeline"], function (_exports, _nzPageBase, _lodash, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region INITIALISATION

    //To be set in each search type page
    searchSubmitTaskName: '',
    searchTabTitle: '',
    searchModelName: '',
    setDefaultModelValues() {},
    init() {
      this._super(...arguments);
      this._loadModel();
    },
    //endregion

    //region TASK
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let branchCodePicklist = yield this.get('picklistRepo.loadNzBranchCodeByEntity').perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('_branchCodePicklist', (0, _lodash.map)((0, _lodash.toPairs)((0, _lodash.groupBy)((0, _lodash.orderBy)(branchCodePicklist, 'title'), 'item.securedPartyGroupName')), p => {
          return {
            groupName: p[0],
            options: p[1]
          };
        }));
        this.set('model.hasBranchCode', !(0, _lodash.isEmpty)(branchCodePicklist));
        if (branchCodePicklist.length === 1) {
          this.set('_selectedBranchCode', branchCodePicklist[0]);
          this.set('model.branchGuid', branchCodePicklist[0].value);
          this.set('model.branchCode', branchCodePicklist[0].title);
        }
      } catch (error) {
        this.get('logger').logError('nz-search-base._loadPicklist', error);
      }
    }),
    _submitSearchTask: (0, _emberConcurrency.task)(function* () {
      try {
        const searchRepo = this.get('searchRepo');
        const response = yield searchRepo.get(`${this.get('searchSubmitTaskName')}`).perform(this.get('model'));
        this.get('model').save();
        this.updateTabProperty('p0', response.searchId);
        this.updateTabProperty('options', response);
        (0, _emberLifeline.runTask)(this, () => {
          this._loadModel();
        }, 100);
      } catch (e) {
        const severity = this.get('constants.backendErrorSeverity');
        if (e.errorSeverity === severity.error || e.errorSeverity === severity.warning) {
          this.get('model').set('displayException', true);
          this.get('model').set('validatErrorMessage', e.errorMessage);
        } else {
          this.get('logger').logError('nz-search-base._submitSearchTask', e);
        }
      }
    }),
    //endregion

    //region METHODS
    _loadModel() {
      if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
        this.updateTabProperty('title', `${this.get('searchTabTitle')} ${this.get('tabModel.p0')}`);
        this.set('_submitSearchResponse', this.get('tabModel.options'));
        this._loadResultGrid();
      } else {
        const newSearchModel = this.createModel(this.get('searchModelName'));
        newSearchModel.set('esisEntityGuid', this.get('credential.currentBusinessEntityGuid'));
        this.set('model', newSearchModel);
        this.setDefaultModelValues();
        this._loadPicklist.perform().then(() => {
          this.get('model').save();
        });
      }
    },
    _loadResultGrid() {
      if (!(0, _lodash.isEmpty)(this.get('_searchResultControl'))) {
        this.get('_searchResultControl').loadGrid();
      } else {
        (0, _emberLifeline.runTask)(this, () => {
          this._loadResultGrid();
        });
      }
    },
    //endregion

    //region Actions
    actions: {
      _branchCodeChanged(selected) {
        this.set('_selectedBranchCode', selected);
        this.set('model.branchGuid', selected.value);
        this.set('model.branchCode', selected.title);
      },
      _createRestGridFilter(options) {
        return this._createRestGridFilter(options);
      },
      _submitSearch() {
        if (this.get('model').validate()) {
          this._submitSearchTask.perform();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});