define("esis-ui/components/areas/report/au/view/search-grantor-diff/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sBqO7h0J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label field-label\"],[8],[0,\"Source Grantor Search Esis ID\"],[9],[0,\"\\n  \"],[1,[28,\"one-way-input-mask\",null,[[\"mask\",\"options\",\"class\",\"update\",\"value\"],[\"9{1,12}\",[28,\"hash\",null,[[\"showMaskOnHover\",\"showMaskOnFocus\",\"nullable\"],[false,false,true]]],\"form-control text-field fixDatePickerWidth\",[28,\"action\",[[23,0,[]],\"_updateSourceId\"],null],[24,[\"_sourceId\"]]]]],false],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"validationErrorMessage\"],[8],[1,[22,\"sourceIdErrorMsg\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"control-label field-label\"],[8],[0,\"Compare Grantor Search Esis ID\"],[9],[0,\"\\n  \"],[1,[28,\"one-way-input-mask\",null,[[\"mask\",\"options\",\"class\",\"update\",\"value\"],[\"9{1,12}\",[28,\"hash\",null,[[\"showMaskOnHover\",\"showMaskOnFocus\",\"nullable\"],[false,false,true]]],\"form-control text-field fixDatePickerWidth\",[28,\"action\",[[23,0,[]],\"_updateCompareId\"],null],[24,[\"_compareId\"]]]]],false],[0,\"\\n  \"],[7,\"small\",true],[10,\"class\",\"validationErrorMessage\"],[8],[1,[22,\"compareIdErrorMsg\"],false],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[1,[28,\"shared/buttons/action-button\",null,[[\"title\",\"type\",\"class\",\"onClick\"],[\"Download Excel Report\",\"primary\",\"shortDownloadBtn\",[28,\"action\",[[23,0,[]],\"_downloadReport\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/report/au/view/search-grantor-diff/template.hbs"
    }
  });
  _exports.default = _default;
});