define("esis-ui/components/areas/report/au/view/registration-count-by-entity/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    init() {
      this._super(...arguments);
    },
    //endregion

    //region TASKS
    _downloadReport: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('reportRepo.downloadAuRegistrationCountExtractReport').linked().perform();
        if (Ember.isPresent(this.get('hideWaitingModal'))) {
          this.get('hideWaitingModal')();
        }
        this.notifySuccess('Extract report has been downloaded');
      } catch (error) {
        this.notifyError(`Failed to download file - ${error.payload.message}`);
        if (Ember.isPresent(this.get('hideWaitingModal'))) {
          this.get('hideWaitingModal')();
        }
      }
    }).drop(),
    //endregion

    //region ACTIONS
    actions: {
      _downloadReport: function _downloadReport() {
        if (Ember.isPresent(this.get('showWaitingModal'))) {
          this.get('showWaitingModal')();
        }
        this.get('_downloadReport').perform();
      }
    }
  });
  _exports.default = _default;
});