define("esis-ui/templates/application-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4YLtYMEY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"appLoadingTagline\"],[8],[0,\"\\n  \"],[7,\"h5\",true],[8],[0,\"Dynamic Health 3 is Loading\"],[9],[0,\" \"],[1,[28,\"fa-icon\",[\"spinner\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/templates/application-loading.hbs"
    }
  });
  _exports.default = _default;
});