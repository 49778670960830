define("esis-ui/components/shared/ember-bootstrap/static-tab/tab-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region initialisation
    initialTab: null,
    model: null,
    // this is optional, this is used by preview panels to detect model change, and pass on the preview model parameters
    hasButton: true,
    withinModal: false,
    screenId: null,
    // the dynamic tab id of the parent screen
    /*
    set to true if there are help topic attached, e.g. session view tabs
     */
    hasHelpTopic: false,
    init() {
      this._super(...arguments);
      this.set('orientation', 'horizontal');
      this.set('uuid', this.get('app').guid());
      this.set('internalActiveTabId', `${this.get('uuid')}_${this.get('initialTab')}`);
      (0, _emberLifeline.scheduleTask)(this, 'render', () => {
        this._switchingTab(this.get('internalActiveTabId'), null);
      });
    },
    //endregion

    //region computed properties
    _innerTabComponentName: Ember.computed('orientation', function () {
      if (this.get('orientation') === 'vertical') {
        return 'shared/ember-bootstrap/static-tab/vertical-tab';
      }
      return 'shared/ember-bootstrap/static-tab/ordered-tab';
    }),
    //endregion

    //region public functions
    /*
    on change event triggers when tab changes
    return newTabId, and previousTabId
     */
    onTabChange: function onTabChange() {},
    activateTab: function activateTab(tabId) {
      (0, _emberLifeline.scheduleTask)(this, 'render', () => {
        if (Ember.isPresent(this.get('internalActiveTabId'))) {
          this._switchingTab(`${this.get('uuid')}_${tabId}`, this.get('internalActiveTabId'));
          this.set('internalActiveTabId', `${this.get('uuid')}_${tabId}`);
        }
      });
    },
    isTabActive: function isTabActive(tabId) {
      return this.get('internalActiveTabId') === `${this.get('uuid')}_${tabId}`;
    },
    //endregion

    //region
    _switchingTab(newId, previousId) {
      const app = this.get('app');
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('screenId'))) {
        if (Ember.isPresent(previousId)) {
          app.popStaticTabElement(this.get('screenId'), previousId);
        } // remove the last selected tab
        app.pushStaticTabElement({
          screenId: this.get('screenId'),
          name: newId.split('_')[1],
          type: constants.screenElementType.staticTab,
          uuid: newId,
          hasHelpTopic: this.get('hasHelpTopic')
        });
      }
    },
    //endregion

    //region action functions
    actions: {
      tabChanged: function tabChanged(newId, previousId) {
        if (this.get('internalActiveTabId') !== newId) {
          this._switchingTab(newId, previousId);
          this.set('internalActiveTabId', newId);
          this.get('onTabChange')(newId, previousId);
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});