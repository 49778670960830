define("esis-ui/components/shared/kendo/report-viewer/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    classNames: ['kendoReport reportTemplate'],
    attributeBindings: ['style'],
    heightOffset: 20,
    /* it must be calculated*/
    widthOffset: 0,
    style: Ember.computed('app.{tabContainerHeight,tabContainerWidth}', 'widthOffset', function () {
      const height = this.get('app.tabContainerHeight') - this.get('heightOffset');
      // let style = `height: ${height}px; width: ${this.get('app.tabContainerWidth')}px;position:relative;`;
      let style = `height: ${height}px;position:relative;`;
      /* is it necessary to set the width? the width is report component container that is most of the time a div with css grid class */
      //style += `width: ${this.get('app.tabContainerWidth') - this.get('diffWidth')}px;`;

      return Ember.String.htmlSafe(style);
    }),
    _report: null,
    options: null,
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      const app = this.get('app');
      this._defaultOptions = {
        serviceUrl: `${constants.apiBaseUrl}/api/reports/`,
        templateUrl: `${constants.apiBaseUrl}/reports/telerikReportViewerTemplate.html?_=${app.guid()}`,
        // Specifies whether the viewer is in interactive or print preview mode.
        // PRINT_PREVIEW - Displays the paginated report as if it is printed on paper. Interactivity is not enabled.
        // INTERACTIVE - Displays the report in its original width and height without paging. Additionally interactivity is enabled.
        viewMode: window.telerikReportViewer.ViewModes.INTERACTIVE,
        // Sets the scale mode of the viewer.
        // Three modes exist currently:
        // FIT_PAGE - The whole report will fit on the page (will zoom in or out), regardless of its width and height.
        // FIT_PAGE_WIDTH - The report will be zoomed in or out so that the width of the screen and the width of the report match.
        // SPECIFIC - Uses the scale to zoom in and out the report.
        scaleMode: window.telerikReportViewer.ScaleModes.SPECIFIC,
        // Zoom in and out the report using the scale
        // 1.0 is equal to 100%, i.e. the original size of the report
        scale: 1.0,
        authenticationToken: this.getCurrentAccessToken(),
        parametersAreaVisible: false,
        documentMapVisible: false,
        exportEnd: this._onExportEnd
      };
    },
    _onExportEnd(e, args) {
      /*
      https://www.telerik.com/account/support-tickets/view-ticket/1402134
       */
      // console.log(args.url);
      // console.log(args.format);
      // console.log(args.handled);
      // console.log(args.windowOpenTarget);
      args.windowOpenTarget = '_blank';
    },
    refresh(reportSource) {
      const thisReport = this.get('_report');
      if (Ember.isPresent(thisReport)) {
        thisReport.reportSource(reportSource);
        //setting the HTML5 Viewer's reportSource, causes a refresh automatically
        //if you need to force a refresh for other case, use:
        //viewer.refreshReport();
      } else {
        let mergedOptions = {};
        (0, _lodash.merge)(mergedOptions, this.get('_defaultOptions'), this.get('options'), {
          reportSource: reportSource
        });
        const report = this.$().telerik_ReportViewer(mergedOptions).data('telerik_ReportViewer');
        this.set('_report', report);
      }
    }
  });
  _exports.default = _default;
});