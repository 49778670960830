define("esis-ui/components/shared/nullable-boolean-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    wrap: false,
    checkboxGroupClass: Ember.computed('formElement.validation', function () {
      if (this.get('formElement.validation') === 'error') {
        return Ember.String.htmlSafe('form-control is-invalid');
      }
      return Ember.String.htmlSafe('');
    }),
    actions: {
      _onChange(type, value) {
        let result;
        if (value) {
          result = value && type;
        } else {
          result = null;
        }
        if (!(0, _lodash.isEmpty)(this.get('onChange'))) {
          this.get('onChange')(result);
        }
      }
    }
  });
  _exports.default = _default;
});