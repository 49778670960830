define("esis-ui/components/areas/registration/nz/list/nz-registration-discharge-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    confirmBtnLabel: 'Discharge',
    onConfirmDischarge: function onConfirmDischarge() {},
    _reset() {
      this.setProperties({
        _dischargeRequest: null,
        _selectedBranchCode: null
      });
    },
    show(registrations) {
      this._reset();
      this.set('_registrations', registrations);
      this.set('_dischargeRequest', this.createModel('nz-discharge-fs-request'));
      if (registrations.length === 1) {
        this.set('_singleReg', registrations[0]);
        this._loadBranchCodePicklist.perform(this.get('_singleReg'));
      }
      this.get('_dialog').show();
    },
    _loadBranchCodePicklist: (0, _emberConcurrency.task)(function* (model) {
      try {
        //BRANCH CODES
        const branchCodePicklist = yield this.get('picklistRepo.loadNzBranchCode').linked().perform(model.spgId);
        this.set('_branchCodeList', (0, _lodash.sortBy)(branchCodePicklist, 'title'));
        this.set('_dischargeRequest.hasBranchCode', !(0, _lodash.isEmpty)(branchCodePicklist));
        if (!(0, _lodash.isNil)(model.branchCode)) {
          this.set('_selectedBranchCode', (0, _lodash.find)(this.get('_branchCodeList'), {
            title: model.branchCode
          }));
          this.set('_dischargeRequest.branchCode', this.get('_selectedBranchCode.title'));
          this.set('_dischargeRequest.branchGuid', this.get('_selectedBranchCode.value'));
        }
      } catch (error) {
        this.get('logger').logError('nz-fs-edit-modal._loadBranchCodePicklist', error);
      }
    }),
    _dischargeRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show('Please be patient while we are submitting your request...');

        //discharge request model
        let regNumbers = [];
        (0, _lodash.forEach)(this.get('_registrations'), item => {
          regNumbers.pushObject({
            registrationNumber: item.registrationNumber,
            spgId: item.spgId,
            branchGuid: this.get('_dischargeRequest.branchGuid')
          });
        });
        const dischargeRequest = {
          registrationNumbers: regNumbers,
          esisEntityGuid: this.get('credential.currentBusinessEntityGuid')
        };
        //

        let response = yield this.get('registrationRepo.dischargeNzFs').linked().perform(dischargeRequest);
        this.get('_waitingModal').hide();

        //discharge result handling
        this.get('_dischargeResultModal').show(response.details);
        this.get('_dialog').hide();
      } catch (e) {
        this.get('logger').logError('nz-registration-discharge-modal._dischargeRegistrationTask', e);
        this.get('_waitingModal').hide();
      }
    }),
    actions: {
      _onChangeBranchCode(selected) {
        this.set('_selectedBranchCode', selected);
        this.set('_dischargeRequest.branchGuid', selected.value);
        this.set('_dischargeRequest.branchCode', selected.title);
      },
      _onConfirm: function _onConfirm() {
        if (this.get('_dischargeRequest').validate()) {
          this._dischargeRegistrationTask.perform();
        }
      },
      _onConfirmResult(hasAnyFailed) {
        this.onConfirmDischarge(hasAnyFailed);
      }
    }
  });
  _exports.default = _default;
});