define("esis-ui/components/areas/shared/au/au-get-action-type-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: '',
    _actionType: null,
    show() {
      this._reset();
      this.get('_dialog').show();
    },
    hide() {
      this.get('_dialog').hide();
    },
    _reset() {
      this.setProperties({
        _actionType: null
      });
    },
    actions: {
      _onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('_actionType'));
        }
      },
      _onTypeChange(value) {
        this.set('_actionType', value);
      }
    }
  });
  _exports.default = _default;
});