define("esis-ui/components/shared/kendo/grid/grid-remote-ds/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xO3l7/r2",
    "block": "{\"symbols\":[\"option\",\"&default\"],\"statements\":[[14,2],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"style\",\"display: none\"],[8],[0,\"\\n  \"],[7,\"ul\",true],[11,\"id\",[22,\"_contextMenuId\"]],[10,\"class\",\"contextMenusStyle \"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"contextMenuOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[11,\"id\",[23,1,[\"id\"]]],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"fa-icon\",[[23,1,[\"icon\"]]],[[\"class\"],[[23,1,[\"iconClass\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/kendo/grid/grid-remote-ds/template.hbs"
    }
  });
  _exports.default = _default;
});