define("esis-ui/components/areas/search/au/view/shared/au-search-result-filter-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Apply Search Filter',
    filterCriteria: null,
    onConfirm: function onConfirm() {},
    init() {
      this._super(...arguments);
    },
    _reset() {},
    show(filterModel) {
      this._reset();
      this.set('filterCriteria', filterModel);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm: function _onConfirm() {
        this.onConfirm();
      }
    }
  });
  _exports.default = _default;
});