define("esis-ui/components/areas/spg/au/wizard/au-spg-wizard/component", ["exports", "esis-ui/components/areas/spg/au/au-spg-view-page-base", "lodash", "ember-concurrency"], function (_exports, _auSpgViewPageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSpgViewPageBase.default.extend({
    init() {
      this._super(...arguments);
      this._loadModel.perform();
      this.steps = [];
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Information',
        stepId: this.newGuid(),
        sortOrder: 1,
        stepComponentName: 'areas/spg/au/wizard/au-spg-information-step'
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Service Address',
        stepId: this.newGuid(),
        sortOrder: 2,
        stepComponentName: 'areas/spg/au/wizard/au-spg-service-address-step'
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Secured Parties',
        stepId: this.newGuid(),
        sortOrder: 3,
        stepComponentName: 'areas/spg/au/wizard/au-spg-secured-parties-step'
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Review',
        stepId: this.newGuid(),
        sortOrder: 4,
        stepComponentName: 'areas/spg/au/wizard/au-spg-review-step'
      }, 'wizard-step'));
      this._options = {
        positionNavigationBtn: "aligned",
        positionSaveBtn: "top",
        positionSubmitBtn: "top",
        showSubmitBtn: true,
        showSaveBtn: true,
        showNavigationBtn: true
      };
    },
    //region COMPUTED PROPERTY
    _wizardTitle: Ember.computed('model.{esisId,securedPartyGroupName,isExisting}', function () {
      if (!(0, _lodash.isNil)(this.get('model.isExisting')) && this.get('model.isExisting')) {
        return `${this.get('model.securedPartyGroupName')} (${this.get('model.esisId')})`;
      }
      return 'New Secured Party Group';
    }),
    //endregion

    //region METHODS
    _createAddressModel() {
      let address = this.createModel('address');
      address.set('countryCode', 'AU');
      return address;
    },
    _createSpgModel(response) {
      this.set('model', this.mapJsonObjectToModel(response, 'secured-party-group'));
      if (!(0, _lodash.isEmpty)(response.addressForServiceMailingAddress)) {
        let addressModel = this.mapJsonObjectToModel(response.addressForServiceMailingAddress, 'address');
        this.set('model.addressForServiceMailingAddress', addressModel);
      } else {
        this.set('model.addressForServiceMailingAddress', this.createModel('address'));
      }
      if (!(0, _lodash.isEmpty)(response.addressForServicePhysicalAddress)) {
        let addressModel = this.mapJsonObjectToModel(response.addressForServicePhysicalAddress, 'address');
        this.set('model.addressForServicePhysicalAddress', addressModel);
      } else {
        this.set('model.addressForServicePhysicalAddress', this.createModel('address'));
      }
      this.set('model.securedParties', []);
      if (!(0, _lodash.isEmpty)(response.securedParties)) {
        (0, _lodash.forEach)(response.securedParties, item => {
          let sp = this.mapJsonObjectToModel(item, 'secured-party');
          if (!(0, _lodash.isEmpty)(item.mailingAddress)) {
            sp.set('mailingAddress', this.mapJsonObjectToModel(item.mailingAddress, 'address'));
          }
          this.get('model.securedParties').pushObject(sp);
        });
      }
    },
    //endregion

    //region TASKS
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('model', this.createModel('secured-party-group'));
        this.get('model').setProperties({
          'addressForServiceMailingAddress': this._createAddressModel(),
          'addressForServicePhysicalAddress': this._createAddressModel(),
          'securedParties': []
        });
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          let result = yield this.get('spgRepo.loadSpgModel').perform(this.get('tabModel.p0'));
          this._createSpgModel(result);
        } else {
          const hasP1 = Ember.isPresent(this.get('tabModel.p1')) && this.get('tabModel.p1') !== this.emptyGuid();
          const entityGuid = hasP1 ? this.get('tabModel.p1') : this.get('credential.currentBusinessEntity.value');
          this.get('model').setProperties({
            'entityGuid': entityGuid,
            'addressForServiceMailingAddress': this._createAddressModel(),
            'addressForServicePhysicalAddress': this._createAddressModel(),
            'securedParties': [],
            'b2GAccount': null,
            'isActive': true,
            'status': this.get('app-constants.statusValue.creating'),
            'spgType': this.get('app-constants.securedPartyGroupType.standard'),
            'errors': [],
            'itemDeleted': false,
            'isGoniRequired': true
          });
        }
        this.get('model').saveModel();
      } catch (error) {
        this.get('logger').logError('au-spg-wizard._loadModel', error);
      }
    }),
    //endregion

    actions: {
      submit: function submit(model) {
        let submitSpgTask = this.get('validateAndSaveAndSubmitSpg');
        let timeoutTask = this.get('runTimeout');
        let timeoutTaskInstance = timeoutTask.perform('submitSpgTaskInstance');
        this.set('saveTimeoutTaskInstance', timeoutTaskInstance);
        let submitSpgTaskInstance = submitSpgTask.perform(model, true);
        this.set('submitSpgTaskInstance', submitSpgTaskInstance);
      },
      save: function save(model) {
        if (!Ember.isPresent(model.securedPartyGroupName)) {
          model.errors.set('securedPartyGroupName', 'Name cannot be blank');
        } else {
          let submitSpgTask = this.get('saveSpg');
          let timeoutTask = this.get('runTimeout');
          let timeoutTaskInstance = timeoutTask.perform('submitSpgTaskInstance');
          this.set('saveTimeoutTaskInstance', timeoutTaskInstance);
          let submitSpgTaskInstance = submitSpgTask.perform(model);
          this.set('submitSpgTaskInstance', submitSpgTaskInstance);
        }
      }
    }
  });
  _exports.default = _default;
});