define("esis-ui/components/areas/admin/contract-type/nz/view/nz-edit-collateral-profile-defualt-modal/component", ["exports", "esis-ui/components/areas/admin/contract-type/nz/view/nz-collateral-profile-page-base", "lodash", "ember-concurrency"], function (_exports, _nzCollateralProfilePageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzCollateralProfilePageBase.default.extend({
    //region INITIALIZATION ---
    _collateralTypeList: null,
    _selectedCollateralType: null,
    _collateralTypeModel: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null
      });
    },
    //endregion

    //region COMPUTER PROPERTIES
    _hasCollTypeDescription: Ember.computed('_selectedCollateralType', function () {
      const constants = this.get('constants');
      return this.get('_selectedCollateralType.value') !== constants.nzFsCollateralTypes.acft && this.get('_selectedCollateralType.value') !== constants.nzFsCollateralTypes.mv;
    }),
    _collateralDescRequired: Ember.computed('_collateralTypeModel.collateralTypeId', function () {
      const constants = this.get('constants');
      return this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.ls || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.crops || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.oth || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.dt || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.cp || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.is || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.ni || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.cash || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.int || this.get('_collateralTypeModel.collateralTypeId') === constants.nzFsCollateralTypes.aappe;
    }),
    //endregion

    //region TASK
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let collateralTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.nzFsCollateralType'));
        this.set('_collateralTypeList', collateralTypePicklist);
        const option = (0, _lodash.find)(this.get('_collateralTypeList'), {
          "value": this.get('_collateralTypeModel.collateralTypeId')
        });
        if (!(0, _lodash.isEmpty)(option)) {
          this.set('_selectedCollateralType', option);
        }
      } catch (error) {
        this.get('logger').logError('nz-edit-collateral-profile-default-modal._loadPicklist', error);
      }
    }),
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _collateralTypeList: null,
        _selectedCollateralType: null,
        _collateralTypeModel: null
      });
    },
    _createModel(model) {
      this.mapObject(model, this.set('model', this.createModel('nz-collateral-profile-request')));
      this.set('model.defaults', []);
      (0, _lodash.forEach)(model.defaults, item => {
        let collateralProfileDefault = this.mapObject(item, this.createModel('nz-collateral-profile-default'));
        this.get('model.defaults').pushObject(collateralProfileDefault);
      });
      this.get('model').saveModel();
      if ((0, _lodash.isNil)(model.selectedIndex)) {
        this.set('_collateralTypeModel', this.createModel('nz-collateral-profile-default'));
      } else if (!(0, _lodash.isEmpty)(this.get('model.defaults'))) {
        let item = this.get('model.defaults')[model.selectedIndex];
        this.set('_collateralTypeModel', this.mapObject(item, this.createModel('nz-collateral-profile-default')));
        this.set('_collateralTypeModel.selectedIndex', model.selectedIndex);
      }
      this.get('_collateralTypeModel').save();
    },
    show(model) {
      this._reset();
      this._createModel(model);
      this._loadPicklist.perform();
      this.get('_dialog').show();
    },
    //endregion

    //region ACTIONS
    actions: {
      _onChangeCollateralType(selected) {
        const constants = this.get('constants');
        this.set('_selectedCollateralType', selected);
        this.set('_collateralTypeModel.collateralTypeId', selected.value);
        if (this.get('_selectedCollateralType.value') === constants.nzFsCollateralTypes.acft || this.get('_selectedCollateralType.value') === constants.nzFsCollateralTypes.mv) {
          this.set('_collateralTypeModel.collateralTypeDescription', '');
        }
      },
      _onConfirm() {
        const collateralTypeModel = this.get('_collateralTypeModel');
        if (collateralTypeModel.validate()) {
          if ((0, _lodash.isNil)(collateralTypeModel.selectedIndex)) {
            this.get('model.defaults').pushObject(collateralTypeModel);
          } else {
            this.mapObject(collateralTypeModel, this.get('model.defaults')[collateralTypeModel.selectedIndex]);
          }
          this.get('saveCollateralProfile').perform();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});