define("esis-ui/models/security-role", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    roleName: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    userCreated: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    userEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    permissions: (0, _modelBase.attr)(null, 'array'),
    saveModel: function saveModel() {
      this.save();
      const permissions = this.get('permissions');
      if (!(0, _lodash.isEmpty)(permissions)) {
        permissions.forEach(function (item) {
          item.save();
        });
      }
    },
    init() {
      this._super(...arguments);
      this.validations = {
        permissions: {
          custom: {
            validation: function validation(key, value, model) {
              const perm = model.get('permissions');
              return Ember.isPresent(perm.findBy('selected', true));
            },
            message: 'At least one permission should be selected'
          }
        },
        roleName: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Role Name must be specified'
          }
        }
      };
    }
  });
  _exports.default = _default;
});