define("esis-ui/components/areas/shared/buttons/edit-button/component", ["exports", "esis-ui/components/shared/buttons/button-base"], function (_exports, _buttonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _buttonBase.default.extend({
    type: 'primary'
  });
  _exports.default = _default;
});