define("esis-ui/components/areas/registration/au/view/au-verified-grantor-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Verified Grantor Organisation',
    cancelBtnLabel: 'Close',
    init() {
      this._super(...arguments);
    },
    show(grantor, verifiedGrantor) {
      this.set('_grantor', grantor);
      this.set('_verifiedGrantor', verifiedGrantor);
      this.get('_dialog').show();
    },
    actions: {
      _updateGrantorOrgName: function _updateGrantorOrgName() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('_grantor'));
        }
      }
    }
  });
  _exports.default = _default;
});