define("esis-ui/components/areas/report/au/view/grantor-org-noname-by-entity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "g8FuRfH2",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n  \"],[7,\"label\",true],[10,\"class\",\"field-label\"],[8],[0,\"Business Entity\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchField\",\"allowClear\",\"searchEnabled\",\"renderInPlace\",\"triggerClass\",\"onChange\"],[[24,[\"_businessEntitiesList\"]],[24,[\"_selectedEntity\"]],\"organisationName\",false,true,true,\"powerSelectDropdown\",[28,\"action\",[[23,0,[]],\"_entityChanged\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,1,[\"organisationName\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[7,\"small\",true],[10,\"class\",\"validationErrorMessage\"],[8],[1,[22,\"entityErrorMsg\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-md-6\"],[8],[0,\"\\n      \"],[1,[28,\"shared/buttons/action-button\",null,[[\"title\",\"type\",\"class\",\"onClick\"],[\"Download Excel Report\",\"primary\",\"shortDownloadBtn\",[28,\"action\",[[23,0,[]],\"_downloadReport\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/report/au/view/grantor-org-noname-by-entity/template.hbs"
    }
  });
  _exports.default = _default;
});