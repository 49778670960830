define("esis-ui/components/shared/kendo/spreadsheet-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nOFeyGgd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[1,[28,\"shared/waiting-modal\",null,[[\"registerAs\"],[[24,[\"_waitModal\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/kendo/spreadsheet-view/template.hbs"
    }
  });
  _exports.default = _default;
});