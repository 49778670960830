define("esis-ui/components/areas/shared/buttons/au-verify-organization-button/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    classNames: ['d-inline-block'],
    model: null,
    title: 'Verify',
    smallWidth: true,
    small: true,
    init() {
      this._super(...arguments);
    },
    _verify: (0, _emberConcurrency.task)(function* () {
      const constants = this.get('constants');
      let orgValidationRequest = this.createModel('company-verification-request');
      orgValidationRequest.setProperties({
        organisationNumber: Ember.isPresent(this.get('model.organisationNumber')) ? this.get('model.organisationNumber').trim() : '',
        abnNumber: Ember.isPresent(this.get('model.abnNumber')) ? this.get('model.abnNumber').trim() : '',
        acnNumber: Ember.isPresent(this.get('model.acnNumber')) ? this.get('model.acnNumber').trim() : '',
        arbnNumber: Ember.isPresent(this.get('model.arbnNumber')) ? this.get('model.arbnNumber').trim() : '',
        arsnNumber: Ember.isPresent(this.get('model.arsnNumber')) ? this.get('model.arsnNumber').trim() : '',
        organisationName: Ember.isPresent(this.get('model.organisationName')) ? this.get('model.organisationName').trim() : ''
      });
      this.set('orgValidationRequest', orgValidationRequest);
      if (this.get('orgValidationRequest.hasValueToVerify')) {
        try {
          let result = yield this.get('validationRepo.validateOrganisation').perform(this.get('orgValidationRequest'));
          if (result.companyRegistrationFound) {
            if (!(0, _lodash.isEmpty)(this.get('_companyInfoModal'))) {
              this.get('_companyInfoModal').show(result.verifiedCompanyInfo);
            }
            let orgNumber = this.get('orgValidationRequest.organisationNumber');
            let isAcnInput = orgNumber.length <= 9;
            let orgType = null;
            if (result.verifiedCompanyInfo.hasAcn && (result.verifiedCompanyInfo.acnIsCurrent || isAcnInput)) {
              orgNumber = result.verifiedCompanyInfo.acnNumber;
              orgType = constants.organisationNumberType.ACN;
            } else if (result.verifiedCompanyInfo.hasArbn && (result.verifiedCompanyInfo.acnIsCurrent || isAcnInput)) {
              orgNumber = result.verifiedCompanyInfo.arbnNumber;
              orgType = constants.organisationNumberType.ARBN;
            } else if (result.verifiedCompanyInfo.hasArsn && (result.verifiedCompanyInfo.acnIsCurrent || isAcnInput)) {
              orgNumber = result.verifiedCompanyInfo.arsnNumber;
              orgType = constants.organisationNumberType.ARSN;
            } else if (result.verifiedCompanyInfo.hasAbn && !isAcnInput) {
              orgNumber = result.verifiedCompanyInfo.abnNumber;
              orgType = constants.organisationNumberType.ABN;
            }
            result.verifiedCompanyInfo.preferredOrganisationNumber = orgNumber;
            result.verifiedCompanyInfo.preferredOrganisationNumberType = orgType;
            if (!(0, _lodash.isEmpty)(this.get('onVerified'))) {
              this.get('onVerified')(result.verifiedCompanyInfo);
            }
          } else {
            if (Ember.isPresent(result.message)) {
              this.notifyError(result.message);
            } else {
              this.notifyInfo('Cannot find any company that matches the information provided');
            }
          }
        } catch (error) {
          this.get('logger').logError('fail to verify organisation', error);
          this.notifyError(`failed to verify organisation, please try again`);
        }
      }
    }),
    actions: {
      _verify() {
        this.get('_verify').perform();
      }
    }
  });
  _exports.default = _default;
});