define("esis-ui/components/areas/login/logout-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "MW6ieGnf",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"danger\",[28,\"action\",[[23,0,[]],\"logout\"],null]]],{\"statements\":[[0,\"Logout\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/login/logout-button/template.hbs"
    }
  });
  _exports.default = _default;
});