define("esis-ui/components/shared/kendo/grid/grid-local-ds/component", ["exports", "esis-ui/components/shared/kendo/grid/grid-base", "ember-lifeline"], function (_exports, _gridBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _gridBase.default.extend({
    _contextMenuIsDone: false,
    init() {
      this._super(...arguments);
      const self = this;
      const logger = self.get('logger');
      self._defaultOptions.height = null;
      self._defaultOptions.dataSource = {
        transport: {
          read: function read(operation) {
            const data = operation.data.data || [];
            // this is emulating delay of a ajax call. this is a hack until found better solution
            (0, _emberLifeline.runTask)(self, () => {
              operation.success(data);
            }, 250);
          }
        },
        requestEnd: function requestEnd() {
          /*
          setup context action menu is any
           */
          (0, _emberLifeline.runTask)(self, '_renderContextMenu', 250);
          /*
          select the previous selected row if available, if row not found, no row is selected.
          if selectedGuid is null, then select the first row
           */
          (0, _emberLifeline.runTask)(self, '_kendoGridInitSelectRow', 500);
        },
        sort: [{
          field: "id",
          dir: "desc"
        }],
        pageSize: 999999999,
        error: function error(e) {
          logger.logError('Local data kendo grid error : ', e);
        }
      };
    },
    loadData(data, initialSelectedGuid) {
      const grid = this.get('_grid');
      if (Ember.isPresent(grid)) {
        if (Ember.isPresent(initialSelectedGuid)) {
          this.set('selectedGuid', initialSelectedGuid);
        }
        grid.dataSource.read({
          data: data
        });
      }
    },
    sort(order) {
      const grid = this.get('_grid');
      if (Ember.isPresent(grid)) {
        grid.dataSource.sort(order);
      }
    }
  });
  _exports.default = _default;
});