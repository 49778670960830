define("esis-ui/components/shared/ember-bootstrap/popover/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: '',
    //--- INITIALIZATION ---

    init() {
      this._super(...arguments);
    }
  });
  _exports.default = _default;
});