define("esis-ui/components/areas/search/nz/view/nz-fs-search-view/component", ["exports", "esis-ui/components/areas/registration/nz/nz-registration-base", "lodash"], function (_exports, _nzRegistrationBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzRegistrationBase.default.extend({
    //region INITIALIZATION ---
    isAmend: false,
    //nz-registration-base
    hasCollateralShowCount: true,
    //nz-registration-base
    init() {
      this._super(...arguments);
      this._loadModel();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _securedPartiesLastIndex: Ember.computed('model.securedParties.@each', function () {
      return this.get('model.securedParties').length - 1;
    }),
    //endregion

    //region METHODS
    _loadModel() {
      if (!(0, _lodash.isNil)(this.get('tabModel.options'))) {
        this._mapModel(this.get('tabModel.options'));
        this.updateTabProperty('title', `${this.get('model').fsNumber}`);
      }
    },
    _mapModel(response) {
      const registration = this._mapApiRegistrationResponseToModel(response);
      this.setProperties({
        _subordinations: response.subordinations,
        model: registration
      });
      this.get('model').setProperties({
        securedParties: response.securedParties,
        renewalDate: response.renewalDate,
        fsVersion: response.registrationVersion,
        fsNumber: response.registrationNumber,
        dischargeDate: response.dischargeDate,
        registrationDate: response.registrationDate,
        lastChangeDate: response.lastChangeDate,
        collateralCount: response.collateralCount,
        debtorCount: response.debtorCount,
        isAmend: false
      });
      this.get('model').saveModel();
      this._filterCollPicklistInCollDisplay();
      this._filterCollateral();
    }
    //endregion
  });
  _exports.default = _default;
});