define("esis-ui/components/shared/ember-bootstrap/modal/modal-dialog-base", ["exports", "esis-ui/components/shared/shared-component-base", "jquery"], function (_exports, _sharedComponentBase, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //--- INITIALIZATION ---
    name: 'dlg',
    model: null,
    showDialog: false,
    cancelBtnLabel: 'Cancel',
    confirmBtnLabel: 'Ok',
    modalTitle: '',
    backdropClose: false,
    keyboard: true,
    closeButton: true,
    headerCloseButton: false,
    confirmButton: true,
    size: '',
    // other possible value sm , lg
    isGlobal: false,
    _modalLevel: 1,
    submitForm: true,
    renderInPlace: true,
    customClass: '',
    modalScrollable: false,
    onConfirm: function onConfirm() {},
    onCancel: null,
    /*
    set this attribute to true if has help topic content
     */
    hasHelpTopic: false,
    init() {
      this._super(...arguments);
      this._screenElementType = this.get('constants.screenElementType.modal');
      this._uuid = this.get('app').guid();
    },
    //region COMPUTED PROPS
    _levelClass: Ember.computed('_modalLevel', 'customClass', 'modalScrollable', function () {
      return `level-${this.get('_modalLevel')} ${this.get('customClass')} ${this.get('modalScrollable') ? "modalScrollable" : ""}`;
    }),
    //endregion

    //region METHODS
    _setDestinationElement() {
      let element = null;
      if (this.get('renderInPlace') === true) {
        /*
        Destination element is not used when render in place is true, just make see implementation of ember-bootstrap
        for Ember-Bootstrap v2.5, there is a change with render in place and destinationElement
        https://github.com/kaliber5/ember-bootstrap/blob/master/addon/templates/components/common/bs-modal.hbs
        it uses maybe-in-element destinationElement _renderInplace
        and ember bootstrap implementation means only when render in place is set to false the destinationElement property is effective
        see https://github.com/DockYard/ember-maybe-in-element
         so we are setting the renderInPlace for ember-bootstrap to always true, and use destinationElement to control if attaching to wormhole (root div) or tab-container (dyanmic tab div)
         REF : https://github.com/kaliber5/ember-bootstrap/blob/master/addon/components/base/bs-modal.js
         */
        element = null;
      }
      if (this.get('isGlobal') === true) {
        element = (0, _jquery.default)('#ember-bootstrap-wormhole')[0];
      }
      // The modal is displayed within the current dynamic tab screen -> page base (e.g session-view). The css must go to x-view styles file
      if (this.get('app.currentDynamicTabId')) {
        let dynamicTab = this.get('app').getRootScreen();
        element = (0, _jquery.default)(`#${dynamicTab.uuid} .tab-content-container div.page-base`)[0];
      }
      this.set('_destinationElement', element);
    },
    show: function show() {
      const app = this.get('app');
      app.pushScreenElement({
        name: this.get('name'),
        type: this._screenElementType,
        uuid: this.get('_uuid'),
        hasHelpTopic: this.get('hasHelpTopic')
      });

      /* It is necessary to calculate destinationElement when modal is going to be open, otherwise modal would be
      attached to a wrong tab (when renderInPlace=false) because the destinationElement is not recalculate by switching tabs.
      app.currentDynamicTabId cannot be watched nigher*/
      this._setDestinationElement();
      const displayedModal = app.getScreenStack(this._screenElementType);
      this.set('_modalLevel', displayedModal.length);
      if (!this.get('showDialog')) {
        this.set('showDialog', true);
      }
    },
    hide: function hide() {
      const app = this.get('app');
      app.popScreenElement(this._screenElementType);
      if (this.get('showDialog')) {
        this.set('showDialog', false);
      }
    },
    //endregion

    // --- ACTIONS ---
    actions: {
      confirm: function confirm() {
        this.get('onConfirm')();
        this.hide();
      },
      cancel: function cancel() {
        if (Ember.isPresent(this.get('onCancel'))) {
          this.get('onCancel')();
        } else {
          this.hide();
        }
      }
    }
  });
  _exports.default = _default;
});