define("esis-ui/components/areas/admin/system-user/view/change-password-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _loadModel(username) {
      let requestModel = this.createModel('change-password-request');
      requestModel.setProperties({
        username: username
      });
      this.set('_requestModel', requestModel);
      this.get('_requestModel').save();
    },
    _reset: function _reset() {
      this.setProperties({
        _requestModel: null
      });
    },
    show(username) {
      this._reset();
      this._loadModel(username);
      this.get('_dialog').show();
    },
    //endregion

    //region TASK
    _changeCredAccessKey: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.changeCredAccessKey').perform(this.get('_requestModel'));
        this.notifySuccess('User password has been reset successfully.');
        this.get('_dialog').hide();
        if (!(0, _lodash.isEmpty)(this.get('onSave'))) {
          this.get('onSave')();
        }
      } catch (error) {
        this.get('logger').logError('system-user-change-cred-access-key-modal._changeCredAccessKey', error);
        this.set('_requestModel.displayException', true);
        this.set('_requestModel.validatErrorMessage', error.errorMessage);
      }
    }),
    //endregion
    //region ACTIONS
    actions: {
      _onConfirm: function _onConfirm() {
        let validated = true;
        if (Ember.isBlank(this.get('_requestModel.newCredAccessKey')) || Ember.isBlank(this.get('_requestModel.confirmCredAccessKey'))) {
          this.set('_requestModel.credAccessKeyError', 'Password cannot be blank');
          validated = false;
        } else if (this.get('_requestModel.newCredAccessKey') !== this.get('_requestModel.confirmCredAccessKey')) {
          this.set('_requestModel.credAccessKeyError', 'Password does not match');
          validated = false;
        }
        if (validated) {
          this._changeCredAccessKey.perform();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});