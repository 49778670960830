define("esis-ui/components/areas/registration/au/wizard/au-registration-wizard-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "136dhzvc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mx-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"wizardContainer\"],[8],[0,\"\\n        \"],[1,[28,\"shared/ember-bootstrap/wizard-control\",null,[[\"steps\",\"model\",\"onSubmit\",\"onSave\",\"options\",\"wizardTitle\"],[[24,[\"steps\"]],[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"_submitRegistration\",[24,[\"model\"]]],null],[28,\"action\",[[23,0,[]],\"_saveDraftRegistration\",[24,[\"model\"]]],null],[24,[\"_options\"]],[24,[\"_wizardTitle\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"],[4,\"if\",[[24,[\"credential\",\"currentUser\",\"isCitecUser\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"areas/shared/waiting-modal\",null,[[\"registerAs\",\"renderInPlace\"],[[24,[\"_waitingModal\"]],false]]],false],[0,\"\\n    \"],[1,[28,\"areas/shared/info-modal\",null,[[\"registerAs\",\"onConfirm\",\"renderInPlace\",\"onCancel\",\"modalTitle\"],[[24,[\"_citecFeeModal\"]],[28,\"action\",[[23,0,[]],\"_onConfirmCitecFee\"],null],false,[28,\"action\",[[23,0,[]],\"_onCancelCitec\"],null],[24,[\"_citecFeeModalTitle\"]]]]],false],[0,\"\\n    \"],[1,[28,\"areas/shared/info-modal\",null,[[\"registerAs\",\"confirmBtnLabel\",\"onConfirm\",\"cancelBtnLabel\",\"renderInPlace\",\"onCancel\"],[[24,[\"_citecTopUpModal\"]],\"Top Up\",[28,\"action\",[[23,0,[]],\"_onCitecTopup\"],null],\"Close\",false,[28,\"action\",[[23,0,[]],\"_onCancelCitecTopup\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"areas/shared/info-modal\",null,[[\"registerAs\",\"onConfirm\",\"onCancel\",\"renderInPlace\"],[[24,[\"_citecSaveRegModal\"]],[28,\"action\",[[23,0,[]],\"_onCitecSaveRegistrationOnCancel\"],null],[28,\"action\",[[23,0,[]],\"_onCitecDiscardRegistrationOnCancel\"],null],false]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/au/wizard/au-registration-wizard-container/template.hbs"
    }
  });
  _exports.default = _default;
});