define("esis-ui/components/areas/report/au/view/registration-by-entity/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION
    _recursive: false,
    _includeToken: false,
    _singleLine: false,
    _periodValidationError: '',
    init() {
      this._super(...arguments);
      this.setProperties({
        _recursive: false,
        _singleLine: false,
        _includeToken: false,
        _selectedFilter: this.get('constants.registrationReportStatusFilterOptions')[0],
        _startDate: new Date(2012, 0, 30),
        _endDate: new Date()
      });
    },
    //endregion

    //region TASKS
    _downloadReport: (0, _emberConcurrency.task)(function* () {
      if (Ember.isPresent(this.get('showWaitingModal'))) {
        this.get('showWaitingModal')();
      }
      let args = {
        statusFilter: this.get('_selectedFilter').id,
        startTime: this.get('_startDate'),
        endTime: this.get('_endDate'),
        entityGuid: this.get('credential.currentBusinessEntity.value'),
        recursive: this.get('_recursive'),
        showToken: this.get('_includeToken') && this.get('credential.hasViewEncryptedDataPermission'),
        grantorInSingleLine: this.get('_singleLine')
      };
      try {
        yield this.get('reportRepo.downloadRegistrationByEntityExtractReport').linked().perform(args);
        if (Ember.isPresent(this.get('hideWaitingModal'))) {
          this.get('hideWaitingModal')();
        }
        this.notifySuccess('Registration extract report has been downloaded');
      } catch (error) {
        this.notifyError(`Failed to download file - ${error.payload.message}`);
        if (Ember.isPresent(this.get('hideWaitingModal'))) {
          this.get('hideWaitingModal')();
        }
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _downloadReport: function _downloadReport() {
        let startDateValid = !(0, _lodash.isNil)(this.get('_startDate'));
        let endDateValid = !(0, _lodash.isNil)(this.get('_endDate'));
        if (this.get('_startDate') > this.get('_endDate')) {
          this.set('_periodValidationError', 'Start Date cannot be later than End Date');
        } else if (startDateValid && endDateValid) {
          this.set('_periodValidationError', '');
          this.get('_downloadReport').perform();
        }
      },
      _onStartDateChanged: function _onStartDateChanged(value) {
        this.set('_startDate', value);
        let startDateValid = !(0, _lodash.isNil)(this.get('_startDate'));
        if (startDateValid) {
          this.set('_startDateErrorMsg', '');
        } else {
          this.set('_startDateErrorMsg', 'Please enter a valid date');
        }
      },
      _onEndDateChanged: function _onEndDateChanged(value) {
        this.set('_endDate', value);
        let endDateValid = !(0, _lodash.isNil)(this.get('_endDate'));
        if (endDateValid) {
          this.set('_endDateErrorMsg', '');
        } else {
          this.set('_endDateErrorMsg', 'Please enter a valid date');
        }
      },
      _filterByChanged: function _filterByChanged(option) {
        this.set('_selectedFilter', option);
      }
    }
    //endregion
  });
  _exports.default = _default;
});