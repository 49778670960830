define("esis-ui/models/file-upload-detail-digest", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    esisId: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    fileName: (0, _modelBase.attr)(),
    uploadSourceTitle: (0, _modelBase.attr)(),
    fileTypeTitle: (0, _modelBase.attr)(),
    status: (0, _modelBase.attr)(),
    statusTitle: (0, _modelBase.attr)(),
    statusMessage: (0, _modelBase.attr)(),
    usernameEdited: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    canDelete: (0, _modelBase.attr)(),
    applyBulkChargeDisplay: (0, _modelBase.attr)(),
    fileType: (0, _modelBase.attr)(),
    recordCount: (0, _modelBase.attr)(),
    errorCount: (0, _modelBase.attr)(),
    showExceptionDetail: (0, _modelBase.attr)(),
    hasDetailedExceptionData: (0, _modelBase.attr)(),
    displayException: Ember.computed.alias('showExceptionDetail')
  });
  _exports.default = _default;
});