define("esis-ui/components/areas/registration/nz/wizard/nz-registration-debtor-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash"], function (_exports, _wizardStepBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    onActivate() {},
    _debtorsLastIndex: Ember.computed('model.debtors.@each', function () {
      return this.get('model.debtors').length - 1;
    }),
    _hasDebtor: Ember.computed('model.debtors.@each', function () {
      return !(0, _lodash.isEmpty)(this.get('model.debtors'));
    }),
    validateStep() {
      let model = this.get('model');
      model.set('errors', []);
      let errors = this.get('model.errors');
      if ((0, _lodash.isEmpty)(this.get('model.debtors'))) {
        errors.set('debtors', 'At least one debtor should be added');
        return false;
      }
      errors.set('debtors', '');
      return true;
    },
    actions: {
      _onAddOrganisation() {
        this.get('_debtorAddDlg').show({
          debtorType: this.get('constants.nzSPOrDebtorType.organisation')
        });
      },
      _onAddPerson() {
        this.get('_debtorAddDlg').show({
          debtorType: this.get('constants.nzSPOrDebtorType.person')
        });
      },
      _onEditDebtor(item, index) {
        Ember.set(item, 'indexId', index);
        this.get('_debtorAddDlg').show(item);
      },
      _onDeleteDebtor(item) {
        let model = this.get('model.debtors');
        if (!(0, _lodash.isEmpty)(model)) {
          model.removeObject(item);
        }
      },
      _saveDebtor(debtorModel) {
        if (Ember.isNone(this.get('model.debtors'))) {
          this.set('model.debtors', []);
        }
        let model = this.get('model.debtors');
        if ((0, _lodash.isNil)(debtorModel.indexId)) {
          model.unshiftObject(debtorModel);
        } else {
          this.mapObject(debtorModel, model[debtorModel.indexId]);
        }
        this.validateStep();
      }
    }
  });
  _exports.default = _default;
});