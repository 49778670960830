define("esis-ui/components/areas/search/nz/list/preview/nz-search-preview/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _previewPanel: 'refPanel',
    //endregion

    openSearchTab() {},
    actions: {
      _openSearchTab() {
        this.get('openSearchTab')();
      }
    }
  });
  _exports.default = _default;
});