define("esis-ui/components/shared/ember-button-dropdown/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    //region INITIALISATION
    renderInPlace: true,
    _dropDown: null,
    //endregion

    //region ACTIONS
    actions: {
      _onClick(selectedAction) {
        if (!selectedAction.disabled) {
          let dropDown = this.get('_dropDown');
          if (!(0, _lodash.isEmpty)(dropDown)) {
            dropDown.actions.close();
          }
          if (!(0, _lodash.isEmpty)(this.get('onSelectAction'))) {
            this.get('onSelectAction')(selectedAction.id);
          }
        }
      },
      // eslint-disable-next-line no-unused-vars
      _onListOpened: function _onListOpened(dropdown, e) {
        this.set('_dropDown', dropdown);
      }
    }
    //endregion
  });
  _exports.default = _default;
});