define("esis-ui/models/report-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    isActive: '',
    title: 'unnamed report',
    tabId: '',
    componentName: '',
    isVisible: Ember.computed.equal('isActive', 'active'),
    hasPermission: null
  });
  _exports.default = _default;
});