define("esis-ui/routes/reset-expired", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "esis-ui/mixins/esis-utils"], function (_exports, _unauthenticatedRouteMixin, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _esisUtils.default, {
    model: function model(params) {
      const requestModel = this.createModel('change-password-request');
      requestModel.setProperties({
        oldCredAccessKey: '',
        newCredAccessKey: '',
        confirmCredAccessKey: '',
        username: params.username
      });
      return requestModel;
    },
    actions: {
      error(error) {
        // Manage your errors
        Ember.onerror(error);
        // substate implementation when returning `true`
        return true;
      }
    }
  });
  _exports.default = _default;
});