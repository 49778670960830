define("esis-ui/models/tab-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    name: '',
    title: null,
    tabId: '',
    componentName: '',
    p0: '',
    p1: '',
    showIcon: true,
    iconName: null,
    tabType: '',
    showClose: true,
    options: null,
    // additional filtering options, e.g. session grid page
    innerPage: null,
    // inner page is supposed to be a component inherit from page-base

    get isVisible() {
      return this.get('tabId') === this.get('app.currentDynamicTabId');
    },
    notice: false,
    tabComponentInstance: null
  });
  _exports.default = _default;
});