define("esis-ui/components/shared/radiobutton-group-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lLdmtYTP",
    "block": "{\"symbols\":[\"radioItem\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"radioButtonGroupClass\"]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"shared/radiobutton-control\",null,[[\"formElement\",\"name\",\"value\",\"property\",\"title\",\"onChange\"],[[24,[\"formElement\"]],[24,[\"name\"]],[23,1,[\"value\"]],[24,[\"property\"]],[23,1,[\"title\"]],[28,\"action\",[[23,0,[]],[24,[\"onChange\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/radiobutton-group-control/template.hbs"
    }
  });
  _exports.default = _default;
});