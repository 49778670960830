define("esis-ui/components/areas/search/au/view/shared/au-grantor-search-filter-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "jquery"], function (_exports, _sharedComponentBase, _lodash, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _filterDateRangeSelected: Ember.computed('model.filterOption', function () {
      let constants = this.get('constants');
      return this.get('model.filterOption') === constants.grantorSearchFilterOption.dateRange;
    }),
    _filterCollateralClassSelected: Ember.computed('model.filterOption', function () {
      let constants = this.get('constants');
      return this.get('model.filterOption') === constants.grantorSearchFilterOption.collateralClassType;
    }),
    //region HOOK
    didInsertElement() {
      this._super(...arguments);
      this.loadFilterCriteria();
    },
    //endregion

    //region METHODS
    loadFilterCriteria() {
      const model = this.get('model');
      if (Ember.isPresent(model)) {
        let filterCriteria = this.createModel('grantor-search-filter-criteria');
        model.set('searchFilterCriteria', filterCriteria);
        if (!(0, _lodash.isEmpty)(model.get('guid'))) {
          this._loadGrantorFilterCriteria();
        }
        model.save();
      }
    },
    _loadGrantorFilterCriteria() {
      let constants = this.get('constants');
      let criteriaStr = this.get('model.filterCollateralClasses');
      if (!Ember.isBlank(criteriaStr) && criteriaStr !== constants.grantorSearchFilterCollateralClassTypeAll) {
        let dataArray = _jquery.default.map(criteriaStr.split(','), function (value) {
          return parseInt(value);
        });
        (0, _lodash.forOwn)(this.get('model.searchFilterCriteria'), (value, propName) => {
          if (propName === 'tangibleProperty' || propName === 'generalProperty' || propName === 'intangibleProperty' || propName === 'financialProperty') {
            if (Ember.isPresent(value)) {
              value.forEach(item => {
                Ember.set(item, 'checked', dataArray.includes(item.value));
                Ember.set(item, 'disabled', true); // make readonly
              });
            }
          }
        });
      }
    },

    //endregion

    actions: {
      _applyCollateralFilter() {
        let filter = this.get('model.searchFilterCriteria');
        this.set('model.collateralClassType', filter.getCollateralClassTypeSummary());
      },
      _discardFilterChange() {
        if (Ember.isPresent(this.get('model.searchFilterCriteria'))) {
          this.get('model.searchFilterCriteria').reset();
        }
      },
      _filterOptionChanged(value) {
        this.set('model.filterOption', value);
        const constants = this.get('constants');
        let model = this.get('model');
        if (model.get('filterOption') === constants.grantorSearchFilterOption.none) {
          model.get('searchFilterCriteria').reset();
        }
      },
      _onOpenCollFilterModal() {
        // for unexpected behaviour, the searchFilterCriteria is null
        this.loadFilterCriteria();
        this.get('_collateralFilter').show(this.get('model.searchFilterCriteria'));
      },
      _fromDateChanged(value) {
        this.set('model.filterRegEventFromDate', value);
      },
      _toDateChanged(value) {
        this.set('model.filterRegEventToDate', value);
      }
    }
  });
  _exports.default = _default;
});