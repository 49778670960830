define("esis-ui/models/secured-party-group-config-setting-nz", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    emailNotificationOnRegistration: (0, _modelBase.attr)(),
    emailNotificationWithPin: (0, _modelBase.attr)(),
    emailNotificationOnAmend: (0, _modelBase.attr)(),
    emailNotificationOnRenewal: (0, _modelBase.attr)(),
    emailNotificationOnDischarge: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.setProperties({
        'emailNotificationOnRegistration': false,
        'emailNotificationWithPin': false,
        'emailNotificationOnAmend': false,
        'emailNotificationOnRenewal': false,
        'emailNotificationOnDischarge': false
      });
    }
  });
  _exports.default = _default;
});