define("esis-ui/components/shared/ember-dropdown/component", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    //region INITIALISATION
    renderInPlace: true,
    _dropDown: null,
    //endregion

    //region COMPUTED PROPERTIES
    _selectedOption: Ember.computed('options', 'modelValue', function () {
      if (!(0, _lodash.isEmpty)(this.get('options')) && !(0, _lodash.isNil)(this.get('modelValue'))) {
        return (0, _lodash.find)(this.get('options'), {
          value: this.get('modelValue')
        });
      }
      return '';
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onOptionChange: function _onOptionChange(selectedOption) {
        // this.set('_selectedOption', selectedOption);
        let dropDown = this.get('_dropDown');
        if (!(0, _lodash.isEmpty)(dropDown)) {
          dropDown.actions.close();
        }
        if (!(0, _lodash.isEmpty)(this.get('onChange'))) {
          this.get('onChange')(selectedOption);
        }
      },
      // eslint-disable-next-line no-unused-vars
      _onListOpened: function _onListOpened(dropdown, e) {
        this.set('_dropDown', dropdown);
      }
    }
    //endregion
  });
  _exports.default = _default;
});