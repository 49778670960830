define("esis-ui/components/shared/ember-bootstrap/alert-ribbon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1XU9GmLH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-alert\",null,[[\"type\",\"onDismiss\"],[[24,[\"type\"]],[28,\"action\",[[23,0,[]],[24,[\"onDismiss\"]]],null]]],{\"statements\":[[0,\"  \"],[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"primary\"],null]],null,{\"statements\":[[1,[28,\"fa-icon\",[\"info\"],[[\"fixedWidth\"],[true]]],false]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"warning\"],null]],null,{\"statements\":[[1,[28,\"fa-icon\",[\"exclamation-triangle\"],[[\"fixedWidth\"],[true]]],false]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"type\"]],\"danger\"],null]],null,{\"statements\":[[1,[28,\"fa-icon\",[\"exclamation\"],[[\"fixedWidth\"],[true]]],false]],\"parameters\":[]},null],[0,\"    \"],[1,[22,\"message\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/alert-ribbon/template.hbs"
    }
  });
  _exports.default = _default;
});