define("esis-ui/components/shared/radiobutton-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zCVy8Qdj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"pretty p-icon p-round p-smooth mt-2\"],[8],[0,\"\\n    \"],[7,\"input\",true],[11,\"name\",[22,\"name\"]],[11,\"checked\",[22,\"checked\"]],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"changed\"],null]],[11,\"disabled\",[22,\"disabled\"]],[10,\"type\",\"radio\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"state p-primary\"],[8],[0,\"\\n        \"],[7,\"i\",true],[10,\"class\",\"icon\"],[8],[1,[28,\"fa-icon\",[\"check\"],[[\"class\",\"fixedWidth\",\"size\"],[\"pb-3 pr-4\",true,\"2x\"]]],false],[9],[0,\"\\n        \"],[7,\"label\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/radiobutton-control/template.hbs"
    }
  });
  _exports.default = _default;
});