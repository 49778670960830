define("esis-ui/components/areas/shared/buttons/assign-me-btn/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "k5iq5OVh",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shared/buttons/action-button\",null,[[\"icon\",\"iconOnly\",\"title\",\"isRunning\",\"disabled\",\"onClick\"],[\"share\",[24,[\"iconOnly\"]],\"Assign To Me\",[24,[\"isRunning\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"_onClick\"],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/buttons/assign-me-btn/template.hbs"
    }
  });
  _exports.default = _default;
});