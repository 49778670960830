define("esis-ui/helpers/display-boolean-nullable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayBooleanNullable = displayBooleanNullable;
  function displayBooleanNullable([value]) {
    if (Ember.isBlank(value)) {
      return 'Not Stated';
    }
    return value ? 'Yes' : 'No';
  }
  var _default = Ember.Helper.helper(displayBooleanNullable);
  _exports.default = _default;
});