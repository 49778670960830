define("esis-ui/repositories/admin-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency", "lodash"], function (_exports, _communicationBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    clearServerCache: (0, _emberConcurrency.task)(function* () {
      const result = yield this.apiGet(this.get('constants.apiEndpoint.clearServerCache'));
      return result;
    }).drop(),
    loadSystemUserPreview: (0, _emberConcurrency.task)(function* (systemUserGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSystemUserPreview'), systemUserGuid));
    }),
    loadSystemUserDetail: (0, _emberConcurrency.task)(function* (systemUserGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSystemUserDetail'), systemUserGuid));
    }),
    updateLinkedBusinessEntitiesTreeForUser: (0, _emberConcurrency.task)(function* (request) {
      return yield this.apiPost(this.get('constants.apiEndpoint.updateLinkedBusinessEntitiesTreeForUser'), request, true);
    }),
    deleteUser: (0, _emberConcurrency.task)(function* (userGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteUser'), userGuid));
    }).drop(),
    transferUser: (0, _emberConcurrency.task)(function* (userGuid, targetBusinessEntity, includeSearches) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.transferUser'), userGuid, targetBusinessEntity, includeSearches));
    }).drop(),
    loadCurrentUserRoles: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadCurrentUserRoles')));
    }),
    changeCredAccessKey: (0, _emberConcurrency.task)(function* (changeCredAccessKeyRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.changeCredAccessKey'), changeCredAccessKeyRequest.clone(), true);
    }).drop(),
    saveSystemUser: (0, _emberConcurrency.task)(function* (userRequest) {
      let copy = userRequest.clone();
      copy.id = userRequest.id;
      copy.hasChange = userRequest.isDirty;
      if (!(0, _lodash.isEmpty)(userRequest.roles)) {
        copy.roles = userRequest.roles.map(function (item) {
          let param = item.clone();
          param.id = item.id;
          param.hasChange = item.isDirty;
          return param;
        });
      }
      if (!(0, _lodash.isEmpty)(userRequest.linkedBusinessEntities)) {
        copy.linkedBusinessEntities = userRequest.linkedBusinessEntities.map(item => item.id);
      }
      return yield this.apiPost(this.get('constants.apiEndpoint.saveSystemUser'), copy, true);
    }).drop(),
    loadEmailPreview: (0, _emberConcurrency.task)(function* (emailGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadEmailPreview'), emailGuid));
    }),
    //region AU CATEGORY
    loadCategory: (0, _emberConcurrency.task)(function* (categoryGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadCategory'), categoryGuid));
    }),
    saveCategory: (0, _emberConcurrency.task)(function* (categoryRequest) {
      let copy = categoryRequest.clone();
      copy.hasChange = categoryRequest.isDirty;
      return yield this.apiPost(this.get('constants.apiEndpoint.saveCategory'), copy, true);
    }).drop(),
    deleteCategory: (0, _emberConcurrency.task)(function* (categoryGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteCategory'), categoryGuid));
    }).drop(),
    //endregion
    //region AU CONTRACT TYPE
    loadContractTypeDetailDigest: (0, _emberConcurrency.task)(function* (contractTypeGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadContractTypeDetailDigest'), contractTypeGuid));
    }),
    deleteContractType: (0, _emberConcurrency.task)(function* (contractTypeGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteContractType'), contractTypeGuid));
    }).drop(),
    loadContractTypeListForCopying: (0, _emberConcurrency.task)(function* (businessEntityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadContractTypeListForCopying'), businessEntityGuid));
    }),
    loadContractTypeForCopying: (0, _emberConcurrency.task)(function* (businessEntityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadContractTypeForCopying'), businessEntityGuid));
    }),
    loadContractType: (0, _emberConcurrency.task)(function* (contractTypeGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadContractType'), contractTypeGuid));
    }),
    saveContractType: (0, _emberConcurrency.task)(function* (contractTypeRequest) {
      let copy = contractTypeRequest.clone();
      copy.id = contractTypeRequest.id;
      copy.hasChange = contractTypeRequest.isDirty;
      if (!(0, _lodash.isEmpty)(contractTypeRequest.defaults)) {
        copy.defaults = contractTypeRequest.defaults.map(item => {
          let param = item.clone();
          param.id = item.id;
          param.hasChange = item.isDirty;
          return param;
        });
      }
      return yield this.apiPost(this.get('constants.apiEndpoint.saveContractType'), copy, true);
    }).drop(),
    //endregion
    //region AU Transaction History
    downloadAuTransHistoryDetail: (0, _emberConcurrency.task)(function* (filterRequest) {
      return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadAuTransHistoryDetail'), filterRequest, true);
    }),
    downloadAuTransHistoryInvoiceSummary: (0, _emberConcurrency.task)(function* (filterRequest) {
      return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadAuTransHistoryInvoiceSummary'), filterRequest, true);
    }),
    //endregion
    //region NZ Transaction History
    downloadNzTransHistoryDetail: (0, _emberConcurrency.task)(function* (filterRequest) {
      return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadNzTransHistoryDetail'), filterRequest, true);
    }),
    downloadNzTransHistoryInvoiceSummary: (0, _emberConcurrency.task)(function* (filterRequest) {
      return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadNzTransHistoryInvoiceSummary'), filterRequest, true);
    }),
    //endregion
    //region NZ COLLATERAL PROFILE
    loadNzCollateralProfileDetailDigest: (0, _emberConcurrency.task)(function* (collateralProfileGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzCollateralProfileDetailDigest'), collateralProfileGuid));
    }),
    deleteNzCollateralProfile: (0, _emberConcurrency.task)(function* (collateralProfileGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteNzCollateralProfile'), collateralProfileGuid), true);
    }).drop(),
    loadNzCollateralProfile: (0, _emberConcurrency.task)(function* (collateralProfileGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzCollateralProfile'), collateralProfileGuid));
    }),
    saveNzCollateralProfile: (0, _emberConcurrency.task)(function* (collateralProfileRequest) {
      let copy = collateralProfileRequest.clone();
      copy.hasChange = collateralProfileRequest.isDirty;
      if (!(0, _lodash.isEmpty)(collateralProfileRequest.defaults)) {
        copy.defaults = collateralProfileRequest.defaults.map(item => {
          let param = item.clone();
          param.hasChange = item.isDirty;
          return param;
        });
      }
      return yield this.apiPost(this.get('constants.apiEndpoint.saveNzCollateralProfile'), copy, true);
    }).drop(),
    //endregion
    //region BUSINESS ENTITY
    loadBusinessEntitiesForEditing: (0, _emberConcurrency.task)(function* (businessEntityRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadBusinessEntitiesForEditing'), businessEntityRequest, true);
    }),
    loadBusinessEntitiesTree: (0, _emberConcurrency.task)(function* (businessEntityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadBusinessEntitiesTree'), businessEntityGuid));
    }),
    loadBusinessEntitiesFlat: (0, _emberConcurrency.task)(function* (businessEntityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadBusinessEntitiesFlat'), businessEntityGuid));
    }),
    loadBusinessEntityModel: (0, _emberConcurrency.task)(function* (businessEntityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadBusinessEntityModel'), businessEntityGuid));
    }),
    saveBusinessEntity: (0, _emberConcurrency.task)(function* (businessEntityRequest) {
      let copy = businessEntityRequest.clone();
      copy.hasChange = businessEntityRequest.isDirty;
      return yield this.apiPost(this.get('constants.apiEndpoint.saveBusinessEntity'), copy, true);
    }).drop(),
    updateBusinessEntityLinkedUser: (0, _emberConcurrency.task)(function* (businessEntityRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.updateBusinessEntityLinkedUser'), businessEntityRequest, false);
    }).drop(),
    grantorAbrOrgNameLookup: (0, _emberConcurrency.task)(function* (businessEntityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.grantorAbrOrgNameLookup'), businessEntityGuid));
    }),
    //endregion
    //region SECURITY ROLE
    loadSecurityRoleDetailDigest: (0, _emberConcurrency.task)(function* (securityRoleGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSecurityRoleDetailDigest'), securityRoleGuid));
    }),
    saveSecurityRole: (0, _emberConcurrency.task)(function* (securityRoleRequest) {
      let copy = securityRoleRequest.clone();
      copy.hasChange = securityRoleRequest.isDirty;
      if (!(0, _lodash.isEmpty)(copy.permissions)) {
        copy.permissions = securityRoleRequest.get('permissions').map(function (item) {
          return item.clone();
        });
      }
      return yield this.apiPost(this.get('constants.apiEndpoint.saveSecurityRole'), copy, true);
    }).drop(),
    loadCurrentUserPermissions: (0, _emberConcurrency.task)(function* () {
      const cache = this.get('cache');
      let result = [];
      if (Ember.isPresent(cache.getLookup("currentUserPermissions"))) {
        result = cache.getLookup("currentUserPermissions");
      } else {
        result = yield this.apiGet(this.get('constants.apiEndpoint.loadCurrentUserPermissions'));
        cache.putLookup("currentUserPermissions", result);
      }
      return result;
    }),
    //endregion
    //region BRANCH CODE
    loadBranchCodeDigest: (0, _emberConcurrency.task)(function* (branchCodeGridFilter) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadBranchCodeDigest'), branchCodeGridFilter, true);
    }),
    saveBranchCode: (0, _emberConcurrency.task)(function* (branchCodeRequest) {
      let copy = branchCodeRequest.clone();
      copy.hasChange = branchCodeRequest.isDirty;
      return yield this.apiPost(this.get('constants.apiEndpoint.saveBranchCode'), copy);
    })
    //endregion
  });
  _exports.default = _default;
});