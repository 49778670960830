define("esis-ui/services/notification-service", ["exports", "jquery", "esis-ui/mixins/esis-utils", "lodash", "ember-lifeline"], function (_exports, _jquery, _esisUtils, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend(_esisUtils.default, {
    //region property injection
    logger: Ember.inject.service('logging-service'),
    app: Ember.inject.service('app-service'),
    event: Ember.inject.service('event-service'),
    credential: Ember.inject.service('credential-service'),
    //endregion

    //region private properties
    _notificationJobId: null,
    _connectionStatusJobId: null,
    _hubConnection: null,
    _hub: null,
    _reconnecting: false,
    _reconnectCount: 0,
    _authSessionUpdateReconnect: false,
    _connectionStatus: 1,
    // checking
    _lastSuccessfulCall: null,
    _slowConnectionNotification: null,
    _noConnectionNotification: null,
    _ppsrConnectionStatus: false,
    _shouldStartConnection: false,
    _currentRegion: null,
    init() {
      this._super(...arguments);
      this._notificationRegister = [];

      // check and polling for connection status change every 10 seconds
      this.set('_connectionStatusJobId', (0, _emberLifeline.pollTask)(this, '_updateConnectionStatus'));
      this.get('event').on(this.get('constants.events.authenticationSessionUpdated'), this, '_authSessionEventHandler');
    },
    //endregion

    _authSessionEventHandler(notification) {
      const constants = this.get('constants');
      if (Ember.isPresent(this._hubConnection) && this.get('_connectionStatus') === constants.connectionStatus.good) {
        this._hubConnection.qs[constants.authorisationQsName] = notification.access_token;
        this.set('_authSessionUpdateReconnect', true);
        this._hubConnection.transport.lostConnection(this._hubConnection);
      }
    },
    //region server connection

    /*
    we should only call connect after loading of user profile, where the system settings have been loaded
     */
    connect() {
      if (this.get('_reconnecting') === false) {
        this.set('_reconnecting', true);
      }
      const logger = this.get('logger');
      const accessToken = this.getCurrentAccessToken();
      if (Ember.isBlank(accessToken)) {
        this.notifyError('Failed to initialise live connection service, credential is not available.');
        logger.logError('Failed to initialise live connection service, credential is not available.', 'Access token not available');
        return;
      }
      const constants = this.get('constants');
      this._hubConnection = _jquery.default.hubConnection(constants.apiBaseUrl);
      this._hubConnection.qs = {};
      this._hubConnection.qs[constants.authorisationQsName] = accessToken;
      //region register hub connection events
      this._hubConnection.stateChanged(state => {
        if (this.get('_authSessionUpdateReconnect') === false && state.oldState === 1 && state.newState !== 1) {
          logger.logInfo('Connection Hub Connection state changed, disconnected', state);
          this._reconnect();
        }
      });
      this._hubConnection.reconnected(() => {
        logger.logDebug('Connection Hub Connection : reconnected');
        if (this.get('_authSessionUpdateReconnect') === true) {
          this.set('_authSessionUpdateReconnect', false);
        }
      });
      this._hubConnection.error(e => {
        logger.logError('Connection Hub Connection error', e);
        this._reconnect();
      });
      this._hubConnection.disconnected(() => {
        logger.logWarning('Connection Hub has been disconnected');
        this._reconnect();
      });
      //endregion
      this._hub = this._hubConnection.createHubProxy('notificationHub');
      this._registerHubEvents();
      //this._hubConnection.logging = true;
      this.set('_shouldStartConnection', true);
      this._hubConnection.start({
        transport: 'longPolling'
      }).done(() => {
        (0, _emberLifeline.runTask)(this, () => {
          //this.notifyInfo('Connect service connection complete.');
          logger.logDebug('Connect service connection complete.');
          if (Ember.isPresent(this.get('_slowConnectionNotification'))) {
            this.tabNotifyDismiss(this.get('_slowConnectionNotification'));
          }
          if (Ember.isPresent(this.get('_noConnectionNotification'))) {
            this.tabNotifyDismiss(this.get('_noConnectionNotification'));
          }
          this.setProperties({
            _reconnecting: false,
            _reconnectCount: 0,
            _slowConnectionNotification: null,
            _noConnectionNotification: null,
            _connectionStatus: constants.connectionStatus.good
          });
          this.get('event').trigger(constants.events.connectionStatusChanged, this.get('_connectionStatus'));
        }, 20);
      }).fail(error => {
        if (error.context && error.context.status === 401) {
          logger.logWarning('Unauthorised to connect to server, access token expired - logout', error);
          this.get('event').trigger(this.get('constants.events.logout'), true);
          return;
        }
        logger.logWarning('Could not connect to live connect service.', error);
        this.set('_connectionStatus', constants.connectionStatus.bad);
        this.get('event').trigger(constants.events.connectionStatusChanged, constants.connectionStatus.bad);
        this.set('_reconnecting', false);
        this._reconnect();
      });
    },
    _registerHubEvents() {
      const logger = this.get('logger');
      if (Ember.isEmpty(this._hub)) {
        logger.logError('notification-service.RegisterHubEvents : Hub is not initialised.');
        return;
      }
      this._hub.on('broadcastMessage', data => {
        // logger.logDebug('Notification service data payload received', data);
        this._notificationMessageReceived(data);
      });
    },
    _reconnect() {
      if (this.get('_reconnecting') === true) {
        //already reconnecting
        return;
      }
      const logger = this.get('logger');
      logger.logInfo('Starting reconnect procedure ... reconnect count ' + this.get('_reconnectCount'));
      this.setProperties({
        _reconnecting: true,
        _reconnectCount: this.get('_reconnectCount') + 1
      });

      // clean up and get ready for a new connection
      this._disconnect();
      if (this.get('_reconnectCount') === 4) {
        this.set('_slowConnectionNotification', this.tabNotifyInfo('Slow internet connection detected. The system is trying to recover the connection.'));
      }
      if (this.get('_reconnectCount') === 7) {
        if (Ember.isPresent(this.get('_slowConnectionNotification'))) {
          this.tabNotifyDismiss(this.get('_slowConnectionNotification'));
          this.set('_slowConnectionNotification', null);
        }
        this.set('_noConnectionNotification', this.tabNotifyWarn('The internet connection has been lost. The system is trying to recover the connection.'));
      }

      // schedule a reconnect after 2 seconds.
      let reconnectInterval = this.get('_reconnectCount') * 1000;
      if (reconnectInterval > 20000) {
        reconnectInterval = 20000;
      }
      (0, _emberLifeline.runTask)(this, 'connect', reconnectInterval);
    },
    _disconnect() {
      try {
        this.setProperties({
          _hubConnection: null,
          _hub: null
        });
      } catch (e) {
        this.get('logger').logInfo('Notification hub disconnect', e);
      }
    },
    //endregion

    //region notification functions
    _notificationMessageReceived(data) {
      // set server call successful
      this.set('_lastSuccessfulCall', this.now());
      const logger = this.get('logger');
      const register = this.get('_notificationRegister');
      if (!(0, _lodash.some)(register, {
        'guid': data.guid
      })) {
        data.timeStamp = this.utcTimestamp();
        register.pushObject(data);
        logger.logDebug(`Firing new notification. type : ${data.type}, initiator : ${data.initiatorUserId}`, data);
        this.get('event').trigger(this.get('constants.events.notification'), data);
      }

      // clean up the local notificaiton cache, remove older notification items.
      // NOTE this may result in older notification gets raised again by server
      (0, _lodash.remove)(register, n => {
        const timeDiff = this.timeDiffAsSecondsUtc(n.timeStamp);
        return timeDiff > 300;
      });
    },
    //endregion

    //region check connection status job
    _updateConnectionStatus() {
      const constants = this.get('constants');
      const logger = this.get('logger');
      if (Ember.isPresent(this._hub) && this.get('_connectionStatus') === constants.connectionStatus.good) {
        this._hub.invoke('checkConnectionStatus', this.get('credential.currentRegion')).done(data => {
          try {
            this.set('_lastSuccessfulCall', this.now());
            this._checkApiConnectionStatus();
            this._checkApplicationVersion(data);
            if (this.get('_currentRegion') !== this.get('credential.currentRegion')) {
              this.setProperties({
                _ppsrConnectionStatus: false,
                _currentRegion: this.get('credential.currentRegion')
              });
            }
            const newPpsrStatus = data.register && data.mailbox && data.search && data.securedPartyGroup;
            if (newPpsrStatus !== this.get('_ppsrConnectionStatus')) {
              this.set('_ppsrConnectionStatus', newPpsrStatus);
              this.get('event').trigger(constants.events.ppsrConnectionStatusChanged, {
                register: data.register,
                mailbox: data.mailbox,
                search: data.search,
                securedPartyGroup: data.securedPartyGroup
              });
            }
          } catch (e) {
            logger.logError('Processing Notification', e);
          }
        }).fail(error => {
          this._checkApiConnectionStatus();
          logger.logError('notification-service : Fail to retrieve notification', error);
        });
      } else {
        this._checkApiConnectionStatus();
      }
      (0, _emberLifeline.runTask)(this, '_updateConnectionStatus', this.get('app').getSetting(constants.systemSetting.connectionStatusPollingInterval) * 1000);
    },
    _checkApiConnectionStatus() {
      const constants = this.get('constants');
      const currentStatus = this.get('_connectionStatus');
      const lastConTime = this.get('_lastSuccessfulCall');
      let changed = false;
      if (currentStatus === constants.connectionStatus.broken) {
        return; // this is the final status and reconnect will not happen therefore no need to check more.
      }

      if (Ember.isEmpty(lastConTime)) {
        if (currentStatus !== constants.connectionStatus.unknown) {
          changed = true;
          this.set('_connectionStatus', constants.connectionStatus.unknown);
        }
      } else if (this.get('_reconnecting') === true) {
        if (currentStatus !== constants.connectionStatus.checking) {
          changed = true;
          this.set('_connectionStatus', constants.connectionStatus.checking);
        }
      } else {
        const diff = this.timeDiffAsSeconds(lastConTime);
        if (diff > 40) {
          if (this.get('_shouldStartConnection') === true && this.get('_reconnecting') !== true) {
            this._reconnect(); // if no connection for 20 seconds and not actively reconnecting, then need to trigger recoonect
          }

          if (diff > 60) {
            if (currentStatus !== constants.connectionStatus.bad) {
              changed = true;
              this.set('_connectionStatus', constants.connectionStatus.bad);
            }
          } else {
            if (currentStatus !== constants.connectionStatus.checking) {
              changed = true;
              this.set('_connectionStatus', constants.connectionStatus.checking);
            }
          }
        } else if (currentStatus !== constants.connectionStatus.good) {
          changed = true;
          this.set('_connectionStatus', constants.connectionStatus.good);
        }
      }
      if (changed) {
        this.get('event').trigger(constants.events.connectionStatusChanged, this.get('_connectionStatus'));
      }
    },
    _checkApplicationVersion(data) {
      const constants = this.get('constants');
      const localVersion = constants.appVersion.split('.');
      const remoteVersion = data.applicationVersion.split('.');
      if (localVersion[0] !== remoteVersion[0] || localVersion[1] !== remoteVersion[1] || localVersion[2] !== remoteVersion[2]) {
        this.get('event').trigger(constants.events.appVersionOutdated, constants.appVersion, data.applicationVersion);
      }
    }
    //endregion
  });
  _exports.default = _default;
});