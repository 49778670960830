define("esis-ui/models/company-verification-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    organisationNumber: (0, _modelBase.attr)(),
    abnNumber: (0, _modelBase.attr)(),
    acnNumber: (0, _modelBase.attr)(),
    arbnNumber: (0, _modelBase.attr)(),
    arsnNumber: (0, _modelBase.attr)(),
    organisationName: (0, _modelBase.attr)(),
    entityType: (0, _modelBase.attr)(),
    hasValueToVerify: Ember.computed('organisationNumber', 'abnNumber', 'acnNumber', 'arbnNumber', 'arsnNumber', 'organisationName', function () {
      return Ember.isPresent(this.get('organisationNumber')) || Ember.isPresent(this.get('abnNumber')) || Ember.isPresent(this.get('acnNumber')) || Ember.isPresent(this.get('arbnNumber')) || Ember.isPresent(this.get('arsnNumber')) || Ember.isPresent(this.get('organisationName'));
    })
  });
  _exports.default = _default;
});