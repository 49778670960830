define("esis-ui/templates/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Xs9QGuwo",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"areas/login/reset-cred-access-key\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/templates/reset.hbs"
    }
  });
  _exports.default = _default;
});