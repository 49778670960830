define("esis-ui/components/areas/shared/scroll-bottom-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FYT01N5P",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"_scrollToBottom\"]],[8],[1,[28,\"fa-icon\",[\"arrow-alt-circle-down\"],[[\"class\"],[\"havePoiner\"]]],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/scroll-bottom-icon/template.hbs"
    }
  });
  _exports.default = _default;
});