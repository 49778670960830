define("esis-ui/models/nz-collateral-profile-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    entityGuid: (0, _modelBase.attr)(),
    code: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    defaults: (0, _modelBase.attr)(null, 'array'),
    isActive: (0, _modelBase.attr)(),
    userEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    userCreated: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.setProperties({
        isActive: true
      });
      this.validations = {
        code: {
          presence: true
        }
      };
    },
    saveModel: function saveModel() {
      this.save();
      const defaults = this.get('defaults');
      if (Ember.isPresent(defaults) && Ember.isArray(defaults)) {
        (0, _lodash.forEach)(defaults, item => {
          item.save();
        });
      }
    }
  });
  _exports.default = _default;
});