define("esis-ui/components/areas/registration/nz/nz-registration-base", ["exports", "esis-ui/components/areas/shared/nz-page-base", "lodash", "ember-lifeline"], function (_exports, _nzPageBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region dialogs
    /*
    these dialogs should be included in the child components inherit from NZ registration page base
    these dialogs are required for shared function to work, these are kind of virtual declaration
     */
    _dischargeConfirmModal: null,
    _waitingModal: null,
    _dischargeResultModal: null,
    _renewModal: null,
    //endregion

    isAmend: false,
    hasCollateralShowCount: false,
    //Collateral
    _isSearching: false,
    //Collateral

    init() {
      this._super(...arguments);

      //Collateral
      this._collateralFilters = {
        showCount: 10,
        collateralType: null,
        searchBy: null,
        searchValue: ''
      };
    },
    //region private methods
    _showWaitModalDlg() {
      if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing !== true) {
        this.get('_waitingModal').show('Please be patient while we are submitting your request...');
      }
    },
    _hideWaitModalDlg() {
      if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing === true) {
        this.get('_waitingModal').hide();
      }
    },
    _closeRegistrationTab() {
      this.removeTab('registrationListNz');
    },
    //endregion

    //region protected shared method with children
    _createRegistrationModel() {
      const rs = this.mapJsonObjectToModel({
        esisEntityGuid: this.get('credential.currentBusinessEntityGuid'),
        debtors: [],
        collateral: [],
        errors: []
      }, 'nz-fs-create-request');
      rs.saveModel();
      return rs;
    },
    _mapApiRegistrationResponseToModel(registrationResponse) {
      let registration = this.mapJsonObjectToModel(registrationResponse, 'nz-fs-create-request');
      registration.setProperties({
        esisGuid: registrationResponse.guid,
        debtors: [],
        collateral: [],
        errors: []
      });
      if (!(0, _lodash.isEmpty)(registrationResponse.debtors)) {
        (0, _lodash.forEach)(registrationResponse.debtors, debtor => {
          let debtorModel = this.mapJsonObjectToModel(debtor, 'nz-debtor-create-request');
          debtorModel.set('contactAddress', this.mapJsonObjectToModel(debtor.contactAddress, 'address-nz'));
          if (!(0, _lodash.isEmpty)(debtor.mailingAddress)) {
            debtorModel.set('mailingAddress', this.mapJsonObjectToModel(debtor.mailingAddress, 'address-nz'));
          }
          if (debtor.debtorType === this.get('constants.nzSPOrDebtorType.organisation')) {
            debtorModel.set('personActingOnBehalf', this.mapJsonObjectToModel(debtor.personActingOnBehalf, 'secured-party-person-on-behalf-nz'));
            debtorModel.set('personActingOnBehalf.contactAddress', this.mapJsonObjectToModel(debtor.personActingOnBehalf.contactAddress, 'address-nz'));
            if (!(0, _lodash.isEmpty)(debtor.personActingOnBehalf.mailingAddress)) {
              debtorModel.set('personActingOnBehalf.mailingAddress', this.mapJsonObjectToModel(debtor.personActingOnBehalf.mailingAddress, 'address-nz'));
            }
          }
          if (this.get('isAmend')) {
            debtorModel.set('existingDebtorId', this.get('app').guid());
          }
          registration.get('debtors').pushObject(debtorModel);
        });
      }
      if (!(0, _lodash.isEmpty)(registrationResponse.collateral)) {
        (0, _lodash.forEach)(registrationResponse.collateral, coll => {
          let collateralModel = this.mapJsonObjectToModel(coll, 'nz-collateral-create-request');
          if (this.get('hasCollateralShowCount')) {
            collateralModel.set('showCount', this.get('_collateralFilters').showCount);
          }
          if (this.get('isAmend')) {
            collateralModel.set('existingCollId', this.get('app').guid());
          }
          collateralModel.set('collateralItems', []);
          if (!(0, _lodash.isEmpty)(coll.collateralItems)) {
            collateralModel.set('collateralItems', this.mapJsonObjectArrayToModelArray(coll.collateralItems, 'nz-collateral-item-request'));
            (0, _lodash.forEach)(collateralModel.get('collateralItems'), collItem => {
              collItem.set('collateralType', coll.collateralType);
              if (this.get('isAmend')) {
                collItem.set('existingItemId', this.get('app').guid());
              }
            });
          }
          registration.get('collateral').pushObject(collateralModel);
        });
      }
      registration.saveModel();
      return registration;
    },
    _submitRegistration(registrationModel) {
      if (registrationModel.validateModel()) {
        registrationModel.setProperties({
          validatErrorMessage: '',
          displayException: false
        });
        this.get('_submitNzFsTask').perform(registrationModel);
      } else {
        registrationModel.set('validatErrorMessage', '');
        if ((0, _lodash.isEmpty)((0, _lodash.filter)(registrationModel.collateral, item => {
          return item.action !== this.get('constants.actionType.remove');
        }))) {
          registrationModel.set('validatErrorMessage', 'At least one collateral should be added |');
          registrationModel.set('displayException', true);
        } else {
          let invalidColls = (0, _lodash.filter)(registrationModel.collateral, coll => {
            return coll.action !== this.get('constants.actionType.remove') && !coll.validate();
          });
          if (!(0, _lodash.isEmpty)(invalidColls)) {
            (0, _lodash.forEach)(invalidColls, item => {
              let str = registrationModel.get('validatErrorMessage');
              registrationModel.set('validatErrorMessage', str + `${item.collateralTypeTitle} collateral must have at least one collateral item |`);
              registrationModel.set('displayException', true);
            });
          }
        }
        if ((0, _lodash.isEmpty)((0, _lodash.filter)(registrationModel.debtors, item => {
          return item.action !== this.get('constants.actionType.remove');
        }))) {
          let str = registrationModel.get('validatErrorMessage');
          registrationModel.set('validatErrorMessage', str + 'Debtor must be specified |');
          registrationModel.set('displayException', true);
        }
        if (registrationModel.hasBranchCode && (0, _lodash.isNil)(registrationModel.branchCode)) {
          let str = registrationModel.get('validatErrorMessage');
          registrationModel.set('validatErrorMessage', str + 'Branch Code must be specified |');
          registrationModel.set('displayException', true);
        }
      }
    },
    _showDischargeDlg(registrations) {
      if (!(0, _lodash.isEmpty)(registrations)) {
        this.get('_dischargeConfirmModal').show(registrations);
      }
    },
    _showRenewDlg(registrations) {
      if (!(0, _lodash.isEmpty)(registrations)) {
        this.get('_renewModal').show(registrations);
      }
    },
    //endregion

    //region Collateral Methods
    /*If We Calculate Collateral Display in Collateral Component, there will be a delay while the component is rendering,
    that is a bad user experience when having many collateral*/
    _filterCollateral(coll, loadMore = false) {
      if (loadMore) {
        let collInFilteredModel = (0, _lodash.find)(this.get('_filteredCollateral'), {
          collateralId: coll.collateralId
        });
        let collInDisplay = coll;
        for (let i = collInDisplay.showCount - this.get('_collateralFilters').showCount; i < collInDisplay.showCount && collInFilteredModel.get('collateralItems').length; i++) {
          collInDisplay.collateralItems[collInDisplay.showCount / this.get('_collateralFilters').showCount - 1].pushObject(collInFilteredModel.get('collateralItems')[i]);
        }
      } else {
        let collateral = this._mapCollateral();
        let filters = this.get('_collateralFilters');
        if (!(0, _lodash.isNil)(filters.collateralType)) {
          collateral = (0, _lodash.filter)(collateral, {
            collateralType: filters.collateralType
          });
          if (!(0, _lodash.isNil)(filters.searchBy) && !(0, _lodash.isEmpty)(filters.searchValue)) {
            if (filters.searchBy === 'collDescription') {
              collateral = (0, _lodash.filter)(collateral, item => {
                return (0, _lodash.includes)(item[filters.searchBy].toLowerCase(), filters.searchValue.trim().toLowerCase());
              });
            } else {
              (0, _lodash.forEach)(collateral, item => {
                Ember.set(item, 'collateralItems', (0, _lodash.filter)(item.collateralItems, collItem => {
                  if (!(0, _lodash.isNil)(collItem[filters.searchBy])) {
                    return (0, _lodash.includes)(collItem[filters.searchBy].toString().toLowerCase(), filters.searchValue.trim().toLowerCase());
                  } else {
                    return false;
                  }
                }));
              });
            }
          }
        }
        this.set('_filteredCollateral', collateral);
        this.set('_displayCollateral', this._setDisplayCollateral());
      }
      this.set('_isSearching', false);
    },
    _mapCollateral() {
      let collateral = this.get('model.collateral');
      let model = [];
      if (!(0, _lodash.isEmpty)(collateral)) {
        (0, _lodash.forEach)(collateral, coll => {
          if (coll.action !== this.get('constants.actionType.remove')) {
            let collateralModel = this.mapObject(coll, this.createModel('nz-collateral-create-request'));
            collateralModel.set('collateralItems', []);
            let notRemoveItems = (0, _lodash.filter)(coll.get('collateralItems'), item => {
              return item.action !== this.get('constants.actionType.remove');
            });
            if (!(0, _lodash.isEmpty)(notRemoveItems)) {
              collateralModel.set('collateralItems', []);
              (0, _lodash.forEach)(notRemoveItems, collItem => {
                let collItemModel = this.mapObject(collItem, this.createModel('nz-collateral-item-request'));
                collItemModel.set('collateralType', coll.collateralType);
                collateralModel.get('collateralItems').pushObject(collItemModel);
              });
              collateralModel.set('showCount', this.get('_collateralFilters').showCount);
            }
            model.pushObject(collateralModel);
          }
        });
      }
      return model;
    },
    _setDisplayCollateral() {
      let collateral = this.get('_filteredCollateral');
      let model = [];
      if (!(0, _lodash.isEmpty)(collateral)) {
        (0, _lodash.forEach)(collateral, coll => {
          coll.validate();
          let collateralModel = this.mapObject(coll, this.createModel('nz-collateral-create-request'));
          collateralModel.set('errors', coll.errors);
          collateralModel.set('collateralItems', []);
          collateralModel.set('itemCount', coll.collateralItems.length);
          for (let i = 0; i < coll.collateralItems.length / this.get('_collateralFilters').showCount + 1; i++) {
            collateralModel.get('collateralItems').pushObject([]);
          }
          for (let i = 0; i < coll.showCount && i < coll.collateralItems.length; i++) {
            collateralModel.get('collateralItems')[0].pushObject(coll.get('collateralItems')[i]);
          }
          collateralModel.set('hasCollItem', !(0, _lodash.isEmpty)(collateralModel.get('collateralItems')[0]));
          model.pushObject(collateralModel);
        });
      }
      return model;
    },
    _filterCollPicklistInCollDisplay() {
      if (!(0, _lodash.isEmpty)(this.get('_collateralDisplay'))) {
        this.get('_collateralDisplay').filterCollateralTypePicklist();
      } else {
        (0, _emberLifeline.runTask)(this, () => {
          this._filterCollPicklistInCollDisplay();
        });
      }
    },
    //endregion

    //region common action handler
    actions: {
      //region SUBMIT Registration
      _submitRegistration: function _submitRegistration(registrationModel) {
        this._submitRegistration(registrationModel);
      },
      //endregion
      _filterCollateral(coll, loadMore) {
        this._filterCollateral(coll, loadMore);
      }
    }
    //endregion
  });
  _exports.default = _default;
});