define("esis-ui/helpers/multiply-numbers", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.multiplyNumbers = multiplyNumbers;
  function multiplyNumbers([...numbers]) {
    let anyNan = !(0, _lodash.isEmpty)((0, _lodash.find)(numbers, num => {
      return (0, _lodash.isNil)(num) || !(0, _lodash.isNumber)(num);
    }));
    if (anyNan) {
      return null;
    }
    let result = 1;
    (0, _lodash.forEach)(numbers, num => {
      result = result * num;
    });
    return result;
  }
  var _default = Ember.Helper.helper(multiplyNumbers);
  _exports.default = _default;
});