define("esis-ui/components/areas/shared/report-instance-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "13O+02kx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row mr-1\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"noteAffected lastOrder reportInstances \",[28,\"if\",[[24,[\"displayNameOnly\"]],\"col-md-2\",\"col-md-4\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"listGridStyle\"],[8],[0,\"\\n      \"],[1,[28,\"shared/kendo/grid/grid-local-ds\",null,[[\"options\",\"registerAs\",\"onSelectionChange\",\"autoSelectFirstRow\",\"autoFocus\"],[[24,[\"_reportListGridOption\"]],[24,[\"_reportListGrid\"]],[28,\"action\",[[23,0,[]],\"onSelectionChange\"],null],false,false]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"noteAffected firstOrder \",[28,\"if\",[[24,[\"displayNameOnly\"]],\"col-md-10\",\"col-md-8\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"_isReportVisible\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"shared/kendo/report-viewer\",null,[[\"registerAs\",\"options\"],[[24,[\"_reportViewer\"]],[24,[\"_reportViewerOption\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/report-instance-display/template.hbs"
    }
  });
  _exports.default = _default;
});