define("esis-ui/components/shared/ember-bootstrap/modal/confirm-dialog/component", ["exports", "esis-ui/components/shared/ember-bootstrap/modal/modal-dialog-base"], function (_exports, _modalDialogBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modalDialogBase.default.extend({
    //--- INITIALIZATION ---
    name: 'ConfirmDlg',
    confirmModalTitle: '',
    dirtyMessage: '',
    init() {
      this._super(...arguments);
      this.cancelBtnLabel = 'No';
      this.confirmBtnLabel = 'Yes';
    },
    // --- COMPUTED PROPS ---
    confirmMsg: Ember.computed('dirtyMessage', function () {
      if (!Ember.isPresent(this.get('dirtyMessage'))) {
        return 'Want to discard your changes?';
      }
      return this.get('dirtyMessage');
    }),
    modalTitle: Ember.computed('confirmModalTitle', function () {
      if (!Ember.isPresent(this.get('confirmModalTitle'))) {
        return 'Please Confirm';
      }
      return this.get('confirmModalTitle');
    })
  });
  _exports.default = _default;
});