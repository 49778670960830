define("esis-ui/components/areas/shell/terms-and-conditions-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    show() {
      this.setProperties({
        _collapse: true
      });
      this.get('_dialog').show();
    },
    hide() {
      this.get('_dialog').hide();
    },
    onConfirm() {},
    onCancel() {},
    actions: {
      _onConfirm() {
        this.get('onConfirm')();
      },
      _onCancel() {
        this.get('onCancel')();
      }
    }
  });
  _exports.default = _default;
});