define("esis-ui/components/areas/shell/version-outdated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kQglgqyj",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"registerAs\",\"confirmBtnLabel\",\"onConfirm\",\"closeButton\",\"keyboard\",\"modalTitle\",\"name\"],[[24,[\"_dialog\"]],\"Reload\",[28,\"action\",[[23,0,[]],\"onReload\"],null],false,false,\"Newer Version of Application Available\",\"versionOutdated\"]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"You are running an older version \"],[1,[22,\"_clientVersion\"],false],[0,\" of ESIS and should be running version \"],[7,\"b\",true],[8],[1,[22,\"_serverVersion\"],false],[9],[0,\".\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Please press [Ctrl-F5] to reload and update to the new version.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shell/version-outdated/template.hbs"
    }
  });
  _exports.default = _default;
});