define("esis-ui/components/areas/shared/nz/nz-organisation-list-item-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ptMClmly",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"p-1 lookupOption \",[28,\"if\",[[28,\"not-eq\",[[24,[\"model\",\"item\",\"statusCode\"]],\"50\"],null],\"noSelectIcon\",\"\"],null]]]],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"_onItemSelect\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row mx-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-1 pt-1\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not-eq\",[[24,[\"model\",\"item\",\"statusCode\"]],\"50\"],null]],null,{\"statements\":[[0,\"        \"],[1,[28,\"fa-icon\",[\"times-circle\"],[[\"fixedWidth\",\"class\",\"size\"],[true,\"errorIcon\",\"lg\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"fa-icon\",[\"check-circle\"],[[\"fixedWidth\",\"class\",\"size\"],[true,\"okIcon\",\"lg\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-11\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[7,\"strong\",true],[8],[1,[24,[\"model\",\"title\"]],false],[9],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"NZBN: \"],[1,[24,[\"model\",\"item\",\"nzbn\"]],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"pl-5\"],[8],[0,\"Entity Type: \"],[1,[24,[\"model\",\"item\",\"typeDescription\"]],false],[9],[9],[0,\"\\n      \"],[7,\"div\",true],[8],[0,\"Status: \"],[1,[24,[\"model\",\"item\",\"statusDescription\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/nz/nz-organisation-list-item-display/template.hbs"
    }
  });
  _exports.default = _default;
});