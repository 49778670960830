define("esis-ui/components/areas/spg/au/au-spg-view-page-base", ["exports", "esis-ui/components/areas/shared/au-page-base", "ember-concurrency", "lodash"], function (_exports, _auPageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    convertToDelegate: (0, _emberConcurrency.task)(function* (spgGuid) {
      try {
        this.notifyInfo('Removing PPSR B2G Account from secured party group, this may take a few moments');
        yield this.get('spgRepo.convertSpgToDelegate').linked().perform(spgGuid);
        this.notifyInfo('Secured party group converted to delegate.');
      } catch (error) {
        // this.get('_formValidationErrorDlg').show(error.errorMessage);
        this.get('logger').logError('SpgViewPageBase.convertToDelegate', error);
      }
    }),
    //region TASK
    saveSpg: (0, _emberConcurrency.task)(function* (model) {
      try {
        let persisted = yield this.get('spgRepo.saveSecuredPartyGroup').perform(model);
        if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
          this.get('saveTimeoutTaskInstance').cancel();
          this.set('saveTimeoutTaskInstance', null);
        }
        if (persisted.isValid) {
          model.saveModel();
          this._createSpgModel(persisted.securedPartyGroupModel);
          this.get('model').saveModel();
          this.notifySuccess(`Secured party group saved. Id : ${persisted.securedPartyGroupModel.esisId}`);
        } else {
          this.notifyError(persisted.validationErrorMessage);
        }
      } catch (error) {
        if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
          this.get('saveTimeoutTaskInstance').cancel();
          this.set('saveTimeoutTaskInstance', null);
        }
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', error);
      }
    }).drop(),
    validateAndSaveAndSubmitSpg: (0, _emberConcurrency.task)(function* (model, isSubmit) {
      const physicalAddress = model.get('addressForServicePhysicalAddress');
      const mailingAddress = model.get('addressForServiceMailingAddress');
      let valid = model.validate() & physicalAddress.validate() & mailingAddress.validate();
      let filledError = physicalAddress.validate() & mailingAddress.validate();
      this.set('showFilledError', !filledError);
      if (valid) {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        try {
          let result = yield this.get('spgRepo.validateAndSaveSpg').perform(model);
          if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
            this.get('saveTimeoutTaskInstance').cancel();
            this.set('saveTimeoutTaskInstance', null);
          }
          if (result.isValid) {
            model.saveModel();
            this._createSpgModel(result.securedPartyGroupModel);
            this.notifySuccess(`Secured party group saved. Id : ${result.securedPartyGroupModel.esisId}`);
            /* Submit SPG */
            if (isSubmit) {
              this.notifyInfo('Submitting secured party group to PPSR ... this may take up to a minute');
              let timeoutTaskInstance = this.get('runTimeout').perform('submitSecuredPartyGroupTaskInstance');
              this.set('submitTimeoutTaskInstance', timeoutTaskInstance);
              this.set('submitSecuredPartyGroupTaskInstance', this.get('_submitSecuredPartyGroup').perform(result));
              yield this.get('submitSecuredPartyGroupTaskInstance');
            }
          } else {
            this.set('model.displayException', true);
            this.set('model.validatErrorMessage', result.validationErrorMessage);
            this.notifyError(`Validation error : ${result.validationErrorMessage}`);
          }
        } catch (error) {
          if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
            this.get('saveTimeoutTaskInstance').cancel();
            this.set('saveTimeoutTaskInstance', null);
          }
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', error);
        }
      } else {
        this.get('saveTimeoutTaskInstance').cancel();
        this.set('saveTimeoutTaskInstance', null);
        this.set('model.displayException', true);
        if (!filledError) {
          this.set('model.validatErrorMessage', 'Please fill physical/mailing address field');
        } else {
          this.set('model.validatErrorMessage', 'Please fill required fields');
        }
      }
    }),
    _submitSecuredPartyGroup: (0, _emberConcurrency.task)(function* (model) {
      try {
        let submitResponse = yield this.get('spgRepo.submitSpg').perform(model.securedPartyGroupModel.guid);
        if (!(0, _lodash.isEmpty)(this.get('submitTimeoutTaskInstance'))) {
          this.get('submitTimeoutTaskInstance').cancel();
          this.set('submitTimeoutTaskInstance', null);
        }
        this.get('logger').logDebug(`Secured party group submitted to PPSR successfully. Id : ${model.securedPartyGroupModel.esisId}`, submitResponse);
        this.notifySuccess(`Secured party group submitted to PPSR successfully. Id : ${model.securedPartyGroupModel.esisId}`);
        this.set('model', null);
        this.removeTab('spgListAu');
      } catch (e) {
        if (!(0, _lodash.isEmpty)(this.get('submitTimeoutTaskInstance'))) {
          this.get('submitTimeoutTaskInstance').cancel();
          this.set('submitTimeoutTaskInstance', null);
        }
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', e.errorMessage);
      }
    }),
    runTimeout: (0, _emberConcurrency.task)(function* (taskInstance) {
      yield (0, _emberConcurrency.timeout)(30000);
      if (!(0, _lodash.isEmpty)(this.get(taskInstance))) {
        this.get(taskInstance).cancel();
        this.set(taskInstance, null);
      }
      this.get('logger').logError(taskInstance + ': Saving timed out');
      this.notifyError(taskInstance + ' timed out, please try again');
    })
    //endregion
  });
  _exports.default = _default;
});