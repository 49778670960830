define("esis-ui/components/shared/slider/slider-component/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OMrMd+sW",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shared/slider/ember-ui-slider\",null,[[\"value\",\"ticks\",\"tooltip\",\"disabled\",\"handle\",\"min\",\"max\",\"tooltipPosition\",\"focus\",\"naturalArrowKeys\",\"immediateResponse\",\"mood\",\"action\",\"changed\",\"error\",\"valueChanged\",\"sectionsAction\"],[[24,[\"value\"]],[24,[\"ticks\"]],[24,[\"tooltip\"]],[24,[\"disabled\"]],\"round\",[24,[\"min\"]],[24,[\"max\"]],\"top\",false,false,true,\"info\",[28,\"action\",[[23,0,[]],\"action\"],null],[28,\"action\",[[23,0,[]],\"changed\"],null],[28,\"action\",[[23,0,[]],\"error\"],null],[28,\"action\",[[23,0,[]],\"valueChanged\"],null],[28,\"action\",[[23,0,[]],\"sectionsAction\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/slider/slider-component/template.hbs"
    }
  });
  _exports.default = _default;
});