define("esis-ui/storages/setting-cache", ["exports", "ember-local-storage/session/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Storage = _object.default.extend({});

  // Uncomment if you would like to set initialState
  Storage.reopenClass({
    initialState() {
      return {
        preferredTheme: 'theme-light'
      };
    }
  });
  var _default = Storage;
  _exports.default = _default;
});