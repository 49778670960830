define("esis-ui/models/role-permission", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //app/models/role-permission.js
  var _default = _modelBase.Model.extend({
    roleId: (0, _modelBase.attr)(),
    permissionId: (0, _modelBase.attr)(),
    permissionName: (0, _modelBase.attr)(),
    permissionDisplayName: (0, _modelBase.attr)(),
    permissionDescription: (0, _modelBase.attr)(),
    selected: (0, _modelBase.attr)(),
    visible: (0, _modelBase.attr)(),
    disabled: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    permissionGroup: (0, _modelBase.attr)(),
    permissionGroupTitle: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.setProperties({
        disabled: false
      });
    }
  });
  _exports.default = _default;
});