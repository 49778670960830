define("esis-ui/components/areas/admin/security-roles/list/preview/security-role-preview/component", ["exports", "esis-ui/components/areas/admin/security-roles/security-role-page-base", "ember-concurrency", "lodash"], function (_exports, _securityRolePageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _securityRolePageBase.default.extend({
    //region Preview Tab Ids
    _permissionPanel: 'permissionPanel',
    //endregion

    //region COMPUTED PROPERTIES
    _lastIndex: Ember.computed('model.@each', function () {
      return this.get('model').length - 1;
    }),
    //endregion

    //region TASKS
    _loadPreview: (0, _emberConcurrency.task)(function* (data) {
      try {
        let preview = yield this.get('adminRepo.loadSecurityRoleDetailDigest').perform(data);
        this.set('model', this.convertPermissionToDisplay((0, _lodash.filter)(preview.permissions, 'selected'), 'permissionGroupTitle'));
      } catch (error) {
        this.get('logger').logError('security-role-preview._loadPreview', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onTabLoad(tabModel) {
        this.get('_loadPreview').perform(tabModel.guid);
      }
    }
    //endregion
  });
  _exports.default = _default;
});