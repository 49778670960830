define("esis-ui/services/logging-service", ["exports", "esis-ui/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    app: Ember.inject.service('app-service'),
    constants: Ember.inject.service('app-constants'),
    logDebug: function logDebug(message, ...args) {
      if (_environment.default.APP.LOG_DEBUG) {
        console.debug(message, args);
      }
    },
    logInfo: function logInfo(message, ...args) {
      if (_environment.default.APP.LOG_INFO) {
        console.info(message, args);
      }
    },
    logWarning: function logWarning(message, ...args) {
      if (_environment.default.APP.LOG_WARN) {
        console.warn(message, args);
      }
    },
    logError: function logError(title, exception, ...args) {
      if (_environment.default.APP.LOG_ERROR !== false) {
        console.error(`Minerva exception : ${title}`, exception, args);
        exception = exception || 'unknown exception';
        const data = {
          error: JSON.stringify(exception || 'undefined exception'),
          stack: exception.stack ? exception.stack : 'no stack info',
          title: title,
          props: JSON.stringify(args || {})
        };
        this.get('logErrorToRemoteService').perform(data);
      }
    },
    logErrorToRemoteService: (0, _emberConcurrency.task)(function* (data) {
      return yield this.get('app').postAjaxAnonymous(this.get('constants.apiEndpoint.logError'), data);
    }).drop()
  });
  _exports.default = _default;
});