define("esis-ui/components/shared/buttons/action-button/component", ["exports", "esis-ui/components/shared/buttons/button-base"], function (_exports, _buttonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _buttonBase.default.extend({
    noLabel: Ember.computed('iconOnly', 'labelLeft', function () {
      return this.iconOnly || this.labelLeft;
    })
  });
  _exports.default = _default;
});