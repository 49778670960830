define("esis-ui/components/areas/admin/business-entity/view/business-entity-add-user-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tvuI01pH",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"registerAs\",\"modalTitle\",\"onConfirm\",\"submitForm\",\"name\"],[[24,[\"_dialog\"]],[24,[\"modalTitle\"]],[28,\"action\",[[23,0,[]],\"_onConfirm\"],null],false,\"addSystemUserModal\"]],{\"statements\":[[0,\"  \"],[7,\"form\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"control-label field-label pl-0 col-md-4\"],[8],[0,\"System User\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-md-8 pl-0\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"allowClear\",\"searchEnabled\",\"searchField\",\"triggerClass\",\"onChange\",\"renderInPlace\"],[[24,[\"_systemUsers\"]],[24,[\"_selectedSystemUser\"]],false,true,\"title\",\"powerSelectDropdown\",[28,\"action\",[[23,0,[]],\"_onChooseSystemUser\"],null],true]],{\"statements\":[[0,\"          \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/admin/business-entity/view/business-entity-add-user-modal/template.hbs"
    }
  });
  _exports.default = _default;
});