define("esis-ui/components/areas/admin/contract-type/au/list/au-contract-type-list/component", ["exports", "esis-ui/components/areas/shared/au-page-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _auPageBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    //region INITIALIZATION
    _contractTypeGrid: null,
    _selectedContractType: null,
    _selectedBusinessEntity: null,
    init() {
      this._super(...arguments);
      const app = this.get('app');
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklistMethod();

      //region CONTRACT TYPES GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('contract-types')
            },
            parameterMap: (options, operation) => {
              options.businessEntityGuid = this.get('_selectedBusinessEntity.value');
              let keyword = this.get('_search');
              if (!Ember.isBlank(keyword)) {
                options.filterKeyword = keyword;
              }
              options._ = app.guid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                realData[i].dateEditedDisplay = this.dateTimeAsStr(realData[i].dateEdited);
                realData[i].collateralType = realData[i].isConsumer ? 'Consumer' : 'Commercial';

                //context menu disable logic
                let enableDelete = realData[i].canDelete;
                let canManageContractType = this.get('credential.hasCreateContractTypePermission');
                // order is important. It must be the same as action options order (_contractTypeGridCxtMenu below)
                realData[i].disableActionStates = [!(enableDelete && canManageContractType)];
              }
              return realData;
            }
          }
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "contractTypeCode",
          title: 'Code',
          filterable: false,
          width: "150px",
          template: '<a href="\\#" data-id="#:guid#" class="grid-link-id">#: contractTypeCode #</a>'
        }, {
          field: "title",
          title: 'Title',
          filterable: false,
          width: "180px"
        }, {
          field: "description",
          title: 'Description',
          filterable: false,
          width: "180px"
        }, {
          field: "isConsumer",
          title: 'Collateral Type',
          filterable: false,
          width: "110px",
          template: '#: collateralType #'
        }, {
          field: 'entityTitle',
          title: 'Business Entity',
          filterable: false,
          width: "200px"
        }, {
          field: "userEdited",
          title: 'User',
          filterable: false,
          width: "120px"
        }, {
          field: "dateEdited",
          title: 'Date',
          filterable: false,
          width: "120px",
          template: '#: dateEditedDisplay #'
        }, {
          field: "isActive",
          title: 'Active',
          filterable: false,
          width: "60px",
          attributes: {
            "class": "text-center"
          },
          template: `<span class=' #: isActive ? 'isActiveStyle' : 'isNotActiveStyle' #'>${this.get('constants.fIcon.squareFull')}</span>`
        }]
      });

      //Context Menu Action Ids
      this._deleteActionId = 'auDeleteContractTypeId';
      this._contractTypeGridCxtMenu = [{
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _loadPreview: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('adminRepo.loadContractTypeDetailDigest').perform(this.get('_selectedContractType.guid'));
        this.set('model', result);
      } catch (error) {
        this.get('logger').logError('contract-type-list._loadPreview', error);
      }
    }),
    _delete: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('logger').logDebug('Deleting contract type', this.get('_selectedContractType.id'));
        yield this.get('adminRepo.deleteContractType').perform(this.get('_selectedContractType.guid'));
        this.notifySuccess(`Contract type ${this.get('_selectedContractType.contractTypeCode')} has been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('contract-type-list._delete', error);
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_contractTypeGrid'))) {
          if (reset === true) {
            this.set('_selectedContractType', null);
          }
          this.get('_contractTypeGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    onScreenChange() {
      this._refreshGrid(true); // soft refresh
    },

    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedContractType')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedContractType', data);
        if (!(0, _lodash.isEmpty)(this.get('_selectedContractType'))) {
          this._loadPreview.perform();
        }
      },
      _onLinkClick(selectedItem) {
        const guid = selectedItem.data('id');
        this.addTab('contractTypeEditAu', guid);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedContractType', selectedRow);
          if (selectedOption === this.get('_deleteActionId')) {
            if (!(0, _lodash.isNil)(selectedRow.id)) {
              this.set('_deleteConfirmMessage', `Are you sure you want to delete contract type ${selectedRow.contractTypeCode}? This action is not reversible.`);
              this.get('_deleteConfirmDlg').show();
            }
          }
        }
      },
      _onConfirmDelete() {
        this._delete.perform();
      },
      //region FILTER ACTIONS
      _onSearch: function _onSearch(value) {
        this.set('_search', value);
        this._refreshGrid(true);
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      },
      //endregion

      //region BUTTON ACTIONS
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _onAdd() {
        this.addTab('contractTypeEditAu', null, this.get('_selectedBusinessEntity.value'));
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});