define("esis-ui/components/areas/shell/version-outdated/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _clientVersion: null,
    _serverVersion: null,
    show(clientVersion, serverVersion) {
      this.setProperties({
        _clientVersion: clientVersion,
        _serverVersion: serverVersion
      });
      this.get('_dialog').show();
    },
    actions: {
      onReload: function onReload() {
        window.location.reload(true);
      }
    }
  });
  _exports.default = _default;
});