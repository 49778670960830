define("esis-ui/components/areas/shell/empty-business-entity-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "u6yoP0VD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"registerAs\",\"closeButton\",\"confirmBtnLabel\",\"modalTitle\",\"name\",\"onConfirm\"],[[24,[\"_dialog\"]],false,\"OK\",\"Warning\",\"emptyBEModal\",[28,\"action\",[[23,0,[]],\"_onConfirm\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"You do not have access to any active transactional business entity.\"],[9],[0,\"\\n        \"],[7,\"p\",true],[8],[0,\"Please contact your administrator.\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shell/empty-business-entity-modal/template.hbs"
    }
  });
  _exports.default = _default;
});