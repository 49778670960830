define("esis-ui/components/areas/admin/business-entity/view/business-entity-view/component", ["exports", "esis-ui/components/areas/admin/admin-page-base", "ember-concurrency"], function (_exports, _adminPageBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adminPageBase.default.extend({
    //region INITIALIZATION ---
    _currentBusinessEntityPanel: 'currentBusinessEntityPanel',
    _allBusinessEntityPanel: 'allBusinessEntityPanel',
    init() {
      this._super(...arguments);
      this.set('model', {
        guid: this.get('credential.currentBusinessEntity')
      });
    },
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('adminRepo.loadBusinessEntityModel').perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('model', result);
      } catch (error) {
        this.get('logger').logError('business-entity-view._loadModel', error);
      }
    }),
    actions: {
      _onClickRefresh() {
        this.get('_currentBusinessEntityView').refreshData();
        this.get('_allBusinessEntityView').refreshData();
      },
      _onClickCreate() {
        this.get('_businessEntityEditModal').show();
      },
      _onRefreshData() {
        this._loadModel.perform();
      }
    }
  });
  _exports.default = _default;
});