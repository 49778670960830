define("esis-ui/components/shared/theme-selection-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jYgRem72",
    "block": "{\"symbols\":[\"userTheme\"],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"onConfirm\",\"modalTitle\",\"onCancel\",\"confirmBtnLabel\",\"cancelBtnLabel\",\"isGlobal\",\"registerAs\"],[[28,\"action\",[[23,0,[]],\"onSelect\"],null],\"Select Theme\",[28,\"action\",[[23,0,[]],\"onCancel\"],null],\"Ok\",\"Cancel\",true,[24,[\"_dialog\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"input-group\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"searchField\",\"allowClear\",\"triggerClass\",\"placeholder\",\"onChange\",\"renderInPlace\"],[[24,[\"_themes\"]],[24,[\"selectedTheme\"]],\"title\",false,\"powerSelectDropdown\",\"Select Theme\",[28,\"action\",[[23,0,[]],\"chooseTheme\"],null],true]],{\"statements\":[[0,\"        \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/theme-selection-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});