define("esis-ui/components/areas/spg/au/wizard/au-spg-service-address-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2i5zms1Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-12 mx-auto row d-felx justify-content-center\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xl-5 col-lg-6 col-md-6\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"shared/section-header\",null,[[\"title\",\"showClearButton\",\"smallWidth\",\"onClearAction\"],[\"Mailing Address\",true,true,[28,\"action\",[[23,0,[]],\"_clearMailingAddress\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"hr\",true],[10,\"class\",\"mb-3\"],[8],[9],[0,\"\\n    \"],[1,[28,\"areas/spg/au/view/au-address-form\",null,[[\"address\",\"registerAs\",\"showLarge\",\"reviewMode\",\"mandatory\"],[[24,[\"model\",\"addressForServiceMailingAddress\"]],[24,[\"_mailingAddress\"]],true,false,true]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col-xl-5 col-lg-6 col-md-6 pr-4\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"shared/section-header\",null,[[\"title\",\"showClearButton\",\"onClearAction\"],[\"Physical Address\",true,[28,\"action\",[[23,0,[]],\"_clearPhysicalAddress\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"hr\",true],[10,\"class\",\"mb-3\"],[8],[9],[0,\"\\n    \"],[1,[28,\"areas/spg/au/view/au-address-form\",null,[[\"address\",\"registerAs\",\"showLarge\",\"reviewMode\",\"mandatory\"],[[24,[\"model\",\"addressForServicePhysicalAddress\"]],[24,[\"_physicalAddress\"]],true,false,true]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex flex-row-reverse mb-3\"],[8],[0,\"\\n      \"],[1,[28,\"shared/buttons/action-button\",null,[[\"title\",\"onClick\",\"type\"],[\"Copy from Mailing Address\",[28,\"action\",[[23,0,[]],\"_copy\"],null],\"primary\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/spg/au/wizard/au-spg-service-address-step/template.hbs"
    }
  });
  _exports.default = _default;
});