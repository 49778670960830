define("esis-ui/utils/model-base", ["exports", "esis-ui/mixins/esis-utils", "esis-ui/mixins/model-validator", "lodash"], function (_exports, _esisUtils, _modelValidator, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.attr = _exports.Model = void 0;
  /*
   * NOTE this file has been modified from its orginal version from github
   * https://github.com/toranb/ember-cli-simple-store/blob/master/addon/model.js
   * */

  function equal(first, second) {
    if (first instanceof Array && second instanceof Array) {
      return first.length === second.length && first.every((v, i) => v === second[i]);
    }
    return first === second;
  }
  function factory(obj) {
    return obj.get("constructor.PrototypeMixin.ownerConstructor");
  }
  function attrs(obj, onlyArry = false) {
    const all = [];
    if (onlyArry) {
      factory(obj).eachComputedProperty(function (key, meta) {
        if (meta.arrayAttr) {
          all.push(key);
        }
      });
    } else {
      factory(obj).eachComputedProperty(function (key, meta) {
        if (meta.isAttribute) {
          all.push(key);
        }
      });
    }
    return all;
  }
  function clone(obj) {
    let copy = {};
    factory(obj).eachComputedProperty(function (key, meta) {
      if (meta.isAttribute) {
        copy[key] = obj.get(key);
      }
    });
    return copy;
  }
  const attr = function attr() {
    let meta = {
      isAttribute: true,
      defaults: arguments[0],
      arrayAttr: arguments[1] === 'array'
    };
    return Ember.computed('_data', {
      get(key) {
        let data = this.get('_data') || {};
        return data[key];
      },
      set(key, value) {
        let data = this.get('_data') || {};
        let dirty = this.get('_dirty') || {};
        let primed = this.get('_primed') || {};
        let defaults = this.get('_defaults') || {};
        defaults[key] = meta.defaults;
        if (!this.get('isDirty')) {
          this.set('_oldState', clone(this));
        }
        let ready = value === '' && Ember.isNone(data[key]);
        dirty[key + ':isDirty'] = true;
        data[key] = value;
        if (!ready && !primed[key + ':isPrimed']) {
          primed[key + ':isPrimed'] = true;
        }
        return data[key];
      }
    }).meta(meta);
  };
  _exports.attr = attr;
  const Model = Ember.Object.extend(_modelValidator.default, _esisUtils.default, {
    init() {
      this._super(...arguments);
      /* Validator */
      this.errors = {};
      this._reset();
      this._setup();
      this.set('_defaults', {});
      this.set('_data', clone(this));
      this.set('_oldState', clone(this));
    },
    rollback() {
      let oldState = this.get('_oldState');
      for (let key in oldState) {
        this.set(key, oldState[key]);
      }
      this._reset();
    },
    save() {
      let oldState = clone(this);
      this.set('_oldState', oldState);
      this._reset();
      return this;
    },
    clone() {
      let copy = clone(this);
      return copy;
    },
    copy() {
      let attributes = attrs(this);
      return Ember.run(this.constructor, 'create', this.getProperties(attributes));
    },
    properties() {
      return attrs(this);
    },
    isNew: Ember.computed('id', function () {
      return Ember.isBlank(this.get('id')) || this.get('id') === 0;
    }),
    isExisting: Ember.computed.not('isNew'),
    validationError: Ember.computed('errors', function () {
      if (Ember.isPresent(this.get('errors'))) {
        var result = this.$.map(this.get('errors'), function (value, key) {
          const label = key.decamelize().replace(/_/g, ' ').capitalize();
          return `${label} : ${value}`;
        });
        return result;
      }
      return [];
    }),
    _reset() {
      this.set('isPrimed', false);
      this.set('_dirty', {});
      this.set('_primed', {});
    },
    _setup() {
      let model = this;
      let attributes = attrs(this);
      let arrayAttrs = attrs(this, true);
      attributes.forEach(attrName => {
        Ember.defineProperty(model, attrName + 'IsDirty', Ember.computed('_dirty', '_defaults', '' + attrName, function () {
          let current = this.get(attrName);
          let defaults = this.get('_defaults')[attrName];
          let original = this.get('_oldState.' + attrName);
          let dirty = this.get('_dirty');
          let dirtyKey = attrName + ':isDirty';
          let legit = equal(current, defaults) && Ember.isNone(original) || equal(original, current);
          return legit ? undefined : dirty[dirtyKey];
        }));
        let dynamicPrimedKey = attrName + 'IsPrimed';
        Ember.defineProperty(model, dynamicPrimedKey, Ember.computed('_primed', '' + attrName, function () {
          let primed = this.get('_primed');
          let primedKey = attrName + ':isPrimed';
          return primed[primedKey];
        }));
      });
      let modelIsDirtyAttrs = attributes.map(attr => attr + 'IsDirty');
      let modelArrayIsDirty = arrayAttrs.map(attr => attr + '.@each.isDirty');
      let nestedModelIsDirty = attributes.map(attr => attr + '.isDirty');
      Ember.defineProperty(model, 'isNotDirty', Ember.computed.not('isDirty'));
      Ember.defineProperty(model, 'isDirty', Ember.computed('attrIsDirty', 'arrayIsDirty', 'nestedModelIsDirty', function () {
        return Ember.get(model, 'attrIsDirty') || Ember.get(model, 'arrayIsDirty') || Ember.get(model, 'nestedModelIsDirty');
      }));
      Ember.defineProperty(model, 'nestedModelIsDirty', Ember.computed(...nestedModelIsDirty, function () {
        return nestedModelIsDirty.some(attr => {
          return Ember.get(model, attr) === true;
        });
      }));
      Ember.defineProperty(model, 'attrIsDirty', Ember.computed(...modelIsDirtyAttrs, function () {
        return modelIsDirtyAttrs.some(attr => {
          return Ember.get(model, attr) === true;
        });
      }));
      if (!(0, _lodash.isEmpty)(arrayAttrs)) {
        Ember.defineProperty(model, 'arrayIsDirty', Ember.computed(...modelArrayIsDirty, function () {
          let dirty = false;
          (0, _lodash.forEach)(arrayAttrs, arrAttr => {
            if (!(0, _lodash.isEmpty)(Ember.get(model, arrAttr))) {
              (0, _lodash.forEach)(Ember.get(model, arrAttr), att => {
                dirty = dirty || Ember.get(att, 'isDirty') === true || Ember.get(att, 'arrayIsDirty') === true;
              });
            }
          });
          return dirty;
        }));
      }
    }
  });
  _exports.Model = Model;
});