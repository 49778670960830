define("esis-ui/models/secured-party-nz", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    securedPartyType: (0, _modelBase.attr)(),
    nzbn: (0, _modelBase.attr)(),
    nzbnValidated: (0, _modelBase.attr)(),
    phoneNumber: (0, _modelBase.attr)(),
    faxNumber: (0, _modelBase.attr)(),
    mailingAddress: (0, _modelBase.attr)(),
    contactAddress: (0, _modelBase.attr)(),
    email: (0, _modelBase.attr)(),
    organisationName: (0, _modelBase.attr)(),
    personFirstName: (0, _modelBase.attr)(),
    personLastName: (0, _modelBase.attr)(),
    personMiddleNames: (0, _modelBase.attr)(),
    personDateOfBirth: (0, _modelBase.attr)(),
    personActingOnBehalf: (0, _modelBase.attr)(),
    securedPartyId: (0, _modelBase.attr)(),
    isOrganisation: Ember.computed('securedPartyType', function () {
      return this.get('securedPartyType') === 2;
    }),
    hasMailingAddress: Ember.computed('mailingAddress', function () {
      return !(0, _lodash.isEmpty)(this.get('mailingAddress'));
    }),
    saveModel: function saveModel() {
      this.save();
      this.contactAddress.save();
      if (this.hasMailingAddress) this.mailingAddress.save();
      if (this.isOrganisation) {
        this.personActingOnBehalf.save();
        this.personActingOnBehalf.contactAddress.save();
        if (this.personActingOnBehalf.hasMailingAddress) this.personActingOnBehalf.mailingAddress.save();
      }
    },
    init() {
      this._super(...arguments);
      this.validations = {
        organisationName: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('securedPartyType') === 2) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Organisation can not be blank'
          }
        },
        nzbn: {
          custom: [{
            validation: function validation(key, value) {
              if (Ember.isPresent(value)) {
                return value.length === 13;
              }
              return true;
            },
            message: 'NZBN must be 13 digits'
          }]
        },
        personFirstName: {
          custom: [{
            validation: function validation(key, value, model) {
              if (model.get('securedPartyType') === 1) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'First Name can not be blank'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length < 2);
              }
              return true;
            },
            message: "First Name can't be less than two characters"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 40);
              }
              return true;
            },
            message: "First Name can't be more than 40 characters"
          }]
        },
        personLastName: {
          custom: [{
            validation: function validation(key, value, model) {
              if (model.get('securedPartyType') === 1) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Last Name can not be blank'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length < 2);
              }
              return true;
            },
            message: "First Name can't be less than two characters"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 40);
              }
              return true;
            },
            message: "First Name can't be more than 40 characters"
          }]
        },
        personMiddleNames: {
          custom: {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 60);
              }
              return true;
            },
            message: "Middle Names can't be more than 60 characters"
          }
        },
        email: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Email can not be blank'
          }
        }
      };
    }
  });
  _exports.default = _default;
});