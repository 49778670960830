define("esis-ui/components/areas/registration/au/express-wizard/au-registration-express/component", ["exports", "esis-ui/components/areas/registration/au/au-registration-base", "ember-concurrency", "lodash"], function (_exports, _auRegistrationBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auRegistrationBase.default.extend({
    _selectedContractType: null,
    _showGrantorAddPanel: true,
    _error: null,
    init() {
      this._super(...arguments);
      this._loadPicklists.perform();
      this.set('_error', null);
      this.set('model', this._createRegistrationModel());
      this.set('grantor', this._createGrantor());
    },
    //region COMPUTED PROPERTIES
    _collateralsAdded: Ember.computed('model.collaterals.@each', function () {
      return !(0, _lodash.isEmpty)(this.get('model.collaterals'));
    }),
    //endregion

    //region METHODS
    _addCollateral(newCollateral) {
      newCollateral.set('id', 0);
      this.get('model.collaterals').unshiftObjects(newCollateral);
      this._setShowGrantorAddPanel();
    },
    _setShowGrantorAddPanel() {
      if (this.get('model.isConsumerCollateral')) {
        this.set('_showGrantorAddPanel', !this.get('model.hasSerialisedCollateral'));
        this.set('grantor.grantorType', this.get('constants.grantorType.individual'));
      } else {
        this.set('_showGrantorAddPanel', true);
      }
    },
    _onRegistrationSaved(returnedModel) {
      const registration = this._mapApiRegistrationResponseToModel(returnedModel);
      this._setShowGrantorAddPanel();
      this.set('model', registration);
    },
    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const entityGuid = this.get('credential.currentBusinessEntityGuid');
        //CATEGORIES
        let categoryPicklist = yield this.get('picklistRepo.loadCategories').linked().perform(entityGuid);
        this.set('_categoryList', categoryPicklist);

        //SPGs
        let spgPicklist = yield this.get('picklistRepo.loadSpgListForRegistration').linked().perform(entityGuid);
        this.set('_spgList', spgPicklist);
        this.set('_selectedSpg', spgPicklist[0]);
        this.set('model.securedPartyGroupGuid', this.get('_selectedSpg.item.guid'));
        this.set('model.isGoniRequired', this.get('_selectedSpg.item.isGoniRequired'));
        this.get('model').save();

        //CONTRACTS
        let contractPicklist = yield this.get('picklistRepo.loadContractTypes').linked().perform(entityGuid);
        this.set('_contractList', contractPicklist);
        this.set('_filteredContractList', (0, _lodash.filter)(this.get('_contractList'), {
          'item': {
            'collateralType': this.get('model.collateralType')
          }
        }));
        this.set('_selectedContractType', this.get('_filteredContractList') ? this.get('_filteredContractList')[0] : null);
      } catch (error) {
        this.get('logger').logError('au-express-wizard._loadPicklist', error);
      }
    }),
    _loadContractTypeDefaults: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        let result = yield this.get('registrationRepo.loadContractTypeDefaults').perform(this.get('_selectedContractType.item.guid'));
        let contractTypes = [];
        (0, _lodash.forEach)(result, item => {
          let copy = this.mapJsonObjectToModel(item, 'collateral');
          if (copy.get('areProceedsClaimed') === true && Ember.isBlank(copy.get('proceedsClaimedDescription'))) {
            copy.set('proceedsClaimedDescription', constants.defaultAreProceedClaimedDescription);
          }
          contractTypes.pushObject(copy);
        });
        let hasAnyOfTheseClassTypes = !(0, _lodash.isEmpty)((0, _lodash.find)(contractTypes, item => {
          return item.collateralClassType === constants.searchCollateralClassType.motorVehicle || item.collateralClassType === constants.searchCollateralClassType.watercraft || item.collateralClassType === constants.searchCollateralClassType.patent || item.collateralClassType === constants.searchCollateralClassType.design || item.collateralClassType === constants.searchCollateralClassType.plantBreedersRight || item.collateralClassType === constants.searchCollateralClassType.tradeMark || item.collateralClassType === constants.searchCollateralClassType.smallAircraft;
        }));
        if (hasAnyOfTheseClassTypes) {
          let hasSerialised = false;
          (0, _lodash.forEach)(contractTypes, item => {
            if (item.isSerialised) {
              if (item.isMotorVehicle) {
                item.set('serialNumberType', constants.serialNumberType.VIN);
                item.set('regOrDescValue', true);
              } else if (item.isWatercraft) {
                item.set('serialNumberType', constants.serialNumberType.HIN);
              } else if (item.collateralClassType === constants.searchCollateralClassType.smallAircraft) {
                item.set('serialNumberType', constants.serialNumberType.AircraftNationalityCodeAndRegistrationMark);
              }
              hasSerialised = true;
            }
          });
          if (hasSerialised && !(0, _lodash.isEmpty)(this.get('_expressModal'))) {
            this.get('_expressModal').show(contractTypes);
          } else {
            this._addCollateral(contractTypes);
          }
        } else {
          this._addCollateral(contractTypes);
        }
      } catch (error) {
        this.get('logger').logError('au-registration-express._loadContractTypeDefaults', error);
      }
    }),
    //endregion
    actions: {
      //region submit registration
      _submitRegistration: function _submitRegistration(...args) {
        if (this.credential.currentBusinessEntity.item.enforceCategoryCode) {
          if ((0, _lodash.isEmpty)(this.get('model.categoryGuid'))) {
            this.set('_error', "can't be blank");
          } else {
            if (!(0, _lodash.isEmpty)(args)) {
              const model = args[0];
              let force = false;
              if (args.length > 1) {
                force = args[1];
              }
              if (force !== true && this.get('grantor.isDirty')) {
                this.get('_submitInfoDlg').show('You have partially completed grantor information that will not be included in this registration. Are you sure you want to submit?', model, true);
              } else {
                this._super(model);
              }
            }
          }
        } else {
          if (!(0, _lodash.isEmpty)(args)) {
            const model = args[0];
            let force = false;
            if (args.length > 1) {
              force = args[1];
            }
            if (force !== true && this.get('grantor.isDirty')) {
              this.get('_submitInfoDlg').show('You have partially completed grantor information that will not be included in this registration. Are you sure you want to submit?', model, true);
            } else {
              this._super(model);
            }
          }
        }
      },
      //endregion

      //region Picklists Selection Changed
      _onChangeCategory(selected) {
        this.set('_selectedCategory', selected);
        this.set('model.categoryGuid', selected.value);
        this.set('_error', null);
      },
      _onChangeSpg(selected) {
        this.set('_selectedSpg', selected);
        this.set('model.securedPartyGroupGuid', selected.item.guid);
        this.set('model.isGoniRequired', selected.item.isGoniRequired);
        this.set('model.errors', []);
      },
      _onChangeContractType(selected) {
        this.set('_selectedContractType', selected);
      },
      _onCollateralTypeChange(value) {
        this.set('model.collateralType', value);
        this.set('_filteredContractList', (0, _lodash.filter)(this.get('_contractList'), {
          'item': {
            'collateralType': this.get('model.collateralType')
          }
        }));
        this.set('_selectedContractType', this.get('_filteredContractList') ? this.get('_filteredContractList')[0] : null);

        //
        this._setShowGrantorAddPanel();
      },
      //endregion
      //BUTTONS
      _onClickAddCollateral() {
        if (!(0, _lodash.isEmpty)(this.get('_selectedContractType'))) {
          this._loadContractTypeDefaults.perform();
        }
      },
      //
      _onConfirmAddCollateral(newCollateral) {
        this._addCollateral(newCollateral);
      },
      _onClickAddGrantor(bypassValidation, grantorReference) {
        const grantor = this.get('grantor');
        if (Ember.isPresent(bypassValidation)) {
          grantor.set('bypassValidation', bypassValidation);
        }
        if (Ember.isPresent(grantorReference)) {
          grantor.set('grantorReference', grantorReference);
        }
        if (grantor.grantorType === this.get('constants.grantorType.orgLookup')) {
          grantor.set('grantorType', this.get('constants.grantorType.organisation'));
        }
        let valid = grantor.validate();
        if (valid) {
          const grantors = this.get('model.grantors');
          grantor.set('sequenceNumber', grantors.length);
          grantors.unshiftObject(grantor);
          this.set('grantor', this._createGrantor());
          this.get('_grantorEdit').resetGrantor();
        }
      },
      _deleteGrantor(item) {
        const grantors = this.get('model.grantors');
        if (!(0, _lodash.isEmpty)(grantors)) {
          grantors.removeObject(item);
        }
      },
      _deleteCollateral: function _deleteCollateral(item) {
        const collaterals = this.get('model.collaterals');
        if (!(0, _lodash.isEmpty)(collaterals)) {
          collaterals.removeObject(item);
        }
        this._setShowGrantorAddPanel();
      },
      _onAddLookupOrg(result) {
        this.get('grantor').setProperties({
          organisationName: result.organisationName,
          organisationEntityTypeDescription: result.entityTypeDescription,
          abnStatus: result.abnStatus,
          acnStatus: result.abnStatus,
          abnIsCurrent: result.abnStatus,
          acnIsCurrent: result.abnStatus,
          organisationNumber: result.preferredOrganisationNumber,
          organisationNumberType: result.preferredOrganisationNumberType
        });
        this.get('_grantorEdit').setOrgNumberTypeTitle(result.preferredOrganisationNumberType);
      }
    }
  });
  _exports.default = _default;
});