define("esis-ui/initializers/toastr", ["exports", "ember-toastr/initializers/toastr", "esis-ui/config/environment"], function (_exports, _toastr, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const toastrOptions = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '4000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  };
  const config = _environment.default['ember-toastr'] || {
    injectAs: 'toast',
    toastrOptions: toastrOptions
  };
  var _default = {
    name: 'ember-toastr',
    initialize() {
      // support 1.x and 2.x
      var application = arguments[1] || arguments[0];
      if (!config.toastrOptions) {
        config.toastrOptions = toastrOptions;
      }
      if (!config.injectAs) {
        config.injectAs = 'toast';
      }
      (0, _toastr.initialize)(application, config);
    }
  };
  _exports.default = _default;
});