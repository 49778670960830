define("esis-ui/components/areas/shared/help-video-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _requestModel: null,
    show() {
      this.set('_requestModel', this.createModel('create-help-video'));
      this.get('_dialog').show();
    },
    onAddVideo() {},
    actions: {
      _onConfirm() {
        if (this.get('_requestModel').validate()) {
          this.get('_dialog').hide();
          this.get('onAddVideo')(this.get('_requestModel'));
        }
      }
    }
  });
  _exports.default = _default;
});