define("esis-ui/components/areas/login/logout-button/component", ["exports", "esis-ui/components/areas/login/login-page-base"], function (_exports, _loginPageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _loginPageBase.default.extend({
    actions: {
      logout: function logout() {
        this.get('session').invalidate();
      }
    }
  });
  _exports.default = _default;
});