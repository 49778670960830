define("esis-ui/components/areas/registration/au/au-registration-base", ["exports", "esis-ui/components/areas/shared/au-page-base", "ember-concurrency", "lodash"], function (_exports, _auPageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    //region dialogs
    /*
    these dialogs should be included in the child components inherit from AU registration page base
    these dialogs are required for shared function to work, these are kind of virtual declaration
     */
    _dischargeConfirmationDlg: null,
    //region CITEC modal dlg
    _waitingModal: null,
    _citecFeeModal: null,
    _citecTopUpModal: null,
    _citecSaveRegModal: null,
    //endregion
    //endregion

    //region callback events
    _onDischarged: function _onDischarged() {},
    _onRegistrationSaved: function _onRegistrationSaved() {},
    //endregion

    //region private methods
    _showWaitModalDlg() {
      if (this.get('_isCitecUser') === true && !(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing !== true) {
        this.get('_waitingModal').show('Please be patient while we are submitting your request...');
      }
    },
    _hideWaitModalDlg() {
      if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing === true) {
        this.get('_waitingModal').hide();
      }
    },
    _closeRegistrationTab() {
      this.removeTab('registrationListAu');
    },
    //endregion

    //region protected shared method with children
    _createRegistrationModel() {
      const rs = this.mapJsonObjectToModel({
        entityGuid: this.get('credential.currentBusinessEntityGuid'),
        collateralType: this.get('constants.collateralType.commercial'),
        itemDeleted: false,
        canDelete: true,
        grantors: [],
        collaterals: [],
        errors: []
      }, 'registration');
      rs.saveModel();
      return rs;
    },
    _mapApiRegistrationResponseToModel(RegistrationResponse) {
      let registration = this.mapJsonObjectToModel(RegistrationResponse, 'registration');
      registration.setProperties({
        grantors: [],
        collaterals: [],
        errors: []
      });
      if (!(0, _lodash.isEmpty)(RegistrationResponse.grantors)) {
        registration.set('grantors', this.mapJsonObjectArrayToModelArray(RegistrationResponse.grantors, 'grantor'));
      }
      if (!(0, _lodash.isEmpty)(RegistrationResponse.collaterals)) {
        registration.set('collaterals', this.mapJsonObjectArrayToModelArray(RegistrationResponse.collaterals, 'collateral'));
        if ((0, _lodash.isNil)(registration.get('collateralType'))) {
          registration.set('collateralType', registration.get('collaterals').objectAt(0).get('collateralType'));
        }
      }
      // if collateral type not set, then default to commercial
      if ((0, _lodash.isNil)(registration.get('collateralType'))) {
        registration.set('collateralType', this.get('constants.collateralType.commercial'));
      }
      if ((0, _lodash.isNil)(registration.get('linkedPpsrRegistrationNumber'))) {
        registration.set('linkedPpsrRegistrationNumber', null);
      }
      registration.saveModel();
      return registration;
    },
    _createGrantor() {
      const grantorModel = this.mapJsonObjectToModel({
        grantorType: this.get('constants.grantorType.orgLookup'),
        isConfirmed: false,
        action: 1 // add action
      }, 'grantor');
      if (this.get('model.isConsumerCollateral')) {
        grantorModel.set('grantorType', this.get('constants.grantorType.individual'));
      }
      grantorModel.save();
      return grantorModel;
    },
    _submitRegistration(registrationModel) {
      if (registrationModel.isValid) {
        registrationModel.setProperties({
          validatErrorMessage: '',
          displayException: false
        });
        this.get('_validateAndSubmitRegistration').perform(registrationModel);
      } else {
        registrationModel.set('validatErrorMessage', '');
        if ((0, _lodash.isEmpty)(registrationModel.collaterals)) {
          registrationModel.set('validatErrorMessage', 'At least one collateral should be added |');
          registrationModel.set('displayException', true);
        }
        if (!registrationModel.isGrantorValid) {
          let str = registrationModel.get('validatErrorMessage');
          let validatErrorMessage = !registrationModel.get('noGrantor') ? str + 'Grantor(s) must be specified |' : str + 'Grantor is not allowed for this type of collateral';
          registrationModel.set('validatErrorMessage', validatErrorMessage);
          registrationModel.set('displayException', true);
        }
        if (!(0, _lodash.isEmpty)(registrationModel.validationErrors)) {
          let str = registrationModel.get('validatErrorMessage');
          (0, _lodash.forEach)(registrationModel.validationErrors, (value /*, key*/) => {
            (0, _lodash.forEach)(value, errorMessage => {
              str += errorMessage;
            });
          });
          registrationModel.set('validatErrorMessage', str);
          registrationModel.set('displayException', true);
        }
      }
    },
    //endregion

    //region common action handler
    actions: {
      //region Discharge Registration
      _showDischargeConfirmation: function _showDischargeConfirmation(registrationModel) {
        this.get('_dischargeConfirmationDlg').show(`Are you sure you want to discharge registration ${registrationModel.get('ppsrRegistrationNumber')}?`, registrationModel);
      },
      _onConfirmDischarge(registrationModel) {
        this.get('_dischargeRegistrationTask').perform(registrationModel);
      },
      //endregion

      //region CITEC accounting dialog confirmation
      _onCitecTopup: function _onCitecTopup(topupUrl) {
        this._hideWaitModalDlg();
        window.open(topupUrl, '_blank');
        this.get('_citecTopUpModal').hide();
      },
      _onConfirmCitecFee: function _onConfirmCitecFee(registrationGuids) {
        try {
          this._showWaitModalDlg();
          this.get('_createAccountingTransactionForCitecClientTask').perform(registrationGuids).then(accountingResponse => {
            if (accountingResponse === true) {
              this.get('_submitRegistrationTask').perform(registrationGuids).then(() => {
                this._hideWaitModalDlg();
              });
            }
          });
        } catch (e) {
          this._hideWaitModalDlg();
          this.get('logger').logError('au-registration-base._onConfirmCitecFee', e);
        }
      },
      _onCancelCitec: function _onCancelCitec(registrationGuids) {
        this._hideWaitModalDlg();
        if (!(0, _lodash.isNil)(this.get('_citecSaveRegModal'))) {
          this.get('_citecSaveRegModal').show('Do you want to save changes to this registration and submit it later?', registrationGuids);
        }
      },
      _onCancelCitecTopup: function _onCancelCitecTopup() {
        this._hideWaitModalDlg();
      },
      _onCitecSaveRegistrationOnCancel: function _onCitecSaveRegistrationOnCancel() {
        this._closeRegistrationTab();
      },
      _onCitecDiscardRegistrationOnCancel: function _onCitecDiscardRegistrationOnCancel(registrationGuids) {
        this._hideWaitModalDlg();
        this.get('_deleteRegistrations').perform(registrationGuids).then(() => {
          this._closeRegistrationTab();
        });
      },
      //endregion

      //region SUBMIT Registration
      _submitRegistration: function _submitRegistration(registrationModel) {
        this._submitRegistration(registrationModel);
      },
      //endregion

      //region SAVE DRAFT Registration (creating or amending)
      _saveDraftRegistration: function _saveDraftRegistration(registrationModel) {
        this.get('_saveDraftRegistrationTask').perform(registrationModel);
      },
      //endregion

      //region Download Registration Verification Statement
      _downloadRegistrationVerification(registrationGuid) {
        this.get('_downloadRegistrationVerificationTask').perform(registrationGuid);
      }
      //endregion
    },

    //endregion

    // region api tasks

    //region Validate, Save, and Submit Registration
    _validateAndSubmitRegistration: (0, _emberConcurrency.task)(function* (registrationModel) {
      try {
        const constants = this.get('constants');
        this._showWaitModalDlg();
        /*
        the discharge call does retrieve, change, create accounting transaction, submit in one go unless is CITEC client.
         */
        const validateResponse = yield this.get('registrationRepo.validateAndSaveRegistration').linked().perform(registrationModel);
        if (!validateResponse.isValid) {
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', validateResponse.validationErrorMessage);
          this._hideWaitModalDlg();
        } else if (validateResponse.showTransactionFeeConfirmation) {
          // CITEC Client ONLY logic
          const registrationGuid = validateResponse.guid;
          this._onRegistrationSaved(validateResponse.registrationModel);
          const registrationIdCollection = validateResponse.registrationModel.transactionType === constants.transactionType.registration ? yield this.get('registrationRepo.preSubmitNewRegistration').linked().perform(registrationGuid) : [validateResponse.guid];
          const zeroFee = yield this.get('_getCitecFeesTask').linked().perform(registrationIdCollection);
          if (zeroFee === true) {
            this._showWaitModalDlg();
            const fundsCheckOk = yield this.get('_createAccountingTransactionForCitecClientTask').linked().perform(registrationIdCollection);
            if (fundsCheckOk === true) {
              yield this.get('_submitRegistrationTask').linked().perform(registrationIdCollection);
            }
          }
        } else {
          registrationModel.saveModel();
          if (validateResponse.ppsrSubmissionNotRequired === true) {
            this.notifyInfo(`ESIS only changes were made.  Registration (${validateResponse.reference}) will not be sent to PPSR`);
          } else {
            this.notifyInfo(`Submitting registration (${validateResponse.reference}) to PPSR ... this may take up to a minute`);
          }
          this._closeRegistrationTab();
        }
      } catch (e) {
        const severity = this.get('constants.backendErrorSeverity');
        if (e.errorSeverity === severity.error || e.errorSeverity === severity.warning) {
          registrationModel.set('displayException', true);
          registrationModel.set('validatErrorMessage', e.errorMessage);
        } else {
          this.get('logger').logError('au-registration-base._validateAndSubmitRegistration', e);
        }
      } finally {
        this._hideWaitModalDlg();
      }
    }),
    //endregion

    //region discharge registration
    _dischargeRegistrationTask: (0, _emberConcurrency.task)(function* (registrationModel) {
      try {
        this._showWaitModalDlg();
        /*
        the discharge call does retrieve, change, create accounting transaction, submit in one go unless is CITEC client.
         */
        const dischargeResponse = yield this.get('registrationRepo.dischargeRegistration').linked().perform(registrationModel.get('guid'));
        if (dischargeResponse.requiresApproval === true) {
          this.notifyInfo(`Discharge registration request for ${registrationModel.get('ppsrRegistrationNumber')} has been sent for approval`);
        } else if (dischargeResponse.showTransactionFeeConfirmation) {
          // CITEC Client ONLY logic
          const zeroFee = yield this.get('_getCitecFeesTask').linked().perform([registrationModel.get('guid')]);
          if (zeroFee === true) {
            this._showWaitModalDlg();
            const fundsCheckOk = yield this.get('_createAccountingTransactionForCitecClientTask').linked().perform([registrationModel.get('guid')]);
            if (fundsCheckOk === true) {
              yield this.get('_submitRegistrationTask').linked().perform([registrationModel.get('guid')]);
            } else {
              return;
            }
          } else {
            return;
          }
        } else {
          this.notifyInfo(`Registration (${registrationModel.get('ppsrRegistrationNumber')}) discharge request is pending submission to PPSR ... this may take up to a minute`);
        }
        this._onDischarged();
      } catch (e) {
        this.get('logger').logError('au-registration-base._dischargeRegistrationTask', e);
      }
    }),
    //endregion

    //region CITEC Task
    _getCitecFeesTask: (0, _emberConcurrency.task)(function* (registrationGuids) {
      try {
        const feesResult = yield this.get('registrationRepo.loadRegistrationFees').linked().perform(registrationGuids);
        if (feesResult.totalFees > 0 && !(0, _lodash.isNil)(this.get('_citecFeeModal'))) {
          this._hideWaitModalDlg();
          const feeModal = this.get('_citecFeeModal');
          if (feesResult.displayFeeAllowed === true) {
            feeModal.setTitle('Fee Confirmation');
            const msg = feesResult.totalRitc > 0 ? `Your account will be charged <strong>$${feesResult.totalFees}</strong>. Ritc - <strong>$${feesResult.totalRitc}</strong>. <br>Click Yes to proceed.` : `Your account will be charged <strong>$${feesResult.totalFees}</strong>. <br>Click Yes to proceed.`;
            feeModal.show(msg, registrationGuids);
          } else {
            feeModal.setTitle('Charge Point');
            feeModal.show('You are going past a charge point, do you want to continue?', registrationGuids);
          }
          return false;
        }
        return true;
      } catch (e) {
        this._hideWaitModalDlg();
        this.get('logger').logError('au-registration-base._getCitecFeesTask', e);
        return false;
      }
    }),
    _createAccountingTransactionForCitecClientTask: (0, _emberConcurrency.task)(function* (registrationGuids) {
      try {
        const constants = this.get('constants');
        const accountingResponse = yield this.get('registrationRepo.createRegistrationAccountingRecord').linked().perform(registrationGuids);
        this._hideWaitModalDlg();
        if (accountingResponse.status === constants.createAccountingRecordResultStatus.allGood) {
          return true;
        }
        if (accountingResponse.status === constants.createAccountingRecordResultStatus.shortfall || accountingResponse.status === constants.createAccountingRecordResultStatus.partialShortfall || accountingResponse.status === constants.createAccountingRecordResultStatus.exceedLimit || accountingResponse.status === constants.createAccountingRecordResultStatus.particalExceedLimit) {
          const topupModal = this.get('_citecTopUpModal');
          if (!(0, _lodash.isNil)(topupModal)) {
            let message = '';
            if (accountingResponse.status === constants.createAccountingRecordResultStatus.shortfall || accountingResponse.status === constants.createAccountingRecordResultStatus.partialShortfall) {
              topupModal.setShowConfirmBtn(true);
              topupModal.setTitle('Account Top up');
              message = Ember.String.htmlSafe(`To process this transaction your account requires <strong>$${accountingResponse.shortfall}</strong> in additional funds.<br>To increase your account balance click Top Up and then try again.`);
            } else {
              topupModal.setShowConfirmBtn(false);
              topupModal.setTitle('Exceeded Limit');
              message = Ember.String.htmlSafe(`Sorry you do not have sufficient funds in your account.To increase your account balance please contact <strong>Customer Service</strong> on <strong>1800 733 733</strong> or <strong>3222 2700</strong>.`);
            }
            topupModal.show(message, accountingResponse.topupUrl);
          } else {
            this.notifyError(`The transaction could not be completed due to ${accountingResponse.errorMessage}`);
          }
        } else {
          this.notifyError(`The transaction could not be completed due to ${accountingResponse.errorMessage}`);
        }
        return false;
      } catch (e) {
        this._hideWaitModalDlg();
        this.get('logger').logError('au-registration-base._createAccountingTransactionForCitecClient', e);
        return false;
      }
    }),
    //endregion

    //region submit registration task
    _submitRegistrationTask: (0, _emberConcurrency.task)(function* (registrationGuids) {
      try {
        yield this.get('registrationRepo.submitRegistration').perform(registrationGuids);
        this._hideWaitModalDlg();
        this._closeRegistrationTab();
      } catch (e) {
        this._hideWaitModalDlg();
        this.get('logger').logError('au-registration-base._submitRegistrationTask', e);
      }
    }),
    //endregion

    //region SAVE Draft registration task
    _saveDraftRegistrationTask: (0, _emberConcurrency.task)(function* (model) {
      try {
        const response = yield this.get('registrationRepo.saveRegistration').linked().perform(model);
        this._onRegistrationSaved(response);
        this.notifyInfo(`Registration saved. Id : ${response.esisId}`);
      } catch (e) {
        this.get('logger').logError('au-registration-base._submitRegistrationTask', e);
      }
    }),
    //endregion

    //region DELETE registration task
    _deleteRegistrations: (0, _emberConcurrency.task)(function* (registrationGuids) {
      try {
        this._showWaitModalDlg();
        yield this.get('registrationRepo.bulkDeleteRegistration').perform(registrationGuids);
      } catch (e) {
        this.get('logger').logError('au-registration-base._deleteRegistrations', e);
      } finally {
        this._hideWaitModalDlg();
      }
    }),
    //endregion

    //region Download Registration Verification Task
    _downloadRegistrationVerificationTask: (0, _emberConcurrency.task)(function* (registrationGuid) {
      try {
        yield this.get('registrationRepo.downloadRegistrationVerificationReport').linked().perform(registrationGuid);
      } catch (e) {
        this.get('logger').logError('au-registration-base._downloadRegistrationVerificationTask', e);
      }
    }),
    //endregion

    //region SYNC registration to PPSR
    _retrieveRegistrationTask: (0, _emberConcurrency.task)(function* (registrationGuid, forceOverride) {
      try {
        return yield this.get('registrationRepo.retrieveRegistration').linked().perform(registrationGuid, forceOverride);
      } catch (e) {
        this.get('logger').logError('au-registration-base._retrieveRegistrationTask', e);
      }
    })
    //endregion

    //endregion
  });
  _exports.default = _default;
});