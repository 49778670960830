define("esis-ui/components/areas/search/au/view/shared/au-retrieving-result/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    progress: 0,
    title: 'Retrieving',
    showProgress: true
  });
  _exports.default = _default;
});