define("esis-ui/components/areas/login/reset-expired-cred-access-key/component", ["exports", "esis-ui/components/areas/shared/change-cred-access-key-page-base", "ember-concurrency", "lodash"], function (_exports, _changeCredAccessKeyPageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _changeCredAccessKeyPageBase.default.extend({
    _resetIsRunning: false,
    _resetCredAccessKey: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('_resetIsRunning', true);
        let response = yield this.get('accountRepo.changeExpiredCredAccessKey').perform(this.get('model'));
        if ((0, _lodash.isEmpty)(response)) {
          this.set('_errorMessage', null);
          this.get('session').authenticate('authenticator:esis-authenticator', this.get('model.username'), this.get('model.newCredAccessKey'), ['useraccess', 'offline_access']).catch(reason => {
            this.set('_resetIsRunning', false);
            if (Ember.isPresent(reason)) {
              if (reason.error === 'invalid_grant') {
                this.set('_errorMessage', 'Invalid username or password. Please try again.');
              } else {
                this.set('_errorMessage', reason.error);
              }
            } else {
              this.set('_errorMessage', 'Failed to login. Unknown Error. Please try again.');
            }
          });
        } else {
          this.set('_resetIsRunning', false);
          const msges = response.split('|');
          this.set('_errorMessage', msges);
        }
      } catch (error) {
        this.set('_errorMessage', error.errorMessage);
        this.set('_resetIsRunning', false);
        this.get('logger').logError('reset-expired-cred-access-key._resetCredAccessKey', error);
      }
    }),
    actions: {
      _submit() {
        this.get('_resetCredAccessKey').perform();
      }
    }
  });
  _exports.default = _default;
});