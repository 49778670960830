define("esis-ui/models/grantor-search-filter-criteria", ["exports", "esis-ui/models/request-base", "lodash"], function (_exports, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    filterType: '',
    init: function init() {
      this._super(...arguments);
      this.reset(); // set default value.
    },

    reset: function reset() {
      // set to default values
      this.set('tangibleProperty', [{
        label: 'Motor vehicle',
        checked: true,
        value: 2,
        disabled: false
      }, {
        label: 'Water craft',
        checked: true,
        value: 3,
        disabled: false
      }, {
        label: 'Aircraft',
        checked: true,
        value: 4,
        disabled: true
      }, {
        label: 'Aircraft engine',
        checked: true,
        value: 5,
        subset: true,
        disabled: false
      }, {
        label: 'Airframe',
        checked: true,
        value: 6,
        subset: true,
        disabled: false
      }, {
        label: 'Helicopter',
        checked: true,
        value: 7,
        subset: true,
        disabled: false
      }, {
        label: 'Small aircraft',
        checked: true,
        value: 8,
        subset: true,
        disabled: false
      }, {
        label: 'Agriculture',
        checked: true,
        value: 9,
        disabled: false
      }, {
        label: 'Crops',
        checked: true,
        value: 10,
        subset: true,
        disabled: false
      }, {
        label: 'Livestock',
        checked: true,
        value: 11,
        subset: true,
        disabled: false
      }, {
        label: 'Other goods',
        checked: true,
        value: 12,
        disabled: false
      }]);
      this.set('generalProperty', [{
        label: 'No exceptions',
        checked: true,
        value: 28,
        disabled: true
      }, {
        label: 'With exceptions',
        checked: true,
        value: 29,
        disabled: true
      }]);
      this.set('intangibleProperty', [{
        label: 'Account',
        checked: true,
        value: 19,
        disabled: false
      }, {
        label: 'General intangible',
        checked: true,
        value: 20,
        disabled: false
      }, {
        label: 'Intellectual property',
        checked: true,
        value: 21,
        disabled: false
      }, {
        label: 'Circuit layout',
        checked: true,
        value: 22,
        subset: true,
        disabled: false
      }, {
        label: 'Copyright',
        checked: true,
        value: 23,
        subset: true,
        disabled: false
      }, {
        label: 'Design',
        checked: true,
        value: 25,
        subset: true,
        disabled: false
      }, {
        label: 'Patent',
        checked: true,
        value: 24,
        subset: true,
        disabled: false
      }, {
        label: "Plant breeder's right",
        checked: true,
        value: 27,
        subset: true,
        disabled: false
      }, {
        label: 'TradeMark',
        checked: true,
        value: 26,
        subset: true,
        disabled: false
      }]);
      this.set('financialProperty', [{
        label: 'Chattel paper',
        checked: true,
        value: 13,
        disabled: false
      }, {
        label: 'Currency',
        checked: true,
        value: 14,
        disabled: false
      }, {
        label: 'Document of title',
        checked: true,
        value: 15,
        disabled: false
      }, {
        label: 'Intermediated security',
        checked: true,
        value: 16,
        disabled: false
      }, {
        label: 'Investment instrument',
        checked: true,
        value: 17,
        disabled: false
      }, {
        label: 'Negotiable instrument',
        checked: true,
        value: 18,
        disabled: false
      }]);
    },
    tangibleChecked: true,
    intangibleChecked: true,
    financialChecked: true,
    //region Calculated properties and functions
    getCollateralClassTypeSummary: function getCollateralClassTypeSummary() {
      let result = [];
      (0, _lodash.forOwn)(this, (value, propName) => {
        if (propName === 'tangibleProperty' || propName === 'generalProperty' || propName === 'intangibleProperty' || propName === 'financialProperty') {
          if (Ember.isPresent(value)) {
            value.forEach(item => {
              if (item.checked) {
                result.pushObject(item.value);
              }
            });
          }
        }
      });
      return result.sort(function (a, b) {
        return a - b;
      }).join();
    },
    //endregion
    //region copyable implementation
    copy: function copy() {
      // copy method is used by the PhotoEditRoute to create a clone of the model
      // we create a clone to preserve the original incase Cancel button is clicked
      //return run(this.constructor, 'create', this.serialize());
    },
    serialize: function serialize() {
      return this.getProperties(['filterType', 'tangibleProperty', 'generalProperty', 'intangibleProperty', 'financialProperty', 'tangibleChecked', 'intangibleChecked', 'financialChecked']);
    },
    setFromCopy: function setFromCopy(copy) {
      this.setProperties(copy);
    }
    //endregion
  });
  _exports.default = _default;
});