define("esis-ui/components/areas/registration/nz/shared/renew-discharge-result-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    renderInPlace: true,
    cancelButton: false,
    confirmBtnLabel: 'Ok',
    modalTitle: "Result",
    show(result) {
      this.set('_result', result);
      this.get('_dialog').show();
    },
    actions: {
      onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          let hasAnyFailed = !(0, _lodash.isEmpty)((0, _lodash.find)(this.get('_result'), {
            isSuccess: false
          }));
          this.get('onConfirm')(hasAnyFailed);
        }
      }
    }
  });
  _exports.default = _default;
});