define("esis-ui/components/areas/shared/business-entity-picklist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oWbkvlEf",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-group\"],[10,\"title\",\"Select a business entity to filter\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"allowClear\",\"searchEnabled\",\"searchField\",\"triggerClass\",\"onChange\",\"renderInPlace\"],[[24,[\"_entities\"]],[24,[\"selectedBusinessEntity\"]],false,true,\"title\",\"powerSelectDropdown\",[28,\"action\",[[23,0,[]],\"_onChooseBusinessEntity\"],null],true]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/business-entity-picklist/template.hbs"
    }
  });
  _exports.default = _default;
});