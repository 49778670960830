define("esis-ui/components/areas/spg/nz/wizard/nz-spg-wizard/component", ["exports", "esis-ui/components/areas/spg/nz/nz-spg-view-page-base", "lodash", "ember-concurrency"], function (_exports, _nzSpgViewPageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSpgViewPageBase.default.extend({
    init() {
      this._super(...arguments);
      this._loadModel.perform();
      this.steps = [];
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Information',
        stepId: this.newGuid(),
        sortOrder: 1,
        stepComponentName: 'areas/spg/nz/wizard/nz-spg-information-step'
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Secured Parties',
        stepId: this.newGuid(),
        sortOrder: 2,
        stepComponentName: 'areas/spg/nz/wizard/nz-spg-secured-parties-step'
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Email Notification',
        stepId: this.newGuid(),
        sortOrder: 3,
        stepComponentName: 'areas/spg/nz/wizard/nz-spg-email-notification-step'
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Review',
        stepId: this.newGuid(),
        sortOrder: 4,
        stepComponentName: 'areas/spg/nz/wizard/nz-spg-review-step'
      }, 'wizard-step'));
      this._options = {
        positionSubmitBtn: "top",
        alignSubmitBtn: 'right',
        showSubmitBtn: true,
        allowJump: true
      };
    },
    //region METHODS
    _createAddressModel() {
      let address = this.createModel('address-nz');
      address.set('countryCode', 'NZ');
      return address;
    },
    _createSpgModel(response) {
      this.set('model', this.mapJsonObjectToModel(response, 'secured-party-group-nz'));
      if (!(0, _lodash.isEmpty)(response.addressForServiceMailingAddress)) {
        let addressModel = this.mapJsonObjectToModel(response.addressForServiceMailingAddress, 'address-nz');
        this.set('model.addressForServiceMailingAddress', addressModel);
      } else {
        this.set('model.addressForServiceMailingAddress', this.createModel('address-nz'));
      }
      if (!(0, _lodash.isEmpty)(response.addressForServicePhysicalAddress)) {
        let addressModel = this.mapJsonObjectToModel(response.addressForServicePhysicalAddress, 'address-nz');
        this.set('model.addressForServicePhysicalAddress', addressModel);
      } else {
        this.set('model.addressForServicePhysicalAddress', this.createModel('address-nz'));
      }
      this.set('model.securedParties', []);
      if (!(0, _lodash.isEmpty)(response.securedParties)) {
        (0, _lodash.forEach)(response.securedParties, item => {
          let sp = this.mapJsonObjectToModel(item, 'secured-party-nz');
          if (!(0, _lodash.isEmpty)(item.mailingAddress)) {
            sp.set('mailingAddress', this.mapJsonObjectToModel(item.mailingAddress, 'address-nz'));
          }
          this.get('model.securedParties').pushObject(sp);
        });
      }
    },
    //endregion

    //region TASKS
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          let result = yield this.get('spgRepo.loadSpgModel').perform(this.get('tabModel.p0'));
          this._createSpgModel(result);
        } else {
          const hasP1 = Ember.isPresent(this.get('tabModel.p1')) && this.get('tabModel.p1') !== this.emptyGuid();
          const entityGuid = hasP1 ? this.get('tabModel.p1') : this.get('credential.currentBusinessEntity.value');
          this.set('model', this.createModel('secured-party-group-nz'));
          this.get('model').setProperties({
            'securedParties': [],
            'configSettings': this.createModel('secured-party-group-config-setting-nz'),
            'esisEntityGuid': entityGuid,
            'authorisedToCreate': false,
            'errors': []
          });
        }
        this.get('model').saveModel();
      } catch (error) {
        this.get('logger').logError('nz-spg-wizard._loadModel', error);
      }
    }),
    //endregion

    actions: {
      submit: function submit(model) {
        if (!this.get('model.displayException')) {
          let submitSpgTask = this.get('submitCreateNzSpg');
          let timeoutTask = this.get('runTimeout');
          let timeoutTaskInstance = timeoutTask.perform('saveSpgTaskInstance');
          this.set('saveTimeoutTaskInstance', timeoutTaskInstance);
          let saveSpgTaskInstance = submitSpgTask.perform(model);
          this.set('saveSpgTaskInstance', saveSpgTaskInstance);
        }
      },
      save: function save() {}
    }
  });
  _exports.default = _default;
});