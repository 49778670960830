define("esis-ui/components/areas/registration/nz/view/nz-fs-subordinations-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1BdvYPmx",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[28,\"shared/section-header\",null,[[\"title\"],[\"Subordinations\"]]],false],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"subordinations\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n    \"],[7,\"table\",true],[10,\"class\",\"table table-striped preview previewTable\"],[8],[0,\"\\n      \"],[7,\"thead\",true],[8],[0,\"\\n      \"],[7,\"tr\",true],[8],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"FS Number\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Type\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Date\"],[9],[0,\"\\n        \"],[7,\"th\",true],[8],[0,\"Expiry Date\"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"subordinations\"]]],null,{\"statements\":[[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"registrationNumber\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[23,1,[\"typeTitle\"]],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[28,\"display-date\",[[23,1,[\"registrationDate\"]]],null],false],[9],[0,\"\\n          \"],[7,\"td\",true],[8],[1,[28,\"display-date\",[[23,1,[\"expiryDate\"]]],null],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"shared/preview/preview-no-data\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/view/nz-fs-subordinations-display/template.hbs"
    }
  });
  _exports.default = _default;
});