define("esis-ui/models/nz-collateral-details-create-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    collDescription: (0, _modelBase.attr)(),
    collateralId: (0, _modelBase.attr)(),
    collateralType: (0, _modelBase.attr)(),
    collateralTypeTitle: (0, _modelBase.attr)(),
    collateralProfileCode: (0, _modelBase.attr)(),
    action: (0, _modelBase.attr)(),
    showCount: (0, _modelBase.attr)(),
    //UI only, Amend only
    newCollateralGuid: (0, _modelBase.attr)(),
    existingCollId: (0, _modelBase.attr)(),
    canHaveCollTypeDescription: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return !(this.get('collateralType') === constants.nzFsCollateralTypes.acft || this.get('collateralType') === constants.nzFsCollateralTypes.mv);
    }),
    mustHaveCollTypeDescription: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('canHaveCollTypeDescription') && this.get('collateralType') !== constants.nzFsCollateralTypes.aapp;
    }),
    init() {
      this._super(...arguments);
      this.validations = {
        collDescription: {
          custom: [{
            validation: (key, value) => {
              if (this.get('mustHaveCollTypeDescription')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Description can't be blank"
          }
          // {
          //     validation: (key, value) => {
          //         if (isPresent(value)) {
          //             return value.length > 2 && value.length <= 400;
          //         }
          //         return true;
          //     },
          //     message: 'Description must be between 3 and 400 characters long'
          // }
          ]
        }
      };
    }
  });
  _exports.default = _default;
});