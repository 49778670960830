define("esis-ui/components/areas/search/nz/view/nz-search-motor-vehicle/component", ["exports", "esis-ui/components/areas/search/nz/nz-search-base"], function (_exports, _nzSearchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSearchBase.default.extend({
    //region INITIALISATION
    searchSubmitTaskName: 'submitSearchNzMV',
    searchTabTitle: 'Motor Vehicle Search',
    searchModelName: 'nz-search-mv-request'
    //endregion
  });
  _exports.default = _default;
});