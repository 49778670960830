define("esis-ui/components/shared/preview/preview-error-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qa5EmzAk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"I am error tab content!\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[24,[\"model\",\"title\"]],false],[0,\": \"],[1,[24,[\"model\",\"description\"]],false],[9],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\\n\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/preview/preview-error-tab/template.hbs"
    }
  });
  _exports.default = _default;
});