define("esis-ui/storages/tab-history-cache", ["exports", "ember-local-storage/session/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Storage = _object.default.extend({
    constants: Ember.inject.service('app-constants')
  });

  // Uncomment if you would like to set initialState
  // Storage.reopenClass({
  //   initialState() {
  //     return {
  //         tabHistory : ''
  //     };
  //   }
  // });
  var _default = Storage;
  _exports.default = _default;
});