define("esis-ui/components/areas/search/au/view/au-search-motor-vehicle/component", ["exports", "esis-ui/components/areas/search/au/view/au-search-view-base", "ember-concurrency", "esis-ui/models/mv-search-criteria", "lodash"], function (_exports, _auSearchViewBase, _emberConcurrency, _mvSearchCriteria, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSearchViewBase.default.extend({
    //region INITIALISATION
    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region METHODS
    _createMvSearchCriteria: function _createMvSearchCriteria() {
      let criteria = _mvSearchCriteria.default.create();
      criteria.set('vin', '');
      criteria.set('chassis', '');
      criteria.set('manufacturersNumber', '');
      return criteria;
    },
    _loadMvSearchCriteria: function _loadMvSearchCriteria(searchHeader) {
      let criteria = _mvSearchCriteria.default.create();
      let searches = searchHeader.get('searches');
      let constants = this.get('app-constants');
      searches.forEach(function (search) {
        if (search.criteriaSerialNumberType === constants.serialNumberType.VIN) {
          criteria.set('vin', search.criteriaSerialNumber);
        } else if (search.criteriaSerialNumberType === constants.serialNumberType.chassisNumber) {
          criteria.set('chassis', search.criteriaSerialNumber);
        } else if (search.criteriaSerialNumberType === constants.serialNumberType.motorVehicleManufacturersNumber) {
          criteria.set('manufacturersNumber', search.criteriaSerialNumber);
        }
      });
      return criteria;
    },
    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          const response = yield this.get('searchRepo.loadSearch').linked().perform(this.get('tabModel.p0'));
          const searchHeader = this._createSearchHeader('', response);
          this.set('model', searchHeader);
          this._mapSearches(this.get('model'));
          this.set('criteria', this._loadMvSearchCriteria(searchHeader));
          this.updateTabProperty('title', `MV Search ${searchHeader.esisId}`);
          this.set('_isReadOnly', true);
        } else {
          const newRecord = this._createSearchHeader(this.get('constants.searchType.searchBySerialNumber'));
          newRecord.set('isMultiSearch', true);
          let criteria = this._createMvSearchCriteria();
          this.set('model', newRecord);
          this.set('criteria', criteria);
        }
        this.get('model').saveModel();
        this.get('criteria').save();
      } catch (error) {
        this.get('logger').logError('au-search-motor-vehicle._loadModel', error);
        this.notifyError('Fail to load search');
      }
    }),
    //endregion

    actions: {
      _onUpdateTab(searchResultSummary) {
        this.updateTabProperty('title', `MV Search ${searchResultSummary.searchHeaderEsisId}`);
        this.updateTabProperty('p0', searchResultSummary.searchHeaderGuid);
      },
      _onLoadSearch(searchHeader) {
        this.set('criteria', this._loadMvSearchCriteria(searchHeader));
        this.get('criteria').save();
        this.get('_grantorFilter').loadFilterCriteria();
      }
    }
  });
  _exports.default = _default;
});