define("esis-ui/components/areas/registration/au/list/au-registration-list/component", ["exports", "esis-ui/components/areas/registration/au/au-registration-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _auRegistrationBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auRegistrationBase.default.extend({
    //region properties
    _registrationGrid: null,
    _selectedRegistration: null,
    _notMigrated: false,
    _deleteAllMyRegistrationConfirmDlg: null,
    _dueToExpireCount: 0,
    _errorCount: 0,
    _unfinishedCount: 0,
    _spgList: null,
    _selectedSpg: null,
    //region INIT
    init() {
      this._super(...arguments);
      const app = this.get('app');
      const constants = this.get('constants');
      this._loadPicklist.perform();
      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklists();

      //region REGISTRATION GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('registrations')
            },
            parameterMap: (options, operation) => {
              options.viewName = this.get('_view');
              options.expiryOption = this.get('_selectedExpiring.value');
              options.businessEntityGuid = this.get('credential.currentBusinessEntity.value');
              options.notMigrated = this.get('_notMigrated');
              options.myRegistration = this.get('_myRegistrations');
              options.statusId = this.get('_selectedStatus.value');
              options.securedPartyGroupGuid = this.get('_selectedSpg.item.guid');
              let keyword = this.get('_filterCriteria');
              if (keyword) {
                options.filterScope = this.get('_searchBy.value');
                options.filterKeyword = keyword;
              }
              options._ = this.newGuid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                if (realData[i].status === constants.statusValue.submitted || realData[i].status === constants.statusValue.confirmed || realData[i].status === constants.statusValue.delegate) {
                  realData[i].class = 'submittedStatusIconColor';
                  realData[i].iconName = 'check';
                } else if (realData[i].status === constants.statusValue.pending || realData[i].status === constants.statusValue.creating || realData[i].status === constants.statusValue.validated || realData[i].status === constants.statusValue.review || realData[i].status === constants.statusValue.pendingAccountingTransaction) {
                  realData[i].class = 'pendingStatusIconColor';
                  realData[i].iconName = 'play';
                } else if (realData[i].status === constants.statusValue.pendingDischarge) {
                  realData[i].class = 'pendingDischargeStatusIconColor';
                  realData[i].iconName = 'questionCircle';
                } else {
                  realData[i].class = 'otherStatusIconColor';
                  realData[i].iconName = 'exclamationTriangle';
                }
                if (!(0, _lodash.isEmpty)(realData[i].dischargeDate)) {
                  realData[i].dischargeDateDisplay = this.dateTimeAsStr(realData[i].dischargeDate);
                } else {
                  if (realData[i].transactionType === constants.transactionType.discharge || realData[i].transactionType === constants.transactionType.expired) {
                    realData[i].dischargeDateDisplay = 'Unknown';
                  } else {
                    realData[i].dischargeDateDisplay = '';
                  }
                }
                realData[i].registrationExpiryDisplay = this.dateTimeAsStr(realData[i].registrationEndTime);
                realData[i].ppsrRegistrationStartTimeDisplay = this.dateTimeAsStr(realData[i].ppsrRegistrationStartTime);
                realData[i].registrationDateDisplay = this.dateTimeAsStr(realData[i].registrationDate);
                realData[i].collateralType = realData[i].isConsumer ? 'Consumer' : 'Commercial';

                //context menu hide logic
                let enablePrintVerification = realData[i].canPrintVerification;
                let enableRetrieve = realData[i].canRetrieve && realData[i].status !== constants.statusValue.pendingDischarge;
                let enableDischarge = this.get('credential.hasDischargeRegistrationPermissionAu') && !this.get('_isCitecUser') && realData[i].canDischarge;
                let enableApproveCancelDischarge = this.get('credential.hasApproveDischargePermission') && this.get('_view') === constants.registrationGridViewFilter.approval;
                let enableResumbit = realData[i].status === constants.statusValue.waitingOnFunds;
                let enableDelete = realData[i].canDelete;
                let canManageRegistration = this.get('credential.hasCreateRegistrationPermissionAu');
                // order is important. It must be the same as action options order (_registrationGridContextMenu below)
                realData[i].disableActionStates = [!canManageRegistration, !canManageRegistration, !canManageRegistration, !canManageRegistration];
                realData[i].hideActionStates = [!enableResumbit, !enableDischarge, !enableApproveCancelDischarge, !enableApproveCancelDischarge, !enablePrintVerification, !enableRetrieve, !enableDelete];
              }
              return realData;
            }
          },
          sort: [{
            field: "ppsrRegistrationNumber",
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "45px",
          template: dataItem => {
            return `<span class="${(0, _lodash.isEmpty)(dataItem.class) ? 'unKnownStatusIconColor' : dataItem.class}">` + this.get('constants.fIcon')[dataItem.iconName] + `</span>`;
          }
        }, {
          field: "ppsrRegistrationNumber",
          title: "Registration Number",
          width: "150px",
          filterable: false,
          template: '<a data-id="#:guid#" data-wizard="#: isCreating#" class="grid-link-id">#: ppsrRegistrationNumber #</a>'
        }, {
          field: "ppsrSecuredPartyGroupNumber",
          title: "SPG Number",
          width: "100px",
          filterable: false
        }, {
          field: "registrationDate",
          title: "Registration Date",
          width: "130px",
          filterable: false,
          template: '#: registrationDateDisplay #'
        }, {
          field: "registrationEndTime",
          title: "Expiry Date",
          width: "130px",
          filterable: false,
          template: '#: registrationExpiryDisplay #'
        }, {
          field: "givingOfNoticeIdentifier",
          title: "Giving Of Notice Identifier",
          width: "260px",
          filterable: false
        }, {
          field: "legalName",
          title: "Grantor",
          width: "230px",
          filterable: false
        }, {
          field: 'transactionTypeTitle',
          title: 'Transaction',
          width: "80px",
          filterable: false
        }, {
          field: "statusTitle",
          title: "Status",
          width: "80px",
          filterable: false
        }, {
          field: "dischargeDateDisplay",
          title: "Discharge Date",
          width: "110px",
          filterable: false,
          template: '#: dischargeDateDisplay #'
        }]
      });

      //Context Menu Action Ids
      this._printVerificationActionId = 'auPrintVerificationId';
      this._syncToPpsrActionId = 'auRegistrationSyncToPpsrId';
      this._resubmitActionId = 'auRegistrationResubmitId';
      this._deleteActionId = 'auDeleteRegistrationId';
      this._dischargeActionId = 'auDischargeRegistrationId';
      this._approveDischargeActionId = 'auApproveDischargeId';
      this._cancelDischargeActionId = 'auCancelDischargeId';
      this._registrationGridContextMenu = [{
        title: 'Resubmit',
        id: this.get('_resubmitActionId'),
        icon: 'square-full',
        iconClass: 'primaryCtxMenuOpt'
      }, {
        title: 'Discharge',
        id: this.get('_dischargeActionId'),
        icon: 'square-full',
        iconClass: 'primaryCtxMenuOpt'
      }, {
        title: 'Approve Discharge',
        id: this.get('_approveDischargeActionId'),
        icon: 'square-full',
        iconClass: 'primaryCtxMenuOpt'
      }, {
        title: 'Cancel Discharge',
        id: this.get('_cancelDischargeActionId'),
        icon: 'square-full',
        iconClass: 'dangerCtxMenuOpt'
      }, {
        title: 'Print Verification',
        id: this.get('_printVerificationActionId'),
        icon: 'print',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Sync to PPSR',
        id: this.get('_syncToPpsrActionId'),
        icon: 'sync',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion

      //region register server send event
      this.get('event').on(this.get('constants.events.notification'), this, '_gridNotificationHandler');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.notification'), this, '_gridNotificationHandler');
      });
      //endregion
    },

    //endregion

    //region COMPUTED PROPERTIES
    _showResubmitAllCommand: Ember.computed('_view', '_isCitecUser', function () {
      return this.get('_isCitecUser') && this.get('_view') === this.get('constants.registrationGridViewFilter.unfinished');
    }),
    _showDeleteAllCommand: Ember.computed('_view', function () {
      const constants = this.get('constants');
      return this.get('_view') === constants.registrationGridViewFilter.unfinished || this.get('_view') === constants.registrationGridViewFilter.error || this.get('_view') === constants.registrationGridViewFilter.all;
    }),
    _displayViewName: Ember.computed('_view', function () {
      const constants = this.get('constants');
      return this.get('_view') === constants.registrationGridViewFilter.all ? '' : (0, _lodash.find)(this.get('_viewFilterOptions'), {
        value: this.get('_view')
      }).title;
    }),
    _disabledStatusPicklist: Ember.computed('_view', function () {
      const constants = this.get('constants');
      return this.get('_view') !== constants.registrationGridViewFilter.all && this.get('_view') !== constants.registrationGridViewFilter.unfinished;
    }),
    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_registrationGrid'))) {
          if (reset === true) {
            this.set('_selectedRegistration', null);
          }
          this.get('_registrationGrid').refreshGrid(reset);
          this._loadRegistrationStatusCount.perform();
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    _onDischarged() {
      this._refreshGrid();
    },
    _gridNotificationHandler(notification) {
      const constants = this.get('constants');
      switch (notification.type) {
        case constants.notificationIdentifier.registrationCreateSubmitted:
        case constants.notificationIdentifier.registrationCreateConfirmed:
        case constants.notificationIdentifier.registrationAmendmentSubmitted:
        case constants.notificationIdentifier.registrationDischargeConfirmed:
        case constants.notificationIdentifier.registrationDischargeSubmitted:
          {
            this._refreshGrid();
            break;
          }
      }
    },
    //endregion

    //region TASK
    _loadPicklists() {
      const constants = this.get('constants');
      //SEARCH LIST
      this.set('_searchByOptions', [{
        title: 'Search All',
        value: 1
      }, {
        title: 'Registration Number',
        value: 10
      }, {
        title: 'Serial Number',
        value: 4
      }, {
        title: 'Grantor',
        value: 3
      }, {
        title: 'Collateral',
        value: 2
      }, {
        title: 'Giving Of Notice Identifier',
        value: 5
      }, {
        title: 'Trading Name',
        value: 9
      }, {
        title: 'Esis ID',
        value: 8
      }, {
        title: 'File ID',
        value: 6
      }, {
        title: 'SPG Number',
        value: 12
      }]);
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.searchBy'))) {
        this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
          value: this.get('tabModel.options.searchBy')
        }));
      } else {
        this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
          value: 1
        }));
      }

      //FILTER CRITERIA
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.filterCriteria'))) {
        this.set('_filterCriteria', this.get('tabModel.options.filterCriteria'));
      }

      //VIEW FILTERS
      const options = [{
        title: 'Unfinished',
        value: constants.registrationGridViewFilter.unfinished
      }, {
        title: 'Errors',
        value: constants.registrationGridViewFilter.error
      }, {
        title: 'Registered',
        value: constants.registrationGridViewFilter.registered
      }, {
        title: 'Due to Expire',
        value: constants.registrationGridViewFilter.dueToExpire
      }, {
        title: 'Discharged/Expired',
        value: constants.registrationGridViewFilter.discharged
      }, {
        title: 'All',
        value: constants.registrationGridViewFilter.all
      }];
      if (this.get('credential.currentBusinessEntity.item.enforceRegistrationDischargeApproval') === true && this.get('credential.hasApproveDischargePermission')) {
        options.unshift({
          title: 'Await Approval',
          value: constants.registrationGridViewFilter.approval
        });
      }
      this.set('_viewFilterOptions', options);
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.viewName'))) {
        this.set('_view', this.get('tabModel.options.viewName'));
      } else if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.searchBy'))) {
        this.set('_view', constants.registrationGridViewFilter.all);
      } else {
        this.set('_view', constants.registrationGridViewFilter.registered);
      }

      //STATUS
      this.set('_statusList', [{
        title: 'All Statuses',
        value: 0
      }, {
        title: 'Creating',
        value: constants.statusValue.creating
      }, {
        title: 'Validated',
        value: constants.statusValue.validated
      }, {
        title: 'Pending',
        value: constants.statusValue.pending
      }, {
        title: 'Review',
        value: constants.statusValue.review
      }, {
        title: 'Waiting On Funds',
        value: constants.statusValue.waitingOnFunds
      }]);
      this.set('_selectedStatus', this.get('_statusList')[0]);

      //EXPIRING
      this.set('_expiringList', [{
        title: 'Expiring at any time',
        value: constants.registrationExpiryOption.notFiltered
      }, {
        title: 'Expiring in 1 week',
        value: constants.registrationExpiryOption.oneWeek
      }, {
        title: 'Expiring in 3 weeks',
        value: constants.registrationExpiryOption.threeWeeks
      }, {
        title: 'Expiring in 1 month',
        value: constants.registrationExpiryOption.oneMonth
      }, {
        title: 'Expiring in 3 months',
        value: constants.registrationExpiryOption.threeMonth
      }]);
      this.set('_selectedExpiring', this.get('_expiringList')[0]);
      this._refreshGrid();
    },
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let spgPicklist = yield this.get('picklistRepo.loadSpgListForRegistration').perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('_spgList', spgPicklist);
      } catch (error) {
        this.get('logger').logError('au-registration-list._loadPicklist', error);
      }
    }),
    _loadPreviewTask: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.get('registrationRepo.loadRegistrationDetailDigest').linked().perform(this.get('_selectedRegistration.guid'));
        this.set('_detailDigest', response);
      } catch (error) {
        // this.notifyError('Loading preview information failed, please try again');
        this.get('logger').logError('au-registration-list._loadPreviewTask', error);
      }
    }),
    _resubmitMyRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        const submitResponse = yield this.get('registrationRepo.resubmitMyRegistrations').linked().perform();
        if (submitResponse.failedCount === 0) {
          this.notifySuccess(`All your Waiting On Funds registrations has submitted successfully`);
          this._refreshGrid(true);
        } else {
          if (submitResponse.successCount === 0) {
            this.notifyError('Fail to re-submit any registrations');
          } else {
            let resubmitMessage = Ember.String.htmlSafe(`${this.get('submitResponse.successCount')} registrations submitted successfully.<br>
                                                    ${this.get('submitResponse.failedCount')} registrations failed`);
            this.notifyWarn(resubmitMessage);
            this._refreshGrid(true);
          }
        }
      } catch (error) {
        this.get('logger').logError('au-registration-list._resubmitMyRegistrationTask', error);
        this.notifyError('Fail to re-submit any registrations');
      }
    }),
    _resubmitRegistration: (0, _emberConcurrency.task)(function* () {
      try {
        let submitResponse = yield this.get('registrationRepo.resubmitRegistration').linked().perform(this.get('_selectedRegistration.guid'));
        if (submitResponse.failedCount === 0) {
          this.notifySuccess(`Registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')} submitted successfully`);
          this._refreshGrid(true);
        } else {
          this.notifyError(`Fail to re-submit registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}`);
        }
      } catch (error) {
        this.get('logger').logError('registration-list._resubmitRegistration', error);
        this.notifyError(`Fail to re-submit the registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}`);
      }
    }),
    _resubmitAllRegistrationByEntityTask: (0, _emberConcurrency.task)(function* () {
      try {
        let submitResponse = yield this.get('registrationRepo.resubmitRegistrationsByEntity').perform(this.get('credential.currentBusinessEntityGuid'));
        if (submitResponse.failedCount === 0) {
          this.notifySuccess(`All Waiting On Funds registrations for ${this.get('credential.currentBusinessEntity.title')} have been submitted`);
          this._refreshGrid(true);
        } else {
          if (submitResponse.successCount === 0) {
            this.notifyError('Fail to re-submit any registrations');
          } else {
            let resubmitMessage = Ember.String.htmlSafe(`${submitResponse.successCount} registrations submitted successfully.<br>
                                                    ${submitResponse.failedCount} registrations failed`);
            this.notifyWarn(resubmitMessage);
            this._refreshGrid(true);
          }
        }
      } catch (error) {
        this.get('logger').logError('au-registration-list._resubmitAllRegistrationByEntityTask', error);
        this.notifyError('Fail to re-submit any registrations');
      }
    }),
    _deleteMyRegistrationsTask: (0, _emberConcurrency.task)(function* (isMine = false) {
      try {
        yield this.get('registrationRepo.deleteMyRegistrations').perform(this.get('_view'));
        let successMessage = isMine ? `All your ${this.get('_displayViewName')} registration have been deleted` : `All your ${this.get('_displayViewName')} registrations for ${this.get('credential.currentBusinessEntity.item.id')} have been deleted`;
        this.notifySuccess(successMessage);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('registration-list._deleteMyRegistrationsTask', error);
        this.notifyError('Fail to delete all my registrations');
      }
    }),
    _deleteRegistrationsByEntityTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('registrationRepo.deleteRegistrationsByEntity').perform(this.get('credential.currentBusinessEntityGuid'), this.get('_view'));
        this.notifySuccess(`All ${this.get('_displayViewName')} registrations for ${this.get('credential.currentBusinessEntity.title')} have been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-registration-list._deleteRegistrationsByEntityTask', error);
        this.notifyError(`Fail to delete draft registrations for ${this.get('credential.currentBusinessEntity.title')}`);
      }
    }),
    _deleteRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('registrationRepo.deleteRegistration').perform(this.get('_selectedRegistration.guid'));
        this.notifySuccess(`Registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')} has been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-registration-list._deleteRegistrationTask', error);
        this.notifyError('Fail to delete registration');
      }
    }),
    _approveDischargeRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.notifyInfo(`Approving registration (${this.get('_selectedRegistration.ppsrRegistrationNumber')}) for discharge ...`);
        yield this.get('registrationRepo.approveDischargeRegistration').perform(this.get('_selectedRegistration.guid'));
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError('au-registration-list._approveDischargeRegistrationTask', error);
      }
    }),
    _cancelDischargeRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('registrationRepo.cancelDischarge').perform(this.get('_selectedRegistration.guid'));
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError('au-registration-list._cancelDischargeRegistrationTask', error);
      }
    }),
    _loadRegistrationStatusCount: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('registrationRepo.loadRegistrationStatusCount').perform({
          filterScope: this.get('_searchBy.value'),
          filterKeyword: this.get('_filterCriteria'),
          myRegistration: this.get('_myRegistrations'),
          expiryOption: this.get('_selectedExpiring.value'),
          status: this.get('_selectedStatus.value'),
          viewName: this.get('_view'),
          esisEntityGuid: this.get('credential.currentBusinessEntity.value')
        });
        this.set('_unfinishedCount', result.unfinishedCount);
        this.set('_errorCount', result.errorCount);
        this.set('_dueToExpireCount', result.dueToExpireCount);
      } catch (e) {
        this.get('logger').logError('au-registration-list._loadRegistrationStatusCount', e);
      }
    }),
    //endregion

    //region when tab activate
    onScreenChange: function onScreenChange(optionChanged, options) {
      if (optionChanged === true && Ember.isPresent(options)) {
        this.setProperties({
          _selectedExpiring: this.get('_expiringList')[0],
          _searchBy: Ember.isPresent(options.searchBy) ? (0, _lodash.find)(this.get('_searchByOptions'), {
            value: options.searchBy
          }) : this.get('_searchByOptions')[0],
          _myRegistrations: false,
          _selectedStatus: this.get('_statusList')[0],
          _view: Ember.isPresent(options.viewName) ? options.viewName : this.get('constants.registrationGridViewFilter.all'),
          _filterCriteria: Ember.isPresent(options.filterCriteria) ? options.filterCriteria : ''
        });
        this._refreshGrid(true);
        return;
      }
      this._refreshGrid(); // soft refresh
    },

    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _spgChanged: function _spgChanged(option) {
        this.set('_selectedSpg', option);
        this._refreshGrid();
      },
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedRegistration')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedRegistration', data);
        if (!(0, _lodash.isNil)(this.get('_selectedRegistration'))) {
          this.get('_loadPreviewTask').perform();
        }
      },
      _onLinkClick: function _onLinkClick(selectedItem) {
        const guid = selectedItem.data('id');
        const wizard = selectedItem.data('wizard');
        let tabName = wizard && this.get('credential.hasCreateRegistrationPermissionAu') ? 'registrationWizardAu' : 'registrationViewAu';
        this.addTab(tabName, guid);
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedRegistration', selectedRow);
          if (selectedOption === this.get('_printVerificationActionId')) {
            if (!(0, _lodash.isNil)(this.get('_selectedRegistration.ppsrRegistrationNumber'))) {
              this.get('_downloadRegistrationVerificationTask').perform(this.get('_selectedRegistration.guid'));
            }
          } else if (selectedOption === this.get('_syncToPpsrActionId')) {
            if (!(0, _lodash.isNil)(this.get('_selectedRegistration.guid')) && selectedRow.canRetrieve) {
              this.get('_retrieveInfoDlg').show(`Are you sure you want to retrieve registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}?`);
            }
          } else if (selectedOption === this.get('_resubmitActionId')) {
            this.set('_resubmitConfirmMsg', `Are you sure you want to resubmit registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}?`);
            this.get('_resubmitConfirmDlg').show();
          } else if (selectedOption === this.get('_deleteActionId')) {
            if (!(0, _lodash.isNil)(this.get('_selectedRegistration.guid')) && selectedRow.canDelete) {
              this.set('_deleteConfirmMsg', `Are you sure you want to delete registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}? This action is not reversible`);
              this.get('_deleteConfirmDlg').show();
            }
          } else if (selectedOption === this.get('_dischargeActionId')) {
            this.get('_dischargeConfirmationDlg').show(`Are you sure you want to discharge registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}?`);
          } else if (selectedOption === this.get('_approveDischargeActionId')) {
            this.get('_approveDischargeInfoDlg').show(`Are you sure you approve discharge of registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}?`);
          } else if (selectedOption === this.get('_cancelDischargeActionId')) {
            this.get('_cancelDischargeInfoDlg').show(`Are you sure you want to cancel discharge registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')}?`);
          }
        }
      },
      //endregion

      //region FILTER ACTIONS
      _searchByChanged: function _searchByChanged(option) {
        this.set('_searchBy', option);
        if (this.get('_filterCriteria')) {
          this._refreshGrid(true);
        }
      },
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view.value)) {
          this.set('_view', view.value);
          const constants = this.get('constants');
          if (view.value !== constants.registrationGridViewFilter.unfinished && view.value !== constants.registrationGridViewFilter.all) {
            this.set('_selectedStatus', this.get('_statusList')[0]);
          }
          if (view.value !== constants.registrationGridViewFilter.registered) {
            this.set('_selectedExpiring', this.get('_expiringList')[0]);
          }
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        let trimedValue = value.replaceAll(" ", "");
        let isValueNotPureNumber = /\D/.test(trimedValue);
        this.set('_filterCriteria', isValueNotPureNumber ? value : trimedValue);
        this.set('_view', this.get('constants.registrationGridViewFilter.all'));
        this.set('_selectedStatus', this.get('_statusList')[0]);
        this.set('_selectedExpiring', this.get('_expiringList')[0]);
        this._refreshGrid(true);
      },
      _onMyRegistrationsChange(value) {
        this.set('_myRegistrations', value);
        this._refreshGrid();
      },
      _onChangeStatus(selected) {
        this.set('_selectedStatus', selected);
        this._refreshGrid();
      },
      _onChangeExpiring(selected) {
        this.set('_selectedExpiring', selected);
        this._refreshGrid();
      },
      //endregion

      //region BUTTON ACTIONS
      _onClickCreateExpress() {
        this.addTab('registrationExpressAu');
      },
      _onClickCreateWizard() {
        this.addTab('registrationWizardAu');
      },
      _onConfirmRetrieve() {
        this.get('_retrieveRegistrationTask').perform(this.get('_selectedRegistration.guid'), true).then(() => {
          this.notifySuccess(`Registration ${this.get('_selectedRegistration.ppsrRegistrationNumber')} has been synchronised with PPSR`);
          this._refreshGrid(true);
        });
      },
      _resubmitAll: function _resubmitAll() {
        if (this.get('credential.hasViewRegistrationPermissionAu')) {
          if (!(0, _lodash.isEmpty)(this.get('_getResubmitTypeModal'))) {
            this.set('_resubmitModalMessage', Ember.String.htmlSafe(`You are about to resubmit all <strong>Waiting On Funds</strong> registrations to PPSR.
                   Please select from options below:`));
            this.get('_getResubmitTypeModal').show();
          }
        } else {
          this.notifyInfo('Submitting all your Waiting On Funds registration to PPSR ... this may take up to a minute');
          this.get('_resubmitMyRegistrationTask').perform();
        }
      },
      _onGetResubmitType: function _onGetResubmitType(actionType) {
        if ((0, _lodash.isEmpty)(actionType)) {
          this.set('_resubmitTypeError', 'Please select resubmit option');
        } else {
          this.get('_getResubmitTypeModal').hide();
          this.set('_resubmitTypeError', '');
          if (actionType === 'mine') {
            this.notifyInfo('Submitting all your Waiting On Funds registration to PPSR ... this may take up to a minute');
            this.get('_resubmitMyRegistrationTask').perform();
          } else {
            this.notifyInfo('Submitting all Waiting On Funds registrations to PPSR ... this may take up to a minute');
            this.get('_resubmitAllRegistrationByEntityTask').perform();
          }
        }
      },
      _deleteAll: function _deleteAll() {
        if (this.get('credential.hasViewRegistrationPermissionAu')) {
          if (!(0, _lodash.isEmpty)(this.get('_getDeleteTypeModal'))) {
            this.set('_deleteModalMessage', Ember.String.htmlSafe(`Are you sure you want to delete all <strong>${this.get('_displayViewName')}</strong> registrations that were not yet submitted to PPSR? This action is not reversible. Please select from options below:`));
            this.get('_getDeleteTypeModal').show();
          }
        } else {
          this.set('_deleteAllConfirmMsg', 'Are you sure you want to delete all your registrations?');
          this.get('_deleteAllConfirmDlg').show();
        }
      },
      _onGetDeleteType: function _onGetDeleteType(actionType) {
        if ((0, _lodash.isEmpty)(actionType)) {
          this.set('_deleteTypeError', 'Please select delete option');
        } else {
          this.get('_getDeleteTypeModal').hide();
          this.set('_deleteTypeError', '');
          if (actionType === 'mine') {
            this.get('_deleteMyRegistrationsTask').perform(true);
          } else {
            this.get('_deleteRegistrationsByEntityTask').perform();
          }
        }
      },
      _onConfirmDeleteAllMyRegistration() {
        this.get('_deleteMyRegistrationsTask').perform();
      },
      _onConfirmResubmit() {
        this.notifyInfo('Submitting registration to PPSR ... this may take up to a minute');
        this._resubmitRegistration.perform();
      },
      _onConfirmDelete() {
        this.get('_deleteRegistrationTask').perform();
      },
      _onConfirmApproveDischarge() {
        this.get('_approveDischargeRegistrationTask').perform();
      },
      _onConfirmCancelDischarge() {
        this.get('_cancelDischargeRegistrationTask').perform();
      }
      //endregion

      //endregion
    }

    //endregion
  });
  _exports.default = _default;
});