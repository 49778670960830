define("esis-ui/components/shared/kendo/tree-list/tree-list-base", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    classNames: ['kendoTreeList'],
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe("clear:both;width:99%;"),
    _treeList: null,
    // internal reference to the jquery kendo treeList object
    _selectGuid: null,
    options: null,
    onDataBound: null,
    autoSelectFirstRow: true,
    autoFocus: true,
    //region public events
    onSelectionChange: function onSelectionChange() {},
    //endregion
    /*
    this is a special flag, means the on selection change event should
    always fire even when caller manually set the initial selected row
    DO NOT change unless you know exactly what you are doing
     */
    ensureTriggerOnSelectionChangedOnInitialLoad: true,
    init() {
      this._super(...arguments);
      const self = this;
      self._defaultOptions = {
        filterable: false,
        sortable: true,
        resizable: true,
        pageable: {
          info: true,
          numeric: false,
          previousNext: false,
          messages: {
            display: "{2:0} records"
          }
        },
        scrollable: true,
        selectable: "row",
        navigatable: true,
        change: function change() {
          (0, _emberLifeline.runTask)(self, '_selectedRowChanged');
        },
        dataBound: function dataBound(e) {
          // self.kendoTreeListInitSelectRow(e.sender, self.get('_selectGuid'), self);

          /*
          Grab focus to the table element of the grid, so user can use keyboard to navigate
          the timeout of 450 is designed to be bigger than select first row timeout
           */
          if (self.autoFocus) {
            (0, _emberLifeline.runTask)(self, () => {
              if (Ember.isPresent(e.sender) && Ember.isPresent(e.sender.table) && e.sender.table.length > 0) {
                e.sender.table.focus();
              }
            }, 550);
          }
        }
      };
      self.get('event').on(self.get('constants.events.tabResize'), self, 'resize');
      self.get('event').on(self.get('constants.events.splitterMoved'), self, 'resize');
    },
    didInsertElement: function didInsertElement() {
      this._super(...arguments);
      const self = this;
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, this.get('_defaultOptions'), this.get('options'));
      const treeList = this.$().kendoTreeList(mergedOptions).data('kendoTreeList');
      /*
      handle keyboard arrow keys, keyboard navigation inside the grid
       */
      const arrows = [37, 38, 39, 40];
      treeList.table.on("keydown", function (e) {
        if (arrows.indexOf(e.keyCode) >= 0) {
          (0, _emberLifeline.runTask)(self, () => {
            const activeRow = self.$("[class$=k-state-focused]").closest("tr");
            treeList.select(activeRow);
          }, 20);
        }
      });
      this.set('_treeList', treeList);
    },
    willDestroyElement() {
      this.get('event').off(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').off(this.get('constants.events.splitterMoved'), this, 'resize');
      if (Ember.isPresent(this.get('_treeList'))) {
        this.get('_treeList').destroy();
      }
      this._super(...arguments);
    },
    clearSelection() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const treeList = this.get('_treeList');
      this.set('selectedGuid', null);
      treeList.select(null);
      treeList.refresh();
    },
    resize: function resize() {
      const treeList = this.get('_treeList');
      if (Ember.isPresent(treeList)) {
        treeList.resize();
      }
    },
    refreshTree: function refreshTree(resetSelected, filterValue) {
      const treeList = this.get('_treeList');
      if (Ember.isPresent(treeList)) {
        if (resetSelected) {
          if (Ember.isPresent(this.get('_selectGuid'))) {
            this.set('_selectGuid', null);
          }
        }
        this.get('logger').logDebug('refresh treeList', resetSelected, this.get('selectedId'));
        treeList.dataSource.filter(filterValue);
        treeList.dataSource.read();
      }
    },
    _selectedRowChanged() {
      const treeList = this.get('_treeList');
      const data = treeList.dataItem(treeList.select()[0]);
      if (Ember.isPresent(data)) {
        // it causes problem when updating grid first row (e.g. Task status)
        if (data.guid !== this.get('_selectGuid')) {
          this.set('_selectGuid', data.guid);
          this._execSelectionChange(data);
        }
      } else {
        this._execSelectionChange(null);
      }
    },
    kendoTreeListInitSelectRow: function kendoTreeListInitSelectRow() {
      const theTreeList = this.get('_treeList');
      const selectedGuid = this.get('_selectGuid');
      if (Ember.isPresent(theTreeList)) {
        let selectedARow = false;
        const pageData = theTreeList.dataSource.view();
        if (!(0, _lodash.isEmpty)(pageData)) {
          if (Ember.isPresent(selectedGuid)) {
            const theRow = (0, _lodash.find)(pageData, {
              guid: selectedGuid
            });
            if (!(0, _lodash.isEmpty)(theRow)) {
              const selectedRow = theTreeList.tbody.find(">tr[data-uid='" + theRow.uid + "']");
              if (selectedRow) {
                if (this.get('ensureTriggerOnSelectionChangedOnInitialLoad') === true) {
                  this.set('_selectGuid', null); // setting the selected guid to null so grid selection change logic will trigger
                }

                theTreeList.select(selectedRow);
                selectedARow = true;
              }
            }
          } else if (this.get('autoSelectFirstRow') === true) {
            // select the first row in the grid (that is visible
            const firstRow = pageData[0];
            const row = theTreeList.tbody.find(">tr[data-uid='" + firstRow.uid + "']");
            if (row.length > 0) {
              theTreeList.select(row);
              selectedARow = true;
            }
          }
        }
        if (!selectedARow) {
          if (Ember.isPresent(this.get('_selectGuid'))) {
            this.set('_selectGuid', null);
            (0, _emberLifeline.runTask)(this, () => {
              this._execSelectionChange(null);
            });
          }
        }
      }
    },
    _execSelectionChange(data) {
      this.get('onSelectionChange')(data);
    }
  });
  _exports.default = _default;
});