define("esis-ui/components/areas/search/au/list/au-create-search-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Create New Search',
    show() {
      this.set('_searchType', null);
      this.get('_dialog').show();
    },
    onConfirm() {},
    actions: {
      _onConfirm() {
        this.get('onConfirm')(this.get('_searchType'));
      },
      _onTypeChange(value) {
        this.set('_searchType', value);
      }
    }
  });
  _exports.default = _default;
});