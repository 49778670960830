define("esis-ui/components/shared/ember-bootstrap/modal/general-modal-dialog/component", ["exports", "esis-ui/components/shared/ember-bootstrap/modal/modal-dialog-base", "jquery", "lodash"], function (_exports, _modalDialogBase, _jquery, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modalDialogBase.default.extend({
    isEmergency: false,
    isTopGeneral: false,
    closeButton: false,
    confirmButtonDisabled: false,
    renderInPlace: true,
    init() {
      this._super(...arguments);
      this.set('_screenElementType', this.get('constants.screenElementType.generalModal'));
    },
    //region COMPUTED PROPS
    _levelClass: Ember.computed('isEmergency', 'isTopGeneral', 'customClass', function () {
      if (this.get('isEmergency')) {
        return `level-3000 ${this.get('customClass')}`;
      }
      if (this.get('isTopGeneral')) {
        return `level-2000 ${this.get('customClass')}`;
      }
      return `level-1000 ${this.get('customClass')}`;
    }),
    //endregion

    //region METHODS
    _setDestinationElement() {
      let element = null;
      if (this.get('renderInPlace') === true) {
        /*
        Destination element is not used when render in place is true, just make see implementation of ember-bootstrap
        for Ember-Bootstrap v2.5, there is a change with render in place and destinationElement
        https://github.com/kaliber5/ember-bootstrap/blob/master/addon/templates/components/common/bs-modal.hbs
        it uses maybe-in-element destinationElement _renderInplace
        and ember bootstrap implementation means only when render in place is set to false the destinationElement property is effective
        see https://github.com/DockYard/ember-maybe-in-element
         so we are setting the renderInPlace for ember-bootstrap to always true, and use destinationElement to control if attaching to wormhole (root div) or tab-container (dyanmic tab div)
         REF : https://github.com/kaliber5/ember-bootstrap/blob/master/addon/components/base/bs-modal.js
         */
        element = null;
      }
      if (this.get('renderInPlace') === false) {
        element = (0, _jquery.default)('#ember-bootstrap-wormhole')[0];
      }
      this.set('_destinationElement', element);
    },
    //endregion
    actions: {
      confirm() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')();
        }
      },
      cancel() {
        if (!(0, _lodash.isEmpty)(this.get('onCancel'))) {
          this.get('onCancel')();
        }
        this.hide();
      }
    }
  });
  _exports.default = _default;
});