define("esis-ui/components/areas/admin/contract-type/au/view/au-edit-collateral-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _modalTitle: 'Add Collateral',
    _reset() {
      this.setProperties({
        _collateral: null
      });
    },
    show(pendingCollateral) {
      this._reset();
      let collateral = this.mapObject(pendingCollateral, this.createModel('contract-type-default'));
      collateral.setProperties({
        indexId: pendingCollateral.indexId,
        isSerialisedOptional: pendingCollateral.isSerialisedOptional,
        isConfirmed: pendingCollateral.isConfirmed
      });
      this.set('_collateral', collateral);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm() {
        if (this.get('_collateral.isConfirmed')) {
          if (!(0, _lodash.isEmpty)(this.get('onUpdate'))) {
            this.get('onUpdate')(this.get('_collateral'));
          }
        } else {
          if (!(0, _lodash.isEmpty)(this.get('onConfirmCollateral'))) {
            this.set('_collateral.isConfirmed', true);
            this.get('onConfirmCollateral')(this.get('_collateral'));
          }
        }
      },
      _onAreProceedsClaimedChange(value) {
        this.set('_collateral.areProceedsClaimed', value);
        let desc = value === true ? this.get('constants.defaultAreProceedClaimedDescription') : null;
        this.set('_collateral.proceedsClaimedDescription', desc);
      },
      _onNotStatedChange(value) {
        this.set('_collateral.isRegistrationEndTimeNotStated', value);
        if (value) {
          this.set('_collateral.durationYears', null);
        }
      }
    }
  });
  _exports.default = _default;
});