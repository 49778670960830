define("esis-ui/models/help-topic-request", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelBase.Model.extend({
    key: (0, _modelBase.attr)(),
    title: (0, _modelBase.attr)(),
    helpContent: (0, _modelBase.attr)(),
    usernameEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)()
  });
  _exports.default = _default;
});