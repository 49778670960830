define("esis-ui/models/wizard-step", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    isVisible: true,
    title: '',
    stepId: '',
    sortOrder: -1,
    stepComponentName: '',
    stepComponentInstance: null
  });
  _exports.default = _default;
});