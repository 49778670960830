define("esis-ui/templates/nz", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GsjS8Q7J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"routeStyleNamespaceClassSet\"]],[8],[0,\"\\n\\n  \"],[1,[22,\"areas/shell/nz/nz-index-page\"],false],[0,\"\\n\\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/templates/nz.hbs"
    }
  });
  _exports.default = _default;
});