define("esis-ui/models/business-entity", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/business-entity.js

  const BusinessEntity = _requestBase.default.extend({
    title: (0, _modelBase.attr)(),
    esisId: (0, _modelBase.attr)(),
    entityTypeId: (0, _modelBase.attr)(),
    parentEntityGuid: (0, _modelBase.attr)(),
    organisationName: (0, _modelBase.attr)(),
    organisationNumber: (0, _modelBase.attr)(),
    organisationNumberType: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    emailAddress: (0, _modelBase.attr)(),
    b2GAccountId: (0, _modelBase.attr)(),
    mailingAddress: (0, _modelBase.attr)(),
    physicalAddress: (0, _modelBase.attr)(),
    billingCode1: (0, _modelBase.attr)(),
    billingCode2: (0, _modelBase.attr)(),
    entityClass: (0, _modelBase.attr)(),
    isNotifyCsv: (0, _modelBase.attr)(),
    isEnforceSearchReference: (0, _modelBase.attr)(),
    isEnforceCategoryCode: (0, _modelBase.attr)(),
    isNotifyRecurringSearch: (0, _modelBase.attr)(),
    emailAddressNotifyCsv: (0, _modelBase.attr)(),
    emailAddressNotifyRecurringSearch: (0, _modelBase.attr)(),
    isActive: (0, _modelBase.attr)(),
    billFrom: (0, _modelBase.attr)(),
    autoLoginSubscriberCode: (0, _modelBase.attr)(),
    autoLoginBranchCode: (0, _modelBase.attr)(),
    enforceRegistrationDischargeApproval: (0, _modelBase.attr)(),
    enforceGrantorPossibleTrustRule: (0, _modelBase.attr)(),
    registrationDueToExpireNotifyInDays: (0, _modelBase.attr)(),
    notifyRegistrationDueToExpire: (0, _modelBase.attr)(),
    regionId: (0, _modelBase.attr)(),
    isHolderEntity: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        parentEntityGuid: {
          presence: true
        },
        entityTypeId: {
          presence: true
        },
        organisationName: {
          presence: true
        },
        emailAddress: {
          custom: {
            validation: function validation(key, value, model) {
              return Ember.isPresent(value) ? model.validateEmailAddress(value) : true;
            },
            message: 'Email address is invalid'
          }
        },
        emailAddressNotifyCsv: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('isNotifyCsv') === true) {
                return Ember.isPresent(value) ? model.validateEmailAddress(value) : false;
              }
              return true;
            },
            message: 'Bulk import notification email address is required'
          }
        }
      };
    }
  });
  var _default = BusinessEntity;
  _exports.default = _default;
});