define("esis-ui/components/shared/ember-bootstrap/static-tab/tab-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "S8Mf4mIw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"parentTab\",\"tab\",\"pane\"]],\"expected `parentTab.tab.pane` to be a contextual component but found a string. Did you mean `(component parentTab.tab.pane)`? ('esis-ui/components/shared/ember-bootstrap/static-tab/tab-panel/template.hbs' @ L1:C3) \"],null]],[[\"title\",\"id\",\"order\",\"iconName\",\"prefix\",\"iconClass\"],[[24,[\"panelTitle\"]],[24,[\"panelInternalId\"]],[24,[\"order\"]],[24,[\"iconName\"]],[24,[\"prefix\"]],[24,[\"iconClass\"]]]],{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"model\",\"isVisible\"],[[24,[\"parentTab\",\"tabModel\"]],[24,[\"isVisible\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/static-tab/tab-panel/template.hbs"
    }
  });
  _exports.default = _default;
});