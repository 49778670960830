define("esis-ui/components/areas/spg/au/view/au-delegate-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _isCreatNew: true,
    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        _requestModel: null,
        _selectedOrgNumberType: null
      });
    },
    show(model) {
      this._reset();
      this.set('_requestModel', this.createModel('secured-party-group'));
      if (!Ember.isPresent(model.guid)) {
        this.set('_isCreatNew', true);
        const entityGuid = model.entityGuid !== this.emptyGuid() ? model.entityGuid : this.get('credential.currentBusinessEntity.value');
        this.get('_requestModel').setProperties({
          'entityGuid': entityGuid,
          'addressForServiceMailingAddress': null,
          'addressForServicePhysicalAddress': null,
          'securedParties': [],
          'b2GAccount': null,
          'isActive': true,
          'status': this.get('constants.statusValue.delegate'),
          'spgType': this.get('constants.securedPartyGroupType.delegate'),
          'errors': [],
          'itemDeleted': false,
          'isGoniRequired': true
        });
      } else {
        this.set('_isCreatNew', false);
        this.mapObject(model, this.get('_requestModel'));
      }
      this._loadPicklist.perform();
      this.get('_requestModel').saveModel();
      this.get('_dialog').show();
    },
    hide() {
      this.get('_dialog').hide();
    },
    //endregion

    //region TASKS
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('_requestModel.entityGuid')
        }));
        let selectedBusinessEntityB2GAccount = this.get('_selectedBusinessEntity.item.b2GAccount');
        this.set('_requestModel.b2GAccount', `${selectedBusinessEntityB2GAccount.description} (${selectedBusinessEntityB2GAccount.customerNumber})`);
        this.get('_requestModel').saveModel();
      } catch (error) {
        this.get('logger').logError('au-delegate-modal._loadPicklist', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('_requestModel.entityGuid', businessEntity.value);
        this.set('_requestModel.b2GAccount', `${businessEntity.item.b2GAccount.description} (${businessEntity.item.b2GAccount.customerNumber})`);
      },
      _onConfirm() {
        let valid = this.get('_requestModel').validate();
        if (valid && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('_requestModel'));
        }
        return valid;
      }
    }
    //endregion
  });
  _exports.default = _default;
});