define("esis-ui/components/areas/login/reset-cred-access-key/component", ["exports", "esis-ui/components/areas/shared/change-cred-access-key-page-base", "ember-concurrency"], function (_exports, _changeCredAccessKeyPageBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _changeCredAccessKeyPageBase.default.extend({
    _resetCredAccessKey: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('accountRepo.resetCredAccessKey').perform(this.get('model'));
        this.set('_errorMessage', null);
        this.get('session').authenticate('authenticator:esis-authenticator', response, this.get('model.newCredAccessKey'), ['useraccess', 'offline_access']).catch(reason => {
          if (Ember.isPresent(reason)) {
            if (reason.error === 'invalid_grant' || reason.error === 'InvalidUsername') {
              this.set('_errorMessage', ['Invalid username or password. Please try again.']);
            } else {
              this.set('_errorMessage', [reason.error]);
            }
          } else {
            this.set('_errorMessage', ['Failed to login. Unknown Error. Please try again.']);
          }
        });
      } catch (error) {
        this.set('_errorMessage', error.errorMessage);
        this.get('logger').logError('reset-cred-access-key._resetCredAccessKey', error);
      }
    }),
    actions: {
      _submit() {
        this.get('_resetCredAccessKey').perform();
      }
    }
  });
  _exports.default = _default;
});