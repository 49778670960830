define("esis-ui/models/registration", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    esisId: (0, _modelBase.attr)(),
    transactionType: (0, _modelBase.attr)(),
    securedPartyGroupGuid: (0, _modelBase.attr)(),
    securedPartyGroupName: (0, _modelBase.attr)(),
    entityGuid: (0, _modelBase.attr)(),
    categoryGuid: (0, _modelBase.attr)(),
    tradingName: (0, _modelBase.attr)(),
    givingOfNoticeIdentifier: (0, _modelBase.attr)(),
    sequenceNumber: (0, _modelBase.attr)(),
    contractDate: (0, _modelBase.attr)(),
    ppsrChangeNumber: (0, _modelBase.attr)(),
    ppsrRegistrationNumber: (0, _modelBase.attr)(),
    linkedPpsrRegistrationNumber: (0, _modelBase.attr)(),
    status: (0, _modelBase.attr)(),
    statusMessage: (0, _modelBase.attr)(),
    ppsrUdf1: (0, _modelBase.attr)(),
    ppsrUdf2: (0, _modelBase.attr)(),
    ppsrUdf3: (0, _modelBase.attr)(),
    ppsrUdf4: (0, _modelBase.attr)(),
    ppsrUdf5: (0, _modelBase.attr)(),
    userCreated: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    userEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    fileUploadId: (0, _modelBase.attr)(),
    isBatched: (0, _modelBase.attr)(),
    canAmend: (0, _modelBase.attr)(),
    showEdit: (0, _modelBase.attr)(),
    grantors: (0, _modelBase.attr)(null, 'array'),
    collaterals: (0, _modelBase.attr)(null, 'array'),
    canDischarge: (0, _modelBase.attr)(),
    collateralType: (0, _modelBase.attr)(),
    categoryTitle: (0, _modelBase.attr)(),
    itemDeleted: (0, _modelBase.attr)(),
    canDelete: (0, _modelBase.attr)(),
    canRenew: (0, _modelBase.attr)(),
    canEditEndTime: (0, _modelBase.attr)(),
    ppsrRegistrationStartTime: (0, _modelBase.attr)(),
    ppsrRegistrationToken: (0, _modelBase.attr)(),
    ppsrTransactionId: (0, _modelBase.attr)(),
    ppsrRegistrationRenewTime: (0, _modelBase.attr)(),
    ppsrMigrationSourceRegister: (0, _modelBase.attr)(),
    isPreload: (0, _modelBase.attr)(),
    canPrintVerification: (0, _modelBase.attr)(),
    isDelegate: (0, _modelBase.attr)(),
    isGoniRequired: (0, _modelBase.attr)(),
    hasSerialisedCollateral: Ember.computed('collaterals', 'collaterals.[]', function () {
      if (Ember.isPresent(this.get('collaterals'))) {
        let isSerialised = (0, _lodash.find)(this.get('collaterals'), {
          'isSerialised': true
        });
        return Ember.isPresent(isSerialised);
      }
      return false;
    }),
    isConsumerCollateral: Ember.computed('collateralType', function () {
      return this.get('collateralType') === this.get('constants.collateralType.consumer');
    }),
    noGrantor: Ember.computed('isConsumerCollateral', 'hasSerialisedCollateral', function () {
      return this.get('hasSerialisedCollateral') && this.get('isConsumerCollateral');
    }),
    collateralTypeTitle: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return Ember.isEqual(this.get('collateralType'), constants.collateralType.consumer) ? 'Consumer' : Ember.isEqual(this.get('collateralType'), constants.collateralType.commercial) ? 'Commercial' : '';
    }),
    saveModel: function saveModel() {
      this.save();
      const col = this.get('collaterals');
      if (Ember.isPresent(col) && Ember.isArray(col)) {
        (0, _lodash.forEach)(col, item => {
          item.save();
        });
      }
      const gran = this.get('grantors');
      if (Ember.isPresent(gran) && Ember.isArray(gran)) {
        (0, _lodash.forEach)(gran, item => {
          item.save();
        });
      }
    },
    get isGrantorValid() {
      return this.get('noGrantor') === true && (0, _lodash.isEmpty)(this.grantors) || !(0, _lodash.isEmpty)(this.grantors) && this.get('noGrantor') !== true;
    },
    get isValid() {
      return this.isGrantorValid && !(0, _lodash.isEmpty)(this.collaterals) && this.validate();
    },
    get hasChange() {
      if (this.isDirty === true) {
        return true;
      }
      let dirty = false;
      (0, _lodash.forEach)(this.collaterals, item => {
        if (item.isDirty === true) {
          dirty = true;
        }
      });
      if (dirty !== true) {
        (0, _lodash.forEach)(this.grantors, item => {
          if (item.isDirty === true || item.get('action') === this.get('constants.actionType.remove') || item.get('isConfirmed')) {
            dirty = true;
          }
        });
      }
      return dirty;
    },
    init() {
      this._super(...arguments);
      this.validations = {
        securedPartyGroupGuid: {
          presence: true
        },
        givingOfNoticeIdentifier: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('isGoniRequired')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'GONI can\'t be blank'
          }
        },
        linkedPpsrRegistrationNumber: {
          custom: {
            validation: function validation(key, value) {
              if (Ember.isPresent(value)) {
                let pat = /[^0-9]/g;
                return value.length >= 15 && !pat.test(value);
              }
              return true;
            },
            message: 'Number should be 15 digits'
          }
        }
      };
    }
  });
  _exports.default = _default;
});