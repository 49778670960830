define("esis-ui/components/areas/profile/user-info/component", ["exports", "esis-ui/components/areas/shared/page-base", "ember-concurrency"], function (_exports, _pageBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    //region INITIALIZATION
    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (Ember.isNone(this.get('credential.currentUser'))) {
          // load user profile when not available
          yield this.get('accountRepo.loadProfile').perform();
        }
      } catch (error) {
        this.get('logger').logError('user-info._loadModel', error);
      }
    })
  });
  _exports.default = _default;
});