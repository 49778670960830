define("esis-ui/components/areas/search/nz/view/nz-search-debtor-organisation/component", ["exports", "esis-ui/components/areas/search/nz/nz-search-base"], function (_exports, _nzSearchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSearchBase.default.extend({
    //region INITIALISATION
    searchSubmitTaskName: 'submitSearchNzDebtorOrganisation',
    searchTabTitle: 'Debtor Organisation Search',
    searchModelName: 'nz-search-org-request'
    //endregion
  });
  _exports.default = _default;
});