define("esis-ui/repositories/file-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    //region AU
    loadFileUploadDigest: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadFileUploadDigest'), guid));
    }),
    loadFileTypes: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.get('constants.apiEndpoint.loadFileTypes'));
    }),
    loadFileUploadLineDigest: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadFileUploadLineDigest'), guid));
    }),
    getBulkFileLinkedIds: (0, _emberConcurrency.task)(function* (guids) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.getBulkFileLinkedIds'), guids));
    }),
    saveFileUpload: (0, _emberConcurrency.task)(function* (fileUploadArgs) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveFile'), fileUploadArgs);
    }),
    deleteFileUpload: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteFileUpload'), guid));
    }),
    //Download
    downloadFileUploaded: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadFileUploaded'), guid));
    }),
    downloadFileErrorDetails: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadFileErrorDetails'), guid));
    }),
    downloadBulkExtractReport: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadBulkExtractReport'), guid));
    }),
    downloadBulkSearchCertificateBundleByFile: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadBulkSearchCertificateBundleByFile'), guid));
    }),
    bulkSearchCertificateBundleProgressByFile: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.bulkSearchCertificateBundleProgressByFile'), guid));
    }).drop(),
    //endregion

    //region NZ
    saveNzBulkFile: (0, _emberConcurrency.task)(function* (nzFileUploadRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveNzBulkFile'), nzFileUploadRequest);
    }).drop(),
    downloadNzFs: (0, _emberConcurrency.task)(function* (nzFsDownloadRequest) {
      if (this.get('credential.currentUser.nzEfxSingleItemBulkDownloadEnabled')) {
        return yield this.apiPost(this.get('constants.apiEndpoint.downloadNzFsV2'), nzFsDownloadRequest);
      } else {
        return yield this.downloadFilePost(this.get('constants.apiEndpoint.downloadNzFs'), nzFsDownloadRequest);
      }
    }),
    loadDownloadNzFsProgress: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.get('constants.apiEndpoint.loadDownloadNzFsProgress'));
    }),
    downloadNzFsFile: (0, _emberConcurrency.task)(function* (requestGuid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadNzFsFile'), requestGuid));
    }),
    cancelDownloadNzFs: (0, _emberConcurrency.task)(function* (requestGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.cancelDownloadNzFs'), requestGuid));
    }),
    loadNzBulkResult: (0, _emberConcurrency.task)(function* (nzBulkFileResultRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadNzBulkResult'), nzBulkFileResultRequest);
    }),
    downloadNzBulkException: (0, _emberConcurrency.task)(function* (fileUploadGuid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadNzBulkException'), fileUploadGuid));
    }),
    deleteNzBulkFile: (0, _emberConcurrency.task)(function* (fileUploadGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteNzBulkFile'), fileUploadGuid));
    })
    //endregion
  });
  _exports.default = _default;
});