define("esis-ui/components/shared/froala/html-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "AAZnUAx2",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"froala-editor\",null,[[\"options\",\"content\",\"update\",\"on-blur-getHtml\",\"on-input\",\"on-contentChanged\",\"attribution\"],[[28,\"merged-hash\",[[24,[\"_defaultOptions\"]],[24,[\"options\"]]],null],[24,[\"content\"]],[28,\"action\",[[23,0,[]],\"_onUpdate\"],null],[28,\"action\",[[23,0,[]],\"_onBlur\"],null],[28,\"action\",[[23,0,[]],\"_onInput\"],null],[28,\"action\",[[23,0,[]],\"_onContentChanged\"],null],false]]],false],[0,\"\\n\\n\"],[7,\"span\",true],[10,\"class\",\"d-none\"],[8],[0,\"\\n  \"],[1,[22,\"_beDisabled\"],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/froala/html-editor/template.hbs"
    }
  });
  _exports.default = _default;
});