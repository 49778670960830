define("esis-ui/components/areas/registration/au/wizard/au-registration-wizard-container/component", ["exports", "esis-ui/components/areas/registration/au/au-registration-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _auRegistrationBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auRegistrationBase.default.extend({
    model: null,
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this.steps = [];
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Details',
        stepId: this.newGuid(),
        sortOrder: 1,
        stepComponentName: constants.wizardStepPath.registrationSpgStepAu
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Collaterals',
        stepId: this.newGuid(),
        sortOrder: 2,
        stepComponentName: constants.wizardStepPath.registrationCollateralStepAu
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Grantors',
        stepId: this.newGuid(),
        sortOrder: 3,
        stepComponentName: constants.wizardStepPath.registrationGrantorStepAu
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Other',
        stepId: this.newGuid(),
        sortOrder: 4,
        stepComponentName: constants.wizardStepPath.registrationUdfStepAu
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Review',
        stepId: this.newGuid(),
        sortOrder: 5,
        stepComponentName: constants.wizardStepPath.registrationReviewStepAu
      }, 'wizard-step'));
      this._options = {
        positionNavigationBtn: 'aligned',
        positionSaveBtn: 'top',
        positionSubmitBtn: 'top',
        showSubmitBtn: true,
        showSaveBtn: true,
        showNavigationBtn: true,
        saveBtnLabel: 'Save as Draft',
        smallSaveBtn: false
      };
      this._loadModel.perform();
    },
    //region COMPUTED PROPERTY
    _wizardTitle: Ember.computed('model.esisId', function () {
      if (this.get('model.esisId') > 0) {
        return `Registration : DRAFT-${this.get('model.esisId')}`;
      }
      return 'Draft New Registration';
    }),
    //endregion

    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          const response = yield this.get('registrationRepo.loadRegistration').perform(this.get('tabModel.p0'));
          const registration = this._mapApiRegistrationResponseToModel(response);
          (0, _emberLifeline.runTask)(this, () => {
            this.get('model').saveModel();
          }, 50);
          this.updateTabProperty('title', `Reg DRAFT-${registration.esisId}`);
          this.set('model', registration);
        } else {
          const requestModel = this._createRegistrationModel();
          this.set('model', requestModel);
        }
      } catch (error) {
        this.get('logger').logError('au-registration-wizard-container._loadModel', error);
      }
    }),
    _onRegistrationSaved(returnedModel) {
      const registration = this._mapApiRegistrationResponseToModel(returnedModel);
      this.set('model', registration);
      (0, _emberLifeline.runTask)(this, () => {
        this.get('model').saveModel();
      }, 50);
      this.updateTabProperty('p0', registration.guid);
      this.updateTabProperty('title', `Reg DRAFT-${registration.esisId}`);
    }
  });
  _exports.default = _default;
});