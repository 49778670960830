define("esis-ui/components/areas/shared/audit-display/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _auditGrid: null,
    init() {
      this._super(...arguments);
      this.set('_auditGridOption', {
        dataSource: {
          sort: [{
            field: "dateCreated",
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: "Audit ID",
          width: "100px",
          filterable: false
        }, {
          field: "title",
          title: "Title",
          width: "200px",
          filterable: false
        }, {
          field: "username",
          title: "Updated By",
          width: "150px",
          filterable: false
        }, {
          field: "dateCreated",
          title: "Date",
          width: "100px",
          filterable: false,
          template: dataItem => {
            return this.dateTimeAsStr(dataItem.dateCreated);
          }
        }]
      });
    },
    loadAudit(data) {
      data = (0, _lodash.orderBy)(data, 'dateCreated', 'desc');
      (0, _emberLifeline.runTask)(this, () => {
        this.get('_auditGrid').loadData(data);
      });
    }
  });
  _exports.default = _default;
});