define("esis-ui/components/areas/admin/system-user/view/system-user-view/component", ["exports", "esis-ui/components/areas/admin/admin-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _adminPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adminPageBase.default.extend({
    //region INITIALIZATION ---
    _businessEntityTreeData: null,
    init() {
      this._super(...arguments);
      this._regionList = [{
        title: 'AU',
        value: this.get('constants.region.au')
      }, {
        title: 'NZ',
        value: this.get('constants.region.nz')
      }];

      //region TREE VIEW
      this.set('_businessEntityTreeOption', {
        autoBind: false,
        height: 700,
        dataSource: {
          schema: {
            data: _data => {
              (0, _lodash.forEach)(_data, item => {
                item.region = item.regionId === this.get('constants.region.nz') ? "NZ" : "AU";
                item.parentId = item.parentEntityId;
                if (item.parentEntityId === null) {
                  item.expanded = true;
                }
              });
              return _data;
            }
          },
          sort: [{
            field: "id",
            dir: "asc"
          }]
        },
        pageable: {
          alwaysVisible: false
        },
        columns: [{
          field: "organisationName",
          title: "Name",
          width: "250px",
          attributes: {
            "class": "breakWord"
          },
          filterable: false
        }, {
          field: "isActive",
          title: "Active",
          width: "50px",
          filterable: false,
          sortable: false,
          template: dataItem => {
            return this.formatBoolean(dataItem.isActive);
          }
        }, {
          field: "isHolderEntity",
          title: "Holder Entity",
          width: "80px",
          filterable: false,
          sortable: false,
          template: dataItem => {
            return this.formatBoolean(dataItem.isHolderEntity);
          }
        }, {
          field: "region",
          title: "Region",
          width: "50px",
          filterable: false,
          sortable: false
        }]
      });
      this._loadModel.perform();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _allowRemoveBusinessEntity: Ember.computed('_selectedBusinessEntityInTree', function () {
      return this.get('_selectedBusinessEntityInTree.parentEntityId') === null;
    }),
    //endregion

    //region METHODS
    _loadBusinessEntitiesTreeData() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_businessEntityTree'))) {
          this.get('_businessEntityTree').loadData(this.get('_businessEntityTreeData'));
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._loadBusinessEntitiesTreeData();
          }, 20);
        }
      });
    },
    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          let result = yield this.get('adminRepo.loadSystemUserDetail').perform(this.get('tabModel.p0'));
          let userDigest = this.mapJsonObjectToModel(result, 'user-digest');
          userDigest.setProperties({
            roles: []
          });
          (0, _lodash.forEach)(result.roles, item => {
            let userRole = this.mapJsonObjectToModel(item, 'user-role');
            userDigest.roles.pushObject(userRole);
          });
          this.updateTabProperty('title', `System User ${this.paddingToLength(userDigest.id, 7)}`);
          this.set('model', userDigest);
          this.set('_selectedRegion', (0, _lodash.find)(this.get('_regionList'), {
            value: this.get('model.defaultRegionId')
          }));
          (0, _lodash.forEach)(this.get('model.roles'), item => item.save());
          this.set('_businessEntityTreeData', result.businessEntities);
          (0, _lodash.forEach)(this.get('_businessEntityTreeData'), item => {
            if (item.parentEntityId === null) {
              let itemToAdd = this.createModel('request-base');
              itemToAdd.set('id', item.id);
              this.get('model.linkedBusinessEntities').pushObject(itemToAdd);
            }
          });
          (0, _lodash.forEach)(this.get('model.linkedBusinessEntities'), item => item.save());
          this.get('model').save();
          let esisId = this.paddingToLength('' + this.get('model.id'), 7);
          this.set('_deleteConfirmMsg', `Are you sure you want to delete system user ${esisId}?`);
          this._loadBusinessEntitiesTreeData();
        } else {
          let model = this.createModel('user-digest');
          model.setProperties({
            isActive: true,
            roles: []
          });
          let currentRoles = yield this.get('adminRepo.loadCurrentUserRoles').perform();
          (0, _lodash.forEach)(currentRoles, item => {
            let role = this.createModel('user-role');
            Ember.set(role, 'roleId', item.roleId);
            Ember.set(role, 'roleName', item.roleName);
            Ember.set(role, 'visible', item.visible);
            model.roles.pushObject(role);
          });
          this.set('model', model);
          this.set('_selectedRegion', (0, _lodash.find)(this.get('_regionList'), {
            value: this.get('credential.currentRegion')
          }));
          this.set('model.defaultRegionId', this.get('_selectedRegion.value'));
          (0, _lodash.forEach)(this.get('model.roles'), item => item.save());
          this.get('model').save();
          this.set('_isNew', true);
        }
      } catch (error) {
        this.get('logger').logError('system-user-view._loadModel', error);
      }
    }),
    _updateBusinessEntitiesTree: (0, _emberConcurrency.task)(function* (addedBusinessEntity) {
      try {
        this.get('_waitingModal').show('Please wait while we are updating the tree data...');
        let result = yield this.get('adminRepo.updateLinkedBusinessEntitiesTreeForUser').perform({
          addedBusinessEntity: addedBusinessEntity,
          userLinkedBusinessEntities: (0, _lodash.map)(this.get('model.linkedBusinessEntities'), 'id')
        });
        this.get('model.linkedBusinessEntities').clear();
        (0, _lodash.forEach)(result, item => {
          if (item.parentEntityId === null) {
            let itemToAdd = this.createModel('request-base');
            itemToAdd.set('id', item.id);
            this.get('model.linkedBusinessEntities').pushObject(itemToAdd);
          }
        });
        this.set('_businessEntityTreeData', result);
        this.get('_waitingModal').hide();
        this.get('_businessEntityTree').loadData(this.get('_businessEntityTreeData'));
      } catch (e) {
        this.get('logger').logError('system-user-view._updateBusinessEntitiesTree', e);
      }
    }),
    _saveUser: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.saveSystemUser').perform(this.get('model'));
        this.get('model').save();
        (0, _lodash.forEach)(this.get('model.roles'), item => {
          item.save();
        });
        (0, _lodash.forEach)(this.get('model.linkedBusinessEntities'), item => {
          item.save();
        });
        const constants = this.get('constants');
        this.get('cache').deleteLookup(this.getCacheKey(constants.cacheType.lookupCache, constants.picklistType.systemUserPicklist));
        this.notifySuccess('Security role has been saved successfully');
        this.notifySuccess('System user has been saved successfully');
        this.removeTab('systemUsersList');
      } catch (e) {
        this.get('logger').logError('system-user-view._saveUser', e);
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', e.errorMessage);
      }
    }),
    _deleteUser: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('logger').logDebug('Deleting system user', this.get('model.id'));
        yield this.get('adminRepo.deleteUser').perform(this.get('model.guid'));
        let esisId = this.paddingToLength('' + this.get('model.id'), 7);
        this.notifySuccess(`System user ${esisId} has been deleted`);
        this.removeTab();
      } catch (error) {
        this.get('logger').logError('system-user-list._deleteUser', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onClickChangeCredAccessKey() {
        this.get('_passwordModal').show(this.get('model.userName'));
      },
      _onChangeCredAccessKey() {
        this._loadModel.perform();
      },
      _onClickSave() {
        if (this.get('model').validate()) {
          this._saveUser.perform();
        }
      },
      _onClickDelete() {
        if (!(0, _lodash.isNil)(this.get('model.id'))) {
          this._deleteUser.perform();
        }
      },
      _onRegionChange(selected) {
        this.set('_selectedRegion', selected);
        this.set('model.defaultRegionId', selected.value);
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('model.entityId', businessEntity.item.id);
      },
      _onSelectBusinessEntity(item) {
        this.set('_selectedBusinessEntityInTree', item);
      },
      _addBusinessEntity() {
        this.get('_businessEntityModal').show();
      },
      _onAddBusinessEntity(item) {
        this._updateBusinessEntitiesTree.perform(item.item.id);
      },
      _removeBusinessEntity() {
        this.set('_deleteConfirmMsg', `Are you sure you want to remove this user’s access to this Business Entity?`);
        this.get('_deleteConfirmDlg').show();
      },
      _onConfirmRemoveEntity() {
        let linkedBusinessEntities = this.get('model.linkedBusinessEntities');
        let entityToRemove = (0, _lodash.find)(linkedBusinessEntities, ['id', this.get('_selectedBusinessEntityInTree.id')]);
        linkedBusinessEntities.removeObject(entityToRemove);
        this._updateBusinessEntitiesTree.perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});