define("esis-ui/components/areas/search/au/view/au-search-view-base", ["exports", "esis-ui/components/areas/shared/au-page-base", "lodash", "ember-concurrency"], function (_exports, _auPageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    //region Initialisation
    criteria: null,
    _searchResultControl: null,
    _isReadOnly: false,
    init() {
      this._super(...arguments);
      this.setProperties({
        '_searchPanel': `searchPanel${this.get('app').guid()}`,
        '_ppsrPanel': `ppsrPanel${this.get('app').guid()}`,
        '_exceptionPanel': `_exceptionPanel${this.get('app').guid()}`
      });
    },
    //endregion

    //region Computed Props
    pageIsDirty: Ember.computed('criteria', function () {
      return !(0, _lodash.isEmpty)(this.get('criteria')) && this.get('criteria.isDirty');
    }),
    _statusMessage: Ember.computed('model.statusMessage', function () {
      let statusMessage = String(this.get('model.statusMessage')).split(/[|]/g);
      for (let i = 0; i < statusMessage.length; i++) {
        statusMessage[i] = statusMessage[i].trim();
      }
      return statusMessage;
    }),
    //endregion
    //region private methods
    _createSearchHeader: function _createSearchHeader(searchType, searchModel = null) {
      let searchHeader = {};
      if (!(0, _lodash.isEmpty)(searchModel)) {
        searchHeader = this.mapJsonObjectToModel(searchModel, 'search-header');
        searchHeader.set('entityGuid', this.get('credential.currentBusinessEntity.value'));
      } else {
        searchHeader = this.createModel('search-header');
        searchHeader.setProperties({
          entityGuid: this.get('credential.currentBusinessEntity.value'),
          searchType: searchType,
          autoRetrieveCertificates: true,
          isMultiSearch: false
        });
      }
      return searchHeader;
    },
    _createSearch: function _createSearch(searchHeaderModel) {
      let search = this.createModel('search');
      search.setProperties({
        searchType: searchHeaderModel.get('searchType'),
        criteriaCollateralClassSearchCriteria: searchHeaderModel.get('collateralClassType')
      });
      if ((0, _lodash.isEmpty)(searchHeaderModel.get('searches'))) {
        searchHeaderModel.set('searches', []);
      }
      searchHeaderModel.get('searches').pushObject(search);
      return search;
    },
    _mapSearches(searchHeaderModel) {
      let searches = [];
      (0, _lodash.forEach)(searchHeaderModel.get('searches'), item => {
        let search = this.mapJsonObjectToModel(item, 'search');
        search.setProperties({
          searchType: searchHeaderModel.get('searchType'),
          criteriaCollateralClassSearchCriteria: searchHeaderModel.get('collateralClassType')
        });
        searches.pushObject(search);
      });
      searchHeaderModel.set('searches', searches);
    },
    //endregion
    //region Task
    /*show modal while resubmit/delete for all grids Discard and delete search if user doesn’t want to top up is don*/
    _delete: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.deleteSearch').linked().perform(this.get('model.guid'));
        this.notifyInfo(`Search ${this.get('model.esisId')} has been deleted`);
        this.removeTab('searchListAu');
      } catch (error) {
        this.get('logger').logError('au-search-base.delete', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onRefresh() {
        if (Ember.isPresent(this.get('_searchResultControl'))) {
          this.get('_searchResultControl').refresh();
        }
      },
      _onCreateSearch(searchHeader) {
        return this._createSearch(searchHeader);
      },
      _onDeleteSearch() {
        this._delete.perform();
      }
    }

    //endregion
  });
  _exports.default = _default;
});