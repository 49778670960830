define("esis-ui/components/shared/kendo/tree-view/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe("clear:both;width:99%;"),
    _treeview: null,
    // internal reference to the jquery kendo treeview object
    _selectGuid: null,
    options: null,
    onDataBound: null,
    init() {
      this._super(...arguments);
      this._defaultOptions = {
        autoBind: true,
        dataSource: {
          transport: {
            read: {
              type: 'POST',
              dataType: 'json',
              contentType: "application/json",
              beforeSend: function beforeSend(xhr) {
                xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
              }
            }
          },
          schema: {
            parse: function parse(data) {
              return data.data;
            },
            model: {
              children: 'subs'
            }
          }
        },
        height: "100%",
        dataTextField: ["title"],
        change: () => {
          const data = this.dataItem(this.select());
          if (Ember.isPresent(data)) {
            // if (data.guid !== self.get('_selectGuid')) {
            this.get('logger').logDebug('Tree view selected changed', this.get('_selectGuid'), data.guid, data);
            this.set('_selectGuid', data.guid);
            this.send('onSelectionChange', data);
            // }
          }
        },

        dataBound: e => {
          this.kendoTreeviewInitSelectRow(e.sender, this.get('_selectGuid'));
          this.send('onDataBound', e);
        }
      };
      this.get('event').on(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').on(this.get('constants.events.splitterMoved'), this, 'resize');
    },
    didInsertElement: function didInsertElement() {
      this._super(...arguments);
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, this.get('_defaultOptions'), this.get('options'));
      const treeview = this.$().kendoTreeView(mergedOptions).data('kendoTreeView');
      this.set('_treeview', treeview);
    },
    willDestroyElement() {
      this.get('event').off(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').off(this.get('constants.events.splitterMoved'), this, 'resize');
      if (Ember.isPresent(this.get('_treeview'))) {
        this.get('_treeview').destroy();
      }
      this._super(...arguments);
    },
    resize: function resize() {
      const treeview = this.get('_treeview');
      if (Ember.isPresent(treeview)) {
        treeview.resize();
      }
    },
    refreshTree: function refreshTree(resetSelected) {
      const treeview = this.get('_treeview');
      if (Ember.isPresent(treeview)) {
        if (resetSelected) {
          treeview.select(null); // clears selection
          this.set('selectedId', null);
        }
        this.get('logger').logDebug('refresh treeview', resetSelected, this.get('selectedId'));
        treeview.dataSource.read();
      }
    },
    kendoTreeviewInitSelectRow: function kendoTreeviewInitSelectRow(theTreeview, selectedGuid) {
      if (theTreeview !== undefined) {
        if (Ember.isPresent(selectedGuid)) {
          const pageData = theTreeview.dataSource.view();
          let node = this.getNodeByGuid(pageData, selectedGuid);
          if (!(0, _lodash.isEmpty)(node)) {
            let selectedNode = theTreeview.findByUid(node.uid);
            if (selectedNode) {
              theTreeview.select(selectedNode);
            }
          }
        } else {
          let node = theTreeview.element.find(".k-first");
          if (node) {
            theTreeview.select(node);
          }
        }
      }
    },
    getNodeByGuid: function getNodeByGuid(nodeArr, selectedGuid) {
      let node = null;
      for (let c = 0; c < nodeArr.length; c++) {
        // console.log('nodeArr[c]: ', nodeArr[c]);
        // console.log(nodeArr[c].guid === selectedGuid);
        if (nodeArr[c].guid === selectedGuid) {
          node = nodeArr[c];
        } else if (nodeArr[c].hasChildren) {
          node = this.getNodeByGuid(nodeArr[c].children.view(), selectedGuid);
        }
        if (!(0, _lodash.isEmpty)(node)) {
          return node;
        }
      }
      return node;
    },
    actions: {
      onSelectionChange: function onSelectionChange(data) {
        if (Ember.isPresent(this.get('onSelectionChange'))) {
          (0, _emberLifeline.runTask)(this, () => {
            this._execSelectionChange(data);
          });
        }
      },
      onDataBound: function onDataBound(e) {
        if (Ember.isPresent(this.get('onDataBound'))) {
          (0, _emberLifeline.runTask)(this, () => {
            this._execDataBound(e);
          });
        }
      }
    },
    _execSelectionChange(data) {
      this.get('onSelectionChange')(data);
    },
    _execDataBound(e) {
      this.get('onDataBound')(e);
    }
  });
  _exports.default = _default;
});