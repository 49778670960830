define("esis-ui/components/areas/shared/nz/nz-organisation-lookup/component", ["exports", "lodash", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _lodash, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _organisationSearchText: null,
    disabled: false,
    reset() {
      this.set('_organisationSearchText', null);
    },
    onOrgLookedup() {},
    //region Task
    _loadOrganisationList: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('spgRepo.nzBusinessSearch').perform({
          searchTerm: this.get('_organisationSearchText')
        });
        if (!(0, _lodash.isEmpty)(this.get('loadTimeoutTaskInstance'))) {
          this.get('loadTimeoutTaskInstance').cancel();
          this.set('loadTimeoutTaskInstance', null);
        }
        this.set('_options', response);
      } catch (error) {
        if (!(0, _lodash.isEmpty)(this.get('loadTimeoutTaskInstance'))) {
          this.get('loadTimeoutTaskInstance').cancel();
          this.set('loadTimeoutTaskInstance', null);
        }
        this.set('_options', null);
        this.get('logger').logError('nz-organisation-lookup._loadOrganisationList', error);
      }
    }),
    _loadOrganisationDetails: (0, _emberConcurrency.task)(function* (nzbn) {
      try {
        this.get('_waitingModal').show('Please be patient while we are loading the organisation details...');
        let response = yield this.get('spgRepo.nzBusinessLookup').perform(nzbn);
        this.get('onOrgLookedup')(response);
      } catch (error) {
        this.get('logger').logError('nz-organisation-lookup._loadOrganisationDetails', error);
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    _runTimeout: (0, _emberConcurrency.task)(function* (taskInstance) {
      yield (0, _emberConcurrency.timeout)(10000);
      if (!(0, _lodash.isEmpty)(this.get(taskInstance))) {
        this.get(taskInstance).cancel();
        this.set(taskInstance, null);
      }
      this.set('_options', null);
      this.notifyError('Loading organisation timed out, please try again');
    }),
    //endregion

    actions: {
      _onOrganisationLookup() {
        let loadOrganisationTask = this.get('_loadOrganisationList');
        let timeoutTask = this.get('_runTimeout');
        let timeoutTaskInstance = timeoutTask.perform('loadOrganisationTaskInstance');
        this.set('loadTimeoutTaskInstance', timeoutTaskInstance);
        let loadOrganisationTaskInstance = loadOrganisationTask.perform();
        this.set('loadOrganisationTaskInstance', loadOrganisationTaskInstance);
      },
      _onSelectOrganisation(option) {
        this._loadOrganisationDetails.perform(option.value);
      }
    }
  });
  _exports.default = _default;
});