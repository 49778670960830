define("esis-ui/components/areas/search/nz/view/nz-search-result-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALISATION
    _resultGrid: null,
    init() {
      this._super(...arguments);
      const constants = this.get('constants');

      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._previewPanel = `previewPanel${this.get('app').guid()}`;
      this._loadPicklist.perform();
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: this.get('app').getApiUrl(constants.apiEndpoint.loadNzSearchResult)
            },
            parameterMap: (options, operation) => {
              if (operation === 'read') {
                let requestParam = this.get('createRestGridFilter')(options);
                requestParam.searchId = this.get('submitSearchResponse.searchId');
                return JSON.stringify(requestParam);
              }
              return options;
            }
          },
          schema: {
            data: _data => {
              let realData = _data.items;
              if ((0, _lodash.isEmpty)(realData)) {
                return [];
              }
              for (let i = 0; i < realData.length; i++) {
                realData[i].registrationDateDisplay = this.dateTimeAsStr(realData[i].registrationDate);
                realData[i].expiryDateDisplay = this.dateTimeAsStr(realData[i].expiryDate);
                let types = [];
                (0, _lodash.forIn)(realData[i].collateralType, (v, k) => {
                  types.pushObject(k.toUpperCase());
                });
                realData[i].collateralTypeSummary = types.join(', ');
              }
              return realData;
            }
          }
        },
        columns: [{
          field: "registrationNumber",
          title: "FS Number",
          filterable: false,
          width: "180px",
          sortable: false,
          template: '<a data-id="#:registrationNumber#" data-guid="#:guid#" class="grid-link-id">#: registrationNumber #</a>'
        }, {
          field: "registrationDate",
          title: "Registration Date",
          filterable: false,
          sortable: false,
          width: "160px",
          template: '#: registrationDateDisplay #'
        }, {
          field: "expiryDate",
          title: "Expiry Date",
          filterable: false,
          sortable: false,
          width: "160px",
          template: '#: expiryDateDisplay #'
        }, {
          field: "collateralTypeSummary",
          title: "Collateral",
          filterable: false,
          sortable: false
        }, {
          field: "securedPartySummary",
          title: "Secured Parties",
          filterable: false,
          sortable: false
        }]
      });
    },
    //endregion

    //region METHODS
    _refreshGrid(reset) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_resultGrid'))) {
          if (reset === true) {
            this.set('_selectedFs', null);
          }
          this.get('_resultGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    loadGrid() {
      this._refreshGrid();
    },
    //endregion

    //region TASKS
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        // COLLATERAL TYPES
        let collateralTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.nzFsCollateralType'), true, 'All Collateral Types');
        this.set('_collateralTypeList', collateralTypePicklist);
      } catch (error) {
        this.get('logger').logError('nz-search-result-control._loadPicklist', error);
      }
    }),
    //endregion

    actions: {
      //region Grid Actions
      _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedFs')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedFs', data);
      },
      _onLinkClick() {
        if (this.get('credential.hasViewRegistrationPermissionNz')) {
          this.addTab('searchViewFsNz', this.get('_selectedFs').registrationNumber, null, true, this.get('_selectedFs'));
        }
      }
      // _collTypeChanged(selected) {
      //     this.set('_selectedCollType', selected);
      //     this._refreshGrid(true);
      // },
      //endregion

      //region Button Actions
      // _onClickDownload() {
      //
      // },
      //endregion
    }
  });
  _exports.default = _default;
});