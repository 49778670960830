define("esis-ui/models/tab-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend({
    message: null,
    messageId: null,
    severity: null,
    timeout: 0,
    tabId: null,
    isGlobal: Ember.computed('tabId', function () {
      return Ember.isBlank(this.get('tabId'));
    }),
    timeStamp: null // timeout should be specified in seconds, not milliseconds
  });
  _exports.default = _default;
});