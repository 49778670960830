define("esis-ui/components/areas/shell/au/au-index-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4LHFLQ4Q",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"set-body-class\",[\"theme-australia\"],null],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"theme-australia\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"app-container\"],[8],[0,\"\\n      \"],[1,[28,\"areas/shared/global-info-modal\",null,[[\"cancelButton\",\"confirmButton\",\"renderInPlace\",\"isGlobal\",\"registerAs\",\"modalTitle\",\"message\"],[false,false,false,true,[24,[\"_auBusinessEntityLoadingDlg\"]],\"Please wait\",\"Please wait while loading business entities...\"]]],false],[0,\"\\n      \"],[1,[22,\"areas/shell/au/au-nav-bar\"],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"ml-3 tab-container\"],[8],[0,\"\\n\"],[4,\"shared/ember-bootstrap/dynamic-tab\",null,null,{\"statements\":[],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n      \"],[1,[28,\"areas/shell/version-outdated\",null,[[\"registerAs\"],[[24,[\"_versionOutdatedDlg\"]]]]],false],[0,\"\\n      \"],[1,[28,\"areas/shell/concurrency-error-modal\",null,[[\"registerAs\"],[[24,[\"_concurrencyErrorDlg\"]]]]],false],[0,\"\\n      \"],[1,[28,\"areas/shell/empty-business-entity-modal\",null,[[\"registerAs\"],[[24,[\"_emptyBEDlg\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shell/au/au-index-page/template.hbs"
    }
  });
  _exports.default = _default;
});