define("esis-ui/components/areas/registration/nz/list/nz-registration-list/component", ["exports", "esis-ui/components/areas/registration/nz/nz-registration-base", "ember-lifeline", "ember-concurrency", "moment", "lodash"], function (_exports, _nzRegistrationBase, _emberLifeline, _emberConcurrency, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzRegistrationBase.default.extend({
    //region private properties
    _fsGrid: null,
    _selectedRegistrations: null,
    _multiSelectOn: false,
    _downloadProgressJobId: null,
    _downloadProgressDigest: null,
    _downloadProgressIsRunning: false,
    //endregion

    //region initialisation
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this._selectedRegistrations = [];
      //region splitter panel options
      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [48, 52],
        minSize: [350, 250]
      });
      //endregion

      //region grid option
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: this.get('app').getApiUrl(constants.apiEndpoint.nzFsList)
            },
            parameterMap: (options, operation) => {
              if (operation === 'read') {
                let requestParam = this._createRestGridFilter(options);
                requestParam.spgIds = this.get('_spgIds');
                requestParam.view = this.get('_view');
                requestParam.expiryOption = this.get('_selectedExpiring') ? this.get('_selectedExpiring.value') : null;
                requestParam.expiryCustomRangeFromDate = this.get('_fromDate') ? this.serialiseDateOnly(this.get('_fromDate')) : null;
                requestParam.expiryCustomRangeToDate = this.get('_toDate') ? this.serialiseDateOnly(this.get('_toDate')) : null;
                requestParam.statusFilter = this.get('_selectedStatus.value');
                requestParam.searchField = this.get('_searchBy.value');
                requestParam.searchTerm = this.get('_search');
                this.set('_gridRequestParam', requestParam);
                return JSON.stringify(requestParam);
              }
              return options;
            }
          },
          schema: {
            data: _data => {
              if (this.isDestroyed || this.isDestroying) {
                return;
              }
              let realData = _data.financingStatements;
              this.set('_numberOfRecords', _data.recordCount);
              if ((0, _lodash.isEmpty)(realData)) {
                return [];
              }
              for (let i = 0; i < realData.length; i++) {
                realData[i].expiryDateDisplay = this.dateTimeAsStr(realData[i].expiryDate);
                realData[i].registrationDateDisplay = this.dateTimeAsStr(realData[i].registrationDate);
                realData[i].canOpenWizard = realData[i].status !== constants.nzFSStatus.billed && realData[i].status !== constants.nzFSStatus.mbieSubmitted && realData[i].status !== constants.nzFSStatus.mbieSuccess && realData[i].status !== constants.nzFSStatus.pending;

                // order is important. It must be the same as action options order (_fsGridContextMenu below)
                realData[i].hideActionStates = [realData[i].isDraftAmendment, !realData[i].isDraftAmendment];
              }
              return realData;
            },
            //We need this part for persisting row selection on paging
            model: {
              id: "registrationNumber"
            }
          },
          sort: [{
            field: 'registrationDate',
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": "# if(previewRequireApiCall) {# actionMenuFieldStyle #} #",
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `# if(previewRequireApiCall) {# <span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span> #} #`
        }, {
          field: "registrationNumber",
          title: "FS Number",
          width: "130px",
          filterable: false,
          // template: '<a data-id="#:guid#" data-wizard="#: isCreating#" data-canopenwizard="#: canOpenWizard#"  data-regnumber="#:registrationNumber#" data-spgid="#:spgId#" data-isdraftamendment="#:isDraftAmendment#" class="grid-link-id">#: registrationNumber #</a>'
          template: `# if(!isCreating || canOpenWizard) {# <a data-id="#:guid#" data-wizard="#: isCreating#" data-canopenwizard="#: canOpenWizard#"  data-regnumber="#:registrationNumber#" data-spgid="#:spgId#" data-isdraftamendment="#:isDraftAmendment#" class="grid-link-id">#: registrationNumber #</a> #} else{# #: registrationNumber # #} #`
        }, {
          field: "efxContractNumber",
          title: "Contract Number",
          width: "100px",
          filterable: false
        }, {
          field: "registrationDate",
          title: "Registration Date",
          width: "150px",
          filterable: false,
          template: '#: registrationDateDisplay #'
        }, {
          field: "expiryDate",
          title: "Expiry Date",
          width: "150px",
          filterable: false,
          template: '#: expiryDateDisplay #'
        }, {
          field: "status",
          title: "Status",
          width: "130px",
          filterable: false,
          template: '#: statusTitle #'
        }]
      });
      //endregion

      this._deleteActionId = 'deleteNzRegistrationId';
      this._cancelActionId = 'cancelNzRegistrationId';
      this._fsGridContextMenu = [{
        title: 'Delete',
        id: this.get('_deleteActionId')
      }, {
        title: 'Cancel',
        id: this.get('_cancelActionId')
      }];
      this._loadPicklists.perform();

      //region register server send event
      this.get('event').on(this.get('constants.events.notification'), this, '_fsGridNotificationHandler');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.notification'), this, '_fsGridNotificationHandler');
      });
      //endregion

      //region pollTask
      if ((0, _lodash.isNil)(this.get('_downloadProgressJobId'))) {
        this.set('_downloadProgressJobId', (0, _emberLifeline.pollTask)(this, '_downloadProgress'));
      }
      //endregion
    },

    //endregion

    //region HOOK
    willDestroyElement: function willDestroyElement() {
      this._super(...arguments);
      this._stopJob('_downloadProgressJobId');
    },
    _stopJob(jobId) {
      if (Ember.isPresent(this.get(jobId))) {
        (0, _emberLifeline.cancelPoll)(this, this.get(jobId));
        this.set(jobId, null);
      }
    },
    //endregion

    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');

        //SPGs
        let spgPicklist = yield this.get('picklistRepo.loadSpgListForNzFs').perform(this.get('credential.currentBusinessEntity.value'));
        let allOption = {
          title: 'All SPGs',
          value: 0
        };
        spgPicklist.unshiftObject(allOption);
        this.set('_spgList', spgPicklist);
        this.set('_selectedSpg', allOption);

        //SEARCH LIST
        let searchByPicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.nzFsFilterField'));
        this.set('_searchByOptions', searchByPicklist);
        if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.searchBy'))) {
          this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
            value: this.get('tabModel.options.searchBy')
          }));
          if (Ember.isPresent(this.get('tabModel.options.filterCriteria'))) {
            this.set('_search', this.get('tabModel.options.filterCriteria'));
          }
        } else {
          this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
            value: constants.nzFsGridSearchByList.registrationNumber
          }));
        }

        //VIEW FILTERS
        const viewFilterOptions = [{
          title: 'Unfinished',
          value: constants.registrationGridViewFilter.unfinished
        }, {
          title: 'Errors',
          value: constants.registrationGridViewFilter.error
        }, {
          title: 'Registered',
          value: constants.registrationGridViewFilter.registered
        }, {
          title: 'Discharged',
          value: constants.registrationGridViewFilter.discharged
        }, {
          title: 'Expired',
          value: constants.registrationGridViewFilter.expired
        }, {
          title: 'All',
          value: constants.registrationGridViewFilter.all,
          hasTooltip: true
        }];
        this.set('_viewFilterOptions', viewFilterOptions);
        if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.viewName'))) {
          this.set('_view', this.get('tabModel.options.viewName'));
        } else {
          this.set('_view', constants.registrationGridViewFilter.registered);
        }

        //STATUS
        this.set('_statusList', [{
          title: 'All Statuses',
          value: null
        }, {
          title: 'Creating',
          value: constants.nzFSStatus.creating
        }, {
          title: 'Pending',
          value: constants.nzFSStatus.pending
        }, {
          title: 'Amending',
          value: constants.nzFSStatus.amending
        }]);
        this.set('_selectedStatus', this.get('_statusList')[0]);

        //EXPIRING
        this.set('_expiringList', [{
          title: 'No date filter applied',
          value: constants.nzFsDueToExpireFilterOption.notFiltered
        }, {
          title: 'Expiring in 1 week',
          value: constants.nzFsDueToExpireFilterOption.oneWeek
        }, {
          title: 'Expiring in 1 month',
          value: constants.nzFsDueToExpireFilterOption.oneMonth
        }, {
          title: 'Expiring in 3 months',
          value: constants.nzFsDueToExpireFilterOption.threeMonth
        }, {
          title: 'Expiring in 6 months',
          value: constants.nzFsDueToExpireFilterOption.sixMonth
        }, {
          title: 'Expiring in custom range',
          value: constants.nzFsDueToExpireFilterOption.customRange
        }, {
          title: 'Registration date range',
          value: constants.nzFsDueToExpireFilterOption.registrationDateCustomRange
        }]);
        this.set('_selectedExpiring', this.get('_expiringList')[0]);
        this._refreshGrid();
      } catch (e) {
        this.get('logger').logError('nz-registration-list._loadPicklists', e);
      }
    }),
    _fsGridNotificationHandler(notification) {
      const constants = this.get('constants');
      const logger = this.get('logger');
      switch (notification.type) {
        case constants.notificationIdentifier.nzFinancingStatementCreateSubmitted:
          {
            logger.logDebug('nz-registraiton-list: NEW FS Submitted Successful Notification', notification);
            this.notifySuccessSticky(`Financing statement successfully registered : ${notification.tag}`);
            this._refreshGrid();
            break;
          }
        case constants.notificationIdentifier.nzFinancingStatementAmendSubmitted:
          {
            logger.logDebug('nz-registraiton-list: Amend FS Submitted Successful Notification', notification);
            this.notifySuccessSticky(`Financing statement amended successfully : ${notification.tag}`);
            this._refreshGrid();
            break;
          }
        case constants.notificationIdentifier.nzFinancingStatementCreateFailed:
          {
            logger.logDebug('nz-registraiton-list: NEW FS Failed Notification', notification);
            this.notifyWarnSticky(`Financing statement registration failed : ${notification.tag}`);
            this._refreshGrid();
            break;
          }
        case constants.notificationIdentifier.nzFinancingStatementAmendFailed:
          {
            logger.logDebug('nz-registraiton-list: Amend FS Failed Notification', notification);
            this.notifyWarnSticky(`Financing statement amendment failed : ${notification.tag}`);
            this._refreshGrid();
            break;
          }
      }
    },
    //endregion

    //region COMPUTED PROPERTIES
    _selectedRegsArray: Ember.computed('_selectedRegistrations.[]', '_multiSelectOn', '_previewModel', function () {
      let regNumbers = [];
      if (this.get('_multiSelectOn')) {
        (0, _lodash.forEach)(this.get('_selectedRegistrations'), item => {
          regNumbers.pushObject(item);
        });
      } else {
        if (!(0, _lodash.isEmpty)(this.get('_previewModel'))) {
          regNumbers.pushObject({
            registrationNumber: this.get('_previewModel').registrationNumber,
            spgId: this.get('_previewModel').spgId,
            branchCode: this.get('_previewModel').efxBranchCode
          });
        }
      }
      return regNumbers;
    }),
    _disabledDownloadBtn: Ember.computed('_view', '_numberOfRecords', '_multiSelectOn', function () {
      const viewConst = this.get('constants.registrationGridViewFilter');
      let view = this.get('_view');
      return this.get('_multiSelectOn') === true || view !== viewConst.registered && view !== viewConst.discharged && view !== viewConst.expired || !(this.get('_numberOfRecords') > 0);
    }),
    _disabledRenewOrDischarge: Ember.computed('_view', '_selectedRegsArray.[]', function () {
      const viewConst = this.get('constants.registrationGridViewFilter');
      let view = this.get('_view');
      return view !== viewConst.registered || (0, _lodash.isEmpty)(this.get('_selectedRegsArray'));
    }),
    _hasOngoingDownload: Ember.computed('_downloadProgressDigest.[]', function () {
      return !(0, _lodash.isEmpty)(this.get('_downloadProgressDigest')) && this.get('credential.currentUser.nzEfxSingleItemBulkDownloadEnabled');
    }),
    _downloadBtnTitle: Ember.computed('_downloadProgressDigest.[]', function () {
      if (!(0, _lodash.isEmpty)(this.get('_downloadProgressDigest'))) {
        return 'Downloads (' + (0, _lodash.filter)(this.get('_downloadProgressDigest'), {
          'status': 6
        }).length + '/' + this.get('_downloadProgressDigest').length + ')';
      } else {
        return 'Downloads';
      }
    }),
    //endregion

    //region TASK
    _loadDraftDetails: (0, _emberConcurrency.task)(function* (draftGuid) {
      try {
        const response = yield this.get('registrationRepo.loadNzDraftFs').linked().perform({
          esisEntityGuid: this.get('credential.currentBusinessEntityGuid'),
          guid: draftGuid
        });
        this.set('_previewModel', this.convertToEmberObject(response));
        this.get('_previewModel').setProperties({
          efxContractNumber: response.contractNumber,
          efxBranchCode: response.branchCode
        });
      } catch (error) {
        this.get('logger').logError('nz-registration-list._loadDraftDetails', error);
      }
    }),
    _loadDraftAmendmentDetails: (0, _emberConcurrency.task)(function* (selected) {
      try {
        let request = {
          guid: selected.guid,
          fsNumber: selected.registrationNumber,
          esisEntityGuid: this.get('credential.currentBusinessEntityGuid')
        };
        let response = yield this.get('registrationRepo.loadNzDraftAmendFs').linked().perform(request);
        this.set('_previewModel', this.convertToEmberObject(response));
        this.set('_previewModel.debtors', (0, _lodash.filter)(response.debtors, debtor => {
          return debtor.action !== this.get('constants.actionType.remove');
        }));
        this.get('_previewModel').setProperties({
          efxContractNumber: response.contractNumber,
          efxBranchCode: response.branchCode
        });
      } catch (error) {
        this.get('logger').logError('nz-registration-list._loadDraftAmendmentDetails', error);
      }
    }),
    _deleteNzDraftFs: (0, _emberConcurrency.task)(function* (draft) {
      try {
        yield this.get('registrationRepo.deleteNzDraftFs').linked().perform({
          esisEntityGuid: this.get('credential.currentBusinessEntityGuid'),
          guid: draft.guid
        });
        this._refreshGrid(true);
        if (draft.isCreating) {
          this.removeTab('', true, {
            name: 'registrationWizardNz',
            p0: draft.guid
          });
        } else {
          this.removeTab('', true, {
            name: 'registrationViewNz',
            p0: draft.registrationNumber,
            p1: draft.isDraftAmendment ? 'draft' : 'registration'
          });
        }
      } catch (error) {
        this.get('logger').logError('nz-registration-list._deleteNzDraftFs', error);
      }
    }),
    _downloadNzFs: (0, _emberConcurrency.task)(function* (downloadType) {
      try {
        let request = this.get('_gridRequestParam');
        request.downloadFileType = downloadType;
        if (this.get('_view') === this.get('constants.registrationGridViewFilter.discharged')) {
          request.statusFilter = this.get('constants.nzFSStatus.discharged');
        } else if (this.get('_view') === this.get('constants.registrationGridViewFilter.expired')) {
          request.statusFilter = this.get('constants.nzFSStatus.expired');
        }
        this._showWaitModalDlg();
        yield this.get('fileRepo.downloadNzFs').linked().perform(request);
      } catch (error) {
        this.get('logger').logError('nz-registration-list._downloadNzFs', error);
      } finally {
        this._hideWaitModalDlg();
        this._downloadProgressInternal();
      }
    }),
    //endregion

    //region Methods
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_fsGrid'))) {
          if (reset === true) {
            if (this.get('_multiSelectOn')) {
              this._resetSelectedRegistrations();
            } else {
              this.set('_previewModel', null);
            }
          }
          this.get('_fsGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    onScreenChange: function onScreenChange(optionChanged, options) {
      if (optionChanged === true && Ember.isPresent(options)) {
        this.setProperties({
          _selectedExpiring: this.get('_expiringList')[0],
          _searchBy: Ember.isPresent(options.searchBy) ? (0, _lodash.find)(this.get('_searchByOptions'), {
            value: options.searchBy
          }) : this.get('_searchByOptions')[0],
          _selectedStatus: this.get('_statusList')[0],
          _view: Ember.isPresent(options.viewName) ? options.viewName : this.get('constants.registrationGridViewFilter.registered'),
          _search: Ember.isPresent(options.filterCriteria) ? options.filterCriteria : '',
          _selectedSpg: (0, _lodash.find)(this.get('_spgList'), {
            value: 0
          }),
          _spgIds: []
        });
        this._refreshGrid(true);
        return;
      }
      this._refreshGrid(); // soft refresh
    },

    _resetSelectedRegistrations() {
      this.set('_selectedRegistrations', []);
      this._loadPreviewGrid();
    },
    _loadPreviewGrid() {
      (0, _emberLifeline.runTask)(this, () => {
        this.get('_preview').loadPreviewGrid(this.get('_selectedRegistrations'));
      }, 150);
    },
    _validateToDate() {
      if (!Ember.isPresent(this.get('_toDate')) || !Ember.isPresent(this.get('_fromDate')) || this.get('_toDate') >= this.get('_fromDate')) {
        this.set('_toDateValidationMessage', '');
        this._refreshGrid();
      } else {
        this.set('_toDateValidationMessage', 'To Date can\'t be before From Date');
      }
    },
    _downloadProgress() {
      if (this.get('credential.currentUser.nzEfxSingleItemBulkDownloadEnabled')) {
        this._downloadProgressInternal();
        (0, _emberLifeline.runTask)(this, '_downloadProgress', 10 * 1000);
      }
    },
    _downloadProgressInternal() {
      if (this.get('_downloadProgressIsRunning') || !this.get('credential.currentUser.nzEfxSingleItemBulkDownloadEnabled')) return;
      this.set('_downloadProgressIsRunning', true);
      this.get('fileRepo.loadDownloadNzFsProgress').perform().then(progressDigest => {
        this.set('_downloadProgressDigest', this.convertToEmberObject(progressDigest));
        (0, _lodash.forEach)(this.get('_downloadProgressDigest'), item => {
          item.downloadProgress = (item.downloadProgress * 100).toFixed(1) + "%";
          item.searchStr = "";
          item.searchStr += "SPG Filter: " + item.securePartyGroupName;
          if (!(0, _lodash.isNil)(item.searchCriteria.searchField) && !(0, _lodash.isNil)(item.searchCriteria.searchTerm) && item.searchCriteria.searchTerm !== "") {
            item.searchStr += ", " + (0, _lodash.find)(this.get('_searchByOptions'), {
              'value': item.searchCriteria.searchField
            }).title + ": ";
            if (item.searchCriteria.searchField === 14 || item.searchCriteria.searchField === 2) {
              item.searchStr += this.dateAsStr((0, _moment.default)(item.searchCriteria.searchTerm, "DD-MM-YYYY"));
            } else {
              item.searchStr += item.searchCriteria.searchTerm;
            }
          }
          if (!(0, _lodash.isNil)(item.searchCriteria.expiryOption) && item.searchCriteria.expiryOption !== 0) {
            item.searchStr += ", " + (0, _lodash.find)(this.get('_expiringList'), {
              'value': item.searchCriteria.expiryOption
            }).title;
            if (!(0, _lodash.isNil)(item.searchCriteria.expiryCustomRangeFromDate)) {
              item.searchStr += " from: " + this.dateAsStr(item.searchCriteria.expiryCustomRangeFromDate);
            }
            if (!(0, _lodash.isNil)(item.searchCriteria.expiryCustomRangeToDate)) {
              item.searchStr += " to: " + this.dateAsStr(item.searchCriteria.expiryCustomRangeToDate);
            }
          }
        });
        this.set('_downloadProgressIsRunning', false);
      }, error => {
        this.get('logger').logError('nz-registration-list._downloadProgress', error);
        this.set('_downloadProgressIsRunning', false);
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onGridRowSelectionChange(selectedItem) {
        this.set('_previewModel', null);
        if (!(0, _lodash.isEmpty)(selectedItem)) {
          if (this.get('_multiSelectOn')) {
            if ((0, _lodash.isEmpty)((0, _lodash.find)(this.get('_selectedRegistrations'), {
              registrationNumber: selectedItem.registrationNumber
            }))) {
              this.get('_selectedRegistrations').unshiftObject(selectedItem);
              this._loadPreviewGrid();
            }
          } else {
            if (selectedItem.previewRequireApiCall) {
              if (selectedItem.isDraftAmendment) {
                this._loadDraftAmendmentDetails.perform(selectedItem);
              } else {
                this._loadDraftDetails.perform(selectedItem.guid);
              }
            } else {
              this.set('_previewModel', selectedItem);
            }
          }
        }
      },
      _onLinkClick: function _onLinkClick(selectedItem) {
        const wizard = selectedItem.data('wizard');
        const canOpenWizard = selectedItem.data('canopenwizard');
        const guid = selectedItem.data('id');
        if (wizard && canOpenWizard && this.get('credential.hasCreateRegistrationPermissionNz')) {
          this.addTab('registrationWizardNz', guid);
        } else if (!wizard && this.get('credential.hasViewRegistrationPermissionNz')) {
          const regNumber = selectedItem.data('regnumber');
          const spgId = selectedItem.data('spgid');
          const isDraftAmendment = selectedItem.data('isdraftamendment');
          this.addTab('registrationViewNz', regNumber, isDraftAmendment ? 'draft' : 'registration', true, {
            spgId: spgId,
            guid: guid,
            isDraftAmendment: isDraftAmendment
          });
        }
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          if (selectedOption === this.get('_deleteActionId')) {
            this.get('_deleteInfoDlg').show(`Are you sure you want to delete ${selectedRow.registrationNumber}?`);
          } else if (selectedOption === this.get('_cancelActionId')) {
            this.get('_cancelInfoDlg').show(`Are you sure you want to cancel your changes on FS ${selectedRow.registrationNumber}?`);
          }
          this.set('_fsDraftToDoAction', selectedRow);
        }
      },
      _onConfirmDelete() {
        this._deleteNzDraftFs.perform(this.get('_fsDraftToDoAction'));
      },
      _onConfirmCancel() {
        this._deleteNzDraftFs.perform(this.get('_fsDraftToDoAction'));
      },
      //endregion

      //region FILTER ACTIONS
      _searchByChanged: function _searchByChanged(option) {
        this.set('_searchBy', option);
        this.set('_search', '');

        //SearchBy Date Picker
        if (option.value === this.get('constants.nzFsGridSearchByList.expiryDate') || option.value === this.get('constants.nzFsGridSearchByList.registrationDate')) {
          (0, _emberLifeline.runTask)(this, () => {
            if (!(0, _lodash.isEmpty)(this.get('_searchByDatePicker'))) {
              this.get('_searchByDatePicker').clear();
              this.set('_searchByDate', null);
            }
          }, 100);
        }
        //

        this._refreshGrid(true);
      },
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view.value)) {
          this.set('_view', view.value);
          if (this.get('_multiSelectOn')) {
            this._resetSelectedRegistrations();
          }
          const constants = this.get('constants');
          if (view.value !== constants.registrationGridViewFilter.unfinished) {
            this.set('_selectedStatus', this.get('_statusList')[0]);
          }
          if (view.value !== constants.registrationGridViewFilter.registered) {
            this.set('_selectedExpiring', null);
            this.set('_multiSelectOn', false);
          } else {
            this.set('_selectedExpiring', this.get('_expiringList')[0]);
          }
          if (view.value === constants.registrationGridViewFilter.unfinished || view.value === constants.registrationGridViewFilter.error) {
            this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
              value: constants.nzFsGridSearchByList.registrationNumber
            }));
          }
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        this.set('_search', value);
        this._refreshGrid(true);
      },
      _onSearchByDateChanged(date) {
        this.set('_searchByDate', date);
        if (Ember.isPresent(date)) {
          let formattedDate = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
          this.set('_search', formattedDate.toString());
        } else {
          this.set('_search', '');
        }
        this._refreshGrid(true);
      },
      _onChangeStatus(selected) {
        this.set('_selectedStatus', selected);
        this._refreshGrid();
      },
      _onChangeExpiring(selected) {
        this.set('_selectedExpiring', selected);
        this._refreshGrid();

        //reset date pickers
        this.set('_toDateValidationMessage', '');
        this.set('_fromDate', null);
        this.set('_toDate', null);
      },
      _onFromDateChanged(value) {
        this.set('_fromDate', value);
        this._validateToDate();
      },
      _onToDateChanged(value) {
        this.set('_toDate', value);
        this._validateToDate();
      },
      _onChangeSpg(selectedOption) {
        //multi select
        // let isAllSelected = !isEmpty(find(this.get('_selectedSpgs'), {value: 0}));
        // let selectAll = !isAllSelected && !isEmpty(find(selectedOptions, {value: 0}));
        // if (!selectAll) {
        //     this.set('_selectedSpgs', filter(selectedOptions, (item) => {
        //         return item.value !== 0;
        //     }));
        //     this.set('_spgIds', map(this.get('_selectedSpgs'), 'value'));
        // }
        // else if (selectAll) {
        //     this.set('_selectedSpgs', filter(this.get('_spgList'), {value: 0}));
        //     this.set('_spgIds', []);
        // }

        //single select
        this.set('_selectedSpg', selectedOption);
        if (selectedOption.value === 0) {
          this.set('_spgIds', []);
        } else {
          this.set('_spgIds', [selectedOption.value]);
        }
        //

        this._refreshGrid();
      },
      //endregion

      //region BUTTON ACTIONS
      _onClickCreate() {
        this.addTab('registrationWizardNz');
      },
      _onClickDischarge(registrations) {
        this._showDischargeDlg(registrations);
      },
      _onConfirmDischargeRenew() {
        this._refreshGrid(true);
      },
      //region Renew Registration
      _onClickRenew(registrations) {
        this._showRenewDlg(registrations);
      },
      //endregion
      _onClickDownload() {
        if ((0, _lodash.filter)(this.get('_downloadProgressDigest'), {
          'status': 3
        }).length >= 10) {
          this.get('_downloadInfoModal').show('You can only have 10 downloads in process');
        } else {
          this.get('_downloadModal').show(this.get('_numberOfRecords'), this.get('_view'));
        }
      },
      _onConfirmDownload(downloadType) {
        this._downloadNzFs.perform(downloadType);
      },
      _onConfirmDownloadInfoDlg() {
        this.get('_downloadInfoModal').hide();
      },
      _onMultiSelectSwitch(value) {
        this.set('_multiSelectOn', value);
        if (!value) {
          this.set('_selectedRegistrations', []);
        }
      },
      _onRemoveAllSelectedRegs() {
        this._resetSelectedRegistrations();
      },
      _removeSelectedReg(regNumber) {
        this.set('_selectedRegistrations', (0, _lodash.filter)(this.get('_selectedRegistrations'), item => {
          return item.registrationNumber !== regNumber;
        }));
        this._loadPreviewGrid();
      },
      //endregion
      _onClickDownloadView() {
        this.get('_downloadProgressModal').show();
      },
      _refreshDownloadProgressDigest() {
        this._downloadProgressInternal();
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});