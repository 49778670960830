define("esis-ui/initializers/ember-cli-uuid", ["exports", "ember-data", "esis-ui/config/environment", "ember-cli-uuid/mixins/adapters/uuid", "ember-cli-uuid/configuration"], function (_exports, _emberData, _environment, _uuid, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-cli-uuid',
    initialize() {
      const config = _environment.default['ember-cli-uuid'] || {};
      _configuration.default.load(config);
      _emberData.default.Adapter.reopen({
        generateIdForRecord() {
          return _configuration.default.defaultUUID ? (0, _uuid.generateIdForRecord)(...arguments) : null;
        }
      });
    }
  };
  _exports.default = _default;
});