define("esis-ui/initializers/models", ["exports", "esis-ui/utils/auto-register-instance"], function (_exports, _autoRegisterInstance) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    (0, _autoRegisterInstance.default)('models', application);
    application.inject('models', 'constants', 'service:app-constants');
    application.inject('models', 'app', 'service:app-service');
  }
  var _default = {
    name: 'models-injector',
    before: 'repository-injector',
    initialize
  };
  _exports.default = _default;
});