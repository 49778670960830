define("esis-ui/components/shared/radiobutton-group-control/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    model: null,
    onChange: function onChange() {},
    checked: Ember.computed('property', 'value', function () {
      return this.get('property') == this.get('value');
    }),
    radioButtonGroupClass: Ember.computed('formElement.validation', function () {
      if (this.get('formElement.validation') === 'error') {
        return Ember.String.htmlSafe('form-control is-invalid');
      }
      return Ember.String.htmlSafe('');
    })
  });
  _exports.default = _default;
});