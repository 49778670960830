define("esis-ui/components/areas/admin/system-user/view/Add-Business-Entity-modal/component", ["exports", "esis-ui/components/areas/admin/admin-page-base", "lodash", "ember-concurrency"], function (_exports, _adminPageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adminPageBase.default.extend({
    //region INIT
    modalTitle: 'Add Business Entity',
    model: null,
    //endregion

    //region TASKS
    _loadBusinessEntities: (0, _emberConcurrency.task)(function* () {
      try {
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), false, false);
        let groupedList = [];
        let auList = (0, _lodash.filter)(businessEntityList, ['item.regionId', this.get('constants.region.au')]);
        if (!(0, _lodash.isEmpty)(auList)) groupedList.push({
          groupName: 'AU',
          options: (0, _lodash.orderBy)(auList, ['title', 'asc'])
        });
        let nzList = (0, _lodash.filter)(businessEntityList, ['item.regionId', this.get('constants.region.nz')]);
        if (!(0, _lodash.isEmpty)(nzList)) groupedList.push({
          groupName: 'NZ',
          options: (0, _lodash.orderBy)(nzList, ['title', 'asc'])
        });
        this.set('_entities', groupedList);
      } catch (error) {
        this.get('logger').logError('Failed to load business entity', error);
        this.notifyError('Failed to load business entities');
      }
    }),
    //endregion

    //region METHODS
    show: function show() {
      this._loadBusinessEntities.perform();
      this.get('_dialog').show();
    },
    //endregion

    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
      },
      _onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onSave'))) {
          this.get('onSave')(this.get('_selectedBusinessEntity'));
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});