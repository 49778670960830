define("esis-ui/components/areas/admin/contract-type/au/view/au-contract-type-view/component", ["exports", "esis-ui/components/areas/shared/au-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _auPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
      this._loadModel.perform();
    },
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          let response = yield this.get('adminRepo.loadContractType').perform(this.get('tabModel.p0'));
          let contractTypeModel = this.mapJsonObjectToModel(response, 'contract-type-request');
          contractTypeModel.set('defaults', []);
          (0, _lodash.forEach)(response.defaults, item => {
            let ctDefault = this.mapJsonObjectToModel(item, 'contract-type-default');
            if ((0, _lodash.isNil)(contractTypeModel.collateralType)) {
              Ember.set(contractTypeModel, 'collateralType', ctDefault.collateralType);
            }
            contractTypeModel.defaults.pushObject(ctDefault);
          });
          this.set('model', contractTypeModel);
          this.updateTabProperty('title', `Contract Type ${this.paddingToLength(this.get('model.id'), 7)}`);
        } else {
          const hasP1 = Ember.isPresent(this.get('tabModel.p1')) && this.get('tabModel.p1') !== this.emptyGuid();
          const entityGuid = hasP1 ? this.get('tabModel.p1') : this.get('credential.currentBusinessEntity.value');
          let contractType = this.createModel('contract-type-request');
          contractType.setProperties({
            entityGuid: entityGuid,
            defaults: [],
            collateralType: this.get('constants.collateralType.commercial'),
            errors: [],
            itemDeleted: false,
            isActive: true
          });
          this.set('model', contractType);
          (0, _emberLifeline.runTask)(this, () => {
            this.set('_contractTypeModalTitle', 'New Contract Type');
            this.get('_addContractTypeModal').show(this.get('model'));
          }, 300);
          this.set('_isNew', true);
        }
        (0, _emberLifeline.runTask)(this, () => {
          this.get('model').saveModel();
        }, 50);
      } catch (error) {
        this.get('logger').logError('contract-type-view._loadModel', error);
      }
    }),
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let collClassTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.collateralClassTypePicklist'));
        this.set('_collClassTypeList', collClassTypePicklist);
      } catch (error) {
        this.get('logger').logError('au-contract-type._loadPicklist', error);
      }
    }),
    _save: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.saveContractType').perform(this.get('model'));
        this.notifySuccess('Contract type successfully created');
        this.get('model').saveModel();
        this.removeTab('contractTypeListAu');
      } catch (e) {
        this.get('logger').logError('contract-type-view._save', e);
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', e.errorMessage);
      }
    }),
    _validate() {
      let valid = this.get('model').validate();
      this.set('model.errors', []);
      this.set('model.displayException', false);
      if (!Ember.isPresent(this.get('model.defaults')) || !(this.get('model.defaults').length > 0)) {
        this.set('model.errors.contract', 'At least one contract default record required');
        valid = false;
      } else {
        this.set('model.errors.contract', '');
      }
      if (!valid) {
        this.set('model.displayException', true);
      }
      return valid;
    },
    actions: {
      _onSave() {
        if (this._validate()) {
          this._save.perform();
        }
      },
      _editContractType() {
        this.set('_contractTypeModalTitle', 'Edit Contract Type Details');
        this.get('_addContractTypeModal').show(this.get('model'));
      },
      _addCollateral() {
        this.get('_addCollateralClassModal').show();
      },
      _onSaveContractType(model) {
        this.mapOnlySourceProps(model, this.get('model'));
        if (this.get('_isNew') && (0, _lodash.isEmpty)(model.defaults)) {
          this.get('_addCollateralClassModal').show();
        }
        this.set('_isNew', false);
      },
      _onAddCollateralClass(args) {
        let collateral = this.createModel('contract-type-default');
        collateral.set('durationYears', 7);
        const colClass = (0, _lodash.find)(this.get('_collClassTypeList'), {
          value: args.collateralClassType
        });
        let sequenceNumber = (0, _lodash.isEmpty)(this.get('model.defaults')) ? 1 : Math.max(...(0, _lodash.map)(this.get('model.defaults'), 'sequenceNumber')) + 1;
        collateral.setProperties({
          collateralType: args.collateralType,
          collateralClassType: colClass.value,
          collateralClassTypeTitle: colClass.title,
          isSerialised: args.isSerialised,
          isSerialisedOptional: args.isSerialisedOptional,
          sequenceNumber: sequenceNumber
        });
        this.get('_editCollModal').show(collateral);
      },
      _onConfirmAddingCollateral(collateral) {
        if (Ember.isNone(this.get('model.defaults'))) {
          this.set('model.defaults', []);
        }
        this.get('model.defaults').unshiftObject(collateral);
      },
      _onEditCollateral(collateral, index) {
        Ember.set(collateral, 'isConfirmed', true);
        Ember.set(collateral, 'indexId', index);
        this.get('_editCollModal').show(collateral);
      },
      _onUpdateCollateral(collateral) {
        if (!(0, _lodash.isEmpty)(this.get('model.defaults'))) {
          let updatedColl = this.get('model.defaults')[collateral.indexId];
          this.mapObject(collateral, updatedColl);
        }
      },
      _onDeleteCollateral(collateral) {
        this.get('model.defaults').removeObject(collateral);
        this.set('model.itemDeleted', true);
      }
    }
  });
  _exports.default = _default;
});