define("esis-ui/components/shared/ember-bootstrap/wizard-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-lifeline", "lodash"], function (_exports, _sharedComponentBase, _emberLifeline, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALISATION

    steps: null,
    model: null,
    // model should be a child class of wizard request base
    activeStepId: '',
    activeStep: null,
    wizardTitle: '',
    onSubmit: function onSubmit() {},
    onSave: function onSave() {},
    init() {
      this._super(...arguments);
      this._defaultOptions = {
        showSubmitBtn: false,
        showSaveBtn: false,
        saveBtnLabel: 'Save',
        smallSaveBtn: true,
        showNavigationBtn: false,
        positionSubmitBtn: 'top',
        // top, bottom
        positionSaveBtn: 'top',
        // top, bottom
        positionNavigationBtn: 'aligned',
        // aligned (with wizard step buttons), bottom
        alignSubmitBtn: 'left',
        // left, right
        alignSaveBtn: 'left',
        // left, right
        alignNavigationBtn: '',
        // none (when position is aligned with wizard step buttons), left, right,
        allowJump: true
      };
    },
    didInsertElement() {
      this._super(...arguments);
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, this.get('_defaultOptions'), this.get('options'));
      this.set('_mergedOptions', mergedOptions);
    },
    //endregion

    //region COMPUTED  PROPERTIES
    onModelLoad: Ember.computed('model', function () {
      if (Ember.isPresent(this.get('steps'))) {
        //To prevent jumping to first step after save(model resets after save)
        if ((0, _lodash.isEmpty)(this.get('activeStepId'))) {
          this._activateStep(this.get('steps').objectAt(0).get('stepId'));
        }
      }
      return null;
    }),
    orderedSteps: Ember.computed('steps', function () {
      return (0, _lodash.orderBy)(this.get('steps'), 'sortOrder');
    }),
    _activeStep: Ember.computed('steps', 'activeStepId', function () {
      return (0, _lodash.find)(this.get('orderedSteps'), {
        'stepId': this.get('activeStepId')
      });
    }),
    //endregion
    _activateStep(newStepId) {
      if (Ember.isPresent(this.get('steps'))) {
        const steps = this.get('steps');
        const currentStep = steps.findBy('stepId', this.get('activeStepId'));
        const newStep = steps.findBy('stepId', newStepId);
        if (Ember.isPresent(currentStep)) {
          if (newStep.sortOrder > currentStep.sortOrder) {
            if (currentStep.stepComponentInstance.validateStep() !== true || !this.get('_mergedOptions.allowJump') && Math.abs(newStep.sortOrder - currentStep.sortOrder) > 1) {
              // validation error on the step so do not switch;
              return;
            }
          }
        }
        if (this.get('activeStepId') !== newStepId) {
          (0, _emberLifeline.scheduleTask)(this, 'render', () => {
            this.set('activeStepId', newStepId);
            if (Ember.isPresent(newStep) && Ember.isPresent(newStep.stepComponentInstance)) {
              newStep.stepComponentInstance.onActivate();
            }
          });
        }
      }
    },
    _setActiveStep() {
      const activeStep = (0, _lodash.find)(this.get('orderedSteps'), {
        'stepId': this.get('activeStepId')
      });
      this.set('activeStep', activeStep);
    },
    goToPrevious: function goToPrevious() {
      const steps = this.get('orderedSteps');
      const currentIndex = (0, _lodash.findIndex)(steps, {
        'stepId': this.get('activeStepId')
      });
      if (currentIndex > 0) {
        const previousStep = steps.objectAt(currentIndex - 1);
        if (Ember.isPresent(previousStep) && previousStep.get('isVisible') === false) {
          this.goToPrevious(previousStep);
        } else if (Ember.isPresent(previousStep)) {
          this._activateStep(previousStep.get('stepId'));
        }
      }
    },
    goToNext: function goToNext() {
      const steps = this.get('orderedSteps');
      const currentIndex = (0, _lodash.findIndex)(steps, {
        'stepId': this.get('activeStepId')
      });
      const nextStep = steps.objectAt(currentIndex + 1);
      if (Ember.isPresent(nextStep) && nextStep.get('isVisible') === false) {
        this.goToNext(nextStep);
      } else if (Ember.isPresent(nextStep)) {
        this._activateStep(nextStep.get('stepId'));
      }
    },
    actions: {
      previous: function previous() {
        this.goToPrevious();
      },
      next: function next() {
        this.goToNext();
      },
      navigate: function navigate(toStep) {
        if (toStep.get('isVisible') !== false && Ember.isPresent(toStep.get('stepComponentInstance'))) {
          this._activateStep(toStep.get('stepId'));
        }
      },
      save: function save() {
        this.get('onSave')(this.model);
      },
      submit: function submit() {
        this.get('onSubmit')(this.model);
      }
    }
  });
  _exports.default = _default;
});