define("esis-ui/components/areas/shared/email-preview-display/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _emailDetails: null,
    _emailGuid: null,
    load(emailGuid) {
      this.setProperties({
        _emailDetails: null
      });
      this.set('_emailGuid', emailGuid);
      this.get('_loadEmailPreview').perform(emailGuid);
    },
    reset() {
      this.set('_emailDetails', {});
    },
    _loadEmailPreview: (0, _emberConcurrency.task)(function* (emailGuid) {
      try {
        let result = yield this.get('adminRepo.loadEmailPreview').linked().perform(emailGuid);
        // add auth token to images
        const oauthToken = this.getCurrentAccessToken();
        const constants = this.get('constants');
        result.bodyPlainText = result.bodyPlainText.replace(/(img[^>]*src=['"])+(\s*)[^'"]*/g, match => {
          if (match.indexOf('src="#') > 0) {
            return match.replace('#', '');
          }
          if (match.indexOf(this.get('constants.apiBaseUrl')) > 0) {
            // only append auth token if it is a image hosted on DH API server
            return `${match}?${constants.authorisationQsName}=${oauthToken}`;
          }
          return match; // no need to do anything if the image is externally linked.
        });

        this.set('_emailDetails', result);
      } catch (error) {
        this.get('logger').logError(error);
      }
    })
  });
  _exports.default = _default;
});