define("esis-ui/components/areas/admin/system-user/list/preview/system-user-preview/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _userPanel: 'userPanel',
    _historyPanel: 'historyPanel',
    //endregion

    //region COMPUTED PROPERTIES
    _dateLastLoginDisplay: Ember.computed('model.dateLastLogin', function () {
      let date = this.dateAsStr(this.get('model.dateLastLogin'));
      return date === '01/01/0001' ? '' : date;
    }),
    _dateLastLockoutDisplay: Ember.computed('model.dateLastLockout', function () {
      let date = this.dateAsStr(this.get('model.dateLastLockout'));
      return date === '01/01/0001' ? '' : date;
    })
    //endregion
  });
  _exports.default = _default;
});