define("esis-ui/components/areas/bulk-file/au/list/preview/au-bulk-file-preview/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _detailPanel: 'detailPanel',
    _fileLinePanel: 'fileLinePanel',
    _exceptionPanel: 'exceptionPanel',
    OnOpenGrid: function OnOpenGrid() {},
    init() {
      this._super(...arguments);
      this._fileLineFilter = [{
        title: 'All',
        value: 'all'
      }, {
        title: 'Errors',
        value: 'errors'
      }, {
        title: 'Processed',
        value: 'processed'
      }];
      this._resetFileLineFilter();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _fileLineFiltered: Ember.computed('fileLineList', {
      get() {
        return this.get('fileLineList');
      },
      set(key, value) {
        return value;
      }
    }),
    _showFileLineLinkToGrid: Ember.computed('detailDigest.fileType', function () {
      const fileType = this.get('constants.fileType');
      return Ember.isPresent(this.get('detailDigest.fileType')) && (this.get('detailDigest.fileType') === fileType.search || this.get('detailDigest.fileType') === fileType.registrationCsv || this.get('detailDigest.fileType') === fileType.registrationAmend || this.get('detailDigest.fileType') === fileType.bulkRegistrationDischarge);
    }),
    _statusMessage: Ember.computed('detailDigest.statusMessage', function () {
      let statusMessage = String(this.get('detailDigest.statusMessage')).split(/[|]/g);
      for (let i = 0; i < statusMessage.length; i++) {
        statusMessage[i] = statusMessage[i].trim();
      }
      return statusMessage;
    }),
    //endregion

    //region METHODS
    _resetFileLineFilter() {
      this.set('_selectedFilter', this.get('_fileLineFilter').objectAt(0));
    },
    //endregion

    actions: {
      _openGrid(fileLineDigest) {
        this.OnOpenGrid(fileLineDigest);
      },
      _onChangeFilter(option) {
        this.set('_selectedFilter', option);
        let filtered = [];
        if (option.value === 'processed') {
          filtered = this.get('fileLineList').filterBy('isProcessed', true);
          this.set('_fileLineFiltered', filtered);
        } else if (option.value === 'errors') {
          filtered = this.get('fileLineList').filterBy('isProcessed', false);
          this.set('_fileLineFiltered', filtered);
        } else {
          this.set('_fileLineFiltered', this.get('fileLineList'));
        }
      }
    }
  });
  _exports.default = _default;
});