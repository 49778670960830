define("esis-ui/components/shared/help-icon/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    classNames: ['d-inline-block'],
    renderInPlace: false,
    actions: {
      _onFocusOutOfHelpIcon() {
        this.set('_visiblePopover', false);
      },
      _onFocusInHelpIcon(event) {
        event.stopPropagation();
        this.set('_visiblePopover', true);
      }
    }
  });
  _exports.default = _default;
});