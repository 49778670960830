define("esis-ui/components/areas/registration/nz/list/preview/nz-registration-preview/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _previewPanel: 'refPanel',
    //endregion
    onRemoveAllSelectedRegs() {},
    removeSelectedReg() {},
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this.set('_regListGridOption', {
        dataSource: {
          schema: {
            data: _data => {
              (0, _lodash.forEach)(_data, item => {
                item.expiryDateDisplay = this.dateTimeAsStr(item.expiryDate);
                item.registrationDateDisplay = this.dateTimeAsStr(item.registrationDate);
                item.canOpenWizard = item.status !== constants.nzFSStatus.billed && item.status !== constants.nzFSStatus.mbieSubmitted && item.status !== constants.nzFSStatus.mbieSuccess && item.status !== constants.nzFSStatus.pending;
              });
              return _data;
            }
          },
          sort: [{
            field: "date",
            dir: "desc"
          }]
        },
        columns: [{
          field: "registrationNumber",
          title: " ",
          width: "20px",
          filterable: false,
          sortable: false,
          template: `<span data-id="#:registrationNumber#" class="grid-delete-icon">${this.get('constants.fIcon.times')}</span>`
        }, {
          field: "registrationNumber",
          title: "FS Number",
          width: "130px",
          filterable: false,
          // template: '<a data-id="#:guid#" data-wizard="#: isCreating#" class="grid-link-id">#: registrationNumber #</a>'
          template: '<a data-id="#:guid#" data-wizard="#: isCreating#" data-canopenwizard="#: canOpenWizard#"  data-regnumber="#:registrationNumber#" data-spgid="#:spgId#" data-isdraftamendment="#:isDraftAmendment#" class="grid-link-id">#: registrationNumber #</a>'
        }, {
          field: "efxContractNumber",
          title: "Contract Number",
          width: "100px",
          filterable: false
        }, {
          field: "registrationDate",
          title: "Registration Date",
          width: "150px",
          filterable: false,
          template: '#: registrationDateDisplay #'
        }, {
          field: "expiryDate",
          title: "Expiry Date",
          width: "150px",
          filterable: false,
          template: '#: expiryDateDisplay #'
        }, {
          field: "statusTitle",
          title: "Status",
          width: "130px",
          filterable: false
        }]
      });
    },
    loadPreviewGrid(selectedRegistrations) {
      if (Ember.isPresent(this.get('_regListGrid'))) {
        this.get('_regListGrid').loadData(selectedRegistrations);
      }
    },
    _linkClick(wizard, canOpenWizard, guid, regNumber, spgId, isDraftAmendment) {
      if (wizard && canOpenWizard && this.get('credential.hasCreateRegistrationPermissionNz')) {
        this.addTab('registrationWizardNz', guid);
      } else if (!wizard && this.get('credential.hasViewRegistrationPermissionNz')) {
        // this.addTab('registrationViewNz', regNumber, spgId);
        this.addTab('registrationViewNz', regNumber, isDraftAmendment ? 'draft' : 'registration', true, {
          spgId: spgId,
          guid: guid,
          isDraftAmendment: isDraftAmendment
        });
      }
    },
    actions: {
      _onGridLinkClick: function _onGridLinkClick(selectedItem) {
        const wizard = selectedItem.data('wizard');
        const canOpenWizard = selectedItem.data('canopenwizard');
        const guid = selectedItem.data('id');
        const regNumber = selectedItem.data('regnumber');
        const spgId = selectedItem.data('spgid');
        const isDraftAmendment = selectedItem.data('isdraftamendment');
        this._linkClick(wizard, canOpenWizard, guid, regNumber, spgId, isDraftAmendment);
      },
      _openDetailTab: function _openDetailTab() {
        const constants = this.get('constants');
        const wizard = this.get('model.isCreating');
        const status = this.get('model.status');
        const canOpenWizard = status !== constants.nzFSStatus.billed && status !== constants.nzFSStatus.mbieSubmitted && status !== constants.nzFSStatus.mbieSuccess && status !== constants.nzFSStatus.pending;
        const guid = this.get('model.guid');
        const regNumber = this.get('model.registrationNumber');
        const spgId = this.get('model.spgId');
        const isDraftAmendment = this.get('model.isDraftAmendment');
        this._linkClick(wizard, canOpenWizard, guid, regNumber, spgId, isDraftAmendment);
      },
      _removeSelectedRegs() {
        this.get('onRemoveAllSelectedRegs')();
      },
      _onDeleteClick(selectedItem) {
        const regNumber = selectedItem.data('id');
        this.get('removeSelectedReg')(regNumber);
      }
    }
  });
  _exports.default = _default;
});