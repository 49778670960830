define("esis-ui/models/renew-reg-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    option: (0, _modelBase.attr)(),
    customDate: (0, _modelBase.attr)(),
    shouldSyncToPpsr: (0, _modelBase.attr)(),
    disableSevenYear: (0, _modelBase.attr)(),
    disableTwentyFiveYear: (0, _modelBase.attr)(),
    disableCustomDate: (0, _modelBase.attr)(),
    disableNoDuration: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.set('disableSevenYear', false);
      this.set('disableTwentyFiveYear', false);
      this.set('disableCustomDate', false);
      this.set('disableNoDuration', false);
    }
  });
  _exports.default = _default;
});