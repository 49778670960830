define("esis-ui/models/contract-type-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    entityGuid: (0, _modelBase.attr)(),
    contractTypeCode: (0, _modelBase.attr)(),
    title: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    defaults: (0, _modelBase.attr)(null, 'array'),
    collateralType: (0, _modelBase.attr)(),
    itemDeleted: (0, _modelBase.attr)(),
    isActive: (0, _modelBase.attr)(),
    canUpdate: (0, _modelBase.attr)(),
    collateralTypeTitle: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return Ember.isEqual(this.get('collateralType'), constants.collateralType.consumer) ? 'Consumer' : Ember.isEqual(this.get('collateralType'), constants.collateralType.commercial) ? 'Commercial' : '';
    }),
    init() {
      this._super(...arguments);
      this.set('canUpdate', true);
      this.validations = {
        contractTypeCode: {
          presence: true
        },
        title: {
          presence: true
        }
      };
    },
    saveModel: function saveModel() {
      this.save();
      const defaults = this.get('defaults');
      if (Ember.isPresent(defaults) && Ember.isArray(defaults)) {
        (0, _lodash.forEach)(defaults, item => {
          item.save();
        });
      }
    }
  });
  _exports.default = _default;
});