define("esis-ui/components/areas/bulk-file/au/list/au-file-uploader-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Upload Bulk File',
    _fileUpload: null,
    onConfirm: function onConfirm() {},
    init() {
      this._super(...arguments);
    },
    _showApproveAllDischarge: Ember.computed('_selectedFileType', function () {
      return this.get('_selectedFileType.value') === this.get('constants.fileType.bulkRegistrationDischarge') && this.get('credential.currentBusinessEntity.item.enforceRegistrationDischargeApproval') && this.get('credential.hasApproveDischargePermission');
    }),
    //region TASK
    _uploadFile: (0, _emberConcurrency.task)(function* () {
      try {
        let data = this.get('_fileInfo');
        if (Ember.isNone(data) || Ember.isBlank(this.get('_description')) || Ember.isBlank(data.fileKey)) {
          if (Ember.isBlank(this.get('_description'))) {
            this.set('_blankDescription', true);
          }
          if (Ember.isNone(data) || Ember.isBlank(data.fileKey)) {
            this.set('_noFile', true);
          }
        } else {
          this.setProperties({
            _blankDescription: false,
            _noFile: false
          });
          data.description = this.get('_description');
          data.fileType = this.get('_selectedFileType.value');
          data.applyBulkCharge = this.get('_applyBulkCharge');
          data.approveAllDischarge = this.get('_approveAllDischarge');
          data.entityGuid = this.get('credential.currentBusinessEntityGuid');
          yield this.get('fileRepo.saveFileUpload').perform(data);
          if (Ember.isPresent(this.get('_fileUpload'))) {
            this.get('_fileUpload').resetDropzone();
          }
          this.set('_description', '');
          this.set('_fileInfo', null);
          this.onConfirm(this.get('_selectedFileType.value'));
          this.get('_dialog').hide();
        }
      } catch (error) {
        this.notifyError('Fail to upload bulk file error', error);
        this.get('logger').logError('au-fil-uploader-modal._uploadFile', error);
      }
    }),
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('fileRepo.loadFileTypes').perform();
        this.set('_fileTypes', response);
      } catch (error) {
        this.get('logger').logError('au-fil-uploader-modal._loadPicklist', error);
      }
    }),
    //endregion

    _reset() {
      this.setProperties({
        _description: '',
        _fileInfo: null,
        _blankDescription: false,
        _noFile: false,
        _applyBulkCharge: false,
        _approveAllDischarge: false,
        _selectedFileType: null
      });
    },
    show() {
      this._reset();
      this._loadPicklist.perform();
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm: function _onConfirm() {
        this._uploadFile.perform();
      },
      _onClose() {
        this._reset();
        if (Ember.isPresent(this.get('_fileUpload'))) {
          this.get('_fileUpload').resetDropzone();
        }
      },
      _discardFile() {
        this.set('_fileInfo', null);
        this.set('_noFile', false);
      },
      _fileUploaded(data) {
        this.set('_fileInfo', data);
      },
      _onChangeFileType(option) {
        this.set('_selectedFileType', option);
        if (option.value !== this.get('constants.fileType.bulkRegistrationDischarge')) {
          this.set('_approveAllDischarge', false);
        }
      }
    }
  });
  _exports.default = _default;
});