define("esis-ui/components/shared/section-header/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    showEditButton: false,
    addButtonTitle: 'Add',
    secondAddButtonTitle: 'Add',
    editButtonEnabled: true,
    addButtonEnabled: true,
    secondAddButtonEnabled: true,
    removeButtonEnabled: true,
    renewButtonEnabled: true,
    showClearButton: false,
    clearButtonEnabled: true,
    smallWidth: true,
    //--- ACTIONS ---

    actions: {
      //-- BUTTON ACTIONS --

      addAction: function addAction() {
        if (Ember.isPresent(this.get('onAddAction'))) {
          this.get('onAddAction')();
        }
      },
      secondAddAction: function secondAddAction() {
        if (Ember.isPresent(this.get('onSecondAddAction'))) {
          this.get('onSecondAddAction')();
        }
      },
      editAction: function editAction() {
        if (Ember.isPresent(this.get('onEditAction'))) {
          this.get('onEditAction')();
        }
      },
      removeAction: function removeAction() {
        if (Ember.isPresent(this.get('onRemoveAction'))) {
          this.get('onRemoveAction')();
        }
      },
      renewAction: function renewAction() {
        if (Ember.isPresent(this.get('onRenewAction'))) {
          this.get('onRenewAction')();
        }
      },
      onLinkClick: function onLinkClick() {
        if (Ember.isPresent(this.get('onLinkClick'))) {
          this.get('onLinkClick')();
        }
      },
      clearAction: function clearAction() {
        if (Ember.isPresent(this.get('onClearAction'))) {
          this.get('onClearAction')();
        }
      }
    }
  });
  _exports.default = _default;
});