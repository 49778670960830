define("esis-ui/components/areas/spg/nz/list/preview/nz-spg-preview/component", ["exports", "esis-ui/components/areas/shared/preview-base", "ember-concurrency"], function (_exports, _previewBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _previewBase.default.extend({
    panelId: 'spgPreview',
    _loadPreview: (0, _emberConcurrency.task)(function* (data) {
      try {
        let preview = yield this.get('spgRepo.loadNzSpgModel').linked().perform(data);
        this.set('model', preview.securedParties);
      } catch (error) {
        this.get('logger').logError('nz-spg-preview._loadPreview', error);
      }
    }),
    actions: {
      _onTabLoad(tabModel) {
        this.load(tabModel, this.get('_loadPreview'));
      }
    }
  });
  _exports.default = _default;
});