define("esis-ui/components/areas/registration/au/view/au-registration-renew-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _confirmBtnLabel: 'OK',
    _renewRequest: null,
    model: null,
    _submit: false,
    onConfirm: function onConfirm() {},
    _reset() {
      this.setProperties({
        _renewRequest: null
      });
    },
    show(submit) {
      this._reset();
      const constants = this.get('constants');
      this.set('_submit', submit === true);
      if (submit === true) {
        this.set('_confirmBtnLabel', 'Submit');
      } else {
        this.set('_confirmBtnLabel', 'Ok');
      }

      //renew model
      const renewRequest = this.mapObject(this.get('model'), this.createModel('renew-reg-request'));
      renewRequest.setProperties({
        shouldSyncToPpsr: this.get('_submit'),
        disableTwentyFiveYear: this.get('model').collaterals[0].collateralClassType === constants.searchCollateralClassType.motorVehicle && this.get('model').collaterals[0].isSerialised,
        disableNoDuration: this.get('model').collaterals[0].collateralClassType === constants.searchCollateralClassType.motorVehicle && this.get('model').collaterals[0].isSerialised
      });
      renewRequest.save();
      this.set('_renewRequest', renewRequest);

      //setting current expiry date
      if (!(0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        if (this.get('model.collaterals').objectAt(0).isRegistrationEndTimeNotStated === true) {
          this.set('_currentExpiryDate', 'Not Stated');
        } else {
          this.set('_currentExpiryDate', this.dateAsStr(this.get('model.collaterals').objectAt(0).registrationEndTime));
        }
      }
      this.get('_dialog').show();
    },
    _renewRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('registrationRepo.renewRegistration').perform(this.get('_renewRequest'));
        this.get('onConfirm')(result, this.get('_submit'));
        this.get('_dialog').hide();
      } catch (e) {
        this.get('logger').logError('au.registration-renew._renewRegistration', e);
        this.set('_renewRequest.displayException', true);
        this.set('_renewRequest.validatErrorMessage', e.errorMessage);
      }
    }),
    _applyExpiryDateTask: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        const request = this.get('_renewRequest');
        if (!(0, _lodash.isNil)(request)) {
          if (request.option === constants.renewRegTimeOption.noDuration) {
            this.get('_renewConfirmDlg').show('Are you sure you like to set registration end time to not stated?');
            return;
          }
          const result = yield this.get('registrationRepo.getRegistrationExpiryInfo').linked().perform(request);
          this.get('_renewConfirmDlg').show(`Are you sure you like to renew registration to ${this.dateAsStr(result.endTime)}?`);
        }
      } catch (e) {
        this.get('logger').logError('au.registration-renew._applyExpiryDateTask', e);
        this.set('_renewRequest.displayException', true);
        this.set('_renewRequest.validatErrorMessage', e.errorMessage);
      }
    }),
    actions: {
      _onConfirm: function _onConfirm() {
        if (this.get('_renewRequest.isDirty') === true && Ember.isPresent(this.get('_renewRequest.option'))) {
          this.get('_applyExpiryDateTask').perform();
        }
      },
      _onConfirmRenew() {
        this.get('_renewRegistrationTask').perform();
      }
    }
  });
  _exports.default = _default;
});