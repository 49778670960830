define("esis-ui/components/areas/shared/picker/address-book-picker/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/wVI9DW7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"modalTitle\",\"registerAs\",\"size\",\"model\",\"name\",\"confirmButton\"],[[24,[\"modalTitle\"]],[24,[\"_dialog\"]],\"lg\",[24,[\"_model\"]],\"addressPicker\",false]],{\"statements\":[[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/picker/address-book-picker/template.hbs"
    }
  });
  _exports.default = _default;
});