define("esis-ui/components/shared/shared-component-base", ["exports", "esis-ui/mixins/esis-utils", "ember-lifeline", "lodash"], function (_exports, _esisUtils, _emberLifeline, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_esisUtils.default, {
    /*
    reverse component structure, allow parent component to call child component's functions / actions directly
    e.g. provide handle for a data grid define theGrid property in parent componenent, then register instance on template
    as register-as=theGrid.
    then operation such as theGrid.refresh(); can be triggered from parent component.
     */
    registerAs: null,
    /*
    closure action to trigger when pressed. pass closure action reference (hash) from parent tab e.g.
    custom action for dynamic-tab
    {{component myTab.componentName tabModel=myTab removeTab=(action "removeTab" myTab)}}
    */
    /**
     * @param theTabToRemove (myTab) : close current tab.
     * @param theTabToAdd : close current tab and add "theTabToAdd"
     * @param {boolean} removeOtherTab {string} otherTabModel: keep current tab and close otherTabModel
     *  */
    removeTab: null,
    willDestroyElement() {
      this._super(...arguments);
      (0, _emberLifeline.runDisposables)(this); // ensure that lifeline will clean up any remaining async work
    },

    init() {
      this._super(...arguments);
      (0, _emberLifeline.runTask)(this, () => {
        this.set('registerAs', this);
      });
    },
    addTab(name, p0, p1, showClose, options) {
      try {
        const pagePath = this.get(`constants.pagePath.${name}`);
        const newTabModel = this.createModel('tab-model');
        const tabType = this.get(`constants.pageTabType.${name}`);
        const tabIcon = this.get(`constants.pageTabIcon.${name}`) || null;
        newTabModel.setProperties({
          name: name,
          title: this.get(`constants.pageTitle.${name}`),
          tabId: this.get('app').guid(),
          componentName: pagePath,
          p0: p0,
          p1: p1,
          tabType: tabType,
          showIcon: Ember.isPresent(tabType) || Ember.isPresent(tabIcon),
          iconName: tabIcon,
          showClose: showClose !== false,
          options: options
        });
        (0, _emberLifeline.runTask)(this, () => {
          this.get('event').trigger(this.get('constants.events.addNewTab'), newTabModel);
        });
      } catch (e) {
        this.notifyWarn('shared-component-base.addTab', e);
      }
    },
    selectInitialBusinessEntity() {
      try {
        const credential = this.get('credential');
        if (!(0, _lodash.isEmpty)(credential.get('businessEntityPicklist')) && (0, _lodash.isNil)(credential.get('currentBusinessEntity'))) {
          if (!(0, _lodash.isEmpty)(credential.get('currentUser'))) {
            if (credential.get('currentRegion') === this.get('constants.region.au')) {
              credential.set('currentBusinessEntity', (0, _lodash.find)(credential.get('businessEntityPicklist'), {
                'value': credential.get('currentUser.lastAccessAuEntityGuid')
              }));
            } else {
              credential.set('currentBusinessEntity', (0, _lodash.find)(credential.get('businessEntityPicklist'), {
                'value': credential.get('currentUser.lastAccessNzEntityGuid')
              }));
            }
          }
          if ((0, _lodash.isNil)(credential.get('currentBusinessEntity'))) {
            credential.set('currentBusinessEntity', credential.get('businessEntityPicklist').objectAt(0));
          }
        }
      } catch (error) {
        this.get('logger').logError('shared-component-base.selectInitialBusinessEntity', error);
      }
    }
  });
  _exports.default = _default;
});