define("esis-ui/components/areas/spg/au/view/au-address-form/component", ["exports", "lodash", "ember-concurrency", "esis-ui/components/shared/shared-component-base"], function (_exports, _lodash, _emberConcurrency, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    address: null,
    showLarge: false,
    reviewMode: true,
    showClearBtn: false,
    _countries: null,
    init() {
      this._super(...arguments);
    },
    onAddressChanged: Ember.observer('address.line1', 'address.countryCode', 'address.line2', 'address.line3', 'address.locality', 'address.postcode', 'address.state', function () {
      if (this.get('address.addressIsDirty') && !(0, _lodash.isEmpty)(this.get('onChange')) && !(0, _lodash.isEmpty)(this.get('address.addressIsDirty'))) {
        this.get('_onChangeModel')(this.get('address'));
      }
    }),
    labelColGrid: Ember.computed('showLarge', function () {
      return this.get('showLarge') ? 'col-md-3' : 'col-md-5';
    }),
    inputColGrid: Ember.computed('showLarge', function () {
      return this.get('showLarge') ? 'col-md-8' : 'col-md-6';
    }),
    clearBtnColGrid: Ember.computed('showLarge', function () {
      return this.get('showLarge') ? 'col-md-2' : 'col-md-1';
    }),
    mandatory: false,
    required: Ember.computed('mandatory', function () {
      return this.get('mandatory') ? 'required' : '';
    }),
    loadPicklistData() {
      this._loadPicklists.perform();
    },
    copyAddress(sourceAddress) {
      this.get('address').setProperties({
        'line1': sourceAddress.line1,
        'line2': sourceAddress.line2,
        'line3': sourceAddress.line3,
        'locality': sourceAddress.locality,
        'postcode': sourceAddress.postcode,
        'state': sourceAddress.state,
        'countryCode': sourceAddress.countryCode
      });
      this.set('_selectedCountry', (0, _lodash.find)(this.get('_countries'), item => {
        return item.item.countryCode === sourceAddress.countryCode;
      }));
    },
    clearAddress() {
      const originalAdress = this.get('address');
      let newAddress = this.createModel('address');
      newAddress.set('countryCode', 'AU');
      if (originalAdress.get('id') > 0) {
        newAddress.set('id', originalAdress.get('id'));
        newAddress.set('recordVersion', originalAdress.get('recordVersion'));
        newAddress.set('hasChange', originalAdress.get('hasChange'));
      }
      this.set('address', newAddress);
      this.set('_selectedCountry', (0, _lodash.find)(this.get('_countries'), item => {
        return item.item.countryCode === "AU";
      }));
    },
    //region Task
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        const countries = yield this.get('picklistRepo.loadPicklistDigest').perform(constants.get('picklistType.countryPicklist'), false);
        this.set('_countries', countries);
        if (!(0, _lodash.isEmpty)(this.get('_countries'))) {
          const option = (0, _lodash.find)(this.get('_countries'), item => {
            return item.item.countryCode === this.get('address.countryCode');
          });
          if (!(0, _lodash.isEmpty)(option)) {
            this.set('address.countryTitle', option.title);
            this.set('_selectedCountry', option);
          } else {
            this.set('_selectedCountry', (0, _lodash.find)(this.get('_countries'), item => {
              return item.item.countryCode === "AU";
            }));
            this.set('address.countryTitle', this.get('_selectedCountry.title'));
            this.set('address.countryCode', "AU");
          }
          this.get('address').save();
        }
      } catch (error) {
        this.get('logger').logError('au-address-form._loadPicklists', error);
      }
    }),
    //endregion

    actions: {
      _onCountryChanged: function _onCountryChanged(option) {
        this.set('address.countryCode', option.item.countryCode);
        this.set('address.countryTitle', option.title);
        this.set('_selectedCountry', option);
      },
      _clearAddress: function _clearAddress() {
        this.clearAddress();
      }
    }
  });
  _exports.default = _default;
});