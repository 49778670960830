define("esis-ui/components/areas/shared/deploy-help-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---

    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _loadModel: function _loadModel() {
      this.set('_deploymentTarget', this.get('app').getSetting(this.get('constants.systemSetting.helpDeploymentTarget')));
      let requestModel = this.createModel('deploy-help-model');
      this.set('_requestModel', requestModel);
      this.get('_requestModel').save();
    },
    _reset: function _reset() {
      this.setProperties({
        _requestModel: null,
        _deploymentTarget: null
      });
    },
    show() {
      this._reset();
      this._loadModel();
      this.get('_dialog').show();
    },
    //endregion

    //region TASK
    _deployHelpTask: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('adminRepo.deployHelpTopic').linked().perform(this.get('_requestModel'));
        if (response.Published) {
          this.notifySuccess('System Help deployed to ' + response.PublishEnvironment + ' successfully');
        } else {
          this.notifyError('System Help deployment error : ' + response.Errors);
        }
        this.get('_dialog').hide();
      } catch (error) {
        this.get('logger').logError('deploy-help-modal._deployHelpTask', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onSave: function _onSave() {
        this._deployHelpTask.perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});