define("esis-ui/components/areas/spg/au/view/au-access-code-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        _selectedBusinessEntity: null
      });
    },
    show(model) {
      this._reset();
      this.set('_ppsrAvccessCode', model.ppsrSecuredPartyGroupAccessCode);
      this.set('_spgGuid', model.guid);
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _updatePpsrAccessCode: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('spgRepo.updatePpsrAccessCode').perform(this.get('_spgGuid'), this.get('_ppsrAvccessCode'));
        this.notifySuccess(`PPSR access code has been updated`);
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')();
        }
        this.get('_dialog').hide();
      } catch (error) {
        this.get('logger').logError('au-access-code-modal._updatePpsrAccessCode', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region BUTTON ACTIONS
      _onConfirm: function _onConfirm() {
        this.get('_updatePpsrAccessCode').perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});