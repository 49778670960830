define("esis-ui/components/areas/admin/business-entity/view/business-entity-linked-user-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nlUQfiqo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n  \"],[1,[28,\"shared/section-header\",null,[[\"title\",\"showAddButton\",\"onAddAction\",\"showRemoveButton\",\"removeButtonEnabled\",\"onRemoveAction\"],[\"Linked System Users\",true,[28,\"action\",[[23,0,[]],\"_addLinkedUser\"],null],true,[24,[\"_allowRemoveLinkedUser\"]],[28,\"action\",[[23,0,[]],\"_removeLinkedUser\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"],[1,[28,\"shared/kendo/grid/grid-local-ds\",null,[[\"registerAs\",\"options\",\"autoFocus\",\"onSelectionChange\",\"selectFirstRowIfNoSelectedFound\"],[[24,[\"_linkedUserGrid\"]],[24,[\"_gridOption\"]],false,[28,\"action\",[[23,0,[]],\"_onItemSelectionChange\"],null],true]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/admin/business-entity/view/business-entity-linked-user-list/template.hbs"
    }
  });
  _exports.default = _default;
});