define("esis-ui/components/areas/shared/preview-base", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: '',
    // do not generate own div tab
    parentTab: null,
    panelId: 'previewPanel',
    model: null,
    currentGuid: null,
    tabPanel: null,
    parentTabModel: Ember.computed.alias('parentTab.tabModel'),
    hasNoData: Ember.computed.empty('parentTab.tabModel.guid'),
    load(tabModel, taskMethodRef) {
      if (!(0, _lodash.isEmpty)(tabModel) && !(0, _lodash.isEmpty)(tabModel.get('guid')) && ((0, _lodash.isEmpty)(this.get('model')) || tabModel.get('guid') !== this.get('currentGuid'))) {
        this.set('currentGuid', tabModel.get('guid'));
        taskMethodRef.perform(tabModel.get('guid'));
      }
    }
  });
  _exports.default = _default;
});