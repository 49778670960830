define("esis-ui/components/areas/bulk-file/nz/list/nz-bulk-file-list/component", ["exports", "esis-ui/components/areas/shared/nz-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _nzPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region properties
    _bulkFileGrid: null,
    _selectedBulkFile: null,
    _myUploads: true,
    //region INIT
    init() {
      this._super(...arguments);
      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this.get('event').on(this.get('constants.events.notification'), this, '_notificationHandler');
      this._loadPicklists();

      //region BULK FILE GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: this.get('app').getOdataApiUrl('nz-file-uploads')
            },
            parameterMap: (options, operation) => {
              options.viewName = this.get('_view');
              options.fileStatus = this.get('_selectedStatus.value');
              options.myUpload = this.get('_myUploads');
              options.businessEntityGuid = this.get('credential.currentBusinessEntityGuid');
              let keyword = this.get('_filterCriteria');
              if (!(0, _lodash.isEmpty)(keyword)) {
                options.filterKeyword = keyword;
              }
              options._ = this.newGuid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                realData[i].displayDate = this.dateTimeAsStr(realData[i].dateEdited);
                realData[i].hideActionStates = [!realData[i].hasExceptionReport, !realData[i].canDelete];
                realData[i].hasAnyAction = (0, _lodash.includes)(realData[i].hideActionStates, false);
              }
              return realData;
            }
          },
          sort: [{
            field: "fileSessionId",
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": "# if(hasAnyAction) {# actionMenuFieldStyle #} #",
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `# if(hasAnyAction) {# <span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span> #} #`
        }, {
          field: "fileName",
          title: "File Name",
          width: "250px",
          filterable: false
        }, {
          field: "description",
          title: "Description",
          width: "350px",
          filterable: false
        }, {
          field: "recordCount",
          title: "#Records",
          width: "80px",
          filterable: false
        }, {
          field: "fileTypeTitle",
          title: "Type",
          width: "100px",
          filterable: false
        }, {
          field: "usernameEdited",
          title: "User",
          width: "150px",
          filterable: false
        }, {
          field: "dateEdited",
          title: "Date",
          width: "150px",
          filterable: false,
          template: '#: displayDate #'
        }, {
          field: "statusTitle",
          title: "Status",
          width: "150px",
          filterable: false,
          sortable: false
        }]
      });

      //Context Menu Action Ids
      this._downloadFileExceptionActionId = 'nzDownloadFileExceptionId';
      this._deleteActionId = 'nzDeleteBulkFileId';
      this._bulkFileGridContextMenu = [{
        title: 'Download Exceptions',
        id: this.get('_downloadFileExceptionActionId'),
        icon: 'file-download',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region HOOK
    willDestroyElement() {
      this._super(...arguments);
      const constants = this.get('constants');
      this.get('event').off(constants.events.notification, this, '_notificationHandler');
    },
    //endregion

    //region COMPUTED PROPERTIES
    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_bulkFileGrid'))) {
          if (reset === true) {
            this.set('_selectedBulkFile', null);
          }
          this.get('_bulkFileGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      }, 50);
    },
    _notificationHandler: function _notificationHandler(notification) {
      const constants = this.get('constants');
      const logger = this.get('logger');
      switch (notification.type) {
        case constants.notificationIdentifier.nzFsCreateBulkSubmitted:
          {
            this.notifyInfo(`Bulk financing statement registration request ${notification.tag} is being processed`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsAmendBulkSubmitted:
          {
            this.notifyInfo(`Bulk financing statement amendment request ${notification.tag} is being processed`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsRenewBulkSubumitted:
          {
            this.notifyInfo(`Bulk financing statement renew request ${notification.tag} is being processed`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsDischargeBulkSubmitted:
          {
            this.notifyInfo(`Bulk financing statement discharge request ${notification.tag} is being processed`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsDischargeBulkProcessed:
          {
            this.notifyInfo(`Bulk financing statement discharge request ${notification.tag}`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsCreateBulkProcessed:
          {
            this.notifyInfo(`Bulk financing statement registration request ${notification.tag}`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsAmendBulkProcessed:
          {
            this.notifyInfo(`Bulk financing statement amendment request ${notification.tag}`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsRenewBulkProcessed:
          {
            this.notifyInfo(`Bulk financing statement renew request ${notification.tag}`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
        case constants.notificationIdentifier.nzFsBulkFileRejected:
          {
            this.notifyWarn(`Bulk financing statement request ${notification.tag} is invalid`);
            logger.logDebug('nz-bulk-file-list -> bulk file notification', notification);
            this._refreshGrid(true);
            break;
          }
      }
    },
    //endregion

    //region TASK
    _loadPicklists() {
      const constants = this.get('constants');
      //STATUS LIST
      this.set('_filterByStatus', [{
        title: 'All File Statuses',
        value: constants.nzFileUploadStatus.all
      }, {
        title: 'Processing',
        value: constants.nzFileUploadStatus.processing
      }, {
        title: 'Processed',
        value: constants.nzFileUploadStatus.processed
      }, {
        title: 'Partially Processed',
        value: constants.nzFileUploadStatus.partiallyProcessed
      }, {
        title: 'Failed',
        value: constants.nzFileUploadStatus.rejected
      }]);
      this.set('_selectedStatus', this.get('_filterByStatus')[0]);

      //VIEW FILTER
      const options = [{
        title: 'Registration',
        value: constants.nzFileUploadGridViewFilter.registration
      }, {
        title: 'Amendment',
        value: constants.nzFileUploadGridViewFilter.amendment
      }, {
        title: 'Discharge',
        value: constants.nzFileUploadGridViewFilter.discharge
      }, {
        title: 'Renew',
        value: constants.nzFileUploadGridViewFilter.renew
      }, {
        title: 'All',
        value: constants.nzFileUploadGridViewFilter.all
      }];
      this.set('_viewFilterOptions', options);
      this.set('_view', constants.nzFileUploadGridViewFilter.all);
      this._refreshGrid();
    },
    _downloadFileExceptions: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show();
        yield this.get('fileRepo.downloadNzBulkException').linked().perform(this.get('_selectedBulkFile.guid'));
        this.notifySuccess(`Bulk file exceptions for ${this.get('_selectedBulkFile.fileName')} has been downloaded successfully`);
      } catch (error) {
        this.get('logger').logError('nz-bulk-file-list._downloadFileExceptions', error);
        // this.notifyError(`Bulk file exceptions for ${this.get('_selectedBulkFile.fileName')} failed to download, please try again`);
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    _deleteFile: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show();
        yield this.get('fileRepo.deleteNzBulkFile').linked().perform(this.get('_selectedBulkFile.guid'));
        this._refreshGrid();
        this.notifySuccess(`File ${this.get('_selectedBulkFile.fileName')} has been deleted successfully`);
      } catch (error) {
        this.get('logger').logError('nz-bulk-file-list._deleteFile', error);
        this.notifyError(`Failed to delete file ${this.get('_selectedBulkFile.fileName')}, please try again`);
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    //endregion

    //region when tab activate
    onScreenChange() {
      this._refreshGrid(); // soft refresh
    },

    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedBulkFile')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedBulkFile', data);
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedBulkFile', selectedRow);
          switch (selectedOption) {
            case this.get('_downloadFileExceptionActionId'):
              {
                this._downloadFileExceptions.perform();
                break;
              }
            case this.get('_deleteActionId'):
              {
                this.set('_deleteConfirmMsg', `Are you sure you want to delete bulk financing statement file ${this.get('_selectedBulkFile.fileName')}? This action is not reversible.`);
                this.get('_deleteConfirmDlg').show();
                break;
              }
          }
        }
      },
      _onConfirmDeleteFile() {
        this.get('_deleteFile').perform();
      },
      //endregion
      //region FILTER ACTIONS
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view.value)) {
          this.set('_view', view.value);
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this._refreshGrid(true);
      },
      _onMyUploadsChange(value) {
        this.set('_myUploads', value);
        this._refreshGrid();
      },
      _onChangeStatus(selected) {
        this.set('_selectedStatus', selected);
        this._refreshGrid();
      },
      //endregion

      //region File Upload
      _onOpenFileUploader() {
        this.get('_fileUploaderModal').show();
      },
      _onFileUploaded(fileType) {
        const constants = this.get('constants');
        if (fileType === constants.fileType.registrationCsv) {
          this.set('_view', constants.nzFileUploadGridViewFilter.registration);
          this.notifySuccess('Bulk registration file uploaded successfully.');
        } else if (fileType === constants.fileType.registrationAmend) {
          this.set('_view', constants.nzFileUploadGridViewFilter.amendment);
          this.notifySuccess('Bulk amendment file uploaded successfully.');
        } else if (fileType === constants.fileType.bulkRegistrationDischarge) {
          this.set('_view', constants.nzFileUploadGridViewFilter.discharge);
          this.notifySuccess('Bulk discharge file uploaded successfully.');
        } else if (fileType === constants.fileType.registrationRenew) {
          this.set('_view', constants.nzFileUploadGridViewFilter.renew);
          this.notifySuccess('Bulk renew file uploaded successfully.');
        }
        this._refreshGrid(true);
      },
      //endregion
      _createRestGridFilter(options) {
        return this._createRestGridFilter(options);
      }
    }

    //endregion
  });
  _exports.default = _default;
});