define("esis-ui/models/nz-collateral-item-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    dateCreated: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    version: (0, _modelBase.attr)(),
    versionEvent: (0, _modelBase.attr)(),
    itemId: (0, _modelBase.attr)(),
    aircraftClass: (0, _modelBase.attr)(),
    aircraftClassTitle: (0, _modelBase.attr)(),
    chassis: (0, _modelBase.attr)(),
    colour: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    identifyingNumbers: (0, _modelBase.attr)(),
    make: (0, _modelBase.attr)(),
    manufacturerSerial: (0, _modelBase.attr)(),
    model: (0, _modelBase.attr)(),
    registrationMark: (0, _modelBase.attr)(),
    registrationPlate: (0, _modelBase.attr)(),
    vin: (0, _modelBase.attr)(),
    year: (0, _modelBase.attr)(),
    action: (0, _modelBase.attr)(),
    //UI only, Amend only
    newItemGuid: (0, _modelBase.attr)(),
    existingItemId: (0, _modelBase.attr)(),
    mustHaveCollItemDescription: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.aappe || this.get('collateralType') === constants.nzFsCollateralTypes.oth;
    }),
    isMakeOrModelOrYearRequired: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.mv || this.get('collateralType') === constants.nzFsCollateralTypes.acft;
    }),
    showColourInput: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.aappe || this.get('collateralType') === constants.nzFsCollateralTypes.oth || this.get('collateralType') === constants.nzFsCollateralTypes.mv;
    }),
    showIdentifyingNumbersInput: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.aappe || this.get('collateralType') === constants.nzFsCollateralTypes.oth;
    }),
    isMotorVehicle: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.mv;
    }),
    isAircraft: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.acft;
    }),
    init() {
      this._super(...arguments);
      this.validations = {
        description: {
          custom: [{
            validation: (key, value, model) => {
              if (this.get('mustHaveCollItemDescription')) {
                if (Ember.isPresent(model.get('colour')) || Ember.isPresent(model.get('year')) || Ember.isPresent(model.get('model')) || Ember.isPresent(model.get('make') || model.get('identifyingNumbers'))) {
                  return Ember.isPresent(value);
                }
              }
              return true;
            },
            message: 'Description can\'t be blank'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length > 2 && value.length < 400;
              }
              return true;
            },
            message: 'Description must be between 3 and 400 characters long'
          }]
        },
        make: {
          custom: {
            validation: (key, value) => {
              if (this.get('isMakeOrModelOrYearRequired')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'can\'t be blank'
          }
        },
        model: {
          custom: {
            validation: (key, value) => {
              if (this.get('isMakeOrModelOrYearRequired')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'can\'t be blank'
          }
        },
        year: {
          custom: [{
            validation: (key, value) => {
              if (this.get('isMakeOrModelOrYearRequired')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'can\'t be blank'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value) && +value >= 0) {
                return +value >= 1700 && +value < new Date().getFullYear() + 1;
              }
              return true;
            },
            message: `Year must be between 1700 and ${new Date().getFullYear() + 1}`
          }],
          format: {
            with: /^[0-9]+$/,
            message: 'Year should be numeric',
            allowBlank: true
          }
        },
        aircraftClass: {
          custom: {
            validation: (key, value) => {
              if (this.get('isAircraft')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'can\'t be blank'
          }
        },
        vin: {
          custom: [{
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length === 17;
              }
              return true;
            },
            message: 'VIN must be 17 characters long'
          }, {
            validation: (key, value, model) => {
              if (this.get('isMotorVehicle') && !Ember.isPresent(model.get('chassis')) && !Ember.isPresent(model.get('registrationPlate'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'VIN or Chassis Number or Reg No must be specified'
          }]
        },
        chassis: {
          custom: {
            validation: (key, value, model) => {
              if (this.get('isMotorVehicle') && !Ember.isPresent(model.get('vin')) && !Ember.isPresent(model.get('registrationPlate'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'VIN or Chassis Number or Reg No must be specified'
          }
        },
        registrationPlate: {
          custom: [{
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length < 7;
              }
              return true;
            },
            message: 'Reg No can\'t be more than 6 characters long'
          }, {
            validation: (key, value, model) => {
              if (this.get('isMotorVehicle') && !Ember.isPresent(model.get('chassis')) && !Ember.isPresent(model.get('vin'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'VIN or Chassis Number or Reg No must be specified'
          }]
        },
        registrationMark: {
          custom: [{
            validation: (key, value, model) => {
              if (this.get('isAircraft') && !Ember.isPresent(model.get('manufacturerSerial'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Registration Mark or Manufacturer Serial number must be specified'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length <= 7 && value.length >= 3;
              }
              return true;
            },
            message: 'Registration mark must be between 3 and 7 characters long'
          }]
        },
        manufacturerSerial: {
          custom: {
            validation: (key, value, model) => {
              if (this.get('isAircraft') && !Ember.isPresent(model.get('registrationMark'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Registration Mark or Manufacturer Serial number must be specified'
          }
        }
      };
    }
  });
  _exports.default = _default;
});