define("esis-ui/components/areas/search/au/view/shared/au-search-result-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "esis-ui/models/grantor-search-result-filter-criteria", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _grantorSearchResultFilterCriteria, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALISATION
    _resultModel: null,
    _resultFilter: null,
    _resultGrid: null,
    _filterSummary: '',
    _retrievalProgressJobId: null,
    _certificateRetrievalProgressJobId: null,
    init() {
      this._super(...arguments);
      const app = this.get('app');
      let filterCriteria = _grantorSearchResultFilterCriteria.default.create();
      this.set('_filterCriteria', filterCriteria);
      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._previewPanel = `previewPanel${this.get('app').guid()}`;
      this._grantorPanel = `grantorPanel${this.get('app').guid()}`;
      this._attachmentPanel = `attachmentPanel${this.get('app').guid()}`;
      if (this.get('model.isExisting')) {
        this._loadGrantorSearchResultFilter.perform(this.get('model.guid'));
      }
      this.get('event').on(this.get('constants.events.requestSearchCertificate'), this, '_searchCertificateRetrievalProgress');

      // pollTask
      if ((0, _lodash.isNil)(this.get('_retrievalProgressJobId'))) {
        this.set('_retrievalProgressJobId', (0, _emberLifeline.pollTask)(this, '_searchRetrievalProgress'));
      }
      if ((0, _lodash.isNil)(this.get('_certificateRetrievalProgressJobId'))) {
        this.set('_certificateRetrievalProgressJobId', (0, _emberLifeline.pollTask)(this, '_searchCertificateRetrievalProgress'));
      }
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('searchResults')
            },
            parameterMap: (options, operation) => {
              options.id = this.get('model.guid');
              let filter = this.get('_filterCriteria');
              if (Ember.isPresent(filter)) {
                options.resultFilter = JSON.stringify(filter.collectCollateralFilter());
              }
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                realData[i].registrationStartTimeDisplay = this.dateTimeAsStr(realData[i].registrationStartTime);
                realData[i].registrationEndTimeDisplay = this.dateTimeAsStr(realData[i].registrationEndTime);
              }
              return realData;
            }
          }
        },
        columns: [{
          field: "registrationNumber",
          title: "Registration Number",
          filterable: false,
          width: "150px"
        }, {
          field: "registrationStartTimeDisplay",
          title: "Reg. Date",
          filterable: false,
          width: "150px"
        }, {
          field: "registrationEndTimeDisplay",
          title: "Expiry Date",
          filterable: false,
          width: "150px"
        }, {
          field: "collateralClassDescription",
          title: "Collateral Class",
          filterable: false,
          width: "150px"
        }, {
          field: "collateralDescription",
          title: "Collateral Description",
          filterable: false
        }, {
          field: "securedParties",
          title: "Secured Parties",
          filterable: false
        }, {
          field: "grantors",
          title: "Grantors",
          filterable: false
        }],
        height: 350
      });
    },
    //endregion

    //region COMPUTED PROPERTIES
    _showFilterControl: Ember.computed('model.{searchType,hasSearchResults}', function () {
      const constants = this.get('constants');
      let searchType = this.get('model.searchType');
      return this.get('model.hasSearchResults') && (searchType === constants.searchType.searchByGrantor || searchType === constants.searchType.searchByGrantorAndEventDateRange);
    }),
    _resultRetrieving: Ember.computed('model.searchRetrievalProgress', function () {
      return !(0, _lodash.isNil)(this.get('model.searchRetrievalProgress')) && this.get('model.searchRetrievalProgress') < 100;
    }),
    _initGrid: Ember.observer('_resultGrid', function () {
      if (!(0, _lodash.isNil)(this.get('_resultGrid'))) {
        this._refreshGrid();
      }
    }),
    //endregion

    //region HOOK
    willDestroyElement: function willDestroyElement() {
      this._super(...arguments);
      this.get('event').off(this.get('constants.events.requestSearchCertificate'), this, '_searchCertificateRetrievalProgress');
      this._stopJob('_retrievalProgressJobId');
      this._stopJob('_certificateRetrievalProgressJobId');
    },
    _stopJob(jobId) {
      if (Ember.isPresent(this.get(jobId))) {
        (0, _emberLifeline.cancelPoll)(this, this.get(jobId));
        this.set(jobId, null);
      }
    },
    //endregion

    //region METHODS
    _refreshGrid(reset) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_resultGrid'))) {
          if (reset === true) {
            this.set('_selectedSearch', null);
          }
          this.get('_resultGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    refresh() {
      this._refreshGrid(true);
    },
    _searchRetrievalProgress() {
      const constants = this.get('constants');
      let retProgress = this.get('model.searchRetrievalProgress');
      if (Ember.isPresent(this.get('model.id')) && this.get('model.id') > 0 && this.get('model.status') === constants.statusValue.submitted) {
        if (retProgress < 100) {
          this.get('searchRepo.loadSearchRetrievalProgress').perform(this.get('model.guid')).then(progress => {
            if (retProgress !== progress) {
              this.get('logger').logDebug('updating search result retrieval status', progress);
              this.set('model.searchRetrievalProgress', progress);
              this.get('model').save();
              if (progress === 100) {
                (0, _emberLifeline.runTask)(this, '_onRetrievalProgressFinish', 250);
                // this._onRetrievalProgressFinish();
              } else {
                this._refreshGrid();
              }
            }
          }, error => {
            this.get('logger').logError('au-search-result-control._loadSearchRetrievalProgress', error);
          });
        }
      }
      (0, _emberLifeline.runTask)(this, '_searchRetrievalProgress', 10 * 1000);
    },
    _onRetrievalProgressFinish() {
      this._refreshGrid();
      this._loadGrantorSearchResultFilter.perform(this.get('model.guid'));
    },
    _searchCertificateRetrievalProgress() {
      let certProgress = this.get('model.searchCertificateRetrievalProgress');
      if (this.get('model.hasNilResult') && this.get('model.bulkCertificateNotRequested') || certProgress === 0) {
        this._stopJob('_certificateRetrievalProgressJobId');
      } else {
        if (certProgress > 0 && certProgress < 100) {
          this._loadSearchCertificateRetrievalProgress.perform().then(progress => {
            const id = this.get('model.id');
            if (Ember.isPresent(this.get('_resultModel'))) {
              if (Ember.isPresent(this.get('_resultModel.certificateNotRequested')) && progress > 0 && id > 0 && this.get('_resultModel.certificateNotRequested')) {
                this._loadSearchResultDigest.perform(this.get('_resultModel.guid'));
              } else if (progress === 100) {
                if (Ember.isPresent(this.get('_resultModel.certificateNotRequested'))) {
                  this.set('_resultModel.isCertificateRetrieved', true);
                }
                if (id > 0) {
                  this._loadSearchResultDigest.perform(this.get('_resultModel.guid'));
                }
              }
            }
            if (certProgress !== progress && progress !== 0) {
              this.get('logger').logDebug('updating search certificate retrieval status', progress);
              this.set('model.searchCertificateRetrievalProgress', progress);
              this.get('model').save();
            }
          }, error => {
            this.get('logger').logError('au-search-result-control._searchCertificateRetrievalProgress', error);
          });
        }
        (0, _emberLifeline.runTask)(this, '_searchCertificateRetrievalProgress', 2 * 1000);
      }
    },
    //endregion

    //region TASKS
    _loadSearchResultDigest: (0, _emberConcurrency.task)(function* (id) {
      try {
        let response = yield this.get('searchRepo.loadSearchResultDigest').linked().perform(id);
        let searchDigest = this.mapJsonObjectToModel(response, 'search-result-detail-digest');
        this.set('_resultModel', searchDigest);
        this.get('logger').logDebug('search result digest loaded', searchDigest);
      } catch (error) {
        this.get('logger').logError('au-search-result-control._loadSearchResultDigest', error);
        this.notifyError('fail to load search result detail digest.');
      }
    }),
    _requestSearchCertificate: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.requestSearchCertificate').linked().perform(this.get('_resultModel.guid'));
        this.set('_resultModel.isCertificateRetrieved', false);
        this.set('model.searchCertificateRetrievalProgress', 1);
        this.get('model').save();
        this.notifyInfo('Search Certificate Requested Successful');
      } catch (error) {
        this.get('logger').logError('au-search-result-control._requestSearchCertificate', error);
        this.notifyError('request search certificate failed');
      }
    }),
    _downloadSearchCertificate: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.downloadSearchCertificate').linked().perform(this.get('_resultModel.guid'));
      } catch (error) {
        this.get('logger').logError('au-search-result-control._downloadSearchCertificate', error);
      }
    }),
    _downloadSearchCertAttachment: (0, _emberConcurrency.task)(function* (attachment) {
      try {
        yield this.get('searchRepo.downloadSearchCertAttachment').linked().perform(attachment.guid);
        this.notifyInfo(`Search certificates attachment ${attachment.title} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-search-result-control._downloadSearchCertAttachment', error);
        this.notifyError(`Search certificate attachment ${attachment.title} failed to downloaded, please try again`);
      }
    }),
    _loadGrantorSearchResultFilter: (0, _emberConcurrency.task)(function* (id) {
      try {
        let grantorSearchResultFilter = yield this.get('searchRepo.loadGrantorSearchResultFilters').linked().perform(id);
        this.set('_filterCriteria.searchResultFilterCriteria', this.mapJsonObjectToModel(grantorSearchResultFilter, 'grantor-search-result-filter-digest'));
      } catch (error) {
        this.get('logger').logError('au-search-result-control._loadGrantorSearchResultFilter', error);
      }
    }),
    _loadSearchCertificateRetrievalProgress: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.get('searchRepo.loadSearchCertificateRetrievalProgress').linked().perform(this.get('model.guid'));
      } catch (error) {
        this.get('logger').logError('au-search-result-control._loadSearchCertificateRetrievalProgress', error);
      }
    }),
    //endregion

    actions: {
      _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedSearch')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedSearch', data);
        if (!(0, _lodash.isNil)(this.get('_selectedSearch'))) {
          this._loadSearchResultDigest.perform(data.guid);
        }
      },
      _onOpenFilterDlg() {
        this.get('_resultFilter').show(this.get('_filterCriteria'));
      },
      _onResetFilter() {
        this.set('_filterSummary', '');
        this.get('_filterCriteria').reset();
        this._refreshGrid(true);
      },
      _applyFilter() {
        this.set('_filterSummary', this.get('_filterCriteria').searchResultFilterSummary());
        this.set('_filterCriteria.forceRefresh', !this.get('_filterCriteria.forceRefresh'));
        this._refreshGrid();
      },
      _discardFilterChange() {
        // todo the following is a temporary fix ??
        let grantorSearchResultFilter = this.get('_filterCriteria.searchResultFilterCriteria');
        this.get('_filterCriteria').rollback();
        this.set('_filterCriteria.searchResultFilterCriteria', grantorSearchResultFilter);
      },
      _onSwitch(tabComponent, newTab) {
        tabComponent.select(newTab);
      },
      //region Download, Certificate
      _downloadCertAttachment(attachment) {
        this._downloadSearchCertAttachment.perform(attachment);
      },
      _requestCertificate: function _requestCertificate() {
        this._requestSearchCertificate.perform();
      },
      _previewCertificate: function _previewCertificate() {
        this._downloadSearchCertificate.perform();
      }
      //endregion
    }
  });
  _exports.default = _default;
});