define("esis-ui/components/areas/admin/contract-type/nz/view/nz-edit-collateral-profile-modal/component", ["exports", "esis-ui/components/areas/admin/contract-type/nz/view/nz-collateral-profile-page-base", "lodash", "ember-concurrency"], function (_exports, _nzCollateralProfilePageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzCollateralProfilePageBase.default.extend({
    //region INITIALIZATION ---
    _selectedBusinessEntity: null,
    _defaultBusinessEntityGuid: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null,
        '_selectedBusinessEntity': null
      });
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _selectedBusinessEntity: null,
        _defaultBusinessEntityGuid: null
      });
    },
    _createModel(model) {
      if (model.entityGuid !== this.emptyGuid()) {
        this.set('_defaultBusinessEntityGuid', model.entityGuid);
      } else {
        this.set('_defaultBusinessEntityGuid', this.get('credential.currentBusinessEntityGuid'));
      }
      this.set('model', this.mapObject(model, this.createModel('nz-collateral-profile-request')));
      this.set('model.entityGuid', this.get('_defaultBusinessEntityGuid'));
      this.set('model.defaults', []);
      if (!(0, _lodash.isEmpty)(model.defaults)) {
        (0, _lodash.forEach)(model.defaults, item => {
          let collateralProfileDefault = this.mapObject(item, this.createModel('nz-collateral-profile-default'));
          this.get('model.defaults').pushObject(collateralProfileDefault);
        });
      }
      this.get('model').saveModel();
    },
    show(model) {
      this._reset();
      this._createModel(model);
      this._loadPicklist.perform();
      this.get('_dialog').show();
    },
    //endregion

    //region TASK
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('_defaultBusinessEntityGuid')
        }));
      } catch (error) {
        this.get('logger').logError('nz-edit-collateral-profile-modal._loadPicklist', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('model.entityGuid', businessEntity.value);
      },
      _onConfirm() {
        this.get('saveCollateralProfile').perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});