define("esis-ui/components/areas/spg/nz/wizard/nz-spg-review-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region INITIALIZATION
    isLastStep: true,
    validateStep: function validateStep() {
      return true;
    },
    //endregion

    //region COMPUTED PROPERTIES
    _hasSecuredParties: Ember.observer('model.securedParties', function () {
      return !(0, _lodash.isEmpty)(this.get('model.securedParties'));
    }),
    _lastIndex: Ember.computed('model.securedParties.@each', function () {
      return (0, _lodash.isEmpty)(this.get('model.securedParties')) ? 0 : this.get('model.securedParties.length') - 1;
    }),
    //endregion

    //region TASK
    _validate: (0, _emberConcurrency.task)(function* (model) {
      let valid = model.validate();
      if (valid) {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        try {
          let result = yield this.get('spgRepo.validateCreateNzSpg').perform(model);
          if (result.isValid) {
            this.set('model.displayException', false);
            this.set('model.validatErrorMessage', '');
          } else {
            this.set('model.displayException', true);
            this.set('model.validatErrorMessage', result.validationErrorMessage);
          }
        } catch (error) {
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', error);
        }
      } else {
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', 'Please fill required fields');
      }
    }),
    //endregion

    //region METHODS
    onActivate() {
      this._validate.perform(this.get('model'));
    },
    //endregion

    //region ACTIONS
    actions: {}
    //endregion
  });
  _exports.default = _default;
});