define("esis-ui/components/areas/shell/au/au-nav-bar/component", ["exports", "esis-ui/components/areas/shell/nav-bar-base"], function (_exports, _navBarBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _navBarBase.default.extend({
    //region Computed Properties
    showAdminMenu: Ember.computed('credential.currentUser', function () {
      const credential = this.get('credential');
      return credential.isSuperAdmin || credential.get('hasAdminPermission') || credential.get('hasManageEntityPermission') || credential.get('hasViewEntityPermission') || credential.get('hasManageUsersPermission') || credential.get('hasViewContractTypePermission') || credential.get('hasViewSpgPermissionAu') || credential.get('hasViewAccountingPermission');
    }),
    showReportMenu: Ember.computed('credential.currentUser', function () {
      const credential = this.get('credential');
      return credential.get('hasAdminPermission') || credential.get('hasViewRegistrationPermissionAu');
    }),
    showRegistrationMenu: Ember.computed('credential.currentUser', function () {
      const credential = this.get('credential');
      return credential.get('hasViewRegistrationPermissionAu');
    }),
    showSearchMenu: Ember.computed('credential.currentUser', function () {
      const credential = this.get('credential');
      return credential.get('hasViewSearchPermissionAu') || credential.get('hasViewAllSearchPermissionAu');
    }),
    showBulkMenu: Ember.computed('credential.currentUser', function () {
      const credential = this.get('credential');
      return credential.get('hasViewBulkloadPermissionAu');
    }),
    showHomeMenu: Ember.computed('credential.currentUser', function () {
      const credential = this.get('credential');
      return credential.get('currentUser.isCitecSearchUser') !== true;
    }),
    //endregion

    //region Notification Handler
    _connectionStatusChanged(connectionStatus) {
      const constants = this.get('constants');
      this.set('_apiServiceStatus', connectionStatus);
      if (connectionStatus === constants.connectionStatus.bad || connectionStatus === constants.connectionStatus.broken) {
        this.set('_conStatusClass', 'red');
      } else if (connectionStatus === constants.connectionStatus.checking) {
        this.set('_conStatusClass', 'orange');
      } else if (connectionStatus === constants.connectionStatus.good) {
        if (Ember.isPresent(this.get('_ppsrServiceStatus')) && this.get('_ppsrServiceStatus').register && this.get('_ppsrServiceStatus').mailbox && this.get('_ppsrServiceStatus').search && this.get('_ppsrServiceStatus').securedPartyGroup) {
          this.set('_conStatusClass', 'green');
        } else {
          this.set('_conStatusClass', 'orange');
        }
      } else {
        this.set('_conStatusClass', 'gray');
      }
    },
    //endregion

    actions: {
      _switchToHomeTab: function _switchToHomeTab() {
        //need to set p0, p1 exactly same as existing home tab to do the switch
        this.addTab("homeAu", null, null, false);
      },
      _changeRoute(region) {
        if (region === this.get('constants.region.nz')) {
          this.set('credential.currentBusinessEntity', null);
          this.set('credential.businessEntityPicklist', null);
          this.get('router').transitionTo('nz');
        }
      },
      _onAbnNameLookupClick() {
        this.get('_abnNameLookupDlg').show();
      }
    }
  });
  _exports.default = _default;
});