define("esis-ui/components/areas/search/au/view/shared/au-search-action-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    classNames: ['d-inline-block'],
    onCreateSearch: function onCreateSearch() {},
    onUpdateTab: function onUpdateTab() {},
    onDelete: function onDelete() {},
    onLoadSearch: function onLoadSearch() {},
    //region CITEC modal dlg
    _waitingModal: null,
    _citecFeeModal: null,
    _citecTopUpModal: null,
    //endregion

    //region Computed
    _downloadVerifyLabel: Ember.computed('model.hasNilResult', function () {
      return this.get('model.hasNilResult') ? 'Download Nil Certificate' : 'Download All Certificates';
    }),
    _requestCertLabel: Ember.computed('model.hasNilResult', function () {
      return this.get('model.hasNilResult') ? 'Request Nil Certificate' : 'Request All Certificate';
    }),
    _organisationValidationRequest: Ember.computed('criteria.{abn,acn,arbn,arsn,searchName}', function () {
      if (Ember.isPresent(this.get('criteria'))) {
        let request = {
          abnNumber: this.get('criteria.abn'),
          acnNumber: this.get('criteria.acn'),
          arbnNumber: this.get('criteria.arbn'),
          arsnNumber: this.get('criteria.arsn'),
          organisationName: this.get('criteria.searchName')
        };
        return this.convertToEmberObject(request);
      }
      return {};
    }),
    _showCommandButtons: Ember.computed('model.searchType', 'criteria', 'criteria.criteriaAcceptGrantorIndividualSearchDeclaration', function () {
      let searchHeader = this.get('model');
      let criteria = this.get('criteria');
      const constants = this.get('constants');
      if (Ember.isPresent(searchHeader) && Ember.isPresent('criteria')) {
        if ((searchHeader.get('searchType') === constants.searchType.searchByGrantor || searchHeader.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange) && !criteria.get('isOrganisationSearch')) {
          return Ember.isPresent(criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration')) && criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration');
        }
        return true;
      }
      return false;
    }),
    _showVerifyBtn: Ember.computed('criteria.{abnSelected,abn}', 'model.searchType', 'criteria', function () {
      let searchHeader = this.get('model');
      let criteria = this.get('criteria');
      const constants = this.get('constants');
      if (Ember.isPresent(searchHeader) && Ember.isPresent('criteria')) {
        if (searchHeader.get('searchType') === constants.searchType.searchByGrantor || searchHeader.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange) {
          if (!criteria.get('isOrganisationSearch')) {
            return criteria.get('abnSelected') && Ember.isPresent(criteria.get('abn'));
          }
          return true;
        }
        return false;
      }
      return false;
    }),
    _showSearchMe: Ember.computed('model.{status,isNew}', function () {
      const constants = this.get('constants');
      return !(0, _lodash.isEmpty)(this.get('model')) && (this.get('model.isNew') || this.get('model.status') === constants.statusValue.error || this.get('model.status') === constants.statusValue.waitingOnFunds);
    }),
    _canSearchAgain: Ember.computed('model.status', function () {
      const constants = this.get('constants');
      return !(0, _lodash.isEmpty)(this.get('model')) && (this.get('model.status') === constants.statusValue.error || this.get('model.status') === constants.statusValue.waitingOnFunds);
    }),
    _certificateRetrieving: Ember.computed('model.{searchCertificateRetrievalProgress,bulkCertificateNotRequested}', function () {
      return !this.get('model.bulkCertificateNotRequested') && !(0, _lodash.isNil)(this.get('model.searchCertificateRetrievalProgress')) && this.get('model.searchCertificateRetrievalProgress') > 0 && this.get('model.searchCertificateRetrievalProgress') < 100;
    }),
    //endregion

    //region private methods
    _showWaitModalDlg() {
      if (this.get('_isCitecUser') === true && !(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing !== true) {
        this.get('_waitingModal').show('Please be patient while we are submitting your request...');
      }
    },
    _hideWaitModalDlg() {
      if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing === true) {
        this.get('_waitingModal').hide();
      }
    },
    //region HELPER
    _createOrganisationSearch: function _createOrganisationSearch(searchHeaderModel, criteria) {
      let constants = this.get('constants');
      if (criteria.get('abnSelected') && Ember.isPresent(criteria.get('abn'))) {
        let a = this.onCreateSearch(searchHeaderModel);
        a.set('criteriaGrantorType', constants.grantorType.organisation);
        a.set('criteriaOrganisationNumberType', constants.organisationNumberType.ABN);
        a.set('criteriaOrganisationNumber', criteria.get('abn'));
      }
      if (criteria.get('acnSelected') && Ember.isPresent(criteria.get('acn'))) {
        let b = this.onCreateSearch(searchHeaderModel);
        b.set('criteriaGrantorType', constants.grantorType.organisation);
        b.set('criteriaOrganisationNumberType', constants.organisationNumberType.ACN);
        b.set('criteriaOrganisationNumber', criteria.get('acn'));
      }
      if (criteria.get('arbnSelected') && Ember.isPresent(criteria.get('arbn'))) {
        let c = this.onCreateSearch(searchHeaderModel);
        c.set('criteriaGrantorType', constants.grantorType.organisation);
        c.set('criteriaOrganisationNumberType', constants.organisationNumberType.ARBN);
        c.set('criteriaOrganisationNumber', criteria.get('arbn'));
      }
      if (criteria.get('arsnSelected') && Ember.isPresent(criteria.get('arsn'))) {
        let d = this.onCreateSearch(searchHeaderModel);
        d.set('criteriaGrantorType', constants.grantorType.organisation);
        d.set('criteriaOrganisationNumberType', constants.organisationNumberType.ARSN);
        d.set('criteriaOrganisationNumber', criteria.get('arsn'));
      }
      if (criteria.get('searchNameSelected') && Ember.isPresent(criteria.get('searchName'))) {
        var e = this.onCreateSearch(searchHeaderModel);
        e.set('criteriaGrantorType', constants.grantorType.organisation);
        e.set('criteriaOrganisationName', criteria.get('searchName'));
      }
      if (criteria.get('verifiedNameSelected') && Ember.isPresent(criteria.get('verifiedName'))) {
        let f = this.onCreateSearch(searchHeaderModel);
        f.set('criteriaGrantorType', constants.grantorType.organisation);
        f.set('criteriaOrganisationName', criteria.get('verifiedName'));
      }
    },
    _createIndividualSearch: function _createIndividualSearch(searchHeaderModel, criteria) {
      let constants = this.get('constants');
      if (criteria.get('abnSelected') && Ember.isPresent(criteria.get('abn'))) {
        let a = this.onCreateSearch(searchHeaderModel);
        a.set('criteriaGrantorType', constants.grantorType.organisation);
        a.set('criteriaOrganisationNumberType', constants.organisationNumberType.ABN);
        a.set('criteriaOrganisationNumber', criteria.get('abn'));
        if (Ember.isPresent(criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'))) {
          a.set('criteriaAcceptGrantorIndividualSearchDeclaration', criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'));
        }
      }
      if (criteria.get('individual1Selected') && Ember.isPresent(criteria.get('givenName1')) && Ember.isPresent(criteria.get('familyName1'))) {
        let b = this.onCreateSearch(searchHeaderModel);
        b.set('criteriaGrantorType', constants.grantorType.individual);
        b.set('criteriaGivenNames', criteria.get('givenName1'));
        b.set('criteriaFamilyName', criteria.get('familyName1'));
        b.set('criteriaDateOfBirth', criteria.get('dob1'));
        if (Ember.isPresent(criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'))) {
          b.set('criteriaAcceptGrantorIndividualSearchDeclaration', criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'));
        }
      }
      if (criteria.get('individual2Selected') && Ember.isPresent(criteria.get('givenName2')) && Ember.isPresent(criteria.get('familyName2'))) {
        let c = this.onCreateSearch(searchHeaderModel);
        c.set('criteriaGrantorType', constants.grantorType.individual);
        c.set('criteriaGivenNames', criteria.get('givenName2'));
        c.set('criteriaFamilyName', criteria.get('familyName2'));
        c.set('criteriaDateOfBirth', criteria.get('dob2'));
        if (Ember.isPresent(criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'))) {
          c.set('criteriaAcceptGrantorIndividualSearchDeclaration', criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'));
        }
      }
      if (criteria.get('individual3Selected') && Ember.isPresent(criteria.get('givenName3')) && Ember.isPresent(criteria.get('familyName3'))) {
        let d = this.onCreateSearch(searchHeaderModel);
        d.set('criteriaGrantorType', constants.grantorType.individual);
        d.set('criteriaGivenNames', criteria.get('givenName3'));
        d.set('criteriaFamilyName', criteria.get('familyName3'));
        d.set('criteriaDateOfBirth', criteria.get('dob3'));
        if (Ember.isPresent(criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'))) {
          d.set('criteriaAcceptGrantorIndividualSearchDeclaration', criteria.get('criteriaAcceptGrantorIndividualSearchDeclaration'));
        }
      }
    },
    _createMvSearch: function _createMvSearch(searchHeaderModel, criteria) {
      const constants = this.get('constants');
      if (Ember.isPresent(criteria.get('vin'))) {
        let a = this.onCreateSearch(searchHeaderModel);
        a.set('criteriaSerialNumberType', constants.get('serialNumberType.VIN'));
        a.set('criteriaSerialNumber', criteria.get('vin'));
      }
      if (Ember.isPresent(criteria.get('chassis'))) {
        let a = this.onCreateSearch(searchHeaderModel);
        a.set('criteriaSerialNumberType', constants.get('serialNumberType.chassisNumber'));
        a.set('criteriaSerialNumber', criteria.get('chassis'));
      }
      if (Ember.isPresent(criteria.get('manufacturersNumber'))) {
        let a = this.onCreateSearch(searchHeaderModel);
        a.set('criteriaSerialNumberType', constants.get('serialNumberType.motorVehicleManufacturersNumber'));
        a.set('criteriaSerialNumber', criteria.get('manufacturersNumber'));
      }
    },
    //endregion

    //endregion
    //region Methods
    _search() {
      const self = this;
      const logger = self.get('logger');
      let searchHeader = self.get('model');
      let criteria = self.get('criteria');
      let valid;
      if ((0, _lodash.isEmpty)(searchHeader)) {
        logger.logError('Search command bar : search model is empty!');
        this.notifyError('System error please contact your system administrator. Error code : 123456');
      }
      valid = searchHeader.validate();
      if (!(0, _lodash.isEmpty)(criteria)) {
        if (!criteria.validate()) {
          let errors = criteria.get('errors');
          if (Ember.isPresent(errors.criteriaSelected)) {
            self.get('_criteriaError').show(errors.criteriaSelected);
          }
        }
        valid = criteria.validate() && valid;
      }
      if ((!self.get('isReadOnly') || self.get('_canSearchAgain')) && valid) {
        const constants = self.get('constants');
        try {
          // if grantor search, then should get the collateral filter options
          if (searchHeader.get('searchType') === constants.searchType.searchByGrantor || searchHeader.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange) {
            if ((0, _lodash.isEmpty)(this.get('model.filterRegEventFromDate')) && (0, _lodash.isEmpty)(this.get('model.filterCollateralClasses'))) {
              let filter = self.get('model.searchFilterCriteria');
              self.set('model.filterCollateralClasses', filter.getCollateralClassTypeSummary());
            }
            if ((0, _lodash.isEmpty)(self.get('criteria.guid'))) {
              if (self.get('criteria.isOrganisationSearch')) {
                this._createOrganisationSearch(searchHeader, self.get('criteria'));
              } else {
                this._createIndividualSearch(searchHeader, self.get('criteria'));
              }
            }
          }
          //check if Motor Vehicle search
          if (searchHeader.get('searchType') === constants.searchType.searchBySerialNumber && searchHeader.get('isMultiSearch')) {
            this._createMvSearch(searchHeader, self.get('criteria'));
          }
          self.get('_saveAndSubmitSearchTask').perform();
        } catch (error) {
          this.get('logger').logError('au-search-action-control._search', error);
          this._hideWaitModalDlg();
        }
      } else {
        this._hideWaitModalDlg();
      }
    },
    //endregion

    //region TASKS
    //region Validate, Save, Submit, Delete
    _saveAndSubmitSearchTask: (0, _emberConcurrency.task)(function* () {
      try {
        // const constants = this.get('constants');
        this._showWaitModalDlg();
        this.notifyInfo('Submitting search to PPSR ... this may take up to a minute');
        let searchResultSummary = yield this.get('searchRepo.saveAndSubmitSearch').linked().perform(this.get('model'));
        this.set('model.guid', searchResultSummary.searchHeaderGuid);
        this.set('model.esisId', searchResultSummary.searchHeaderEsisId);
        this.get('model').saveModel();
        if (!(0, _lodash.isEmpty)(this.get('criteria'))) {
          this.get('criteria').save();
        }
        this.onUpdateTab(searchResultSummary);
        if (!this.get('isReadOnly')) {
          this.set('isReadOnly', true);
        }
        if (searchResultSummary.isCitec) {
          // CITEC Client ONLY logic
          const zeroFee = yield this.get('_getCitecFeesTask').linked().perform(searchResultSummary.searchHeaderGuid);
          if (zeroFee === true) {
            this._showWaitModalDlg();
            const fundsCheckOk = yield this.get('_createAccountingTransactionForCitecClientTask').linked().perform(searchResultSummary.searchHeaderGuid);
            if (fundsCheckOk === true) {
              yield this.get('_submitSearchTask').linked().perform(searchResultSummary.searchHeaderGuid);
            }
          }
        } else {
          yield this.get('_loadSearchTask').linked().perform(searchResultSummary.searchHeaderGuid);
        }
      } catch (error) {
        this.get('logger').logError('au-search-action-control._saveAndSubmitSearchTask', error);
      }
    }),
    _createAccountingTransactionForCitecClientTask: (0, _emberConcurrency.task)(function* (searchHeaderGuid) {
      try {
        const constants = this.get('constants');
        const accountingResponse = yield this.get('searchRepo.createSearchAccountingRecord').linked().perform(searchHeaderGuid);
        this._hideWaitModalDlg();
        if (accountingResponse.status === constants.createAccountingRecordResultStatus.allGood) {
          // screen.set('discardSearch', false);
          return true;
        }
        if (accountingResponse.status === constants.createAccountingRecordResultStatus.shortfall || accountingResponse.status === constants.createAccountingRecordResultStatus.partialShortfall || accountingResponse.status === constants.createAccountingRecordResultStatus.exceedLimit || accountingResponse.status === constants.createAccountingRecordResultStatus.particalExceedLimit) {
          const topupModal = this.get('_citecTopUpModal');
          if (!(0, _lodash.isNil)(topupModal)) {
            let message = '';
            if (accountingResponse.status === constants.createAccountingRecordResultStatus.shortfall || accountingResponse.status === constants.createAccountingRecordResultStatus.partialShortfall) {
              topupModal.setShowConfirmBtn(true);
              topupModal.setTitle('Account Top up');
              message = Ember.String.htmlSafe(`To process this transaction your account requires <strong>${this.formatMonetaryValue(accountingResponse.shortfall)}</strong> in additional funds.<br>To increase your account balance click Top Up and then try again.`);
            } else {
              topupModal.setShowConfirmBtn(false);
              topupModal.setTitle('Exceeded Limit');
              message = Ember.String.htmlSafe(`Sorry you do not have sufficient funds in your account.To increase your account balance please contact <strong>Customer Service</strong> on <strong>1800 733 733</strong> or <strong>3222 2700</strong>.`);
            }
            yield this.get('_loadSearchTask').perform(searchHeaderGuid);
            topupModal.show(message, accountingResponse.topupUrl);
          } else {
            this.notifyError(`The transaction could not be completed due to ${accountingResponse.errorMessage}`);
          }
        } else {
          this.notifyError(`The transaction could not be completed due to ${accountingResponse.errorMessage}`);
        }
        return false;
      } catch (error) {
        this._hideWaitModalDlg();
        this.get('logger').logError('au-search-action-control._createAccountingTransactionForCitecClientTask', error);
        return false;
      }
    }),
    _getCitecFeesTask: (0, _emberConcurrency.task)(function* (searchHeaderGuid) {
      try {
        const feesResult = yield this.get('searchRepo.loadSearchFees').linked().perform(searchHeaderGuid);
        if (feesResult.totalFees > 0 && !(0, _lodash.isNil)(this.get('_citecFeeModal'))) {
          this._hideWaitModalDlg();
          const feeModal = this.get('_citecFeeModal');
          if (feesResult.displayFeeAllowed === true) {
            feeModal.setTitle('Fee Confirmation');
            const msg = feesResult.totalRitc > 0 ? `Your account will be charged <strong>${this.formatMonetaryValue(feesResult.totalFees)}</strong>. Ritc - <strong>${this.formatMonetaryValue(feesResult.totalRitc)}</strong>. <br>Click Yes to proceed.` : `Your account will be charged <strong>${this.formatMonetaryValue(feesResult.totalFees)}</strong>. <br>Click Yes to proceed.`;
            feeModal.show(msg, searchHeaderGuid);
          } else {
            feeModal.setTitle('Charge Point');
            feeModal.show('You are going past a charge point, do you want to continue?', searchHeaderGuid);
          }
          // this.set('discardSearch', true);
          return false;
        }
        return true;
      } catch (error) {
        this._hideWaitModalDlg();
        this.get('logger').logError('au-search-action-control._getCitecFeesTask', error);
        return false;
      }
    }),
    _submitSearchTask: (0, _emberConcurrency.task)(function* (searchHeaderGuid) {
      try {
        yield this.get('searchRepo.submitSearch').linked().perform(searchHeaderGuid);
        yield this.get('_loadSearchTask').perform(searchHeaderGuid);
        this._hideWaitModalDlg();
      } catch (error) {
        this.get('logger').logError('au-search-action-control._submitSearchTask', error);
        this._hideWaitModalDlg();
      }
    }),
    _loadSearchTask: (0, _emberConcurrency.task)(function* (searchHeaderGuid) {
      try {
        let response = yield this.get('searchRepo.loadSearch').linked().perform(searchHeaderGuid);
        let searchHeader = this.mapJsonObjectToModel(response, 'search-header');
        searchHeader.set('entityGuid', this.get('credential.currentBusinessEntity.value'));
        let searches = [];
        (0, _lodash.forEach)(searchHeader.get('searches'), item => {
          let search = this.mapJsonObjectToModel(item, 'search');
          search.setProperties({
            searchType: searchHeader.get('searchType'),
            criteriaCollateralClassSearchCriteria: searchHeader.get('collateralClassType')
          });
          searches.pushObject(search);
        });
        searchHeader.set('searches', searches);
        if (searchHeader.get('autoRetrieveCertificates')) {
          searchHeader.set('searchCertificateRetrievalProgress', 1);
        }
        this.set('model', searchHeader);
        this.get('model').saveModel();
        this.onLoadSearch(this.get('model'));
      } catch (error) {
        this.get('logger').logError('au-search-action-control._loadSearchTask', error);
      }
    }),
    //endregion

    //region Download, Print, Certificate
    _downloadSearchCertificateBulkTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.downloadSearchCertificateBulk').linked().perform(this.get('model.guid'));
        this.notifyInfo(`Search certificates bundle for ${this.get('model.esisId')} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-search-action-control._downloadSearchCertificateBulkTask', error);
        this.notifyError(`Search certificate bundle for ${this.get('model.esisId')} failed to download, please try again`);
      }
    }),
    _requestSearchCertificateBulkTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.requestSearchCertificateBulk').linked().perform(this.get('model.guid'));
        this.set('model.searchCertificateRetrievalProgress', 2);
        this.get('model').save();
        this.get('event').trigger(this.get('constants.events.requestSearchCertificate'));
        this.notifyInfo(`Search certificates bundle for ${this.get('model.esisId')} has been requested`);
      } catch (error) {
        this.get('logger').logError('au-search-action-control._requestSearchCertificateBulkTask', error);
        this.notifyError('request bulk search certificate failed');
      }
    }),
    _previewSearchSummaryReportTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.downloadSearchSummaryReport').linked().perform(this.get('model.guid'));
      } catch (error) {
        this.get('logger').logError('au-search-action-control._previewSearchSummaryReportTask', error);
      }
    }),
    _downloadSearchExtractReport: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.downloadSearchExtractReport').linked().perform(this.get('model.guid'));
        this.notifyInfo(`Search extract report for ${this.get('model.esisId')} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-search-action-control._downloadSearchExtractReport', error);
        this.notifyError(`Search extract report for ${this.get('model.esisId')} failed to download, please try again`);
      }
    }),
    //endregion
    //endregion

    //region Action
    actions: {
      //region CITEC accounting dialog confirmation
      _onCitecTopup: function _onCitecTopup(topupUrl) {
        this._hideWaitModalDlg();
        window.open(topupUrl, '_blank');
        this.get('_citecTopUpModal').hide();
      },
      _onConfirmCitecFee: function _onConfirmCitecFee(searchHeaderGuid) {
        try {
          this._showWaitModalDlg();
          this.get('_createAccountingTransactionForCitecClientTask').perform(searchHeaderGuid).then(accountingResponse => {
            if (accountingResponse === true) {
              this.get('_submitSearchTask').perform(searchHeaderGuid).then(() => {
                this._hideWaitModalDlg();
              });
            } else {
              this.get('_loadSearchTask').perform(searchHeaderGuid);
            }
          });
        } catch (e) {
          this._hideWaitModalDlg();
          this.get('logger').logError('au-search-action-control._onConfirmCitecFee', e);
        }
      },
      _onCancelCitec: function _onCancelCitec() {
        this._hideWaitModalDlg();
        this.get('model').saveModel();
        // delete search
        this.onDelete();
      },
      _onCancelCitecTopup: function _onCancelCitecTopup() {
        this._hideWaitModalDlg();
        this.get('model').saveModel();
        // delete search
        this.onDelete();
      },
      //endregion

      _onSearch( /*model*/
      ) {
        // this.set('model', model);
        this._search();
      },
      _downloadCertificateBulk() {
        this._downloadSearchCertificateBulkTask.perform();
      },
      _requestCertificateBulk() {
        this._requestSearchCertificateBulkTask.perform();
      },
      _previewSummaryReport() {
        this._previewSearchSummaryReportTask.perform();
      },
      _downloadExtractReport() {
        this._downloadSearchExtractReport.perform();
      },
      _onVerified(result) {
        this.get('criteria').setProperties({
          abn: result.abnNumber,
          acn: result.acnNumber,
          verifiedName: result.organisationName
        });
      },
      _onRefresh: function _onRefresh() {
        if (Ember.isPresent(this.get('onRefresh'))) {
          this.get('onRefresh')();
        }
      }
    }
  });
  _exports.default = _default;
});