define("esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-by-profile-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    show(args) {
      this.set('_collaterals', []);
      (0, _lodash.forEach)(args.collateralTypes, item => {
        let collateral = this.createModel('nz-collateral-create-request');
        collateral.setProperties({
          collateralType: item.value,
          collateralTypeTitle: item.title,
          collateralItems: [],
          collDescription: item.collDescription,
          collateralProfileCode: item.collateralProfileCode
        });
        this.get('_collaterals').pushObject(collateral);
      });
      this.set('_modalTitle', args.modalTitle);
      this.get('_dialog').show();
    },
    //region Closure Actions
    onAddCollateral() {},
    //endregion
    actions: {
      _onConfirm() {
        this.get('onAddCollateral')(this.get('_collaterals'));
      }
    }
  });
  _exports.default = _default;
});