define("esis-ui/components/shared/buttons/button-base", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: 'span',
    title: '',
    disabled: false,
    isRunning: false,
    small: true,
    smallWidth: false,
    icon: "",
    hasIcon: false,
    outline: true,
    type: 'secondary',
    iconOnly: false,
    refreshNotification: false,
    buttonType: 'button',
    customClass: null,
    labelLeft: false,
    toolTipLabel: '',
    onClick: function onClick() {},
    // closure action to be pass in by container / parent component

    classProp: Ember.computed('iconClass', function () {
      return !(0, _lodash.isEmpty)(this.get('iconClass')) ? `${this.get('iconClass')} smallIcon` : 'smallIcon';
    }),
    btnClass: Ember.computed('refreshNotification', 'iconOnly', 'small', 'customClass', 'smallWidth', function () {
      let className = '';
      if (this.get('refreshNotification')) {
        className += 'refreshBtnStyle ';
      }
      if (this.get('iconOnly')) {
        className += 'btn-sm btnIconOnly ';
      } else if (this.get('small')) {
        className += 'btn-sm ';
      }
      if (this.get('smallWidth')) {
        className += 'smallWidthButton ';
      }
      if (!(0, _lodash.isEmpty)(this.get('customClass'))) {
        className += `${this.get('customClass')} `;
      }
      return className;
    }),
    actions: {
      _onClick() {
        this.get('onClick')();
      }
    }
  });
  _exports.default = _default;
});