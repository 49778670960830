define("esis-ui/initializers/common-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    /* Application Constants */
    application.inject('route', 'constants', 'service:app-constants');
    application.inject('component', 'constants', 'service:app-constants');
    application.inject('controller', 'constants', 'service:app-constants');
    /* Application Service */
    application.inject('route', 'app', 'service:app-service');
    application.inject('component', 'app', 'service:app-service');
    /* Credential service (current user profile and permissions) */
    application.inject('route', 'credential', 'service:credential-service');
    application.inject('component', 'credential', 'service:credential-service');
    /* Logger */
    application.inject('route', 'logger', 'service:logging-service');
    application.inject('component', 'logger', 'service:logging-service');
    application.inject('controller', 'logger', 'service:logging-service');
    /* Notification */
    // application.inject('component', 'notification', 'service:notification-service');
    /* Event */
    application.inject('route', 'event', 'service:event-service');
    application.inject('component', 'event', 'service:event-service');
    application.inject('controller', 'event', 'service:event-service');
    /* Cache */
    application.inject('route', 'cache', 'service:cache-service');
    application.inject('component', 'cache', 'service:cache-service');
    application.inject('controller', 'cache', 'service:cache-service');
    /* Cache */
    /* Theme */
    application.inject('route', 'theme', 'service:theme-service');
    application.inject('component', 'theme', 'service:theme-service');
    application.inject('controller', 'theme', 'service:theme-service');
    application.inject('initializer', 'event', 'service:event-service');
  }
  var _default = {
    name: 'common-service',
    before: 'models-injector',
    initialize
  };
  _exports.default = _default;
});