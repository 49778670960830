define("esis-ui/components/areas/search/au/view/au-search-serial-number/component", ["exports", "esis-ui/components/areas/search/au/view/au-search-view-base", "lodash", "ember-concurrency"], function (_exports, _auSearchViewBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSearchViewBase.default.extend({
    //region INITIALISATION
    _selectedSerialNumberType: null,
    init() {
      this._super(...arguments);
      this._loadModel.perform();
      this._serialNumberTypeOptions = this.get('constants.serialNumberTypeOptions');
    },
    //endregion

    //region METHODS

    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          const response = yield this.get('searchRepo.loadSearch').linked().perform(this.get('tabModel.p0'));
          const searchHeader = this._createSearchHeader('', response);
          this.set('model', searchHeader);
          this._mapSearches(this.get('model'));
          this.updateTabProperty('title', `Serial Num Search ${searchHeader.esisId}`);
          this.set('_isReadOnly', true);
          if (!(0, _lodash.isNil)(this.get('model.firstSearch.criteriaSerialNumberType'))) {
            this.set('_selectedSerialNumberType', (0, _lodash.find)(this.get('_serialNumberTypeOptions'), {
              id: this.get('model.firstSearch.criteriaSerialNumberType')
            }));
          }
        } else {
          const newRecord = this._createSearchHeader(this.get('constants.searchType.searchBySerialNumber'));
          this._createSearch(newRecord);
          this.set('model', newRecord);
        }
        this.get('model').saveModel();
      } catch (error) {
        this.get('logger').logError('au-search-serial-number._loadModel', error);
        this.notifyError('Fail to load search');
      }
    }),
    //endregion

    actions: {
      _serialNumberTypeChanged(option) {
        this.set('_selectedSerialNumberType', option);
        this.set('model.firstSearch.criteriaSerialNumberType', option.id);
      },
      _onUpdateTab(searchResultSummary) {
        this.updateTabProperty('title', `Serial Num Search ${searchResultSummary.searchHeaderEsisId}`);
        this.updateTabProperty('p0', searchResultSummary.searchHeaderGuid);
      }
    }
  });
  _exports.default = _default;
});