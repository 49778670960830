define("esis-ui/components/areas/registration/au/wizard/au-registration-collateral-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
    },
    //region COMPUTED PROPERTIES
    _collateralsLastIndex: Ember.computed('model.collaterals.@each', function () {
      return this.get('model.collaterals').length - 1;
    }),
    //endregion

    onActivate() {
      if ((0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        this.get('_collateralTypeModal').show();
      }
    },
    validateStep() {
      let model = this.get('model');
      model.set('errors', []);
      let errors = this.get('model.errors');
      if ((0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        errors.set('collateral', 'At least one collateral should be added');
        return false;
      } else {
        errors.set('collateral', '');
        return true;
      }
    },
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let collClassTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.collateralClassTypePicklist'));
        this.set('_collClassTypeList', collClassTypePicklist);
      } catch (error) {
        this.get('logger').logError('au-registration-collateral._loadPicklist', error);
      }
    }),
    _loadContractTypeDefaults: (0, _emberConcurrency.task)(function* (contractType) {
      try {
        const constants = this.get('constants');
        let result = yield this.get('registrationRepo.loadContractTypeDefaults').perform(contractType.item.guid);
        let contractTypes = [];
        (0, _lodash.forEach)(result, item => {
          let copy = this.mapJsonObjectToModel(item, 'collateral');
          if (copy.get('areProceedsClaimed') === true && Ember.isBlank(copy.get('proceedsClaimedDescription'))) {
            copy.set('proceedsClaimedDescription', constants.defaultAreProceedClaimedDescription);
          }
          contractTypes.pushObject(copy);
        });
        let collaterals = [];
        (0, _lodash.forEach)(contractTypes, collateral => {
          if (Ember.isBlank(collateral.serialNumberType) && collateral.isSerialised) {
            if (collateral.isMotorVehicle) {
              collateral.set('serialNumberType', constants.serialNumberType.VIN);
              collateral.set('regOrDescValue', true);
            } else if (collateral.isWatercraft) {
              collateral.set('serialNumberType', constants.serialNumberType.HIN);
            } else if (collateral.collateralClassType === constants.searchCollateralClassType.aircraftEngine) {
              collateral.set('serialNumberType', constants.serialNumberType.aircraftEngineManufacturersNumber);
            } else if (collateral.collateralClassType === constants.searchCollateralClassType.airframe) {
              collateral.set('serialNumberType', constants.serialNumberType.airframeManufacturersNumber);
            } else if (collateral.collateralClassType === constants.searchCollateralClassType.helicopter) {
              collateral.set('serialNumberType', constants.serialNumberType.helicopterManufacturersNumber);
            } else if (collateral.collateralClassType === constants.searchCollateralClassType.smallAircraft) {
              collateral.set('serialNumberType', constants.serialNumberType.aircraftNationalityCodeAndRegistrationMark);
            }
          }
          collateral.set('isConfirmed', false);
          collaterals.pushObject(collateral);
        });
        this.get('_editCollModal').show(collaterals, contractType);
      } catch (error) {
        this.get('logger').logError('registration-wizard._loadContractTypeDefaults', error);
      }
    }),
    actions: {
      _onSelectAddType(args) {
        const constants = this.get('constants');
        if (!(0, _lodash.isEmpty)(args.contractType)) {
          this._loadContractTypeDefaults.perform(args.contractType);
        } else {
          let collateral = this.createModel('collateral');
          if (args.collateralClassType === constants.searchCollateralClassType.motorVehicle && !args.isSerialised) {
            collateral.set('contractDefaultDurationYears', 25);
          } else {
            collateral.set('contractDefaultDurationYears', 7);
          }
          const colClass = (0, _lodash.find)(this.get('_collClassTypeList'), {
            'value': args.collateralClassType
          });
          let serialNumberType = collateral.serialNumberType;
          let collateralClassTypeTitle = colClass.title;
          if (args.isSerialised && (Ember.isBlank(collateral.serialNumberType) || collateral.collateralClassType !== args.collateralClassType)) {
            if (args.collateralClassType === constants.searchCollateralClassType.motorVehicle) {
              serialNumberType = constants.serialNumberType.VIN;
            } else if (args.collateralClassType === constants.searchCollateralClassType.watercraft) {
              serialNumberType = constants.serialNumberType.HIN;
            } else if (args.collateralClassType === constants.searchCollateralClassType.aircraftEngine) {
              serialNumberType = constants.serialNumberType.aircraftEngineManufacturersNumber;
            } else if (args.collateralClassType === constants.searchCollateralClassType.airframe) {
              serialNumberType = constants.serialNumberType.airframeManufacturersNumber;
            } else if (args.collateralClassType === constants.searchCollateralClassType.helicopter) {
              serialNumberType = constants.serialNumberType.helicopterManufacturersNumber;
            } else if (args.collateralClassType === constants.searchCollateralClassType.smallAircraft) {
              serialNumberType = constants.serialNumberType.aircraftNationalityCodeAndRegistrationMark;
            } else if (args.collateralClassType === constants.searchCollateralClassType.patent) {
              serialNumberType = constants.serialNumberType.patentNumber;
            } else if (args.collateralClassType === constants.searchCollateralClassType.design) {
              serialNumberType = constants.serialNumberType.designNumber;
            } else if (args.collateralClassType === constants.searchCollateralClassType.tradeMark) {
              serialNumberType = constants.serialNumberType.tradeMarkNumber;
            } else if (args.collateralClassType === constants.searchCollateralClassType.plantBreedersRight) {
              serialNumberType = constants.serialNumberType.plantBreedersRightNumber;
            }
          }
          collateral.setProperties({
            collateralType: args.collateralType,
            collateralClassType: colClass.value,
            collateralClassTypeTitle: collateralClassTypeTitle,
            isSerialised: args.isSerialised,
            serialNumberType: serialNumberType,
            isConfirmed: false
          });
          let collArray = [];
          collArray.pushObject(collateral);
          this.get('_editCollModal').show(collArray, args.contractType);
        }
      },
      _onConfirmAddingCollateral(collateral) {
        if (Ember.isNone(this.get('model.collaterals'))) {
          this.set('model.collaterals', []);
        }
        collateral.set('id', 0);
        this.get('model.collaterals').unshiftObject(collateral);
      },
      _onUpdateWorkingCollateral(collateral) {
        if (!(0, _lodash.isEmpty)(this.get('model.collaterals'))) {
          let updatedColl = this.get('model.collaterals')[collateral.indexId];
          this.mapObject(collateral, updatedColl);
        }
      },
      _onEditCollateral(collateral, index) {
        collateral.set('isConfirmed', true);
        collateral.set('indexId', index);
        let collArray = [];
        collArray.pushObject(collateral);
        this.get('_editCollModal').show(collArray);
      },
      _onDeleteCollateral(collateral) {
        this.get('model.collaterals').removeObject(collateral);
        this.set('model.itemDeleted', true);
      },
      _onClickAdd: function _onClickAdd() {
        this.get('_collateralTypeModal').show();
      }
    }
  });
  _exports.default = _default;
});