define("esis-ui/components/areas/admin/branch-code/nz/list/nz-branch-code-list/component", ["exports", "esis-ui/components/areas/shared/nz-page-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _nzPageBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region INITIALIZATION
    _branchCodeGrid: null,
    _branchCodeList: null,
    _selectedBranchCode: null,
    _selectedBusinessEntity: null,
    _selectedSpg: null,
    init() {
      this._super(...arguments);
      this._loadPicklistMethod();

      //region BRANCH CODE GRID
      this.set('_gridOption', {
        height: 600,
        dataSource: {
          pageSize: 30
        },
        columns: [{
          field: "code",
          title: 'Code',
          filterable: false,
          width: "100px",
          template: '<a href="\\#" data-id="#:guid#" class="grid-link-id">#: code #</a>'
        }, {
          field: "billingCode",
          title: 'Billing Code',
          filterable: false,
          width: "100px"
        }, {
          field: "description",
          title: 'Description',
          filterable: false,
          width: "200px"
        }, {
          field: 'entityTitle',
          title: 'Business Entity',
          filterable: false,
          width: "100px"
        }, {
          field: "securedPartyGroupId",
          title: 'SPG ID',
          filterable: false,
          width: "100px"
        }, {
          field: "securedPartyGroupName",
          title: 'SPG Name',
          filterable: false,
          width: "150px"
        }]
      });
      //endregion
    },

    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* (loadEntityList = true) {
      try {
        if (loadEntityList) {
          //BUSINESS ENTITY
          this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        }

        //SPG PICKLIST
        let spgPicklist = yield this.get('picklistRepo.loadNzSpgListFromEntityDown').linked().perform(this.get('_selectedBusinessEntity.value'));
        this.set('_spgList', [{
          value: 0,
          title: 'All SPGs',
          sortOrder: 0,
          item: {
            id: 0
          }
        }].concat(spgPicklist));
        this.set('_selectedSpg', this.get('_spgList')[0]);
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError('branch-code-list._loadPicklist', error);
      }
    }),
    _loadBranchCodeList: (0, _emberConcurrency.task)(function* () {
      try {
        let selectedSpgGuid = this.get('_selectedSpg.value') === 0 ? this.emptyGuid() : this.get('_selectedSpg.value');
        let response = yield this.get('adminRepo.loadBranchCodeDigest').linked().perform({
          securedPartyGroupGuid: selectedSpgGuid,
          esisEntityGuid: this.get('_selectedBusinessEntity.value')
        });
        this.set('_branchCodeList', response);
        (0, _emberLifeline.runTask)(this, () => {
          this.get('_branchCodeGrid').loadData(response);
        });
      } catch (error) {
        this.get('logger').logError('branch-code-list._loadBranchCodeList', error);
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_branchCodeGrid'))) {
          this._loadBranchCodeList.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_refreshGrid', 20);
        }
      });
    },
    onScreenChange() {
      this._refreshGrid(); // soft refresh
    },

    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedBranchCode')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedBranchCode', data);
      },
      _onLinkClick(selectedItem) {
        this.set('_modalTitle', "Edit Branch Code");
        this.get('_branchCodeDlg').show((0, _lodash.find)(this.get('_branchCodeList'), {
          "guid": selectedItem.data('id')
        }));
      },
      _onRefreshData() {
        this._refreshGrid();
      },
      //region FILTER ACTIONS
      _onChangeSpg: function _onChangeSpg(value) {
        this.set('_selectedSpg', value);
        this._refreshGrid();
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.get('_loadPicklists').perform(false);
      },
      //endregion

      //region BUTTON ACTIONS
      _onAdd() {
        this.set('_modalTitle', "New Branch Code");
        this.get('_branchCodeDlg').show({
          entityGuid: this.get('_selectedBusinessEntity.value')
        });
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});