define("esis-ui/components/areas/registration/nz/shared/nz-registration-expiry-options/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WIcScOXg",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-5 pr-0\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"allowClear\",\"triggerClass\",\"onChange\"],[[24,[\"_renewOptions\"]],[24,[\"_selectedRenewOption\"]],false,\"powerSelectDropdown\",[28,\"action\",[[23,0,[]],\"_onChangeRenewOption\"],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"col-7 \",[28,\"if\",[[24,[\"isWizard\"]],\"pr-3\",\"pr-0 pl-2\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"_selectedRenewOption\",\"value\"]],[24,[\"constants\",\"nzFsExpiryDateOption\",\"custom\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"shared/power-datepicker\",null,[[\"onDateChanged\",\"value\",\"maxYearOffset\",\"minYearOffset\"],[[28,\"action\",[[23,0,[]],\"_onRenewDateChanged\"],null],[24,[\"model\",\"expiryDate\"]],5,6]]],false],[0,\"\\n      \"],[7,\"small\",true],[10,\"class\",\"validationErrorMessage\"],[8],[1,[24,[\"model\",\"errors\",\"expiryDate\"]],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"pt-1\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"expiryDateFiveYearsDisplay\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"display-date\",[[24,[\"model\",\"expiryDateFiveYearsDisplay\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"display-date\",[[24,[\"model\",\"expiryDate\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/shared/nz-registration-expiry-options/template.hbs"
    }
  });
  _exports.default = _default;
});