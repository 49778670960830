define("esis-ui/components/areas/shared/buttons/refresh-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TJuK7mid",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shared/buttons/action-button\",null,[[\"icon\",\"small\",\"title\",\"disabled\",\"iconOnly\",\"type\",\"isRunning\",\"onClick\",\"refreshNotification\"],[\"sync\",[24,[\"small\"]],\"Refresh\",[24,[\"disabled\"]],[24,[\"iconOnly\"]],[24,[\"type\"]],[24,[\"isRunning\"]],[28,\"action\",[[23,0,[]],\"_onClick\"],null],[24,[\"refreshNotification\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/buttons/refresh-button/template.hbs"
    }
  });
  _exports.default = _default;
});