define("esis-ui/components/areas/registration/nz/wizard/nz-collateral-item-edit/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
    },
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      let picklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.nzAircraftClass'));
      this.set('_aircraftClasslist', (0, _lodash.sortBy)(picklist, 'title'));
      if (!(0, _lodash.isNil)(this.get('model.aircraftClass'))) {
        this.set('_selectedAircraftClass', (0, _lodash.find)(picklist, {
          value: this.get('model.aircraftClass')
        }));
      }
    }),
    actions: {
      _onAircraftClassChange(selected) {
        this.set('_selectedAircraftClass', selected);
        this.set('model.aircraftClass', selected.value);
        this.set('model.aircraftClassTitle', selected.title);
      },
      _validateVin() {
        if (!(0, _lodash.isEmpty)(this.get('model.vin'))) {
          let valid = this.validateVin(this.get('model.vin'));
          this.set('_vinWarningMessage', valid ? '' : 'Likely to be invalid');
        } else {
          this.set('_vinWarningMessage', '');
        }
      }
    }
  });
  _exports.default = _default;
});