define("esis-ui/components/areas/spg/au/view/au-copy-spg-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _spgDetail: null,
    _newSecuredPartyGroupName: null,
    _errorMessage: null,
    _error: null,
    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        _spgDetail: null,
        _newSecuredPartyGroupName: null,
        _errorMessage: null,
        _error: null
      });
    },
    show(spg) {
      this._reset();
      this.set('_spgDetail', spg);
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _copySpg: (0, _emberConcurrency.task)(function* () {
      try {
        let res = yield this.get('spgRepo.copySpg').perform({
          guid: this.get('_spgDetail.guid'),
          newSecuredPartyGroupName: this.get('_newSecuredPartyGroupName')
        });
        this.notifySuccess(`New SPG was successfully copied from source SPG ESIS ID: ${res.esisId}.`);
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')();
        }
        this.get('_dialog').hide();
      } catch (error) {
        this.get('logger').logError('au-copy-spg-modal._copySpg', error);
        this.set('_errorMessage', error.errorMessage);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onConfirm: function _onConfirm() {
        if ((0, _lodash.isEmpty)(this.get('_newSecuredPartyGroupName'))) {
          this.set('_error', "can't be blank");
        } else {
          this.get('_copySpg').perform();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});