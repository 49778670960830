define("esis-ui/repositories/system-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    loadHelpTopic: (0, _emberConcurrency.task)(function* (key) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadHelpTopic'), {
        key: key
      });
    }),
    saveHelpTopic: (0, _emberConcurrency.task)(function* (data) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveHelpTopic'), data.clone(), false);
    }).drop(),
    loadBusinessEntityPicklist: (0, _emberConcurrency.task)(function* (region, filterRegion = true, filterHolderEntity = true, suppressError = false) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadBusinessEntityPicklist'), {
        region: region,
        filterRegion: filterRegion,
        filterHolderEntity: filterHolderEntity
      }, suppressError);
    })
  });
  _exports.default = _default;
});