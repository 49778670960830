define("esis-ui/components/areas/registration/au/express-wizard/au-express-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    modalTitle: 'Serial Number Details',
    //region METHOD
    _createModel(model) {
      this.set('model', []);
      (0, _lodash.forEach)(model, item => {
        let collModel = this.mapObject(item, this.createModel('collateral'));
        this.get('model').pushObject(collModel);
      });
      this._loadPicklists.perform();
    },
    _reset() {
      this.setProperties({
        model: null
      });
    },
    show(model) {
      this._reset();
      this._createModel(model);
      this.get('_dialog').show();
    },
    _setSerialNumberTypeTitle() {
      if (!(0, _lodash.isEmpty)(this.get('_serialNumberTypeList'))) {
        (0, _lodash.forEach)(this.get('model'), item => {
          if (item.showSerialNumber) {
            let type = (0, _lodash.find)(this.get('_serialNumberTypeList'), {
              value: item.serialNumberType
            });
            if (!(0, _lodash.isEmpty)(type)) {
              // item.set('serialNumberTypeTitle', type.title);
              Ember.set(item, 'serialNumberTypeTitle', type.title);
            }
          }
        });
      }
    },
    //endregion

    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        let serialNumberTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.serialNumberTypePicklist'));
        this.set('_serialNumberTypeList', serialNumberTypePicklist);
        this._setSerialNumberTypeTitle();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    actions: {
      _onConfirm: function _onConfirm() {
        const constants = this.get('constants');
        let validated = true;
        (0, _lodash.forEach)(this.get('model'), item => {
          if (item.showSerialNumber) {
            if ((0, _lodash.isNil)(item.serialNumber)) {
              Ember.set(item, 'serialNumberError', 'Serial number cannot be blank');
              validated = false;
            } else if (item.serialNumberType === constants.serialNumberType.VIN) {
              const vinValid = this.validateVin(item.serialNumber);
              if (!vinValid) {
                Ember.set(item, 'serialNumberError', 'VIN must be 17 characters and not cannot contain I, O, Q');
                validated = false;
              }
            } else if (item.serialNumberType === constants.serialNumberType.HIN) {
              const hinNumber = item.serialNumber.replace('-', '');
              const hinValid = this.validateHin(hinNumber);
              if (!hinValid) {
                Ember.set(item, 'serialNumberError', 'HIN must be 12 or 14 characters and first 3 or 5 characters, 9 or 11 positions alpha');
                validated = false;
              } else {
                Ember.set(item, 'serialNumber', hinNumber);
              }
            } else if (item.serialNumberType === constants.serialNumberType.officialNumber) {
              const officeNumber = this.paddingToLength(item.serialNumber, 6);
              const officialValid = this.validateOfficialNumber(officeNumber);
              if (!officialValid) {
                Ember.set(item, 'serialNumberError', 'Official Number must be 6 numeric');
                validated = false;
              } else {
                Ember.set(item, 'serialNumber', officeNumber);
              }
            }
            if (validated) {
              Ember.set(item, 'serialNumberError', '');
            }
          }
        });
        if (validated && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('model'));
        }
        return validated;
      },
      _onSerialNumberTypeChange(collateral, value) {
        Ember.set(collateral, 'serialNumberType', value);
        this._setSerialNumberTypeTitle();
      },
      _onRegOrDescValueChange(collateral, value) {
        Ember.set(collateral, 'regOrDescValue', value);
      }
    }
  });
  _exports.default = _default;
});