define("esis-ui/components/areas/shared/buttons/help-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o4GoAGyv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"click\"]],[8],[1,[28,\"fa-icon\",[\"question-circle\"],[[\"prefix\",\"size\",\"class\",\"fixedWidth\",\"title\"],[\"far\",\"2x\",\"navHelpIcon havePointer\",true,[24,[\"title\"]]]]],false],[9],[0,\"\\n\\n\"],[1,[28,\"areas/shared/help-modal\",null,[[\"registerAs\"],[[24,[\"_helpDlg\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/buttons/help-icon/template.hbs"
    }
  });
  _exports.default = _default;
});