define("esis-ui/services/credential-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    constants: Ember.inject.service('app-constants'),
    // current user
    ssoId: 'esis-sso',
    currentUser: null,
    currentRegion: 1,
    // Australia

    //region CURRENT Business Entity
    // current business entity
    currentBusinessEntity: null,
    businessEntityPicklist: null,
    get currentBusinessEntityGuid() {
      return this.get('currentBusinessEntity.value');
    },
    //endregion

    //region PERMISSION Arrays
    isSuperAdmin: Ember.computed('currentUser', function () {
      return this.get('currentUser.isSuperAdmin');
    }),
    _hasPermission: function _hasPermission(permissionToCheck) {
      if (Ember.isPresent(this.get('currentUser.permissions')) && Ember.isArray(this.get('currentUser.permissions'))) {
        return this.get('currentUser.permissions').includes(permissionToCheck);
      }
      return false;
    },
    //General Permissions
    hasAdminPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.admin'));
    }),
    hasManageEntityPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageEntity'));
    }),
    hasCreateContractTypePermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageContractTypes'));
    }),
    hasViewContractTypePermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageContractTypes')) || this._hasPermission(this.get('constants.permissions.viewContractTypes'));
    }),
    hasViewEncryptedDataPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewEncryptedData'));
    }),
    hasAbrLookupPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.abrLookup'));
    }),
    hasViewEntityPricingPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewEntityPricing'));
    }),
    hasManageEntityPricingPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageEntityPricing'));
    }),
    hasDataCleansingPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.dataCleansing'));
    }),
    hasApplyBulkChargePermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.applyBulkCharge'));
    }),
    hasCopyContractPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.copyContract'));
    }),
    hasManageUsersPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageUsers'));
    }),
    hasManageUserCredAccessKeyExpiryPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageUserCredAccessKeyExpiry'));
    }),
    hasViewEntityPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewEntity'));
    }),
    hasApproveDischargePermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.approveDischarge'));
    }),
    hasViewAccountingPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewAccounting'));
    }),
    hasManageEntitySettingPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageEntitySetting'));
    }),
    hasViewSystemReportPermission: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewSystemReport'));
    }),
    // AU Permissions
    hasViewBulkloadPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkloadAu')) || this._hasPermission(this.get('constants.permissions.viewBulkloadAu'));
    }),
    hasBulkloadPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkloadAu'));
    }),
    hasCreateSearchPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.searchAu'));
    }),
    hasViewSearchPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.searchAu')) || this._hasPermission(this.get('constants.permissions.viewMySearchesAu'));
    }),
    hasViewAllSearchPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewAllSearchesAu'));
    }),
    hasCreateRegistrationPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.registerAu'));
    }),
    hasViewRegistrationPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.registerAu')) || this._hasPermission(this.get('constants.permissions.viewRegistrationsAu'));
    }),
    hasDischargeRegistrationPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.dischargeAu'));
    }),
    hasCreateSpgPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageSPGAu'));
    }),
    hasViewSpgPermissionAu: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewSPGAu')) || this._hasPermission(this.get('constants.permissions.manageSPGAu'));
    }),
    //NZ Permissions
    hasViewBulkloadPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkloadNz')) || this._hasPermission(this.get('constants.permissions.viewBulkloadNz'));
    }),
    hasBulkAmendNzPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkAmendNz'));
    }),
    hasBulkDischargeNzPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkDischargeNz'));
    }),
    hasBulkRegisterNzPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkRegisterNz'));
    }),
    hasBulkRenewNzPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkRenewNz'));
    }),
    hasBulkloadPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.bulkloadNz'));
    }),
    hasCreateSearchPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.searchNz'));
    }),
    hasViewSearchPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.searchNz')) || this._hasPermission(this.get('constants.permissions.viewSearchNz'));
    }),
    // hasViewAllSearchPermissionAu: computed('currentUser', function () {
    //     return this._hasPermission(this.get('constants.permissions.viewAllSearchesAu'));
    // }),//???????
    hasCreateRegistrationPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.registerNz'));
    }),
    hasViewRegistrationPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.registerNz')) || this._hasPermission(this.get('constants.permissions.viewRegistrationsNz'));
    }),
    hasAmendRegistrationPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.amendNz'));
    }),
    hasRenewRegistrationPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.renewNz'));
    }),
    hasDischargeRegistrationPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.dischargeNz'));
    }),
    hasCreateSpgPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageSPGNz'));
    }),
    hasViewSpgPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewSPGNz')) || this._hasPermission(this.get('constants.permissions.manageSPGNz'));
    }),
    hasViewAndUseBranchCodePermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.viewAndUseBranchCodeNz')) || this._hasPermission(this.get('constants.permissions.manageBranchCodeNz'));
    }),
    hasManageBranchCodePermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageBranchCodeNz'));
    }),
    hasManageLinkedAccountsPermissionNz: Ember.computed('currentUser', function () {
      return this._hasPermission(this.get('constants.permissions.manageLinkedAccountsNz'));
    })
    //endregion
  });
  _exports.default = _default;
});