define("esis-ui/models/grantor-search-result-filter-criteria", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    migratedTransitional: (0, _modelBase.attr)(),
    nonMigratedTransitional: (0, _modelBase.attr)(),
    nonTransitional: (0, _modelBase.attr)(),
    securityInterestOnly: (0, _modelBase.attr)(),
    lastChangeYear: (0, _modelBase.attr)(),
    startYear: (0, _modelBase.attr)(),
    isPmsi: (0, _modelBase.attr)(),
    searchResultFilterCriteria: (0, _modelBase.attr)(),
    collateralTypes: (0, _modelBase.attr)(),
    collateralClassTypes: (0, _modelBase.attr)(),
    forceRefresh: (0, _modelBase.attr)(),
    isInventory: (0, _modelBase.attr)(),
    init: function init() {
      this._super();
      this.set('migratedTransitional', false);
      this.set('nonMigratedTransitional', false);
      this.set('nonTransitional', false);
      this.set('securityInterestOnly', false);
      this.set('lastChangeYear', 0);
      this.set('startYear', 0);
      this.set('isPmsi', false);
      this.set('searchResultFilterCriteria', null);
      this.set('collateralTypes', []);
      this.set('collateralClassTypes', []);
      this.set('forceRefresh', false);
      this.set('isInventory', false);
    },
    searchResultFilterSummary: function searchResultFilterSummary() {
      // todo complete implementaiton
      let resultText = [];
      if (this.get('migratedTransitional')) {
        resultText.addObject('Migrated Transitional');
      }
      if (this.get('nonMigratedTransitional')) {
        resultText.addObject('Non Migrated Transitional');
      }
      if (this.get('nonTransitional')) {
        resultText.addObject('Non Transitional');
      }
      if (this.get('securityInterestOnly')) {
        resultText.addObject('Security Interest Only');
      }
      if (!Ember.isBlank(this.get('lastChangeYear')) && this.get('lastChangeYear') > 0) {
        resultText.addObject('Last change year : ' + this.get('lastChangeYear'));
      }
      if (!Ember.isBlank(this.get('startYear')) && this.get('startYear') > 0) {
        resultText.addObject('Start year : ' + this.get('startYear'));
      }
      if (this.get('isPmsi')) {
        resultText.addObject('Is PMSI Only');
      }
      if (Ember.isPresent(this.get('searchResultFilterCriteria.collateralTypes'))) {
        this.get('searchResultFilterCriteria.collateralTypes').forEach(function (item) {
          if (item.checked) {
            resultText.addObject(`${item.label}`);
          }
        });
      }
      if (Ember.isPresent(this.get('searchResultFilterCriteria.collateralClassTypes'))) {
        this.get('searchResultFilterCriteria.collateralClassTypes').forEach(function (item) {
          if (item.checked) {
            resultText.addObject(`${item.label}`);
          }
        });
      }
      if (this.get('isInventory')) {
        resultText.addObject('Is Inventory Only');
      }
      if (Ember.isPresent(resultText)) {
        return 'Result filtered by : ' + resultText.toArray().join(', ');
      }
      return '';
    },
    reset: function reset() {
      this.set('migratedTransitional', false);
      this.set('nonMigratedTransitional', false);
      this.set('nonTransitional', false);
      this.set('securityInterestOnly', false);
      this.set('lastChangeYear', 0);
      this.set('startYear', 0);
      this.set('isPmsi', false);
      this.get('searchResultFilterCriteria.collateralTypes').forEach(function (item) {
        Ember.set(item, 'checked', false);
      });
      this.get('searchResultFilterCriteria.collateralClassTypes').forEach(function (item) {
        Ember.set(item, 'checked', false);
      });
      this.set('isInventory', false);
    },
    collectCollateralFilter: function collectCollateralFilter() {
      let self = this;
      if (Ember.isBlank(this.get('startYear'))) {
        self.set('startYear', 0);
      }
      if (Ember.isBlank(this.get('lastChangeYear'))) {
        self.set('lastChangeYear', 0);
      }
      if (Ember.isPresent(self.get('searchResultFilterCriteria'))) {
        self.get('searchResultFilterCriteria.collateralTypes').forEach(function (item) {
          if (item.checked) {
            self.get('collateralTypes').addObject(item.value);
          } else {
            self.get('collateralTypes').removeObject(item.value);
          }
        });
        self.get('searchResultFilterCriteria.collateralClassTypes').forEach(function (item) {
          if (item.checked) {
            self.get('collateralClassTypes').addObject(item.value);
          } else {
            self.get('collateralClassTypes').removeObject(item.value);
          }
        });
      }
      let result = self.clone();
      result.searchResultFilterCriteria = null;
      return result;
    }
  });
  _exports.default = _default;
});