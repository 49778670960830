define("esis-ui/components/areas/search/au/view/au-search-individual/component", ["exports", "esis-ui/components/areas/search/au/view/au-search-view-base", "lodash", "ember-concurrency", "esis-ui/models/individual-search-criteria"], function (_exports, _auSearchViewBase, _lodash, _emberConcurrency, _individualSearchCriteria) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSearchViewBase.default.extend({
    //region INITIALISATION
    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region METHODS
    _loadIndividualSearchCriteria(searchHeader) {
      let criteria = _individualSearchCriteria.default.create();
      let searches = searchHeader.get('searches');
      const constants = this.get('constants');
      searches.forEach(function (search) {
        criteria.set('guid', search.guid);
        if (search.criteriaGrantorType === constants.grantorType.organisation) {
          if (search.criteriaOrganisationNumberType === constants.organisationNumberType.ABN) {
            criteria.set('abn', search.criteriaOrganisationNumber);
            criteria.set('abnSelected', true);
          }
        } else {
          if (Ember.isBlank(criteria.get('givenName1'))) {
            criteria.set('dob1', search.criteriaDateOfBirth);
            criteria.set('familyName1', search.criteriaFamilyName);
            criteria.set('givenName1', search.criteriaGivenNames);
            criteria.set('individual1Selected', true);
            criteria.set('criteriaAcceptGrantorIndividualSearchDeclaration', search.criteriaAcceptGrantorIndividualSearchDeclaration);
          } else if (Ember.isBlank(criteria.get('givenName2'))) {
            criteria.set('dob2', search.criteriaDateOfBirth);
            criteria.set('familyName2', search.criteriaFamilyName);
            criteria.set('givenName2', search.criteriaGivenNames);
            criteria.set('individual2Selected', true);
            criteria.set('criteriaAcceptGrantorIndividualSearchDeclaration', search.criteriaAcceptGrantorIndividualSearchDeclaration);
          } else {
            criteria.set('dob3', search.criteriaDateOfBirth);
            criteria.set('familyName3', search.criteriaFamilyName);
            criteria.set('givenName3', search.criteriaGivenNames);
            criteria.set('individual3Selected', true);
            criteria.set('criteriaAcceptGrantorIndividualSearchDeclaration', search.criteriaAcceptGrantorIndividualSearchDeclaration);
          }
        }
      });
      return criteria;
    },
    _createIndividualSearchCriteria: function _createIndividualSearchCriteria() {
      let criteria = _individualSearchCriteria.default.create();
      criteria.set('individual1Selected', false);
      criteria.set('individual2Selected', false);
      criteria.set('individual3Selected', false);
      criteria.set('abnSelected', false);
      criteria.set('givenName1', '');
      criteria.set('familyName1', '');
      criteria.set('givenName2', '');
      criteria.set('familyName2', '');
      criteria.set('givenName3', '');
      criteria.set('familyName3', '');
      criteria.set('abn', '');
      return criteria;
    },
    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          const response = yield this.get('searchRepo.loadSearch').linked().perform(this.get('tabModel.p0'));
          const searchHeader = this._createSearchHeader('', response);
          this.set('model', searchHeader);
          this._mapSearches(this.get('model'));
          this.set('criteria', this._loadIndividualSearchCriteria(searchHeader));
          this.updateTabProperty('title', `Individual Search ${searchHeader.esisId}`);
          this.set('_isReadOnly', true);
        } else {
          const newRecord = this._createSearchHeader(this.get('constants.searchType.searchByGrantor'));
          let criteria = this._createIndividualSearchCriteria();
          this.set('model', newRecord);
          this.set('criteria', criteria);
        }
        this.get('model').saveModel();
        this.get('criteria').save();
      } catch (error) {
        this.get('logger').logError('au-search-individual._loadModel', error);
        this.notifyError('Fail to load search');
      }
    }),
    //endregion

    actions: {
      _onBlurAbn: function _onBlurAbn(value) {
        this.set('criteria.abn', value);
        this.set('criteria.abnSelected', Ember.isPresent(this.get('criteria.abn')));
      },
      _dob1Updated: function _dob1Updated(value) {
        this.set('criteria.dob1', value);
        if (Ember.isPresent(this.get('criteria.givenName1')) && Ember.isPresent(this.get('criteria.familyName1')) && Ember.isPresent(this.get('criteria.dob1'))) {
          this.set('criteria.individual1Selected', true);
        }
      },
      _dob2Updated: function _dob2Updated(value) {
        this.set('criteria.dob2', value);
        if (Ember.isPresent(this.get('criteria.givenName2')) && Ember.isPresent(this.get('criteria.familyName2')) && Ember.isPresent(this.get('criteria.dob2'))) {
          this.set('criteria.individual2Selected', true);
        }
      },
      _dob3Updated: function _dob3Updated(value) {
        this.set('criteria.dob3', value);
        if (Ember.isPresent(this.get('criteria.givenName3')) && Ember.isPresent(this.get('criteria.familyName3')) && Ember.isPresent(this.get('criteria.dob3'))) {
          this.set('criteria.individual3Selected', true);
        }
      },
      _onBlurPersonInfo1: function _onBlurPersonInfo1() {
        if (Ember.isPresent(this.get('criteria.givenName1')) && Ember.isPresent(this.get('criteria.familyName1')) && Ember.isPresent(this.get('criteria.dob1'))) {
          this.set('criteria.individual1Selected', true);
        }
      },
      _onBlurPersonInfo2: function _onBlurPersonInfo2() {
        if (Ember.isPresent(this.get('criteria.givenName2')) && Ember.isPresent(this.get('criteria.familyName2')) && Ember.isPresent(this.get('criteria.dob2'))) {
          this.set('criteria.individual2Selected', true);
        }
      },
      _onBlurPersonInfo3: function _onBlurPersonInfo3() {
        if (Ember.isPresent(this.get('criteria.givenName3')) && Ember.isPresent(this.get('criteria.familyName3')) && Ember.isPresent(this.get('criteria.dob3'))) {
          this.set('criteria.individual3Selected', true);
        }
      },
      _onUpdateTab(searchResultSummary) {
        this.updateTabProperty('title', `Individual Search ${searchResultSummary.searchHeaderEsisId}`);
        this.updateTabProperty('p0', searchResultSummary.searchHeaderGuid);
      },
      _onProceed: function _onProceed() {
        this.set('criteria.criteriaAcceptGrantorIndividualSearchDeclaration', true);
      },
      _onClose() {
        this.removeTab();
      },
      _onLoadSearch(searchHeader) {
        this.set('criteria', this._loadIndividualSearchCriteria(searchHeader));
        this.get('criteria').save();
        this.get('_grantorFilter').loadFilterCriteria();
      }
    }
  });
  _exports.default = _default;
});