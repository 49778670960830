define("esis-ui/components/areas/report/au/view/search-grantor-diff/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION
    _sourceId: null,
    _compareId: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        _sourceId: null,
        _compareId: null
      });
    },
    //endregion

    //region TASKS
    _downloadReport: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('reportRepo.downloadSearchGrantorDiffExtractReport').linked().perform(this.get('_sourceId'), this.get('_compareId'));
        if (Ember.isPresent(this.get('hideWaitingModal'))) {
          this.get('hideWaitingModal')();
        }
        this.notifySuccess('Grantor search result comparison extract report has been downloaded');
      } catch (error) {
        this.notifyError(`Failed to download file - ${error.payload.message}`);
        if (Ember.isPresent(this.get('hideWaitingModal'))) {
          this.get('hideWaitingModal')();
        }
      }
    }).drop(),
    //endregion

    //region ACTIONS
    actions: {
      _downloadReport: function _downloadReport() {
        if (Ember.isPresent(this.get('_sourceId')) && Ember.isPresent(this.get('_compareId'))) {
          this.get('_downloadReport').perform();
        } else {
          if (!Ember.isPresent(this.get('_sourceId'))) this.set('sourceIdErrorMsg', 'Please enter the source ID');
          if (!Ember.isPresent(this.get('_compareId'))) this.set('compareIdErrorMsg', 'Please enter the compare ID');
        }
      },
      _updateSourceId(value) {
        this.set('_sourceId', value);
        if (Ember.isPresent(value)) {
          this.set('sourceIdErrorMsg', '');
        } else {
          this.set('sourceIdErrorMsg', 'Please enter the source ID');
        }
      },
      _updateCompareId(value) {
        this.set('_compareId', value);
        if (Ember.isPresent(value)) {
          this.set('compareIdErrorMsg', '');
        } else {
          this.set('compareIdErrorMsg', 'Please enter the compare ID');
        }
      }
    }
  });
  _exports.default = _default;
});