define("esis-ui/components/areas/admin/contract-type/nz/list/nz-collateral-profile-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JlHD5HQ3",
    "block": "{\"symbols\":[\"staticTab\",\"item\"],\"statements\":[[4,\"shared/ember-bootstrap/static-tab/tab-control\",null,[[\"initialTab\",\"screenId\",\"model\",\"registerAs\"],[[24,[\"_collateralTypePanel\"]],[24,[\"screenId\"]],[24,[\"model\"]],[24,[\"_tabPreview\"]]]],{\"statements\":[[4,\"shared/ember-bootstrap/static-tab/preview-panel\",null,[[\"panelTitle\",\"panelId\",\"parentTab\",\"isLoading\",\"order\"],[\"Preview\",[24,[\"_collateralTypePanel\"]],[23,1,[]],[24,[\"_loadPreview\",\"isRunning\"]],1]],{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[22,\"shared/preview/preview-no-data\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"table-responsive mt-3 ml-3 pr-3\"],[8],[0,\"\\n        \"],[7,\"table\",true],[10,\"class\",\"table table-striped preview previewTable\"],[8],[0,\"\\n          \"],[7,\"thead\",true],[8],[0,\"\\n          \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[10,\"style\",\"width:20%\"],[8],[0,\"Collateral Type per Contract\"],[9],[0,\"\\n            \"],[7,\"th\",true],[10,\"style\",\"width:40%\"],[8],[0,\"Default Collateral Type Description\"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[9],[0,\"\\n          \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"defaults\"]]],null,{\"statements\":[[0,\"            \"],[7,\"tr\",true],[8],[0,\"\\n              \"],[7,\"td\",true],[8],[1,[23,2,[\"collateralTypeTitle\"]],false],[9],[0,\"\\n              \"],[7,\"td\",true],[8],[1,[23,2,[\"collateralTypeDescription\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/admin/contract-type/nz/list/nz-collateral-profile-preview/template.hbs"
    }
  });
  _exports.default = _default;
});