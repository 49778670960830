define("esis-ui/components/areas/admin/business-entity/view/business-entity-detail-display/component", ["exports", "esis-ui/components/areas/shared/view-page-base"], function (_exports, _viewPageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _viewPageBase.default.extend({
    detailsModel: null,
    //region INITIALIZATION ---

    //region ACTIONS
    actions: {
      _editBusinessEntity() {
        this.get('onEditClick')();
      }
    }
    //endregion
  });
  _exports.default = _default;
});