define("esis-ui/models/secured-party-person-on-behalf-nz", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    firstName: (0, _modelBase.attr)(),
    lastName: (0, _modelBase.attr)(),
    dateOfBirth: (0, _modelBase.attr)(),
    email: (0, _modelBase.attr)(),
    contactAddress: (0, _modelBase.attr)(),
    mailingAddress: (0, _modelBase.attr)(),
    middleNames: (0, _modelBase.attr)(),
    phoneNumber: (0, _modelBase.attr)(),
    faxNumber: (0, _modelBase.attr)(),
    jobTitle: (0, _modelBase.attr)(),
    isOverride: (0, _modelBase.attr)(),
    hasMailingAddress: Ember.computed('mailingAddress', function () {
      return !(0, _lodash.isEmpty)(this.get('mailingAddress'));
    }),
    hasName: Ember.computed('firstName', 'middleNames', 'lastName', function () {
      return Ember.isPresent(this.get('firstName')) || Ember.isPresent(this.get('lastName')) || Ember.isPresent(this.get('middleNames'));
    }),
    hasJobTitle: Ember.computed('jobTitle', function () {
      return Ember.isPresent(this.get('jobTitle'));
    }),
    init() {
      this._super(...arguments);
      this.validations = {
        jobTitle: {
          custom: {
            validation: function validation(key, value, model) {
              if (!Ember.isPresent(model.get('firstName')) && !Ember.isPresent(model.get('lastName'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Job Title or Name can not be blank'
          }
        },
        firstName: {
          custom: [{
            validation: function validation(key, value, model) {
              if (!Ember.isPresent(model.get('jobTitle')) && Ember.isPresent(model.get('lastName'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'First Name can not be blank'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length < 2);
              }
              return true;
            },
            message: "First Name can't be less than two characters"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 40);
              }
              return true;
            },
            message: "First Name can't be more than 40 characters"
          }]
        },
        lastName: {
          custom: [{
            validation: function validation(key, value, model) {
              if (!Ember.isPresent(model.get('jobTitle')) && Ember.isPresent(model.get('firstName'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Last Name can not be blank'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length < 2);
              }
              return true;
            },
            message: "Last Name can't be less than two characters"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 40);
              }
              return true;
            },
            message: "Last Name can't be more than 40 characters"
          }]
        },
        middleNames: {
          custom: {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 60);
              }
              return true;
            },
            message: "Middle Names can't be more than 60 characters"
          }
        }
      };
    }
  });
  _exports.default = _default;
});