define("esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _collateral: null,
    _modalTitle: 'Add Collateral Type',
    _reset() {
      this.setProperties({
        _enableAddCollItem: false,
        _collateral: null
      });
    },
    show(coll) {
      this._reset();
      let collateral = this.createModel('nz-collateral-create-request');
      collateral.setProperties({
        collateralType: coll.value,
        collateralTypeTitle: coll.title,
        collateralItems: [],
        collDescription: coll.collDescription
      });
      if (collateral.mustHaveCollItem) {
        let colItem = this.createModel('nz-collateral-item-request');
        colItem.set('collateralType', coll.value);
        if (!(0, _lodash.isEmpty)(coll.itemDescription)) {
          colItem.set('description', coll.itemDescription);
          this.set('_enableAddCollItem', true);
        }
        collateral.collateralItems.pushObject(colItem);
      }
      this.set('_collateral', collateral);
      this.get('_dialog').show();
    },
    //region Closure Actions
    onAddCollateral() {},
    //endregion
    actions: {
      _onConfirm() {
        if (this.get('_collateral').validateModel()) {
          this.get('onAddCollateral')(this.get('_collateral'));
          this.get('_dialog').hide();
        }
      },
      _onClickAddCollItem() {
        this.set('_enableAddCollItem', true);
        let colItem = this.createModel('nz-collateral-item-request');
        colItem.set('collateralType', this.get('_collateral').collateralType);
        colItem.set('action', this.get('constants.actionType.add'));
        this.get('_collateral').collateralItems.pushObject(colItem);
      },
      _onClickRemoveCollItem() {
        this.set('_enableAddCollItem', false);
        this.set('_collateral.collateralItems', []);
      }
    }
  });
  _exports.default = _default;
});