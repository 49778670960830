define("esis-ui/models/collateral", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    esisId: (0, _modelBase.attr)(),
    registrationId: (0, _modelBase.attr)(),
    itemNumber: (0, _modelBase.attr)(),
    contractTypeId: (0, _modelBase.attr)(),
    contractTypeTitle: (0, _modelBase.attr)(),
    contractTypeCode: (0, _modelBase.attr)(),
    registrationEndTime: (0, _modelBase.attr)(),
    areAssetsSubjectToControl: (0, _modelBase.attr)(),
    areProceedsClaimed: (0, _modelBase.attr)(),
    collateralType: (0, _modelBase.attr)(),
    collateralClassType: (0, _modelBase.attr)(),
    collateralClassTypeTitle: (0, _modelBase.attr)(),
    collateralDescription: (0, _modelBase.attr)(),
    isInventory: (0, _modelBase.attr)(),
    isPmsi: (0, _modelBase.attr)(),
    isRegistrationEndTimeNotStated: (0, _modelBase.attr)(),
    isSubordinate: (0, _modelBase.attr)(),
    isTransitional: (0, _modelBase.attr)(),
    isMigrated: (0, _modelBase.attr)(),
    proceedsClaimedDescription: (0, _modelBase.attr)(),
    serialNumber: (0, _modelBase.attr)(),
    serialNumberType: (0, _modelBase.attr)(),
    serialNumberTypeTitle: (0, _modelBase.attr)(),
    vehicleDescriptiveText: (0, _modelBase.attr)(),
    vehicleRegistrationNumber: (0, _modelBase.attr)(),
    aircraftNationality: (0, _modelBase.attr)(),
    aircraftNationalityCodeAndRegistrationMark: (0, _modelBase.attr)(),
    manufacturersModel: (0, _modelBase.attr)(),
    manufacturersName: (0, _modelBase.attr)(),
    udf1: (0, _modelBase.attr)(),
    udf2: (0, _modelBase.attr)(),
    udf3: (0, _modelBase.attr)(),
    udf4: (0, _modelBase.attr)(),
    udf5: (0, _modelBase.attr)(),
    isSerialised: (0, _modelBase.attr)(),
    serialNumberError: (0, _modelBase.attr)(),
    registrationStartTime: (0, _modelBase.attr)(),
    registrationRenewTime: (0, _modelBase.attr)(),
    expiryDateMessage: (0, _modelBase.attr)(),
    isConfirmed: (0, _modelBase.attr)(),
    isRegistrationEndTimeChanged: (0, _modelBase.attr)(),
    existingEndTime: (0, _modelBase.attr)(),
    registrationEndTimeOption: (0, _modelBase.attr)(),
    contractDefaultDurationYears: (0, _modelBase.attr)(),
    renewRequest: null,
    // this is not a model property, just used by registration wizard collateral step
    isAircraft: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      return this.get('collateralClassType') === constants.searchCollateralClassType.aircraftEngine || this.get('collateralClassType') === constants.searchCollateralClassType.airframe || this.get('collateralClassType') === constants.searchCollateralClassType.helicopter || this.get('collateralClassType') === constants.searchCollateralClassType.smallAircraft;
    }),
    showSerialNumber: Ember.computed('collateralClassType', 'isSerialised', function () {
      const constants = this.get('constants');
      return this.get('isSerialised') && (this.get('collateralClassType') === constants.searchCollateralClassType.motorVehicle || this.get('collateralClassType') === constants.searchCollateralClassType.watercraft || this.get('collateralClassType') === constants.searchCollateralClassType.aircraftEngine || this.get('collateralClassType') === constants.searchCollateralClassType.airframe || this.get('collateralClassType') === constants.searchCollateralClassType.helicopter || this.get('collateralClassType') === constants.searchCollateralClassType.patent || this.get('collateralClassType') === constants.searchCollateralClassType.design || this.get('collateralClassType') === constants.searchCollateralClassType.plantBreedersRight || this.get('collateralClassType') === constants.searchCollateralClassType.tradeMark || this.get('collateralClassType') === constants.searchCollateralClassType.smallAircraft);
    }),
    showAircraftManufacturer: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      return this.get('collateralClassType') === constants.searchCollateralClassType.aircraftEngine || this.get('collateralClassType') === constants.searchCollateralClassType.airframe || this.get('collateralClassType') === constants.searchCollateralClassType.helicopter || this.get('collateralType') === constants.collateralType.consumer && this.get('collateralClassType') === constants.searchCollateralClassType.smallAircraft;
    }),
    showAircraftNationality: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      return this.get('collateralClassType') === constants.searchCollateralClassType.airframe || this.get('collateralClassType') === constants.searchCollateralClassType.helicopter;
    }),
    showContractType: Ember.computed('contractTypeId', function () {
      return Ember.isPresent(this.get('contractTypeId')) && this.get('contractTypeId') > 0;
    }),
    collateralTypeTitle: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      if (this.get('collateralType') === constants.collateralType.consumer) {
        return 'Consumer';
      } else if (this.get('collateralType') === constants.collateralType.commercial) {
        return 'Commercial';
      } else {
        return '';
      }
    }),
    isMotorVehicle: Ember.computed('collateralClassType', function () {
      return this.get('collateralClassType') === this.get('constants.searchCollateralClassType.motorVehicle');
    }),
    isWatercraft: Ember.computed('collateralClassType', function () {
      return this.get('collateralClassType') === this.get('constants.searchCollateralClassType.watercraft');
    }),
    isSmallAircraft: Ember.computed('collateralClassType', function () {
      return this.get('collateralClassType') === this.get('constants.searchCollateralClassType.smallAircraft');
    }),
    hasManufacturersNumber: Ember.computed('serialNumberType', function () {
      return this.get('serialNumberType') === this.get('constants.serialNumberType.motorVehicleManufacturersNumber');
    }),
    hasVehicleRegistrationNumber: Ember.computed.notEmpty('vehicleRegistrationNumber'),
    hasVehicleDescription: Ember.computed.notEmpty('vehicleDescriptiveText'),
    regOrDescValue: Ember.computed('hasVehicleRegistrationNumber', function () {
      return this.get('hasVehicleRegistrationNumber');
    }),
    showSerialisedStatus: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent((0, _lodash.find)(constants.collateralClassTypeSerialisedStatus, {
        'value': this.get('collateralClassType')
      }))) {
        return true;
      }
      return false;
    }),
    showSerialNumberForNotMv: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.patent) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.design) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.plantBreedersRight) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.tradeMark);
      }
      return false;
    }),
    showSubjectToControl: Ember.computed('collateralClassType', 'isInventory', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapWithExcept) && (Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.currency) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.negotiableInstrument) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.account)) || this.get('isInventory');
      }
      return false;
    }),
    setSubjectToControl: Ember.observer('showSubjectToControl', 'isInventory', function () {
      if (!this.get('showSubjectToControl')) {
        this.set('areAssetsSubjectToControl', null);
      }
    }),
    showCollateralDescrition: Ember.computed('isSerialised', 'showSerialNumberForNotMv', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && (!this.get('isSerialised') || this.get('showSerialNumberForNotMv'));
      }
      return false;
    }),
    showInventory: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapWithExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.currency) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.negotiableInstrument);
      }
      return false;
    }),
    showPmsi: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapWithExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.aircraft) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.chattelPaper) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.negotiableInstrument) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.intermediatedSecurity) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.investmentInstrument) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.account);
      }
      return false;
    }),
    serialNumberChanged: Ember.observer('serialNumber', function () {
      const constants = this.get('constants');
      if (Ember.isEqual(this.get('serialNumberType'), constants.serialNumberType.HIN)) {
        let formattedString = this.get('serialNumber').replace(/[.,/#!$%^&*;:{}=<>\-_`~()@+?'"[\]|\\]/g, "").replace(/\s+/g, "");
        const result = this.set('serialNumber', formattedString);
        if (this.get('serialNumber') !== result) {
          this.set('serialNumber', result);
        }
      }
    }),
    init() {
      this._super(...arguments);
      this.set('isConfirmed', true);
    }
  });
  _exports.default = _default;
});