define("esis-ui/models/nz-debtor-create-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    debtorId: (0, _modelBase.attr)(),
    debtorReference: (0, _modelBase.attr)(),
    debtorType: (0, _modelBase.attr)(),
    nzbn: (0, _modelBase.attr)(),
    nzbnEntityType: (0, _modelBase.attr)(),
    phoneNumber: (0, _modelBase.attr)(),
    faxNumber: (0, _modelBase.attr)(),
    mailingAddress: (0, _modelBase.attr)(),
    contactAddress: (0, _modelBase.attr)(),
    email: (0, _modelBase.attr)(),
    nzbnValidated: (0, _modelBase.attr)(),
    organisationName: (0, _modelBase.attr)(),
    incorporationNumber: (0, _modelBase.attr)(),
    organisationType: (0, _modelBase.attr)(),
    organisationTypeTitle: (0, _modelBase.attr)(),
    personActingOnBehalf: (0, _modelBase.attr)(),
    personFirstName: (0, _modelBase.attr)(),
    personLastName: (0, _modelBase.attr)(),
    personMiddleNames: (0, _modelBase.attr)(),
    personDateOfBirth: (0, _modelBase.attr)(),
    //amend
    action: (0, _modelBase.attr)(),
    //UI only, Amend only
    newDebtorGuid: (0, _modelBase.attr)(),
    existingDebtorId: (0, _modelBase.attr)(),
    isOrganisation: Ember.computed('debtorType', function () {
      return this.get('debtorType') === this.get('constants.nzSPOrDebtorType.organisation');
    }),
    hasMailingAddress: Ember.computed('mailingAddress', function () {
      return !(0, _lodash.isEmpty)(this.get('mailingAddress'));
    }),
    saveModel: function saveModel() {
      this.save();
      this.contactAddress.save();
      if (this.hasMailingAddress) this.mailingAddress.save();
      if (this.isOrganisation) {
        this.personActingOnBehalf.save();
        this.personActingOnBehalf.contactAddress.save();
        if (this.personActingOnBehalf.hasMailingAddress) this.personActingOnBehalf.mailingAddress.save();
      }
    },
    validateModel() {
      if (this.get('action') === this.get('constants.actionType.remove')) {
        return true;
      }
      let valid = this.validate();
      valid = this.get('contactAddress').validate() && valid;
      if (this.get('hasMailingAddress')) {
        valid = this.get('mailingAddress').validate() && valid;
      }
      let personActingOnBehalfValid = true;
      if (this.get('isOrganisation')) {
        personActingOnBehalfValid = this.get('personActingOnBehalf').validate() && personActingOnBehalfValid;
        personActingOnBehalfValid = this.get('personActingOnBehalf.contactAddress').validate() && personActingOnBehalfValid;
      }
      if (this.get('isOrganisation') && this.get('personActingOnBehalf.hasMailingAddress')) {
        personActingOnBehalfValid = this.get('personActingOnBehalf.mailingAddress').validate() && personActingOnBehalfValid;
      }
      return valid && personActingOnBehalfValid;
    },
    init() {
      this._super(...arguments);
      this.validations = {
        organisationName: {
          custom: {
            validation: (key, value) => {
              if (this.get('isOrganisation')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Organisation name can not be blank'
          }
        },
        organisationType: {
          custom: {
            validation: (key, value) => {
              if (this.get('isOrganisation')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Organisation Type can not be blank'
          }
        },
        incorporationNumber: {
          custom: {
            validation: (key, value) => {
              const nzDebtorPpsrOrgType = this.get('constants.nzDebtorPpsrOrganisationType');
              if (this.get('isOrganisation') && this.get('organisationType') !== nzDebtorPpsrOrgType.other && this.get('organisationType') !== nzDebtorPpsrOrgType.partnership && this.get('organisationType') !== nzDebtorPpsrOrgType.trust) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Incorporation Number can't be blank"
          }
        },
        email: {
          custom: [{
            validation: (key, value, model) => {
              if (this.get('isOrganisation')) {
                if (Ember.isPresent(value)) {
                  return model.validateEmailAddress(value);
                }
              }
              return true;
            },
            message: 'Not a valid email'
          }
          // {
          //     validation: (key, value/*, model*/) => {
          //         if (this.get('isOrganisation')) {
          //             return isPresent(value);
          //         }
          //         return true;
          //     },
          //     message: "Email can't be blank"
          // },
          ]
        },

        personFirstName: {
          custom: [{
            validation: (key, value) => {
              if (!this.get('isOrganisation')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "First Name can't be blank"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length < 2);
              }
              return true;
            },
            message: "First Name can't be less than two characters"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 40);
              }
              return true;
            },
            message: "First Name can't be more than 40 characters"
          }]
        },
        personLastName: {
          custom: [{
            validation: (key, value) => {
              if (!this.get('isOrganisation')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Last Name can't be blank"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length < 2);
              }
              return true;
            },
            message: "Last Name can't be less than two characters"
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 40);
              }
              return true;
            },
            message: "Last Name can't be more than 40 characters"
          }]
        },
        personMiddleNames: {
          custom: {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 60);
              }
              return true;
            },
            message: "Middle Names can't be more than 60 characters"
          }
        },
        debtorReference: {
          custom: {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return !(value.length > 200);
              }
              return true;
            },
            message: "Debtor Reference can't be more than 200 characters"
          }
        },
        personDateOfBirth: {
          custom: [{
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return this.getAge(value) >= 16 && this.getAge(value) <= 120;
              }
              return true;
            },
            message: "Age must be between 16 and 120 years old"
          }, {
            validation: (key, value) => {
              if (!this.get('isOrganisation')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'DoB can\'t be blank'
          }]
        }
      };
    }
  });
  _exports.default = _default;
});