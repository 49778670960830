define("esis-ui/components/areas/registration/au/view/au-registration-edit-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Edit Registration',
    _renewRequest: null,
    _loadPicklistsTask: (0, _emberConcurrency.task)(function* () {
      try {
        let categoryPicklist = yield this.get('picklistRepo.loadCategories').perform(this.get('credential.currentBusinessEntityGuid'));
        this.set('_categoryList', categoryPicklist);
        this.set('_selectedCategory', (0, _lodash.find)(this.get('_categoryList'), {
          'value': this.get('model.categoryGuid')
        }));
      } catch (error) {
        this.get('logger').logError('au-registration-edit-modal._loadPicklists', error);
      }
    }),
    _canEditRegToken: Ember.computed('model.{isDelegate,ppsrRegistrationToken}', function () {
      return this.get('model.isDelegate') && Ember.isPresent('model.ppsrRegistrationToken') && this.get('credential.hasViewEncryptedDataPermission');
    }),
    _createModel(model) {
      const constants = this.get('constants');
      let regModel = this.mapObject(model, this.createModel('registration-details'));
      this.set('model', regModel);
      const renewRequest = this.mapObject(this.get('model'), this.createModel('renew-reg-request'));
      renewRequest.setProperties({
        shouldSyncToPpsr: false,
        disableTwentyFiveYear: model.collaterals[0].collateralClassType === constants.searchCollateralClassType.motorVehicle && model.collaterals[0].isSerialised,
        disableNoDuration: model.collaterals[0].collateralClassType === constants.searchCollateralClassType.motorVehicle && model.collaterals[0].isSerialised
      });
      renewRequest.save();
      this.set('_renewRequest', renewRequest);
    },
    _reset() {
      this.setProperties({
        _selectedCategory: null,
        _renewRequest: null,
        model: null
      });
    },
    show(model) {
      this._reset();
      this._createModel(model);
      this.get('_loadPicklistsTask').perform();
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm() {
        if (this.get('model').validate() && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('model'), this.get('_renewRequest'));
          this.get('_dialog').hide();
        }
      },
      _onCategoryChange(selected) {
        this.set('_selectedCategory', selected);
        this.set('model.categoryGuid', selected.value);
        this.set('model.categoryTitle', selected.title);
      },
      _onContractDateChanged: function _onContractDateChanged(value) {
        this.set('model.contractDate', value);
      }
    }
  });
  _exports.default = _default;
});