define("esis-ui/helpers/include-in", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includesInInner = includesInInner;
  function includesInInner([collection, value]) {
    return (0, _lodash.includes)(collection, value);
  }
  var _default = Ember.Helper.helper(includesInInner);
  _exports.default = _default;
});