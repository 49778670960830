define("esis-ui/components/shared/checkbox-control/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* for individual validation pass formElement to checkbox-control e.g. dynamic-form/checkbox-control
   * for group validation pass formElement only to checkbox-group-control e.g. policy/wizard-application/wizard-step/product-step
    * */
  var _default = _sharedComponentBase.default.extend({
    title: '',
    disabled: false,
    required: false,
    titleInLeft: false,
    formElement: null,
    icon: 'check',
    checkboxGroupClass: Ember.computed('formElement.validation', function () {
      if (this.get('formElement.validation') === 'error') {
        return Ember.String.htmlSafe('form-control is-invalid');
      }
      return Ember.String.htmlSafe('');
    })
  });
  _exports.default = _default;
});