define("esis-ui/components/areas/registration/nz/shared/renew-discharge-result-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nRZDT9bo",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"modalTitle\",\"registerAs\",\"onConfirm\",\"confirmBtnLabel\",\"closeButton\",\"renderInPlace\",\"modalScrollable\"],[[24,[\"modalTitle\"]],[24,[\"_dialog\"]],[28,\"action\",[[23,0,[]],\"onConfirm\"],null],[24,[\"confirmBtnLabel\"]],[24,[\"cancelButton\"]],[24,[\"renderInPlace\"]],true]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"_result\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"\\n        \"],[7,\"span\",true],[8],[0,\" Registration \"],[1,[23,1,[\"registrationNumber\"]],false],[0,\" : \"],[9],[0,\"\\n        \"],[7,\"span\",true],[11,\"class\",[28,\"if\",[[23,1,[\"isSuccess\"]],\"text-success\",\"text-danger\"],null]],[8],[1,[23,1,[\"statusTitle\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"errorMessage\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n            Error Message :\\n            \"],[7,\"span\",true],[10,\"class\",\"validationErrorMessage\"],[8],[1,[23,1,[\"errorMessage\"]],false],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/shared/renew-discharge-result-modal/template.hbs"
    }
  });
  _exports.default = _default;
});