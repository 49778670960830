define("esis-ui/components/areas/shared/info-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    renderInPlace: true,
    cancelButton: true,
    confirmButton: true,
    confirmBtnLabel: 'Yes',
    cancelBtnLabel: 'No',
    modalTitle: "Confirmation",
    _additionalArgs: null,
    isShowing: false,
    setShowConfirmBtn(show) {
      this.set('confirmButton', show);
    },
    setTitle(title) {
      this.set('modalTitle', title);
    },
    show(message, ...args) {
      if (!(0, _lodash.isEmpty)(message)) {
        this.set('message', Ember.String.htmlSafe(message));
      }
      if (!(0, _lodash.isEmpty)(args)) {
        this.set('_additionalArgs', args);
      }
      this.get('_dialog').show();
      this.set('isShowing', true);
    },
    hide() {
      this.get('_dialog').hide();
      this.set('isShowing', false);
    },
    actions: {
      onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm').apply(null, this.get('_additionalArgs'));
        }
      },
      onCancel() {
        if (!(0, _lodash.isEmpty)(this.get('onCancel'))) {
          this.get('onCancel').apply(null, this.get('_additionalArgs'));
        }
      }
    }
  });
  _exports.default = _default;
});