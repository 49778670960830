define("esis-ui/components/areas/admin/abn-name-lookup-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _selectedBusinessEntity: null,
    //region TASK
    _grantorAbrOrgNameLookup: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.grantorAbrOrgNameLookup').perform(this.get('_selectedBusinessEntity.value'));
        this.notifySuccess('ABN name lookup completed');
        this.get('_dialog').hide();
      } catch (error) {
        this.get('logger').logError('abn-name-lookup-modal._grantorAbrOrgNameLookup', error);
      }
    }),
    //endregion

    //region METHODS
    show() {
      this.setProperties({
        _selectedBusinessEntity: this.get('credential.currentBusinessEntity')
      });
      this.get('_dialog').show();
    },
    //endregion

    //region ACTIONS
    actions: {
      _chooseBusinessEntity(item) {
        this.set('_selectedBusinessEntity', item);
      },
      _onConfirm() {
        this.get('_grantorAbrOrgNameLookup').perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});