define("esis-ui/models/secured-party-person-on-behalf-spg-nz", ["exports", "esis-ui/models/secured-party-person-on-behalf-nz"], function (_exports, _securedPartyPersonOnBehalfNz) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _securedPartyPersonOnBehalfNz.default.extend({
    init() {
      this._super(...arguments);
      this.validations = {
        email: {
          custom: {
            validation: function validation(key, value, model) {
              return Ember.isPresent(value) ? model.validateEmailAddress(value) : false;
            },
            message: 'Not a valid email'
          }
        }
      };
    }
  });
  _exports.default = _default;
});