define("esis-ui/components/areas/spg/au/list/preview/au-spg-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Z0WiYg41",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"shared/ember-bootstrap/static-tab/preview-panel\",null,[[\"panelTitle\",\"panelId\",\"parentTab\",\"refreshTab\",\"isLoading\",\"order\"],[\"Secured Parties\",[24,[\"panelId\"]],[24,[\"parentTab\"]],[28,\"action\",[[23,0,[]],\"_onTabLoad\"],null],[24,[\"_loadPreview\",\"isRunning\"]],1]],{\"statements\":[[4,\"if\",[[24,[\"hasNoData\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"shared/preview/preview-no-data\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-md-12 mt-2 ml-0 pl-0\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"table-responsive\"],[8],[0,\"\\n          \"],[7,\"table\",true],[10,\"class\",\"table table-striped previewTable preview\"],[8],[0,\"\\n            \"],[7,\"thead\",true],[8],[0,\"\\n            \"],[7,\"tr\",true],[8],[0,\"\\n              \"],[7,\"th\",true],[8],[0,\"Secured Party Name\"],[9],[0,\"\\n              \"],[7,\"th\",true],[8],[0,\"Type\"],[9],[0,\"\\n              \"],[7,\"th\",true],[8],[0,\"Number Type\"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"              \"],[7,\"tr\",true],[8],[0,\"\\n                \"],[7,\"td\",true],[10,\"class\",\"\"],[8],[1,[23,1,[\"securedPartyName\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[10,\"class\",\"\"],[8],[1,[23,1,[\"type\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[10,\"class\",\"\"],[8],[1,[23,1,[\"numberType\"]],false],[9],[0,\"\\n              \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/spg/au/list/preview/au-spg-preview/template.hbs"
    }
  });
  _exports.default = _default;
});