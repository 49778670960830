define("esis-ui/components/shared/kendo/grid/grid-remote-rest-ds/component", ["exports", "esis-ui/components/shared/kendo/grid/grid-base", "ember-lifeline"], function (_exports, _gridBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _gridBase.default.extend({
    init() {
      this._super(...arguments);
      const self = this;
      const logger = self.get('logger');
      self._defaultOptions.height = "100%";
      self._defaultOptions.autoBind = false;
      self._defaultOptions.dataSource = {
        transport: {
          read: {
            dataType: 'json',
            contentType: 'application/json',
            type: 'POST',
            beforeSend: xhr => {
              xhr.setRequestHeader('Authorization', `Bearer ${self.getCurrentAccessToken()}`);
            }
          }
        },
        schema: {
          total: 'recordCount',
          parse: function parse(response) {
            if (response.isSuccess !== true) {
              self.notifyWarn(response.errors.join('|'));
              return [];
            }
            return response.data;
          },
          data: function data(_data) {
            return _data;
          }
        },
        sort: [{
          field: "id",
          dir: "desc"
        }],
        pageSize: self.get('isPageable') ? 30 : 50,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        error: function error(e) {
          logger.logError('Network error, remote rest data cannot be loaded into the grid.', e);
        },
        requestStart: function requestStart() {
          if (Ember.isPresent(self.$(self.element))) {
            if (self.$("div.k-loading-mask").length === 0) {
              kendo.ui.progress(self.$(self.element), true);
            }
            self.get('noticeGridIsLoadingData')(true);
          }
        },
        requestEnd: function requestEnd(e) {
          if (Ember.isPresent(self.$(self.element))) {
            kendo.ui.progress(self.$(self.element), false);
            if (e.type === 'read' && e.response) {
              /*
              setup context action menu is any
               */
              (0, _emberLifeline.runTask)(self, '_renderContextMenu', 250);
              /*
              select the previous selected row if available, if row not found, no row is selected.
              if selectedGuid is null, then select the first row
               */
              (0, _emberLifeline.runTask)(self, '_kendoGridInitSelectRow', 500);
            }
            self.get('noticeGridIsLoadingData')(false);
          }
        }
      };
    }
  });
  _exports.default = _default;
});