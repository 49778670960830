define("esis-ui/components/shared/kendo/tree-list/tree-list-local-ds/component", ["exports", "esis-ui/components/shared/kendo/tree-list/tree-list-base", "ember-lifeline"], function (_exports, _treeListBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _treeListBase.default.extend({
    _contextMenuIsDone: false,
    init() {
      this._super(...arguments);
      const self = this;
      const logger = self.get('logger');
      self._defaultOptions.height = null;
      self._defaultOptions.dataSource = {
        transport: {
          read: function read(operation) {
            const data = operation.data.data || [];
            // this is emulating delay of a ajax call. this is a hack until found better solution
            (0, _emberLifeline.runTask)(self, () => {
              operation.success(data);
            }, 250);
          }
        },
        requestEnd: function requestEnd() {
          (0, _emberLifeline.runTask)(self, 'kendoTreeListInitSelectRow', 500);
        },
        sort: [{
          field: "id",
          dir: "desc"
        }],
        pageSize: 999999999,
        error: function error(e) {
          logger.logError('Local data kendo TreeList error : ', e);
        }
      };
    },
    loadData(data, initialSelectedGuid) {
      const theTreeList = this.get('_treeList');
      if (Ember.isPresent(theTreeList)) {
        if (Ember.isPresent(initialSelectedGuid)) {
          this.set('selectedGuid', initialSelectedGuid);
        }
        theTreeList.dataSource.read({
          data: data
        });
      }
    },
    sort(order) {
      const theTreeList = this.get('_treeList');
      if (Ember.isPresent(theTreeList)) {
        theTreeList.dataSource.sort(order);
      }
    }
  });
  _exports.default = _default;
});