define("esis-ui/components/shared/ember-file-uploader/component", ["exports", "ember-cli-dropzonejs/components/drop-zone", "esis-ui/mixins/esis-utils", "esis-ui/config/environment"], function (_exports, _dropZone, _esisUtils, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _dropZone.default.extend(_esisUtils.default, {
    //region Services
    session: Ember.inject.service('session'),
    toast: Ember.inject.service('toast'),
    constants: Ember.inject.service('app-constants'),
    credential: Ember.inject.service('credential-service'),
    cacheService: Ember.inject.service('cache-service'),
    app: Ember.inject.service('app-service'),
    logger: Ember.inject.service('logging-service'),
    //endregion

    //region Initialisation
    addRemoveLinks: true,
    clickable: true,
    acceptedFiles: '.zip,.csv,.xlsx',
    dictDefaultMessage: 'Drag and drop files into this box or click here to browse and select a file',
    url: function url() {
      return `${_environment.default.apiBaseUrl}/api/upload-file`;
    },
    onSuccess: function onSuccess() {},
    onRemoveFile: function onRemoveFile() {},
    //endregion

    myDropzone: Ember.computed({
      get( /*key*/
      ) {
        return typeof FastBoot === 'undefined' ? document.body : undefined;
      },
      set(key, value) {
        return value;
      }
    }),
    dropzoneCreated: Ember.observer('myDropzone', function () {
      //this.set('registerAs', this);

      if (Ember.isPresent(this.get('myDropzone'))) {
        this.get('logger').logDebug('dropZoneCreated and initialised');
        this.get('myDropzone').on('sending', (file, xhr) => {
          xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
        });
        this.get('myDropzone').on('success', (file, response) => {
          if (typeof response === 'string') {
            this.get('onSuccess')({
              fileName: file.name,
              fileKey: response
            });
            this.get('myDropzone').disable();
          } else {
            this.get('myDropzone').removeAllFiles(true);
            this.get('logger').logError('Fail to upload file', response.errors.join());
            this.notifyError('File upload failed, please try again.');
          }
        });
        this.get('myDropzone').on('removedfile', () => {
          this.get('myDropzone').enable();
          this.get('onRemoveFile')();
        });
        this.get('myDropzone').on('error', (file, errorMessage) => {
          this.get('myDropzone').removeAllFiles(true);
          this.get('logger').logError(errorMessage, file);
          this.notifyWarn(errorMessage);
        });
      }
    }),
    resetDropZone: 0,
    resetDropzone() {
      const dropzone = this.get('myDropzone');
      if (Ember.isPresent(dropzone)) {
        dropzone.removeAllFiles(true);
        dropzone.enable();
      }
    }
  });
  _exports.default = _default;
});