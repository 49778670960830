define("esis-ui/controllers/home", ["exports", "ember-lifeline"], function (_exports, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['sn', 'p0', 'p1'],
    sn: null,
    p0: null,
    p1: null,
    previousSn: null,
    previousP0: null,
    previousP1: null,
    queryParamsChanged: Ember.observer('sn', 'p0', 'p1', function () {
      if (this.get('sn') !== this.get('previousSn') || this.get('p0') !== this.get('previousP0')) {
        this.setProperties({
          previousSn: this.get('sn'),
          previousP0: this.get('p0'),
          previousP1: this.get('p1')
        });
        (0, _emberLifeline.runTask)(this, '_queryParamsChangedEvent', 500);
      }
    }),
    _queryParamsChangedEvent() {
      this.get('logger').logDebug('queryParamsChanged', this.get('sn'), this.get('p0'), this.get('p1'));
      this.get('event').trigger(this.get('constants.events.queryParamsChanged'), this.get('sn'), this.get('p0'), this.get('p1'));
    },
    willDestroy() {
      this._super(...arguments);
      (0, _emberLifeline.runDisposables)(this); // ensure that lifeline will clean up any remaining async work
    }
  });
  _exports.default = _default;
});