define("esis-ui/helpers/display-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayContent = displayContent;
  function displayContent([content, contentType]) {
    if (Ember.isBlank(content)) {
      return '';
    }
    if (contentType === 'text/html') {
      return Ember.String.htmlSafe(content);
    }
    return Ember.String.htmlSafe(content.replace(/((\r\n){3}|\r\n|\n|\r)/g, "<br />"));
  }
  var _default = Ember.Helper.helper(displayContent);
  _exports.default = _default;
});