define("esis-ui/repositories/account-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    loadProfile: (0, _emberConcurrency.task)(function* () {
      const response = yield this.apiGet(this.get('constants').get('apiEndpoint.loadUserProfile'), true);
      const userDigest = this.mapJsonObjectToModel(response, 'user-digest');
      this.get('credential').set('currentUser', userDigest);
      this.get('notification').connect();
      return userDigest;
    }),
    logoutUser: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.get('constants.apiEndpoint.logoutUser'), true);
    }).drop(),
    changeExpiredCredAccessKey: (0, _emberConcurrency.task)(function* (changeCredAccessKeyRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.changeExpiredCredAccessKey'), changeCredAccessKeyRequest.clone(), true);
    }).drop(),
    requestCredAccessKey: (0, _emberConcurrency.task)(function* (email) {
      return yield this.apiPost(this.get('constants.apiEndpoint.requestCredAccessKey'), email, true);
    }).drop(),
    resetCredAccessKey: (0, _emberConcurrency.task)(function* (changeCredAccessKeyRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.resetCredAccessKey'), changeCredAccessKeyRequest, true);
    }).drop(),
    agreeNzTc: (0, _emberConcurrency.task)(function* (nzUserAgreeToTcRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.agreeNzTc'), nzUserAgreeToTcRequest);
    }).drop()
  });
  _exports.default = _default;
});