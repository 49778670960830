define("esis-ui/components/shared/radiobutton-control/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    title: 'Label not set',
    value: null,
    property: null,
    disabled: false,
    onChange: function onChange() {},
    checked: Ember.computed('property', 'value', function () {
      return this.get('property') === this.get('value');
    }),
    actions: {
      changed: function changed() {
        this.set('property', this.get('value'));
        if (Ember.isPresent(this.get('onChange'))) {
          this.get('onChange')(this.get('value'));
        }
      }
    }
  });
  _exports.default = _default;
});