define("esis-ui/components/areas/report/au/view/registration-count-by-entity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JfFlxyTB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-group mt-3\"],[8],[0,\"\\n  \"],[1,[28,\"shared/buttons/action-button\",null,[[\"title\",\"type\",\"class\",\"onClick\"],[\"Download Excel Extract\",\"primary\",\"shortDownloadBtn\",[28,\"action\",[[23,0,[]],\"_downloadReport\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/report/au/view/registration-count-by-entity/template.hbs"
    }
  });
  _exports.default = _default;
});