define("esis-ui/components/areas/spg/au/view/au-spg-view/component", ["exports", "esis-ui/components/areas/spg/au/au-spg-view-page-base", "ember-concurrency", "lodash"], function (_exports, _auSpgViewPageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSpgViewPageBase.default.extend({
    //region INITIALIZATION ---
    model: null,
    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _isDelegate: Ember.computed('model', function () {
      const constants = this.get('constants');
      return this.get('model.status') === constants.statusValue.delegate;
    }),
    // 19 is status of delegate
    _hasSecuredParties: Ember.computed('model.securedParties', function () {
      return !(0, _lodash.isEmpty)(this.get('model.securedParties'));
    }),
    _showAmendBtn: Ember.computed.alias('model.canAmend'),
    _showEditBtn: Ember.computed('_showAmendBtn', function () {
      return !this.get('_showAmendBtn') && this.get('app.credential.hasCreateSpgPermissionAu');
    }),
    _showSaveBtn: Ember.computed('_showAmendBtn', 'isDirty', function () {
      return !this.get('_showAmendBtn') && this.get('isDirty');
    }),
    _showSubmitBtn: Ember.computed('_showAmendBtn', function () {
      const constants = this.get('constants');
      return !this.get('_showAmendBtn') && this.get('model.status') !== constants.statusValue.delegate;
    }),
    //endregion

    //region METHODS
    _createSpgModel(response) {
      this.set('model', this.mapJsonObjectToModel(response, 'secured-party-group'));
      if (!(0, _lodash.isEmpty)(response.addressForServiceMailingAddress)) {
        let addressModel = this.mapJsonObjectToModel(response.addressForServiceMailingAddress, 'address');
        this.set('model.addressForServiceMailingAddress', addressModel);
      } else {
        this.set('model.addressForServiceMailingAddress', this.createModel('address'));
      }
      if (!(0, _lodash.isEmpty)(response.addressForServicePhysicalAddress)) {
        let addressModel = this.mapJsonObjectToModel(response.addressForServicePhysicalAddress, 'address');
        this.set('model.addressForServicePhysicalAddress', addressModel);
      } else {
        this.set('model.addressForServicePhysicalAddress', this.createModel('address'));
      }
      this.set('model.securedParties', []);
      if (!(0, _lodash.isEmpty)(response.securedParties)) {
        (0, _lodash.forEach)(response.securedParties, item => {
          let sp = this.mapJsonObjectToModel(item, 'secured-party');
          if (!(0, _lodash.isEmpty)(item.mailingAddress)) {
            sp.set('mailingAddress', this.mapJsonObjectToModel(item.mailingAddress, 'address'));
          }
          sp.get('mailingAddress').save();
          sp.save();
          this.get('model.securedParties').pushObject(sp);
        });
      }
      this.get('model.addressForServiceMailingAddress').save();
      this.get('model.addressForServicePhysicalAddress').save();
      this.get('model').save();
    },
    //endregion

    //region TASKS
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('spgRepo.loadSpgModel').perform(this.get('tabModel.p0'));
        this._createSpgModel(response);
        this.updateTabProperty('title', 'SPG(' + this.get('model.esisId') + ')');
        // this.updateTabProperty('iconName', this.getTabIconName(this.get('model.status')));
      } catch (error) {
        this.get('logger').logError('au-spg-view._loadModel', error);
      }
    }),
    _convertToDelegate: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('convertToDelegate').perform(this.get('model.guid'));
        this.removeTab();
      } catch (error) {
        this.get('logger').logError('au-spg-view._convertToDelegate', error);
      }
    }),
    _amendTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('spgRepo.retrieveSpg').perform(this.get('model.guid'), false);
        this.notifySuccess('Secured party group synchronised from PPSR');
        let result = yield this.get('spgRepo.loadSpgModel').perform(this.get('model.guid'));
        this.set('model', null);
        this._createSpgModel(result);
        this.set('model.canAmend', false);
      } catch (error) {
        this.notifyError('Fail to synchronise secured party group from PPSR');
        this.get('logger').logError('au-spg-view._amendSpg', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onRefreshData() {
        return this._loadModel.perform();
      },
      _convert: function _convert() {
        this.get('_convertToDelegateDlg').show();
      },
      _onConfirmConvertToDelegate: function _onConfirmConvertToDelegate() {
        this.get('_convertToDelegate').perform();
      },
      _amend: function _amend() {
        this.get('_confirmAmendDlg').show();
      },
      _editPPSRAccessCode: function _editPPSRAccessCode() {
        this.get('_editPPSRAccessCodeDlg').show(this.get('model'));
      },
      _onAmendConfirmation: function _onAmendConfirmation() {
        this.get('_amendTask').perform();
      },
      _transfer: function _transfer() {
        this.get('_transferDlg').show();
      },
      _onSaveSpg: function _onSaveSpg() {
        let submitSpgTask = this.get('validateAndSaveAndSubmitSpg');
        if (this.get('_isDelegate')) {
          submitSpgTask = this.get('saveSpg');
        }
        let timeoutTask = this.get('runTimeout');
        let timeoutTaskInstance = timeoutTask.perform('saveSpgTaskInstance');
        this.set('saveTimeoutTaskInstance', timeoutTaskInstance);
        let saveSpgTaskInstance = submitSpgTask.perform(this.get('model'), false);
        this.set('saveSpgTaskInstance', saveSpgTaskInstance);
        this.get('model').save();
      },
      _onSubmitSpg: function _onSubmitSpg() {
        let submitSpgTask = this.get('validateAndSaveAndSubmitSpg');
        let timeoutTask = this.get('runTimeout');
        let timeoutTaskInstance = timeoutTask.perform('saveSpgTaskInstance');
        this.set('saveTimeoutTaskInstance', timeoutTaskInstance);
        let saveSpgTaskInstance = submitSpgTask.perform(this.get('model'), true);
        this.set('saveSpgTaskInstance', saveSpgTaskInstance);
      },
      _editSecuredPartyGroup: function _editSecuredPartyGroup() {
        if (this.get('_isDelegate')) {
          this.get('_delegateDlg').show(this.get('model'));
        } else {
          this.get('_spgDlg').show(this.get('model'));
        }
      },
      _onConfirmEditDelegate: function _onConfirmEditDelegate(model) {
        this.mapOnlySourceProps(model, this.get('model'));
      },
      _onConfirmEditSpg: function _onConfirmEditSpg(model) {
        this.mapOnlySourceProps(model, this.get('model'));
      },
      _onSpEditClick: function _onSpEditClick(item) {
        this.get('_spDlg').show(item);
      },
      _onConfirmEditSp: function _onConfirmEditSp(model) {
        this.mapOnlySourceProps(model, (0, _lodash.find)(this.get('model.securedParties'), ['id', model.id]));
      },
      _onConfirmEditPPSRAccessCode: function _onConfirmEditPPSRAccessCode() {
        this._loadModel.perform();
      },
      _onCopySpg() {
        this.get('_copySpgDlg').show(this.get('model'));
      },
      _onConfirmCopySpg() {
        this.addTab('spgListAu', null, null, true, {
          view: 'review'
        });
      }
    }
  });
  _exports.default = _default;
});