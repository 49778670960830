define("esis-ui/components/areas/shell/concurrency-error-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ik0o8p4R",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"registerAs\",\"keyboard\",\"modalTitle\",\"name\",\"size\",\"confirmBtnLabel\",\"confirmButton\",\"onConfirm\",\"isTopGeneral\"],[[24,[\"_dialog\"]],false,\"Concurrency Error\",\"concurrencyError\",\"lg\",\"OK\",true,[28,\"action\",[[23,0,[]],\"_onConfirm\"],null],true]],{\"statements\":[[0,\" \"],[7,\"div\",true],[10,\"class\",\"\"],[8],[0,\"\\n   \"],[1,[22,\"_message\"],false],[0,\"\\n \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shell/concurrency-error-modal/template.hbs"
    }
  });
  _exports.default = _default;
});