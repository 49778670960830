define("esis-ui/components/areas/admin/abn-name-lookup-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q5JdnDyd",
    "block": "{\"symbols\":[\"businessEntity\"],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"registerAs\",\"confirmBtnLabel\",\"name\",\"modalTitle\",\"closeButton\",\"cancelBtnLabel\",\"onConfirm\"],[[24,[\"_dialog\"]],\"OK\",\"zbnNameLookup\",\"ABN Name Lookup\",true,\"Cancel\",[28,\"action\",[[23,0,[]],\"_onConfirm\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"Please select a business entity\"],[9],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"selected\",\"allowClear\",\"searchEnabled\",\"searchField\",\"triggerClass\",\"renderInPlace\",\"onChange\"],[[24,[\"credential\",\"businessEntityPicklist\"]],[24,[\"_selectedBusinessEntity\"]],false,true,\"title\",\"powerSelectDropdown\",true,[28,\"action\",[[23,0,[]],\"_chooseBusinessEntity\"],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"item\",\"esisId\"]],false],[0,\" - \"],[1,[23,1,[\"title\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/admin/abn-name-lookup-modal/template.hbs"
    }
  });
  _exports.default = _default;
});