define("esis-ui/helpers/camelize-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelizeString = camelizeString;
  _exports.default = void 0;
  function camelizeString([string]) {
    if (Ember.isBlank(string)) {
      return '';
    }
    return Ember.String.camelize(Ember.String.decamelize(string));
  }
  var _default = Ember.Helper.helper(camelizeString);
  _exports.default = _default;
});