define("esis-ui/repositories/home-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    loadRegistrationStatistics: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadRegistrationStatistics'), entityGuid));
    }),
    loadNzFsStatistics: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzFsStatistics'), entityGuid));
    }),
    updateLastAccessEntity: (0, _emberConcurrency.task)(function* (request) {
      return yield this.apiPost(this.get('constants.apiEndpoint.updateLastAccessEntity'), request, true);
    })
  });
  _exports.default = _default;
});