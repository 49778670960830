define("esis-ui/components/areas/spg/nz/list/nz-spg-list/component", ["exports", "esis-ui/components/areas/spg/nz/nz-spg-view-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _nzSpgViewPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSpgViewPageBase.default.extend({
    //region properties
    _spgGrid: null,
    _selectedSpg: null,
    _spgGridContextMenu: null,
    _selectedBusinessEntity: null,
    //region Preview Tab Ids
    _refPanel: 'refPanel',
    //endregion

    //region init component
    init() {
      this._super(...arguments);

      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._viewFilterOptions = [{
        title: 'Draft',
        value: 'draft'
      }, {
        title: 'Registered',
        value: 'registered'
      }, {
        title: 'All',
        value: 'all'
      }];
      this._view = 'all';
      this._loadPicklistMethod();
      //region SPG GRID
      this.set('_gridOption', {
        height: '100%',
        dataSource: {
          schema: {
            data: _data => {
              for (let i = 0; i < _data.length; i++) {
                _data[i].disableActionStates = [!this.get('credential.hasCreateSpgPermissionNz')];
              }
              return _data;
            }
          },
          pageSize: 30
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "35px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "ppsrSpgNumber",
          title: "SPG ID",
          filterable: false,
          width: "30%",
          template: '<a data-id="#:guid#" data-esis="#: esisId#" data-spgid="#: ppsrSpgNumber#" class="grid-link-id">#: ppsrSpgNumber #</a>'
        }, {
          field: "name",
          title: "SPG Name",
          filterable: false,
          width: "70%"
        }, {
          field: 'entityTitle',
          title: 'Business Entity',
          filterable: false,
          width: "200px"
        }]
      });
      this._transferActionId = 'transferNzSpgId';
      this._spgGridContextMenu = [{
        title: 'Transfer Financing Statements',
        id: this.get('_transferActionId'),
        icon: 'file-export',
        iconClass: 'defaultCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_spgGrid'))) {
          if (reset === true) {
            this.set('_selectedSpg', null);
            this.set('_spgPreview', null);
          }
          this._loadSpgList.perform();
          this.set('_refreshNotification', false);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region when tab activate
    onScreenChange: function onScreenChange() {
      this._refreshGrid(); // soft refresh
    },

    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _loadSpgList: (0, _emberConcurrency.task)(function* () {
      try {
        let _filterModel = {
          viewName: this.get('_view'),
          esisEntityGuid: this.get('_selectedBusinessEntity.value')
        };
        let response = yield this.get('spgRepo.loadNzSpgList').linked().perform(_filterModel);
        (0, _emberLifeline.runTask)(this, () => {
          if (Ember.isPresent(this.get('_spgGrid'))) {
            this.get('_spgGrid').loadData(response);
          }
        });
      } catch (error) {
        this.get('logger').logError('nz-spg-list._loadSpgList', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedSpg')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedSpg', data);
        if (Ember.isPresent(this.get('_tabPreview'))) {
          (0, _emberLifeline.runTask)(this, () => {
            this.get('_tabPreview').activateTab(this.get('_refPanel'));
          }, 50);
        }
      },
      _onLinkClick: function _onLinkClick(element) {
        let guid = element.data('id');
        let esisId = element.data('esis');
        let spgId = element.data('spgid');
        this.addTab('spgReviewNz', guid, null, true, {
          esisId: esisId,
          spgId: spgId
        });
      },
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedSpg', selectedRow);
          if (selectedOption === this.get('_transferActionId')) {
            this.get('_transferDlg').show();
          }
        }
      },
      _onConfirmSyncToPPSR: function _onConfirmSyncToPPSR() {
        this.get('_syncToPPSR').perform();
      },
      _onDelete: function _onDelete() {
        this.get('_deleteSpg').perform();
      },
      //endregion

      //region FILTER ACTIONS
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      },
      //endregion

      //region BUTTON ACTIONS
      _onAddSpgClick() {
        this.addTab("spgWizardNz", null, this.get('_selectedBusinessEntity.value'));
      },
      _onImportSpgClick() {
        this.get('_importSpgModal').show(this.get('_selectedBusinessEntity.value'));
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});