define("esis-ui/models/change-password-request", ["exports", "esis-ui/utils/model-base", "lodash", "esis-ui/models/request-base"], function (_exports, _modelBase, _lodash, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    username: (0, _modelBase.attr)(),
    oldCredAccessKey: (0, _modelBase.attr)(),
    newCredAccessKey: (0, _modelBase.attr)(),
    confirmCredAccessKey: (0, _modelBase.attr)(),
    credAccessKeyError: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        newCredAccessKey: {
          custom: {
            validation: function validation(key, value, model) {
              if ((0, _lodash.isNil)(model.get('id'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'can\'t be blank'
          }
        },
        confirmCredAccessKey: {
          custom: {
            validation: function validation(key, value, model) {
              if ((0, _lodash.isNil)(model.get('id'))) {
                return Ember.isEqual(model.get('newCredAccessKey'), value);
              }
              return true;
            },
            message: 'Passwords do not match'
          }
        }
      };
    }
  });
  _exports.default = _default;
});