define("esis-ui/components/shared/ember-chart-js/component", ["exports", "lodash", "ember-cli-chart/components/ember-chart", "ember-lifeline"], function (_exports, _lodash, _emberChart, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberChart.default.extend({
    //--- INITIALIZATION ---

    type: 'bar',
    height: 500,
    width: 1500,
    registerAs: null,
    init() {
      this._super(...arguments);
      this._defaultOptions = {
        maintainAspectRatio: true,
        // responsive: false,
        title: {
          // fontColor: '#ff0000',
        },
        legend: {
          labels: {
            // This more specific font property overrides the global property
            //fontColor: 'black'
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            fontColor: '#fff'
          }]
        }
      };

      // override
      this._mergeData();
      (0, _emberLifeline.runTask)(this, '_registerSelfAs');
      //** To be implemented **//
      // this.get('event').on(this.get('constants.events.tabResize'), this, 'resize');
      // this.get('event').on(this.get('constants.events.splitterMoved'), this, 'resize');
    },

    willDestroyElement() {
      (0, _emberLifeline.runDisposables)(this); // ensure that lifeline will clean up any remaining async work
      this._super(...arguments);
    },
    //region METHODS
    refreshChart: function refreshChart() {
      (0, _emberLifeline.runTask)(this, () => {
        this._mergeData();
        this.updateChart();
      });
    },
    _registerSelfAs: function _registerSelfAs() {
      this.set('registerAs', this);
    },
    _mergeData: function _mergeData() {
      // merge defaults
      (0, _lodash.merge)(this.get('data'), this.get('_defaultData'));
      (0, _lodash.merge)(this.get('options'), this.get('_defaultOptions'));
    }
    //endregion
  });
  _exports.default = _default;
});