define("esis-ui/components/areas/registration/au/view/au-grantor-add-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    modalTitle: 'Add Grantor',
    onAdd: function onAdd() {},
    onUpdate: function onUpdate() {},
    _createGrantorModel(grantor) {
      if (!(0, _lodash.isEmpty)(grantor)) {
        let grantorModel = this.mapObject(grantor, this.createModel('grantor'));
        grantorModel.set('indexId', grantor.get('indexId'));
        grantorModel.set('isConfirmed', grantor.get('isConfirmed'));
        this.set('_grantorModel', grantorModel);
      } else {
        const grantorModel = this.mapJsonObjectToModel({
          grantorType: this.get('constants.grantorType.orgLookup'),
          isConfirmed: false,
          action: 1 // add action
        }, 'grantor');
        if (this.get('model.isConsumerCollateral')) {
          grantorModel.set('grantorType', this.get('constants.grantorType.individual'));
        }
        this.set('_grantorModel', grantorModel);
      }
    },
    _reset() {
      this.setProperties({
        _grantorModel: null
      });
    },
    show(grantor) {
      this._reset();
      this._createGrantorModel(grantor);
      this.get('_dialog').show();
    },
    actions: {
      _addGrantor(bypassValidation, grantorReference) {
        const grantor = this.get('_grantorModel');
        if (Ember.isPresent(bypassValidation)) {
          grantor.set('bypassValidation', bypassValidation);
        }
        if (Ember.isPresent(grantorReference)) {
          grantor.set('grantorReference', grantorReference);
        }
        if (grantor.grantorType === this.get('constants.grantorType.orgLookup')) {
          grantor.set('grantorType', this.get('constants.grantorType.organisation'));
        }
        let valid = grantor.validate();
        if (valid) {
          grantor.set('isConfirmed', true);
          this.get('onAdd')(grantor);
          this.get('_dialog').hide();
        }
      },
      _updateGrantor() {
        if (this.get('_grantorModel').validate()) {
          this.get('onUpdate')(this.get('_grantorModel'));
          this.get('_dialog').hide();
        }
      },
      _onCancel() {
        this.get('_dialog').hide();
      },
      _onVerified(result) {
        this.get('_grantorModel').setProperties({
          organisationName: result.organisationName,
          organisationEntityTypeDescription: result.entityTypeDescription,
          abnStatus: result.abnStatus,
          acnStatus: result.abnStatus,
          abnIsCurrent: result.abnStatus,
          acnIsCurrent: result.abnStatus,
          organisationNumber: result.preferredOrganisationNumber,
          organisationNumberType: result.preferredOrganisationNumberType
        });
        this.get('_grantorEdit').setOrgNumberTypeTitle(result.preferredOrganisationNumberType);
      }
    }
  });
  _exports.default = _default;
});