define("esis-ui/components/shared/ember-bootstrap/static-tab/ordered-pane/component", ["exports", "ember-bootstrap/components/bs-tab/pane"], function (_exports, _pane) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pane.default.extend({
    /**
     * The order for this tab pane. This is used by the `bs-tab` component to automatically generate
     * the tab navigation.
     *
     * @property order
     * @type number
     * @default null
     * @public
     */
    order: 0
  });
  _exports.default = _default;
});