define("esis-ui/models/nz-collateral-profile-default", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    description: (0, _modelBase.attr)(),
    collateralTypeId: (0, _modelBase.attr)(),
    collateralTypeDescription: (0, _modelBase.attr)(),
    collateralItemDescription: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        collateralTypeId: {
          presence: true
        },
        collateralTypeDescription: {
          custom: {
            validation: (key, value) => {
              const constants = this.get('constants');
              if (this.get('collateralTypeId') === constants.nzFsCollateralTypes.ls || this.get('collateralTypeId') === constants.nzFsCollateralTypes.crops || this.get('collateralTypeId') === constants.nzFsCollateralTypes.oth || this.get('collateralTypeId') === constants.nzFsCollateralTypes.dt || this.get('collateralTypeId') === constants.nzFsCollateralTypes.cp || this.get('collateralTypeId') === constants.nzFsCollateralTypes.is || this.get('collateralTypeId') === constants.nzFsCollateralTypes.ni || this.get('collateralTypeId') === constants.nzFsCollateralTypes.cash || this.get('collateralTypeId') === constants.nzFsCollateralTypes.int || this.get('collateralTypeId') === constants.nzFsCollateralTypes.aappe) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Collateral type description is required"
          }
        }
      };
    }
  });
  _exports.default = _default;
});