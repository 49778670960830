define("esis-ui/repositories/search-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    //region AU
    loadSearchDigest: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.searchDetailDigest'), guid));
    }),
    loadSearchFees: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSearchFees'), guid));
    }),
    loadSearch: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSearch'), guid));
    }),
    loadSearchResultDigest: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.searchResultDetailDigest'), guid));
    }),
    loadGrantorSearchResultFilters: (0, _emberConcurrency.task)(function* (id) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.grantorSearchResultFilters'), id));
    }),
    loadSearchRetrievalProgress: (0, _emberConcurrency.task)(function* (id) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.searchRetrievalProgress'), id));
    }),
    loadSearchCertificateRetrievalProgress: (0, _emberConcurrency.task)(function* (id) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.searchCertificateRetrievalProgress'), id));
    }),
    submitSearch: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.submitSearch'), guid));
    }).drop(),
    resubmitSearchByEntity: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.resubmitSearchByEntity'), guid));
    }).drop(),
    resubmitMySearches: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.get('constants.apiEndpoint.resubmitMySearches'));
    }).drop(),
    resubmitSearch: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.resubmitSearch'), guid));
    }).drop(),
    deleteMySearches: (0, _emberConcurrency.task)(function* (viewName) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteMySearches'), viewName));
    }).drop(),
    deleteSearchesByEntity: (0, _emberConcurrency.task)(function* (entityGuid, viewName) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteSearchByEntity'), entityGuid, viewName));
    }).drop(),
    deleteSearch: (0, _emberConcurrency.task)(function* (searchHeaderGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteSearch'), searchHeaderGuid));
    }).drop(),
    saveAndSubmitSearch: (0, _emberConcurrency.task)(function* (searchHeaderRequest) {
      let copy = searchHeaderRequest.clone();
      copy.id = searchHeaderRequest.get('id');
      if (copy.searches && copy.searches.length > 0) {
        copy.searches = copy.searches.map(function (item) {
          let param = item.clone();
          param.id = item.get('id');
          return param;
        });
      }
      return yield this.apiPost(this.get('constants.apiEndpoint.saveAndSubmitSearch'), copy);
    }).drop(),
    createSearchAccountingRecord: (0, _emberConcurrency.task)(function* (searchHeaderGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.createSearchAccountingRecord'), searchHeaderGuid));
    }).drop(),
    //region Download, Print, Certificate
    downloadSearchSummaryReport: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.previewFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchSummaryReport'), guid));
    }),
    downloadSearchExtractReport: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchExtractReport'), guid));
    }),
    downloadSearchCertificateById: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.previewFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchCertificateById'), guid));
    }),
    downloadSearchCertificate: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.previewFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchCertificate'), guid));
    }),
    downloadSearchCertificateBulk: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchCertificateBulk'), guid));
    }),
    downloadSearchCertAttachment: (0, _emberConcurrency.task)(function* (id) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadSearchCertAttachment'), id));
    }),
    requestSearchCertificateBulk: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.requestSearchCertificateBulk'), guid));
    }).drop(),
    requestSearchCertificate: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.requestSearchCertificate'), guid));
    }).drop(),
    //endregion
    //endregion

    //region NZ
    submitSearchNzDebtorOrganisation: (0, _emberConcurrency.task)(function* (nzDebtorOrganisationSearchRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitSearchNzDebtorOrganisation'), nzDebtorOrganisationSearchRequest.clone());
    }).drop(),
    submitSearchNzDebtorPerson: (0, _emberConcurrency.task)(function* (nzDebtorPersonSearchRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitSearchNzDebtorPerson'), nzDebtorPersonSearchRequest.clone());
    }).drop(),
    submitSearchNzRegistrationNumber: (0, _emberConcurrency.task)(function* (nzRegistrationNumberSearchRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitSearchNzRegistrationNumber'), nzRegistrationNumberSearchRequest.clone(), true);
    }).drop(),
    submitSearchNzMV: (0, _emberConcurrency.task)(function* (zMvSearchRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitSearchNzMV'), zMvSearchRequest.clone());
    }).drop()
    // loadNzSearchResult: task(function* (nzSearchResultRequest) {
    //     return yield this.apiPost(this.get('constants.apiEndpoint.loadNzSearchResult'), nzSearchResultRequest);
    // }),
    //endregion
  });
  _exports.default = _default;
});