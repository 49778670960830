define("esis-ui/components/areas/registration/au/view/au-registration-expiry-options/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    renewRequest: null,
    init() {
      this._super(...arguments);
      this._name = `renewRegTimeOption-${this.newGuid()}`;
    },
    _setExpiryMessageTask: (0, _emberConcurrency.task)(function* () {
      if (Ember.isPresent(this.get('renewRequest.customDate'))) {
        const endTime = this.get('renewRequest.customDate');
        if (endTime == null) {
          this.set('_expiryDateMessage', 'End Time Not Stated');
        }
        if (Ember.isPresent(endTime)) {
          let model = {
            "endDate": endTime,
            "startDate": null
          };
          try {
            let message = yield this.get('registrationRepo.loadExpiryMessage').perform(model);
            this.set('_expiryDateMessage', message);
          } catch (error) {
            this.get('logger').logError('au-registration-expiry-options._setExpiryDateMessage', error);
          }
        }
      } else {
        this.set('_expiryDateMessage', 'not stated');
      }
    }),
    actions: {
      _onRenewRegTimeOptionChange(value) {
        this.set('renewRequest.option', value);
        if (value !== this.get('constants.renewRegTimeOption.custom')) {
          this.get('_datePicker').setDateOnlyInCalendar(null);
          this.set('renewRequest.customDate', null);
          this.set('_expiryDateMessage', '');
        }
      },
      _onExpiryDateChanged: function _onExpiryDateChanged(value) {
        this.set('renewRequest.customDate', value);
        this.get('_setExpiryMessageTask').perform();

        //set renew option to custom
        this.set('renewRequest.option', this.get('constants.renewRegTimeOption.custom'));
      }
    }
  });
  _exports.default = _default;
});