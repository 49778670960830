define("esis-ui/components/shared/checkbox-group-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "e1r0iubC",
    "block": "{\"symbols\":[\"checkBoxItem\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"checkboxGroupClass\"]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"shared/checkbox-control\",null,[[\"title\",\"property\"],[[23,1,[\"title\"]],[23,1,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/checkbox-group-control/template.hbs"
    }
  });
  _exports.default = _default;
});