define("esis-ui/components/shared/ember-bootstrap/popover/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xYreXuUb",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"bs-popover\",null,[[\"placement\",\"triggerElement\"],[\"bottom\",\"parentView\"]],{\"statements\":[[1,[22,\"text\"],false]],\"parameters\":[]},null],[0,\"\\nHello\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/popover/template.hbs"
    }
  });
  _exports.default = _default;
});