define("esis-ui/routes/home", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-concurrency", "esis-ui/mixins/esis-utils"], function (_exports, _authenticatedRouteMixin, _emberConcurrency, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, _esisUtils.default, {
    beforeModel() {
      if (this.isAuthenticated()) {
        this.get('_load').perform();
      }
      this._super(...arguments);
    },
    _load: (0, _emberConcurrency.task)(function* () {
      try {
        const userDigest = yield this.get('accountRepo.loadProfile').perform();
        if (userDigest.defaultRegionId === this.get('constants.region.nz')) {
          this.transitionTo('nz');
        } else {
          this.transitionTo('au');
        }
      } catch (e) {
        this.get('logger').logError('route-home._load', e);
        this.notifyError(`Load user profile failed with reason : ${e}`);
        this.get('session').invalidate();
      }
    }),
    actions: {
      error(error) {
        // Manage your errors
        Ember.onerror(error);
        // substate implementation when returning `true`
        return true;
      }
    }
  });
  _exports.default = _default;
});