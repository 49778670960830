define("esis-ui/utils/auto-register-instance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const registerAsInstanceWithApplication = function registerAsInstanceWithApplication(dirName, application) {
    const directoryRegExp = new RegExp("^" + application.name + "/" + dirName);
    const require = window.require;
    Object.keys(require.entries).filter(function (key) {
      return directoryRegExp.test(key);
    }).forEach(function (moduleName) {
      let module = require(moduleName, null, null, true);
      // eslint-disable-next-line
      let fileName = moduleName.match(/[^\/]+\/?$/)[0];
      if (!module || !module["default"] ||
      // eslint-disable-next-line ember/new-module-imports
      !(module["default"].prototype instanceof Ember.Object)) {
        //console.log(dirName + "/" + fileName + ".js did not have an Ember.Object as the default export.");
        throw new Error(moduleName + " must export a default to be registered with application.");
      }
      application.register(dirName + ":" + fileName, module["default"], {
        singleton: false
      });
    });
  };
  var _default = registerAsInstanceWithApplication;
  _exports.default = _default;
});