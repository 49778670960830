define("esis-ui/helpers/display-date-with-month-abbr", ["exports", "esis-ui/mixins/esis-utils"], function (_exports, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend(_esisUtils.default, {
    compute([value]) {
      if (Ember.isPresent(value)) {
        const result = this.dateWithMonthAbbr(value);
        return result;
      }
      return '';
    }
  });
  _exports.default = _default;
});