define("esis-ui/components/areas/shared/email-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ss+uW8V9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"listGridStyle\"],[8],[0,\"\\n  \"],[1,[28,\"shared/kendo/grid/grid-local-ds\",null,[[\"options\",\"registerAs\",\"onSelectionChange\",\"autoFocus\"],[[24,[\"_msgGridOption\"]],[24,[\"_messageGrid\"]],[28,\"action\",[[23,0,[]],\"_onSelectionChange\"],null],[24,[\"emailGridAutoFocus\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n  \"],[1,[28,\"areas/shared/email-preview-display\",null,[[\"registerAs\",\"sessionModel\"],[[24,[\"_messagePreview\"]],[24,[\"sessionModel\"]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/email-display/template.hbs"
    }
  });
  _exports.default = _default;
});