define("esis-ui/components/areas/admin/category/au/list/category-list/component", ["exports", "esis-ui/components/areas/shared/page-base", "lodash", "ember-concurrency", "ember-lifeline"], function (_exports, _pageBase, _lodash, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    //region INITIALIZATION
    _categoryGrid: null,
    _selectedCategory: null,
    _selectedBusinessEntity: null,
    init() {
      this._super(...arguments);
      const app = this.get('app');
      this._loadPicklistMethod();

      //region CATEGORY GRID
      this.set('_gridOption', {
        autoBind: false,
        height: 600,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('categories')
            },
            parameterMap: (options, operation) => {
              options.businessEntityGuid = this.get('_selectedBusinessEntity.value');
              let keyword = this.get('_filterCriteria');
              if (!(0, _lodash.isNil)(keyword)) {
                options.filterKeyword = keyword;
              }
              options._ = app.guid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                realData[i].disableActionStates = [!realData[i].canDelete];
              }
              return realData;
            }
          },
          sort: [{
            field: "id",
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "35px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "categoryCode",
          title: 'CategoryCode',
          filterable: false,
          width: "200px",
          template: '<a href="\\#" data-id="#:guid#" class="grid-link-id">#: categoryCode #</a>'
        }, {
          field: "description",
          title: 'Description',
          filterable: false
        }, {
          field: 'entityTitle',
          title: 'Business Entity',
          filterable: false,
          width: "200px"
        }]
      });
      //endregion

      //Context Menu Action Ids
      this._deleteActionId = 'deleteCategoryId';
      this._categoryGridContextMenu = [{
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _loadCategory: (0, _emberConcurrency.task)(function* (categoryGuid) {
      try {
        let result = yield this.get('adminRepo.loadCategory').perform(categoryGuid);
        this.get('_categoryEditDlg').show(result);
      } catch (error) {
        this.get('logger').logError('category-list._loadCategory', error);
      }
    }),
    _deleteCategory: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('logger').logDebug('Deleting _deleteCategory', this.get('_selectedCategory.guid'));
        yield this.get('adminRepo.deleteCategory').perform(this.get('_selectedCategory.guid'));
        this.notifySuccess(`Category ${this.get('_selectedCategory.categoryCode')} has been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('category-list._deleteCategory', error);
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid() {
      this.set('_selectedCategory', null);
      this.get('_categoryGrid').refreshGrid(true);
    },
    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if (!(0, _lodash.isEmpty)(this.get('_selectedCategory')) && !(0, _lodash.isEmpty)(data)) {
          this.set('_selectedCategory', data);
        }
      },
      _onLinkClick(selectedItem) {
        const guid = selectedItem.data('id');
        this.set('_modalTitle', "Edit Category");
        this.set('_successMsg', "Category updated successfully");
        this.get('_loadCategory').perform(guid);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedCategory', selectedRow);
          if (selectedOption === this.get('_deleteActionId')) {
            if (!(0, _lodash.isNil)(selectedRow.id)) {
              this.set('_deleteConfirmMessage', `Are you sure you want to delete category ${selectedRow.categoryCode}? This action is not reversible.`);
              this.get('_deleteConfirmDlg').show();
            }
          }
        }
      },
      //region FILTER ACTIONS
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this._refreshGrid(true);
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      },
      //endregion

      //region BUTTON ACTIONS
      _onRefreshData() {
        this._refreshGrid();
      },
      _onAdd() {
        this.set('_modalTitle', "New Category");
        this.set('_successMsg', "Category created successfully");
        this.get('_categoryEditDlg').show({
          entityGuid: this.get('_selectedBusinessEntity.value')
        });
      },
      _onConfirmEditCategory() {
        this._refreshGrid(true);
      },
      _onConfirmDelete: function _onConfirmDelete() {
        this.get('_deleteCategory').perform();
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});