define("esis-ui/components/areas/admin/security-roles/list/preview/security-role-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BDqxxV19",
    "block": "{\"symbols\":[\"staticTab\",\"item\",\"index\",\"permission\"],\"statements\":[[4,\"shared/ember-bootstrap/static-tab/tab-control\",null,[[\"initialTab\",\"screenId\",\"model\",\"registerAs\"],[[24,[\"_permissionPanel\"]],[24,[\"screenId\"]],[24,[\"model\"]],[24,[\"_tabPreview\"]]]],{\"statements\":[[4,\"shared/ember-bootstrap/static-tab/preview-panel\",null,[[\"panelTitle\",\"panelId\",\"parentTab\",\"isLoading\",\"order\"],[\"Permissions\",[24,[\"_permissionPanel\"]],[23,1,[]],[24,[\"_loadPreview\",\"isRunning\"]],1]],{\"statements\":[[4,\"if\",[[28,\"not\",[[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[22,\"shared/preview/preview-no-data\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"row mx-0 mt-2\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"col-12 mb-2\"],[8],[0,\"\\n            \"],[7,\"div\",true],[8],[7,\"strong\",true],[8],[1,[23,2,[\"permissionGroupTitle\"]],false],[9],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"row mx-0\"],[8],[0,\"\\n\"],[4,\"each\",[[23,2,[\"permissions\"]]],null,{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"col-xl-3 col-md-4 col-sm-6 col-xs-12\"],[8],[0,\"\\n                  \"],[1,[23,4,[\"permissionDisplayName\"]],false],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[4]},null],[0,\"            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"not-eq\",[[23,3,[]],[24,[\"_lastIndex\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n              \"],[7,\"hr\",true],[8],[9],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2,3]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/admin/security-roles/list/preview/security-role-preview/template.hbs"
    }
  });
  _exports.default = _default;
});