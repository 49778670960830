define("esis-ui/components/areas/admin/contract-type/nz/list/nz-collateral-profile-list/component", ["exports", "esis-ui/components/areas/shared/nz-page-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _nzPageBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region INITIALIZATION
    _collateralProfileGrid: null,
    _selectedCollateralProfile: null,
    _selectedBusinessEntity: null,
    init: function init() {
      this._super(...arguments);
      const app = this.get('app');
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklistMethod();

      //region SYSTEM USERS GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('nz-contract-types')
            },
            parameterMap: (options, operation) => {
              options.entityGuid = this.get('_selectedBusinessEntity.value');
              options._ = app.guid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                let canDelete = !this.get('credential.hasCreateContractTypePermission');
                realData[i].disableActionStates = [canDelete];
              }
              return realData;
            }
          }
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "28px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "code",
          title: 'Contract Code',
          filterable: false,
          width: "200px",
          template: '<a data-id="#:guid#" class="grid-link-id">#: code #</a>'
        }, {
          field: "description",
          title: 'Description',
          filterable: false
        }, {
          field: 'entityTitle',
          title: 'Business Entity',
          filterable: false,
          width: "200px"
        }]
      });

      //Context Menu Action Ids
      this._deleteActionId = 'nzDeleteCollateralProfileId';
      this._collateralProfileGridCxtMenu = [{
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _loadPreview: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('adminRepo.loadNzCollateralProfileDetailDigest').perform(this.get('_selectedCollateralProfile.guid'));
        this.set('model', result);
      } catch (error) {
        this.get('logger').logError('nz-collateral-profile-list._loadPreview', error);
      }
    }),
    _deleteCollateralProfile: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('logger').logDebug('Deleting collateral profile', this.get('_selectedCollateralProfile.code'));
        yield this.get('adminRepo.deleteNzCollateralProfile').perform(this.get('_selectedCollateralProfile.guid'));
        this.notifySuccess(`Collateral profile ${this.get('_selectedCollateralProfile.code')} has been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.notifyError(error.errorMessage[0]);
        this.get('logger').logError('nz-collateral-profile-list._deleteCollateralProfile', error);
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_collateralProfileGrid'))) {
          if (reset === true) {
            this.set('_selectedCollateralProfile', null);
          }
          this.get('_collateralProfileGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    onScreenChange() {
      this._refreshGrid(true); // soft refresh
    },

    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedCollateralProfile')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedCollateralProfile', data);
        this._loadPreview.perform();
      },
      _onLinkClick(selectedItem) {
        const guid = selectedItem.data('id');
        this.addTab('contractTypeViewNz', guid);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedCollateralProfile', selectedRow);
          if (selectedOption === this.get('_deleteActionId')) {
            if (!(0, _lodash.isNil)(selectedRow.code)) {
              this.get('_deleteInfoDlg').show(`Are you sure you want to delete collateral profile ${selectedRow.code}?`);
            }
          }
        }
      },
      _onConfirmDelete() {
        this.get('_deleteCollateralProfile').perform();
      },
      //region BUTTON ACTIONS
      _onAddCollateralProfileClick() {
        let model = this.mapJsonObjectToModel({
          entityGuid: this.get('_selectedBusinessEntity.value')
        }, 'nz-collateral-profile-request');
        this.get('_collateralProfileModal').show(model);
      },
      //endregion

      //region FILTER ACTIONS
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      },
      //endregion

      _onAddCollateralProfile(newCollateralProfileData) {
        this._refreshGrid(true);
        this.addTab('contractTypeViewNz', newCollateralProfileData.guid);
      }
    }
    //endregion
  });
  _exports.default = _default;
});