define("esis-ui/models/request-base", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelBase.Model.extend({
    id: (0, _modelBase.attr)(),
    guid: (0, _modelBase.attr)(),
    esisEntityGuid: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    hasChange: (0, _modelBase.attr)(),
    validatErrorMessage: null,
    displayException: null,
    showValidationErrorMessage: Ember.computed('validatErrorMessage', function () {
      if (this.get('displayException')) {
        let errorMessage = [];
        if (Ember.isPresent(this.get('validatErrorMessage'))) {
          if (Ember.isArray(this.get('validatErrorMessage'))) {
            errorMessage = this.get('validatErrorMessage');
          } else {
            errorMessage = String(this.get('validatErrorMessage')).split(/[|]/g);
            for (let i = 0; i < errorMessage.length; i++) {
              errorMessage[i] = errorMessage[i].trim();
            }
          }
          return errorMessage;
        }
      }
      return null;
    }),
    statusMessage: Ember.computed('displayException', function () {
      let displayException = this.get('model.displayException');
      if (!Ember.isNone(displayException) && displayException) {
        let statusMessage = String(this.get('model.statusMessage')).split(/[|]/g);
        for (let i = 0; i < statusMessage.length; i++) {
          statusMessage[i] = statusMessage[i].trim();
        }
        return statusMessage;
      }
      return '';
    })
  });
  _exports.default = _default;
});