define("esis-ui/components/shared/buttons/action-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JGMsxveg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"buttonType\",\"disabled\",\"outline\",\"class\",\"type\",\"onClick\",\"title\"],[[24,[\"buttonType\"]],[28,\"or\",[[24,[\"isRunning\"]],[24,[\"disabled\"]]],null],[24,[\"outline\"]],[24,[\"btnClass\"]],[24,[\"type\"]],[28,\"action\",[[23,0,[]],\"_onClick\"],null],[24,[\"toolTipLabel\"]]]],{\"statements\":[[4,\"if\",[[24,[\"labelLeft\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"i\",true],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isRunning\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[\"sync-alt\"],[[\"class\"],[\"smallIcon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"hasIcon\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],[[\"class\",\"title\",\"prefix\"],[[24,[\"classProp\"]],[24,[\"toolTipLabel\"]],[24,[\"prefix\"]]]]],false],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"unless\",[[24,[\"noLabel\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"title\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/buttons/action-button/template.hbs"
    }
  });
  _exports.default = _default;
});