define("esis-ui/components/areas/admin/branch-code/nz/view/nz-edit-branch-code-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INIT
    _selectedBusinessEntity: null,
    _defaultBusinessEntityGuid: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null,
        '_selectedSpg': null,
        '_spgList': null,
        '_selectedBusinessEntity': null
      });
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _selectedSpg: null,
        _spgList: null,
        _selectedBusinessEntity: null,
        _defaultBusinessEntityGuid: null
      });
    },
    show(model) {
      this._reset();
      if (model.entityGuid !== this.emptyGuid()) {
        this.set('_defaultBusinessEntityGuid', model.entityGuid);
      } else {
        this.set('_defaultBusinessEntityGuid', this.get('credential.currentBusinessEntityGuid'));
      }
      this.set('model', this.mapJsonObjectToModel(model, 'branch-code-details-request'));
      this._loadPicklist.perform();
      this.get('model').save();
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _loadPicklist: (0, _emberConcurrency.task)(function* (loadEntityList = true) {
      try {
        if (loadEntityList) {
          //BUSINESS ENTITY
          const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
          this.set('_entities', businessEntityList);
          this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
            'value': this.get('_defaultBusinessEntityGuid')
          }));
        }
        let spgPicklist = yield this.get('picklistRepo.loadNzSpgListFromEntityDown').linked().perform(this.get('_selectedBusinessEntity.value'));
        this.set('_spgList', spgPicklist);
        this.set('_selectedSpg', (0, _lodash.find)(spgPicklist, {
          "value": this.get('model.securedPartyGroupGuid')
        }));
      } catch (error) {
        this.get('logger').logError('nz-edit-branch-code-modal._loadPicklist', error);
      }
    }),
    _saveBranchCode: (0, _emberConcurrency.task)(function* () {
      try {
        if (this.get('model').validate()) {
          let result = yield this.get('adminRepo.saveBranchCode').perform(this.get('model'));
          if (result && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
            this.get('onConfirm')();
            this.get('_dialog').hide();
          }
        }
      } catch (error) {
        this.get('logger').logError('nz-edit-branch-code-modal._saveBranchCode', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.get('_loadPicklist').perform(false);
        this.set('model.securedPartyGroupGuid', null);
      },
      _onChangeSpg(selected) {
        this.set('_selectedSpg', selected);
        this.set('model.securedPartyGroupGuid', selected.value);
      },
      _onConfirm() {
        this.get('_saveBranchCode').perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});