define("esis-ui/components/areas/spg/au/view/au-transfer-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    spgGuid: null,
    init() {
      this._super(...arguments);
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        _selectedBusinessEntity: null
      });
    },
    show(spgGuid) {
      this._reset();
      this._loadPicklists.perform();
      this.set('_spgGuid', spgGuid);
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const entityGuid = this.get('credential.currentBusinessEntity.value');
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').perform(this.get('constants.region.au'));

        // init Client Type data
        this.set('_businessEntityOptions', (0, _lodash.orderBy)(businessEntityList, 'sortOrder'));
        this.set('_selectedBusinessEntity', (0, _lodash.find)(businessEntityList, function (o) {
          return o.item.guid === entityGuid;
        }));
      } catch (error) {
        this.get('logger').logError('au-transfer-modal._loadPicklist', error);
      }
    }),
    _transferSpg: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('spgRepo.transferSpg').perform(this.get('spgGuid'), this.get('_selectedBusinessEntity.item.guid'));
        this.notifySuccess(`Secured Party Group has been transferred`);
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')();
        }
        this.get('_dialog').hide();
      } catch (error) {
        this.get('logger').logError('au-transfer-modal._transferSpg', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region PICKLIST ACTIONS
      _onBusinessEntityChange: function _onBusinessEntityChange(selectedOption) {
        this.set('_selectedBusinessEntity', selectedOption);
      },
      //endregion
      //region BUTTON ACTIONS
      _onConfirm: function _onConfirm() {
        this.get('_transferSpg').perform();
      }
    }
    //endregion
  });
  _exports.default = _default;
});