define("esis-ui/components/shared/ember-bootstrap/modal/tab-restoration-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    message: '',
    bypassMessage: '',
    _bypass: false,
    isShowing: Ember.computed.alias('_dialog.showDialog'),
    _bypassModal() {
      if (this.get('isShowing')) {
        this.setProperties({
          _confirmButton: true,
          _bypass: true
        });
      }
    },
    show() {
      this.get('_dialog').show();
      (0, _emberLifeline.runTask)(this, () => {
        this._bypassModal();
      }, 20 * 1000);
    },
    hide() {
      this.get('_dialog').hide();
    },
    actions: {
      _proceed() {
        this.get('_dialog').hide();
      }
    }
  });
  _exports.default = _default;
});