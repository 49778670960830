define("esis-ui/components/areas/admin/contract-type/nz/view/nz-collateral-profile-view/component", ["exports", "esis-ui/components/areas/shared/nz-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _nzPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region INITIALIZATION ---
    model: null,
    _collateralProfileDefaultGrid: null,
    _selectedCollateralProfileDefault: null,
    init() {
      this._super(...arguments);
      this._loadModel.perform();
      //region CONTRACT TYPES GRID
      this.set('_gridOption', {
        height: 400,
        dataSource: {
          pageSize: 30,
          schema: {
            data: _data => {
              for (let i = 0; i < _data.length; i++) {
                _data[i].guid = this.get('app').guid();
                let canDelete = !this.get('credential.hasCreateContractTypePermission');
                _data[i].disableActionStates = [canDelete];
              }
              return _data;
            }
          }
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "28px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "collateralTypeTitle",
          title: 'Collateral Type',
          filterable: false,
          template: `<a href="\\#" data-id="#:id#" class="# if(${this.get('credential.hasCreateContractTypePermission')}) {# grid-link-id #} #">#: collateralTypeTitle #</a>`
        }, {
          field: "collateralTypeDescription",
          title: 'Default Collateral Type Description',
          filterable: false
        }]
      });

      //Context Menu Action Ids
      this._deleteActionId = 'nzDeleteCollateralId';
      this._collateralProfileDefaultGridContextMenu = [{
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('adminRepo.loadNzCollateralProfile').perform(this.get('tabModel.p0'));
        this._mapResultToModel(result);
      } catch (error) {
        this.get('logger').logError('nz-collateral-profile-view._loadModel', error);
      }
    }),
    _deleteCollateralProfileDefault: (0, _emberConcurrency.task)(function* () {
      try {
        let valid = this.get('model').validate();
        if (!(0, _lodash.isEmpty)(this.get('model.defaults'))) {
          (0, _lodash.forEach)(this.get('model.defaults'), item => {
            valid = valid && item.validate();
          });
        }
        if (valid) {
          let result = yield this.get('adminRepo.saveNzCollateralProfile').perform(this.get('model'));
          this.set('model', null);
          this._mapResultToModel(result);
        }
      } catch (error) {
        this.get('logger').logError('nz-collateral-profile-view.saveCollateralProfile', error);
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid(data) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_collateralProfileDefaultGrid'))) {
          this.get('_collateralProfileDefaultGrid').loadData(data);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(data);
          }, 20);
        }
      });
    },
    _mapResultToModel(result) {
      let collateralProfileModel = this.mapJsonObjectToModel(result, 'nz-collateral-profile-request');
      collateralProfileModel.setProperties({
        defaults: []
      });
      (0, _lodash.forEach)(result.defaults, item => {
        let collateralProfileDefault = this.mapJsonObjectToModel(item, 'nz-collateral-profile-default');
        collateralProfileModel.defaults.pushObject(collateralProfileDefault);
      });
      this.updateTabProperty('title', `Collateral Profile ${collateralProfileModel.code}`);
      this.set('model', collateralProfileModel);
      this.get('model').saveModel();
      this._refreshGrid(result.defaults);
    },
    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedCollateralProfileDefault')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedCollateralProfileDefault', data);
      },
      _onLinkClick(selectedItem) {
        const id = selectedItem.data('id');
        let index = (0, _lodash.findIndex)(this.get('model.defaults'), {
          "id": id
        });
        this.set('_modalTitle', "Edit Collateral Profile Collateral Type");
        this.set('model.selectedIndex', index);
        this.get('_collateralProfileDefaultModal').show(this.get('model'));
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedCollateralProfileDefault', selectedRow);
          if (selectedOption === this.get('_deleteActionId')) {
            if (!(0, _lodash.isNil)(selectedRow.collateralTypeTitle)) {
              this.get('_deleteInfoDlg').show(`Are you sure you want to delete collateral Type ${selectedRow.collateralTypeTitle}?`);
            }
          }
        }
      },
      _onConfirmDelete() {
        const collateralProfileDefaults = this.get('model.defaults');
        collateralProfileDefaults.removeObject((0, _lodash.find)(collateralProfileDefaults, {
          "id": this.get('_selectedCollateralProfileDefault.id')
        }));
        this._deleteCollateralProfileDefault.perform();
      },
      //endregion

      //region BUTTON ACTIONS
      _onClickAddCollateral() {
        this.set('_modalTitle', "New Collateral Profile Collateral Type");
        this.set('model.selectedIndex', null);
        this.get('_collateralProfileDefaultModal').show(this.get('model'));
      },
      _onSaved(result) {
        this.set('model', null);
        this._mapResultToModel(result);
      },
      _onClickEditCollateralProfile() {
        this.get('_collateralProfileModal').show(this.get('model'));
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});