define("esis-ui/components/shared/ember-bootstrap/static-tab/vertical-tab/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "rpJ9EqyE",
    "block": "{\"symbols\":[\"nav\",\"item\",\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"verticalNav d-flex flex-row\"],[8],[0,\"\\n\"],[4,\"bs-nav\",null,[[\"type\",\"class\"],[[24,[\"type\"]],[28,\"if\",[[24,[\"hasButton\"]],\"vertical mr-2\",\"vertical mr-2\"],null]]],{\"statements\":[[4,\"each\",[[24,[\"navItems\"]]],null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"item\"]],\"expected `nav.item` to be a contextual component but found a string. Did you mean `(component nav.item)`? ('esis-ui/components/shared/ember-bootstrap/static-tab/vertical-tab/template.hbs' @ L4:C9) \"],null]],[[\"active\"],[[28,\"bs-eq\",[[23,2,[\"elementId\"]],[24,[\"isActiveId\"]]],null]]],{\"statements\":[[0,\"        \"],[7,\"a\",false],[12,\"href\",[29,[\"#\",[23,2,[\"elementId\"]]]]],[12,\"role\",\"tab\"],[12,\"class\",[28,\"if\",[[28,\"bs-eq\",[[24,[\"isActiveId\"]],[23,2,[\"elementId\"]]],null],\"nav-link active filled\",\"nav-link\"],null]],[3,\"action\",[[23,0,[]],\"select\",[23,2,[\"elementId\"]]]],[8],[0,\"\\n          \"],[7,\"span\",true],[10,\"class\",\"tabTitle\"],[8],[1,[23,2,[\"title\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"tab-content\"],[8],[0,\"\\n    \"],[14,3,[[28,\"hash\",null,[[\"pane\",\"activeId\",\"select\"],[[28,\"component\",[\"shared/ember-bootstrap/static-tab/ordered-pane\"],[[\"parent\",\"activeId\",\"fade\",\"fadeTransition\"],[[23,0,[]],[24,[\"isActiveId\"]],[24,[\"fade\"]],[24,[\"fadeTransition\"]]]]],[24,[\"isActiveId\"]],[28,\"action\",[[23,0,[]],\"select\"],null]]]]]],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/static-tab/vertical-tab/template.hbs"
    }
  });
  _exports.default = _default;
});