define("esis-ui/components/areas/registration/nz/wizard/nz-registration-wizard-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kGHhOWYO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mx-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wizardContainer\"],[8],[0,\"\\n    \"],[1,[28,\"shared/ember-bootstrap/wizard-control\",null,[[\"steps\",\"model\",\"onSubmit\",\"onSave\",\"options\",\"wizardTitle\"],[[24,[\"steps\"]],[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"_submitRegistration\",[24,[\"model\"]]],null],[28,\"action\",[[23,0,[]],\"_saveDraftRegistration\"],null],[24,[\"_options\"]],[24,[\"_wizardTitle\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"areas/shared/waiting-modal\",null,[[\"registerAs\",\"renderInPlace\"],[[24,[\"_waitingModal\"]],false]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/wizard/nz-registration-wizard-container/template.hbs"
    }
  });
  _exports.default = _default;
});