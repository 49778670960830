define("esis-ui/components/shared/ember-bootstrap/static-tab/tab-panel/component", ["exports", "esis-ui/components/shared/ember-bootstrap/static-tab/panel-base", "ember-lifeline"], function (_exports, _panelBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _panelBase.default.extend({
    isLoading: false,
    _cachedDynamicTabId: null,
    /*
    when dynamic tab changed, we may want to refresh the tab showing.
     */
    _rootTabChanged: Ember.observer('app.currentDynamicTabId', function () {
      if (this.get('app.currentDynamicTabId') !== this.get('_cachedDynamicTabId')) {
        this.set('_cachedDynamicTabId', this.get('app.currentDynamicTabId'));
        if (this.get('isVisible') === true) {
          // the tab is the current showing tab, therefore refresh immediate when tab model change
          (0, _emberLifeline.runTask)(this, '_refreshTab');
        }
      }
    })
  });
  _exports.default = _default;
});