define("esis-ui/utils/auto-register-singleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const registerWithApplication = function registerWithApplication(dirName, application) {
    const directoryRegExp = new RegExp("^" + application.name + "/" + dirName);
    const require = window.require;
    Object.keys(require.entries).filter(function (key) {
      return directoryRegExp.test(key);
    }).forEach(function (moduleName) {
      const module = require(moduleName, null, null, true);
      const fileName = moduleName.match(/[^/]+\/?$/)[0];
      if (!module || !module["default"] || !(module["default"].prototype instanceof Ember.Object)) {
        //console.log(dirName + "/" + fileName + ".js did not have an Ember.Object as the default export."); // eslint-disable-line
        throw new Error(moduleName + " must export a default to be registered with application.");
      }
      application.register(dirName + ":" + fileName, module["default"]);
    });
  };
  var _default = registerWithApplication;
  _exports.default = _default;
});