define("esis-ui/components/areas/login/contact-info/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zTukhekF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h3\",true],[10,\"class\",\"horizonTagline\"],[8],[0,\"\\n  Welcome to \"],[1,[24,[\"constants\",\"appName\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"info\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"infoHeader\"],[8],[0,\"Australia Support\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"T : +61 2 9119 5065 ext 3\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"E : edxsupport@equifax.com\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"infoHeader mt-3\"],[8],[0,\"New Zealand Support\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"T : +64 9 558 0037\"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"E : EDXSupportNZ@equifax.com\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/login/contact-info/template.hbs"
    }
  });
  _exports.default = _default;
});