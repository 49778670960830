define("esis-ui/helpers/display-htmlsafe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayHtmlsafe = displayHtmlsafe;
  function displayHtmlsafe([content]) {
    if (Ember.isBlank(content)) {
      return '';
    }
    return Ember.String.htmlSafe(content);
  }
  var _default = Ember.Helper.helper(displayHtmlsafe);
  _exports.default = _default;
});