define("esis-ui/components/areas/shell/nz/nz-index-page/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "ember-lifeline", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _emberLifeline, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    title: 'Home',
    clientAppOutdated: false,
    _versionOutdatedDlg: null,
    _apiServiceStatus: 2,
    // good
    _nzBusinessEntityLoadingDlg: null,
    init() {
      this._super(...arguments);
      this.set('app.region', this.get('constants.region.nz'));
      (0, _emberLifeline.runTask)(this, () => {
        this._loadHomePage();
      }, 300);
      this.get('event').on(this.get('constants.events.notification'), this, '_globalNotificationReceived');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.notification'), this, '_globalNotificationReceived');
      });
      this.get('event').on(this.get('constants.events.appVersionOutdated'), this, '_applicationOutdated');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.appVersionOutdated'), this, '_applicationOutdated');
      });
      this.get('event').on(this.get('constants.events.concurrencyError'), this, '_concurrencyErrorOccurred');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.concurrencyError'), this, '_concurrencyErrorOccurred');
      });
    },
    _addHomPage: (0, _emberConcurrency.task)(function* () {
      try {
        if (Ember.isPresent(this.get('_nzBusinessEntityLoadingDlg'))) {
          this.get('_nzBusinessEntityLoadingDlg').show();
        }
        let businessEntities = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('constants.region.nz'), true, true, true);
        businessEntities = (0, _lodash.sortBy)(businessEntities, ['title']);
        this.get('credential').set('businessEntityPicklist', businessEntities);
        this.selectInitialBusinessEntity();
        this.addTab("homeNz", null, null, false);
      } catch (e) {
        this.get('logger').logError('nz-index-page._load', e);
        if (!(0, _lodash.isEmpty)(this.get('_emptyBEDlg'))) {
          this.get('_emptyBEDlg').show();
        }
      } finally {
        this.get('_nzBusinessEntityLoadingDlg').hide();
      }
    }),
    _agreeNzTc: (0, _emberConcurrency.task)(function* (agree) {
      try {
        let response = yield this.get('accountRepo.agreeNzTc').linked().perform({
          agreeToTc: agree
        });
        if (response === true) {
          this.get('credential.currentUser').set('agreedToNzTc', response);
        }
        this.get('_tcDlg').hide();
        this.get('_addHomPage').perform();
      } catch (e) {
        this.get('logger').logError('nz-index-page._agreeNzTc', e);
      }
    }),
    //region global notification handler
    _globalNotificationReceived(notification) {
      this.get('logger').logDebug('NZ-Index-Page: server notificaiton received', notification);
    },
    _applicationOutdated(clientVersion, serverVersion) {
      if (this.get('clientAppOutdated') === false) {
        this.get('logger').logWarning('Application is outdated', clientVersion, serverVersion);
        this.set('clientAppOutdated', true);
        if (Ember.isPresent(this.get('_versionOutdatedDlg'))) {
          this.get('_versionOutdatedDlg').show(clientVersion, serverVersion);
        }
      }
    },
    _concurrencyErrorOccurred(message) {
      this.get('_concurrencyErrorDlg').show(message);
    },
    _loadHomePage() {
      if (!(0, _lodash.isEmpty)(this.get('credential.currentUser'))) {
        if (this.get('credential.currentUser.agreedToNzTc')) {
          this.get('_addHomPage').perform();
        } else {
          this._showTandCDialog();
        }
      } else {
        (0, _emberLifeline.runTask)(this, () => {
          this._loadHomePage();
        }, 50);
      }
    },
    _showTandCDialog() {
      if (!(0, _lodash.isEmpty)(this.get('_tcDlg'))) {
        this.get('_tcDlg').show();
      } else {
        (0, _emberLifeline.runTask)(this, () => {
          this._showTandCDialog();
        }, 50);
      }
    },
    actions: {
      _onConfirmTandC() {
        this._agreeNzTc.perform(true);
      },
      _onCancelTandC() {
        this._agreeNzTc.perform(false);
      }
    }
  });
  _exports.default = _default;
});