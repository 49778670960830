define("esis-ui/components/areas/registration/nz/shared/nz-registration-expiry-options/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    isWizard: false,
    model: null,
    init() {
      this._super(...arguments);
      this.set('_renewOptions', [{
        title: '5 years',
        value: this.get('constants.nzFsExpiryDateOption.fiveYears')
      }, {
        title: 'Custom Date',
        value: this.get('constants.nzFsExpiryDateOption.custom')
      }]);
      if (this.get('model.expiryOption') === this.get('constants.nzFsExpiryDateOption.custom')) {
        this.set('_selectedRenewOption', (0, _lodash.find)(this.get('_renewOptions'), {
          value: this.get('constants.nzFsExpiryDateOption.custom')
        }));
      } else {
        this.set('_selectedRenewOption', this.get('_renewOptions')[0]);
        this.set('model.expiryOption', this.get('_selectedRenewOption').value);

        //Expiry Date Display for Five Years
        this._loadNzExpiryDateDisplay.perform();
      }
    },
    //region TASK
    _loadNzExpiryDateDisplay: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('registrationRepo.loadNzExpiryDateDisplay').perform({
          expiryDate: null,
          expiryOption: this.get('model.expiryOption')
        });
        this.set('model.expiryDateFiveYearsDisplay', result);
      } catch (error) {
        this.get('logger').logError('nz-registration-expiry-options._loadNzExpiryDateDisplay', error);
      }
    }),
    //endregion

    actions: {
      _onChangeRenewOption(selected) {
        this.set('model.expiryOption', selected.value);
        if (selected.value === this.get('constants.nzFsExpiryDateOption.fiveYears')) {
          this.set('model.expiryDate', null);
          this._loadNzExpiryDateDisplay.perform();
        }
        this.set('_selectedRenewOption', selected);
      },
      _onRenewDateChanged(date) {
        this.set('model.expiryDate', date);
      }
    }
  });
  _exports.default = _default;
});