define("esis-ui/components/areas/admin/contract-type/au/view/au-add-contract-type-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    model: null,
    _selectedBusinessEntity: null,
    removeTab() {},
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _selectedBusinessEntity: null
      });
    },
    show(model) {
      this._reset();
      this._loadContractTypeListForCopying.perform();
      this.set('model', this.mapObject(model, this.createModel('contract-type-details-request')));
      this._loadPicklist.perform();
      this.get('_dialog').show();
    },
    //region TASKS
    _loadContractTypeListForCopying: (0, _emberConcurrency.task)(function* () {
      try {
        const contractTypes = yield this.get('adminRepo.loadContractTypeListForCopying').perform(this.get('credential.currentBusinessEntity.value'));
        //grouped picklist
        let currentCode = '';
        let groups = [];
        let options = [];
        (0, _lodash.forEach)(contractTypes, ct => {
          if (currentCode !== ct.item.key) {
            currentCode = ct.item.key;
            options = [{
              value: ct.value,
              title: ct.title,
              guid: ct.item.guid
            }];
            groups.addObject({
              groupName: currentCode,
              options: options
            });
          } else {
            options.addObject({
              value: ct.value,
              title: ct.title,
              guid: ct.item.guid
            });
          }
        });
        this.set('_contractTypesForCopyingList', groups);
      } catch (error) {
        this.get('logger').logError('add-contract-type-modal._loadContractTypeListForCopying', error);
      }
    }),
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('model.entityGuid')
        }));
      } catch (error) {
        this.get('logger').logError('au-add-contract-type-modal._loadPicklist', error);
      }
    }),
    _loadContractTypeForCopy: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.get('adminRepo.loadContractTypeForCopying').perform(this.get('_selectedContractType.guid'));
        let contractTypeModel = this.mapJsonObjectToModel(response, 'contract-type-request');
        contractTypeModel.set('defaults', []);
        (0, _lodash.forEach)(response.defaults, item => {
          let ctDefault = this.mapJsonObjectToModel(item, 'contract-type-default');
          if ((0, _lodash.isNil)(contractTypeModel.collateralType)) {
            Ember.set(contractTypeModel, 'collateralType', ctDefault.collateralType);
          }
          contractTypeModel.defaults.pushObject(ctDefault);
        });
        contractTypeModel.setProperties({
          entityGuid: this.get('_selectedBusinessEntity.value'),
          isActive: true
        });
        this.set('model', contractTypeModel);
        this.get('model').save();
      } catch (error) {
        this.get('logger').logError('add-contract-type-modal._loadContractTypeForCopy', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onContractTypeChange(selected) {
        this.set('_selectedContractType', selected);
        if (this.get('credential.hasCopyContractPermission') && !(0, _lodash.isEmpty)(selected)) {
          this._loadContractTypeForCopy.perform();
        }
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('model.entityGuid', businessEntity.value);
      },
      _onConfirm() {
        if (this.get('model').validate()) {
          if (!(0, _lodash.isEmpty)(this.get('onSave'))) {
            this.get('onSave')(this.get('model'));
          }
          this.get('_dialog').hide();
        }
      },
      _onCancel() {
        if (this.get('isNew')) {
          this.get('removeTab')();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});