define("esis-ui/components/areas/registration/au/wizard/au-registration-review-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "ember-concurrency"], function (_exports, _wizardStepBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    isLastStep: true,
    /*
    should implement validation on activate.
     */
    _isRegistrationReadyForSubmission: false,
    //region COMPUTED PROPERTIES
    _collateralsLastIndex: Ember.computed('model.collaterals.@each', function () {
      return this.get('model.collaterals').length - 1;
    }),
    _grantorsLastIndex: Ember.computed('model.grantors.@each', function () {
      return this.get('model.grantors').length - 1;
    }),
    //endregion
    onActivate: function onActivate() {
      /*
      implement submit request to server for validation as soon as this page is loaded
      set is valid flag for validation step.
      set _isRegistrationReadyForSubmission with server validation result
       */
      this.get('_validateRegistrationTask').perform();
    },
    validateStep: function validateStep() {
      // virtual should be implemented by step component
      return this.get('_isRegistrationReadyForSubmission'); // validated OK, return false if validation failed
    },

    _validateRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        let result = yield this.get('registrationRepo.validateRegistration').perform(this.get('model'));
        this.set('_isRegistrationReadyForSubmission', result.isValid);
        if (!result.isValid) {
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', result.validationErrorMessage);
          // this.notifyError(`Validation error : ${result.validationErrorMessage}`);
        }
      } catch (error) {
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', error);
      }
    })
  });
  _exports.default = _default;
});