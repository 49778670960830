define("esis-ui/components/areas/registration/au/view/au-company-info-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    modalTitle: 'Company Information',
    showCloseButton: false,
    model: null,
    _hasAcnResult: Ember.computed('model.{hasAbn,hasAcn,hasArbn,hasArsn}', function () {
      return this.get('model.hasAcn') || this.get('model.hasArbn') || this.get('model.hasArsn');
    }),
    show: function show(companyInfo) {
      this.set('_bypassValidation', false);
      this.set('model', companyInfo);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirmVerified'))) {
          this.get('onConfirmVerified')(this.get('_bypassValidation'), this.get('_grantorReference'));
        }
      }
    }
  });
  _exports.default = _default;
});