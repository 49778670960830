define("esis-ui/helpers/display-phonenumber", ["exports", "esis-ui/mixins/esis-utils"], function (_exports, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend(_esisUtils.default, {
    compute([value]) {
      return this.formatPhoneNumber(value);
    }
  });
  _exports.default = _default;
});