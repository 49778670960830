define("esis-ui/components/areas/spg/nz/wizard/nz-spg-secured-parties-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash"], function (_exports, _wizardStepBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region INITIALIZATION
    //endregion

    //region COMPUTED PROPERTIES
    _lastIndex: Ember.computed('model.securedParties.@each', function () {
      return (0, _lodash.isEmpty)(this.get('model.securedParties')) ? 0 : this.get('model.securedParties.length') - 1;
    }),
    _hasSecuredParty: Ember.computed('model.securedParties.@each', function () {
      return !(0, _lodash.isEmpty)(this.get('model.securedParties'));
    }),
    //endregion

    //region METHODS
    validateStep: function validateStep() {
      let model = this.get('model');
      let valid = true;
      model.set('errors', []);
      let errors = model.get('errors');
      if ((0, _lodash.isEmpty)(this.get('model.securedParties'))) {
        errors.set('securedParty', 'At least one Secured Party should be added');
        valid = false;
      } else {
        errors.set('securedParty', '');
      }
      return valid;
    },
    //endregion

    //region ACTIONS
    actions: {
      _onAddOrganisation() {
        this.set('_modalTitle', "Add Secured Party - Organisation");
        this.get('_securedPartyDlg').show({
          securedPartyType: this.get('constants.nzSecuredPartyType.organisation')
        }, true);
      },
      _onAddPerson() {
        this.set('_modalTitle', "Add Secured Party - Person");
        this.get('_securedPartyDlg').show({
          securedPartyType: this.get('constants.nzSecuredPartyType.person')
        }, true);
      },
      _onEditSecuredParty(item, indexId) {
        this.set('_modalTitle', "Edit Secured Party - Person");
        Ember.set(item, 'indexId', indexId);
        this.get('_securedPartyDlg').show(item, false);
      },
      _onDeleteSecuredParty(item) {
        let model = this.get('model.securedParties');
        if (!(0, _lodash.isEmpty)(model)) {
          model.removeObject(item);
        }
      },
      _saveSecuredParty(spModel) {
        let model = this.get('model.securedParties');
        if ((0, _lodash.isNil)(spModel.indexId)) {
          model.pushObject(spModel);
        } else {
          this.mapObject(spModel, model[spModel.indexId]);
        }
        this.validateStep();
      }
    }
    //endregion
  });
  _exports.default = _default;
});