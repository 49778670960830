define("esis-ui/components/areas/shared/nz/nz-organisation-lookup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "gHpchCda",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shared/ember-lookup\",null,[[\"options\",\"onLookUp\",\"minSearchTextLength\",\"onSelectOption\",\"searchText\",\"isRunning\",\"placeholder\",\"maxlength\",\"disabled\",\"optionComponentName\"],[[24,[\"_options\"]],[28,\"action\",[[23,0,[]],\"_onOrganisationLookup\"],null],3,[28,\"action\",[[23,0,[]],\"_onSelectOrganisation\"],null],[24,[\"_organisationSearchText\"]],[24,[\"_loadOrganisationList\",\"isRunning\"]],\"Organisation Name or NZBN\",\"50\",[24,[\"disabled\"]],\"areas/shared/nz/nz-organisation-list-item-display\"]]],false],[0,\"\\n\\n\"],[1,[28,\"areas/shared/waiting-modal\",null,[[\"registerAs\",\"renderInPlace\"],[[24,[\"_waitingModal\"]],false]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/nz/nz-organisation-lookup/template.hbs"
    }
  });
  _exports.default = _default;
});