define("esis-ui/components/shared/kendo/grid/grid-base", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "jquery", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _jquery, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region private properties
    classNames: ['kendoGrid'],
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe("clear:both;width:99%;"),
    _autoRefreshJobId: null,
    _grid: null,
    _gridId: null,
    //endregion

    //region public events
    onSelectionChange: function onSelectionChange() {},
    onLinkClick: function onLinkClick() {},
    onDeleteClick: function onDeleteClick() {},
    onMenuSelect: function onMenuSelect() {},
    noticeGridIsLoadingData() {},
    //endregion

    //region public properties and options
    selectedGuid: null,
    //pageable
    isPageable: true,
    // auto refresh
    autoRefresh: false,
    stopAutoRefresh: false,
    autoRefreshInterval: 60000,
    // context menu
    hasContextMenu: false,
    contextMenuOptions: null,
    // grid option
    options: null,
    autoSelectFirstRow: true,
    autoFocus: true,
    /*
    When selected guid is set,
    if true : then select the first row in grid
    if false : then set selection to empty
     */
    selectFirstRowIfNoSelectedFound: false,
    /*
    this is a special flag, means the on selection change event should
    always fire even when caller manually set the initial selected row
    DO NOT change unless you know exactly what you are doing
     */
    ensureTriggerOnSelectionChangedOnInitialLoad: true,
    /*
    this flag is ask grid to add instanceGuid property to data object when trigger grid on row select change event
    this is to force the data object to be unique, so consumer such as preview panel of static tab
    will be forced to reload.
     */
    assignInstanceIdOnRowSelectionChange: false,
    //endregion

    //region initialisation
    init() {
      this._super(...arguments);
      const self = this;
      self.set('_contextMenuId', `contextMenu-${self.get('app').guid()}`);
      // const logger = self.get('logger');
      self._defaultOptions = {
        // height: "100%",
        filterable: false,
        sortable: true,
        resizable: true,
        pageable: {
          info: true,
          numeric: self.get('isPageable'),
          previousNext: self.get('isPageable'),
          messages: {
            display: "{2:0} records"
          },
          buttonCount: 5
        },
        scrollable: {
          virtual: !self.get('isPageable')
        },
        selectable: "row",
        navigatable: true,
        change: function change() {
          (0, _emberLifeline.runTask)(self, '_selectedRowChanged');
        },
        dataBound: function dataBound(e) {
          /*
          setup hyperlink for id column is specified
           */
          self.$('a.grid-link-id').click(function () {
            if (Ember.isPresent(self.$(this))) {
              if (Ember.isPresent(self.$(this).data('guid')) && self.$(this).data('guid') !== self.get('selectedGuid')) {
                const theGrid = self.get('_grid');
                const pageData = theGrid.dataSource.view();
                const theRow = (0, _lodash.find)(pageData, {
                  guid: self.$(this).data("guid")
                });
                if (theRow) {
                  const selectedRow = theGrid.tbody.find(">tr[data-uid='" + theRow.uid + "']");
                  theGrid.select(selectedRow);
                }
              }
              (0, _emberLifeline.runTask)(self, () => {
                self.get('onLinkClick')(self.$(this));
              });
            }
          });

          /*
          Grab focus to the table element of the grid, so user can use keyboard to navigate
          the timeout of 450 is designed to be bigger than select first row timeout
           */
          if (self.autoFocus) {
            (0, _emberLifeline.runTask)(self, () => {
              if (Ember.isPresent(e.sender) && Ember.isPresent(e.sender.table) && e.sender.table.length > 0) {
                e.sender.table.focus();
              }
            }, 550);
          }

          /*
          setup delete Icon on first column
          */
          self.$('span.grid-delete-icon').click(function () {
            if (Ember.isPresent(self.$(this))) {
              (0, _emberLifeline.runTask)(self, () => {
                self.get('onDeleteClick')(self.$(this));
              });
            }
          });
        }
      };

      // handling resize events
      self.get('event').on(self.get('constants.events.tabResize'), self, 'resize');
      self.get('event').on(self.get('constants.events.splitterMoved'), self, 'resize');
    },
    didInsertElement: function didInsertElement() {
      this._super(...arguments);
      const self = this;
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, self.get('_defaultOptions'), self.get('options'));
      const grid = self.$().kendoGrid(mergedOptions).data("kendoGrid");

      /*
      handle keyboard arrow keys, keyboard navigation inside the grid
       */
      const arrows = [37, 38, 39, 40];
      grid.table.on("keydown", function (e) {
        if (arrows.indexOf(e.keyCode) >= 0) {
          (0, _emberLifeline.runTask)(self, () => {
            const activeRow = self.$("[id$=_active_cell]").closest("tr");
            grid.select(activeRow);
          }, 20);
        }
      });
      self.set('_grid', grid);
      // run job to poll search result if in progress
      if (Ember.isNone(self.get('_autoRefreshJobId')) && self.get('autoRefresh') === true) {
        self.set('_autoRefreshJobId', (0, _emberLifeline.pollTask)(self, '_autoRefreshGrid'));
      }

      // context menu jquery context, get grid container div id
      const id = self.$().attr('id');
      self.set('_gridId', id);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('event').off(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').off(this.get('constants.events.splitterMoved'), this, 'resize');
      if (Ember.isPresent(this.get('_grid'))) {
        this.get('_grid').destroy();
      }
      if (Ember.isPresent(this.get('_autoRefreshJobId'))) {
        (0, _emberLifeline.cancelPoll)(this, this.get('_autoRefreshJobId')); // this is not strictly required
      }

      this._super(...arguments);
    },
    //endregion

    //region public methods
    refreshGrid: function refreshGrid(resetSelected) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const grid = this.get('_grid');
      if (Ember.isPresent(grid)) {
        if (resetSelected === true) {
          this.set('selectedGuid', null);
        }
        // this.get('logger').logDebug('refresh grid', resetSelected, this.get('selectedGuid'));
        if (resetSelected) {
          grid.dataSource.page(1);
          grid.pager.refresh();
        } else {
          grid.dataSource.read();
        }
        grid.refresh();
      }
    },
    clearSelection() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const grid = this.get('_grid');
      this.set('selectedGuid', null);
      grid.select(null);
      grid.refresh();
    },
    resize: function resize() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const grid = this.get('_grid');
      if (Ember.isPresent(grid)) {
        grid.resize();
      }
    },
    //endregion

    //region private or protected methods
    _selectedRowChanged() {
      const grid = this.get('_grid');
      const data = grid.dataItem(grid.select());
      if (Ember.isPresent(data)) {
        // it causes problem when updating grid first row (e.g. Task status)
        if (data.guid !== this.get('selectedGuid')) {
          // logger.logDebug('Grid selected id changed', self.get('selectedGuid'), data.guid, data);
          this.set('selectedGuid', data.guid);
          this._execSelectionChange(data);
        }
      } else {
        this._execSelectionChange(null);
      }
    },
    _kendoGridInitSelectRow() {
      const theGrid = this.get('_grid');
      const selectedGuid = this.get('selectedGuid');
      if (Ember.isPresent(theGrid)) {
        let selectedARow = false;
        const pageData = theGrid.dataSource.view();
        const pageNumber = theGrid.dataSource.page();
        if (!(0, _lodash.isEmpty)(pageData)) {
          if (Ember.isPresent(selectedGuid)) {
            const theRow = (0, _lodash.find)(pageData, {
              guid: selectedGuid
            });
            if (!(0, _lodash.isEmpty)(theRow)) {
              const selectedRow = theGrid.tbody.find(">tr[data-uid='" + theRow.uid + "']");
              if (selectedRow) {
                if (this.get('ensureTriggerOnSelectionChangedOnInitialLoad') === true) {
                  this.set('selectedGuid', null); // setting the selected guid to null so grid selection change logic will trigger
                }

                theGrid.select(selectedRow);
                selectedARow = true;
              }
            } else if (this.get('selectFirstRowIfNoSelectedFound') === true) {
              const firstRow = pageData[0];
              const row = theGrid.tbody.find(">tr[data-uid='" + firstRow.uid + "']");
              if (row.length > 0) {
                theGrid.select(row);
                selectedARow = true;
              }
            }
          } else if (this.get('autoSelectFirstRow') === true && pageNumber === 1) {
            // select the first row in the grid (that is visible
            const firstRow = pageData[0];
            const row = theGrid.tbody.find(">tr[data-uid='" + firstRow.uid + "']");
            if (row.length > 0) {
              theGrid.select(row);
              selectedARow = true;
            }
          }
        }
        if (!selectedARow) {
          if (Ember.isPresent(this.get('selectedGuid')) && pageNumber === 1) {
            this.set('selectedGuid', null);
            (0, _emberLifeline.runTask)(this, () => {
              this._execSelectionChange(null);
            });
          }
        }
      }
    },
    _renderContextMenu() {
      const self = this;
      const grid = this.get('_grid');
      if (self.get('hasContextMenu') === true) {
        const contextMenu = self.$(`#${self.get('_contextMenuId')}`).kendoContextMenu({
          showOn: "click",
          filter: `#${self.get('_gridId')} td.actionMenuFieldStyle`,
          open: function open() {
            (0, _emberLifeline.runTask)(this, () => {
              if (Ember.isPresent(self.get('selectedGuid'))) {
                const pageData = grid.dataSource.data();
                const model = (0, _lodash.find)(pageData, {
                  guid: self.get('selectedGuid')
                });
                if (!(0, _lodash.isEmpty)(model.hideActionStates)) {
                  for (let i = 0; i < model.hideActionStates.length; i++) {
                    contextMenu.enable(`li:nth-child(${i + 1})`, !model.hideActionStates[i]);
                    if (model.hideActionStates[i]) {
                      /* since context menu is attached to the body we must use jquery to select it,
                         it is not in the same context with grid so this.$ wouldn't work.
                      */
                      (0, _jquery.default)(`#${self.get('_contextMenuId')} li:nth-child(${i + 1})`).addClass('d-none');
                    } else {
                      (0, _jquery.default)(`#${self.get('_contextMenuId')} li:nth-child(${i + 1})`).removeClass('d-none');
                    }
                  }
                }
                if (!(0, _lodash.isEmpty)(model.disableActionStates)) {
                  for (let i = 0; i < model.disableActionStates.length; i++) {
                    contextMenu.enable(`li:nth-child(${i + 1})`, !model.disableActionStates[i]);
                    if (model.disableActionStates[i]) {
                      /* since context menu is attached to the body we must use jquery to select it,
                         it is not in the same context with grid so this.$ wouldn't work.
                      */
                      (0, _jquery.default)(`#${self.get('_contextMenuId')} li:nth-child(${i + 1}) svg`).addClass('disabledIcon');
                    } else {
                      (0, _jquery.default)(`#${self.get('_contextMenuId')} li:nth-child(${i + 1}) svg`).removeClass('disabledIcon');
                    }
                  }
                }
              }
            }, 100);
          },
          select: function select(e) {
            const selectedId = e.item.id;
            if (Ember.isPresent(selectedId)) {
              if (Ember.isPresent(self.get('selectedGuid'))) {
                const pageData = grid.dataSource.data();
                const model = (0, _lodash.find)(pageData, {
                  guid: self.get('selectedGuid')
                });
                (0, _emberLifeline.runTask)(self, () => {
                  self.get('onMenuSelect')(selectedId, self.convertToEmberObject(model));
                });
              }
            }
          }
        }).data("kendoContextMenu");
      }
    },
    _execSelectionChange(data) {
      if (this.get('assignInstanceIdOnRowSelectionChange') === true) {
        data['instanceGuid'] = this.get('app').guid();
      }
      this.get('onSelectionChange')(this.convertToEmberObject(data));
    },
    _autoRefreshGrid() {
      if (this.get('stopAutoRefresh') !== true) {
        this.refreshGrid(false);
      }
      (0, _emberLifeline.runTask)(this, '_autoRefreshGrid', this.get('autoRefreshInterval'));
    }
    //endregion
  });
  _exports.default = _default;
});