define("esis-ui/components/areas/shared/business-entity-picklist/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION
    selectedBusinessEntity: null,
    _entities: null,
    filterRegion: false,
    filterHolderEntity: false,
    loadPicklist() {
      return this.get('_loadPicklists').perform();
    },
    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').perform(this.get('credential.currentRegion'), this.get('filterRegion'), this.get('filterHolderEntity'));
        let groupedList = [];
        let auList = (0, _lodash.filter)(businessEntityList, ['item.regionId', this.get('constants.region.au')]);
        if (!(0, _lodash.isEmpty)(auList)) groupedList.push({
          groupName: 'AU',
          options: (0, _lodash.orderBy)(auList, ['title', 'asc'])
        });
        let nzList = (0, _lodash.filter)(businessEntityList, ['item.regionId', this.get('constants.region.nz')]);
        if (!(0, _lodash.isEmpty)(nzList)) groupedList.push({
          groupName: 'NZ',
          options: (0, _lodash.orderBy)(nzList, ['title', 'asc'])
        });
        this.set('_entities', [{
          value: this.emptyGuid(),
          title: 'All Business Entities',
          sortOrder: 0,
          item: {
            id: 0
          }
        }].concat(groupedList));
        this.set('_selectedBusinessEntity', (0, _lodash.find)(businessEntityList, {
          'value': this.get('credential.currentBusinessEntityGuid')
        }));
        return this.get('_selectedBusinessEntity');
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.get('onChange')(businessEntity);
      }
    }
  });
  _exports.default = _default;
});