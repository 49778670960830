define("esis-ui/models/nz-search-registration-number-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    registrationNumbers: (0, _modelBase.attr)(),
    hasBranchCode: (0, _modelBase.attr)(),
    branchGuid: (0, _modelBase.attr)(),
    branchCode: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.hasBranchCode = false;
      this.validations = {
        registrationNumbers: {
          custom: {
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('vin')) && !Ember.isPresent(model.get('chassisNumber'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'At least one Registration Number is required'
          }
        },
        branchCode: {
          custom: {
            validation: (key, value) => {
              if (this.get('hasBranchCode')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Branch Code can't be blank"
          }
        }
      };
    }
  });
  _exports.default = _default;
});