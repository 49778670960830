define("esis-ui/components/areas/shared/nz-page-base", ["exports", "esis-ui/components/areas/shared/page-base"], function (_exports, _pageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    _createRestGridFilter(options) {
      const credential = this.get('credential');
      let requestParam = {
        esisEntityGuid: credential.get('currentBusinessEntityGuid'),
        pageIndex: options.page - 1,
        pageSize: options.pageSize
      };
      if (options.sort.length > 0) {
        requestParam.sortFieldName = options.sort[0].field;
        requestParam.sortDirection = options.sort[0].dir;
      }
      return requestParam;
    }
  });
  _exports.default = _default;
});