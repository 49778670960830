define("esis-ui/components/areas/spg/au/view/au-wizard-sp-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _selectedOrgNumberType: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null,
        '_validationErrorMessage': null,
        '_detailsPanel': `detailsPanel${this.get('app').guid()}`,
        '_addressPanel': `addressPanel${this.get('app').guid()}`
      });
    },
    //endregion

    //region COMPUTED PROPERTIES
    _addressComponent: Ember.observer('_mailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_mailingAddress'))) {
        this.get('_mailingAddress').loadPicklistData();
      }
      return null;
    }),
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _validationErrorMessage: null,
        _selectedOrgNumberType: null
      });
    },
    show(model, isNew) {
      this._reset();
      if (isNew) {
        this.set('model', this.createModel('secured-party'));
        this.set('model.securedPartyType', this.get('constants.securedPartyType.organisation'));
        this.set('model.mailingAddress', this.mapObject(model, this.createModel('address')));
      } else {
        this.set('model', this.mapObject(model, this.createModel('secured-party')));
        this.set('model.mailingAddress', this.mapObject(model.mailingAddress, this.createModel('address')));
        this.set('model.indexId', model.indexId);
      }
      this._loadPicklists.perform();
      this.get('model').save();
      this.get('model.mailingAddress').save();
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        const orgNumberTypeList = yield this.get('picklistRepo.loadPicklist').perform(constants.get('picklistType.organisationNumberTypePicklist'), false);
        this.set('_organisationNumberTypeOptions', orgNumberTypeList);
        this.set('_selectedOrgNumberType', (0, _lodash.find)(orgNumberTypeList, o => {
          return o.value === this.get('model.organisationNumberType');
        }));
      } catch (error) {
        this.get('logger').logError('au-wizard-sp-modal._loadPicklist', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onSecuredPartyTypeChange: function _onSecuredPartyTypeChange(value) {
        this.set('model.securedPartyType', value);
      },
      //region PICKLIST ACTIONS
      _onOrganisationNumberTypeChange: function _onOrganisationNumberTypeChange(selectedOption) {
        this.set('_selectedOrgNumberType', selectedOption);
        this.set('model.organisationNumberType', selectedOption.value);
        this.set('model.organisationNumberTypeTitle', selectedOption.title);
      },
      //endregion
      //region BUTTON ACTIONS
      _onVerified(result) {
        this.get('model').setProperties({
          organisationName: result.organisationName,
          organisationEntityTypeDescription: result.entityTypeDescription,
          abnStatus: result.abnStatus,
          acnStatus: result.abnStatus,
          abnIsCurrent: result.abnStatus,
          acnIsCurrent: result.abnStatus,
          organisationNumber: result.preferredOrganisationNumber,
          organisationNumberType: result.preferredOrganisationNumberType
        });
        let orgNumberType = (0, _lodash.find)(this.get('_organisationNumberTypeOptions'), o => {
          return o.value === result.preferredOrganisationNumberType;
        });
        this.set('_selectedOrgNumberType', orgNumberType);
        this.set('model.organisationNumberTypeTitle', orgNumberType.title);
      },
      _onSave: function _onSave() {
        let modelValid = this.get('model').validate();
        let addressValid = this.get('model.mailingAddress').validate();
        this.set('_detailValidation', modelValid ? "" : "required");
        this.set('_addressValidation', addressValid ? "" : "required");
        if (modelValid && addressValid && !(0, _lodash.isEmpty)(this.get('onSave'))) {
          this.get('onSave')(this.get('model'));
        } else {
          this.set('_validationErrorMessage', "Please fill all the required fields");
        }
        return modelValid && addressValid;
      }
    }
    //endregion
  });
  _exports.default = _default;
});