define("esis-ui/components/areas/shared/help-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency", "esis-ui/utils/froala-base-options"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency, _froalaBaseOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _helpKey: null,
    _helpContent: null,
    _requestModel: null,
    _editModeOn: false,
    init() {
      this._super(...arguments);
      let toolbarButtons = (0, _lodash.clone)(_froalaBaseOptions.default.toolbarButtons);
      toolbarButtons.push('|', 'save');
      toolbarButtons.push('|', 'video');
      this._froalaOptions = {
        height: 370,
        toolbarButtons: toolbarButtons
      };
    },
    _loadHelp: (0, _emberConcurrency.task)(function* () {
      let currentScreenStack = this.get('app').getScreenStack();
      this.set('_requestModel', this.createModel('help-topic-request'));
      let path = '';
      (0, _lodash.forEach)(currentScreenStack, item => {
        if (item.hasHelpTopic === true) {
          path = `${path}${path === '' ? '' : '.'}${item.name}`;
        }
      });
      if (Ember.isPresent(path)) {
        this.set('_helpKey', path);
        const helpTopic = yield this.get('systemRepo.loadHelpTopic').perform(path);
        let requestModel = this.mapJsonObjectToModel(helpTopic, 'help-topic-request');
        this.set('_requestModel', requestModel);
        this.set('_requestModel.key', path);
      }
      this.get('_requestModel').save();
    }),
    _reset() {
      this.setProperties({
        _helpKey: null,
        _helpContent: null,
        _requestModel: null,
        _editModeOn: false
      });
    },
    show() {
      this._reset();
      this._loadHelp.perform();
      this.get('_dialog').show();
    },
    _saveHelpTopic: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('systemRepo.saveHelpTopic').perform(this.get('_requestModel'));
        this.notifySuccess(`Help topic ${this.get('_requestModel.key')} saved successfully`);
        let requestModel = this.mapJsonObjectToModel(response, 'help-topic-request');
        this.set('_requestModel', requestModel);
        this.get('_requestModel').save();
      } catch (error) {
        this.get('logger').logError('_saveHelpTopic', error);
      }
    }),
    actions: {
      _onHelpChange(content) {
        this.set('_requestModel.helpContent', content);
      },
      _onSaveHelp() {
        if (this.get('_requestModel.helpContentIsDirty')) {
          this._saveHelpTopic.perform();
        }
      },
      _onEnableEdit() {
        this.toggleProperty('_editModeOn');
      },
      _onClickImportVideo() {
        this.get('_editor').saveCursorPosition(); //To save the cursor position of froala editor
        this.get('_videoDlg').show();
      },
      _onConfirmAddVideo(videoModel) {
        if (!(0, _lodash.isEmpty)(videoModel)) {
          const constants = this.get('constants');
          let fileExtension = 'mp4';
          let width = !(0, _lodash.isEmpty)(videoModel.videoWidth) ? videoModel.videoWidth : '';
          let height = !(0, _lodash.isEmpty)(videoModel.videoHeight) ? videoModel.videoHeight : (0, _lodash.isEmpty)(videoModel.videoWidth) ? '90%' : '';
          let hasExtension = videoModel.fileName.split(".").slice(-1).pop() === fileExtension;
          let fileName = hasExtension ? videoModel.fileName : `${videoModel.fileName}.${fileExtension}`;
          let videoHtml = `<video controls="" height="${height}" width="${width}"><source src="${constants.videoBaseUrl}/${fileName}" type="video/${fileExtension}"></video>`;
          this.get('_editor').restoreCursorPosition(); //To restore the cursor position of froala editor
          this.get('_editor').insertHtml(videoHtml);
        }
      }
    }
  });
  _exports.default = _default;
});