define("esis-ui/initializers/repositories", ["exports", "esis-ui/utils/auto-register-singleton"], function (_exports, _autoRegisterSingleton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    (0, _autoRegisterSingleton.default)('repositories', application);
    // application.inject('repositories', 'store', 'store:main');
    application.inject('repositories', 'constants', 'service:app-constants');
    application.inject('repositories', 'app', 'service:app-service');
    application.inject('repositories', 'logger', 'service:logging-service');
    application.inject('repositories', 'credential', 'service:credential-service');
    application.inject('repositories', 'cache', 'service:cache-service');
    application.inject('repositories', 'notification', 'service:notification-service');

    // inject repository into components
    application.inject('component', 'systemRepo', 'repositories:system-repository');
    application.inject('component', 'accountRepo', 'repositories:account-repository');
    application.inject('component', 'registrationRepo', 'repositories:registration-repository');
    application.inject('component', 'searchRepo', 'repositories:search-repository');
    application.inject('component', 'spgRepo', 'repositories:spg-repository');
    application.inject('component', 'validationRepo', 'repositories:validation-repository');
    application.inject('component', 'picklistRepo', 'repositories:picklist-repository');
    application.inject('component', 'homeRepo', 'repositories:home-repository');
    application.inject('component', 'adminRepo', 'repositories:admin-repository');
    application.inject('component', 'reportRepo', 'repositories:report-repository');
    application.inject('component', 'fileRepo', 'repositories:file-repository');

    // inject repository into route
    application.inject('route', 'systemRepo', 'repositories:system-repository');
    application.inject('route', 'accountRepo', 'repositories:account-repository');
  }
  var _default = {
    name: 'repository-injector',
    after: 'models-injector',
    initialize
  };
  _exports.default = _default;
});