define("esis-ui/components/areas/search/au/view/shared/au-collateral-class-filter-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION
    modalTitle: 'Collateral Classes to include',
    collateralClassFilter: null,
    onConfirm: function onConfirm() {},
    onCancel: function onCancel() {},
    init() {
      this._super(...arguments);
    },
    //endregion

    _reset() {
      this.set('collateralClassFilter', null);
    },
    show(collateralClassFilter) {
      this._reset();
      this.set('collateralClassFilter', collateralClassFilter);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm() {
        this.onConfirm(this.get('collateralClassFilter'));
      },
      _onCancel() {
        this.onCancel();
      },
      _onPropertyChanged(propName1, propName2, value) {
        this.set(`collateralClassFilter.${propName2}`, value);
        let property = this.get(`collateralClassFilter.${propName1}`);
        if (!Ember.isNone(property)) {
          property.forEach(item => {
            Ember.set(item, 'checked', value);
          });
        }
      }
    }
  });
  _exports.default = _default;
});