define("esis-ui/components/areas/registration/nz/wizard/nz-registration-spg-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    isFirstStep: true,
    init() {
      this._super(...arguments);
      this._loadPicklist.perform();
    },
    validateStep: function validateStep() {
      return this.get('model').validate();
    },
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //SPGs
        let spgPicklist = yield this.get('picklistRepo.loadSpgListForNzFs').linked().perform(this.get('credential.currentBusinessEntity.value'));
        this.set('_spgList', spgPicklist);
        if (!(0, _lodash.isEmpty)(this.get('model.guid'))) {
          this.set('_selectedSpg', (0, _lodash.find)(spgPicklist, {
            'value': this.get('model.spgId')
          }));
          if ((0, _lodash.isNil)(this.get('_selectedSpg'))) {
            this.set('_selectedSpg', spgPicklist[0]);
            this.set('model.spgId', spgPicklist[0].value);
          }
          this.set('model.spgName', this.get('_selectedSpg').title);
        } else {
          this.set('_selectedSpg', spgPicklist[0]);
          this.set('model.spgId', spgPicklist[0].value);
          this.set('model.spgName', spgPicklist[0].title);
        }
        this.get('_loadBranchCodePicklist').perform();
      } catch (error) {
        this.get('logger').logError('nz-registration-spg-step._loadPicklist', error);
      }
    }),
    _loadBranchCodePicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BRANCH CODES
        const branchCodePicklist = yield this.get('picklistRepo.loadNzBranchCode').linked().perform(this.get('model.spgId'));
        this.set('_branchCodeList', (0, _lodash.sortBy)(branchCodePicklist, 'title'));
        this.set('model.hasBranchCode', !(0, _lodash.isEmpty)(branchCodePicklist));
        this.set('model.errors.branchCode', null);
        if (!(0, _lodash.isNil)(this.get('model.branchGuid'))) {
          this.set('_selectedBranchCode', (0, _lodash.find)(this.get('_branchCodeList'), {
            value: this.get('model.branchGuid')
          }));
        } else if (branchCodePicklist.length === 1) {
          this.set('_selectedBranchCode', this.get('_branchCodeList')[0]);
          this.set('model.branchGuid', this.get('_branchCodeList')[0].value);
          this.set('model.branchCode', this.get('_branchCodeList')[0].title);
        }
        this.get('model').saveModel();
      } catch (error) {
        this.get('logger').logError('nz-registration-spg-step._loadBranchCodePicklist', error);
      }
    }),
    actions: {
      _onChangeSpg(selected) {
        this.set('_selectedSpg', selected);
        this.set('model.spgId', selected.value);
        this.set('model.spgName', selected.title);
        this.set('model.branchCode', null);
        this.set('model.branchGuid', null);
        this.set('_selectedBranchCode', null);
        this.get('_loadBranchCodePicklist').perform();
      },
      _onChangeBranchCode(selected) {
        this.set('_selectedBranchCode', selected);
        this.set('model.branchGuid', selected.value);
        this.set('model.branchCode', selected.title);
      }
    }
  });
  _exports.default = _default;
});