define("esis-ui/models/mv-search-criteria", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/mv-search-criteria.js
  var _default = _modelBase.Model.extend({
    vin: (0, _modelBase.attr)(),
    chassis: (0, _modelBase.attr)(),
    manufacturersNumber: (0, _modelBase.attr)(),
    criteriaSelected: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        criteriaSelected: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('vin') || model.get('chassis') || model.get('manufacturersNumber')) {
                return true;
              } else {
                return false;
              }
            },
            message: 'At least one criteria should be filled'
          }
        }
      };
    }
  });
  _exports.default = _default;
});