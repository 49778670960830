define("esis-ui/helpers/display-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayId = displayId;
  function displayId([id]) {
    const length = 6;
    let r = "" + id;
    while (r.length < length) {
      r = "0" + r;
    }
    return r;
  }
  var _default = Ember.Helper.helper(displayId);
  _exports.default = _default;
});