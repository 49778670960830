define("esis-ui/models/address-nz", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    id: (0, _modelBase.attr)(),
    countryCode: (0, _modelBase.attr)(),
    countryDescription: (0, _modelBase.attr)(),
    addressLine1: (0, _modelBase.attr)(),
    suburb: (0, _modelBase.attr)(),
    cityTown: (0, _modelBase.attr)(),
    postCode: (0, _modelBase.attr)(),
    hasChange: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    addressIsDirty: Ember.computed('countryCodeIsDirty', 'countryDescriptionIsDirty', 'addressLine1IsDirty', 'cityTownIsDirty', 'suburbIsDirty', 'postCodeIsDirty', function () {
      return this.get('countryCodeIsDirty') || this.get('addressLine1IsDirty') || this.get('cityTownIsDirty') || this.get('postCodeIsDirty');
    }),
    displayAddress: Ember.computed('id', 'addressIsDirty', 'countryCode', 'countryDescription', 'addressLine1', 'cityTown', 'suburb', 'postCode', function () {
      let address = ' ';
      if (Ember.isPresent(this.get('addressLine1'))) {
        address += this.get('addressLine1') + ', ';
      }
      if (Ember.isPresent(this.get('suburb'))) {
        address += this.get('suburb') + ', ';
      }
      if (Ember.isPresent(this.get('cityTown'))) {
        address += this.get('cityTown') + ', ';
      }
      if (Ember.isPresent(this.get('postCode'))) {
        address += this.get('postCode') + ', ';
      }
      if (Ember.isPresent(this.get('countryDescription'))) {
        address += this.get('countryDescription');
      }
      return Ember.String.htmlSafe(address);
    }),
    init() {
      this._super(...arguments);
      this.validations = {
        addressLine1: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Street can not be blank'
          }
        },
        cityTown: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'City / Town can not be blank'
          }
        },
        countryCode: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Country can not be blank'
          }
        }
      };
    }
  });
  _exports.default = _default;
});