define("esis-ui/components/areas/search/nz/view/nz-search-debtor-person/component", ["exports", "esis-ui/components/areas/search/nz/nz-search-base"], function (_exports, _nzSearchBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSearchBase.default.extend({
    //region INITIALISATION
    searchSubmitTaskName: 'submitSearchNzDebtorPerson',
    searchTabTitle: 'Debtor Person Search',
    searchModelName: 'nz-search-person-request',
    //endregion

    actions: {
      _onDobChanged(value) {
        this.set('model.dateOfBirth', value);
      }
    }
  });
  _exports.default = _default;
});