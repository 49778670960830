define("esis-ui/components/shared/ember-bootstrap/static-tab/vertical-tab/component", ["exports", "esis-ui/components/shared/ember-bootstrap/static-tab/ordered-tab-base"], function (_exports, _orderedTabBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _orderedTabBase.default.extend({
    hasButton: true
  });
  _exports.default = _default;
});