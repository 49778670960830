define("esis-ui/components/shared/ember-bootstrap/static-tab/ordered-tab-base", ["exports", "ember-bootstrap/components/bs-tab", "lodash"], function (_exports, _bsTab, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _bsTab.default.extend({
    navItems: Ember.computed('childPanes.@each.{order,elementId,title,group,iconName,prefix,iconClass}', function () {
      let items = Ember.A();
      let orderedChildren = (0, _lodash.orderBy)(this.get('childPanes'), 'order');
      orderedChildren.forEach(pane => {
        let groupTitle = pane.get('groupTitle');
        let item = pane.getProperties('elementId', 'title', 'iconName', 'prefix', 'iconClass');
        if (Ember.isPresent(groupTitle)) {
          let group = items.findBy('groupTitle', groupTitle);
          if (group) {
            group.children.push(item);
            group.childIds.push(item.elementId);
          } else {
            items.push({
              isGroup: true,
              groupTitle,
              children: Ember.A([item]),
              childIds: Ember.A([item.elementId])
            });
          }
        } else {
          items.push(item);
        }
      });
      return items;
    })
  });
  _exports.default = _default;
});