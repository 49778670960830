define("esis-ui/components/areas/registration/au/wizard/au-collateral-type-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    showContractType: true,
    modalTitle: 'Add Collateral Type',
    collateral: null,
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //CONTRACT TYPES
        let contractPicklist = yield this.get('picklistRepo.loadContractTypes').perform(this.get('credential.currentBusinessEntity.value'));
        this.set('_filteredContractList', (0, _lodash.filter)(contractPicklist, {
          'item': {
            collateralType: this.get('collateralType')
          }
        }));
        // this.set('_selectedContractType', this.get('_filteredContractList') ? this.get('_filteredContractList')[0] : null);
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _reset() {
      this.setProperties({
        _nonSerialisedDisabled: false,
        _serialisedDisabled: false,
        _selectedContractType: null
      });
    },
    show() {
      this._reset();
      this.set('_modalGuid', this.get('app').guid());
      if (this.get('showContractType')) {
        this.set('_addType', 'addByContractType');
      } else {
        this.set('_addType', 'addByCollateralType');
      }
      let collateralClassTypes = this.createModel('grantor-search-filter-criteria');
      (0, _lodash.forEach)(collateralClassTypes.generalProperty, (element, index, array) => {
        array[index].disabled = false;
      });
      this.set('_collateralClassTypeOptions', collateralClassTypes);
      if (!(0, _lodash.isEmpty)(this.get('collateral'))) {
        this.set('_collateralClassType', this.get('collateral.collateralClassType'));
        this.set('_isSerialised', this.get('collateral.isSerialised'));
      } else {
        this.set('_collateralClassType', this.get('constants.searchCollateralClassType.motorVehicle'));
        this.set('_isSerialised', true);
      }
      this._collateralClassTypeChanged();
      this._loadPicklists.perform();
      this.get('_dialog').show();
    },
    _collateralClassTypeChanged() {
      const type = this.get('collateralType');
      const classType = this.get('_collateralClassType');
      const collateralClassTypes = this.get('_collateralClassTypeOptions');
      const constants = this.get('constants');
      let intellectualProperty = (0, _lodash.find)(collateralClassTypes.intangibleProperty, {
        'value': constants.searchCollateralClassType.intellectualProperty
      });
      if (!(0, _lodash.isNil)(type)) {
        Ember.set(intellectualProperty, 'disabled', type === constants.collateralType.consumer);
      }
      if (!(0, _lodash.isNil)(classType)) {
        if (classType === constants.searchCollateralClassType.motorVehicle || classType === constants.searchCollateralClassType.watercraft || classType === constants.searchCollateralClassType.patent || classType === constants.searchCollateralClassType.design || classType === constants.searchCollateralClassType.tradeMark || classType === constants.searchCollateralClassType.plantBreedersRight) {
          // Moter Vehicles, Watercraft, Design, Patent, Plant Breeders' Right, Trade Mark
          if (type === constants.collateralType.consumer) {
            // Consumer
            this.set('_isSerialised', true);
            this.set('_serialisedDisabled', false);
            this.set('_nonSerialisedDisabled', true);
          } else if (type === constants.collateralType.commercial) {
            // Commercial
            this.set('_isSerialised', true);
            this.set('_serialisedDisabled', false);
            this.set('_nonSerialisedDisabled', false);
          }
        } else if (classType === constants.searchCollateralClassType.airframe || classType === constants.searchCollateralClassType.helicopter || classType === constants.searchCollateralClassType.aircraftEngine || classType === constants.searchCollateralClassType.smallAircraft) {
          // Aircraft Engine, Airframe, Helicopter, Small Aircraft
          this.set('_serialisedDisabled', false);
          this.set('_nonSerialisedDisabled', true);
          this.set('_isSerialised', true);
        } else {
          this.set('_isSerialised', false);
          this.set('_serialisedDisabled', true);
          this.set('_nonSerialisedDisabled', true);
        }
      }
      this.set('_isSerialisedOptional', !this.get('_nonSerialisedDisabled') && !this.get('_serialisedDisabled'));
    },
    actions: {
      _onAddColTypeChange(value) {
        this.set('_addType', value);
        if (value === 'addByCollateralType') {
          this.set('_selectedContractType', null);
        }
      },
      _onChangeContractType(selected) {
        this.set('_selectedContractType', selected);
      },
      _onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onSelectAddType'))) {
          const args = {
            contractType: this.get('_selectedContractType'),
            collateralType: this.get('collateralType'),
            collateralClassType: this.get('_collateralClassType'),
            isSerialised: this.get('_isSerialised'),
            isSerialisedOptional: this.get('_isSerialisedOptional')
          };
          this.get('onSelectAddType')(args);
        }

        // reset state
        this.set('_selectedContractType', null);
      },
      _onCollClassTypeChange(value) {
        this.set('_collateralClassType', value);
        this._collateralClassTypeChanged();
      },
      _onIsSerialisedChange(value) {
        this.set('_isSerialised', value);
      }
    }
  });
  _exports.default = _default;
});