define("esis-ui/models/nz-search-mv-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    registrationNumber: (0, _modelBase.attr)(),
    vin: (0, _modelBase.attr)(),
    chassisNumber: (0, _modelBase.attr)(),
    hasBranchCode: (0, _modelBase.attr)(),
    branchGuid: (0, _modelBase.attr)(),
    branchCode: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.hasBranchCode = false;
      this.validations = {
        registrationNumber: {
          custom: [{
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('vin')) && !Ember.isPresent(model.get('chassisNumber'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'You must enter criteria into at least one field'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length < 7;
              }
              return true;
            },
            message: 'Registration Number can\'t be more than 6 characters long'
          }]
        },
        vin: {
          custom: [{
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('registrationNumber')) && !Ember.isPresent(model.get('chassisNumber'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'You must enter criteria into at least one field'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length === 17;
              }
              return true;
            },
            message: 'VIN must be 17 characters long'
          }]
        },
        chassisNumber: {
          custom: {
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('vin')) && !Ember.isPresent(model.get('registrationNumber'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'You must enter criteria into at least one field'
          }
        },
        branchCode: {
          custom: {
            validation: (key, value) => {
              if (this.get('hasBranchCode')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Branch Code can't be blank"
          }
        }
      };
    }
  });
  _exports.default = _default;
});