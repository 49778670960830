define("esis-ui/services/cache-service", ["exports", "ember-local-storage", "esis-ui/mixins/esis-utils"], function (_exports, _emberLocalStorage, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend(_esisUtils.default, {
    credential: Ember.inject.service('credential-service'),
    //region load caches
    lookupCache: (0, _emberLocalStorage.storageFor)('lookup-cache'),
    translationCache: (0, _emberLocalStorage.storageFor)('translation-cache'),
    settingCache: (0, _emberLocalStorage.storageFor)('setting-cache'),
    policyCache: (0, _emberLocalStorage.storageFor)('policy-cache'),
    tabHistoryCache: (0, _emberLocalStorage.storageFor)('tab-history-cache'),
    //endregion

    //region common Cache function
    resetAllCache() {
      this.resetLookup();
      this.resetSetting();
      this.resetTabHistory();
    },
    //endregion

    //region Setting Cache
    putSetting(settingName, value) {
      this.set(`settingCache.${settingName}`, value);
    },
    getSetting(settingName) {
      if (Ember.isPresent(this.get(`settingCache.${settingName}`))) {
        return this.get(`settingCache.${settingName}`);
      }
      return null;
    },
    resetSetting() {
      this.get('settingCache').reset();
    },
    //endregion

    //region Translation Cache
    putTranslation(language, data) {
      this.set(`translationCache.${language}`, data);
    },
    getTranslation(language) {
      const cachedItem = this.get('translationCache').getWithDefault(language, {});
      if (cachedItem.length > 0) {
        return cachedItem;
      }
      return null;
    },
    resetTranslation() {
      this.get('translationCache').reset();
    },
    //endregion

    //region Lookup / picklist cache
    putLookup(key, value) {
      const cache = this.get('lookupCache');
      const internalKey = this._getLookupCacheKey(key);
      const existing = cache.findBy('key', internalKey);
      if (Ember.isPresent(existing)) {
        this.deleteLookup(internalKey);
      }
      cache.pushObject({
        key: internalKey,
        value: value
      });
    },
    getLookup(key) {
      const cache = this.get('lookupCache');
      const internalKey = this._getLookupCacheKey(key);
      const existing = cache.findBy('key', internalKey); // note existing is a plain javascript object
      if (Ember.isPresent(existing)) {
        return existing.value;
      }
      return null;
    },
    deleteLookup(key) {
      const cache = this.get('lookupCache');
      const internalKey = this._getLookupCacheKey(key);
      const existing = cache.findBy('key', internalKey); // note existing is a plain javascript object
      if (Ember.isPresent(existing)) {
        cache.removeObject(existing);
        return true;
      }
      return false;
    },
    resetLookup() {
      this.get('lookupCache').reset();
    },
    _getLookupCacheKey(key) {
      return key;
    },
    //endregion

    //region Tab History Cache
    putTabHistory(name, value) {
      this.set(`tabHistoryCache.${name}`, value);
    },
    getTabHistory(name) {
      if (Ember.isPresent(this.get(`tabHistoryCache.${name}`))) {
        return this.get(`tabHistoryCache.${name}`);
      }
      return null;
    },
    resetTabHistory() {
      this.get('tabHistoryCache').reset();
    }
    //endregion
  });
  _exports.default = _default;
});