define("esis-ui/components/areas/spg/nz/view/nz-finance-statement-picker/component", ["exports", "esis-ui/components/areas/shared/nz-page-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _nzPageBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region private properties
    _fsGrid: null,
    _selectedFs: null,
    _spgId: null,
    _search: null,
    //endregion

    //region initialisation
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      //region grid option
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: this.get('app').getApiUrl(constants.apiEndpoint.nzFsList)
            },
            parameterMap: (options, operation) => {
              if (operation === 'read') {
                let requestParam = this._createRestGridFilter(options);
                requestParam.spgIds = [this.get('_spgId')]; // todo get it from drop down list
                requestParam.view = constants.registrationGridViewFilter.registered;
                requestParam.searchField = this.get('_searchBy.value');
                requestParam.searchTerm = this.get('_search');
                return JSON.stringify(requestParam);
              }
              return options;
            }
          },
          schema: {
            data: _data => {
              if (!(0, _lodash.isEmpty)(_data)) {
                let realData = _data.financingStatements;
                for (let i = 0; i < realData.length; i++) {
                  realData[i].expiryDate = this.dateTimeAsStr(realData[i].expiryDate);
                }
                return realData;
              }
              return _data;
            },
            //We need this part for persisting row selection on paging
            model: {
              id: "registrationNumber"
            }
          },
          sort: [{
            field: 1,
            dir: "desc"
          }]
        },
        height: 400,
        columns: [{
          field: "registrationNumber",
          title: "FS Number",
          width: "180px",
          filterable: false
        }, {
          field: "expiryDate",
          title: "Expiry Date",
          width: "140px",
          filterable: false
        }, {
          field: "efxBranchCode",
          title: "Branch Code",
          width: "180px",
          filterable: false
        }, {
          field: "statusTitle",
          title: "Status",
          width: "120px",
          filterable: false
        }]
      });
      //endregion
    },

    //endregion

    //region COMPUTED PROPERTIES
    _disableConfirmButton: Ember.computed('_selectedFs', function () {
      return (0, _lodash.isEmpty)(this.get('_selectedFs'));
    }),
    //endregion

    //region METHODS
    show(spgId) {
      this.setProperties({
        _spgId: spgId,
        _search: null,
        _searchBy: null,
        _selectedFs: []
      });
      this._loadPicklists.perform();
      this.get('_dialog').show();
    },
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_fsGrid'))) {
          if (reset === true) {
            this.set('_selectedFs', null);
          }
          this.get('_fsGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        const financeStatementTypeList = yield this.get('picklistRepo.loadPicklist').perform(constants.get('picklistType.nzFsFilterField'), false);
        this.set('_searchByOptions', financeStatementTypeList);
      } catch (error) {
        this.get('logger').logError('nz-finance-statement-picker._loadPicklist', error);
      }
    }),
    //endregion

    //region actions
    actions: {
      //region grid actions
      _onGridRowSelectionChange: function _onGridRowSelectionChange(item) {
        this.set('_selectedFs', item);
      },
      //endregion

      //region search filter
      _searchByChanged: function _searchByChanged(option) {
        this.set('_searchBy', option);
      },
      _onSearch: function _onSearch(value) {
        this.set('_search', value);
        this._refreshGrid(true);
      },
      //endregion
      _onAdd: function _onAdd() {
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('_selectedFs'));
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});