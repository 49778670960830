define("esis-ui/components/shared/copy-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "X4+JIzgt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[10,\"role\",\"button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"_copy\"],null]],[8],[0,\"\\n  \"],[1,[28,\"bs-tooltip\",null,[[\"title\",\"triggerEvents\",\"renderInPlace\",\"visible\",\"placement\",\"triggerElement\"],[\"Copied!\",\" \",true,[24,[\"_visibleTooltip\"]],\"bottom\",\"parentView\"]]],false],[0,\"\\n    \"],[1,[28,\"fa-icon\",[[24,[\"icon\"]]],[[\"class\",\"title\",\"prefix\"],[\"smallIcon havePointer ml-1\",[24,[\"title\"]],[24,[\"prefix\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/copy-text/template.hbs"
    }
  });
  _exports.default = _default;
});