define("esis-ui/helpers/padding-to-length", ["exports", "esis-ui/mixins/esis-utils"], function (_exports, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.extend(_esisUtils.default, {
    compute([value, length]) {
      let result = '';
      if (Ember.isPresent(value)) {
        result = this.paddingToLength(value, length);
      }
      return result;
    }
  });
  _exports.default = _default;
});