define("esis-ui/routes/forgot", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model(params) {
      return {
        username: params.username
      };
    },
    actions: {
      error(error) {
        // Manage your errors
        Ember.onerror(error);
        // substate implementation when returning `true`
        return true;
      }
    }
  });
  _exports.default = _default;
});