define("esis-ui/repositories/registration-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency", "lodash"], function (_exports, _communicationBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    //region Methods
    _cloneAuRegistrationCreateRequest(registrationRequest) {
      let copy = registrationRequest.clone();
      copy.id = registrationRequest.get('id');
      copy.hasChange = registrationRequest.get('isDirty');
      if (!(0, _lodash.isEmpty)(registrationRequest.get('grantors'))) {
        copy.grantors = registrationRequest.get('grantors').map(function (item) {
          let param = item.clone();
          param.id = item.get('id');
          param.hasChange = item.get('isDirty');
          return param;
        });
      }
      if (!(0, _lodash.isEmpty)(registrationRequest.get('collaterals'))) {
        copy.collaterals = registrationRequest.get('collaterals').map(function (item) {
          let param = item.clone();
          param.id = item.get('id');
          param.hasChange = item.get('isDirty');
          return param;
        });
      }
      return copy;
    },
    _cloneNzFsCreateRequest(nzFSCreateRequest) {
      let copy = nzFSCreateRequest.clone();
      if (!(0, _lodash.isEmpty)(nzFSCreateRequest.get('debtors'))) {
        copy.debtors = nzFSCreateRequest.get('debtors').map(function (item) {
          let param = item.clone();
          if (!(0, _lodash.isEmpty)(item.get('mailingAddress'))) {
            param.mailingAddress = item.get('mailingAddress').clone();
          }
          if (!(0, _lodash.isEmpty)(item.get('contactAddress'))) {
            param.contactAddress = item.get('contactAddress').clone();
          }
          if (!(0, _lodash.isEmpty)(item.get('personActingOnBehalf'))) {
            param.personActingOnBehalf = item.get('personActingOnBehalf').clone();
            if (!(0, _lodash.isEmpty)(item.get('personActingOnBehalf.contactAddress'))) {
              param.personActingOnBehalf.contactAddress = item.get('personActingOnBehalf.contactAddress').clone();
            }
            if (!(0, _lodash.isEmpty)(item.get('personActingOnBehalf.mailingAddress'))) {
              param.personActingOnBehalf.mailingAddress = item.get('personActingOnBehalf.mailingAddress').clone();
            }
          }
          return param;
        });
      }
      if (!(0, _lodash.isEmpty)(nzFSCreateRequest.get('collateral'))) {
        copy.collateral = nzFSCreateRequest.get('collateral').map(function (coll) {
          let param = coll.clone();
          if (!(0, _lodash.isEmpty)(coll.get('collateralItems'))) {
            param.collateralItems = coll.get('collateralItems').map(function (item) {
              let param = item.clone();
              return param;
            });
          }
          return param;
        });
      }
      return copy;
    },
    //endregion

    //region AU
    loadRegistrationDetailDigest: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadRegistrationDetailDigest'), guid));
    }),
    downloadRegistrationAttachment: (0, _emberConcurrency.task)(function* (fileId) {
      return yield this.downloadFile(this.fmt(this.get('constants.apiEndpoint.downloadRegistrationAttachment'), fileId));
    }),
    downloadRegistrationVerificationReport: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.previewFile(this.fmt(this.get('constants.apiEndpoint.downloadRegistrationVerificationReport'), guid));
    }),
    retrieveRegistration: (0, _emberConcurrency.task)(function* (guid, force) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.retrieveRegistration'), guid, force));
    }).drop(),
    dischargeRegistration: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.dischargeRegistration'), guid));
    }).drop(),
    loadRegistrationFees: (0, _emberConcurrency.task)(function* (idCollection) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadRegistrationFees'), idCollection, false);
    }),
    createRegistrationAccountingRecord: (0, _emberConcurrency.task)(function* (idCollection) {
      return yield this.apiPost(this.get('constants.apiEndpoint.createRegistrationAccountingRecord'), idCollection, false);
    }).drop(),
    cancelDischarge: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.cancelDischarge'), guid));
    }).drop(),
    loadRegistration: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadRegistration'), guid));
    }),
    submitRegistration: (0, _emberConcurrency.task)(function* (idCollection) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitRegistration'), idCollection, false);
    }).drop(),
    approveDischargeRegistration: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.approveDischargeRegistration'), guid));
    }).drop(),
    resubmitMyRegistrations: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.resubmitMyRegistrations')));
    }).drop(),
    resubmitRegistration: (0, _emberConcurrency.task)(function* (registrationGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.resubmitRegistration'), registrationGuid));
    }).drop(),
    resubmitRegistrationsByEntity: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.resubmitRegistrationByEntity'), entityGuid));
    }).drop(),
    deleteMyRegistrations: (0, _emberConcurrency.task)(function* (viewFilter) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteMyRegistrations'), viewFilter));
    }).drop(),
    deleteRegistrationsByEntity: (0, _emberConcurrency.task)(function* (guid, viewFilter) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteRegistrationsByEntity'), guid, viewFilter));
    }).drop(),
    deleteRegistration: (0, _emberConcurrency.task)(function* (registrationGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteRegistration'), registrationGuid));
    }).drop(),
    loadExpiryMessage: (0, _emberConcurrency.task)(function* (registrationStartEndDate) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadExpiryMessage'), registrationStartEndDate, false);
    }),
    saveRegistration: (0, _emberConcurrency.task)(function* (registrationRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveRegistration'), this._cloneAuRegistrationCreateRequest(registrationRequest), false);
    }).drop(),
    validateAndSaveRegistration: (0, _emberConcurrency.task)(function* (registrationRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.validateAndSaveRegistration'), this._cloneAuRegistrationCreateRequest(registrationRequest), true);
    }).drop(),
    validateRegistration: (0, _emberConcurrency.task)(function* (registrationRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.validateRegistration'), this._cloneAuRegistrationCreateRequest(registrationRequest), true);
    }).drop(),
    loadContractTypeDefaults: (0, _emberConcurrency.task)(function* (contractTypeGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadContractTypeDefaults'), contractTypeGuid));
    }),
    preSubmitNewRegistration: (0, _emberConcurrency.task)(function* (regGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.preSubmitNewRegistration'), regGuid));
    }).drop(),
    bulkDeleteRegistration: (0, _emberConcurrency.task)(function* (guidCollection) {
      return yield this.apiPost(this.get('constants.apiEndpoint.bulkDeleteRegistration'), guidCollection, false);
    }).drop(),
    renewRegistration: (0, _emberConcurrency.task)(function* (renewRegistrationRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.renewRegistration'), renewRegistrationRequest.clone(), true);
    }).drop(),
    getRegistrationExpiryInfo: (0, _emberConcurrency.task)(function* (request) {
      return yield this.apiPost(this.get('constants.apiEndpoint.getRegistrationExpiryInfo'), request.clone(), false);
    }).drop(),
    loadRegistrationStatusCount: (0, _emberConcurrency.task)(function* (registrationGridFilterArgs) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadRegistrationStatusCount'), registrationGridFilterArgs);
    }),
    //endregion
    //NZ
    saveNzDraftFs: (0, _emberConcurrency.task)(function* (nzFSCreateRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveNzDraftFs'), this._cloneNzFsCreateRequest(nzFSCreateRequest), true);
    }).drop(),
    loadNzDraftFs: (0, _emberConcurrency.task)(function* (nzDraftFSGetRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadNzDraftFs'), nzDraftFSGetRequest);
    }),
    submitNewNzFs: (0, _emberConcurrency.task)(function* (nzFSCreateRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitNewNzFs'), this._cloneNzFsCreateRequest(nzFSCreateRequest), true);
    }).drop(),
    saveAmendNzFsDraft: (0, _emberConcurrency.task)(function* (NzFSAmendRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveAmendNzFsDraft'), this._cloneNzFsCreateRequest(NzFSAmendRequest), true);
    }).drop(),
    submitAmendNzFs: (0, _emberConcurrency.task)(function* (NzFSAmendRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.submitAmendNzFs'), this._cloneNzFsCreateRequest(NzFSAmendRequest), true);
    }).drop(),
    validateCreateNzFs: (0, _emberConcurrency.task)(function* (nzFSCreateRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.validateCreateNzFs'), this._cloneNzFsCreateRequest(nzFSCreateRequest), false);
    }).drop(),
    dischargeNzFs: (0, _emberConcurrency.task)(function* (nzFinancingStatementDischargeRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.dischargeNzFs'), nzFinancingStatementDischargeRequest);
    }).drop(),
    renewNzFs: (0, _emberConcurrency.task)(function* (nzFSRenewRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.renewNzFs'), nzFSRenewRequest);
    }).drop(),
    loadNzFsModel: (0, _emberConcurrency.task)(function* (nzFSGetRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadNzFsModel'), nzFSGetRequest);
    }),
    loadNzDraftAmendFs: (0, _emberConcurrency.task)(function* (nzDraftFSGetRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadNzDraftAmendFs'), nzDraftFSGetRequest);
    }),
    loadNzExpiryDateDisplay: (0, _emberConcurrency.task)(function* (nzExpiryDateDisplayRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadNzExpiryDateDisplay'), nzExpiryDateDisplayRequest);
    }),
    deleteNzDraftFs: (0, _emberConcurrency.task)(function* (nzFinancingStatementDeleteRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.deleteNzDraftFs'), nzFinancingStatementDeleteRequest);
    }),
    loadNzCollateralProfileDefaults: (0, _emberConcurrency.task)(function* (collateralProfileGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzCollateralProfileDefaults'), collateralProfileGuid));
    })
    //endregion
  });
  _exports.default = _default;
});