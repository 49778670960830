define("esis-ui/models/transfer-financing-statement-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    esisSpgGuid: (0, _modelBase.attr)(),
    authorisedToTransfer: (0, _modelBase.attr)(),
    financingStatementsToTransfer: (0, _modelBase.attr)(),
    futureSpgId: (0, _modelBase.attr)(),
    transferAll: (0, _modelBase.attr)(),
    verifyingSecuredPartyEmail: (0, _modelBase.attr)(),
    eTag: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.set('financingStatementsToTransfer', []);
      this.validations = {
        futureSpgId: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Target SPG ID can not be blank'
          }
        },
        authorisedToTransfer: {
          custom: {
            validation: function validation(key, value) {
              return value;
            },
            message: 'You must declare before continuing'
          }
        },
        verifyingSecuredPartyEmail: {
          custom: {
            validation: function validation(key, value, model) {
              return Ember.isPresent(value) ? model.validateEmailAddress(value) : false;
            },
            message: 'Not a valid email'
          }
        },
        financingStatementsToTransfer: {
          custom: {
            validation: function validation(key, value, model) {
              return model.transferAll ? true : !(0, _lodash.isEmpty)(value);
            },
            message: 'You must select at least one FS'
          }
        }
      };
    }
  });
  _exports.default = _default;
});