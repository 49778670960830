define("esis-ui/components/areas/registration/nz/view/nz-fs-edit-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Edit Financing Statement Details',
    onSave() {},
    _loadBranchCodePicklist: (0, _emberConcurrency.task)(function* (model) {
      try {
        //BRANCH CODES
        const branchCodePicklist = yield this.get('picklistRepo.loadNzBranchCode').linked().perform(model.spgId);
        this.set('_branchCodeList', (0, _lodash.sortBy)(branchCodePicklist, 'title'));
        model.hasBranchCode = !(0, _lodash.isEmpty)(branchCodePicklist);
        if (!(0, _lodash.isNil)(model.branchGuid)) {
          this.set('_selectedBranchCode', (0, _lodash.find)(this.get('_branchCodeList'), {
            value: model.branchGuid
          }));
        }
        this._createModel(model);
      } catch (error) {
        this.get('logger').logError('nz-fs-edit-modal._loadBranchCodePicklist', error);
      }
    }),
    _createModel(model) {
      let fsModel = this.mapObject(model, this.createModel('nz-fs-details-request'));
      this.set('_fsRequest', fsModel);
      this.get('_fsRequest').save();
    },
    _reset() {
      this.setProperties({
        _selectedBranchCode: null,
        _fsRequest: null
      });
    },
    show(model) {
      this._reset();
      this._loadBranchCodePicklist.perform(model);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm() {
        let valid = this.get('_fsRequest').validate();
        if (valid && !(0, _lodash.isEmpty)(this.get('onSave'))) {
          this.get('onSave')(this.get('_fsRequest'));
        }
        return valid;
      },
      _onChangeBranchCode(selected) {
        this.set('_selectedBranchCode', selected);
        this.set('_fsRequest.branchGuid', selected.value);
        this.set('_fsRequest.branchCode', selected.title);
      }
    }
  });
  _exports.default = _default;
});