define("esis-ui/components/areas/registration/nz/wizard/nz-registration-wizard-container/component", ["exports", "esis-ui/components/areas/registration/nz/nz-registration-base", "ember-concurrency", "lodash"], function (_exports, _nzRegistrationBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzRegistrationBase.default.extend({
    model: null,
    init: function init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this.steps = [];
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Details',
        stepId: this.newGuid(),
        sortOrder: 1,
        stepComponentName: constants.wizardStepPath.registrationSpgStepNz
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Debtors',
        stepId: this.newGuid(),
        sortOrder: 2,
        stepComponentName: constants.wizardStepPath.registrationDebtorsStepNz
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Collaterals',
        stepId: this.newGuid(),
        sortOrder: 3,
        stepComponentName: constants.wizardStepPath.registrationCollateralStepNz
      }, 'wizard-step'));
      this.steps.pushObject(this.mapJsonObjectToModel({
        title: 'Review',
        stepId: this.newGuid(),
        sortOrder: 4,
        stepComponentName: constants.wizardStepPath.registrationReviewStepNz
      }, 'wizard-step'));
      this._options = {
        positionNavigationBtn: 'aligned',
        positionSaveBtn: 'top',
        positionSubmitBtn: 'top',
        alignSubmitBtn: 'right',
        showSubmitBtn: true,
        showSaveBtn: true,
        showNavigationBtn: false,
        saveBtnLabel: 'Save as Draft',
        smallSaveBtn: false,
        allowJump: false
      };
      this._loadModel.perform();
    },
    //region COMPUTED PROPERTY
    _wizardTitle: Ember.computed('model.esisId', function () {
      if (this.get('model.esisId') > 0) {
        return `FS : DRAFT-${this.get('model.esisId')}`;
      }
      return 'New Financing Statement';
    }),
    //endregion

    //region Task
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          const response = yield this.get('registrationRepo.loadNzDraftFs').perform({
            esisEntityGuid: this.get('credential.currentBusinessEntityGuid'),
            guid: this.get('tabModel.p0')
          });
          const registration = this._mapApiRegistrationResponseToModel(response);
          this.updateTabProperty('title', `FS DRAFT-${registration.esisId}`);
          this.set('model', registration);
        } else {
          const requestModel = this._createRegistrationModel();
          this.set('model', requestModel);
        }
      } catch (error) {
        this.get('logger').logError('nz-registration-wizard-container._loadModel', error);
      }
    }),
    _saveDraftRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.get('registrationRepo.saveNzDraftFs').linked().perform(this.get('model'));
        this._onRegistrationSaved(response);
        this.notifyInfo(`Financing statement saved. Draft-${response.esisId}`);
      } catch (e) {
        this.get('logger').logError('nz-registration-wizard-container._saveDraftRegistrationTask', e);
      }
    }),
    _submitNzFsTask: (0, _emberConcurrency.task)(function* (registrationModel) {
      try {
        this._showWaitModalDlg();
        yield this.get('registrationRepo.submitNewNzFs').perform(registrationModel);
        registrationModel.saveModel();
        this.notifyInfo(`Submitting registration ... this may take up to a minute`);
        this._closeRegistrationTab();
      } catch (e) {
        const severity = this.get('constants.backendErrorSeverity');
        if (e.errorSeverity === severity.error || e.errorSeverity === severity.warning) {
          registrationModel.set('displayException', true);
          registrationModel.set('validatErrorMessage', e.errorMessage);
        } else {
          this.get('logger').logError('nz-registration-wizard-container._submitNzFsTask', e);
        }
      } finally {
        this._hideWaitModalDlg();
      }
    }),
    //endregion

    _onRegistrationSaved(returnedModel) {
      const registration = this._mapApiRegistrationResponseToModel(returnedModel);
      let hasBranchCode = this.get('model.hasBranchCode');
      this.set('model', registration);
      this.set('model.hasBranchCode', hasBranchCode);
      this.updateTabProperty('p0', registration.guid);
      this.updateTabProperty('title', `FS DRAFT-${registration.esisId}`);
    },
    actions: {
      //region SAVE DRAFT Registration
      _saveDraftRegistration: function _saveDraftRegistration() {
        this.get('_saveDraftRegistrationTask').perform();
      }
      //endregion
    }
  });
  _exports.default = _default;
});