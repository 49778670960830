define("esis-ui/helpers/starts-with", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.startsWithInner = startsWithInner;
  function startsWithInner([value, test]) {
    return (0, _lodash.startsWith)(value, test);
  }
  var _default = Ember.Helper.helper(startsWithInner);
  _exports.default = _default;
});