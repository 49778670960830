define("esis-ui/components/shared/help-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "GgFbhNrm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",true],[10,\"class\",\"helpIcon\"],[10,\"href\",\"#\"],[11,\"onblur\",[28,\"action\",[[23,0,[]],\"_onFocusOutOfHelpIcon\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"_onFocusInHelpIcon\"],null]],[8],[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"question-circle\"],[[\"prefix\",\"class\"],[\"far\",\"attrHelpIcon ml-1\"]]],false],[0,\"\\n  \"],[4,\"bs-popover\",null,[[\"class\",\"placement\",\"triggerEvents\",\"renderInPlace\",\"visible\"],[\"dynamicFormsHelpIconPopover\",\"bottom\",\" \",[24,[\"renderInPlace\"]],[24,[\"_visiblePopover\"]]]],{\"statements\":[[1,[22,\"helpText\"],false]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/help-icon/template.hbs"
    }
  });
  _exports.default = _default;
});