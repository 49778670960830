define("esis-ui/models/nz-bussiness-digest", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    incorporationNumber: (0, _modelBase.attr)(),
    ppsrType: (0, _modelBase.attr)(),
    ppsrTypeDescription: (0, _modelBase.attr)(),
    ppsrTypeCode: (0, _modelBase.attr)(),
    statusDescription: (0, _modelBase.attr)(),
    statusCode: (0, _modelBase.attr)(),
    typeDescription: (0, _modelBase.attr)(),
    typeCode: (0, _modelBase.attr)(),
    organisationName: (0, _modelBase.attr)(),
    email: (0, _modelBase.attr)(),
    contactAddress: (0, _modelBase.attr)(),
    mailingAddress: (0, _modelBase.attr)(),
    phoneNumber: (0, _modelBase.attr)(),
    faxNumber: (0, _modelBase.attr)(),
    nzbn: (0, _modelBase.attr)()
  });
  _exports.default = _default;
});