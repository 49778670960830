define("esis-ui/models/branch-code-details-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    code: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    securedPartyGroupGuid: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        code: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Code must be specified'
          }
        },
        securedPartyGroupGuid: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'You must select a SPG'
          }
        }
      };
    }
  });
  _exports.default = _default;
});