define("esis-ui/components/areas/registration/au/view/au-registration-view/component", ["exports", "esis-ui/components/areas/registration/au/au-registration-base", "lodash", "ember-concurrency"], function (_exports, _auRegistrationBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auRegistrationBase.default.extend({
    //region INITIALIZATION ---
    _isDischarge: false,
    _persistedRegistration: null,
    _amended: false,
    _renewDlg: null,
    _changeTrigger: null,
    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _showEditAndSubmitBtn: Ember.computed('_showEdit', 'credential', function () {
      return this.get('_showEdit') && this.get('credential.hasCreateRegistrationPermissionAu');
    }),
    _showSaveBtn: Ember.computed('_changeTrigger', function () {
      return !(0, _lodash.isNil)(this.get('model')) && this.get('model').isDirty;
    }),
    _showRenewBtn: Ember.computed('model.{canRenew,collaterals.@each.isRegistrationEndTimeNotStated}', '_showEditAndSubmitBtn', function () {
      if (!(0, _lodash.isNil)(this.get('model'))) {
        return this.get('model.canRenew') && !this.get('_showEditAndSubmitBtn') && this.get('_isCitecUser') !== true && !this.get('model.collaterals').objectAt(0).isRegistrationEndTimeNotStated;
      }
      return false;
    }),
    _hasGrantor: Ember.computed('model.{grantors,grantors.@each.showGrantor}', function () {
      if (!(0, _lodash.isEmpty)(this.get('model.grantors'))) {
        (0, _lodash.forEach)(this.get('model.grantors'), item => {
          if (item.showGrantor) {
            return true;
          }
        });
      }
      return false;
    }),
    _registrationEndTimeDisplay: Ember.computed('model.collaterals.@each.{registrationEndTime,isRegistrationEndTimeNotStated}', function () {
      if (!(0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        if (this.get('model.collaterals').objectAt(0).isRegistrationEndTimeNotStated) {
          return 'Not Stated';
        }
        return this.dateAsStr(this.get('model.collaterals').objectAt(0).registrationEndTime);
      }
      return null;
    }),
    _isRegistrationEndTimeChanged: Ember.computed('model.collaterals.@each.{isRegistrationEndTimeChanged}', function () {
      if (!(0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        return this.get('model.collaterals').objectAt(0).isRegistrationEndTimeChanged;
      }
      return null;
    }),
    _registrationOldExpiryDisplay: Ember.computed('model.collaterals.@each.existingEndTime', function () {
      if (!(0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        return this.dateAsStr(this.get('model.collaterals').objectAt(0).existingEndTime);
      }
      return null;
    }),
    _expiryDateMessage: Ember.computed('model.collaterals.@each.expiryDateMessage', function () {
      if (!(0, _lodash.isEmpty)(this.get('model.collaterals'))) {
        return this.get('model.collaterals').objectAt(0).expiryDateMessage;
      }
      return null;
    }),
    //endregion

    //region METHODS
    _onDischarged() {
      this._closeRegistrationTab();
    },
    _onRegistrationSaved(registration) {
      this.set('model', this._mapApiRegistrationResponseToModel(registration));
      this.set('_changeTrigger', this.newGuid());
    },
    //endregion

    //region TASKS
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          const response = yield this.get('registrationRepo.loadRegistration').perform(this.get('tabModel.p0'));
          const registration = this._mapApiRegistrationResponseToModel(response);
          this.set('model', registration);
          this.updateTabProperty('title', `Reg ${registration.ppsrRegistrationNumber}`);
          this.set('_showAmendBtn', this.get('model.canAmend'));
          this.set('_showEdit', this.get('model.showEdit'));
        } else {
          // we should never get here unless adventurous user is hacking
          this.notifyError('Cannot create new registration from this screen, please use the create new registration wizard!');
        }
      } catch (error) {
        this.get('logger').logError('au-registration-view._loadModel', error);
        this.notifyError('Fail to load registration.');
      }
    }),
    _amendTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show();
        const response = yield this.get('_retrieveRegistrationTask').perform(this.get('model.guid'), false);
        if (response === true) {
          yield this._loadModel.perform();
          this.notifyInfo(`Registration (${this.get('model.ppsrRegistrationNumber')}) is synchronised to PPSR and ready for amendment`);
          this.set('_showAmendBtn', false);
          this.set('_showEdit', true);
          this.set('_amended', true);
        }
      } catch (error) {
        this.get('logger').logError('au-registration-view._amendTask', error);
        this.notifyError('Fail to synchronise registration from PPSR');
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    _cancelAmendTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show();
        const response = yield this.get('_retrieveRegistrationTask').perform(this.get('model.guid'), true);
        if (response === true) {
          yield this._loadModel.perform();
          this.notifyInfo(`Amending registration has been canceled`);
          this.set('_showEdit', false);
          this.set('_amended', false);
          this.set('_changeTrigger', this.newGuid());
        }
      } catch (error) {
        this.get('logger').logError('au-registration-view._cancelAmendTask', error);
        this.notifyError('Fail to synchronise registration from PPSR');
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    _reVerifyOrganization: (0, _emberConcurrency.task)(function* (grantor) {
      try {
        let verifyResult = yield this.get('validationRepo.reverifyOrganisation').perform(grantor.id);
        this.set('_verifiedGrantor', verifyResult);
        if (this.get('_verifiedGrantor.hasOrganisationNameChanged')) {
          this.set('_verifiedGrantor.organisationNewNameDisplay', verifyResult.organisationName.toUpperCase());
        }
        this.get('_verifiedGrantorModal').show(grantor, verifyResult);
      } catch (error) {
        this.get('logger').logError('au-registration-view._reVerifyOrganization', error);
        this.notifyError(`Verification failed. ${error}`);
      }
    }),
    _applyExpiryDateTask: (0, _emberConcurrency.task)(function* (request) {
      try {
        const constants = this.get('constants');
        if (!(0, _lodash.isNil)(request)) {
          let collateral = this.get('model.collaterals').objectAt(0);
          if (request.option === constants.renewRegTimeOption.noDuration) {
            collateral.setProperties({
              registrationEndTimeOption: request.option,
              isRegistrationEndTimeNotStated: true,
              registrationEndTime: null,
              isRegistrationEndTimeChanged: true
            });
          } else {
            const result = yield this.get('registrationRepo.getRegistrationExpiryInfo').linked().perform(request);
            collateral.setProperties({
              registrationEndTimeOption: request.option,
              registrationEndTime: result.endTime,
              expiryDateMessage: result.expiryDateMessage,
              isRegistrationEndTimeNotStated: null,
              isRegistrationEndTimeChanged: true
            });
          }
          this.get('_validateRegistrationTask').perform();
        }
      } catch (error) {
        this.get('logger').logError('au-registration-view._applyExpiryDateTask', error);
        this.notifyError(`Update expiry date failed. ${error}`);
      }
    }),
    _validateRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        let result = yield this.get('registrationRepo.validateRegistration').perform(this.get('model'));
        this.set('_isRegistrationReadyForSubmission', result.isValid);
        if (!result.isValid) {
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', result.validationErrorMessage);
          // this.notifyError(`Validation error : ${result.validationErrorMessage}`);
        }
      } catch (error) {
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', error);
      }
    }),
    //endregion

    actions: {
      _amend: function _amend() {
        if (!(0, _lodash.isNil)(this.get('model.guid'))) {
          this.get('_amendConfirmDlg').show('Latest version of Registration will be retrieved from PPSR before proceeding. Do you want to continue?');
        }
      },
      _onConfirmAmend() {
        this._amendTask.perform();
      },
      _cancelAmendment() {
        this.get('_retrieveInfoDlg').show(`Are you sure you want to cancel amending registration ${this.get('model.ppsrRegistrationNumber')}?`);
      },
      _onConfirmCancel() {
        this._cancelAmendTask.perform();
      },
      _openRegModal: function _openRegModal() {
        this.get('_regModalDialog').show(this.get('model'));
      },
      _onConfirmEditReg(editedModel, renewRequest) {
        this.mapOnlySourceProps(editedModel, this.get('model'));
        if (!(0, _lodash.isNil)(renewRequest) && renewRequest.isDirty) {
          this.get('_applyExpiryDateTask').perform(renewRequest);
        }
        this.set('_changeTrigger', this.newGuid());
      },
      _showRenewDlg: function _showRenewDlg(submit) {
        this.get('_renewDlg').show(submit);
      },
      _onConfirmRenew(renewResponse, submit) {
        if (submit === true) {
          this._closeRegistrationTab();
        }
      },
      _onEditCollateral() {
        let collaterals = this.get('model.collaterals');
        let collateralModel = collaterals.objectAt(0);
        this.get('_collateralEditDlg').show(collateralModel);
      },
      _onConfirmEditCollateral(collateral) {
        this.mapObject(collateral, this.get('model.collaterals')[0]);
        this.set('_changeTrigger', this.newGuid());
      },
      _onDeleteGrantor(grantor) {
        grantor.set('action', this.get('constants.actionType.remove'));
        this.set('_changeTrigger', this.newGuid());
      },
      _onVerifyGrantor(grantor) {
        let grantors = this.get('model.grantors');
        let grantorModel = (0, _lodash.find)(grantors, {
          id: grantor.id
        });
        this.get('_reVerifyOrganization').perform(grantorModel);
      },
      _onUpdateGrantorOrgName: function _onUpdateGrantorOrgName(grantor) {
        grantor.set('organisationName', this.get('_verifiedGrantor.organisationName'));
        this.set('_changeTrigger', this.newGuid());
      },
      _showAddGrantorDlg() {
        this.get('_grantorAddModal').show();
      },
      _onAddGrantor(grantor) {
        if ((0, _lodash.isNil)(this.get('model.grantors'))) {
          this.set('model.grantors', []);
        }
        grantor.set('sequenceNumber', this.get('model.grantors').length);
        this.get('model.grantors').unshiftObject(grantor);
        this.set('_changeTrigger', this.newGuid());
      }
    }
  });
  _exports.default = _default;
});