define("esis-ui/models/create-help-video", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    fileName: (0, _modelBase.attr)(),
    videoHeight: (0, _modelBase.attr)(),
    videoWidth: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        fileName: {
          custom: {
            validation: (key, value) => {
              return Ember.isPresent(value);
            },
            message: 'Video file name cannot be blank'
          }
        },
        videoHeight: {
          format: {
            with: /^[0-9]+$/,
            message: 'Video height must be numeric',
            allowBlank: true
          }
        },
        videoWidth: {
          format: {
            with: /^[0-9]+$/,
            message: 'Video width must be numeric',
            allowBlank: true
          }
        }
      };
    }
  });
  _exports.default = _default;
});