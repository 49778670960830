define("esis-ui/components/areas/spg/nz/view/nz-spg-view/component", ["exports", "esis-ui/components/areas/spg/nz/nz-spg-view-page-base", "ember-concurrency", "lodash"], function (_exports, _nzSpgViewPageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSpgViewPageBase.default.extend({
    //region INITIALIZATION ---
    model: null,
    _notEditing: true,
    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _hasSecuredParties: Ember.computed('model.securedParties', function () {
      return !(0, _lodash.isEmpty)(this.get('model.securedParties'));
    }),
    //endregion

    //region METHODS
    _createSpgModel(response) {
      this.set('model', this.mapJsonObjectToModel(response, 'secured-party-group-nz'));
      if (!(0, _lodash.isEmpty)(response.configSettings)) {
        let configSettingsModel = this.mapJsonObjectToModel(response.configSettings, 'secured-party-group-config-setting-nz');
        this.set('model.configSettings', configSettingsModel);
      } else {
        this.set('model.configSettings', this.createModel('secured-party-group-config-setting-nz'));
      }
      this.set('model.securedParties', []);
      if (!(0, _lodash.isEmpty)(response.securedParties)) {
        (0, _lodash.forEach)(response.securedParties, item => {
          let sp = this.mapJsonObjectToModel(item, 'secured-party-nz');
          if (!(0, _lodash.isEmpty)(item.mailingAddress)) {
            sp.set('mailingAddress', this.mapJsonObjectToModel(item.mailingAddress, 'address-nz'));
          }
          if (!(0, _lodash.isEmpty)(item.contactAddress)) {
            sp.set('contactAddress', this.mapJsonObjectToModel(item.contactAddress, 'address-nz'));
          }
          let _personActingOnBehalf = item.personActingOnBehalf;
          if (!(0, _lodash.isEmpty)(_personActingOnBehalf)) {
            sp.set('personActingOnBehalf', this.mapJsonObjectToModel(_personActingOnBehalf, 'secured-party-person-on-behalf-nz'));
            if (!(0, _lodash.isEmpty)(item.personActingOnBehalf.mailingAddress)) {
              sp.set('personActingOnBehalf.mailingAddress', this.mapJsonObjectToModel(_personActingOnBehalf.mailingAddress, 'address-nz'));
            }
            if (!(0, _lodash.isEmpty)(item.personActingOnBehalf.contactAddress)) {
              sp.set('personActingOnBehalf.contactAddress', this.mapJsonObjectToModel(_personActingOnBehalf.contactAddress, 'address-nz'));
            }
          }
          this.get('model.securedParties').pushObject(sp);
        });
      }
      this.get('model').saveModel();
    },
    //endregion

    //region TASKS
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        this.updateTabProperty('title', 'SPG ' + this.get('tabModel.options.spgId'));
        let response = yield this.get('spgRepo.loadNzSpgModel').perform(this.get('tabModel.p0'));
        this._createSpgModel(response);
      } catch (error) {
        this.get('logger').logError('nz-spg-view._loadModel', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _amend: function _amend() {
        this.set('_notEditing', false);
        this.updateTabProperty('title', 'Amend SPG ' + this.get('tabModel.options.spgId'));
      },
      _transfer: function _transfer() {
        this.get('_transferDlg').show();
      },
      _cancel: function _cancel() {
        this.set('_notEditing', true);
        this.set('model', null);
        this._loadModel.perform();
      },
      _onSubmitSpg: function _onSubmitSpg() {
        let submitSpgTask = this.get('submitUpdateNzSpg');
        let timeoutTask = this.get('runTimeout');
        let timeoutTaskInstance = timeoutTask.perform('saveSpgTaskInstance');
        this.set('saveTimeoutTaskInstance', timeoutTaskInstance);
        let saveSpgTaskInstance = submitSpgTask.perform(this.get('model'));
        this.set('saveSpgTaskInstance', saveSpgTaskInstance);
      },
      _editSecuredPartyGroup: function _editSecuredPartyGroup() {
        this.get('_spgDlg').show(this.get('model'));
      },
      _onConfirmEditSpg: function _onConfirmEditSpg(model) {
        this.set('model.spgName', model.spgName);
        this.set('model.configSettings', model.configSettings);
      },
      _onSpEditClick: function _onSpEditClick(item) {
        this.get('_spDlg').show(item);
      },
      _onConfirmEditSp: function _onConfirmEditSp(model) {
        this.mapOnlySourceProps(model, (0, _lodash.find)(this.get('model.securedParties'), ['securedPartyId', model.securedPartyId]));
      }
    }
  });
  _exports.default = _default;
});