define("esis-ui/components/areas/admin/email-notification/list/email-notification-list/component", ["exports", "esis-ui/components/areas/shared/page-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _pageBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    //region INITIALIZATION
    _emailGrid: null,
    _selectedEmail: null,
    _detailPanel: 'detailPanel',
    _selectedBusinessEntity: null,
    init() {
      this._super(...arguments);
      const app = this.get('app');
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklistMethod();

      //region EMAIL GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('emails')
            },
            parameterMap: (options, operation) => {
              options.businessEntityGuid = this.get('_selectedBusinessEntity.value');
              options.regionId = this.get('credential.currentRegion');
              let keyword = this.get('_filterCriteria');
              if (!Ember.isBlank(keyword)) {
                options.filterKeyword = keyword;
              }
              options._ = app.guid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                if (realData[i].statusTitle === 'Processed') {
                  realData[i].statusTitle = 'Sent';
                }
                realData[i].dateCreated = this.dateTimeAsStr(realData[i].dateCreated);
              }
              return realData;
            }
          },
          sort: [{
            field: "id",
            dir: "desc"
          }]
        },
        columns: [{
          field: "esisId",
          title: 'ESIS ID',
          filterable: false,
          width: "100px"
        }, {
          field: "emailTo",
          title: 'To',
          filterable: false,
          width: "170px"
        }, {
          field: "subject",
          title: 'Subject',
          filterable: false,
          width: "300px"
        }, {
          field: "statusTitle",
          title: 'Status',
          filterable: false,
          width: "70px"
        }, {
          field: 'entityTitle',
          title: 'Business Entity',
          filterable: false,
          width: "200px"
        }, {
          field: "userCreated",
          title: 'User',
          filterable: false,
          width: "100px"
        }, {
          field: "dateCreated",
          title: 'Date',
          filterable: false,
          width: "130px"
        }]
      });
      //endregion
    },

    //endregion

    //region METHODS
    _refreshGrid() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_emailGrid'))) {
          this.set('_selectedEmail', null);
          this.get('_emailGrid').refreshGrid(true);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid();
          }, 20);
        }
      });
    },
    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onSelectionChange: function _onSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedEmail')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedEmail', data);
        this.get('_emailPreview').reset();
        this.get('_emailPreview').load(data.guid);
      },
      _onRefreshData() {
        this._refreshGrid();
      },
      //region FILTER ACTIONS
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this._refreshGrid(true);
      },
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});