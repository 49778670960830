define("esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-item-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    show(collateralArgs, collItem) {
      if (!(0, _lodash.isEmpty)(collItem)) {
        this.set('_collItem', this.mapObject(collItem, this.createModel('nz-collateral-item-request')));
        this.get('_collItem').setProperties({
          indexId: collItem.indexId
        });
        this.set('_modalTitle', 'Edit Collateral Item');
        this.set('_confirmBtnLabel', 'Update');
      } else {
        this.set('_collItem', this.createModel('nz-collateral-item-request'));
        this.set('_modalTitle', 'Add Collateral Item');
        this.set('_confirmBtnLabel', 'Add');
      }
      this.get('_collItem').setProperties({
        collateralIndexId: collateralArgs.collateralIndexId,
        collateralNewCollateralGuid: collateralArgs.collateralNewCollateralGuid,
        collateralExistingCollId: collateralArgs.collateralExistingCollId,
        collateralType: collateralArgs.collateralType
      });
      this.set('_collateralTypeTitle', collateralArgs.collateralTypeTitle);
      this.get('_dialog').show();
    },
    //region Closure Actions
    onAddCollItem() {},
    onUpdateCollItem() {},
    //endregion
    actions: {
      _onConfirm() {
        if (this.get('_collItem').validate()) {
          if (Ember.isPresent(this.get('_collItem.indexId'))) {
            this.get('onUpdateCollItem')(this.get('_collItem'));
          } else {
            this.get('onAddCollItem')(this.get('_collItem'));
          }
          this.get('_dialog').hide();
        }
      }
    }
  });
  _exports.default = _default;
});