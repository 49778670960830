define("esis-ui/components/areas/bulk-file/au/list/au-bulk-file-list/component", ["exports", "esis-ui/components/areas/shared/page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _pageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    //region properties
    _bulkFileGrid: null,
    _selectedBulkFile: null,
    _myUploads: true,
    //region INIT
    init() {
      this._super(...arguments);
      const app = this.get('app');
      const constants = this.get('constants');
      this.get('event').on(constants.events.notification, this, '_forceRefreshGrid');

      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklists();

      //region BULK FILE GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('file-uploads')
            },
            parameterMap: (options, operation) => {
              options.viewName = this.get('_view');
              options.fileStatus = this.get('_selectedStatus.value');
              options.myUpload = this.get('_myUploads');
              options.businessEntityGuid = this.get('credential.currentBusinessEntityGuid');
              let keyword = this.get('_filterCriteria');
              if (!(0, _lodash.isEmpty)(keyword)) {
                options.filterKeyword = keyword;
              }
              options._ = this.newGuid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                if (realData[i].status === constants.statusValue.submitted || realData[i].status === constants.statusValue.confirmed || realData[i].status === constants.statusValue.processed) {
                  realData[i].class = 'submittedStatusIconColor';
                  realData[i].iconName = 'check';
                } else if (realData[i].status === constants.statusValue.uploaded || realData[i].status === constants.statusValue.processing) {
                  realData[i].class = 'pendingStatusIconColor';
                  realData[i].iconName = 'play';
                } else if (realData[i].status === constants.statusValue.partiallySubmitted || realData[i].status === constants.statusValue.waitingOnFunds || realData[i].status === constants.statusValue.partialError) {
                  realData[i].class = 'pendingDischargeStatusIconColor';
                  realData[i].iconName = 'exclamationTriangle';
                } else {
                  realData[i].class = 'otherStatusIconColor';
                  realData[i].iconName = 'exclamationTriangle';
                }
                realData[i].dateCreated = this.dateTimeAsStr(realData[i].dateCreated);
                realData[i].hideActionStates = [!realData[i].hasDetailedExceptionData, false, realData[i].canDelete || realData[i].fileTypeTitle === "VinDataCleanse", realData[i].canDelete || !realData[i].isBulkSearch, !realData[i].canDelete];
              }
              return realData;
            }
          },
          sort: [{
            field: "esisId",
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "45px",
          template: dataItem => {
            return `<span class="${(0, _lodash.isEmpty)(dataItem.class) ? 'unKnownStatusIconColor' : dataItem.class}">` + this.get('constants.fIcon')[dataItem.iconName] + `</span>`;
          }
        }, {
          field: "fileName",
          title: "File Name",
          width: "250px",
          filterable: false,
          template: '<a data-id="#:guid#" data-guid="#:guid#" data-esisid="#:esisId#" class="grid-link-id">#: fileName #</a>'
        }, {
          field: "description",
          title: "Description",
          width: "350px",
          filterable: false
        }, {
          field: "recordCount",
          title: "#Records",
          width: "80px",
          filterable: false
        }, {
          field: "submitCount",
          title: "#Submitted",
          width: "80px",
          filterable: false
        }, {
          field: "uploadSourceTitle",
          title: "Source",
          width: "70px",
          filterable: false
        }, {
          field: "fileTypeTitle",
          title: "Type",
          width: "100px",
          filterable: false
        }, {
          field: "usernameEdited",
          title: "User",
          width: "150px",
          filterable: false
        }, {
          field: "dateCreated",
          title: "Date",
          width: "150px",
          filterable: false
        }, {
          field: "statusTitle",
          title: "Status",
          width: "150px",
          filterable: false
        }]
      });

      //Context Menu Action Ids
      this._downloadFileExceptionActionId = 'auDownloadFileExceptionId';
      this._downloadFileActionId = 'auDownloadFileId';
      this._reportActionId = 'auReportId';
      this._deleteActionId = 'auDeleteBulkFileId';
      this._certificateDownloadId = 'auCertificateDownloadId';
      this._bulkFileGridContextMenu = [{
        title: 'Download Exceptions',
        id: this.get('_downloadFileExceptionActionId'),
        icon: 'file-download',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Download File',
        id: this.get('_downloadFileActionId'),
        icon: 'file-download',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Successfully Processed Extract',
        id: this.get('_reportActionId'),
        icon: 'file-download',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Search Certificate Bundle',
        id: this.get('_certificateDownloadId'),
        icon: 'file-download',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region HOOK
    willDestroyElement() {
      this._super(...arguments);
      const constants = this.get('constants');
      this.get('event').off(constants.events.notification, this, '_forceRefreshGrid');
    },
    //endregion

    //region COMPUTED PROPERTIES
    //endregion

    //region METHODS
    _loadPicklists() {
      const constants = this.get('constants');
      //STATUS LIST
      this.set('_filterByStatus', [{
        title: 'All',
        value: 'all'
      }, {
        title: 'Waiting on Funds',
        value: 'waitingOnFunds'
      }, {
        title: 'Completed',
        value: 'completed'
      }, {
        title: 'Completed with Errors',
        value: 'completedWithError'
      }]);
      this.set('_selectedStatus', this.get('_filterByStatus')[0]);

      //VIEW FILTER
      const options = [{
        title: 'Search',
        value: constants.bulkFileGridViewFilter.search
      }, {
        title: 'Registration',
        value: constants.bulkFileGridViewFilter.registration
      }, {
        title: 'Amendment',
        value: constants.bulkFileGridViewFilter.amendment
      }, {
        title: 'Discharge',
        value: constants.bulkFileGridViewFilter.discharge
      }, {
        title: 'All',
        value: constants.bulkFileGridViewFilter.all
      }];
      if (!this.get('credential.hasViewSearchPermissionAu') && !this.get('credential.hasViewAllSearchPermissionAu')) {
        options.unshift({
          title: 'Search',
          value: constants.bulkFileGridViewFilter.search
        });
      }
      if (!this.get('credential.hasViewRegistrationPermissionAu')) {
        options.unshift({
          title: 'Registration',
          value: constants.bulkFileGridViewFilter.registration
        }, {
          title: 'Amendment',
          value: constants.bulkFileGridViewFilter.amendment
        }, {
          title: 'Discharge',
          value: constants.bulkFileGridViewFilter.discharge
        });
      }
      this.set('_viewFilterOptions', options);
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.viewName'))) {
        this.set('_view', this.get('tabModel.options.viewName'));
      } else {
        this.set('_view', constants.bulkFileGridViewFilter.all);
      }
      this._refreshGrid();
    },
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_bulkFileGrid'))) {
          if (reset === true) {
            this.set('_selectedBulkFile', null);
          }
          this.get('_bulkFileGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    _forceRefreshGrid: function _forceRefreshGrid(notification) {
      const constants = this.get('constants');
      const logger = this.get('logger');
      if (notification.type === constants.notificationIdentifier.bulkSearchFileProcessed || notification.type === constants.notificationIdentifier.bulkRegistrationCreateFileProcessed || notification.type === constants.notificationIdentifier.bulkRegistrationAmendmentFileProcessed || notification.type === constants.notificationIdentifier.bulkRegistrationDischargeProcessed) {
        logger.logDebug('au-bulk-file-list -> bulk file notification', notification);
        this._refreshGrid(true);
      }
    },
    _openRegistrationGridTab: function _openRegistrationGridTab(esisId, args) {
      const constants = this.get('constants');
      if (Ember.isPresent(args) && Ember.isPresent(args.esisIdFilter)) {
        this.addTab('registrationListAu', null, null, true, {
          searchBy: constants.regSearchByOptions.esisId,
          filterCriteria: esisId
        });
        // this.addTab('registrationListAu', null, null, true, {viewName: viewName});
      } else {
        this.addTab('registrationListAu', null, null, true, {
          searchBy: constants.regSearchByOptions.fileId,
          filterCriteria: esisId
        });
      }
    },
    _openSearchGridTab(esisId, args) {
      const constants = this.get('constants');
      if (Ember.isPresent(args) && Ember.isPresent(args.esisIdFilter)) {
        this.addTab('searchListAu', null, null, true, {
          searchBy: constants.searchByOptions.searchId,
          filterCriteria: esisId
        });
      } else {
        this.addTab('searchListAu', null, null, true, {
          searchBy: constants.searchByOptions.fileId,
          filterCriteria: esisId
        });
      }
    },
    //endregion

    //region TASK
    _loadPreviewTask: (0, _emberConcurrency.task)(function* () {
      try {
        const selectedGuid = this.get('_selectedBulkFile.guid');
        const response = yield this.get('fileRepo.loadFileUploadDigest').linked().perform(selectedGuid);
        this.set('_detailDigest', this.mapJsonObjectToModel(response, 'file-upload-detail-digest'));
        this.set('_fileLineList', null);
        if (response.recordCount > 0) {
          const fileLineResponse = yield this.get('fileRepo.loadFileUploadLineDigest').linked().perform(selectedGuid);
          let fileUploadLineDigest = [];
          fileLineResponse.forEach(item => {
            let fileUploadLine = this.mapJsonObjectToModel(item, 'file-upload-line-digest');
            fileUploadLineDigest.pushObject(fileUploadLine);
          });
          this.set('_fileLineList', fileUploadLineDigest);
        }
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._loadPreviewTask', error);
      }
    }),
    _downloadFile: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('fileRepo.downloadFileUploaded').linked().perform(this.get('_detailDigest.guid'));
        this.notifySuccess(`Bulk file for ${this.get('_detailDigest.esisId')} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._downloadFile', error);
        this.notifyError(`Bulk file for ${this.get('_detailDigest.esisId')} failed to downloaded, please try again`);
      }
    }),
    _downloadFileExceptions: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('fileRepo.downloadFileErrorDetails').linked().perform(this.get('_detailDigest.guid'));
        this.notifySuccess(`Bulk file exceptions for ${this.get('_detailDigest.esisId')} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._downloadFileExceptions', error);
        this.notifyError(`Bulk file exceptions for ${this.get('_detailDigest.esisId')} failed to downloaded, please try again`);
      }
    }),
    _downloadReport: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show('Please wait while ESIS is busy generating bulk data extract');
        yield this.get('fileRepo.downloadBulkExtractReport').linked().perform(this.get('_detailDigest.guid'));
        this.notifySuccess(`Bulk registration extract report for ${this.get('_detailDigest.esisId')} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._downloadReport', error);
        this.notifyError(`Bulk file exceptions for ${this.get('_detailDigest.esisId')} failed to downloaded, please try again`);
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    _downloadSearchCertificate: (0, _emberConcurrency.task)(function* () {
      try {
        // check progress first
        const progress = yield this.get('fileRepo.bulkSearchCertificateBundleProgressByFile').linked().perform(this.get('_detailDigest.guid'));
        if (progress.retrievalCompleted === true) {
          this.get('_waitingModal').show('Please wait while ESIS is busy generating search certificate bundle');
          yield this.get('fileRepo.downloadBulkSearchCertificateBundleByFile').linked().perform(this.get('_detailDigest.guid'));
          this.notifySuccess(`Bulk search certificate bundle for ${this.get('_detailDigest.esisId')} has been downloaded`);
        } else {
          if (progress.hasRetrievalError === true) {
            this.notifyError(progress.retrievalErrorMsg);
          } else {
            this.notifyInfo(`Search certificate retrieval in progress, ${progress.progressPercentage}% completed, please try again later.`);
          }
        }
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._downloadReport', error);
        this.notifyError(`Bulk file exceptions for ${this.get('_detailDigest.esisId')} failed to downloaded, please try again`);
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    _deleteFileUploaded: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('fileRepo.deleteFileUpload').linked().perform(this.get('_detailDigest.guid'));
        this.notifySuccess(`Bulk registration file ${this.get('_detailDigest.esisId')} has been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._deleteFile', error);
      }
    }),
    _getBulkFileLinkedIds: (0, _emberConcurrency.task)(function* (fileLineDigest) {
      try {
        const constants = this.get('constants');
        let response = yield this.get('fileRepo.getBulkFileLinkedIds').perform(fileLineDigest.get('linkedInstanceIds'));
        if (response.length > 0 && Ember.isPresent(this.get('_detailDigest'))) {
          if (this.get('_detailDigest.fileType') === constants.fileType.search) {
            this._openSearchGridTab(response, {
              esisIdFilter: true
            });
          } else if (this.get('_detailDigest.fileType') === constants.fileType.registrationCsv || this.get('_detailDigest.fileType') === constants.fileType.registrationAmend || this.get('_detailDigest.fileType') === constants.fileType.bulkRegistrationDischarge) {
            this._openRegistrationGridTab(response, {
              esisIdFilter: true
            });
          }
        }
      } catch (error) {
        this.get('logger').logError('au-bulk-file-list._getBulkFileLinkedIds', error);
      }
    }),
    //endregion

    //region when tab activate
    onScreenChange( /*optionChanged, options*/
    ) {
      this._refreshGrid(); // soft refresh
    },

    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedBulkFile')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedBulkFile', data);
        if (!(0, _lodash.isNil)(this.get('_selectedBulkFile'))) {
          this.get('_loadPreviewTask').perform();
        }
      },
      _onLinkClick(selectedItem) {
        const id = selectedItem.data('esisid');
        const constants = this.get('constants');
        if (this.get('_detailDigest.fileType') === constants.fileType.search) {
          this._openSearchGridTab(id);
        } else if (this.get('_detailDigest.fileType') === constants.fileType.registrationCsv || this.get('_detailDigest.fileType') === constants.fileType.registrationAmend || this.get('_detailDigest.fileType') === constants.fileType.bulkRegistrationDischarge) {
          this._openRegistrationGridTab(id);
        }
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedSearch', selectedRow);
          switch (selectedOption) {
            case this.get('_downloadFileActionId'):
              {
                this._downloadFile.perform();
                break;
              }
            case this.get('_downloadFileExceptionActionId'):
              {
                this._downloadFileExceptions.perform();
                break;
              }
            case this.get('_reportActionId'):
              {
                this._downloadReport.perform();
                break;
              }
            case this.get('_deleteActionId'):
              {
                this.set('_deleteConfirmMsg', `Are you sure you want to delete bulk file ${this.get('_detailDigest.esisId')}? This action is not reversible.`);
                this.get('_deleteFile').show();
                break;
              }
            case this.get('_certificateDownloadId'):
              {
                this._downloadSearchCertificate.perform();
                break;
              }
          }
        }
      },
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _onConfirmDeleteFile() {
        this.get('_deleteFileUploaded').perform();
      },
      _onOpenGrid: function _onOpenGrid(fileLineDigest) {
        this._getBulkFileLinkedIds.perform(fileLineDigest);
      },
      //region FILTER ACTIONS
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view.value)) {
          this.set('_view', view.value);
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this.set('_view', 'all');
        this.set('_selectedStatus', this.get('_filterByStatus')[0]);
        this._refreshGrid(true);
      },
      _onMyUploadsChange(value) {
        this.set('_myUploads', value);
        this._refreshGrid();
      },
      _onChangeStatus(selected) {
        this.set('_selectedStatus', selected);
        this._refreshGrid();
      },
      //endregion

      //region File Upload
      _onOpenFileUploader() {
        this.get('_fileUploaderModal').show();
      },
      _onFileUploaded(fileType) {
        const constants = this.get('constants');
        if (fileType === constants.fileType.registrationCsv) {
          this.set('_view', constants.bulkFileGridViewFilter.registration);
        } else if (fileType === constants.fileType.registrationAmend) {
          this.set('_view', constants.bulkFileGridViewFilter.amendment);
        } else if (fileType === constants.fileType.bulkRegistrationDischarge) {
          this.set('_view', constants.bulkFileGridViewFilter.discharge);
        } else if (fileType === constants.fileType.search) {
          this.set('_view', constants.bulkFileGridViewFilter.search);
        }
        this.notifySuccess('File uploaded successfully.');
        this._refreshGrid(true);
      }

      //endregion
    }

    //endregion
  });
  _exports.default = _default;
});