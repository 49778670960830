define("esis-ui/components/shared/ember-bootstrap/emoji-picker/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash"], function (_exports, _sharedComponentBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    init() {
      this._super(...arguments);
      this.emojies = {
        'Faces': {
          '😂': ['😇', '😢', '🤦', '🤒', '😂', '😔', '😲', '😴', '😎', '🤔', '🦄', '😊']
        },
        'Activities': {
          '🏊': ['🎨', '📧', '🎮', '🎵', '📞', '⚽', '🏊', '🏋️', '🧘']
        },
        'Food and Nature': {
          '🌏': ['🥦', '🦋', '🐱', '🐶', '🍇', '☔', '🍎', '🌱', '☀', '🌳', '🌏']
        },
        'Non verbal': {
          '👌': ['💪', '🤝', '👌', '🤷', '💭', '👎', '👋']
        },
        'Validation': {
          '🏆': ['💯', '👏', '🤞', '🏆', '⭐', '✋', '🏳️‍🌈', '🙌', '👍']
        }
      };
    },
    actions: {
      _onSelectEmoji(emoji, po) {
        if (!(0, _lodash.isEmpty)(this.get('onSelectEmoji'))) {
          this.get('onSelectEmoji')(emoji);
        }
        po.close();
      }
    }
  });
  _exports.default = _default;
});