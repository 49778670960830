define("esis-ui/components/areas/registration/nz/view/nz-registration-view/component", ["exports", "esis-ui/components/areas/registration/nz/nz-registration-base", "lodash", "ember-concurrency", "ember-lifeline"], function (_exports, _nzRegistrationBase, _lodash, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzRegistrationBase.default.extend({
    //region INITIALIZATION ---
    _showWaitingSpin: true,
    _isAmendMode: false,
    // _isSearching: false,
    isAmend: true,
    hasCollateralShowCount: true,
    //nz-registration-base
    init() {
      this._super(...arguments);
      this.count = 0;
      //Maintain Options
      this._dischargeActionId = 'dischargeId';
      this._amendActionId = 'amendId';
      this._dischargeActionId = 'renewId';
      this._maintainOptions = [{
        title: 'Discharge',
        id: this.get('_dischargeActionId'),
        disabled: !this.get('credential.hasDischargeRegistrationPermissionNz')
      }, {
        title: 'Amend',
        id: this.get('_amendActionId'),
        disabled: !this.get('credential.hasAmendRegistrationPermissionNz')
      }, {
        title: 'Renew',
        id: this.get('_renewActionId'),
        disabled: !this.get('credential.hasRenewRegistrationPermissionNz')
      }];
      this._loadModel.perform();
    },
    //endregion

    //region COMPUTED PROPERTIES
    _canMaintain: Ember.computed('model', function () {
      return this.get('model.status') === this.get('constants.nzFSStatus.registered') || this.get('model.status') === this.get('constants.nzFSStatus.amending');
    }),
    _enableSaveBtn: Ember.computed('pageIsDirty', 'model.isDirty', function () {
      return this.get('pageIsDirty') || !(0, _lodash.isNil)(this.get('model')) && this.get('model').isDirty;
    }),
    pageIsDirty: Ember.computed('_debtorArrayLengthIsDirty', '_collItemsArrayLengthIsDirty', '_collArrayLengthIsDirty', function () {
      return this.get('_debtorArrayLengthIsDirty') || this.get('_collArrayLengthIsDirty') || this.get('_collItemsArrayLengthIsDirty');
    }),
    _collateralCount: Ember.computed('_collArrayLengthIsDirty', 'model.isDirty', function () {
      return this.get('model.collateral') ? (0, _lodash.filter)(this.get('model.collateral'), coll => {
        return coll.action !== this.get('constants.actionType.remove');
      }).length : 0;
    }),
    _debtorCount: Ember.computed('_debtorArrayLengthIsDirty', 'model.isDirty', function () {
      return this.get('model.debtors') ? (0, _lodash.filter)(this.get('model.debtors'), debtor => {
        return debtor.action !== this.get('constants.actionType.remove');
      }).length : 0;
    }),
    //endregion

    //region METHODS
    //region when tab activate
    onScreenChange: function onScreenChange() {
      if (!this.get('tabModel.options.isDraftAmendment') && !this.get('pageIsDirty') && !(0, _lodash.isEmpty)(this.get('model')) && !this.get('model.isDirty')) {
        this.set('_showWaitingSpin', false);
        this._loadModel.perform().then(() => {
          this.set('_showWaitingSpin', true);
        });
      }
    },
    //endregion

    _mapModel(response) {
      const registration = this._mapApiRegistrationResponseToModel(response);
      this.setProperties({
        _auditTrail: (0, _lodash.orderBy)(response.auditTrail, 'timestamp', 'desc'),
        _subordinations: response.subordinations,
        model: registration,
        _isAmendMode: response.isAmending === true,
        _debtorArrayLengthIsDirty: false,
        _collItemsArrayLengthIsDirty: false,
        _collArrayLengthIsDirty: false
      });
      this.get('model').setProperties({
        renewalDate: response.renewalDate,
        fsVersion: response.registrationVersion,
        fsNumber: response.registrationNumber,
        status: response.status,
        statusTitle: response.statusTitle,
        dischargeDate: response.dischargeDate,
        registrationDate: response.registrationDate,
        lastChangeDate: response.lastChangeDate,
        isAmend: true
      });
      this.get('model').saveModel();
      // this._filterCollateralTypePicklist();
      // this._filterCollateral();
      this._filterCollateral();
      (0, _emberLifeline.runTask)(this, () => {
        this._filterCollPicklistInCollDisplay();
      }, 1000);
    },
    _addCollateral(collaterals) {
      if (Ember.isNone(this.get('model.collateral'))) {
        this.set('model.collateral', []);
      }
      (0, _lodash.forEach)(collaterals, coll => {
        coll.setProperties({
          action: this.get('constants.actionType.add'),
          newCollateralGuid: this.get('app').guid()
        });
        if (!(0, _lodash.isEmpty)(coll.collateralItems)) {
          let item = coll.collateralItems[0];
          item.set('newItemGuid', this.get('app').guid());
        }
        this.get('model.collateral').unshiftObject(coll);
      });
      //Filter Collateral Type picklist
      if (collaterals.length === 1) {
        this.get('_collateralDisplay').filterCollateralTypePicklist(true, collaterals[0]);
        if ((0, _lodash.isNil)(this.get('_collateralFilters.collateralType')) || this.get('_collateralFilters.collateralType') === collaterals[0].collateralType) {
          this._filterCollateral();
        }
      } else {
        this.get('_collateralDisplay').filterCollateralTypePicklist();
        this._filterCollateral();
      }
    },
    //endregion

    //region TASKS
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          let isETagChanged = true;
          let currentETag = !(0, _lodash.isEmpty)(this.get('model')) ? this.get('model.eTag') : null;
          let response;
          const regNumber = this.get('tabModel.p0');
          const esisEntityGuid = this.get('credential.currentBusinessEntityGuid');
          if (this.get('tabModel.options.isDraftAmendment')) {
            let request = {
              guid: this.get('tabModel.options.guid'),
              fsNumber: regNumber,
              esisEntityGuid: esisEntityGuid
            };
            response = yield this.get('registrationRepo.loadNzDraftAmendFs').perform(request);
          } else {
            let request = {
              registrationNumber: regNumber,
              spgId: this.get('tabModel.options.spgId'),
              esisEntityGuid: esisEntityGuid
            };
            response = yield this.get('registrationRepo.loadNzFsModel').perform(request);
            isETagChanged = !(0, _lodash.isEmpty)(currentETag) ? currentETag !== response.eTag : true;
          }
          if (isETagChanged) {
            this._mapModel(response);
            this.updateTabProperty('title', `${this.get('model').fsNumber} ${this.get('tabModel.options.isDraftAmendment') ? '(Draft)' : ''}`);
            this.set('_regToRenewOrDischarge', [{
              registrationNumber: this.get('model').fsNumber,
              spgId: this.get('model').spgId,
              branchCode: this.get('model').branchCode
            }]);
          }
        } else {
          // we should never get here unless adventurous user is hacking
          this.notifyError('Cannot create new registration from this screen, please use the create new Registration wizard!');
        }
      } catch (error) {
        this.get('logger').logError('nz-registration-view._loadModel', error);
        this.notifyError('Fail to load registration.');
      }
    }),
    _loadNzExpiryDateDisplay: (0, _emberConcurrency.task)(function* () {
      try {
        let result = yield this.get('registrationRepo.loadNzExpiryDateDisplay').perform({
          expiryDate: null,
          expiryOption: this.get('model.expiryOption')
        });
        this.set('model.expiryDateFiveYearsDisplay', result);
      } catch (error) {
        this.get('logger').logError('nz-registration-view._loadNzExpiryDateDisplay', error);
      }
    }),
    //region Collateral
    _addCollateralTypeTask: (0, _emberConcurrency.task)(function* (args) {
      try {
        if (!(0, _lodash.isEmpty)(args.collateralProfile)) {
          let addCollItemModalArgs;
          let response = yield this.get('registrationRepo.loadNzCollateralProfileDefaults').perform(args.collateralProfile.item.guid);
          let collateralTypes = [];
          (0, _lodash.forEach)(response, item => {
            collateralTypes.pushObject({
              value: item.collateralTypeId,
              title: item.collateralTypeTitle,
              collDescription: item.collateralTypeDescription,
              itemDescription: item.collateralItemDescription,
              collateralProfileCode: args.collateralProfile.item.code
            });
          });
          addCollItemModalArgs = {
            modalTitle: `Add Collateral Types by Collateral Profile: ${args.collateralProfile.item.code}`,
            collateralTypes: collateralTypes
          };
          this.get('_addCollByProfileModal').show(addCollItemModalArgs);
        } else {
          this.get('_addCollModal').show(args.collateralType);
        }
      } catch (error) {
        this.get('logger').logError('nz-registration-view._addCollateralTypeTask', error);
      }
    }),
    //endregion
    //region SAVE and SUBMIT
    _saveAmendFSDraftTask: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.get('registrationRepo.saveAmendNzFsDraft').linked().perform(this.get('model'));
        if (!(0, _lodash.isEmpty)(this.get('tabModel')) && !(0, _lodash.isEmpty)(this.get('tabModel.options'))) {
          this.set('tabModel.options.isDraftAmendment', true);
          this.set('tabModel.p1', 'draft');
          this.updateTabProperty('title', `${response.registrationNumber} (Draft)`);
        }
        this._mapModel(response);
        this.notifyInfo(`Financing statement saved. FS-${response.registrationNumber}`);
      } catch (e) {
        this.get('logger').logError('nz-registration-view._saveAmendFSDraftTask', e);
      }
    }),
    _submitNzFsTask: (0, _emberConcurrency.task)(function* (registrationModel) {
      try {
        this._showWaitModalDlg();
        yield this.get('registrationRepo.submitAmendNzFs').perform(registrationModel);
        registrationModel.saveModel();
        this.notifyInfo(`Submitting registration ... this may take up to a minute`);
        this._closeRegistrationTab();
      } catch (e) {
        const severity = this.get('constants.backendErrorSeverity');
        if (e.errorSeverity === severity.error || e.errorSeverity === severity.warning) {
          registrationModel.set('displayException', true);
          registrationModel.set('validatErrorMessage', e.errorMessage);
        } else {
          this.get('logger').logError('nz-registration-view._submitNzFsTask', e);
        }
      } finally {
        this._hideWaitModalDlg();
      }
    }),
    //endregion
    //endregion

    actions: {
      _onSelectMaintainOption(actionId) {
        if (actionId === this.get('_dischargeActionId')) {
          this._showDischargeDlg(this.get('_regToRenewOrDischarge'));
        } else if (actionId === this.get('_amendActionId')) {
          this.set('_isAmendMode', true);
        } else if (actionId === this.get('_renewActionId')) {
          this._showRenewDlg(this.get('_regToRenewOrDischarge'));
        }
      },
      _onConfirmRenew() {
        this._loadModel.perform();
      },
      _onConfirmDischarge(hasAnyFailed) {
        if (!hasAnyFailed) {
          this._closeRegistrationTab();
        }
      },
      //region Details
      _onClickEditDetails() {
        this.get('_editFSDetailsModal').show(this.get('model'));
      },
      _onUpdateFSDetails(model) {
        this.mapOnlySourceProps(model, this.get('model'));
      },
      //endregion
      //region Debtor
      _onAddOrganisation() {
        this.get('_debtorAddDlg').show({
          debtorType: this.get('constants.nzSPOrDebtorType.organisation')
        });
      },
      _onAddPerson() {
        this.get('_debtorAddDlg').show({
          debtorType: this.get('constants.nzSPOrDebtorType.person')
        });
      },
      _onEditDebtor(item, index) {
        Ember.set(item, 'indexId', index);
        this.get('_debtorAddDlg').show(item);
      },
      _onDeleteDebtor(item) {
        let debtors = this.get('model.debtors');
        if (!(0, _lodash.isNil)(item.debtorId)) {
          item.set('action', this.get('constants.actionType.remove'));
        } else if (!(0, _lodash.isEmpty)(item.newDebtorGuid)) {
          let removedDebtor = (0, _lodash.find)(debtors, {
            newDebtorGuid: item.newDebtorGuid
          });
          debtors.removeObject(removedDebtor);
        } else if (!(0, _lodash.isEmpty)(item.existingDebtorId)) {
          let removedDebtor = (0, _lodash.find)(debtors, {
            existingDebtorId: item.existingDebtorId
          });
          debtors.removeObject(removedDebtor);
          this.set('_debtorArrayLengthIsDirty', true);
        }
      },
      _saveDebtor(debtorModel) {
        if (Ember.isNone(this.get('model.debtors'))) {
          this.set('model.debtors', []);
        }
        let debtors = this.get('model.debtors');
        if ((0, _lodash.isNil)(debtorModel.debtorId)) {
          if (!(0, _lodash.isEmpty)(debtorModel.newDebtorGuid)) {
            let updatedDebtor = (0, _lodash.find)(debtors, {
              newDebtorGuid: debtorModel.newDebtorGuid
            });
            this.mapObject(debtorModel, updatedDebtor);
          } else if (!(0, _lodash.isEmpty)(debtorModel.existingDebtorId)) {
            let updatedDebtor = (0, _lodash.find)(debtors, {
              existingDebtorId: debtorModel.existingDebtorId
            });
            this.mapObject(debtorModel, updatedDebtor);
          } else {
            debtorModel.set('newDebtorGuid', this.get('app').guid());
            debtorModel.set('action', this.get('constants.actionType.add'));
            debtors.unshiftObject(debtorModel);
          }
        } else {
          let updatedDebtor = (0, _lodash.find)(debtors, {
            debtorId: debtorModel.debtorId
          });
          debtorModel.set('action', this.get('constants.actionType.amend'));
          this.mapObject(debtorModel, updatedDebtor);
        }
      },
      //endregion
      //region Collateral
      _onClickAddCollType: function _onClickAddCollType() {
        this.get('_selectCollTypeModal').show();
      },
      _onSelectCollateralType(args) {
        this._addCollateralTypeTask.perform(args);
      },
      _onAddCollateral(collateral) {
        this._addCollateral([collateral]);
      },
      _onAddCollateralByProfile(collaterals) {
        this._addCollateral(collaterals);
      },
      _onClickRemoveColl(collateral) {
        if (!(0, _lodash.isNil)(collateral.collateralId)) {
          let removedColl = (0, _lodash.find)(this.get('model.collateral'), {
            collateralId: collateral.collateralId
          });
          removedColl.set('action', this.get('constants.actionType.remove'));
        } else if (!(0, _lodash.isEmpty)(collateral.newCollateralGuid)) {
          let removedColl = (0, _lodash.find)(this.get('model.collateral'), {
            newCollateralGuid: collateral.newCollateralGuid
          });
          this.get('model.collateral').removeObject(removedColl);
        } else if (!(0, _lodash.isEmpty)(collateral.existingCollId)) {
          let removedColl = (0, _lodash.find)(this.get('model.collateral'), {
            existingCollId: collateral.existingCollId
          });
          this.get('model.collateral').removeObject(removedColl);
          this.set('_collArrayLengthIsDirty', true);
        }

        //Filter Collateral Type picklist
        this.get('_collateralDisplay').filterCollateralTypePicklist();

        //Filter Collateral
        this._filterCollateral();
      },
      _onClickEditColl(collateral, collIndex) {
        Ember.set(collateral, 'indexId', collIndex);
        this.get('_editCollModal').show(collateral);
      },
      _onEditColl(collateral) {
        let updatedColl;
        if (!(0, _lodash.isNil)(collateral.collateralId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            collateralId: collateral.collateralId
          });
          collateral.set('action', this.get('constants.actionType.amend'));
        } else if (!(0, _lodash.isEmpty)(collateral.newCollateralGuid)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            newCollateralGuid: collateral.newCollateralGuid
          });
        } else if (!(0, _lodash.isEmpty)(collateral.existingCollId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            existingCollId: collateral.existingCollId
          });
        }
        if (!(0, _lodash.isEmpty)(updatedColl)) {
          this.mapOnlySourceProps(collateral, updatedColl);
        }

        //Filter Collateral
        this._filterCollateral();
      },
      _onClickRemoveCollItem(collateral, item) {
        let updatedColl;
        let removedCollItem;
        if (!(0, _lodash.isNil)(collateral.collateralId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            collateralId: collateral.collateralId
          });
          if (!(0, _lodash.isNil)(item.itemId)) {
            updatedColl.set('action', this.get('constants.actionType.amend'));
            removedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
              itemId: item.itemId
            });
            removedCollItem.set('action', this.get('constants.actionType.remove'));
          } else if (!(0, _lodash.isEmpty)(item.existingItemId)) {
            removedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
              existingItemId: item.existingItemId
            });
            updatedColl.collateralItems.removeObject(removedCollItem);
            this.set('_collItemsArrayLengthIsDirty', true);
          }
        } else if (!(0, _lodash.isEmpty)(collateral.newCollateralGuid)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            newCollateralGuid: collateral.newCollateralGuid
          });
        } else if (!(0, _lodash.isEmpty)(collateral.existingCollId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            existingCollId: collateral.existingCollId
          });
          removedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
            existingItemId: item.existingItemId
          });
          updatedColl.collateralItems.removeObject(removedCollItem);
          this.set('_collItemsArrayLengthIsDirty', true);
        }
        if (!(0, _lodash.isEmpty)(item.newItemGuid)) {
          removedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
            newItemGuid: item.newItemGuid
          });
          updatedColl.collateralItems.removeObject(removedCollItem);
        }
        //Filter Collateral
        this._filterCollateral();
      },
      _onClickEditCollItem(collateral, collItem) {
        //only for the modal indexId check
        Ember.set(collItem, 'indexId', 1);
        //
        this.get('_addCollItemModal').show({
          collateralIndexId: collateral.collateralId,
          collateralNewCollateralGuid: collateral.newCollateralGuid,
          collateralExistingCollId: collateral.existingCollId,
          collateralTypeTitle: collateral.collateralTypeTitle,
          collateralType: collateral.collateralType
        }, collItem);
      },
      _onUpdateCollItem(collItem) {
        let updatedColl;
        let updatedCollItem;
        if (!(0, _lodash.isNil)(collItem.collateralIndexId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            collateralId: collItem.collateralIndexId
          });
          if (!(0, _lodash.isNil)(collItem.itemId)) {
            updatedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
              itemId: collItem.itemId
            });
            updatedColl.set('action', this.get('constants.actionType.amend'));
            collItem.set('action', this.get('constants.actionType.amend'));
          } else if (!(0, _lodash.isEmpty)(collItem.existingItemId)) {
            updatedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
              existingItemId: collItem.existingItemId
            });
          }
        } else if (!(0, _lodash.isEmpty)(collItem.collateralNewCollateralGuid)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            newCollateralGuid: collItem.collateralNewCollateralGuid
          });
        } else {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            existingCollId: collItem.collateralExistingCollId
          });
          updatedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
            existingItemId: collItem.existingItemId
          });
        }
        if (!(0, _lodash.isEmpty)(collItem.newItemGuid)) {
          updatedCollItem = (0, _lodash.find)(updatedColl.collateralItems, {
            newItemGuid: collItem.newItemGuid
          });
        }
        if (!(0, _lodash.isEmpty)(updatedCollItem)) {
          this.mapObject(collItem, updatedCollItem);
          updatedCollItem.set('aircraftClassTitle', collItem.aircraftClassTitle);
        }

        //Filter Collateral
        this._filterCollateral();
      },
      _onClickAddCollItem(collateral) {
        this.get('_addCollItemModal').show({
          collateralIndexId: collateral.collateralId,
          collateralNewCollateralGuid: collateral.newCollateralGuid,
          collateralExistingCollId: collateral.existingCollId,
          collateralTypeTitle: collateral.collateralTypeTitle,
          collateralType: collateral.collateralType
        });
      },
      _onAddCollItem(collItem) {
        collItem.setProperties({
          action: this.get('constants.actionType.add'),
          newItemGuid: this.get('app').guid()
        });
        let updatedColl;
        if (!(0, _lodash.isNil)(collItem.collateralIndexId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            collateralId: collItem.collateralIndexId
          });
          updatedColl.set('action', this.get('constants.actionType.amend'));
        } else if (!(0, _lodash.isEmpty)(collItem.collateralNewCollateralGuid)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            newCollateralGuid: collItem.collateralNewCollateralGuid
          });
        } else if (!(0, _lodash.isEmpty)(collItem.collateralExistingCollId)) {
          updatedColl = (0, _lodash.find)(this.get('model.collateral'), {
            existingCollId: collItem.collateralExistingCollId
          });
        }
        if (Ember.isNone(updatedColl.collateralItems)) {
          updatedColl.set('collateralItems', []);
        }
        updatedColl.collateralItems.unshiftObject(collItem);

        //Filter Collateral
        this._filterCollateral();
      },
      //endregion
      //region Submit and Create
      _saveAmendFSDraft: function _saveAmendFSDraft() {
        this.get('_saveAmendFSDraftTask').perform();
      }
      //endregion
    }
  });
  _exports.default = _default;
});