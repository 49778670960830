define("esis-ui/models/address", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    id: (0, _modelBase.attr)(),
    countryCode: (0, _modelBase.attr)(),
    countryTitle: (0, _modelBase.attr)(),
    line1: (0, _modelBase.attr)(),
    line2: (0, _modelBase.attr)(),
    line3: (0, _modelBase.attr)(),
    locality: (0, _modelBase.attr)(),
    postcode: (0, _modelBase.attr)(),
    state: (0, _modelBase.attr)(),
    addressType: (0, _modelBase.attr)(),
    parentId: (0, _modelBase.attr)(),
    hasChange: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    displayAddress: Ember.computed('id', 'addressIsDirty', 'countryCode', 'countryTitle', 'line1', 'line2', 'line3', 'locality', 'postcode', 'state', function () {
      let address = ' ';
      if (Ember.isPresent(this.get('line1'))) {
        address += this.get('line1') + ', ';
      }
      if (Ember.isPresent(this.get('line2'))) {
        address += this.get('line2') + ', ';
      }
      if (Ember.isPresent(this.get('line3'))) {
        address += this.get('line3') + ', ';
      }
      if (Ember.isPresent(this.get('locality'))) {
        address += this.get('locality') + ', ';
      }
      if (Ember.isPresent(this.get('state'))) {
        address += this.get('state') + ', ';
      }
      if (Ember.isPresent(this.get('postcode'))) {
        address += this.get('postcode') + ', ';
      }
      if (Ember.isPresent(this.get('countryTitle'))) {
        address += this.get('countryTitle');
      }
      return Ember.String.htmlSafe(address);
    }),
    init() {
      this._super(...arguments);
      this.validations = {
        line1: {
          presence: true
        },
        postcode: {
          presence: true
        }
      };
    }
  });
  _exports.default = _default;
});