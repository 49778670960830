define("esis-ui/components/areas/spg/nz/view/nz-sp-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // areas/spg/nz/view/sp-modal/component.js
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        'model': null,
        '_detailHasMailingAddress': null,
        '_personOnBehalfHasMailingAddress': null,
        '_validationErrorMessage': null
      });
    },
    //endregion

    //region COMPUTED PROPERTIES
    _manageDetailMailingAddressButtonTitle: Ember.computed('_detailHasMailingAddress', function () {
      return this.get('_detailHasMailingAddress') ? "Remove Mailing Address" : "Add Mailing Address";
    }),
    _managePersonOnBehalfMailingAddressButtonTitle: Ember.computed('_personOnBehalfHasMailingAddress', function () {
      return this.get('_personOnBehalfHasMailingAddress') ? "Remove Mailing Address" : "Add Mailing Address";
    }),
    _mailingAddressComponent: Ember.observer('_mailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_mailingAddress'))) {
        this.get('_mailingAddress').loadPicklistData();
      }
      return null;
    }),
    _contactAddressComponent: Ember.observer('_contactAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_contactAddress'))) {
        this.get('_contactAddress').loadPicklistData();
      }
      return null;
    }),
    _personActingOnBehalfMailingAddressComponent: Ember.observer('_personActingOnBehalfMailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_personActingOnBehalfMailingAddress'))) {
        this.get('_personActingOnBehalfMailingAddress').loadPicklistData();
      }
      return null;
    }),
    _personActingOnBehalfContactAddressComponent: Ember.observer('_personActingOnBehalfContactAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_personActingOnBehalfContactAddress'))) {
        this.get('_personActingOnBehalfContactAddress').loadPicklistData();
      }
      return null;
    }),
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        model: null,
        _detailHasMailingAddress: null,
        _personOnBehalfHasMailingAddress: null,
        _validationErrorMessage: null
      });
    },
    show(model) {
      this._reset();
      this.set('model', this.mapObject(model, this.createModel('secured-party-nz')));
      this.set('model.contactAddress', this.mapObject(model.contactAddress, this.createModel('address-nz')));
      if (model.hasMailingAddress) {
        this.set('_detailHasMailingAddress', true);
        this.set('model.mailingAddress', this.mapObject(model.mailingAddress, this.createModel('address-nz')));
      }
      if (model.isOrganisation) {
        let personOnBehalfModel = this.createModel('secured-party-person-on-behalf-nz');
        this.set('model.personActingOnBehalf', this.mapObject(model.personActingOnBehalf, personOnBehalfModel));
        if (model.personActingOnBehalf.hasMailingAddress) {
          this.set('_personOnBehalfHasMailingAddress', true);
          let address = model.personActingOnBehalf.mailingAddress;
          this.set('model.personActingOnBehalf.mailingAddress', this.mapObject(address, this.createModel('address-nz')));
        }
      }
      this.get('model').saveModel();
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _validateEmail: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('validationRepo.validateEmail').perform({
          emailAddress: this.get('model.email')
        });
        if (response.isValid) {
          this.set('model.errors.email', '');
        } else {
          this.set('model.errors.email', response.validationErrorMessage);
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validateEmail', error);
      }
    }),
    _validatePhone: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.phoneNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.phoneNumber')
          });
          if (response.isValid) {
            this.set('_phoneNumberError', '');
          } else {
            this.set('_phoneNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_phoneNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePhone', error);
      }
    }),
    _validateFax: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.faxNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.faxNumber')
          });
          if (response.isValid) {
            this.set('_faxNumberError', '');
          } else {
            this.set('_faxNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_faxNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validateFax', error);
      }
    }),
    _validatePAOBEmail: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('validationRepo.validateEmail').perform({
          emailAddress: this.get('model.personActingOnBehalf.email')
        });
        if (response.isValid) {
          this.set('model.personActingOnBehalf.errors.email', '');
        } else {
          this.set('model.personActingOnBehalf.errors.email', response.validationErrorMessage);
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePAOBEmail', error);
      }
    }),
    _validatePAOBPhone: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.personActingOnBehalf.phoneNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.personActingOnBehalf.phoneNumber')
          });
          if (response.isValid) {
            this.set('_paobPhoneNumberError', '');
          } else {
            this.set('_paobPhoneNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_paobPhoneNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePAOBPhone', error);
      }
    }),
    _validatePAOBFax: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('model.personActingOnBehalf.faxNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('model.personActingOnBehalf.faxNumber')
          });
          if (response.isValid) {
            this.set('_paobFaxNumberError', '');
          } else {
            this.set('_paobFaxNumberError', response.validationErrorMessage);
          }
        } else {
          this.set('_paobFaxNumberError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-wizard-sp-modal._validatePAOBFax', error);
      }
    }),
    _save: (0, _emberConcurrency.task)(function* () {
      let valid = this.get('model').validate();
      valid = this.get('model.contactAddress').validate() && valid;
      if (this.get('_detailHasMailingAddress')) {
        valid = this.get('model.mailingAddress').validate() && valid;
      }
      let personActingOnBehalfValid = true;
      if (this.get('model.isOrganisation')) {
        personActingOnBehalfValid = this.get('model.personActingOnBehalf').validate() && personActingOnBehalfValid;
        personActingOnBehalfValid = this.get('model.personActingOnBehalf.contactAddress').validate() && personActingOnBehalfValid;
      }
      if (this.get('model.isOrganisation') && this.get('_personOnBehalfHasMailingAddress')) {
        personActingOnBehalfValid = this.get('model.personActingOnBehalf.mailingAddress').validate() && personActingOnBehalfValid;
      }
      yield this._validateEmail.perform();
      if (this.get('model.isOrganisation')) {
        yield this._validatePAOBEmail.perform();
      }
      let emailHasError = !(0, _lodash.isEmpty)(this.get('model.errors.email')) || !(0, _lodash.isEmpty)(this.get('model.personActingOnBehalf.errors.email'));
      if (valid && personActingOnBehalfValid && !emailHasError && !(0, _lodash.isEmpty)(this.get('onConfirm'))) {
        this.get('onConfirm')(this.get('model'));
        this.get('_dialog').hide();
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region BUTTON ACTIONS
      _onManageDetailMailingAddressClick() {
        this.toggleProperty('_detailHasMailingAddress');
        if (this.get('_detailHasMailingAddress')) {
          this.set('model.mailingAddress', this.createModel('address-nz'));
        } else {
          this.set('model.mailingAddress', null);
        }
      },
      _onManagePersonOnBehalfMailingAddressClick() {
        this.toggleProperty('_personOnBehalfHasMailingAddress');
        if (this.get('_personOnBehalfHasMailingAddress')) {
          this.set('model.personActingOnBehalf.mailingAddress', this.createModel('address-nz'));
        } else {
          this.set('model.personActingOnBehalf.mailingAddress', null);
        }
      },
      _copyAddress: function _copyAddress() {
        let model = this.get('model');
        this.get('_mailingAddress').copyAddress(model.contactAddress);
      },
      _copyPersonOnBehalfAddress: function _copyPersonOnBehalfAddress() {
        let model = this.get('model.personActingOnBehalf');
        this.get('_personActingOnBehalfMailingAddress').copyAddress(model.contactAddress);
      },
      //endregion

      _onConfirm() {
        this._save.perform();
      },
      //region email phone fax validations
      //person
      _validateEmail() {
        this._validateEmail.perform();
      },
      _validatePhone() {
        this._validatePhone.perform();
      },
      _validateFax() {
        this._validateFax.perform();
      },
      //PAOB
      _validatePAOBEmail() {
        this._validatePAOBEmail.perform();
      },
      _validatePAOBPhone() {
        this._validatePAOBPhone.perform();
      },
      _validatePAOBFax() {
        this._validatePAOBFax.perform();
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});