define("esis-ui/components/shared/buttons/share-link-button/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: 'span',
    sharedLink: Ember.computed('app.currentDynamicTabId', function () {
      const app = this.get('app');
      const screen = app.getRootScreen();
      if (Ember.isPresent(screen)) {
        let queryString = `?sn=${screen.name}`;
        if (Ember.isPresent(screen.p0)) {
          queryString += `&p0=${screen.p0}`;
        }
        if (Ember.isPresent(screen.p1)) {
          queryString += `&p1=${screen.p1}`;
        }
        return `${this.currentHost()}/home${queryString}`;
      }
      return null;
    }).readOnly()
  });
  _exports.default = _default;
});