define("esis-ui/components/areas/bulk-file/nz/list/preview/nz-bulk-file-preview/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _detailPanel: 'detailPanel',
    _fileLinePanel: 'fileLinePanel',
    //endregion

    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this._loadPicklist();
      //region File Line GRID
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: this.get('app').getApiUrl(constants.apiEndpoint.loadNzBulkResult)
            },
            parameterMap: (options, operation) => {
              if (operation === 'read') {
                let requestParam = this.get('createRestGridFilter')(options);
                requestParam.fileUploadGuid = this.get('model.guid');
                requestParam.status = this.get('_selectedStatus.value');
                return JSON.stringify(requestParam);
              }
              return options;
            }
          },
          schema: {
            data: _data => {
              let realData = _data.lines;
              if ((0, _lodash.isEmpty)(realData)) {
                return [];
              }
              for (let i = 0; i < realData.length; i++) {
                if (!(0, _lodash.isEmpty)(realData[i].errorMessage)) {
                  realData[i].errorMessageDisplay = realData[i].errorMessage.join(', ');
                } else {
                  realData[i].errorMessageDisplay = '';
                }
                if ((0, _lodash.isNil)(realData[i].registrationNumber)) {
                  realData[i].registrationNumber = '';
                }
              }
              return realData;
            }
          },
          sort: [{
            field: 'sequence',
            dir: 'asc'
          }]
        },
        // height: '100%',
        columns: [{
          field: 'sequence',
          title: "Sequence",
          width: "170px",
          filterable: false,
          sortable: false
        }, {
          field: 'spgId',
          title: "SPG ID",
          width: "170px",
          filterable: false,
          sortable: false
        }, {
          field: 'registrationNumber',
          title: "Registration Number",
          width: "170px",
          filterable: false,
          sortable: false,
          template: '<a data-regnumber="#:registrationNumber#" class="grid-link-id">#: registrationNumber #</a>'
        }, {
          field: 'statusTitle',
          title: "Status",
          filterable: false,
          sortable: false,
          width: "150px"
        }, {
          field: 'errorMessageDisplay',
          title: "Error Message",
          filterable: false,
          sortable: false,
          template: '<span class="validationErrorMessage">#: errorMessageDisplay #</span>'
        }]
      });
      //endregion
    },

    _fileGridSelectionChanged: Ember.observer('model.{guid,hasLineDetailInfo}', function () {
      if (this.get('model.hasLineDetailInfo')) {
        if (!(0, _lodash.isEmpty)(this.get('model.guid'))) {
          if (Ember.isPresent(this.get('_tabPreview')) && this.get('_tabPreview').isTabActive(this.get('_fileLinePanel'))) {
            this._refreshGrid();
          }
        }
      } else {
        if (Ember.isPresent(this.get('_tabPreview')) && this.get('_tabPreview').isTabActive(this.get('_fileLinePanel'))) {
          this.get('_tabPreview').activateTab(this.get('_detailPanel'));
        }
      }
    }),
    //region Methods
    _loadPicklist() {
      const constants = this.get('constants');
      //STATUS LIST
      this.set('_fileUploadStatusList', [{
        title: 'All File Statuses',
        value: constants.nzFileUploadStatus.all
      }, {
        title: 'Processed',
        value: constants.nzFileUploadStatus.processed
      }, {
        title: 'Failed',
        value: constants.nzFileUploadStatus.failed
      }]);
      this.set('_selectedStatus', this.get('_fileUploadStatusList')[0]);
    },
    _refreshGrid() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_fileLineGrid'))) {
          this.get('_fileLineGrid').refreshGrid();
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid();
          }, 20);
        }
      });
    },
    //endregion

    actions: {
      _onFileLineTabLoad() {
        this._refreshGrid();
      },
      _onLinkClick(selectedItem) {
        if (Ember.isPresent(selectedItem)) {
          const regNumber = selectedItem.data('regnumber');
          this.addTab('registrationListNz', null, null, true, {
            searchBy: this.get('constants.nzFsGridSearchByList.registrationNumber'),
            filterCriteria: regNumber
          });
        }
      },
      _onChangeStatus(selected) {
        this.set('_selectedStatus', selected);
        this._refreshGrid();
      }
    }
  });
  _exports.default = _default;
});