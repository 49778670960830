define("esis-ui/components/shared/ember-bootstrap/modal/confirm-deletion-dialog/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //--- INITIALIZATION ---
    tagName: 'span',
    showDialog: false,
    _dialog: null,
    cancelBtnLabel: true,
    confirmButton: true,
    dirtyRecordConfirmMsg: '',
    confirmModalTitle: '',
    _additionalArgs: null,
    btnTitle: 'Delete',
    init() {
      this._super(...arguments);
      this.cancelBtnLabel = "No";
      this.confirmBtnLabel = "Yes";
    },
    confirmMsg: Ember.computed('dirtyRecordConfirmMsg', function () {
      if (!Ember.isPresent(this.get('dirtyRecordConfirmMsg'))) {
        return `Are you sure you want to ${this.get('btnTitle')} the record?`;
      }
      return this.get('dirtyRecordConfirmMsg');
    }),
    modalTitle: Ember.computed('confirmModalTitle', function () {
      if (!Ember.isPresent(this.get('confirmModalTitle'))) {
        return 'Please Confirm';
      }
      return this.get('confirmModalTitle');
    }),
    // --- METHODS ---
    show: function show(...args) {
      if (Ember.isPresent(this.get('_dialog'))) {
        if (Ember.isPresent(args)) {
          this.set('_additionalArgs', args);
        }
        this.get('_dialog').show();
      }
    },
    // --- ACTIONS ---
    actions: {
      confirm: function confirm() {
        if (Ember.isPresent(this.get('onConfirm'))) {
          this.get('onConfirm')(this.get('_additionalArgs'));
        }
      },
      cancel: function cancel() {
        if (Ember.isPresent(this.get('onCancel'))) {
          this.get('onCancel')(this.get('_additionalArgs'));
        }
      }
    }
  });
  _exports.default = _default;
});