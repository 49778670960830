define("esis-ui/components/areas/search/au/view/au-search-organisation/component", ["exports", "esis-ui/components/areas/search/au/view/au-search-view-base", "lodash", "ember-concurrency", "esis-ui/models/organisation-search-criteria"], function (_exports, _auSearchViewBase, _lodash, _emberConcurrency, _organisationSearchCriteria) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSearchViewBase.default.extend({
    //region INITIALISATION

    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region METHODS
    _loadOrganisationSearchCriteria(searchHeader) {
      let criteria = _organisationSearchCriteria.default.create();
      let searches = searchHeader.get('searches');
      const constants = this.get('constants');
      searches.forEach(function (search) {
        if (Ember.isPresent(search.criteriaOrganisationNumberType)) {
          if (search.criteriaOrganisationNumberType === constants.organisationNumberType.ABN) {
            criteria.set('abn', search.criteriaOrganisationNumber);
            criteria.set('abnSelected', true);
          } else if (search.criteriaOrganisationNumberType === constants.organisationNumberType.ACN) {
            criteria.set('acn', search.criteriaOrganisationNumber);
            criteria.set('acnSelected', true);
          } else if (search.criteriaOrganisationNumberType === constants.organisationNumberType.ARBN) {
            criteria.set('arbn', search.criteriaOrganisationNumber);
            criteria.set('arbnSelected', true);
          } else if (search.criteriaOrganisationNumberType === constants.organisationNumberType.ARSN) {
            criteria.set('arsn', search.criteriaOrganisationNumber);
            criteria.set('arsnSelected', true);
          }
        } else if (Ember.isPresent(search.criteriaOrganisationName)) {
          if (Ember.isBlank(criteria.get('searchName'))) {
            criteria.set('searchName', search.criteriaOrganisationName);
            criteria.set('searchNameSelected', true);
          } else {
            criteria.set('verifiedName', search.criteriaOrganisationName);
            criteria.set('verifiedNameSelected', true);
          }
        }
      });
      return criteria;
    },
    _createOrganisationSearchCriteria: function _createOrganisationSearchCriteria() {
      let criteria = _organisationSearchCriteria.default.create();
      criteria.set('abnSelected', false);
      criteria.set('acnSelected', false);
      criteria.set('arbnSelected', false);
      criteria.set('arsnSelected', false);
      criteria.set('searchNameSelected', false);
      criteria.set('verifiedNameSelected', false);
      criteria.set('abn', '');
      criteria.set('acn', '');
      criteria.set('arbn', '');
      criteria.set('arsn', '');
      criteria.set('searchName', '');
      return criteria;
    },
    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          const response = yield this.get('searchRepo.loadSearch').linked().perform(this.get('tabModel.p0'));
          const searchHeader = this._createSearchHeader('', response);
          this.set('model', searchHeader);
          this._mapSearches(this.get('model'));
          this.set('criteria', this._loadOrganisationSearchCriteria(searchHeader));
          this.updateTabProperty('title', `Organisation Search ${searchHeader.esisId}`);
          this.set('_isReadOnly', true);
        } else {
          const newRecord = this._createSearchHeader(this.get('constants.searchType.searchByGrantor'));
          let criteria = this._createOrganisationSearchCriteria();
          this.set('model', newRecord);
          this.set('criteria', criteria);
        }
        this.get('model').saveModel();
        this.get('criteria').save();
      } catch (error) {
        this.get('logger').logError('au-search-organisation._loadModel', error);
        this.notifyError('Fail to load search');
      }
    }),
    //endregion

    actions: {
      _onBlurAbn: function _onBlurAbn(value) {
        this.set('criteria.abn', value);
        this.set('criteria.abnSelected', Ember.isPresent(this.get('criteria.abn')));
      },
      _onBlurAcn: function _onBlurAcn(value) {
        this.set('criteria.acn', value);
        this.set('criteria.acnSelected', Ember.isPresent(this.get('criteria.acn')));
      },
      _onBlurArbn: function _onBlurArbn(value) {
        this.set('criteria.arbn', value);
        this.set('criteria.arbnSelected', Ember.isPresent(this.get('criteria.arbn')));
      },
      _onBlurArsn: function _onBlurArsn(value) {
        this.set('criteria.arsn', value);
        this.set('criteria.arsnSelected', Ember.isPresent(this.get('criteria.arsn')));
      },
      _onBlurSearchName: function _onBlurSearchName() {
        this.set('criteria.searchNameSelected', Ember.isPresent(this.get('criteria.searchName')));
      },
      _onUpdateTab(searchResultSummary) {
        this.updateTabProperty('title', `Organisation Search ${searchResultSummary.searchHeaderEsisId}`);
        this.updateTabProperty('p0', searchResultSummary.searchHeaderGuid);
      },
      _onLoadSearch(searchHeader) {
        this.set('criteria', this._loadOrganisationSearchCriteria(searchHeader));
        this.get('criteria').save();
        this.get('_grantorFilter').loadFilterCriteria();
      }
    }
  });
  _exports.default = _default;
});