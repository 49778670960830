define("esis-ui/components/shared/kendo/tree-list/tree-list-remote-ds/component", ["exports", "esis-ui/components/shared/kendo/tree-list/tree-list-base", "ember-lifeline"], function (_exports, _treeListBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _treeListBase.default.extend({
    init() {
      this._super(...arguments);
      const self = this;
      const logger = self.get('logger');
      self._defaultOptions.height = "100%";
      this._defaultOptions.dataSource = {
        type: "odata-v4",
        transport: {
          read: {
            dataType: "json",
            beforeSend: xhr => {
              xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
            }
          }
        },
        schema: {
          data: function data(_data) {
            return _data.value;
          }
        },
        sort: [{
          field: "id",
          dir: "desc"
        }],
        pageSize: 30,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        error: function error(e) {
          logger.logError('Network error, remote data cannot be loaded into the TreeList.', e);
        },
        requestStart: function requestStart() {
          if (self.$("div.k-loading-mask").length === 0) {
            kendo.ui.progress(self.$(self.element), true);
          }
        },
        requestEnd: function requestEnd(e) {
          kendo.ui.progress(self.$(self.element), false);
          if (e.type === 'read' && e.response) {
            (0, _emberLifeline.runTask)(self, 'kendoTreeListInitSelectRow', 500);
          }
        }
      };
    }
  });
  _exports.default = _default;
});