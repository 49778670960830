define("esis-ui/components/areas/admin/security-roles/view/security-role-view/component", ["exports", "esis-ui/components/areas/admin/security-roles/security-role-page-base", "ember-concurrency", "lodash"], function (_exports, _securityRolePageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _securityRolePageBase.default.extend({
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this._permissionToDisplay = null;
      this._loadModel.perform();
    },
    //endregion

    isSuperAdmin: Ember.computed('model', function () {
      return this.get('model.roleName') === "Super Admin";
    }),
    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('tabModel.p0'))) {
          let result = yield this.get('adminRepo.loadSecurityRoleDetailDigest').perform(this.get('tabModel.p0'));
          let roleDigest = this.mapJsonObjectToModel(result, 'security-role');
          roleDigest.setProperties({
            permissions: []
          });
          (0, _lodash.forEach)(result.permissions, item => {
            let rolePermission = this.mapJsonObjectToModel(item, 'role-permission');
            roleDigest.permissions.pushObject(rolePermission);
          });
          this.set('_permissionToDisplay', this.convertPermissionToDisplay(roleDigest.permissions));
          this.updateTabProperty('title', `Edit Security Role`);
          this.set('model', roleDigest);
          this.get('model').saveModel();
        } else {
          let model = this.createModel('security-role');
          model.setProperties({
            permissions: []
          });
          let currentPermissions = yield this.get('adminRepo.loadCurrentUserPermissions').perform();
          (0, _lodash.forEach)(currentPermissions, item => {
            let permission = this.mapJsonObjectToModel(item, 'role-permission');
            model.permissions.pushObject(permission);
          });
          this.set('_permissionToDisplay', this.convertPermissionToDisplay(model.permissions));
          this.set('model', model);
          this.get('model').saveModel();
        }
      } catch (error) {
        this.get('logger').logError('system-user-view._loadModel', error);
      }
    }),
    _saveRole: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.saveSecurityRole').perform(this.get('model'));
        this.get('model').save();
        (0, _lodash.forEach)(this.get('model.permissions'), item => {
          item.save();
        });
        const constants = this.get('constants');
        this.get('cache').deleteLookup(this.getCacheKey(constants.cacheType.lookupCache, constants.picklistType.systemUserRolePicklist));
        this.notifySuccess('Security role has been saved successfully');
        this.removeTab('securityRoleList');
      } catch (e) {
        this.get('logger').logError('security-role-view._saveRole', e);
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', e.errorMessage);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onPermissionChange(item, value) {
        let constPermissions = this.get('constants.permissions');
        let permissions = this.get('model.permissions');
        item.set('selected', value);
        switch (item.permissionName) {
          case this.get('constants.permissions.searchAu'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewAllSearchesAu
                }));
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewMySearchesAu
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.searchNz'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewSearchNz
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.renewNz'):
          case this.get('constants.permissions.registerNz'):
          case this.get('constants.permissions.dischargeNz'):
          case this.get('constants.permissions.amendNz'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewRegistrationsNz
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.approveDischarge'):
          case this.get('constants.permissions.registerAu'):
          case this.get('constants.permissions.dischargeAu'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewRegistrationsAu
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.bulkloadAu'):
          case this.get('constants.permissions.applyBulkCharge'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewBulkloadAu
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.bulkAmendNz'):
          case this.get('constants.permissions.bulkDischargeNz'):
          case this.get('constants.permissions.bulkloadNz'):
          case this.get('constants.permissions.bulkRegisterNz'):
          case this.get('constants.permissions.bulkRenewNz'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewBulkloadNz
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.manageBranchCodeNz'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewAndUseBranchCodeNz
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.manageContractTypes'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewContractTypes
                }));
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.copyContract
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.manageEntity'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewEntity
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.manageSPGAu'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewSPGAu
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.manageSPGNz'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.viewSPGNz
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
          case this.get('constants.permissions.manageUserCredAccessKeyExpiry'):
            {
              if (value) {
                let items = [];
                items.pushObject((0, _lodash.find)(permissions, {
                  permissionName: constPermissions.manageUsers
                }));
                (0, _lodash.forEach)(items, p => {
                  p.set("selected", true);
                });
              }
              break;
            }
        }
      },
      _onClickSave() {
        if (this.get('model').validate()) {
          this._saveRole.perform();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});