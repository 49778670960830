define("esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-item-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wP9w8GwM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"registerAs\",\"modalTitle\",\"name\",\"renderInPlace\",\"customClass\",\"dismiss\",\"onConfirm\",\"confirmBtnLabel\"],[[24,[\"_dialog\"]],[24,[\"_modalTitle\"]],\"nzFsAddCollItemWizardModal\",[24,[\"renderInPlace\"]],\"nzFsAddCollItemWizardModal\",false,[28,\"action\",[[23,0,[]],\"_onConfirm\"],null],[24,[\"_confirmBtnLabel\"]]]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"role\",\"form\"],[10,\"class\",\"form-horizontal mt-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"control-label field-label col-2 px-0\"],[8],[0,\"Collateral Type\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-10 mt-1\"],[8],[0,\"\\n        \"],[1,[22,\"_collateralTypeTitle\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[28,\"areas/registration/nz/wizard/nz-collateral-item-edit\",null,[[\"model\"],[[24,[\"_collItem\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-item-modal/template.hbs"
    }
  });
  _exports.default = _default;
});