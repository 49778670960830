define("esis-ui/components/areas/shell/nav-bar-base", ["exports", "esis-ui/components/shared/shared-component-base", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    router: Ember.inject.service(),
    authSession: Ember.computed.oneWay('session'),
    _conStatusClass: 'gray',
    _ppsrServiceStatus: null,
    //unknown

    init() {
      this._super(...arguments);
      this.get('event').on(this.get('constants.events.connectionStatusChanged'), this, '_connectionStatusChanged');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.connectionStatusChanged'), this, '_connectionStatusChanged');
      });
      this.get('event').on(this.get('constants.events.ppsrConnectionStatusChanged'), this, '_ppsrStatusChanged');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.ppsrConnectionStatusChanged'), this, '_ppsrStatusChanged');
      });
    },
    //region Notification Handler
    _ppsrStatusChanged(connectionStatus) {
      this.get('logger').logDebug('PPSR Status Changed', connectionStatus);
      this.set('_ppsrServiceStatus', connectionStatus);
      this._connectionStatusChanged(this.get('constants.connectionStatus.good'));
    },
    //endregion

    actions: {
      addScreen: function addScreen(name) {
        this.addTab(name);
      },
      _onLogout: function _onLogout() {
        this.get('accountRepo.logoutUser').perform().then(() => {
          this.get('cache').resetAllCache();
          this.get('session').invalidate();
        }).catch(reason => {
          this.get('logger').logError('nav-bar-base._onLogout', reason);
          this.get('session').invalidate();
        });
      },
      clearCache: function clearCache() {
        this.get('adminRepo.clearServerCache').perform().then(() => {
          this.notifySuccess('Server side cache has been cleared');
        });
        this.get('cache').resetAllCache();
        this.notifySuccess('Client side cache has been cleared');
      }
    },
    //region Computed Properties
    businessEntityLabel: Ember.computed('credential.currentBusinessEntity', function () {
      if (Ember.isPresent(this.get('credential.currentBusinessEntity'))) {
        return `${this.get('credential.currentBusinessEntity.title')} (${this.get('credential.currentBusinessEntity.item.esisId')})`;
      }
      return 'Please select business entity';
    }),
    _hasSwitchRoutePermission: Ember.computed('credential.currentUser', function () {
      return Ember.isPresent(this.get('credential.currentUser')) && this.get('credential.currentUser.hasAuPermission') && this.get('credential.currentUser.hasNzPermission');
    })
    //endregion
  });
  _exports.default = _default;
});