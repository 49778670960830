define("esis-ui/components/shared/preview/preview-no-data/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mi+GVAbL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"No data to display. \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/preview/preview-no-data/template.hbs"
    }
  });
  _exports.default = _default;
});