define("esis-ui/components/areas/shared/picker/resource-file-picker/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---

    confirmBtnLabel: 'OK',
    closeButton: false,
    renderInPlace: true,
    customClass: '',
    init() {
      this._super(...arguments);
      this._resourceView = {};
      //region TREE VIEW
      const self = this;
      const app = self.get('app');
      self.set('_resourceTreeOption', {
        autoBind: false,
        height: 620,
        dataSource: {
          transport: {
            read: {
              type: 'GET',
              url: app.getApiUrl('resource-category-tree')
            },
            parameterMap: function parameterMap(data, operation) {
              if (operation !== "read" && Ember.isPresent(data)) {
                return kendo.stringify(data);
              }
            }
          },
          schema: {
            data: function data(_data) {
              return _data.resource;
            }
          },
          sort: [{
            field: "sortOrder",
            dir: "asc"
          }]
        },
        columns: [{
          field: "title",
          title: "Title",
          width: "100px",
          filterable: false
        }]
      });
      //endregion
    },

    //endregion

    //region COMPUTED PROPERTIES
    _disabledConfirmButton: Ember.computed('_resourceView', function () {
      return (0, _lodash.isEmpty)(this.get('_resourceView'));
    }),
    //endregion

    //region METHODS
    _refreshTree() {
      if (Ember.isPresent(this.get('_resourceTree'))) {
        this.get('_resourceTree').refreshTree(true);
      } else {
        (0, _emberLifeline.runTask)(this, '_refreshTree', 50);
      }
    },
    show() {
      this.get('_dialog').show();
      (0, _emberLifeline.runTask)(this, () => {
        this._refreshTree();
      }, 200);
    },
    _loadResource: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('adminRepo.loadResources').perform(this.get('_category.guid'), this.get('constants.resourceFilter.files'));
        this.set('_resources', response);
        this.set('_resourceView', this.get('_resources').objectAt(0));
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onSelectCategory(category) {
        if ((0, _lodash.isEmpty)(category)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_category', category);
        this.set('_resourceView', {});
        // this.set('_resources', []);
        this._loadResource.perform();
      },
      _onSelectResource(resource) {
        this.set('_resourceView', resource);
      },
      _onAdd() {
        if (!(0, _lodash.isEmpty)(this.get('onAdd'))) {
          this.get('onAdd')(this.get('_resourceView'));
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});