define("esis-ui/services/theme-service", ["exports", "esis-ui/mixins/esis-utils"], function (_exports, _esisUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  //import {computed, observer} from '@ember/object';
  var _default = Ember.Service.extend(_esisUtils.default, {
    credential: Ember.inject.service('credential-service'),
    cache: Ember.inject.service('cache-service'),
    app: Ember.inject.service('app-service'),
    constants: Ember.inject.service('app-constants'),
    logger: Ember.inject.service('logging-service'),
    init() {
      this._super(...arguments);
      // this.set('availableLanguage', []);
      const cache = this.get('cache');
      if (Ember.isPresent(cache.getSetting('preferredTheme'))) {
        this.set('currentTheme', cache.getSetting('preferredTheme'));
      } else {
        this.set('currentTheme', this.get('constants.appTheme.lightTheme'));
      }
    },
    // fetch() {
    //     const currentTheme = this.get('currentTheme');
    //
    // },
    setTheme(theme) {
      this.get('logger').logInfo('Switching theme', this.get('currentTheme'), "to", theme);
      this.set('currentTheme', theme);
      this.get('cache').putSetting('preferredTheme', theme);
    }
  });
  _exports.default = _default;
});