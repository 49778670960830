define("esis-ui/components/areas/shared/nz/nz-organisation-list-item-display/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    model: null,
    //endregion

    //region ACTION
    actions: {
      _onItemSelect() {
        if (this.get('model.item.statusCode') === "50") this.get('onSelect')();
      }
    }
    //endregion
  });
  _exports.default = _default;
});