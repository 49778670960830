define("esis-ui/components/shared/slider/slider-component/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    actions: {
      action: function action( /*type,options*/
      ) {},
      sectionsAction: function sectionsAction( /*type,options*/
      ) {},
      error: function error( /*code,options*/
      ) {},
      changed: function changed( /*value,options*/
      ) {},
      valueChanged: function valueChanged(action, button) {
        if (action === 'pressed') {
          this.set('DDAUvalue', button.value);
        }
      }
    }
  });
  _exports.default = _default;
});