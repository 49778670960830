define("esis-ui/components/areas/spg/au/wizard/au-spg-service-address-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash"], function (_exports, _wizardStepBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region INITIALIZATION
    validateStep: function validateStep() {
      let model = this.get('model');
      let valid = true;
      model.set('addressForServicePhysicalAddress.errors', []);
      model.set('addressForServiceMailingAddress.errors', []);
      let physicalAddressErrors = model.get('addressForServicePhysicalAddress.errors');
      if ((0, _lodash.isEmpty)(model.get('addressForServicePhysicalAddress.line1'))) {
        physicalAddressErrors.set('line1', 'Field cannot be blank');
        valid = false;
      } else {
        physicalAddressErrors.set('line1', '');
      }
      if ((0, _lodash.isEmpty)(model.get('addressForServicePhysicalAddress.postcode'))) {
        physicalAddressErrors.set('postcode', 'Field cannot be blank');
        valid = false;
      } else {
        physicalAddressErrors.set('postcode', '');
      }
      let mailingAddressErrors = model.get('addressForServiceMailingAddress.errors');
      if ((0, _lodash.isEmpty)(model.get('addressForServiceMailingAddress.line1'))) {
        mailingAddressErrors.set('line1', 'Field cannot be blank');
        valid = false;
      } else {
        mailingAddressErrors.set('line1', '');
      }
      if ((0, _lodash.isEmpty)(model.get('addressForServiceMailingAddress.postcode'))) {
        mailingAddressErrors.set('postcode', 'Field cannot be blank');
        valid = false;
      } else {
        mailingAddressErrors.set('postcode', '');
      }
      return valid;
    },
    onActivate() {
      this.get('_mailingAddress').loadPicklistData();
      this.get('_physicalAddress').loadPicklistData();
    },
    //endregion

    //region ACTIONS
    actions: {
      _copy: function _copy() {
        let model = this.get('model');
        this.get('_physicalAddress').copyAddress(model.addressForServiceMailingAddress);
      },
      _clearPhysicalAddress() {
        this.get('_physicalAddress').clearAddress();
      },
      _clearMailingAddress() {
        this.get('_mailingAddress').clearAddress();
      }
    }
    //endregion
  });
  _exports.default = _default;
});