define("esis-ui/routes/nz", ["exports", "esis-ui/routes/route-base"], function (_exports, _routeBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _routeBase.default.extend({
    model() {
      this.set('credential.currentRegion', this.get('constants.region.nz'));
      this._super(...arguments);
    }
  });
  _exports.default = _default;
});