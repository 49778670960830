define("esis-ui/components/areas/shared/buttons/resource-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9ZZFYY83",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",false],[12,\"role\",\"button\"],[3,\"action\",[[23,0,[]],\"click\"]],[8],[1,[28,\"fa-icon\",[\"file-alt\"],[[\"prefix\",\"size\",\"class\",\"fixedWidth\",\"title\"],[\"far\",\"2x\",\"resourceIcon havePointer\",true,[24,[\"title\"]]]]],false],[0,\" \"],[9],[0,\"\\n\\n\"],[1,[28,\"areas/shared/picker/resource-picker\",null,[[\"registerAs\"],[[24,[\"_resourcePicker\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/buttons/resource-icon/template.hbs"
    }
  });
  _exports.default = _default;
});