define("esis-ui/models/nz-search-org-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    organisationName: (0, _modelBase.attr)(),
    incorporationNumber: (0, _modelBase.attr)(),
    nzbn: (0, _modelBase.attr)(),
    hasBranchCode: (0, _modelBase.attr)(),
    branchGuid: (0, _modelBase.attr)(),
    branchCode: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.hasBranchCode = false;
      this.validations = {
        organisationName: {
          custom: [{
            validation: (key, value) => {
              if (this.get('isOrganisation')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Organisation name can not be blank'
          }, {
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('nzbn')) && !Ember.isPresent(model.get('incorporationNumber'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'You must enter criteria into at least one field'
          }]
        },
        incorporationNumber: {
          custom: {
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('nzbn')) && !Ember.isPresent(model.get('organisationName'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'You must enter criteria into at least one field'
          }
        },
        nzbn: {
          custom: [{
            validation: (key, value, model) => {
              if (!Ember.isPresent(model.get('incorporationNumber')) && !Ember.isPresent(model.get('organisationName'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'You must enter criteria into at least one field'
          }, {
            validation: (key, value) => {
              if (Ember.isPresent(value)) {
                return value.length === 13;
              }
              return true;
            },
            message: 'NZBN must be 13 characters long'
          }],
          format: {
            with: /^[0-9]+$/,
            message: 'NZBN must be numeric',
            allowBlank: true
          }
        },
        branchCode: {
          custom: {
            validation: (key, value) => {
              if (this.get('hasBranchCode')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Branch Code can't be blank"
          }
        }
      };
    }
  });
  _exports.default = _default;
});