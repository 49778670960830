define("esis-ui/models/contract-type-default", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    description: (0, _modelBase.attr)(),
    collateralType: (0, _modelBase.attr)(),
    // collateralTypeTitle: attr(),
    collateralClassType: (0, _modelBase.attr)(),
    collateralClassTypeTitle: (0, _modelBase.attr)(),
    collateralDescription: (0, _modelBase.attr)(),
    areAssetsSubjectToControl: (0, _modelBase.attr)(),
    isInventory: (0, _modelBase.attr)(),
    isPmsi: (0, _modelBase.attr)(),
    isSubordinate: (0, _modelBase.attr)(),
    isTransitional: (0, _modelBase.attr)(),
    areProceedsClaimed: (0, _modelBase.attr)(),
    proceedsClaimedDescription: (0, _modelBase.attr)(),
    durationYears: (0, _modelBase.attr)(),
    udf1: (0, _modelBase.attr)(),
    udf2: (0, _modelBase.attr)(),
    udf3: (0, _modelBase.attr)(),
    udf4: (0, _modelBase.attr)(),
    udf5: (0, _modelBase.attr)(),
    isSerialised: (0, _modelBase.attr)(),
    isRegistrationEndTimeNotStated: (0, _modelBase.attr)(),
    sequenceNumber: (0, _modelBase.attr)(),
    collateralTypeTitle: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      if (this.get('collateralType') === constants.collateralType.consumer) {
        return 'Consumer';
      } else if (this.get('collateralType') === constants.collateralType.commercial) {
        return 'Commercial';
      } else {
        return '';
      }
    }),
    showSerialisedStatus: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent((0, _lodash.find)(constants.collateralClassTypeSerialisedStatus, {
        'value': this.get('collateralClassType')
      }))) {
        return true;
      }
      return false;
    }),
    showSubjectToControl: Ember.computed('collateralClassType', 'isInventory', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapWithExcept) && (Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.currency) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.negotiableInstrument) || Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.account)) || this.get('isInventory');
      }
      return null;
    }),
    setSubjectToControl: Ember.observer('showSubjectToControl', 'isInventory', function () {
      if (!this.get('showSubjectToControl')) {
        this.set('areAssetsSubjectToControl', null);
      }
      return null;
    }),
    showCollateralDescription: Ember.computed('isSerialised', 'showSerialNumberForNotMv', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !this.get('isAircraft');
      }
      return null;
    }),
    showInventory: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapWithExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.currency) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.negotiableInstrument);
      }
      return false;
    }),
    showPmsi: Ember.computed('collateralClassType', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('collateralClassType'))) {
        return !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapNoExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.allPapWithExcept) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.aircraft) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.chattelPaper) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.negotiableInstrument) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.intermediatedSecurity) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.investmentInstrument) && !Ember.isEqual(this.get('collateralClassType'), constants.searchCollateralClassType.account);
      }
      return false;
    })
  });
  _exports.default = _default;
});