define("esis-ui/components/shared/search-input/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {observer} from '@ember/object';
  var _default = _sharedComponentBase.default.extend({
    initialValue: '',
    disabled: false,
    isRunning: false,
    // myValue : '',
    // initialValuesChanged : observer('initialValue', function(){
    //     if (isPresent(this.get('initialValue')))
    //     {
    //         this.set('myValue', this.get('initialValue'));
    //     }
    // }),
    keyDown: function keyDown(event) {
      if (event.which === 27) {
        this.send('clear');
      } // ESC key
    },

    actions: {
      clear: function clear() {
        this.set('initialValue', '');
        this.$().find('input').focus();
        if (Ember.isPresent(this.get('refresh'))) {
          this.get('refresh')('');
        }
      },
      refresh: function refresh() {
        if (Ember.isPresent(this.get('refresh'))) {
          this.get('refresh')(this.get('initialValue'));
        }
      }
    }
  });
  _exports.default = _default;
});