define("esis-ui/components/areas/registration/nz/wizard/nz-edit-collateral-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _modalTitle: 'Edit Collateral',
    show(collateral) {
      this.set('_collateral', this.mapObject(collateral, this.createModel('nz-collateral-details-create-request')));
      this.get('_collateral').setProperties({
        indexId: collateral.indexId
      });
      this.get('_dialog').show();
    },
    onEditColl() {},
    actions: {
      _onConfirm() {
        if (this.get('_collateral').validate()) {
          this.get('onEditColl')(this.get('_collateral'));
          this.get('_dialog').hide();
        }
      }
    }
  });
  _exports.default = _default;
});