define("esis-ui/components/areas/search/nz/list/nz-search-list/component", ["exports", "esis-ui/components/areas/shared/nz-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _nzPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    //region properties
    _searchGrid: null,
    _selectedSearch: null,
    //region INIT
    init() {
      this._super(...arguments);
      const constants = this.get('constants');

      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklists.perform();

      //region Search GRID
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: this.get('app').getApiUrl(constants.apiEndpoint.nzSearchList)
            },
            parameterMap: (options, operation) => {
              if (operation === 'read') {
                let requestParam = this._createRestGridFilter(options);
                requestParam.view = this.get('_view');
                requestParam.searchField = this.get('_searchBy.value');
                requestParam.searchTerm = this.get('_search');
                return JSON.stringify(requestParam);
              }
              return options;
            }
          },
          schema: {
            data: _data => {
              let realData = _data.searchResults;
              if ((0, _lodash.isEmpty)(realData)) {
                return [];
              }
              for (let i = 0; i < realData.length; i++) {
                realData[i].dateDisplay = this.dateTimeAsStr(realData[i].searchDate);
                realData[i].isActive = realData[i].status === constants.nzSearchStatus.active;
              }
              return realData;
            }
          },
          sort: [{
            field: 'searchDate',
            dir: 'desc'
          }]
        },
        columns: [{
          field: 'searchId',
          title: 'Search ID',
          filterable: false,
          width: '110px',
          sortable: false,
          template: '# if(isActive) {# <a data-guid="#:guid#" class="grid-link-id">#: searchId #</a> #} ' + 'else{# <span title="Expired searches may not be viewed"> #: searchId # </span> #} #'
        }, {
          field: 'searchTypeTitle',
          title: "Type",
          width: "170px",
          filterable: false
        }, {
          field: 'searchCriteriaSummary',
          title: "Criteria",
          filterable: false,
          sortable: false
        }, {
          field: 'searchResultCount',
          title: "Result Count",
          width: "130px",
          filterable: false,
          sortable: false
        }, {
          field: "searchDate",
          title: "Date",
          width: "160px",
          filterable: false,
          template: '#: dateDisplay #'
        }, {
          field: "nzSearchStatusTitle",
          title: "Status",
          width: "130px",
          filterable: false,
          sortable: false
        }]
      });
      //endregion
    },

    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_searchGrid'))) {
          if (reset === true) {
            this.set('_selectedSearch', null);
          }
          this.get('_searchGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    _openSearchTab: function _openSearchTab(search) {
      if (this.get('credential.hasViewSearchPermissionNz')) {
        const constants = this.get('constants');
        let tabName = '';
        if (search.searchType === constants.nzSearchType.debtorOrganisation) {
          tabName = 'searchByDebtorOrgNz';
        } else if (search.searchType === constants.nzSearchType.debtorPerson) {
          tabName = 'searchByDebtorPersonNz';
        } else if (search.searchType === constants.nzSearchType.motorVehicle) {
          tabName = 'searchByMvNz';
        } else if (search.searchType === constants.nzSearchType.fsNumber) {
          tabName = 'searchByRegistrationNumberNz';
        }
        this.addTab(tabName, search.searchId, null, true, search.searchId ? search : null);
      }
    },
    //endregion
    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');

        //SEARCH LIST
        let searchByPicklist = yield this.get('picklistRepo.loadPicklist').perform(constants.picklistType.nzSearchFilterField);
        this.set('_searchByOptions', (0, _lodash.filter)(searchByPicklist, item => {
          return item.value !== constants.nzSearchFilterField.searchExpiryDate;
        }));
        this.set('_searchBy', this.get('_searchByOptions')[0]);

        //VIEW FILTERS
        const options = [{
          title: 'Debtor Person',
          value: constants.nzSearchGridViewFilter.debtorPerson
        }, {
          title: 'Debtor Organisation',
          value: constants.nzSearchGridViewFilter.debtorOrganisation
        }, {
          title: 'Motor Vehicle',
          value: constants.nzSearchGridViewFilter.motorVehicle
        }, {
          title: 'FS Number',
          value: constants.nzSearchGridViewFilter.fsNumber
        }, {
          title: 'All',
          value: constants.nzSearchGridViewFilter.all
        }];
        this.set('_viewFilterOptions', options);
        this.set('_view', constants.nzSearchGridViewFilter.all);
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError('nz-search-list._loadPicklist', error);
      }
    }),
    //endregion

    //region when tab activate
    onScreenChange: function onScreenChange() {
      this._refreshGrid(); // soft refresh
    },

    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedSearch')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedSearch', data);
      },
      _onLinkClick( /*selectedItem*/
      ) {
        this._openSearchTab(this.get('_selectedSearch'));
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onPreviewLinkClick() {
        this._openSearchTab(this.get('_selectedSearch'));
      },
      //endregion

      //region FILTER ACTIONS
      _searchByChanged: function _searchByChanged(option) {
        this.set('_searchBy', option);
        if (this.get('_search')) {
          this._refreshGrid(true);
        }
      },
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view.value)) {
          this.set('_view', view.value);
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        this.set('_search', value);
        this._refreshGrid(true);
      },
      //endregion

      //region BUTTON ACTIONS
      _onClickCreate() {
        this.get('_getCreateSearchTypeModal').show();
      },
      _onGetSearchType(searchType) {
        this._openSearchTab({
          searchType: searchType
        });
      }
      //endregion

      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});