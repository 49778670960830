define("esis-ui/utils/froala-base-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const options = {
    theme: 'gray',
    iconsTemplate: 'font_awesome_5',
    // heightMin: 100,
    // heightMax: 430,
    height: 230,
    toolbarButtons: ['paragraphFormat', 'bold', 'italic', 'underline', 'strikethrough', 'formatOL', 'formatUL', '|', 'color', '|', 'paragraphStyle', '|', 'align', 'outdent', 'indent', 'quote', '|', 'insertLink', '|', 'undo', 'redo', 'clearFormatting', '|', 'html'],
    colorsDefaultTab: 'background',
    colorsStep: 6,
    pasteAllowedStyleProps: ['font-family', 'font-size', 'color', 'background-color', 'font-style', 'font-weight', 'text-decoration'],
    pasteDeniedAttrs: ['class', 'id'],
    //pasteDeniedTags: ['span'],
    pluginsEnabled: ['align', 'charCounter', 'colors', 'draggable', 'emoticons', 'entities', 'fontFamily', 'fontSize', 'image', 'imageManager', 'inlineStyle', 'lineBreaker', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'table', 'url', 'wordPaste', 'codeView']
  };
  var _default = options;
  _exports.default = _default;
});