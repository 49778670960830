define("esis-ui/models/search", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    searchType: (0, _modelBase.attr)(),
    searchNumber: (0, _modelBase.attr)(),
    criteriaRegistrationNumber: (0, _modelBase.attr)(),
    criteriaSerialNumber: (0, _modelBase.attr)(),
    criteriaSerialNumberType: (0, _modelBase.attr)(),
    criteriaCollateralClassSearchCriteria: (0, _modelBase.attr)(),
    criteriaGrantorType: (0, _modelBase.attr)(),
    criteriaOrganisationName: (0, _modelBase.attr)(),
    criteriaOrganisationNumber: (0, _modelBase.attr)(),
    criteriaOrganisationNumberType: (0, _modelBase.attr)(),
    criteriaDateOfBirth: (0, _modelBase.attr)(),
    criteriaFamilyName: (0, _modelBase.attr)(),
    criteriaGivenNames: (0, _modelBase.attr)(),
    summaryAccessToSearchResultExpiryTime: (0, _modelBase.attr)(),
    summarySearchExecutedDateTime: (0, _modelBase.attr)(),
    status: (0, _modelBase.attr)(),
    statusMessage: (0, _modelBase.attr)(),
    criteriaAcceptGrantorIndividualSearchDeclaration: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    searchHeaderId: (0, _modelBase.attr)(),
    criteriaSummary: (0, _modelBase.attr)(),
    criteriaIncludeArchived: (0, _modelBase.attr)(),
    criteriaIncludeCurrent: (0, _modelBase.attr)(),
    criteriaIncludeExpired: (0, _modelBase.attr)(),
    criteriaIncludeMigratedTransitional: (0, _modelBase.attr)(),
    criteriaIncludeNonMigratedTransitional: (0, _modelBase.attr)(),
    criteriaIncludeNonTransitional: (0, _modelBase.attr)(),
    criteriaIncludeRemoved: (0, _modelBase.attr)(),
    criteriaIsPmsiSearchCriteria: (0, _modelBase.attr)(),
    criteriaOrganisationNameIsVerified: (0, _modelBase.attr)(),
    criteriaPointInTimeDateTime: (0, _modelBase.attr)(),
    criteriaRegEventFromDate: (0, _modelBase.attr)(),
    criteriaRegEventOneChangeNumber: (0, _modelBase.attr)(),
    criteriaRegEventOneRegistrationEventType: (0, _modelBase.attr)(),
    criteriaRegEventOneRegistrationNumber: (0, _modelBase.attr)(),
    criteriaRegEventToDate: (0, _modelBase.attr)(),
    criteriaRegEventTwoChangeNumber: (0, _modelBase.attr)(),
    criteriaRegEventTwoRegistrationEventType: (0, _modelBase.attr)(),
    criteriaRegEventTwoRegistrationNumber: (0, _modelBase.attr)(),
    criteriaRegistrationNumberSortOrder: (0, _modelBase.attr)(),
    criteriaSecurityInterestOnly: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    esisId: (0, _modelBase.attr)(),
    grantorResultCollateralClassSummary: (0, _modelBase.attr)(),
    grantorResultCollateralTypeSummary: (0, _modelBase.attr)(),
    grantorResultFilterNumber: (0, _modelBase.attr)(),
    grantorResultMaximumNumberOfReturnedRegistrationsPerRetrieve: (0, _modelBase.attr)(),
    grantorResultOrganisationName: (0, _modelBase.attr)(),
    grantorResultOrganisationNumber: (0, _modelBase.attr)(),
    grantorResultOrganisationNumberType: (0, _modelBase.attr)(),
    grantorResultOrganisationNumberVerificationStatus: (0, _modelBase.attr)(),
    grantorResultOrganisationNumberVerificationStatusTitle: (0, _modelBase.attr)(),
    grantorResultTransitionalPeriodMessage: (0, _modelBase.attr)(),
    ordinalResultOrdinalSearchResult: (0, _modelBase.attr)(),
    ordinalResultRegOneChangeNumber: (0, _modelBase.attr)(),
    ordinalResultRegOneEventTime: (0, _modelBase.attr)(),
    ordinalResultRegOneIsAccessRestricted: (0, _modelBase.attr)(),
    ordinalResultRegOneIsArchived: (0, _modelBase.attr)(),
    ordinalResultRegOneIsRemoved: (0, _modelBase.attr)(),
    ordinalResultRegOneRegistrationEventType: (0, _modelBase.attr)(),
    ordinalResultRegOneRegistrationNumber: (0, _modelBase.attr)(),
    ordinalResultRegTwoChangeNumber: (0, _modelBase.attr)(),
    ordinalResultRegTwoEventTime: (0, _modelBase.attr)(),
    ordinalResultRegTwoIsAccessRestricted: (0, _modelBase.attr)(),
    ordinalResultRegTwoIsArchived: (0, _modelBase.attr)(),
    ordinalResultRegTwoIsRemoved: (0, _modelBase.attr)(),
    ordinalResultRegTwoRegistrationEventType: (0, _modelBase.attr)(),
    ordinalResultRegTwoRegistrationNumber: (0, _modelBase.attr)(),
    ordinalResultSearchResultRetrievedDateTime: (0, _modelBase.attr)(),
    ordinalResultTransitionalMessage: (0, _modelBase.attr)(),
    ppsrTransactionId: (0, _modelBase.attr)(),
    resultNevDisVerificationStatus: (0, _modelBase.attr)(),
    resultSearchResultRetrievedDateTime: (0, _modelBase.attr)(),
    resultTransitionalPeriodMessage: (0, _modelBase.attr)(),
    searchTypeSummary: (0, _modelBase.attr)(),
    statusTitle: (0, _modelBase.attr)(),
    summaryResultCount: (0, _modelBase.attr)(),
    userIdCreated: (0, _modelBase.attr)(),
    userIdEdited: (0, _modelBase.attr)()
  });
  _exports.default = _default;
});