define("esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    step: null,
    // input own step object
    model: null,
    // model object shared with other steps.
    isFirstStep: false,
    // virtual should be override by step component
    isLastStep: false,
    // virtual should be override by step component
    get isCompleted() {
      // return this.validateStep();
      return false;
    },
    // indicate if step has been completed in wizard
    stepTabUiClass: Ember.computed('isCompleted', function () {
      return Ember.String.htmlSafe(this.get('isCompleted') === true ? 'complete' : '');
    }),
    validateStep: function validateStep() {
      // virtual should be implemented by step component
      return true; // validated OK, return false if validation failed
    },

    onActivate: function onActivate() {}
  });
  _exports.default = _default;
});