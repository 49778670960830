define("esis-ui/components/areas/registration/au/list/preview/au-registration-preview/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _previewPanel: 'refPanel',
    _ppsrPanel: 'ppsrPanel',
    _grantorPanel: 'grantorPanel',
    _esisOnlyPanel: 'esisOnlyPanel',
    _attachmentPanel: 'attachmentPanel',
    _exceptionPanel: 'exceptionPanel',
    //endregion

    //region COMPUTED PROPERTIES
    _showWizard: Ember.computed('detailDigest.{status,ppsrRegistrationNumber}', function () {
      const constant = this.get('constants');
      let status = this.get('detailDigest.status');
      return Ember.isEqual(status, constant.statusValue.creating) || (Ember.isEqual(status, constant.statusValue.error) || Ember.isEqual(status, constant.statusValue.review) || Ember.isEqual(status, constant.get('statusValue.rejected')) || Ember.isEqual(status, constant.statusValue.validated)) && (0, _lodash.isEmpty)(this.get('detailDigest.ppsrRegistrationNumber'));
    }),
    _displayException: Ember.computed('detailDigest.status', function () {
      const constants = this.get('constants');
      return this.get('detailDigest.status') === constants.statusValue.rejected || this.get('detailDigest.status') === constants.statusValue.error;
    }),
    _statusMessage: Ember.computed('detailDigest.statusMessage', function () {
      let statusMessage = String(this.get('detailDigest.statusMessage')).split(/[|]/g);
      for (let i = 0; i < statusMessage.length; i++) {
        statusMessage[i] = statusMessage[i].trim();
      }
      return statusMessage;
    }),
    //endregion

    //region TASK
    _downloadRegistrationFile: (0, _emberConcurrency.task)(function* (fileId) {
      try {
        yield this.get('registrationRepo.downloadRegistrationAttachment').perform(fileId);
        const regId = this.get('detailDigest.id');
        this.notifySuccess(`Attachment file for ${regId} has been downloaded`);
      } catch (error) {
        this.get('logger').logError('registration-preview._downloadRegistrationFile', error);
        // appService.logError('fail to download registration attachment file', error, regId, id);
        // this.toast.error(`Attachment file for registration (${self.get('detailDigest.esisId')}) failed to downloaded, please try again`);
      }
    }),

    //endregion

    actions: {
      _openDetailTab: function _openDetailTab() {
        if (!(0, _lodash.isNil)(this.get('detailDigest.guid'))) {
          let tabName = this.get('_showWizard') && this.get('credential.hasCreateRegistrationPermissionAu') ? 'registrationWizardAu' : 'registrationViewAu';
          this.addTab(tabName, this.get('detailDigest.guid'));
        }
      },
      _downloadFile: function _downloadFile(id) {
        this._downloadRegistrationFile.perform(id);
      }
    }
  });
  _exports.default = _default;
});