define("esis-ui/components/areas/registration/nz/wizard/nz-registration-review-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "ember-concurrency", "lodash"], function (_exports, _wizardStepBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import {computed} from '@ember/object';
  var _default = _wizardStepBase.default.extend({
    isLastStep: true,
    /*
    should implement validation on activate.
     */
    _isRegistrationReadyForSubmission: false,
    //region COMPUTED PROPERTIES
    //endregion
    onActivate: function onActivate() {
      (0, _lodash.forEach)(this.get('model.debtors'), item => {
        item.set('collapsed', true);
      });
      /*
      implement submit request to server for validation as soon as this page is loaded
      set is valid flag for validation step.
      set _isRegistrationReadyForSubmission with server validation result
       */
      this.get('_validateRegistrationTask').perform();
    },
    validateStep: function validateStep() {
      // virtual should be implemented by step component
      return this.get('_isRegistrationReadyForSubmission'); // validated OK, return false if validation failed
    },

    _validateRegistrationTask: (0, _emberConcurrency.task)(function* () {
      try {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        let result = yield this.get('registrationRepo.validateCreateNzFs').perform(this.get('model'));
        this.set('_isRegistrationReadyForSubmission', result.isValid);
        if (!result.isValid) {
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', result.validationErrorMessage);
        }
      } catch (error) {
        this.set('model.displayException', true);
        this.set('model.validatErrorMessage', error);
      }
    }),
    actions: {
      _toggleSingle: function _toggleSingle(item) {
        item.toggleProperty('collapsed');
      }
    }
  });
  _exports.default = _default;
});