define("esis-ui/components/areas/search/au/view/au-search-ppsr-number/component", ["exports", "esis-ui/components/areas/search/au/view/au-search-view-base", "ember-concurrency", "lodash"], function (_exports, _auSearchViewBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSearchViewBase.default.extend({
    //region INITIALISATION

    init() {
      this._super(...arguments);
      this._loadModel.perform();
    },
    //endregion

    //region TASK
    _loadModel: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isNil)(this.get('tabModel.p0'))) {
          const response = yield this.get('searchRepo.loadSearch').linked().perform(this.get('tabModel.p0'));
          const searchHeader = this._createSearchHeader('', response);
          this.set('model', searchHeader);
          this._mapSearches(this.get('model'));
          this.updateTabProperty('title', `Reg Num Search ${searchHeader.esisId}`);
          this.set('_isReadOnly', true);
        } else {
          const newRecord = this._createSearchHeader(this.get('constants.searchType.searchByRegistrationNumber'));
          this._createSearch(newRecord);
          this.set('model', newRecord);
        }
        this.get('model').saveModel();
      } catch (error) {
        this.get('logger').logError('au-search-ppsr-number._loadModel', error);
        this.notifyError('Fail to load search');
      }
    }),
    //endregion

    actions: {
      _onUpdateTab(searchResultSummary) {
        this.updateTabProperty('title', `Reg Num Search ${searchResultSummary.searchHeaderEsisId}`);
        this.updateTabProperty('p0', searchResultSummary.searchHeaderGuid);
      }
    }
  });
  _exports.default = _default;
});