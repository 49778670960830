define("esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-by-profile-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "TS9N6cqN",
    "block": "{\"symbols\":[\"collateral\"],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"registerAs\",\"modalTitle\",\"name\",\"renderInPlace\",\"onConfirm\",\"customClass\",\"modalScrollable\"],[[24,[\"_dialog\"]],[24,[\"_modalTitle\"]],\"nzFsAddCollByProfileModal\",[24,[\"renderInPlace\"]],[28,\"action\",[[23,0,[]],\"_onConfirm\"],null],\"nzFsAddCollByProfileModal\",true]],{\"statements\":[[0,\"  \"],[7,\"ol\",true],[10,\"class\",\"pl-3\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"_collaterals\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\",true],[10,\"class\",\"mb-3\"],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[0,\"\\n          \"],[7,\"label\",true],[10,\"class\",\"control-label field-label\"],[8],[0,\"\\n            \"],[1,[23,1,[\"collateralTypeTitle\"]],false],[0,\"\\n          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"collDescription\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"label\",true],[10,\"class\",\"control-label field-label pr-0\"],[8],[0,\"\\n              Description :\\n            \"],[9],[0,\"\\n            \"],[7,\"label\",true],[10,\"class\",\"control-label field-label\"],[8],[0,\"\\n              \"],[1,[23,1,[\"collDescription\"]],false],[0,\"\\n            \"],[9],[0,\"\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/wizard/nz-add-collateral-by-profile-modal/template.hbs"
    }
  });
  _exports.default = _default;
});