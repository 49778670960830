define("esis-ui/components/areas/admin/security-roles/security-role-page-base", ["exports", "esis-ui/components/areas/admin/admin-page-base", "lodash"], function (_exports, _adminPageBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adminPageBase.default.extend({
    convertPermissionToDisplay(permissions) {
      return (0, _lodash.map)((0, _lodash.toPairs)((0, _lodash.groupBy)(permissions, 'permissionGroupTitle')), p => {
        return {
          permissionGroupTitle: p[0],
          permissions: p[1]
        };
      });
    }
  });
  _exports.default = _default;
});