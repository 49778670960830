define("esis-ui/components/areas/registration/nz/wizard/nz-registration-collateral-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash", "ember-concurrency"], function (_exports, _wizardStepBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    //region COMPUTED PROPERTIES

    //endregion

    onActivate() {
      if ((0, _lodash.isEmpty)(this.get('model.collateral'))) {
        this.get('_selectCollTypeModal').show();
      }
    },
    validateStep() {
      let isValid = true;
      (0, _lodash.forEach)(this.get('model.collateral'), item => {
        if (!item.validateModel()) {
          isValid = item.validateModel();
        }
      });
      this.set('model.errors', []);
      let errors = this.get('model.errors');
      if ((0, _lodash.isEmpty)(this.get('model.collateral'))) {
        errors.set('collateral', 'At least one collateral should be added');
        return false;
      } else {
        errors.set('collateral', '');
        return isValid;
      }
    },
    _addCollateralTypeTask: (0, _emberConcurrency.task)(function* (args) {
      try {
        if (!(0, _lodash.isEmpty)(args.collateralProfile)) {
          let addCollItemModalArgs;
          let response = yield this.get('registrationRepo.loadNzCollateralProfileDefaults').perform(args.collateralProfile.item.guid);
          let collateralTypes = [];
          (0, _lodash.forEach)(response, item => {
            collateralTypes.pushObject({
              value: item.collateralTypeId,
              title: item.collateralTypeTitle,
              collDescription: item.collateralTypeDescription,
              itemDescription: item.collateralItemDescription,
              collateralProfileCode: args.collateralProfile.item.code
            });
          });
          addCollItemModalArgs = {
            modalTitle: `Add Collateral Types by Collateral Profile: ${args.collateralProfile.item.code}`,
            collateralTypes: collateralTypes
          };
          this.get('_addCollByProfileModal').show(addCollItemModalArgs);
        } else {
          this.get('_addCollModal').show(args.collateralType);
        }
      } catch (error) {
        this.get('logger').logError('nz-fs-wizard._addCollateralTypeTask', error);
      }
    }),
    actions: {
      _onClickAddCollType: function _onClickAddCollType() {
        this.get('_selectCollTypeModal').show();
      },
      _onSelectCollateralType(args) {
        this._addCollateralTypeTask.perform(args);
      },
      _onAddCollateral(collateral) {
        if (Ember.isNone(this.get('model.collateral'))) {
          this.set('model.collateral', []);
        }
        this.get('model.collateral').unshiftObject(collateral);
        this.validateStep();
      },
      _onAddCollateralByProfile(collaterals) {
        if (Ember.isNone(this.get('model.collateral'))) {
          this.set('model.collateral', []);
        }
        (0, _lodash.forEach)(collaterals, coll => {
          this.get('model.collateral').unshiftObject(coll);
        });
        this.validateStep();
      },
      _onClickRemoveColl(collateral) {
        this.get('model.collateral').removeObject(collateral);
      },
      _onClickEditColl(collateral, collIndex) {
        Ember.set(collateral, 'indexId', collIndex);
        this.get('_editCollModal').show(collateral);
      },
      _onEditColl(collateral) {
        let updatedCollType = this.get('model.collateral')[collateral.indexId];
        this.mapOnlySourceProps(collateral, updatedCollType);
        this.validateStep();
      },
      _onClickRemoveCollItem(collateral, item) {
        collateral.collateralItems.removeObject(item);
      },
      _onClickEditCollItem(collateral, collIndex, collItem, itemIndex) {
        Ember.set(collItem, 'indexId', itemIndex);
        this.get('_addCollItemModal').show({
          collateralIndexId: collIndex,
          collateralTypeTitle: collateral.collateralTypeTitle,
          collateralType: collateral.collateralType
        }, collItem);
      },
      _onUpdateCollItem(collItem) {
        let updatedColl = this.get('model.collateral')[collItem.collateralIndexId];
        let updatedCollItem = updatedColl.collateralItems[collItem.indexId];
        this.mapObject(collItem, updatedCollItem);
        updatedCollItem.set('aircraftClassTitle', collItem.aircraftClassTitle);
      },
      _onClickAddCollItem(collateral, collIndex) {
        this.get('_addCollItemModal').show({
          collateralIndexId: collIndex,
          collateralTypeTitle: collateral.collateralTypeTitle,
          collateralType: collateral.collateralType
        });
      },
      _onAddCollItem(collItem) {
        let updatedColl = this.get('model.collateral')[collItem.collateralIndexId];
        if (Ember.isNone(updatedColl.collateralItems)) {
          updatedColl.set('collateralItems', []);
        }
        updatedColl.collateralItems.unshiftObject(collItem);
        this.validateStep();
      }
    }
  });
  _exports.default = _default;
});