define("esis-ui/components/areas/shared/buttons/close-button/component", ["exports", "esis-ui/components/shared/buttons/button-base", "lodash"], function (_exports, _buttonBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _buttonBase.default.extend({
    actions: {
      _onClick() {
        if (!(0, _lodash.isEmpty)(this.get('onClose'))) {
          this.get('onClose')();
        }
      }
    }
  });
  _exports.default = _default;
});