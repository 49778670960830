define("esis-ui/models/search-header", ["exports", "esis-ui/utils/model-base", "lodash", "esis-ui/models/request-base", "moment"], function (_exports, _modelBase, _lodash, _requestBase, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    esisId: (0, _modelBase.attr)(),
    entityId: (0, _modelBase.attr)(),
    entityGuid: (0, _modelBase.attr)(),
    reference: (0, _modelBase.attr)(),
    description: (0, _modelBase.attr)(),
    statusTitle: (0, _modelBase.attr)(),
    status: (0, _modelBase.attr)(),
    statusMessage: (0, _modelBase.attr)(),
    searchType: (0, _modelBase.attr)(),
    isMultiSearch: (0, _modelBase.attr)(),
    autoRetrieveCertificates: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    searches: (0, _modelBase.attr)(null, 'array'),
    summaryResultCount: (0, _modelBase.attr)(),
    searchRetrievalProgress: (0, _modelBase.attr)(),
    searchCertificateRetrievalProgress: (0, _modelBase.attr)(),
    filterCollateralTypes: (0, _modelBase.attr)(),
    filterCollateralClasses: (0, _modelBase.attr)(),
    filterCollateralClassesTitle: (0, _modelBase.attr)(),
    filterRegEventFromDate: (0, _modelBase.attr)(),
    filterRegEventToDate: (0, _modelBase.attr)(),
    searchFilterCriteria: null,
    criteriaSummary: (0, _modelBase.attr)(),
    criteriaSerialNumberType: (0, _modelBase.attr)(),
    criteriaSerialNumber: (0, _modelBase.attr)(),
    firstSearch: Ember.computed.alias('searches.firstObject'),
    hasBeenSubmitted: Ember.computed('id', 'status', function () {
      const constants = this.get('constants');
      return this.get('id') > 0 && (this.get('status') === constants.statusValue.submitted || this.get('status') === constants.statusValue.error || this.get('status') === constants.statusValue.confirmed || this.get('status') === constants.statusValue.expired);
    }),
    hasSearchResults: Ember.computed('id', 'status', 'summaryResultCount', function () {
      return this.get('id') > 0 && this.get('hasBeenSubmitted') && this.get('summaryResultCount') > 0;
    }),
    hasNilResult: Ember.computed('id', 'status', 'summaryResultCount', function () {
      return this.get('id') > 0 && this.get('hasBeenSubmitted') && this.get('summaryResultCount') === 0;
    }),
    searchRetrievalCompleted: Ember.computed('searchRetrievalProgress', 'status', function () {
      return this.get('hasBeenSubmitted') && (this.get('searchRetrievalProgress') === 100 || this.get('summaryResultCount') === 0);
    }),
    bulkCertificateNotRequested: Ember.computed.lte('searchCertificateRetrievalProgress', 1),
    bulkCertificateAvailable: Ember.computed.equal('searchCertificateRetrievalProgress', 100),
    displayException: Ember.computed.equal('statusTitle', 'Error'),
    filterOption: Ember.computed('filterCollateralClasses', 'filterRegEventFromDate', {
      get() {
        let constants = this.get('constants');
        if (this.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange || this.get('searchType') === constants.searchType.searchByGrantor && !(0, _lodash.isNil)(this.get('filterRegEventFromDate'))) {
          return constants.grantorSearchFilterOption.dateRange;
        }
        if (!Ember.isBlank(this.get('filterCollateralClasses'))) {
          if (this.get('filterCollateralClasses') === constants.grantorSearchFilterCollateralClassTypeAll) {
            return constants.grantorSearchFilterOption.none;
          } else {
            return constants.grantorSearchFilterOption.collateralClassType;
          }
        }
        return constants.grantorSearchFilterOption.none;
      },
      set(key, value) {
        return value;
      }
    }),
    saveModel: function saveModel() {
      this.save();
      const search = this.get('searches');
      if (Ember.isPresent(search) && Ember.isArray(search)) {
        (0, _lodash.forEach)(search, item => {
          item.save();
        });
      }
    },
    init() {
      this._super(...arguments);
      this.validations = {
        reference: {
          custom: {
            validation: function validation(key, value, model) {
              if (model.get('credential.currentBusinessEntity.item.enforceSearchReference')) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'Reference field cannot be blank'
          }
        },
        searchType: {
          custom: {
            validation: function validation(key, value, model) {
              if (value === 1) {
                let regNum = '';
                if (Ember.isPresent(model.get('firstSearch.criteriaRegistrationNumber'))) {
                  regNum = model.get('firstSearch.criteriaRegistrationNumber');
                }
                return Ember.isPresent(model.get('firstSearch.criteriaRegistrationNumber')) && regNum.length >= 15;
              }
              return true;
            },
            message: 'Search criteria should be at least 15 digits'
          }
        },
        criteriaSerialNumber: {
          custom: {
            validation: function validation(key, value, model) {
              if (!model.get('isMultiSearch') && model.searchType === model.get('constants.searchType.searchBySerialNumber')) {
                return Ember.isPresent(model.get('firstSearch.criteriaSerialNumber'));
              }
              return true;
            },
            message: 'Serial number cannot be blank'
          }
        },
        criteriaSerialNumberType: {
          custom: {
            validation: function validation(key, value, model) {
              if (!model.get('isMultiSearch') && model.searchType === model.get('constants.searchType.searchBySerialNumber')) {
                return Ember.isPresent(model.get('firstSearch.criteriaSerialNumberType'));
              }
              return true;
            },
            message: 'Serial number type cannot be blank'
          }
        },
        filterRegEventToDate: {
          custom: {
            validation: function validation(key, value, model) {
              const constants = model.get('constants');
              if (model.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange || model.get('searchType') === constants.searchType.searchByGrantor && !(0, _lodash.isNil)(model.get('filterRegEventToDate'))) {
                return model.get('filterRegEventToDate') < (0, _moment.default)().subtract(1, "day");
              }
              return true;
            },
            message: 'must be before today'
          }
        },
        filterRegEventFromDate: {
          custom: [{
            validation: function validation(key, value, model) {
              const constants = model.get('constants');
              if (model.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange || model.get('searchType') === constants.searchType.searchByGrantor) {
                if (!(0, _lodash.isNil)(model.get('filterRegEventFromDate')) && !(0, _lodash.isNil)(model.get('filterRegEventToDate'))) {
                  return model.get('filterRegEventFromDate') < model.get('filterRegEventToDate');
                }
              }
              return true;
            },
            message: 'cannot be after To Date<br>'
          }, {
            validation: function validation(key, value, model) {
              const constants = model.get('constants');
              if (model.get('searchType') === constants.searchType.searchByGrantorAndEventDateRange || model.get('searchType') === constants.searchType.searchByGrantor && !(0, _lodash.isNil)(model.get('filterRegEventFromDate'))) {
                return model.get('filterRegEventFromDate') < (0, _moment.default)().subtract(1, "day");
              }
              return true;
            },
            message: 'must be before today'
          }]
        }
      };
    }
  });
  _exports.default = _default;
});