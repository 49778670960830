define("esis-ui/models/secured-party", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    id: (0, _modelBase.attr)(),
    esisId: (0, _modelBase.attr)(),
    securedPartyGroupId: (0, _modelBase.attr)(),
    securedPartyType: (0, _modelBase.attr)(),
    securedPartyTypeTitle: (0, _modelBase.attr)(),
    contactName: (0, _modelBase.attr)(),
    contactPhone: (0, _modelBase.attr)(),
    emailAddress: (0, _modelBase.attr)(),
    individualFamilyName: (0, _modelBase.attr)(),
    individualGivenNames: (0, _modelBase.attr)(),
    organisationName: (0, _modelBase.attr)(),
    organisationNumber: (0, _modelBase.attr)(),
    organisationNumberType: (0, _modelBase.attr)(),
    organisationNumberTypeTitle: (0, _modelBase.attr)(),
    sequenceNumber: (0, _modelBase.attr)(),
    mailingAddress: (0, _modelBase.attr)(),
    userIdCreated: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    userIdEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    securedPartyName: (0, _modelBase.attr)(),
    organisationNumberTypeAndNumber: (0, _modelBase.attr)(),
    ppsrOrganisationNumberVerificationDateTime: (0, _modelBase.attr)(),
    ppsrOrganisationNumberVerificationStatus: (0, _modelBase.attr)(),
    organisationEntityTypeDescription: (0, _modelBase.attr)(),
    abnStatus: (0, _modelBase.attr)(),
    acnStatus: (0, _modelBase.attr)(),
    abnIsCurrent: (0, _modelBase.attr)(),
    acnIsCurrent: (0, _modelBase.attr)(),
    isConfirmed: true,
    isOrganisation: Ember.computed('securedPartyType', function () {
      return this.get('securedPartyType') === 1;
    }),
    securedPartyIsDirty: Ember.computed('contactNameIsDirty', 'contactPhoneIsDirty', 'emailAddressIsDirty', 'mailingAddress.addressIsDirty', 'organisationName', 'organisationNumberIsDirty', 'organisationNumberTypeIsDirty', 'individualFamilyNameIsDirty', 'individualGivenNamesIsDirty', function () {
      return this.get('contactNameIsDirty') || this.get('contactPhoneIsDirty') || this.get('emailAddressIsDirty') || this.get('mailingAddress.addressIsDirty') || this.get('organisationName') || this.get('organisationNumberIsDirty') || this.get('organisationNumberTypeIsDirty') || this.get('individualFamilyNameIsDirty') || this.get('individualGivenNamesIsDirty');
    }),
    orgNumberRequired: Ember.computed('organisationNumberType', function () {
      return Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.unsupported')) || Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.ACN')) || Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.ARBN')) || Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.ARSN')) || Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.ABN')) ? 'required' : '';
    }),
    orgNameRequired: Ember.computed('organisationNumberType', function () {
      return Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.unsupported')) || Ember.isEqual(this.get('organisationNumberType'), this.get('constants.organisationNumberType.nameOnly')) ? 'required' : '';
    }),
    init() {
      this._super(...arguments);
      this.validations = {
        organisationNumberType: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isEqual(model.get('securedPartyType'), model.get('constants.securedPartyType.organisation'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Organisation number type can't be blank"
          }
        },
        organisationNumber: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isEqual(model.get('securedPartyType'), model.get('constants.securedPartyType.organisation')) && (Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.unsupported')) || Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.ACN')) || Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.ARBN')) || Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.ARSN')) || Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.ABN')))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Organisation number can't be blank"
          }
        },
        organisationName: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isEqual(model.get('securedPartyType'), model.get('constants.securedPartyType.organisation')) && (Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.unsupported')) || Ember.isEqual(model.get('organisationNumberType'), model.get('constants.organisationNumberType.nameOnly')))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Organisation name can't be blank"
          }
        },
        individualFamilyName: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isEqual(model.get('securedPartyType'), model.get('constants.securedPartyType.individual'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Can't be blank"
          }
        },
        individualGivenNames: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isEqual(model.get('securedPartyType'), model.get('constants.securedPartyType.individual'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: "Can't be blank"
          }
        },
        contactName: {
          presence: true
        },
        contactPhone: {
          presence: true
        },
        emailAddress: {
          presence: true,
          custom: {
            validation: function validation(key, value, model) {
              return Ember.isPresent(value) ? model.validateEmailAddress(value) : true;
            },
            message: 'Not a valid email'
          }
        }
      };
    }
  });
  _exports.default = _default;
});