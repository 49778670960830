define("esis-ui/components/shared/nullable-boolean-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BOvQ+PCJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"checkboxGroupClass\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[28,\"not\",[[24,[\"wrap\"]]],null],\"row ml-3\"],null]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"mr-3 \",[28,\"if\",[[24,[\"isRequired\"]],\"required\"],null]]]],[8],[0,\"\\n      \"],[1,[22,\"label\"],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"row \",[28,\"if\",[[24,[\"wrap\"]],\"wrapStyle\"],null]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ml-2\"],[8],[0,\"\\n        \"],[1,[28,\"shared/checkbox-action\",null,[[\"property\",\"title\",\"disabled\",\"onChange\"],[[24,[\"property\"]],\"Yes\",[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"_onChange\",true],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ml-2\"],[8],[0,\"\\n        \"],[1,[28,\"shared/checkbox-action\",null,[[\"property\",\"title\",\"disabled\",\"onChange\"],[[28,\"if\",[[28,\"is-empty\",[[24,[\"property\"]]],null],false,[28,\"not\",[[24,[\"property\"]]],null]],null],\"No\",[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"_onChange\",false],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/nullable-boolean-control/template.hbs"
    }
  });
  _exports.default = _default;
});