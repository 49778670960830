define("esis-ui/components/areas/search/nz/view/nz-search-fs-number/component", ["exports", "esis-ui/components/areas/search/nz/nz-search-base", "lodash"], function (_exports, _nzSearchBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzSearchBase.default.extend({
    //region INITIALISATION
    searchSubmitTaskName: 'submitSearchNzRegistrationNumber',
    searchTabTitle: 'FS Number Search',
    searchModelName: 'nz-search-registration-number-request',
    //endregion

    //region Methods
    setDefaultModelValues() {
      this.set('model.registrationNumbers', []);
    },
    //endregion

    actions: {
      _onAddFSNumber() {
        if (this.get('model.registrationNumbers').length < 10) {
          if (this.get('_fsNumber').trim().length === 16) {
            if ((0, _lodash.indexOf)(this.get('model.registrationNumbers'), this.get('_fsNumber')) < 0) {
              this.get('model.registrationNumbers').pushObject(this.get('_fsNumber'));
              this.set('_fsNumber', null);
              if (this.get('model.registrationNumbers').length === 1) {
                this.get('model').validate();
              }
              this.set('_fsNumberValidationError', '');
            } else {
              this.set('_fsNumberValidationError', 'Fs number is already added');
            }
          } else {
            this.set('_fsNumberValidationError', 'Fs number must be exactly 16 characters long');
          }
        } else {
          this.set('_fsNumberValidationError', 'Up to ten registration numbers may be added');
        }
      },
      _onRemoveFsNumber(fsNumber) {
        this.get('model.registrationNumbers').removeObject(fsNumber);
      }
    }
  });
  _exports.default = _default;
});