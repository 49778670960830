define("esis-ui/components/shared/kendo/grid/multi-select-grid-base", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline", "jquery"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region private properties
    classNames: ['kendoGrid'],
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe("clear:both;width:99%;"),
    _autoRefreshJobId: null,
    _grid: null,
    //endregion

    //region public events
    onSelectionChange: function onSelectionChange() {},
    onLinkClick: function onLinkClick() {},
    //endregion

    //region public properties and options

    // auto refresh
    autoRefresh: false,
    autoRefreshInterval: 60000,
    // grid option
    options: null,
    autoFocus: true,
    /*
    this flag is ask grid to add instanceGuid property to data object when trigger grid on row select change event
    this is to force the data object to be unique, so consumer such as preview panel of static tab
    will be forced to reload.
     */
    assignInstanceIdOnRowSelectionChange: false,
    //endregion

    //region initialisation
    init() {
      this._super(...arguments);
      const self = this;
      self.set('selectedItems', []);
      self._defaultOptions = {
        // height: "100%",
        filterable: false,
        sortable: true,
        resizable: true,
        selectable: 'multiple',
        pageable: {
          buttonCount: 5
        },
        persistSelection: true,
        navigatable: true,
        change: function change(e) {
          //getting all the selected rows in all pages in multi select mode
          const grid = self.get('_grid');
          let listView = e.sender;
          let items = listView.items();
          items.each(function (idx, row) {
            let data = grid.dataItem(row);
            if (row.className.indexOf("k-state-selected") >= 0) {
              if ((0, _lodash.isEmpty)((0, _lodash.find)(self.get('selectedItems'), {
                id: data.id
              }))) {
                self.get('selectedItems').pushObject(data);
              }
            } else if (!(0, _lodash.isEmpty)((0, _lodash.find)(self.get('selectedItems'), {
              id: data.id
            }))) {
              self.set('selectedItems', (0, _lodash.filter)(self.get('selectedItems'), item => {
                return item.id !== data.id;
              }));
            }
          });
          (0, _emberLifeline.runTask)(self, '_selectedRowChanged');
        },
        dataBound: function dataBound(e) {
          /*
          setup hyperlink for id column is specified
           */
          self.$('a.grid-link-id').click(function () {
            if (Ember.isPresent(self.$(this))) {
              (0, _emberLifeline.runTask)(self, () => {
                self.get('onLinkClick')(self.$(this));
              });
            }
          });

          /*
          Grab focus to the table element of the grid, so user can use keyboard to navigate
          the timeout of 450 is designed to be bigger than select first row timeout
           */
          if (self.autoFocus) {
            (0, _emberLifeline.runTask)(self, () => {
              if (Ember.isPresent(e.sender) && Ember.isPresent(e.sender.table) && e.sender.table.length > 0) {
                e.sender.table.focus();
              }
            }, 550);
          }

          //Triggering multi select with row clicking for first Page
          self.multiSelectOnRowClick();
        },
        page: function page() {
          //Triggering Multi select on clicking rows when paging
          //We need to call this method on page change because the rows in DOM element are different for each page
          self.multiSelectOnRowClick();
        }
      };

      // handling resize events
      self.get('event').on(self.get('constants.events.tabResize'), self, 'resize');
      self.get('event').on(self.get('constants.events.splitterMoved'), self, 'resize');
    },
    didInsertElement: function didInsertElement() {
      this._super(...arguments);
      const self = this;
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, self.get('_defaultOptions'), self.get('options'));
      const grid = self.$().kendoGrid(mergedOptions).data("kendoGrid");

      /*
      handle keyboard arrow keys, keyboard navigation inside the grid
       */
      const arrows = [37, 38, 39, 40];
      grid.table.on("keydown", function (e) {
        if (arrows.indexOf(e.keyCode) >= 0) {
          (0, _emberLifeline.runTask)(self, () => {
            const activeRow = self.$("[id$=_active_cell]").closest("tr");
            grid.select(activeRow);
          }, 20);
        }
      });
      self.set('_grid', grid);
      // run job to poll search result if in progress
      if (Ember.isNone(self.get('_autoRefreshJobId')) && self.get('autoRefresh') === true) {
        self.set('_autoRefreshJobId', (0, _emberLifeline.pollTask)(self, '_autoRefreshGrid'));
      }

      //grid id
      const id = self.$().attr('id');
      self.set('_gridId', id);
    },
    willDestroyElement: function willDestroyElement() {
      this.get('event').off(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').off(this.get('constants.events.splitterMoved'), this, 'resize');
      if (Ember.isPresent(this.get('_grid'))) {
        this.get('_grid').destroy();
      }
      if (Ember.isPresent(this.get('_autoRefreshJobId'))) {
        (0, _emberLifeline.cancelPoll)(this, this.get('_autoRefreshJobId')); // this is not strictly required
      }

      this._super(...arguments);
    },
    //endregion

    //region public methods

    //This is the only way Kendo provides to Multi Select without holding Ctrl
    multiSelectOnRowClick() {
      const self = this;
      (0, _jquery.default)(`#${self.get('_gridId')} tbody`).on("click", "tr", function (e) {
        let rowElement = this;
        let row = (0, _jquery.default)(rowElement);
        let grid = (0, _jquery.default)(`#${self.get('_gridId')}`).getKendoGrid();
        if (row.hasClass("k-state-selected")) {
          let selected = grid.select();
          let itemToRemove = grid.dataItem(row);
          selected = (0, _lodash.filter)(selected, function (x) {
            let currentItem = grid.dataItem(x);
            return itemToRemove.id !== currentItem.id;
          });
          grid.clearSelection();
          grid.select(selected);
          e.stopPropagation();
        } else {
          grid.select(row);
          e.stopPropagation();
        }
      });
    },
    refreshGrid: function refreshGrid(resetSelected) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const grid = this.get('_grid');
      if (Ember.isPresent(grid)) {
        if (resetSelected) {
          grid.dataSource.page(1);
          grid.pager.refresh();
          grid.clearSelection();
        } else {
          grid.dataSource.read();
        }
        grid.refresh();
      }
    },
    clearSelection() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const grid = this.get('_grid');
      grid._selectedIds = {};
      grid.clearSelection();
      this.set('selectedItems', []);
    },
    resize: function resize() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      const grid = this.get('_grid');
      if (Ember.isPresent(grid)) {
        grid.resize();
      }
    },
    //endregion

    //region private or protected methods
    _selectedRowChanged() {
      let selectedItems = this.get('selectedItems');
      if (!(0, _lodash.isEmpty)(selectedItems)) {
        this._execSelectionChange(selectedItems);
      } else {
        this._execSelectionChange(null);
      }
    },
    _execSelectionChange(data) {
      if (this.get('assignInstanceIdOnRowSelectionChange') === true) {
        data['instanceGuid'] = this.get('app').guid();
      }
      this.get('onSelectionChange')(this.convertToEmberObject(data));
    },
    _autoRefreshGrid() {
      this.refreshGrid(false);
      (0, _emberLifeline.runTask)(this, '_autoRefreshGrid', this.get('autoRefreshInterval'));
    }
    //endregion
  });
  _exports.default = _default;
});