define("esis-ui/components/shared/checkbox-group-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NFk76IV5",
    "block": "{\"symbols\":[\"checkBoxItem\"],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"checkboxGroupClass\"]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"col-6 my-2\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"pretty p-icon p-smooth mr-0\"],[8],[0,\"\\n        \"],[1,[28,\"input\",null,[[\"type\",\"checked\",\"change\",\"disabled\"],[\"checkbox\",[23,1,[\"selected\"]],[28,\"action\",[[23,0,[]],\"_onChange\",[23,1,[\"id\"]]],null],[24,[\"disabled\"]]]]],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"state p-primary\"],[8],[0,\"\\n          \"],[7,\"i\",true],[10,\"class\",\"icon checkBoxIcon\"],[8],[1,[28,\"fa-icon\",[\"check\"],[[\"class\",\"fixedWidth\",\"size\"],[\"pb-3 pr-4\",true,\"2x\"]]],false],[9],[0,\"\\n          \"],[7,\"label\",true],[8],[1,[23,1,[\"displayName\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[7,\"i\",true],[8],[1,[28,\"shared/help-icon\",null,[[\"helpText\",\"renderInPlace\"],[[23,1,[\"description\"]],[24,[\"renderInPlace\"]]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/checkbox-group-action/template.hbs"
    }
  });
  _exports.default = _default;
});