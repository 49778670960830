define("esis-ui/helpers/concat-index", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.concatIndex = concatIndex;
  _exports.default = void 0;
  function concatIndex([index, value]) {
    if ((0, _lodash.isNumber)(index) && !(0, _lodash.isEmpty)(value)) {
      return `${index + 1}. ${value}`;
    }
    return '';
  }
  var _default = Ember.Helper.helper(concatIndex);
  _exports.default = _default;
});