define("esis-ui/components/areas/home/au/au-home-page/component", ["exports", "esis-ui/components/areas/home/home-page-base", "ember-lifeline", "ember-concurrency"], function (_exports, _homePageBase, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _homePageBase.default.extend({
    _regChartData: null,
    init() {
      this._super(...arguments);
      (0, _emberLifeline.runTask)(this, () => {
        this.loadData.perform();
      }, 100);
    },
    //region when tab activate
    onScreenChange: function onScreenChange() {
      this.selectInitialBusinessEntity();
      this.loadData.perform();
      this.set('_disableBusinessEntityPicklist', this.get('app').getHasActiveScreen());
    },
    //endregion

    _loadStatistics: (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.get('homeRepo.loadRegistrationStatistics').linked().perform(this.get('credential.currentBusinessEntityGuid'));
      } catch (e) {
        this.get('logger').logError('au-home-page._loadStatistics', e);
      }
    }),
    actions: {
      _onClickRegStatistics(viewName) {
        this.addTab('registrationListAu', null, null, true, {
          viewName: viewName
        });
      }
    }
  });
  _exports.default = _default;
});