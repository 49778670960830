define("esis-ui/components/areas/registration/nz/list/nz-registration-download-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    confirmBtnLabel: 'Download',
    onConfirmDownload: function onConfirmDownload() {},
    _reset() {
      this.setProperties({
        _numberOfRecords: null,
        _downloadType: null,
        _view: null
      });
    },
    show(numberOfRecords, view) {
      this._reset();
      this.set('_numberOfRecords', numberOfRecords);
      this.set('_view', view);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm: function _onConfirm() {
        this.get('onConfirmDownload')(this.get('_downloadType'));
      },
      _onTypeChange(value) {
        this.set('_downloadType', value);
      }
    }
  });
  _exports.default = _default;
});