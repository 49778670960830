define("esis-ui/components/shared/ember-bootstrap/modal/tab-restoration-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OBryp3Pe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"registerAs\",\"closeButton\",\"confirmButton\",\"confirmBtnLabel\",\"keyboard\",\"modalTitle\",\"onConfirm\",\"name\"],[[24,[\"_dialog\"]],[24,[\"fasle\"]],[24,[\"_confirmButton\"]],\"Proceed\",false,\"Please wait…\",[28,\"action\",[[23,0,[]],\"_proceed\"],null],\"tabRestoration\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"_bypass\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"previewStyle\"],[8],[1,[28,\"display-htmlsafe\",[[24,[\"message\"]]],null],false],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"previewStyle\"],[8],[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"spinner\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"k-text-justify\"],[8],[1,[28,\"display-htmlsafe\",[[24,[\"bypassMessage\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/modal/tab-restoration-modal/template.hbs"
    }
  });
  _exports.default = _default;
});