define("esis-ui/components/areas/shared/global-info-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V7Djw5iz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/general-modal-dialog\",null,[[\"modalTitle\",\"registerAs\",\"onConfirm\",\"onCancel\",\"confirmBtnLabel\",\"cancelBtnLabel\",\"closeButton\",\"renderInPlace\",\"confirmButton\"],[[24,[\"modalTitle\"]],[24,[\"_dialog\"]],[28,\"action\",[[23,0,[]],\"onConfirm\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null],[24,[\"confirmBtnLabel\"]],[24,[\"cancelBtnLabel\"]],[24,[\"cancelButton\"]],[24,[\"renderInPlace\"]],[24,[\"confirmButton\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[1,[22,\"message\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/global-info-modal/template.hbs"
    }
  });
  _exports.default = _default;
});