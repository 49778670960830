define("esis-ui/helpers/capitalize-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeString = capitalizeString;
  _exports.default = void 0;
  function capitalizeString([string]) {
    if (Ember.isBlank(string)) {
      return '';
    }
    return Ember.String.capitalize(string);
  }
  var _default = Ember.Helper.helper(capitalizeString);
  _exports.default = _default;
});