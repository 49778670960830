define("esis-ui/components/shared/command-bar-top/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DJ4u5xw2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[22,\"commandBarClass\"],\" topCommandBar mt-2 mb-2\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/command-bar-top/template.hbs"
    }
  });
  _exports.default = _default;
});