define("esis-ui/components/areas/shell/au/au-index-page/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "ember-lifeline", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _emberLifeline, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    title: 'Home',
    clientAppOutdated: false,
    _versionOutdatedDlg: null,
    _apiServiceStatus: 2,
    // good
    _auBusinessEntityLoadingDlg: null,
    init() {
      this._super(...arguments);
      this.set('app.region', this.get('constants.region.au'));
      (0, _emberLifeline.runTask)(this, () => {
        this.get('_load').perform();
      }, 500);
      this.get('event').on(this.get('constants.events.notification'), this, '_globalNotificationReceived');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.notification'), this, '_globalNotificationReceived');
      });
      this.get('event').on(this.get('constants.events.appVersionOutdated'), this, '_applicationOutdated');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.appVersionOutdated'), this, '_applicationOutdated');
      });
      this.get('event').on(this.get('constants.events.concurrencyError'), this, '_concurrencyErrorOccurred');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.concurrencyError'), this, '_concurrencyErrorOccurred');
      });
    },
    _load: (0, _emberConcurrency.task)(function* () {
      try {
        if (Ember.isPresent(this.get('_auBusinessEntityLoadingDlg'))) {
          this.get('_auBusinessEntityLoadingDlg').show();
        }
        let businessEntities = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('constants.region.au'), true, true, true);
        businessEntities = (0, _lodash.sortBy)(businessEntities, ['title']);
        const credential = this.get('credential');
        credential.set('businessEntityPicklist', businessEntities);
        this.selectInitialBusinessEntity();
        if (credential.get('currentUser.isCitecSearchUser') === true) {
          this.addTab("searchListAu");
        } else {
          this.addTab("homeAu", null, null, false);
        }
      } catch (e) {
        this.get('logger').logError('au-index-page._load', e);
        if (!(0, _lodash.isEmpty)(this.get('_emptyBEDlg'))) {
          this.get('_emptyBEDlg').show();
        }
      } finally {
        this.get('_auBusinessEntityLoadingDlg').hide();
      }
    }),
    //region global notification handler
    _globalNotificationReceived(notification) {
      const constants = this.get('constants');
      const logger = this.get('logger');
      logger.logDebug('New global notification received', notification);
      switch (notification.type) {
        case constants.notificationIdentifier.searchSubmitted:
          this.notifySuccess(`Search (${notification.id + 1000000}) has been submitted to PPSR`);
          break;
        case constants.notificationIdentifier.searchCertificateRetrieved:
          this.notifySuccess(`Search certificate has been retrieved and ready for download id: ${notification.id}`);
          break;
        case constants.notificationIdentifier.searchCertificateRetrievedBulk:
          this.notifySuccess(`All search certificates have been retrieved and ready for download id: ${notification.id + 1000000}`);
          break;
        case constants.notificationIdentifier.bulkSearchFileProcessed:
          this.notifySuccess(`Bulk search file ${notification.id + 1000000} has been processed`);
          break;
        case constants.notificationIdentifier.spgSubmitted:
          this.notifySuccess(`Secured party group (${notification.id + 1000000}) has been submitted`);
          break;
        case constants.notificationIdentifier.spgConfirmed:
          this.notifySuccess(`Secured party group (${notification.id + 1000000}) has been confirmed`);
          break;
        case constants.notificationIdentifier.spgAmendmentSubmitted:
          this.notifySuccess(`Secured party group (${notification.id + 1000000}) amendment has been submitted`);
          break;
        case constants.notificationIdentifier.spgAmendmentConfirmed:
          this.notifySuccess(`Secured party group (${notification.id + 1000000}) amendment has been confirmed`);
          break;
        case constants.notificationIdentifier.registrationCreateSubmitted:
          this.notifySuccess(`Registration (${notification.id + 2000000}) has been submitted`);
          break;
        case constants.notificationIdentifier.registrationCreateConfirmed:
          this.notifySuccess(`Registration (${notification.id + 2000000}) has been confirmed`);
          break;
        case constants.notificationIdentifier.registrationAmendmentSubmitted:
          this.notifySuccess(`Registration (${notification.id + 2000000}) amendment has been submitted`);
          break;
        case constants.notificationIdentifier.registrationAmendmentConfirmed:
          this.notifySuccess(`Registration (${notification.id + 2000000}) amendment has been confirmed`);
          break;
        case constants.notificationIdentifier.registrationDischargeSubmitted:
          this.notifySuccess(`Registration (${notification.id + 2000000}) discharge has been submitted`);
          break;
        case constants.notificationIdentifier.registrationDischargeConfirmed:
          this.notifySuccess(`Registration (${notification.id + 2000000}) discharge has been confirmed`);
          break;
        case constants.notificationIdentifier.bulkRegistrationCreateFileProcessed:
          this.notifySuccess(`Bulk registration file ${notification.id + 1000000} has been processed`);
          break;
        case constants.notificationIdentifier.bulkRegistrationAmendmentFileProcessed:
          this.notifySuccess(`Bulk registration amendment file ${notification.id + 1000000} has been processed`);
          break;
        case constants.notificationIdentifier.bulkRegistrationDischargeProcessed:
          this.notifySuccess(`Bulk registration discharge file ${notification.id + 1000000} has been processed`);
          break;
      }
    },
    _applicationOutdated(clientVersion, serverVersion) {
      if (this.get('clientAppOutdated') === false) {
        this.get('logger').logWarning('Application is outdated', clientVersion, serverVersion);
        this.set('clientAppOutdated', true);
        if (Ember.isPresent(this.get('_versionOutdatedDlg'))) {
          this.get('_versionOutdatedDlg').show(clientVersion, serverVersion);
        }
      }
    },
    _concurrencyErrorOccurred(message) {
      this.get('_concurrencyErrorDlg').show(message);
    }
    //endregion
  });
  _exports.default = _default;
});