define("esis-ui/repositories/picklist-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    //region common help options
    _addAllOption(data, allOptionLabel) {
      return [{
        value: 0,
        title: Ember.isPresent(allOptionLabel) ? allOptionLabel : 'All',
        sortOrder: 0,
        item: {
          id: 0
        }
      }].concat(data);
    },
    _loadPickListGuidInner: (0, _emberConcurrency.task)(function* (picklistType, includeAllOption = false, allOptionLabel) {
      const cache = this.get('cache');
      const key = this.getCacheKey(this.get('constants.cacheType.lookupCache'), picklistType);
      let result = [];
      if (Ember.isPresent(cache.getLookup(key))) {
        result = cache.getLookup(key);
      } else {
        const physicalEndpoint = this.get('constants.apiEndpoint.loadPicklistGuid');
        result = yield this.apiGet(this.fmt(physicalEndpoint, picklistType));
        cache.putLookup(key, result);
      }
      if (includeAllOption) {
        result = this._addAllOption(result, allOptionLabel);
      }
      return result;
    }),
    _loadPickListInner: (0, _emberConcurrency.task)(function* (picklistType, includeAllOption = false, allOptionLabel) {
      const cache = this.get('cache');
      const key = this.getCacheKey(this.get('constants.cacheType.lookupCache'), picklistType);
      let result = [];
      if (Ember.isPresent(cache.getLookup(key))) {
        result = cache.getLookup(key);
      } else {
        const physicalEndpoint = this.get('constants.apiEndpoint.loadPicklist');
        result = yield this.apiGet(this.fmt(physicalEndpoint, picklistType));
        cache.putLookup(key, result);
      }
      if (includeAllOption) {
        result = this._addAllOption(result, allOptionLabel);
      }
      return result;
    }),
    _loadPickListDigestInner: (0, _emberConcurrency.task)(function* (picklistType, includeAllOption = false, allOptionLabel) {
      const cache = this.get('cache');
      const key = this.getCacheKey(this.get('constants.cacheType.lookupCache'), picklistType);
      let result = [];
      if (Ember.isPresent(cache.getLookup(key))) {
        result = cache.getLookup(key);
      } else {
        const physicalEndpoint = this.get('constants.apiEndpoint.loadPicklistDigest');
        result = yield this.apiGet(this.fmt(physicalEndpoint, picklistType));
        cache.putLookup(key, result);
      }
      if (includeAllOption) {
        result = this._addAllOption(result, allOptionLabel);
      }
      return result;
    }),
    loadPicklistGuid: (0, _emberConcurrency.task)(function* (picklistType, includeAllOption, allOptionLabel) {
      return yield this.get('_loadPickListGuidInner').perform(picklistType, includeAllOption, allOptionLabel);
    }),
    loadPicklist: (0, _emberConcurrency.task)(function* (picklistType, includeAllOption, allOptionLabel) {
      return yield this.get('_loadPickListInner').perform(picklistType, includeAllOption, allOptionLabel);
    }),
    loadPicklistDigest: (0, _emberConcurrency.task)(function* (picklistType, includeAllOption, allOptionLabel) {
      return yield this.get('_loadPickListDigestInner').perform(picklistType, includeAllOption, allOptionLabel);
    }),
    loadCategories: (0, _emberConcurrency.task)(function* (entityId) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadCategories'), entityId));
    }),
    loadContractTypes: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadContractTypes'), entityGuid));
    }),
    loadNzContractTypes: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzContractTypes'), entityGuid));
    }),
    loadSpgListForRegistration: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSpgListForRegistration'), entityGuid));
    }),
    loadSpgListForNzFs: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSpgListForNzFs'), entityGuid));
    }),
    loadNzBranchCode: (0, _emberConcurrency.task)(function* (spgGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzBranchCode'), spgGuid));
    }),
    loadNzSpgListFromEntityDown: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzSpgListFromEntityDown'), entityGuid));
    }),
    loadNzBulkFileTypes: (0, _emberConcurrency.task)(function* () {
      return yield this.apiGet(this.get('constants.apiEndpoint.loadNzBulkFileTypes'));
    }),
    loadNzBranchCodeByEntity: (0, _emberConcurrency.task)(function* (entityGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzBranchCodeByEntity'), entityGuid));
    })
  });
  _exports.default = _default;
});