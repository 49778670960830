define("esis-ui/components/areas/shared/report-instance-display/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region properties
    // public
    displayNameOnly: false,
    parentContainer: null,
    showReportParameter: false,
    // private
    _reportViewer: null,
    _reportListGrid: null,
    _isReportVisible: false,
    _selectedReport: null,
    //endregion

    //region initialisation
    init() {
      this._super(...arguments);
      this.set('_reportListGridOption', {
        height: null,
        dataSource: {
          schema: {
            data: _data => {
              (0, _lodash.forEach)(_data, item => {
                item.dateCreated = this.dateTimeAsStr(item.dateCreated);
              });
              return _data;
            }
          },
          sort: [{
            field: "sortOrder",
            dir: "desc"
          }]
        },
        pageable: {
          alwaysVisible: false
        },
        columns: [{
          field: "reportTitle",
          title: "Report / Dataset",
          width: "150px",
          filterable: false
        }, {
          field: "dateCreated",
          title: "Date",
          width: "150px",
          filterable: false,
          hidden: this.get('displayNameOnly')
        }, {
          field: "userCreated",
          title: "User",
          width: "150px",
          filterable: false,
          hidden: this.get('displayNameOnly')
        }]
      });
      this.set('_reportViewerOption', {
        parametersAreaVisible: this.get('showReportParameter')
      });
      this.get('event').on(this.get('constants.events.screenElementChanged'), this, '_reportVisibilityChanged');
      (0, _emberLifeline.registerDisposable)(this, () => {
        this.get('event').off(this.get('constants.events.screenElementChanged'), this, '_reportVisibilityChanged');
      });
    },
    //endregion

    //region public method / interface
    loadReportInstance(data) {
      (0, _emberLifeline.runTask)(this, () => {
        this.set('_isReportVisible', false);
        this.get('_reportListGrid').loadData(data);
      });
    },
    //endregion

    //region private method
    _showReport(reportParam) {
      this.set('_selectedReport', reportParam);
      this.set('_reportViewerOption', {
        parametersAreaVisible: this.get('showReportParameter')
      });
      (0, _emberLifeline.runTask)(this, () => {
        this.get('_reportViewer').refresh(reportParam);
      }, 50);
    },
    _reportVisibilityChanged() {
      if (Ember.isPresent(this.get('parentContainer'))) {
        const isVisible = this.get('parentContainer.isVisible');
        this.set('_isReportVisible', isVisible);
        if (isVisible && Ember.isPresent(this.get('_selectedReport'))) {
          this._showReport(this.get('_selectedReport'));
        }
      }
    },
    //endregion

    //region action
    actions: {
      onSelectionChange: function onSelectionChange(data) {
        if (!(0, _lodash.isEmpty)(data)) {
          this.set('_isReportVisible', true);
          this._showReport({
            report: data.reportName.replace("^", "."),
            parameters: {
              Id: data.entityGuid
            }
          });
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});