define("esis-ui/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      redirectToForgotPassword() {
        this.transitionToRoute('forgot');
      },
      redirectToResetExpiredPassword(username) {
        this.transitionToRoute('reset-expired', username);
      }
    }
  });
  _exports.default = _default;
});