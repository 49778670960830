define("esis-ui/components/shared/splitter-control/component", ["exports", "esis-ui/components/shared/shared-component-base", "split.js", "lodash"], function (_exports, _sharedComponentBase, _split, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    panels: null,
    options: null,
    heightOffset: 100,
    paddingInPx: 3,
    attributeBindings: ['style'],
    style: Ember.computed('app.{tabContainerHeight,tabContainerWidth}', function () {
      const height = this.get('app.tabContainerHeight') - this.get('heightOffset');
      return Ember.String.htmlSafe(`height: ${height}px; width: ${this.get('app.tabContainerWidth')}px;`);
    }),
    init() {
      this._super(...arguments);
      this._defaultOptions = {
        direction: 'vertical',
        cursor: 'row-resize',
        onDragEnd: this._onChange.bind(this)
      };
    },
    didInsertElement() {
      this._super(...arguments);
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, this.get('_defaultOptions'), this.get('options'));
      if (Ember.isPresent(this.get('panels'))) {
        const thisElementId = this.get('elementId');
        const panelCount = this.get('panels').length;
        const panels = this.get('panels').map((item, index) => {
          if (mergedOptions.direction === 'horizontal') {
            this.$(item).addClass('split split-horizontal');
            if (Ember.isPresent(mergedOptions.minSize)) {
              this.$(item).css('min-width', `${mergedOptions.minSize[index]}px`);
            }
            if (index === 0) {
              this.$(item).css('padding-right', `${this.get('paddingInPx')}px`);
            } else if (index === panelCount - 1) {
              this.$(item).css('padding-left', `${this.get('paddingInPx')}px`);
            } else {
              this.$(item).css('padding-left', `${this.get('paddingInPx')}px`).css('padding-right', `${this.get('paddingInPx')}px`);
            }
          } else {
            if (Ember.isPresent(mergedOptions.minSize)) {
              this.$(item).css('min-height', `${mergedOptions.minSize[index]}px`);
            }
            if (index === 0) {
              this.$(item).css('padding-bottom', `${this.get('paddingInPx')}px`);
            } else if (index === panelCount - 1) {
              this.$(item).css('padding-top', `${this.get('paddingInPx')}px`);
            } else {
              this.$(item).css('padding-bottom', `${this.get('paddingInPx')}px`).css('padding-top', `${this.get('paddingInPx')}px`);
            }
          }
          return `#${thisElementId} ${item}`;
        });
        //console.log('split options', mergedOptions);
        //console.log('split panels', panels);
        (0, _split.default)(panels, mergedOptions);
      }
      // this.get('event').trigger(this.get('constants.events.tabResize'));
    },

    _onChange() {
      this.get('event').trigger(this.get('constants.events.splitterMoved'));
    }
  });
  _exports.default = _default;
});