define("esis-ui/components/areas/spg/au/list/au-spg-list/component", ["exports", "esis-ui/components/areas/spg/au/au-spg-view-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _auSpgViewPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auSpgViewPageBase.default.extend({
    //region properties
    _spgGrid: null,
    _selectedSpg: null,
    _spgGridContextMenu: null,
    _selectedBusinessEntity: null,
    _searchInactiveEntity: false,
    //region Preview Tab Ids
    _refPanel: 'refPanel',
    //endregion

    //region init component
    init() {
      this._super(...arguments);
      const app = this.get('app');

      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._searchByOptions = [{
        title: 'All',
        id: 1
      }, {
        title: 'Secured Party Group',
        id: 11
      }, {
        title: 'Secured Party',
        id: 12
      }];
      this._searchBy = (0, _lodash.find)(this._searchByOptions, {
        id: 1
      });
      this._viewFilterOptions = [{
        title: 'Under Review',
        value: 'review'
      }, {
        title: 'Delegates',
        value: 'delegate'
      }, {
        title: 'Pending',
        value: 'pending'
      }, {
        title: 'Errors',
        value: 'error'
      }, {
        title: 'Registered',
        value: 'registered'
      }, {
        title: 'All',
        value: 'all'
      }];
      this._view = 'all';
      this._loadPicklistMethod();
      //region SPG GRID
      this.set('_gridOption', {
        autoBind: false,
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('secured-party-groups')
            },
            parameterMap: (options, operation) => {
              options.viewName = this.get('_view');
              options.businessEntityGuid = this.get('_selectedBusinessEntity.value');
              options.activeOnly = this.get('_activeOnly');
              options.searchInactiveEntity = this.get('_searchInactiveEntity');
              let keyword = this.get('_filterCriteria');
              if (!(0, _lodash.isNil)(keyword)) {
                options.filterScope = this.get('_searchBy').id;
                options.filterKeyword = keyword;
              }
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                if (realData[i].status === app.constants.statusValue.submitted || realData[i].status === app.constants.statusValue.confirmed || realData[i].status === app.constants.statusValue.delegate) {
                  realData[i].class = 'submittedStatusIconColor';
                  realData[i].iconName = 'check';
                } else if (realData[i].status === app.constants.statusValue.pending || realData[i].status === app.constants.statusValue.creating || realData[i].status === app.constants.statusValue.validated || realData[i].status === app.constants.statusValue.review) {
                  realData[i].class = 'pendingStatusIconColor';
                  realData[i].iconName = 'play';
                } else {
                  realData[i].class = 'otherStatusIconColor';
                  realData[i].iconName = 'exclamationTriangle';
                }

                //context menu disable logic
                let canConvert = realData[i].canConvert;
                let canRetrieve = realData[i].canRetrieve;
                let enableDelete = realData[i].canDelete;
                let canManageSPG = this.get('credential.hasCreateSpgPermissionAu');
                // order is important. It must be the same as action options order (_spgGridContextMenu below)
                realData[i].disableActionStates = [!canManageSPG, !canManageSPG, !canManageSPG, !canManageSPG];
                realData[i].hideActionStates = [!canConvert, !canRetrieve, false, !enableDelete];
              }
              return realData;
            }
          }
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "color",
          title: ' ',
          template: dataItem => {
            return `<span class="${(0, _lodash.isEmpty)(dataItem.class) ? 'unKnownStatusIconColor' : dataItem.class}">` + this.get('constants.fIcon')[dataItem.iconName] + `</span>`;
          },
          width: "45px"
        }, {
          field: "esisId",
          title: "Esis ID",
          filterable: false,
          width: "100px",
          template: '<a data-id="#:guid#" data-wizard="#: isCreating#" data-esis="#: esisId#" data-status="#: statusTitle#" class="grid-link-id">#: esisId #</a>'
        }, {
          field: "ppsrSecuredPartyGroupNumber",
          title: "SPG Number",
          width: "100px",
          filterable: false
        }, {
          field: "securedPartyGroupName",
          title: "SPG Name",
          filterable: false
        }, {
          field: "isActive",
          title: 'Active',
          filterable: false,
          width: "70px",
          template: `<span class=' #: isActive ? 'isActiveStyle' : 'isNotActiveStyle' #'>${this.get('constants.fIcon.squareFull')}</span>`
        }, {
          field: "statusTitle",
          title: "Status",
          width: "100px",
          filterable: false
        }, {
          field: "reference",
          title: "Reference",
          filterable: false
        }, {
          field: "entityTitle",
          title: 'Business Entity',
          filterable: false,
          width: "300px",
          template: `<span class=' #: entityIsActive ? 'isActiveStyle' : 'isNotActiveStyle' #'>${this.get('constants.fIcon.squareFull')}</span><span class="ml-1"> #: entityTitle #</span>`
        }]
      });
      this._convertToDelegateActionId = 'auSpgConvertToDelegateId';
      this._syncToPPSRActionId = 'auSpgSyncToPPSRId';
      this._transferActionId = 'transferAuSpgId';
      this._copyActionId = 'copyAuSpgId';
      this._deleteActionId = 'deleteAuSpgId';
      this._spgGridContextMenu = [{
        title: 'Convert To Delegate',
        id: this.get('_convertToDelegateActionId'),
        icon: 'retweet',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Copy',
        id: this.get('_copyActionId'),
        icon: 'share-alt',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Sync to PPSR',
        id: this.get('_syncToPPSRActionId'),
        icon: 'cloud-download-alt',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Transfer',
        id: this.get('_transferActionId'),
        icon: 'file-export',
        iconClass: 'defaultCtxMenuOpt'
      }, {
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
    },

    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_spgGrid'))) {
          if (reset === true) {
            this.set('_selectedSpg', null);
            this.set('_spgPreview', null);
          }
          this.get('_spgGrid').refreshGrid(reset);
          this.set('_refreshNotification', false);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    _openSpgTab: function _openSpgTab(guid, esisId, status, showWizard) {
      const credential = this.get('app.credential');
      let tabName = showWizard && credential.get('hasCreateSpgPermissionAu') ? 'spgWizardAu' : 'spgReviewAu';
      this.addTab(tabName, guid, null, true);
    },
    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region when tab activate
    onScreenChange: function onScreenChange() {
      this._refreshGrid(); // soft refresh
      if (this.get('tabModel.options.view')) {
        this.set('_view', this.get('tabModel.options.view'));
        this.set('tabModel.options.view', null);
      }
    },
    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshGrid();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _convertToDelegate: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('convertToDelegate').perform(this.get('_selectedSpg.guid'));
      } catch (error) {
        this.get('logger').logError('au-spg-list._convertToDelegate', error);
      }
    }),
    _syncToPPSR: (0, _emberConcurrency.task)(function* () {
      try {
        // if (!isEmpty(this.get('detailDigest.guid')) && this.get('detailDigest.canRetrieve')) {
        if (!(0, _lodash.isEmpty)(this.get('_selectedSpg.guid'))) {
          yield this.get('spgRepo.retrieveSpg').perform(this.get('_selectedSpg.guid'), true);
          this.notifySuccess(`Secured party group ${this.get('_selectedSpg.esisId')} has been synchronised with PPSR`);
          this._refreshGrid(true);
        }
      } catch (error) {
        this.get('logger').logError('au-spg-list._syncToPPSR', error);
      }
    }),
    _deleteSpg: (0, _emberConcurrency.task)(function* () {
      try {
        // if (Ember.isPresent(this.get('detailDigest.guid')) && this.get('detailDigest.canDelete')) {
        if (!(0, _lodash.isEmpty)(this.get('_selectedSpg.guid'))) {
          yield this.get('spgRepo.deleteSpg').perform(this.get('_selectedSpg.guid'));
          this.notifySuccess(`Secured party group ${this.get('_selectedSpg.esisId')} has been deleted`);
          this._refreshGrid(true);
        }
      } catch (error) {
        this.get('logger').logError('au-spg-list._deleteSpg', error);
      }
    }),
    _createDelegateSpg: (0, _emberConcurrency.task)(function* (model) {
      try {
        let result = yield this.get('spgRepo.saveSecuredPartyGroup').perform(model);
        if (result.isValid) {
          this.notifySuccess(`Secured party group ${result.esisId} saved`);
          this.get('_delegateDlg').hide();
        } else {
          this.notifyError(result.validationErrorMessage);
        }
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-spg-list._createDelegateSpg', error);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedSpg')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedSpg', data);
        if (Ember.isPresent(this.get('_tabPreview'))) {
          (0, _emberLifeline.runTask)(this, () => {
            this.get('_tabPreview').activateTab(this.get('_refPanel'));
          }, 50);
        }
      },
      _onLinkClick: function _onLinkClick(element) {
        let guid = element.data('id');
        let esisId = element.data('esis');
        let status = element.data('status');
        let wizard = element.data('wizard');
        this._openSpgTab(guid, esisId, status, wizard);
      },
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedSpg', selectedRow);
          if (selectedOption === this.get('_convertToDelegateActionId')) {
            this.get('_convertToDelegateDlg').show();
          } else if (selectedOption === this.get('_copyActionId')) {
            this.get('_copySpgDlg').show(selectedRow);
          } else if (selectedOption === this.get('_syncToPPSRActionId')) {
            this.get('_syncToPPSRDlg').show();
          } else if (selectedOption === this.get('_transferActionId')) {
            this.get('_transferDlg').show();
          } else if (selectedOption === this.get('_deleteActionId')) {
            this.get('_deleteSpgDlg').show();
          }
        }
      },
      _onConfirmConvertToDelegate: function _onConfirmConvertToDelegate() {
        this.get('_convertToDelegate').perform();
        this._refreshGrid(true);
      },
      _onConfirmSyncToPPSR: function _onConfirmSyncToPPSR() {
        this.get('_syncToPPSR').perform();
      },
      _onDelete: function _onDelete() {
        this.get('_deleteSpg').perform();
      },
      //endregion

      //region FILTER ACTIONS
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this._refreshGrid(true);
      },
      _searchByChanged: function _searchByChanged(option) {
        this.set('_searchBy', option);
        if (this.get('_filterCriteria')) {
          this._refreshGrid(true);
        }
      },
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view)) {
          this.set('_view', view);
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this.set('_view', 'all');
        this._refreshGrid(true);
      },
      _onActiveOnlyChange: function _onActiveOnlyChange(value) {
        this.set('_activeOnly', value);
        this._refreshGrid(true);
      },
      _onSearchInactiveEntityChange: function _onSearchInactiveEntityChange(value) {
        this.set('_searchInactiveEntity', value);
        this._refreshGrid(true);
      },
      //endregion

      //region BUTTON ACTIONS
      _onAddStandardSpgClick() {
        this.addTab("spgWizardAu", null, this.get('_selectedBusinessEntity.value'));
      },
      _onAddDelegateSpgClick() {
        this.get('_delegateDlg').show(this.mapJsonObjectToModel({
          entityGuid: this.get('_selectedBusinessEntity.value')
        }, 'secured-party-group'));
      },
      //endregion

      _onConfirmCreateDelegate(model) {
        this.get('_createDelegateSpg').perform(model);
      },
      _onConfirmCopySpg() {
        this.set('_view', 'review');
        this._refreshGrid(true);
      }
    }
    //endregion
  });
  _exports.default = _default;
});