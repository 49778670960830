define("esis-ui/components/areas/spg/nz/nz-spg-view-page-base", ["exports", "esis-ui/components/areas/shared/nz-page-base", "ember-concurrency", "lodash"], function (_exports, _nzPageBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzPageBase.default.extend({
    _waitingModal: null,
    //region private methods
    _showWaitModalDlg() {
      if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing !== true) {
        this.get('_waitingModal').show('Please be patient while we are submitting your request...');
      }
    },
    _hideWaitModalDlg() {
      if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing === true) {
        this.get('_waitingModal').hide();
      }
    },
    //endregion

    //region SUBMIT SPG
    submitCreateNzSpg: (0, _emberConcurrency.task)(function* (model) {
      let valid = model.validate();
      if (valid) {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        try {
          this._showWaitModalDlg();
          this.notifyInfo('Submitting Secured Party Group to PPSR ... this may take up to a minute');
          let result = yield this.get('spgRepo.submitCreateNzSpg').perform(model);
          const picklistType = this.get('constants.picklistType.nzSpgFromEntityDownPicklist');
          this.get('cache').deleteLookup(this.getCacheKey(this.get('constants.cacheType.lookupCache'), picklistType));
          if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
            this.get('saveTimeoutTaskInstance').cancel();
            this.set('saveTimeoutTaskInstance', null);
          }
          this._hideWaitModalDlg();
          this.get('logger').logDebug(`Secured Party Group submitted to PPSR successfully.`, result);
          this.notifySuccessSticky(`Secured Party Group - ${result.spgId} submitted to PPSR successfully.`);
          this.set('model', null);
          this.removeTab('spgListNz');
        } catch (error) {
          this._hideWaitModalDlg();
          if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
            this.get('saveTimeoutTaskInstance').cancel();
            this.set('saveTimeoutTaskInstance', null);
          }
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', error.errorMessage);
        }
      } else {
        this.get('saveTimeoutTaskInstance').cancel();
        this.set('saveTimeoutTaskInstance', null);
        this.set('model.displayException', true);
      }
    }).drop(),
    submitUpdateNzSpg: (0, _emberConcurrency.task)(function* (model) {
      let valid = model.validate();
      if (valid) {
        this.set('model.displayException', false);
        this.set('model.validatErrorMessage', '');
        try {
          this._showWaitModalDlg();
          this.notifyInfo('Submitting Secured Party Group to PPSR ... this may take up to a minute');
          let result = yield this.get('spgRepo.submitUpdateNzSpg').perform(model);
          const picklistType = this.get('constants.picklistType.nzSpgFromEntityDownPicklist');
          this.get('cache').deleteLookup(this.getCacheKey(this.get('constants.cacheType.lookupCache'), picklistType));
          if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
            this.get('saveTimeoutTaskInstance').cancel();
            this.set('saveTimeoutTaskInstance', null);
          }
          this._hideWaitModalDlg();
          this.get('logger').logDebug(`Secured Party Group submitted to PPSR successfully.`, result);
          this.notifySuccessSticky(`Secured Party Group - ${result.spgId} submitted to PPSR successfully.`);
          this.set('model', null);
          this.removeTab('spgListNz');
        } catch (error) {
          this._hideWaitModalDlg();
          if (!(0, _lodash.isEmpty)(this.get('saveTimeoutTaskInstance'))) {
            this.get('saveTimeoutTaskInstance').cancel();
            this.set('saveTimeoutTaskInstance', null);
          }
          this.set('model.displayException', true);
          this.set('model.validatErrorMessage', error.errorMessage);
        }
      } else {
        this.get('saveTimeoutTaskInstance').cancel();
        this.set('saveTimeoutTaskInstance', null);
        this.set('model.displayException', true);
      }
    }).drop(),
    runTimeout: (0, _emberConcurrency.task)(function* (taskInstance) {
      yield (0, _emberConcurrency.timeout)(30000);
      if (!(0, _lodash.isEmpty)(this.get(taskInstance))) {
        this.get(taskInstance).cancel();
        this.set(taskInstance, null);
      }
      this._hideWaitModalDlg();
      this.get('logger').logError('submit-spg-control.submitSpg: Saving timed out');
      this.notifyError('Submitting timed out, please try again');
    })
    //endregion
  });
  _exports.default = _default;
});