define("esis-ui/components/areas/admin/category/au/view/category-edit-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    successMsg: '',
    _selectedBusinessEntity: null,
    _defaultBusinessEntityGuid: null,
    init() {
      this._super(...arguments);
      this.setProperties({
        '_dialog': null,
        '_requestModel': null,
        '_selectedBusinessEntity': null
      });
    },
    //endregion

    //region METHODS
    _loadModel(model) {
      let requestModel = this.createModel('category');
      if (model.entityGuid !== this.emptyGuid()) {
        this.set('_defaultBusinessEntityGuid', model.entityGuid);
      } else {
        this.set('_defaultBusinessEntityGuid', this.get('credential.currentBusinessEntityGuid'));
      }
      requestModel = this.mapJsonObjectToModel(model, 'category');
      this.set('_requestModel', requestModel);
      this.set('_requestModel.entityGuid', this.get('_defaultBusinessEntityGuid'));
      this.get('_requestModel').save();
    },
    _reset: function _reset() {
      this.setProperties({
        _requestModel: null,
        _selectedBusinessEntity: null,
        _defaultBusinessEntityGuid: null
      });
    },
    show(model) {
      this._reset();
      this._loadModel(model);
      this._loadPicklist.perform();
      this.get('_dialog').show();
    },
    //endregion

    //region TASKS
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('_defaultBusinessEntityGuid')
        }));
      } catch (error) {
        this.get('logger').logError('category-edit-modal._loadPicklist', error);
      }
    }),
    _saveCategory: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.saveCategory').perform(this.get('_requestModel'));
        this.notifySuccess(this.get('successMsg'));
        this.get('_dialog').hide();
        if (!(0, _lodash.isEmpty)(this.get('onConfirm'))) {
          this.get('onConfirm')();
        }
      } catch (error) {
        this.get('logger').logError('category-edit-modal._saveCategory', error);
        this.notifyError(`Verification failed. ${error.errorMessage}`);
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('_requestModel.entityGuid', businessEntity.value);
      },
      //region BUTTON ACTIONS
      _onConfirm() {
        this.get('_saveCategory').perform();
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});