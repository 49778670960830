define("esis-ui/templates/reset-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+F826gyG",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"areas/login/reset-expired-cred-access-key\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/templates/reset-expired.hbs"
    }
  });
  _exports.default = _default;
});