define("esis-ui/components/areas/registration/nz/wizard/nz-select-collateral-type-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    modalTitle: 'Select Collateral Type',
    collateral: null,
    init() {
      this._super(...arguments);
      this._collAddByType = {
        addByCollProfile: 1,
        addByCollateralType: 2
      };
    },
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        // Collateral Profiles
        let collateralProfilePicklist = yield this.get('picklistRepo.loadNzContractTypes').perform(this.get('credential.currentBusinessEntity.value'));
        this.set('_collProfileList', collateralProfilePicklist);

        // COLLATERAL TYPES
        let collateralTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.nzFsCollateralType'));
        this.set('_collateralTypeList', collateralTypePicklist);
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _reset() {
      this.setProperties({
        _selectedCollProfile: null,
        _selectedCollateralType: null,
        _addType: this.get('_collAddByType.addByCollProfile')
      });
    },
    show() {
      this._reset();
      this.set('_modalGuid', this.get('app').guid());
      this.set('_addType', this.get('_collAddByType.addByCollProfile'));
      this._loadPicklists.perform();
      this.get('_dialog').show();
    },
    //region Closure Actions
    onSelectCollateralType() {},
    //endregion
    actions: {
      _onAddColTypeChange(value) {
        this.set('_addType', value);
        if (value === this.get('_collAddByType.addByCollateralType')) {
          this.set('_selectedCollProfile', null);
        } else if (value === this.get('_collAddByType.addByCollProfile')) {
          this.set('_selectedCollateralType', null);
        }
      },
      _onChangeCollProfile(selected) {
        this.set('_selectedCollProfile', selected);
      },
      _onChangeCollateralType(selected) {
        this.set('_selectedCollateralType', selected);
      },
      _onConfirm() {
        if (!(0, _lodash.isNil)(this.get('_selectedCollateralType')) || !(0, _lodash.isNil)(this.get('_selectedCollProfile'))) {
          const args = {
            collateralProfile: this.get('_selectedCollProfile'),
            collateralType: this.get('_selectedCollateralType')
          };
          this.get('onSelectCollateralType')(args);
        }
      }
    }
  });
  _exports.default = _default;
});