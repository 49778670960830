define("esis-ui/components/areas/spg/nz/wizard/nz-spg-email-notification-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V6BfcLdt",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"form-horizontal\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"info-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"offset-md-3 inline\"],[8],[0,\"\\n        \"],[1,[28,\"shared/checkbox-control\",null,[[\"property\",\"class\",\"title\"],[[24,[\"model\",\"configSettings\",\"emailNotificationOnAmend\"]],\"mb-2\",\"Amend\"]]],false],[0,\"\\n        \"],[1,[28,\"shared/checkbox-control\",null,[[\"property\",\"class\",\"title\"],[[24,[\"model\",\"configSettings\",\"emailNotificationOnRegistration\"]],\"mb-2\",\"Registration\"]]],false],[0,\"\\n        \"],[1,[28,\"shared/checkbox-control\",null,[[\"property\",\"class\",\"title\"],[[24,[\"model\",\"configSettings\",\"emailNotificationOnRenewal\"]],\"mb-2\",\"Renewal\"]]],false],[0,\"\\n        \"],[1,[28,\"shared/checkbox-control\",null,[[\"property\",\"class\",\"title\"],[[24,[\"model\",\"configSettings\",\"emailNotificationOnDischarge\"]],\"mb-2\",\"Discharge\"]]],false],[0,\"\\n        \"],[1,[28,\"shared/checkbox-control\",null,[[\"property\",\"class\",\"title\"],[[24,[\"model\",\"configSettings\",\"emailNotificationWithPin\"]],\"mb-2\",\"Financing Statement PIN for New Registrations\"]]],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"mt-4\"],[8],[0,\"\\n          \"],[7,\"p\",true],[8],[0,\"Selected notifications will be sent to the following email addresses:\"],[9],[0,\"\\n          \"],[7,\"ul\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"_emailList\"]]],null,{\"statements\":[[0,\"              \"],[7,\"li\",true],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"          \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/spg/nz/wizard/nz-spg-email-notification-step/template.hbs"
    }
  });
  _exports.default = _default;
});