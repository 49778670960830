define("esis-ui/components/areas/admin/business-entity/view/business-entity-linked-user-list/component", ["exports", "esis-ui/components/areas/shared/view-page-base", "ember-lifeline"], function (_exports, _viewPageBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _viewPageBase.default.extend({
    init() {
      this._super(...arguments);
      this.loadListData();
      //region BRANCH CODE GRID
      this.set('_gridOption', {
        height: 600,
        dataSource: {
          pageSize: 99999999,
          sort: [{
            field: "userName",
            dir: "asc"
          }]
        },
        columns: [{
          field: "userName",
          title: 'User Name',
          filterable: false,
          width: "100px"
        }, {
          field: "isActive",
          title: 'Active',
          filterable: false,
          width: "80px",
          template: dataItem => {
            return this.formatBoolean(dataItem.isActive);
          }
        }, {
          field: 'firstName',
          title: 'First Name',
          filterable: false,
          width: "100px"
        }, {
          field: "lastName",
          title: 'Last Name',
          filterable: false,
          width: "100px"
        }, {
          field: "email",
          title: 'Email',
          filterable: false,
          width: "200px"
        }, {
          field: "isDirectlyLinked",
          title: 'Directly Linked',
          filterable: false,
          width: "150px",
          template: dataItem => {
            return this.formatBoolean(dataItem.isDirectlyLinked);
          }
        }]
      });
      //endregion
    },

    //region COMPUTED PROPERTIES
    _allowRemoveLinkedUser: Ember.computed('_selectedUser', function () {
      return this.get('_selectedUser.isDirectlyLinked');
    }),
    //endregion

    //region METHODS
    loadListData() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_linkedUserGrid')) && Ember.isPresent(this.get('detailsModel'))) {
          this.get('_linkedUserGrid').loadData(this.get('detailsModel').users);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this.loadListData();
          }, 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      _onItemSelectionChange(data) {
        this.set('_selectedUser', data);
      },
      _addLinkedUser() {
        this.get('onAddUserClick')();
      },
      _removeLinkedUser() {
        this.get('onRemoveUserClick')(this.get('_selectedUser'));
      }
    }
    //endregion
  });
  _exports.default = _default;
});