define("esis-ui/helpers/not-starts-with", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.notStartsWith = notStartsWith;
  function notStartsWith([value, test]) {
    return !(0, _lodash.startsWith)(value, test);
  }
  var _default = Ember.Helper.helper(notStartsWith);
  _exports.default = _default;
});