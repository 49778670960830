define("esis-ui/components/areas/spg/au/view/au-access-code-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fwjzlMJw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"registerAs\",\"size\",\"confirmBtnLabel\",\"modalTitle\",\"name\",\"dismiss\",\"onConfirm\"],[[24,[\"_dialog\"]],\"md\",\"OK\",[24,[\"modalTitle\"]],\"editPPSRAccessCode\",false,[28,\"action\",[[23,0,[]],\"_onConfirm\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ml-0 mr-2 mb-4 w-100\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"mb-1\"],[8],[0,\"PPSR Access Code\"],[9],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"class\",\"maxlength\"],[\"text\",[24,[\"_ppsrAvccessCode\"]],\"form-control text-field\",\"20\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/spg/au/view/au-access-code-modal/template.hbs"
    }
  });
  _exports.default = _default;
});