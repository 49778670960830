define("esis-ui/components/areas/report/au/list/au-report-list/component", ["exports", "esis-ui/components/areas/shared/au-page-base", "lodash"], function (_exports, _auPageBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    //region INITIALIZATION
    _reportList: null,
    _selectedReport: null,
    init() {
      this._super(...arguments);
      this.set('_reportList', this._loadReportList());
    },
    //endregion

    //region METHODS
    _loadReportList: function _loadReportList() {
      let reports = [];
      reports.addObject({
        hasPermission: this.get('credential.hasViewRegistrationPermissionAu'),
        title: 'Registrations by Entity',
        tabId: this.newGuid(),
        componentName: 'areas/report/au/view/registration-by-entity'
      });
      reports.addObject({
        hasPermission: this.get('credential.hasViewRegistrationPermissionAu'),
        title: 'Registrations by Secured Party Group',
        tabId: this.newGuid(),
        componentName: 'areas/report/au/view/registration-by-spg'
      });
      reports.addObject({
        hasPermission: this.get('credential.hasManageEntityPermission'),
        title: 'Missing Grantor Organisation Names',
        tabId: this.newGuid(),
        componentName: 'areas/report/au/view/grantor-org-noname-by-entity'
      });
      reports.addObject({
        hasPermission: this.get('credential.hasViewAllSearchPermissionAu'),
        title: 'Grantor Search Comparison',
        tabId: this.newGuid(),
        componentName: 'areas/report/au/view/search-grantor-diff'
      });
      reports.addObject({
        hasPermission: this.get('credential.hasViewSystemReportPermission'),
        title: 'Registration Count by Entity',
        tabId: this.newGuid(),
        componentName: 'areas/report/au/view/registration-count-by-entity'
      });
      this.set('_selectedReport', (0, _lodash.filter)(reports, 'hasPermission')[0]);
      return reports;
    },
    //endregion

    //region ACTIONS
    actions: {
      _selectReport: function _selectReport(selected) {
        this.set('_selectedReport', selected);
      },
      _showWaitModalDlg() {
        if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing !== true) {
          this.get('_waitingModal').show('Please be patient while we prepare report to download...');
        }
      },
      _hideWaitModalDlg() {
        if (!(0, _lodash.isNil)(this.get('_waitingModal')) && this.get('_waitingModal').isShowing === true) {
          this.get('_waitingModal').hide();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});