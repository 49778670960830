define("esis-ui/components/areas/admin/business-entity/view/business-entity-edit-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _requestModel: null,
    _parentPicklist: null,
    _selectedParent: null,
    _selectedRegion: null,
    _entityTypePicklist: null,
    _selectedEntityType: null,
    _entityClassPickList: null,
    _selectedEntityClass: null,
    init() {
      this._super(...arguments);
      this._regionList = [{
        title: 'AU',
        value: this.get('constants.region.au')
      }, {
        title: 'NZ',
        value: this.get('constants.region.nz')
      }];
      this._dialog = null;
    },
    //endregion

    //region METHODS
    _loadModel(model) {
      let requestModel = this.createModel('business-entity');
      if ((0, _lodash.isEmpty)(model)) {
        requestModel.setProperties({
          parentEntityGuid: this.get('credential.currentBusinessEntity.value'),
          isActive: true,
          entityTypeId: 4,
          entityClass: 1,
          regionId: this.get('credential.currentRegion')
        });
      } else {
        requestModel = this.mapJsonObjectToModel(model, 'business-entity');
      }
      this.set('_requestModel', requestModel);
      this.get('_requestModel').save();
    },
    _reset: function _reset() {
      this.setProperties({
        _requestModel: null,
        _parentPicklist: null,
        _selectedParent: null,
        _entityTypePicklist: null,
        _selectedEntityType: null,
        _entityClassPickList: null,
        _selectedEntityClass: null
      });
    },
    show(model) {
      this._reset();
      this._loadModel(model);
      this._loadPicklists.perform().then(() => {
        this.get('_dialog').show();
      });
    },
    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      const constants = this.get('constants');
      try {
        let childTasks = [];
        const parentTask = this.get('adminRepo.loadBusinessEntitiesForEditing').perform({
          businessEntityGuid: this.get('_requestModel.guid')
        });
        childTasks.push(parentTask);
        const entityTypeTask = this.get('picklistRepo.loadPicklist').perform(constants.get('picklistType.entityTypePicklist'));
        childTasks.push(entityTypeTask);
        const entityClassTask = this.get('picklistRepo.loadPicklist').perform(constants.get('picklistType.entityClassesPicklist'));
        childTasks.push(entityClassTask);
        yield (0, _emberConcurrency.all)(childTasks);

        // init Parent data
        let parentsPicklist = parentTask.value;
        this.set('_parentPicklist', parentsPicklist);
        this.set('_selectedParent', (0, _lodash.find)(parentsPicklist, {
          'value': this.get('_requestModel.parentEntityGuid')
        }));

        // init Entity Type data
        let entityTypePickList = entityTypeTask.value;
        entityTypePickList = (0, _lodash.orderBy)(entityTypePickList, 'sortOrder');
        this.set('_entityTypePicklist', entityTypePickList);
        this.set('_selectedEntityType', (0, _lodash.find)(entityTypePickList, {
          'value': this.get('_requestModel.entityTypeId')
        }));

        // init Entity Class data
        let entityClassPickList = entityClassTask.value;
        entityClassPickList = (0, _lodash.orderBy)(entityClassPickList, 'sortOrder');
        this.set('_entityClassPickList', entityClassPickList);
        this.set('_selectedEntityClass', (0, _lodash.find)(entityClassPickList, {
          'value': this.get('_requestModel.entityClass')
        }));

        // init Region data
        this.set('_selectedRegion', (0, _lodash.find)(this.get('_regionList'), {
          value: this.get('_requestModel.regionId')
        }));
      } catch (error) {
        this.get('logger').logError('business-entity-edit-modal.loadPicklists', error);
      }
    }),
    _saveBusinessEntity: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('adminRepo.saveBusinessEntity').perform(this.get('_requestModel'));
        this.notifySuccess(this.get('successMsg'));
        this.get('_dialog').hide();
        if (!(0, _lodash.isEmpty)(this.get('onSave'))) {
          this.get('onSave')();
        }
      } catch (error) {
        this.get('logger').logError('business-entity-edit-modal._saveBusinessEntity', error);
        this.set('_requestModel.displayException', true);
        this.set('_requestModel.validatErrorMessage', error.errorMessage);
      }
    }),
    //endregion
    //region ACTIONS
    actions: {
      _onConfirm: function _onConfirm() {
        if (this.get('_requestModel').validate()) {
          this._saveBusinessEntity.perform();
        }
      },
      _onParentChange(selected) {
        this.set('_selectedParent', selected);
        this.set('_requestModel.parentEntityGuid', selected.value);
      },
      _onEntityTypeChange(selected) {
        this.set('_selectedEntityType', selected);
        this.set('_requestModel.entityTypeId', selected.value);
      },
      _onEntityClassChange(selected) {
        this.set('_selectedEntityClass', selected);
        this.set('_requestModel.entityClass', selected.value);
      },
      _onRegionChange(selected) {
        this.set('_selectedRegion', selected);
        this.set('_requestModel.regionId', selected.value);
      },
      _onBillFromDateChanged(value) {
        this.set('_requestModel.billFrom', value);
      }
    }
    //endregion
  });
  _exports.default = _default;
});