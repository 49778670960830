define("esis-ui/components/shared/slider/ember-ui-slider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7fIVdrXA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\",true],[11,\"id\",[29,[\"slider-value-\",[22,\"elementId\"]]]],[10,\"class\",\"slider-value\"],[10,\"type\",\"text\"],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/slider/ember-ui-slider/template.hbs"
    }
  });
  _exports.default = _default;
});