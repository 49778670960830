define("esis-ui/components/shared/ember-bootstrap/modal/modal-dialog/component", ["exports", "esis-ui/components/shared/ember-bootstrap/modal/modal-dialog-base"], function (_exports, _modalDialogBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modalDialogBase.default.extend({
    name: 'ModalDlg',
    isDirty: false,
    dismiss: true,
    dirtyRecordConfirmMsg: null,
    _confirmDlg: null,
    confirmButtonDisabled: false,
    modalIsDirty: Ember.computed('isDirty', 'model.isDirty', function () {
      return Ember.isPresent(this.get('isDirty')) && this.get('isDirty') || Ember.isPresent(this.get('model.isDirty')) && this.get('model.isDirty');
    }),
    _showConfirmDialog: function _showConfirmDialog() {
      if (Ember.isPresent(this.get('_confirmDlg'))) {
        this.get('_confirmDlg').show();
      }
    },
    // --- ACTIONS ---
    actions: {
      confirm: function confirm() {
        let shouldDismiss = true;
        if (Ember.isPresent(this.get('onConfirm'))) {
          shouldDismiss = this.get('onConfirm')();
        }
        if (shouldDismiss !== false && this.get('dismiss')) {
          this.hide();
        }
      },
      cancel: function cancel() {
        if (Ember.isPresent(this.get('onCancel'))) {
          this.get('onCancel')();
        }
        if (this.get('modalIsDirty')) {
          this._showConfirmDialog();
        } else {
          this.hide();
        }
      },
      onDirtyDiscardConfirmation: function onDirtyDiscardConfirmation() {
        this.hide();
      }
    }
  });
  _exports.default = _default;
});