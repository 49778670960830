define("esis-ui/components/shared/ember-bootstrap/dynamic-tab/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-resize-aware/mixins/resize-aware", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _resizeAware, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend(_resizeAware.default, {
    /*
    this property is binded to #bs-tab.isActive property, it is used to programmatically set the current active tab,
    but it is not always synced to the latest changes, i.e. one way binding
     */
    activeTabId: '',
    confirmDlg: null,
    tabHeight: 600,
    tabWidth: 800,
    tabWidthOffset: 20,
    tabHeightOffset: 90,
    _notificationTimeoutJobId: null,
    _apiConnectionIsEstablished: false,
    _isConnectionEstablished: false,
    _tabRestorationModal: null,
    _pendingRestoreTab: false,
    tabFixSize: Ember.computed('tabHeight', 'tabWidth', function () {
      return Ember.String.htmlSafe(`height: ${this.get('tabHeight')}px; width: ${this.get('tabWidth')}px;`);
    }),
    _tabCollection: Ember.computed('_internalCollection.@each.{notice,order}', function () {
      return this.get('_internalCollection');
    }),
    didResize() {
      this._resize();
    },
    init() {
      this._super(...arguments);
      if (this.get('app.region') === this.get('constants.region.nz')) {
        this.set('tabHeightOffset', 43);
      }
      this._internalCollection = []; // array of tab-model object
      this._tabNotifications = [];
      this.get('event').on(this.get('constants.events.addNewTab'), this, '_addNewTab');
      this.get('event').on(this.get('constants.events.newTabNotification'), this, '_addTabNotification');
      this.get('event').on(this.get('constants.events.dismissTabNotification'), this, '_dismissTabNotification');
      this.get('event').on(this.get('constants.events.restoreTabs'), this, '_restoreTabs');
      this.get('event').on(this.get('constants.events.connectionStatusChanged'), this, '_connectionStatusChanged');
      if ((0, _lodash.isNil)(this.get('_notificationTimeoutJobId'))) {
        this.set('_notificationTimeoutJobId', (0, _emberLifeline.pollTask)(this, '_checkNotificationTimeout'));
      }
    },
    didInsertElement() {
      this._super(...arguments);
      const tabDimension = this.element.getBoundingClientRect();
      const widthOffset = window.innerWidth - tabDimension.width + 5;
      this.set('tabWidthOffset', widthOffset);
      this._resize();
    },
    willDestroyElement() {
      if (Ember.isPresent(this.get('_notificationTimeoutJobId'))) {
        (0, _emberLifeline.cancelPoll)(this, this.get('_notificationTimeoutJobId'));
        this.set('_notificationTimeoutJobId', null);
      }
      this.get('event').off(this.get('constants.events.addNewTab'), this, '_addNewTab');
      this.get('event').off(this.get('constants.events.newTabNotification'), this, '_addTabNotification');
      this.get('event').off(this.get('constants.events.dismissTabNotification'), this, '_dismissTabNotification');
      this.get('event').off(this.get('constants.events.restoreTabs'), this, '_restoreTabs');
      this.get('event').off(this.get('constants.events.connectionStatusChanged'), this, '_connectionStatusChanged');
      this._super(...arguments);
    },
    _addTabNotification(notification) {
      const notifications = this.get('_tabNotifications');
      if (notification.timeout > 0) {
        notification.set('timeStamp', this.now());
      }
      if (notification.get('isGlobal') === true) {
        if (!(0, _lodash.some)(notifications, {
          'message': notification.message
        })) {
          notifications.pushObject(notification);
        }
      } else {
        if (!(0, _lodash.some)(notifications, {
          'message': notification.message,
          'tabId': notification.tabId
        })) {
          notifications.pushObject(notification);
        }
      }
    },
    _dismissTabNotification(notification) {
      const notifications = this.get('_tabNotifications');
      if (notification.get('isGlobal')) {
        const toRemove = notifications.findBy('message', notification.message);
        if (Ember.isPresent(toRemove)) {
          notifications.removeObject(toRemove);
        }
      } else {
        const toRemove = notifications.filter(item => {
          return item.messageId === notification.messageId || item.tabId === notification.tabId && item.message === notification.message;
        });
        if (Ember.isPresent(toRemove)) {
          notifications.removeObjects(toRemove);
        }
      }
    },
    _checkNotificationTimeout() {
      const notifications = this.get('_tabNotifications');
      const toRemove = notifications.filter(item => {
        return this.timeDiffAsSeconds(item.timeStamp) > item.timeout;
      });
      if (Ember.isPresent(toRemove)) {
        notifications.removeObjects(toRemove);
      }
      (0, _emberLifeline.runTask)(this, '_checkNotificationTimeout', 2000); // check for banner time out every 2 seconds
    },

    _resize() {
      const tabDimension = this.element.getBoundingClientRect();
      const newWidth = Math.floor(window.innerWidth - this.get('tabWidthOffset'));
      const newHeight = Math.floor(window.innerHeight - tabDimension.top - this.get('tabHeightOffset'));
      this.get('logger').logDebug(`Resize tab window height : ${window.innerHeight} | window width : ${window.innerWidth} | tab height : ${newHeight} | tab width : ${newWidth}`);
      this.setProperties({
        tabHeight: newHeight,
        tabWidth: newWidth
      });
      this.get('app').setProperties({
        tabContainerHeight: newHeight,
        tabContainerWidth: newWidth
      });
      this.get('event').trigger(this.get('constants.events.tabResize'));
    },
    actions: {
      /**
      * @param theTabToRemove (myTab) : close current tab, it is passed via dynamic tab.
      * @param theTabToAdd : close current tab and add "theTabToAdd"
       * @param {boolean} removeOtherTab {string} otherTabModel: keep current tab and close otherTabModel
      *  */
      _removeTab: function _removeTab(theTabToRemove, theTabToAdd, removeOtherTab, otherTabModel) {
        let switchTab = true;
        if (removeOtherTab === true) {
          this.set('theTabToRemove', (0, _lodash.find)(this.get('_internalCollection'), {
            name: otherTabModel.name,
            p0: otherTabModel.p0,
            p1: otherTabModel.p1
          }));
          switchTab = false;
        } else {
          this.set('theTabToRemove', theTabToRemove);
        }
        if (Ember.isPresent(this.get('theTabToRemove'))) {
          if (Ember.isPresent(this.get('theTabToRemove').innerPage) && this.get('theTabToRemove').innerPage.isDirty) {
            this._switchToTab(this.get('theTabToRemove').tabId);
            this.set('_dirtyMessage', this.get('theTabToRemove').innerPage.dirtyMessage);
            this.get('confirmDlg').show();
          } else {
            this.send('_onConfirmClose', switchTab);
          }
        }
        //check the type to differentiate between tab name and click event object
        if (Ember.typeOf(theTabToAdd) === 'string' && !(0, _lodash.isEmpty)(theTabToAdd)) {
          this.addTab(theTabToAdd);
        }
      },
      _onConfirmClose: function _onConfirmClose(switchTab) {
        (0, _emberLifeline.scheduleTask)(this, 'render', '_destroyTab', this.get('theTabToRemove'), switchTab);
      },
      _tabChanged: function _tabChanged(newId) {
        this._switchToTab(newId);
      },
      _dismissNotification: function _dismissNotification(messageId) {
        const notifications = this.get('_tabNotifications');
        const toRemove = notifications.findBy('messageId', messageId);
        if (Ember.isPresent(toRemove)) {
          notifications.removeObject(toRemove);
        }
      },
      _dragEndAction() {
        // this.get('logger').logDebug('_tabCollection: ', this.get('_tabCollection'));
        this._setTabHistoryCache(this.get('_tabCollection'));
      }
    },
    _addNewTab: function _addNewTab(newTab) {
      let existingTab = null;
      const internalCollection = this.get('_internalCollection');
      if (Ember.isPresent(internalCollection)) {
        existingTab = this.get('_internalCollection').find(existingTab => {
          return existingTab.get('componentName') === newTab.get('componentName') && ((0, _lodash.isNil)(existingTab.get('p0')) && (0, _lodash.isNil)(newTab.get('p0')) || existingTab.get('p0') === newTab.get('p0')) && ((0, _lodash.isNil)(existingTab.get('p1')) && (0, _lodash.isNil)(newTab.get('p1')) || existingTab.get('p1') === newTab.get('p1'));
        }, this);
      }
      if (Ember.isPresent(existingTab)) {
        existingTab.set('options', newTab.get('options'));
        this._switchToTab(existingTab.get('tabId'), true);
      } else {
        if (internalCollection.length === 0 && this.get('_isConnectionEstablished') !== true) {
          //  this._showConnectionWaitDlg();
        }
        internalCollection.pushObject(newTab);
        this.get('app').addScreen(newTab);
        this._switchToTab(newTab.get('tabId'), true, true);
      }
      this.set('newTab', null);
      if (newTab.name !== 'homeAu' && newTab.name !== 'homeNz' && ((0, _lodash.isEmpty)(newTab.options) || (0, _lodash.isNil)(newTab.options.isInitialTab) || !newTab.options.isInitialTab)) {
        this._setTabHistoryCache(this.get('_tabCollection'));
      }
    },
    _showConnectionWaitDlg() {
      const modal = this.get('_tabRestorationModal');
      if (modal.get('isShowing') === false) {
        modal.set('message', 'Establishing server connection… Please wait');
        modal.set('bypassMessage', 'IMPORTANT <br>By clicking the [Proceed] button, you will be able to access to the DH3 application.' + '<br><br>Please note that due to connectivity problems the functionality will be severely affected, including, but not limited to: chat, whiteboard, video chat, notifications, and it may also affect the ability for Clients to log in and use the external website.' + '<br><br>You should only proceed if access is absolutely required, and you should immediately report this issue to the IT Support team.');
        modal.show();
      }
      if (this.get('_isConnectionEstablished') === true) {
        if (this.get('_pendingRestoreTab') !== true) {
          modal.hide();
        }
      } else {
        (0, _emberLifeline.runTask)(this, '_showConnectionWaitDlg', 50);
      }
    },
    _switchToTab(tabId, optionsChanged = false, isNewTab = false) {
      this.set('activeTabId', tabId);
      this.get('app').switchScreen(tabId);
      if (isNewTab === false) {
        (0, _emberLifeline.runTask)(this, () => {
          const tabCollection = this.get('_internalCollection');
          const theTab = tabCollection.findBy('tabId', tabId);
          if (Ember.isPresent(theTab) && Ember.isPresent(theTab.tabComponentInstance)) {
            theTab.get('tabComponentInstance').onScreenChange(optionsChanged, theTab.get('options'));
          }
        });
      }
    },
    _destroyTab(theTab, switchTab = true) {
      const tabCollection = this.get('_internalCollection');
      if (switchTab && tabCollection.length > 1) {
        const removeTabIndex = tabCollection.indexOf(theTab);
        if (removeTabIndex > -1) {
          if (removeTabIndex === 0) {
            this._switchToTab(tabCollection.objectAt(1).get('tabId'));
          } else {
            this._switchToTab(tabCollection.objectAt(removeTabIndex - 1).get('tabId'));
          }
        }
      }
      this.get('app').removeScreen(theTab.get('tabId'));
      theTab.set('tabComponentInstance', null);
      tabCollection.removeObject(theTab);
      this.set('theTabToRemove', null);
      this._setTabHistoryCache(this.get('_tabCollection'));
    },
    _addRestoredTabs: function _addRestoredTabs(newTabArg) {
      const newTab = newTabArg;
      let existingTab = null;
      if (Ember.isPresent(this.get('_internalCollection'))) {
        existingTab = this.get('_internalCollection').find(tab => {
          return tab.get('componentName') === newTab.get('componentName') && ((0, _lodash.isEmpty)(tab.get('p0')) && (0, _lodash.isEmpty)(newTab.get('p0')) || tab.get('p0') === newTab.get('p0')) && ((0, _lodash.isEmpty)(tab.get('p1')) && (0, _lodash.isEmpty)(newTab.get('p1')) || tab.get('p1') === newTab.get('p1'));
        }, this);
      }
      if (!Ember.isPresent(existingTab)) {
        this.get('_internalCollection').pushObject(newTab);
        this.get('app').addScreen(newTab);
        this._switchToTab(newTab.get('tabId'));
      }
      this.set('newTab', null);
    },
    _restoreTabs() {
      const cache = this.get('cache');
      if (Ember.isPresent(cache.getTabHistory('tabHistory'))) {
        this.set('_tabHistory', JSON.parse(cache.getTabHistory('tabHistory')));
        if (this.get('_tabHistory').length > 1) {
          this.set('_pendingRestoreTab', true);
          const modal = this.get('_tabRestorationModal');
          if (this.get('_isConnectionEstablished') === true) {
            modal.set('message', 'Restoring internal tabs…');
            if (modal.get('isShowing') === false) {
              modal.show();
            }
            this._restoreTabHistory(0, this.get('_tabHistory'));
            this.set('_pendingRestoreTab', false);
          } else {
            // runTask(this, '_restoreTabs', 200);
          }
        }
      }
    },
    _restoreTabHistory(counter, tabHistory) {
      if (!(counter++ >= tabHistory.length)) {
        const tab = tabHistory[counter - 1];
        let timeOut = 100;
        if (counter > 1 && (tabHistory[counter - 2].name === 'clientView' || tabHistory[counter - 2].name === 'taskView' || tabHistory[counter - 2].name === 'sessionView')) {
          timeOut = 1500;
        }
        (0, _emberLifeline.runTask)(this, () => {
          const newTabModel = this.createModel('tab-model');
          const pagePath = this.get(`constants.pagePath.${tab.name}`);
          const tabType = this.get(`constants.pageTabType.${tab.name}`);
          const tabIcon = this.get(`constants.pageTabIcon.${tab.name}`) || null;
          newTabModel.setProperties({
            name: tab.name,
            title: this.get(`constants.pageTitle.${tab.name}`),
            tabId: tab.uuid,
            componentName: pagePath,
            p0: tab.p0,
            p1: tab.p1,
            tabType: tabType,
            showIcon: Ember.isPresent(tabType) || Ember.isPresent(tabIcon),
            iconName: tabIcon,
            showClose: tab.name,
            options: tab.options
          });
          this._addRestoredTabs(newTabModel);
          this._restoreTabHistory(counter, tabHistory);
        }, timeOut);
      } else {
        this.get('_tabRestorationModal').hide();
      }
    },
    _setTabHistoryCache(tabs) {
      //this.get('logger').logDebug('tabHistory', this.get('tabs'), "to", tabs);
      let storedTabs = [];
      (0, _lodash.forEach)(tabs, function (value) {
        storedTabs.pushObject({
          name: value.get('name').length > 0 ? value.get('name') : value.get('title'),
          type: value.tabType,
          uuid: value.tabId,
          p0: value.p0,
          p1: value.p1,
          options: value.options
        });
      });
      this.get('cache').putTabHistory('tabHistory', JSON.stringify(storedTabs));
    },
    _connectionStatusChanged(connectionStatus) {
      const constants = this.get('constants');
      this.set('_isConnectionEstablished', connectionStatus === constants.connectionStatus.good);
    }
  });
  _exports.default = _default;
});