define("esis-ui/models/individual-search-criteria", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelBase.Model.extend({
    guid: (0, _modelBase.attr)(),
    givenName1: (0, _modelBase.attr)(),
    familyName1: (0, _modelBase.attr)(),
    dob1: (0, _modelBase.attr)(),
    individual1Selected: (0, _modelBase.attr)(),
    givenName2: (0, _modelBase.attr)(),
    familyName2: (0, _modelBase.attr)(),
    dob2: (0, _modelBase.attr)(),
    individual2Selected: (0, _modelBase.attr)(),
    givenName3: (0, _modelBase.attr)(),
    familyName3: (0, _modelBase.attr)(),
    dob3: (0, _modelBase.attr)(),
    individual3Selected: (0, _modelBase.attr)(),
    abn: (0, _modelBase.attr)(),
    abnSelected: (0, _modelBase.attr)(),
    isOrganisationSearch: false,
    criteriaSelected: (0, _modelBase.attr)(),
    individual1FullFilled: (0, _modelBase.attr)(),
    individual2FullFilled: (0, _modelBase.attr)(),
    individual3FullFilled: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.validations = {
        criteriaSelected: {
          custom: {
            validation: function validation(key, value, model) {
              if ((Ember.isPresent(model.get('givenName1')) || Ember.isPresent(model.get('familyName1')) || Ember.isPresent(model.get('dob1'))) && !model.get('individual1Selected')) {
                return false;
              }
              if (model.get('individual1Selected') || model.get('individual2Selected') || model.get('individual3Selected') || model.get('abnSelected')) {
                return true;
              } else {
                return false;
              }
            },
            message: 'At least one criteria should be selected'
          }
        },
        individual1FullFilled: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isPresent(model.get('givenName1')) || Ember.isPresent(model.get('familyName1')) || Ember.isPresent(model.get('dob1'))) {
                return Ember.isPresent(model.get('givenName1')) && Ember.isPresent(model.get('familyName1')) && Ember.isPresent(model.get('dob1'));
              }
              return true;
            },
            message: 'All fields need to be filled'
          }
        },
        individual2FullFilled: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isPresent(model.get('givenName2')) || Ember.isPresent(model.get('familyName2')) || Ember.isPresent(model.get('dob2'))) {
                return Ember.isPresent(model.get('givenName2')) && Ember.isPresent(model.get('familyName2')) && Ember.isPresent(model.get('dob2'));
              }
              return true;
            },
            message: 'All fields need to be filled'
          }
        },
        individual3FullFilled: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isPresent(model.get('givenName3')) || Ember.isPresent(model.get('familyName3')) || Ember.isPresent(model.get('dob3'))) {
                return Ember.isPresent(model.get('givenName3')) && Ember.isPresent(model.get('familyName3')) && Ember.isPresent(model.get('dob3'));
              }
              return true;
            },
            message: 'All fields need to be filled'
          }
        }
      };
    }
  });
  _exports.default = _default;
});