define("esis-ui/components/areas/spg/nz/list/nz-import-spg-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Import Secured Party Group',
    _importSpgRequest: null,
    _entities: null,
    _selectedBusinessEntity: null,
    _selectedBusinessEntityGuid: null,
    onConfirm() {},
    _reset() {
      this.setProperties({
        _requestModel: null
      });
    },
    show(selectedBusinessEntityGuid) {
      this._reset();
      this.set('_requestModel', this.createModel('nz-import-spg'));
      if ((0, _lodash.isNil)(selectedBusinessEntityGuid)) {
        this.set('_selectedBusinessEntityGuid', this.get('credential.currentBusinessEntityGuid'));
      } else {
        this.set('_selectedBusinessEntityGuid', selectedBusinessEntityGuid);
      }
      this.get('_loadPicklist').perform();
      this.get('_dialog').show();
    },
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        const businessEntityList = yield this.get('systemRepo.loadBusinessEntityPicklist').linked().perform(this.get('credential.currentRegion'), true, true);
        this.set('_entities', businessEntityList);
        this.set('_selectedBusinessEntity', (0, _lodash.find)(this.get('_entities'), {
          'value': this.get('_selectedBusinessEntityGuid')
        }));
      } catch (error) {
        this.get('logger').logError('nz-import-spg-modal._loadPicklist', error);
      }
    }),
    _importSpg: (0, _emberConcurrency.task)(function* () {
      try {
        this.get('_waitingModal').show();
        const request = this.get('_requestModel');
        request.set('esisEntityGuid', this.get('_selectedBusinessEntityGuid'));
        yield this.get('spgRepo.importNzSpg').linked().perform(request);
        this.notifySuccess(`SPG ${this.get('_requestModel.spgId')} has been imported successfully`);
        this.get('_dialog').hide();
        this.get('onConfirm')();
      } catch (e) {
        const severity = this.get('constants.backendErrorSeverity');
        if (e.errorSeverity === severity.error || e.errorSeverity === severity.warning) {
          this.set('_requestModel.displayException', true);
          this.set('_requestModel.validatErrorMessage', e.errorMessage);
        } else {
          this.get('logger').logError('nz-import-spg-modal._importSpg', e);
        }
      } finally {
        this.get('_waitingModal').hide();
      }
    }),
    actions: {
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedBusinessEntity', businessEntity);
        this.set('_selectedBusinessEntityGuid', businessEntity.value);
      },
      _onConfirm() {
        if (this.get('_requestModel').validate()) {
          this._importSpg.perform();
        }
      }
    }
  });
  _exports.default = _default;
});