define("esis-ui/components/areas/home/home-page-base", ["exports", "esis-ui/components/areas/shared/page-base", "lodash", "ember-concurrency", "ember-lifeline"], function (_exports, _pageBase, _lodash, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    loadData: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('credential.currentBusinessEntityGuid'))) {
          yield this._updateLastAccessEntity.perform();
          const result = yield this._loadStatistics.perform();
          if (!(0, _lodash.isEmpty)(result)) {
            this.set('_regStatistics', result);
            this.loadChart(result);
          }
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this.loadData.perform();
          }, 100);
        }
      } catch (e) {
        this.get('logger').logError('home-page-base.loadData', e);
      }
    }),
    _updateLastAccessEntity: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('homeRepo.updateLastAccessEntity').linked().perform({
          regionId: this.get('credential.currentRegion'),
          BusinessEntityGuid: this.get('credential.currentBusinessEntityGuid')
        });
      } catch (e) {
        this.get('logger').logError('home-page-base._updateLastAccessEntity', e);
      }
    }),
    loadChart(data) {
      let bgColor = this.get('app.region') === this.get('constants.region.au') ? '#008cba' : '#981E32';
      let chartData = [data.dueToExpireInOneWeekCount, data.dueToExpireInOneMonthCount, data.dueToExpireInThreeMonthCount, data.dueToExpireInSixMonthCount];
      let maxChartData = Math.max(...chartData);
      let logBase = 10;
      let chartTicks = [];
      chartTicks.push(0);
      chartTicks.push(logBase);
      let i = 2;
      for (; maxChartData >= Math.pow(logBase, i); i++) {
        chartTicks.push(Math.pow(logBase, i));
      }
      chartTicks.push(Math.pow(logBase, i));
      let maxYaxValue = Math.pow(logBase, i);
      //chart data
      const regChartData = [{
        caption: '1 Week',
        value: data.dueToExpireInOneWeekCount
      }, {
        caption: '1 Month',
        value: data.dueToExpireInOneMonthCount
      }, {
        caption: '3 Month',
        value: data.dueToExpireInThreeMonthCount
      }, {
        caption: '6 Month',
        value: data.dueToExpireInSixMonthCount
      }];
      //reg Chart
      this.set('_regChartData', {
        labels: (0, _lodash.map)(regChartData, 'caption'),
        datasets: [{
          label: '# of Votes',
          data: (0, _lodash.map)(regChartData, 'value'),
          backgroundColor: bgColor
        }]
      });
      this.set('_regChartOptions', {
        title: {
          display: true,
          text: 'Registrations Expiring Within Period'
        },
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            ticks: {
              callback: function callback(value /*index, values*/) {
                // return moment(moment(value).set('minute', 0)).format('H:mm');
                return value;
              }
              // fontSize: 25,
            }
          }],

          yAxes: [{
            // display: true,
            type: 'logarithmic',
            // autoSkip: true,
            ticks: {
              min: 0,
              max: maxYaxValue,
              callback: function callback(value /*, index, values*/) {
                //needed to change the scientific notation results from using logarithmic scale
                return Number(value.toString()); //pass tick values as a string into Number function
              }
            },

            afterBuildTicks: function afterBuildTicks(pckBarChart) {
              pckBarChart.ticks = chartTicks;
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function label(tooltipItem) {
              return tooltipItem.yLabel;
            }
          }
        }
      });
    },
    actions: {
      _chooseBusinessEntity: function _chooseBusinessEntity(businessEntity) {
        const credential = this.get('credential');
        credential.set('currentBusinessEntity', businessEntity);
        this.loadData.perform();
      }
    }
  });
  _exports.default = _default;
});