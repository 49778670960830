define("esis-ui/components/shared/preview/preview-loading/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    loadingPreviewMessage: 'Loading preview information',
    small: true,
    medium: false,
    large: false
  });
  _exports.default = _default;
});