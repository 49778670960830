define("esis-ui/components/areas/admin/business-entity/view/business-entity-list/component", ["exports", "esis-ui/components/areas/shared/view-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _viewPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _viewPageBase.default.extend({
    panelId: 'allBusinessEntitiesPanel',
    _businessEntityTree: null,
    _selectedBusinessEntity: null,
    _selectedPicklistBusinessEntity: null,
    _showInactive: false,
    //region INITIALIZATION ---
    init() {
      this._super(...arguments);
      this.setProperties({
        '_businessEntityDetailPanel': `businessEntityDetailPanel${this.get('app').guid()}`,
        '_businessEntityUserPanel': `businessEntityUserPanel${this.get('app').guid()}`
      });
      const app = this.get('app');
      this._loadPicklistMethod();
      this.set('_businessEntityTreeOption', {
        autoBind: false,
        height: 620,
        pageable: {
          pageSize: 999999999
        },
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('business-entities-grid')
            },
            parameterMap: (options, operation) => {
              options.businessEntityGuid = this.get('_selectedPicklistBusinessEntity.value');
              options.showInactive = this.get('_showInactive');
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              if (!(0, _lodash.isEmpty)(_data)) {
                let realData = _data.value;
                for (let i = 0; i < realData.length; i++) {
                  realData[i].organisationName = realData[i].organisationName + " (" + realData[i].esisId + ")";
                  realData[i].region = realData[i].regionId === this.get('constants.region.nz') ? "NZ" : "AU";
                }
                return realData;
              }
              return _data;
            }
          },
          serverPaging: false,
          serverFiltering: false,
          serverSorting: false
        },
        columns: [{
          field: "organisationName",
          title: "Organisation Name",
          width: "300px",
          filterable: false,
          sortable: false
        }, {
          field: "isHolderEntity",
          title: "Holder Entity",
          width: "80px",
          filterable: false,
          sortable: false,
          template: dataItem => {
            return this.formatBoolean(dataItem.isHolderEntity);
          }
        }, {
          field: "isActive",
          title: "Active",
          width: "50px",
          filterable: false,
          sortable: false,
          template: dataItem => {
            return this.formatBoolean(dataItem.isActive);
          }
        }, {
          field: "entityTypeName",
          title: "Entity Type",
          width: "110px",
          filterable: false,
          sortable: false
        }, {
          field: "region",
          title: "Region",
          width: "50px",
          filterable: false,
          sortable: false
        }]
      });
    },
    //endregion

    //region TASK
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        //BUSINESS ENTITY
        this.set('_selectedPicklistBusinessEntity', yield this.get('_loadBusinessEntityPicklist')._loadPicklists.perform());
        this._refreshTree();
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    _loadModel: (0, _emberConcurrency.task)(function* (selectedBusinessEntity) {
      try {
        let result = yield this.get('adminRepo.loadBusinessEntityModel').perform(selectedBusinessEntity.guid);
        this.set('_detailsModel', result);
      } catch (error) {
        this.get('logger').logError('business-entity-list._loadModel', error);
      }
    }),
    _updateBusinessEntityLinkedUser: (0, _emberConcurrency.task)(function* (requestModel) {
      try {
        yield this.get('adminRepo.updateBusinessEntityLinkedUser').perform(requestModel);
        const constants = this.get('constants');
        this.get('cache').deleteLookup(this.getCacheKey(constants.cacheType.lookupCache, constants.picklistType.systemUserPicklist));
        this.notifySuccess(`Linked system users have been updated successfully`);
        this.get('onRefresh')();
      } catch (error) {
        this.get('logger').logError('business-entity-list._updateBusinessEntityLinkedUser', error);
      }
    }),
    //endregion

    //region METHODS
    loadData() {
      this._refreshTree();
    },
    _loadBusinessEntityUserList() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_businessEntityUserList'))) {
          this.get('_businessEntityUserList').loadListData(this.get('_detailsModel').users);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._loadBusinessEntityUserList();
          }, 20);
        }
      });
    },
    _refreshTree(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_businessEntityTree'))) {
          if (reset === true) {
            this.set('_selectedBusinessEntity', null);
            this.set('_businessEntityPreview', null);
          }
          this.get('_businessEntityTree').refreshTree(reset, this.get('_filterValue'));
          this.set('_refreshNotification', false);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshTree(reset);
          }, 20);
        }
      });
    },
    _loadPicklistMethod() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_loadBusinessEntityPicklist'))) {
          this._loadPicklists.perform();
        } else {
          (0, _emberLifeline.runTask)(this, '_loadPicklistMethod', 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      _onSaveBusinessEntity() {
        this.get('onRefresh')();
      },
      //region FILTER ACTIONS
      _onChooseBusinessEntity(businessEntity) {
        this.set('_selectedPicklistBusinessEntity', businessEntity);
        this._refreshTree(true);
      },
      _onSearch(value) {
        if ((0, _lodash.isEmpty)(value)) {
          value = '';
        }
        this.set('_filterValue', {
          field: "organisationName",
          operator: "contains",
          value: value
        });
        this._refreshTree(true);
      },
      //endregion
      _onSelectBusinessEntity(item) {
        let preSelectedBusinessEntity = this.get('_selectedBusinessEntity');
        if (!(0, _lodash.isEmpty)(item)) {
          this.set('_selectedBusinessEntity', item);
          this.get('_loadModel').perform(item);
        } else if (!(0, _lodash.isEmpty)(preSelectedBusinessEntity)) {
          this.get('_loadModel').perform(preSelectedBusinessEntity);
        }
      },
      _onEditClick() {
        this.get('_businessEntityEditModal').show(this.get('_detailsModel'));
      },
      _onRemoveUserClick(selectedUser) {
        this.set('_userToRemove', selectedUser);
        this.set('_deleteLinkedUserConfirmMsg', "Are you sure you want to remove this user’s access to this Business Entity?");
        this.get('_linkedUserDeleteDlg').show();
      },
      _onConfirmRemoveEntity() {
        this.get('_updateBusinessEntityLinkedUser').perform({
          businessEntityGuid: this.get('_detailsModel.guid'),
          systemUserGuid: this.get('_userToRemove.guid'),
          isAdding: false,
          recordVersion: this.get('_detailsModel.recordVersion')
        });
      },
      _onAddUserClick() {
        this.get('_businessEntityAddUserModal').show();
      },
      _onSaveAddedUser(model) {
        this.get('_updateBusinessEntityLinkedUser').perform({
          businessEntityGuid: this.get('_detailsModel.guid'),
          systemUserGuid: model.value,
          isAdding: true,
          recordVersion: this.get('_detailsModel.recordVersion')
        });
      },
      _onShowInactiveChange: function _onShowInactiveChange(value) {
        this.set('_showInactive', value);
        this._refreshTree(true);
      }
    }
    //endregion
  });
  _exports.default = _default;
});