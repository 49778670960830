define("esis-ui/components/areas/shared/buttons/au-verify-organization-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jCE++El6",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shared/buttons/action-button\",null,[[\"title\",\"small\",\"smallWidth\",\"isRunning\",\"onClick\",\"type\"],[[24,[\"title\"]],[24,[\"small\"]],[24,[\"smallWidth\"]],[24,[\"_retrieveRegistration\",\"isRunning\"]],[28,\"action\",[[23,0,[]],\"_verify\"],null],\"primary\"]]],false],[0,\"\\n\"],[1,[28,\"areas/registration/au/view/au-company-info-modal\",null,[[\"showCompanyDlgCloseButton\",\"onConfirmVerified\",\"registerAs\",\"renderInPlace\"],[[24,[\"showCompanyDlgCloseButton\"]],[24,[\"onConfirmVerified\"]],[24,[\"_companyInfoModal\"]],false]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/buttons/au-verify-organization-button/template.hbs"
    }
  });
  _exports.default = _default;
});