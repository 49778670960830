define("esis-ui/components/areas/shared/buttons/delete-button/component", ["exports", "esis-ui/components/shared/buttons/button-base"], function (_exports, _buttonBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _buttonBase.default.extend({
    renderInPlace: false,
    title: 'Delete',
    dirtyRecordConfirmMsg: '',
    type: 'danger',
    classNames: ['d-inline-block'],
    actions: {
      click: function click() {
        if (this.get('_confirmDelDlg')) {
          this.get('_confirmDelDlg').show();
        }
      }
    }
  });
  _exports.default = _default;
});