define("esis-ui/models/user-digest", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    id: (0, _modelBase.attr)(),
    guid: (0, _modelBase.attr)(),
    esisId: (0, _modelBase.attr)(),
    email: (0, _modelBase.attr)(),
    userName: (0, _modelBase.attr)(),
    firstName: (0, _modelBase.attr)(),
    lastName: (0, _modelBase.attr)(),
    isActive: (0, _modelBase.attr)(),
    isLockedOut: (0, _modelBase.attr)(),
    isSystemUser: (0, _modelBase.attr)(),
    displayName: (0, _modelBase.attr)(),
    roles: (0, _modelBase.attr)(null, 'array'),
    isDeleted: (0, _modelBase.attr)(),
    position: (0, _modelBase.attr)(),
    failedPasswordAttemptCount: (0, _modelBase.attr)(),
    comment: (0, _modelBase.attr)(),
    dateLastLockout: (0, _modelBase.attr)(),
    dateLastLogin: (0, _modelBase.attr)(),
    userIdCreated: (0, _modelBase.attr)(),
    dateCreated: (0, _modelBase.attr)(),
    userIdEdited: (0, _modelBase.attr)(),
    userCreated: (0, _modelBase.attr)(),
    userEdited: (0, _modelBase.attr)(),
    dateEdited: (0, _modelBase.attr)(),
    recordVersion: (0, _modelBase.attr)(),
    passwordUnencrypted: (0, _modelBase.attr)(),
    confirmedPassword: (0, _modelBase.attr)(),
    passwordError: (0, _modelBase.attr)(),
    hasRole: (0, _modelBase.attr)(),
    hasMorePermission: (0, _modelBase.attr)(),
    permissions: (0, _modelBase.attr)(),
    userNameWhiteSpace: (0, _modelBase.attr)(),
    secretDoesNotExpire: (0, _modelBase.attr)(),
    isCitecUser: (0, _modelBase.attr)(),
    isCitecSearchUser: (0, _modelBase.attr)(),
    defaultRegionId: (0, _modelBase.attr)(),
    isSuperAdmin: (0, _modelBase.attr)(),
    hasAuPermission: (0, _modelBase.attr)(),
    hasNzPermission: (0, _modelBase.attr)(),
    connectionStatusPollingInterval: (0, _modelBase.attr)(),
    agreedToNzTc: (0, _modelBase.attr)(),
    linkedBusinessEntities: (0, _modelBase.attr)(null, 'array'),
    hasBusinessEntity: (0, _modelBase.attr)(),
    lastAccessAuEntityGuid: (0, _modelBase.attr)(),
    lastAccessNzEntityGuid: (0, _modelBase.attr)(),
    nzEfxSingleItemBulkDownloadEnabled: (0, _modelBase.attr)(),
    init() {
      this._super(...arguments);
      this.set('linkedBusinessEntities', []);
      this.validations = {
        hasRole: {
          custom: {
            validation: function validation(key, value, model) {
              const perm = model.get('roles');
              return Ember.isPresent(perm.findBy('selected', true));
            },
            message: 'At least one role should be selected'
          }
        },
        hasBusinessEntity: {
          custom: {
            validation: function validation(key, value, model) {
              return !(0, _lodash.isEmpty)(model.get('linkedBusinessEntities'));
            },
            message: 'At least one business entity should be added'
          }
        },
        userNameWhiteSpace: {
          custom: {
            validation: function validation(key, value, model) {
              if (Ember.isPresent(model.get('userName'))) {
                var patt = /\s/g;
                var str = model.get('userName');
                return (0, _lodash.isEmpty)(str.match(patt));
              }
              return true;
            },
            message: 'User name is not allowed to contain spaces'
          }
        },
        email: {
          custom: {
            validation: function validation(key, value, model) {
              return Ember.isPresent(value) ? model.validateEmailAddress(value) : false;
            },
            message: 'Not a valid email'
          }
        },
        userName: {
          presence: true
        },
        defaultRegionId: {
          presence: true
        },
        passwordUnencrypted: {
          custom: {
            validation: function validation(key, value, model) {
              if ((0, _lodash.isNil)(model.get('id'))) {
                return Ember.isPresent(value);
              }
              return true;
            },
            message: 'can\'t be blank'
          }
        },
        confirmedPassword: {
          custom: {
            validation: function validation(key, value, model) {
              if ((0, _lodash.isNil)(model.get('id'))) {
                return Ember.isEqual(model.get('passwordUnencrypted'), value);
              }
              return true;
            },
            message: 'Passwords do not match'
          }
        }
      };
    }
  });
  _exports.default = _default;
});