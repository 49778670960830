define("esis-ui/components/shared/ember-lookup/component", ["exports", "lodash", "ember-lifeline"], function (_exports, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    //region INITIALISATION
    _dropDown: null,
    _searchText: '',
    renderInPlace: true,
    disabled: false,
    placeholder: '',
    maxlength: '',
    toolTipLabel: '',
    optionComponentName: '',
    minSearchTextLength: 3,
    onSelectOption: function onSelectOption() {},
    onLookUp: function onLookUp() {},
    init() {
      this._super(...arguments);
      this.get('event').on(this.get('constants.events.tabResize'), this, '_resize');
    },
    //endregion

    //region Hook
    willDestroyElement() {
      this._super(...arguments);
      this.get('event').off(this.get('constants.events.tabResize'), this, '_resize');
    },
    //endregion

    //region COMPUTED PROPERTIES
    _hasOptions: Ember.computed('options', function () {
      return !(0, _lodash.isEmpty)(this.get('options'));
    }),
    _hasOptionComponent: Ember.computed('optionComponentName', function () {
      return !(0, _lodash.isEmpty)(this.get('optionComponentName'));
    }),
    _selectedOption: Ember.computed('options', 'searchText', function () {
      if (!(0, _lodash.isEmpty)(this.get('options')) && !(0, _lodash.isNil)(this.get('searchText'))) {
        return (0, _lodash.find)(this.get('options'), {
          value: this.get('searchText')
        });
      }
      return '';
    }),
    _disableLookUp: Ember.computed('searchText', function () {
      return (0, _lodash.isEmpty)(this.get('searchText')) || this.get('searchText').length < this.get('minSearchTextLength');
    }),
    //endregion

    //region TASK
    //endregion

    // region METHOD
    _resize() {
      const lookupWidth = this.$('#lookupContainer').width();
      this.$('#lookupContainer .contentStyle').css("width", lookupWidth + "px");
    },
    //endregion

    //region ACTIONS
    actions: {
      _onOptionChange: function _onOptionChange(selectedOption) {
        let dropDown = this.get('_dropDown');
        if (!(0, _lodash.isEmpty)(dropDown)) {
          dropDown.actions.close();
        }
        this.onSelectOption(selectedOption);
      },
      // eslint-disable-next-line no-unused-vars
      _onListOpened: function _onListOpened(dropdown) {
        this.set('_dropDown', dropdown);
        (0, _emberLifeline.runTask)(this, () => {
          this._resize();
        }, 10);
      },
      _onListClosed: function _onListClosed(dropdown) {
        this.set('_dropDown', dropdown);
      },
      _onLookUp() {
        if (!this.get('_dropDown.isOpen')) {
          this.onLookUp();
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});