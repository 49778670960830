define("esis-ui/models/company-reverify-request", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/company-reverify-request.js
  var _default = _modelBase.Model.extend({
    organisationNumber: (0, _modelBase.attr)(),
    organisationNumberType: (0, _modelBase.attr)()
  });
  _exports.default = _default;
});