define("esis-ui/components/areas/shared/buttons/remove-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sl+U6LAI",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"shared/buttons/action-button\",null,[[\"icon\",\"hasIcon\",\"small\",\"smallWidth\",\"title\",\"disabled\",\"iconOnly\",\"type\",\"isRunning\",\"customClass\",\"onClick\"],[\"times\",[24,[\"hasIcon\"]],[24,[\"small\"]],[24,[\"smallWidth\"]],[24,[\"title\"]],[24,[\"disabled\"]],[24,[\"iconOnly\"]],[24,[\"type\"]],[24,[\"isRunning\"]],[24,[\"customClass\"]],[28,\"action\",[[23,0,[]],\"_onClick\"],null]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/shared/buttons/remove-button/template.hbs"
    }
  });
  _exports.default = _default;
});