define("esis-ui/components/shared/ember-bootstrap/static-tab/tab-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5+3aLTHT",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[4,\"component\",[[24,[\"_innerTabComponentName\"]]],[[\"activeId\",\"onChange\",\"hasButton\",\"withinModal\"],[[24,[\"internalActiveTabId\"]],[28,\"action\",[[23,0,[]],\"tabChanged\"],null],[24,[\"hasButton\"]],[24,[\"withinModal\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[28,\"hash\",null,[[\"uuid\",\"tab\",\"tabModel\",\"activeTabId\",\"screenId\"],[[24,[\"uuid\"]],[23,1,[]],[24,[\"model\"]],[24,[\"internalActiveTabId\"]],[24,[\"screenId\"]]]]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/static-tab/tab-control/template.hbs"
    }
  });
  _exports.default = _default;
});