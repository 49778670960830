define("esis-ui/components/areas/login/forgot-cred-access-key/component", ["exports", "esis-ui/components/areas/shared/page-base", "ember-concurrency"], function (_exports, _pageBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    _successMessage: '',
    _resetCredAccessKey: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('accountRepo.requestCredAccessKey').perform(this.get('_userEmail'));
        this.set('_successMessage', 'Reset email sent. Please check your inbox.');
        this.set('_errorMessage', '');
      } catch (error) {
        this.set('_errorMessage', error.errorMessage);
        this.set('_successMessage', '');
        this.get('logger').logError('forgot-Cred-Access-Key._resetCredAccessKey', error);
      }
    }),
    actions: {
      _resetCredAccessKey() {
        if (Ember.isPresent(this.get('_userEmail')) && this.get('_userEmail').length > 3) {
          this.set('_errorMessage', '');
          this.get('_resetCredAccessKey').perform();
        } else {
          this.set('_errorMessage', ['Email address or username is required']);
        }
      }
    }
  });
  _exports.default = _default;
});