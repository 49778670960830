define("esis-ui/components/areas/admin/security-roles/list/security-role-list/component", ["exports", "esis-ui/components/areas/admin/security-roles/security-role-page-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _securityRolePageBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _securityRolePageBase.default.extend({
    //region INITIALIZATION
    _securityRoleGrid: null,
    _selectedSecurityRole: null,
    init() {
      this._super(...arguments);
      const app = this.get('app');
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });

      //region SECURITY ROLE GRID
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('security-role-list')
            },
            parameterMap: (options, operation) => {
              options._ = app.guid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              return _data.value;
            }
          }
        },
        columns: [{
          field: "roleName",
          title: 'Role Name',
          filterable: false,
          width: "130px",
          template: '<a href="\\#" data-id="#:guid#" class="grid-link-id">#: roleName #</a>'
        }, {
          field: "description",
          title: 'Description',
          filterable: false,
          width: "300px"
        }]
      });
      //endregion
    },

    //endregion

    //region TASK
    _loadPreview: (0, _emberConcurrency.task)(function* () {
      try {
        let preview = yield this.get('adminRepo.loadSecurityRoleDetailDigest').perform(this.get('_selectedSecurityRole.guid'));
        this.set('model', this.convertPermissionToDisplay((0, _lodash.filter)(preview.permissions, 'selected'), 'permissionGroupTitle'));
      } catch (error) {
        this.get('logger').logError('security-role-preview._loadPreview', error);
      }
    }),
    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_securityRoleGrid'))) {
          if (reset === true) {
            this.set('_selectedSecurityRole', null);
          }
          this.get('_securityRoleGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    onScreenChange() {
      this._refreshGrid(true); // soft refresh
    },

    //endregion

    //region ACTIONS
    actions: {
      //-- GRID ACTIONS --
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isEmpty)(this.get('_selectedSecurityRole')) && (0, _lodash.isEmpty)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedSecurityRole', data);
        this._loadPreview.perform();
      },
      _onLinkClick(selectedItem) {
        const guid = selectedItem.data('id');
        this.addTab('securityRoleView', guid);
      },
      //region BUTTON ACTIONS
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _onAddSecurityRole() {
        this.addTab('securityRoleView');
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});