define("esis-ui/components/areas/login/username-password/component", ["exports", "esis-ui/components/areas/login/login-page-base"], function (_exports, _loginPageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _loginPageBase.default.extend({
    username: '',
    credAccessKey: '',
    _loginIsRunning: false,
    init() {
      this._super(...arguments);
      this._countries = [{
        title: 'AU',
        value: '1'
      }, {
        title: 'NZ',
        value: '2'
      }];
      this._selectedCountry = this._countries[0];
      this.country = this._selectedCountry.value;
    },
    submitButtonDisabled: Ember.computed('username', 'credAccessKey', function () {
      // eslint-disable-next-line ember/no-side-effects
      this.set('errorMessage', null);
      return Ember.isBlank(this.get('username')) || Ember.isBlank(this.get('credAccessKey'));
    }),
    actions: {
      authenticate() {
        this.set('_loginIsRunning', true);
        let {
          username,
          credAccessKey
        } = this.getProperties('username', 'credAccessKey');
        this.get('session').authenticate('authenticator:esis-authenticator', username, credAccessKey, ['useraccess', 'offline_access']).catch(reason => {
          this.set('_loginIsRunning', false);
          if (Ember.isPresent(reason)) {
            if (reason.error === 'PasswordExpired') {
              this.get('redirectToResetExpiredPassword')(username);
            } else if (reason.error === 'invalid_grant' || reason.error === 'InvalidUsername') {
              this.set('errorMessage', 'Invalid username or password. Please try again.');
            } else {
              this.set('errorMessage', reason.error);
            }
          } else {
            this.set('errorMessage', 'Failed to login. Unknown Error. Please try again.');
          }
        });
      },
      redirectToForgotPassword() {
        if (Ember.isPresent(this.get('redirectToForgotPassword'))) {
          this.get('redirectToForgotPassword')();
        }
      },
      _onCountryChange: function _onCountryChange(selectedOption) {
        this.set('_selectedCountry', selectedOption);
        this.set('country', selectedOption ? selectedOption.value : null);
      }
    }
  });
  _exports.default = _default;
});