define("esis-ui/components/shared/theme-selection-dialog/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    showDialog: false,
    userLanguage: '',
    init() {
      this._super(...arguments);
      const constants = this.get('constants');
      this._themes = [{
        title: 'Light',
        key: constants.appTheme.lightTheme
      }, {
        title: 'Dark',
        key: constants.appTheme.darkTheme
      }];
    },
    selectedTheme: Ember.computed('theme.currentTheme', function () {
      return this.get('_themes').findBy('key', this.get('theme.currentTheme'));
    }),
    show: function show() {
      this.get('_dialog').show();
    },
    actions: {
      onSelect: function onSelect() {
        //Add to cache for the moment
        this.get('theme').setTheme(this.get('selectedTheme.key'));
        this.get('_dialog').hide();
        // this.get('cache').putSetting('preferredTheme', this.get('selectedTheme.key'));
      },

      onCancel: function onCancel() {},
      chooseTheme: function chooseTheme(userTheme) {
        this.set('selectedTheme', userTheme);
      },
      refresh: function refresh() {
        this.get('logger').logInfo('Refreshing theme');
        //this.get('translation').refreshTranslation();
      }
    }
  });
  _exports.default = _default;
});