define("esis-ui/components/areas/registration/nz/list/nz-registration-download-progress-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _downloadCoolDownList: null,
    _cancelCoolDownList: null,
    show() {
      this.set('_downloadCoolDownList', []);
      this.set('_cancelCoolDownList', []);
      this.get('_dialog').show();
    },
    _downloadNzFsFile: (0, _emberConcurrency.task)(function* (requestGuid) {
      try {
        this.get('_downloadCoolDownList').pushObject(requestGuid);
        yield this.get('fileRepo.downloadNzFsFile').linked().perform(requestGuid);
      } catch (error) {
        this.get('logger').logError('nz-registration-download-progress-modal._downloadNzFsFile', error);
      } finally {
        (0, _emberLifeline.runTask)(this, () => {
          this.get('_downloadCoolDownList').removeObject(requestGuid);
        }, 5000);
      }
    }),
    _cancelDownloadNzFs: (0, _emberConcurrency.task)(function* (requestGuid) {
      try {
        this.get('_cancelCoolDownList').pushObject(requestGuid);
        yield this.get('fileRepo.cancelDownloadNzFs').linked().perform(requestGuid);
        this.get('refreshProgressDigest')();
      } catch (error) {
        this.get('logger').logError('nz-registration-download-progress-modal._cancelDownloadNzFs', error);
      } finally {
        this.get('_cancelCoolDownList').removeObject(requestGuid);
      }
    }),
    actions: {
      _onDownloadClick(requestGuid) {
        if (!(0, _lodash.isNil)(requestGuid)) {
          this.get('_downloadNzFsFile').perform(requestGuid);
        }
      },
      _onCancelDownloadClick(requestGuid) {
        if (!(0, _lodash.isNil)(requestGuid)) {
          this.set('_cancelItemGuid', requestGuid);
          this.get("_cancelDownload").show();
        }
      },
      _onConfirmCancelDownload() {
        if (!(0, _lodash.isNil)(this.get('_cancelItemGuid'))) {
          this.get('_cancelDownloadNzFs').perform(this.get('_cancelItemGuid'));
        }
      }
    }
  });
  _exports.default = _default;
});