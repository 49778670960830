define("esis-ui/helpers/lowercase-string", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.lowercaseString = lowercaseString;
  function lowercaseString([string]) {
    if (Ember.isBlank(string)) {
      return '';
    }
    return Ember.String.decamelize(string);
  }
  var _default = Ember.Helper.helper(lowercaseString);
  _exports.default = _default;
});