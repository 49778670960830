define("esis-ui/models/grantor-search-result-filter-digest", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // app/models/grantor-search-result-filter-digest.js
  var _default = _requestBase.default.extend({
    collateralClassTypes: (0, _modelBase.attr)(),
    collateralTypes: (0, _modelBase.attr)()
  });
  _exports.default = _default;
});