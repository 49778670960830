define("esis-ui/components/shared/copy-text/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: 'span',
    prefix: 'fas',
    _visibleTooltip: false,
    actions: {
      _copy() {
        this.set('_visibleTooltip', false);
        let plainText = this.get('copyValue');

        //Maryam: I will review this part and try ro improve it by regular expression
        if (!(0, _lodash.isEmpty)(plainText)) {
          plainText = plainText.replace(/<br>/g, '<br>\n');
          plainText = plainText.replace(/<br\/>/g, '<br/>\n');
          plainText = plainText.replace(/<li>/g, '<li>\n');
          plainText = plainText.replace(/<\/ul>/g, '</ul>\n');
          plainText = plainText.replace(/<\/ol>/g, '</ol>\n');
          plainText = plainText.replace(/<\/p>/g, '</p>\n');
          plainText = plainText.replace(/<\/div>/g, '</div>\n');
          plainText = plainText.replace(/<\/div>\n<ul><li>\n/g, '</div><ul><li>\n');
          plainText = plainText.replace(/<\/div>\n<\/li><li>\n/g, '</div></li><li>\n');
          plainText = plainText.replace(/<\/div>\n<\/li><\/ul>\n/g, '</div></li></ul>\n');
          plainText = '<div>' + plainText + '</div>';
          plainText = this.$(plainText).text();
        }
        const self = this;
        function listener(e) {
          e.clipboardData.setData("text/html", self.get('copyValue'));
          e.clipboardData.setData("text/plain", plainText);
          e.preventDefault();
        }
        document.addEventListener("copy", listener);
        let ok = document.execCommand("copy");
        document.removeEventListener("copy", listener);
        if (ok) {
          this.set('_visibleTooltip', true);
          if (!(0, _lodash.isEmpty)(this.get('onCopy'))) {
            this.get('onCopy')();
          } else {
            (0, _emberLifeline.runTask)(this, () => {
              this.set('_visibleTooltip', false);
            }, 2000);
          }
        }
      }
    }
  });
  _exports.default = _default;
});