define("esis-ui/components/areas/search/au/list/preview/au-search-preview/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region Preview Tab Ids
    _criteriaPanel: 'criteriaPanel',
    _resultPanel: 'resultPanel',
    _exceptionPanel: 'exceptionPanel',
    openDetailTab: function openDetailTab() {},
    //endregion

    //region COMPUTED PROPERTIES

    _displayException: Ember.computed('detailDigest.status', function () {
      const constants = this.get('constants');
      return this.get('detailDigest.status') === constants.statusValue.rejected || this.get('detailDigest.status') === constants.statusValue.error;
    }),
    _statusMessage: Ember.computed('detailDigest.statusMessage', function () {
      let statusMessage = String(this.get('detailDigest.statusMessage')).split(/[|]/g);
      for (let i = 0; i < statusMessage.length; i++) {
        statusMessage[i] = statusMessage[i].trim();
      }
      return statusMessage;
    }),
    //endregion

    //region TASK

    //endregion

    actions: {
      _openDetailTab: function _openDetailTab(guid) {
        this.openDetailTab(guid);
      }
    }
  });
  _exports.default = _default;
});