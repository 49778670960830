define("esis-ui/components/areas/admin/business-entity/view/business-entity-add-user-modal/component", ["exports", "esis-ui/components/areas/admin/admin-page-base", "lodash", "ember-concurrency"], function (_exports, _adminPageBase, _lodash, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _adminPageBase.default.extend({
    //region INIT
    modalTitle: 'Add System User',
    model: null,
    //endregion

    //region TASKS
    _loadSystemUsers: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        const systemUserList = yield this.get('picklistRepo.loadPicklistGuid').perform(constants.picklistType.systemUserPicklist);
        this.set('_systemUsers', systemUserList);
      } catch (error) {
        this.get('logger').logError('Failed to load system users', error);
        this.notifyError('Failed to load system users');
      }
    }),
    //endregion

    //region METHODS
    show: function show() {
      this._loadSystemUsers.perform();
      this.get('_dialog').show();
    },
    //endregion

    //region ACTIONS
    actions: {
      _onChooseSystemUser(systemUser) {
        this.set('_selectedSystemUser', systemUser);
      },
      _onConfirm() {
        if (!(0, _lodash.isEmpty)(this.get('onSave'))) {
          this.get('onSave')(this.get('_selectedSystemUser'));
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});