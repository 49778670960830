define("esis-ui/components/shared/froala/html-editor/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "esis-ui/utils/froala-base-options", "jquery"], function (_exports, _sharedComponentBase, _lodash, _froalaBaseOptions, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    options: null,
    disabled: false,
    _defaultOptions: _froalaBaseOptions.default,
    _editor: null,
    onSaveMyChange: function onSaveMyChange() {},
    init() {
      this._super(...arguments);
      /* List all custom buttons here, then we can easily call closure action in callbacks.
       (we should go with event, method/function not working in callbacks)
       */
      _jquery.default.FroalaEditor.DefineIcon('save', {
        NAME: 'save'
      });
      _jquery.default.FroalaEditor.RegisterCommand('save', {
        title: 'Save',
        focus: false,
        // undo: false,
        refreshAfterCallback: false,
        callback: () => {
          this._saveMyChange();
        }
      });
      _jquery.default.FroalaEditor.DefineIcon('video', {
        NAME: 'video'
      });
      _jquery.default.FroalaEditor.RegisterCommand('video', {
        title: 'Video',
        focus: false,
        // undo: false,
        refreshAfterCallback: false,
        callback: () => {
          this._onClickVideo();
        }
      });
    },
    didInsertElement() {
      this._super(...arguments);
      this.set('_editor', this.$('div.froala-editor-instance'));
    },
    //region COMPUTED PROPERTIES
    _beDisabled: Ember.computed('disabled', '_editor', function () {
      if (!(0, _lodash.isNil)(this.get('_editor'))) {
        this.disableEditor(this.get('disabled'));
      }
      return '';
    }),
    //endregion
    //region METHODS
    disableEditor(value) {
      if (value) {
        this.clearEditor();
        this.get('_editor').froalaEditor('edit.off');
      } else {
        this.get('_editor').froalaEditor('edit.on');
      }
    },
    _saveMyChange() {
      this.get('onSaveMyChange')();
    },
    _onClickVideo() {
      this.get('onClickVideo')();
    },
    clearEditor() {
      this.get('_editor').froalaEditor('html.set', '');
    },
    insertHtml(html) {
      this.get('_editor').froalaEditor('html.insert', html);
    },
    /* Froala usually keeps the cursor position, but if we click inside any other input field it will lose the position of cursor.
    Using these two methods will help to overcome the problem.
    Use saveCursorPosition to save the position of cursor.
    Use restoreCursorPosition to restore the position(i.e. before calling insertHtml).
     */
    saveCursorPosition() {
      this.get('_editor').froalaEditor('selection.save');
    },
    restoreCursorPosition() {
      this.get('_editor').froalaEditor('selection.restore');
    },
    //endregion

    actions: {
      _onUpdate(content) {
        if (!(0, _lodash.isEmpty)(this.get('onUpdate'))) {
          this.get('onUpdate')(content);
        }
      },
      _onBlur(html /*component*/) {
        if (!(0, _lodash.isEmpty)(this.get('onBlur'))) {
          this.get('onBlur')(html);
        }
      },
      _onInput( /*component*/
      ) {
        if (!(0, _lodash.isEmpty)(this.get('onInput'))) {
          this.get('onInput')();
        }
      },
      _onContentChanged( /*component*/
      ) {
        if (!(0, _lodash.isEmpty)(this.get('onContentChanged'))) {
          this.get('onContentChanged')();
        }
      }
    }
  });
  _exports.default = _default;
});