define("esis-ui/components/areas/admin/security-roles/list/security-role-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "98cFggMb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"my-2\"],[8],[0,\"\\n  \"],[1,[28,\"areas/shared/buttons/refresh-button\",null,[[\"onClick\"],[[28,\"action\",[[23,0,[]],\"_onRefreshData\"],null]]]],false],[0,\"\\n  \"],[1,[28,\"shared/buttons/action-button\",null,[[\"onClick\",\"title\"],[[28,\"action\",[[23,0,[]],\"_onAddSecurityRole\"],null],\"Add Security Role\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"shared/splitter-control\",null,[[\"panels\",\"options\"],[[24,[\"_splitterPanels\"]],[24,[\"_splitterOptions\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"id\",\"topPanel\"],[8],[0,\"\\n    \"],[1,[28,\"shared/kendo/grid/grid-remote-ds\",null,[[\"registerAs\",\"screenId\",\"options\",\"selectFirstRowIfNoSelectedFound\",\"onSelectionChange\",\"onLinkClick\"],[[24,[\"_securityRoleGrid\"]],[24,[\"tabModel\",\"tabId\"]],[24,[\"_gridOption\"]],true,[28,\"action\",[[23,0,[]],\"_onItemSelectionChange\"],null],[28,\"action\",[[23,0,[]],\"_onLinkClick\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"bottomPanel\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"_selectedSecurityRole\"]]],null]],null,{\"statements\":[[0,\"      \"],[1,[22,\"shared/preview/preview-no-data\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"_loadPreview\",\"isRunning\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"shared/preview/preview-loading\",null,[[\"loadingPreviewMessage\"],[\"Loading preview, please wait...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"areas/admin/security-roles/list/preview/security-role-preview\",null,[[\"model\",\"screenId\"],[[24,[\"model\"]],[24,[\"tabModel\",\"tabId\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/admin/security-roles/list/security-role-list/template.hbs"
    }
  });
  _exports.default = _default;
});