define("esis-ui/components/areas/registration/nz/view/nz-fs-view-collateral-display/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALISATION
    isAmendMode: false,
    _filteredCollateralTypePicklist: null,
    init() {
      this._super(...arguments);
      this._loadPicklists.perform();
    },
    onClickAddCollType() {},
    onClickRemoveColl() {},
    onClickEditColl() {},
    onClickRemoveCollItem() {},
    onClickEditCollItem() {},
    onClickAddCollItem() {},
    //endregion

    //region COMPUTED PROPERTIES

    //endregion

    //region Methods
    _filterSearchByPicklist() {
      this.set('_filteredSearchByPicklist', (0, _lodash.filter)(this.get('_searchByPicklist'), item => {
        return (0, _lodash.includes)(item.collateralTypes, this.get('_selectedCollateralType.value'));
      }));
      this.set('_selectedSearchBy', this.get('_filteredSearchByPicklist') ? this.get('_filteredSearchByPicklist')[0] : null);
      this.set('collateralFilters.searchBy', this.get('_selectedSearchBy') ? this.get('_selectedSearchBy.value') : null);
    },
    filterCollateralTypePicklist(check = false, collateral) {
      if (!check || check && (0, _lodash.isEmpty)((0, _lodash.find)(this.get('_filteredCollateralTypePicklist'), {
        value: collateral.collateralType
      }))) {
        this.set('_filteredCollateralTypePicklist', (0, _lodash.filter)(this.get('_collateralTypeList'), item => {
          let modelCollateralTypes = (0, _lodash.map)((0, _lodash.filter)(this.get('collaterals'), coll => {
            return coll.action !== this.get('constants.actionType.remove');
          }), 'collateralType');
          return (0, _lodash.includes)(modelCollateralTypes, item.value);
        }));
      }
    },
    //endregion

    //region TASKS
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        // COLLATERAL TYPES
        let collateralTypePicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.nzFsCollateralType'));
        this.set('_collateralTypeList', collateralTypePicklist);

        //Search Context Picklist

        //The values of this picklist depend on collateral item model attribute names
        //collateral types array is set based on collateral rules
        this.set('_searchByPicklist', [{
          title: 'Description',
          value: 'collDescription',
          collateralTypes: [constants.nzFsCollateralTypes.aapp, constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.cash, constants.nzFsCollateralTypes.cp, constants.nzFsCollateralTypes.crops, constants.nzFsCollateralTypes.dt, constants.nzFsCollateralTypes.int, constants.nzFsCollateralTypes.is, constants.nzFsCollateralTypes.ls, constants.nzFsCollateralTypes.ni, constants.nzFsCollateralTypes.oth]
        }, {
          title: 'Aircraft Class',
          value: 'aircraftClassTitle',
          collateralTypes: [constants.nzFsCollateralTypes.acft]
        }, {
          title: 'Chassis',
          value: 'chassis',
          collateralTypes: [constants.nzFsCollateralTypes.mv]
        }, {
          title: 'Colour',
          value: 'colour',
          collateralTypes: [constants.nzFsCollateralTypes.mv, constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.oth]
        }, {
          title: 'Item Description',
          value: 'description',
          collateralTypes: [constants.nzFsCollateralTypes.acft, constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.oth, constants.nzFsCollateralTypes.mv]
        }, {
          title: 'Identifying Numbers',
          value: 'identifyingNumbers',
          collateralTypes: [constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.oth]
        }, {
          title: 'Make',
          value: 'make',
          collateralTypes: [constants.nzFsCollateralTypes.acft, constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.oth, constants.nzFsCollateralTypes.mv]
        }, {
          title: 'Manufacturer Serial Number',
          value: 'manufacturerSerial',
          collateralTypes: [constants.nzFsCollateralTypes.acft]
        }, {
          title: 'Model',
          value: 'model',
          collateralTypes: [constants.nzFsCollateralTypes.acft, constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.oth, constants.nzFsCollateralTypes.mv]
        }, {
          title: 'Registration Mark',
          value: 'registrationMark',
          collateralTypes: [constants.nzFsCollateralTypes.acft]
        }, {
          title: 'Reg No',
          value: 'registrationPlate',
          collateralTypes: [constants.nzFsCollateralTypes.mv]
        }, {
          title: 'VIN',
          value: 'vin',
          collateralTypes: [constants.nzFsCollateralTypes.mv]
        }, {
          title: 'Year',
          value: 'year',
          collateralTypes: [constants.nzFsCollateralTypes.acft, constants.nzFsCollateralTypes.aappe, constants.nzFsCollateralTypes.oth, constants.nzFsCollateralTypes.mv]
        }]);
      } catch (error) {
        this.get('logger').logError(error);
      }
    }),
    //endregion

    actions: {
      _toggleSingle: function _toggleSingle(item) {
        item.toggleProperty('collapsed');
      },
      _onChangeCollateralType(selected) {
        this.set('_selectedCollateralType', selected);
        this.set('collateralFilters.collateralType', selected ? selected.value : null);
        //reset search
        this.set('_searchValue', '');
        this.set('collateralFilters.searchValue', '');

        //Filter Search by
        this._filterSearchByPicklist();

        //Filter Collateral
        this.get('filterCollateral')();
      },
      _onChangeSearchBy(selected) {
        this.set('_selectedSearchBy', selected);
        this.set('collateralFilters.searchBy', selected.value);
      },
      _onSearch(value) {
        if (!this.get('isSearching')) {
          this.setProperties({
            isSearching: true,
            _searchValue: value
          });
          this.set('collateralFilters.searchValue', value);

          //Filter Collateral
          (0, _emberLifeline.runTask)(this, () => {
            this.get('filterCollateral')();
          }, 10);
        }
      },
      _onShowMore(collateral) {
        collateral.set('showCount', collateral.get('showCount') + this.get('collateralFilters').showCount);
        this.get('filterCollateral')(collateral, true);
      },
      _onClickAddCollType() {
        this.get('onClickAddCollType')();
      },
      _onClickRemoveColl(collateral) {
        this.get('onClickRemoveColl')(collateral);
      },
      _onClickEditColl(collateral, collIndex) {
        this.get('onClickEditColl')(collateral, collIndex);
      },
      _onClickRemoveCollItem(collateral, item) {
        this.get('onClickRemoveCollItem')(collateral, item);
      },
      _onClickEditCollItem(collateral, collItem) {
        this.get('onClickEditCollItem')(collateral, collItem);
      },
      _onClickAddCollItem(collateral) {
        this.get('onClickAddCollItem')(collateral);
      }
    }
  });
  _exports.default = _default;
});