define("esis-ui/repositories/validation-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency"], function (_exports, _communicationBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    reverifyOrganisation: (0, _emberConcurrency.task)(function* (grantorId) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.reverifyOrganisation'), grantorId));
    }).drop(),
    validateOrganisation: (0, _emberConcurrency.task)(function* (ValidateOrganisationRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.validateOrganisation'), ValidateOrganisationRequest.clone(), false);
    }),
    validateEmail: (0, _emberConcurrency.task)(function* (ValidateEmailRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.validateEmail'), ValidateEmailRequest, false);
    }),
    validateNzPhoneNumber: (0, _emberConcurrency.task)(function* (ValidateNzPhoneNumberRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.validateNzPhoneNumber'), ValidateNzPhoneNumberRequest, false);
    })
  });
  _exports.default = _default;
});