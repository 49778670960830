define("esis-ui/components/areas/shared/view-page-base", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: '',
    parentTab: null,
    panelId: 'viewPanel',
    currentGuid: null,
    tabPanel: null,
    get isVisible() {
      return this.get('tabPanel.isVisible');
    },
    model: Ember.computed.alias('parentTab.tabModel'),
    // used for detecting dirty notes.
    get tabPanelUuid() {
      return this.get('tabPanel.panelInternalId');
    },
    shouldRefreshOnceWhenVisible: false,
    alwaysRfreshDataWhenSwitchTo: false,
    load(tabModel, ...tasks) {
      if (!(0, _lodash.isEmpty)(tabModel) && !(0, _lodash.isEmpty)(tabModel.get('guid')) && tabModel.get('guid') !== this.get('currentGuid')) {
        this.set('currentGuid', tabModel.get('guid'));
        for (let task of tasks) {
          task.perform(tabModel.get('guid'));
        }
      }
    },
    loadV2() {
      (0, _emberLifeline.runTask)(this, () => {
        if (!(0, _lodash.isEmpty)(this.get('tabPanelUuid'))) {
          const model = this.get('model');
          if (!(0, _lodash.isEmpty)(model)) {
            if (model.guid !== this.get('currentGuid') || this.get('shouldRefreshOnceWhenVisible') === true) {
              this.set('currentGuid', model.guid);
              this.refreshData();
            }
          }
        } else {
          (0, _emberLifeline.runTask)(this, 'loadV2', 20);
        }
      });
    },
    refreshData(fullRefresh = false) {
      (0, _emberLifeline.runTask)(this, () => {
        const model = this.get('model');
        if (!(0, _lodash.isEmpty)(model)) {
          if (this.get('isVisible') === true || fullRefresh === true) {
            this.loadData();
            if (fullRefresh === true) {
              this.fullRefresh();
            }
            this.set('shouldRefreshOnceWhenVisible', false);
          } else {
            this.set('shouldRefreshOnceWhenVisible', true);
          }
        }
      });
    },
    /*
    this virtual method should be overridden on each of child page to allow refresh data service
     */
    loadData() {},
    tabSelect() {},
    fullRefresh() {},
    actions: {
      onTabSelect: function onTabSelect() {
        this.tabSelect();
        if (this.get('alwaysRfreshDataWhenSwitchTo') === true) {
          this.refreshData();
        } else {
          this.loadV2();
        }
      },
      onRefreshData() {
        this.refreshData(true);
      }
    }
  });
  _exports.default = _default;
});