define("esis-ui/authenticators/esis-authenticator", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "esis-ui/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  Custom authoriser to connect to ESIS backend custom IDP
  use resource owner flow
  use refresh token
   */
  var _default = _oauth2PasswordGrant.default.extend({
    // override token and refresh token endpoint
    serverTokenEndpoint: `${_environment.default.apiBaseUrl}/token`,
    refreshAccessTokens: false,
    sendClientIdAsQueryParam: true
  });
  _exports.default = _default;
});