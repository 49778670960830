define("esis-ui/components/areas/shared/email-display/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION
    emailGridAutoFocus: false,
    _messageGrid: null,
    _messagePreview: null,
    init() {
      this._super(...arguments);
      this.set('_msgGridOption', {
        height: '100%',
        columns: [{
          field: "id",
          title: " ",
          width: "35px",
          filterable: false,
          sortable: false,
          template: `<span class='attachFileIcon pl-2'>#if (hasAttachments) {# ${this.get('constants.fIcon.file')} #} #</span>`
        }, {
          field: "statusTitle",
          title: "Status",
          width: "90px",
          filterable: false
        }, {
          field: "addressFrom",
          title: "Address From",
          width: "170px",
          filterable: false
        }, {
          field: "addressTo",
          title: "Address To",
          width: "170px",
          filterable: false
        }, {
          field: "subject",
          title: "Subject",
          filterable: false
        }, {
          field: "userEdited",
          title: "User",
          width: "150px",
          filterable: false
        }, {
          field: "dateEmail",
          title: "Date",
          width: "150px",
          filterable: false
        }]
      });
    },
    //endregion

    //region Methods
    loadData(preview) {
      (0, _emberLifeline.runTask)(this, () => {
        let selectedGuid = null;
        if (!(0, _lodash.isEmpty)(preview)) {
          const sortedData = (0, _lodash.orderBy)(preview, 'sessionId');
          const firstIn = (0, _lodash.find)(sortedData, {
            'direction': 'I'
          });
          if (!(0, _lodash.isEmpty)(firstIn)) {
            selectedGuid = firstIn.guid;
          }
        }
        this.get('_messageGrid').loadData(preview, selectedGuid);
      });
      this.get('_messagePreview').reset();
    },
    //endregion

    //region Actions
    actions: {
      _onSelectionChange: function _onSelectionChange(data) {
        this.set('_selectedItem', data);
        (0, _emberLifeline.runTask)(this, () => {
          if (!(0, _lodash.isEmpty)(this.get('_selectedItem.guid'))) {
            this.get('_messagePreview').load(this.get('_selectedItem.guid'));
          }
        });
        if (!(0, _lodash.isEmpty)(this.get('onSelectItem'))) {
          this.get('onSelectItem')(data);
        }
      }
    }
    //endregion
  });
  _exports.default = _default;
});