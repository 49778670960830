define("esis-ui/components/areas/registration/nz/shared/nz-debtor-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline", "ember-concurrency"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    _dialog: null,
    _requestModel: null,
    _indexId: null,
    _detailHasMailingAddress: null,
    _personOnBehalfHasMailingAddress: null,
    init() {
      this._super(...arguments);
      this._randomGuid = this.get('app').guid();
      const constants = this.get('constants');
      this.set('_orgTypeList', [{
        title: 'Partnership',
        value: constants.nzDebtorPpsrOrganisationType.partnership
      }, {
        title: 'Trust',
        value: constants.nzDebtorPpsrOrganisationType.trust
      }, {
        title: 'Other',
        value: constants.nzDebtorPpsrOrganisationType.other
      }]);
    },
    //endregion

    //region COMPUTED PROPERTIES
    _manageDetailMailingAddressButtonTitle: Ember.computed('_detailHasMailingAddress', function () {
      return this.get('_detailHasMailingAddress') ? "Remove Mailing Address" : "Add Mailing Address";
    }),
    _managePersonOnBehalfMailingAddressButtonTitle: Ember.computed('_personOnBehalfHasMailingAddress', function () {
      return this.get('_personOnBehalfHasMailingAddress') ? "Remove Mailing Address" : "Add Mailing Address";
    }),
    _mailingAddressComponent: Ember.observer('_mailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_mailingAddress'))) {
        this.get('_mailingAddress').loadPicklistData();
      }
      return null;
    }),
    _contactAddressComponent: Ember.observer('_contactAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_contactAddress'))) {
        this.get('_contactAddress').loadPicklistData();
      }
      return null;
    }),
    _personActingOnBehalfMailingAddressComponent: Ember.observer('_personActingOnBehalfMailingAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_personActingOnBehalfMailingAddress'))) {
        this.get('_personActingOnBehalfMailingAddress').loadPicklistData();
      }
      return null;
    }),
    _personActingOnBehalfContactAddressComponent: Ember.observer('_personActingOnBehalfContactAddress', function () {
      if (!(0, _lodash.isEmpty)(this.get('_personActingOnBehalfContactAddress'))) {
        this.get('_personActingOnBehalfContactAddress').loadPicklistData();
      }
      return null;
    }),
    _isOrgLookUp: Ember.computed('_addOrgType', function () {
      return this.get('_addOrgType') === this.get('constants.nzAddOrgDebtorType.lookup');
    }),
    //endregion

    //region METHODS
    _reset: function _reset() {
      this.setProperties({
        _requestModel: null,
        _indexId: null,
        _detailHasMailingAddress: null,
        _personOnBehalfHasMailingAddress: null,
        _selectedOrgType: null,
        _disabledAddOrgTypeRadioBtn: false,
        _personEmailError: '',
        _paobEmailError: '',
        _paobPhoneError: '',
        _paobFaxError: '',
        _personPhoneError: '',
        _personFaxError: ''
      });
    },
    _loadModel(model, reSelectCountry) {
      if ((0, _lodash.isNil)(model.indexId)) {
        this.set('_requestModel', this.mapJsonObjectToModel(model, 'nz-debtor-create-request'));
        this.set('_requestModel.contactAddress', this.createModel('address-nz'));
        if (this.get('_requestModel.isOrganisation')) {
          this.set('_requestModel.personActingOnBehalf', this.createModel('secured-party-person-on-behalf-nz'));
          this.set('_requestModel.personActingOnBehalf.contactAddress', this.createModel('address-nz'));
        }
        if (reSelectCountry) {
          let selectedCountry = this.get('_contactAddress').setSelectedCountry('NZ');
          this.set('_requestModel.contactAddress.countryDescription', selectedCountry.title);
          this.set('_requestModel.contactAddress.countryCode', 'NZ');
          this.set('_requestModel.personActingOnBehalf.contactAddress.countryDescription', selectedCountry.title);
          this.set('_requestModel.personActingOnBehalf.contactAddress.countryCode', 'NZ');
        }
      } else {
        this.set('_requestModel', this.mapObject(model, this.createModel('nz-debtor-create-request')));
        this.set('_requestModel.contactAddress', this.mapObject(model.contactAddress, this.createModel('address-nz')));
        if (model.hasMailingAddress) {
          this.set('_detailHasMailingAddress', true);
          this.set('_requestModel.mailingAddress', this.mapObject(model.mailingAddress, this.createModel('address-nz')));
        }
        if (model.isOrganisation) {
          let personOnBehalfModel = this.createModel('secured-party-person-on-behalf-nz');
          this.set('_requestModel.personActingOnBehalf', this.mapObject(model.personActingOnBehalf, personOnBehalfModel));
          if (model.personActingOnBehalf.hasMailingAddress) {
            this.set('_personOnBehalfHasMailingAddress', true);
            let address = model.personActingOnBehalf.mailingAddress;
            this.set('_requestModel.personActingOnBehalf.mailingAddress', this.mapObject(address, this.createModel('address-nz')));
          }
        }
        this.set('_requestModel.indexId', model.indexId);
        this.set('_selectedOrgType', (0, _lodash.find)(this.get('_orgTypeList'), {
          value: model.organisationType
        }));
      }
      this.get('_requestModel').saveModel();
    },
    _loadOrgModelToRequestModel(orgModel) {
      let businessModel = this.mapJsonObjectToModel(orgModel, 'nz-bussiness-digest');
      this.mapOnlySourceProps(businessModel, this.get('_requestModel'));
      this.get('_requestModel').setProperties({
        organisationType: orgModel.ppsrTypeCode,
        organisationTypeTitle: orgModel.ppsrTypeDescription
      });
      if (!(0, _lodash.isEmpty)(orgModel.mailingAddress)) {
        this.set('_requestModel.mailingAddress', this.mapJsonObjectToModel(orgModel.mailingAddress, 'address-nz'));
        if (this.get('_detailHasMailingAddress')) {
          let selectedCountry = this.get('_mailingAddress').setSelectedCountry(orgModel.mailingAddress.countryCode);
          if (!(0, _lodash.isEmpty)(selectedCountry)) {
            this.set('_requestModel.mailingAddress.countryDescription', selectedCountry.title);
          }
        } else {
          this.set('_detailHasMailingAddress', true);
        }
      } else if (this.get('_detailHasMailingAddress')) {
        this.set('_requestModel.mailingAddress', this.createModel('address-nz'));
      }
      if (!(0, _lodash.isEmpty)(orgModel.contactAddress)) {
        this.set('_requestModel.contactAddress', this.mapJsonObjectToModel(orgModel.contactAddress, 'address-nz'));
        let selectedCountry = this.get('_contactAddress').setSelectedCountry(orgModel.contactAddress.countryCode);
        if (!(0, _lodash.isEmpty)(selectedCountry)) {
          this.set('_requestModel.contactAddress.countryDescription', selectedCountry.title);
        }
      } else {
        this.set('_requestModel.contactAddress', this.createModel('address-nz'));
        let selectedCountry = this.get('_contactAddress').setSelectedCountry('NZ');
        this.set('_requestModel.contactAddress.countryDescription', selectedCountry.title);
        this.set('_requestModel.contactAddress.countryCode', 'NZ');
      }
    },
    show(model) {
      this._reset();
      this.set('model', model);

      //adding new debtor
      if ((0, _lodash.isNil)(model.indexId)) {
        this.set('_modalTitle', model.debtorType === this.get('constants.nzSPOrDebtorType.organisation') ? 'Add Debtor - Organisation' : 'Add Debtor - Person');
        this.set('_confirmBtnLabel', 'Add');
        this.set('_addOrgType', this.get('constants.nzAddOrgDebtorType.lookup'));
      }
      //editing existing debtor
      else {
        this.set('_modalTitle', model.debtorType === this.get('constants.nzSPOrDebtorType.organisation') ? 'Edit Debtor - Organisation' : 'Edit Debtor - Person');
        this.set('_confirmBtnLabel', 'Update');
        this.set('_addOrgType', (0, _lodash.isEmpty)(model.nzbn) ? this.get('constants.nzAddOrgDebtorType.manual') : this.get('constants.nzAddOrgDebtorType.lookup'));
        this.set('_disabledAddOrgTypeRadioBtn', true);
      }
      this._loadModel(this.get('model'));
      this.get('_dialog').show();
      (0, _emberLifeline.runTask)(this, () => {
        if (this.get('_requestModel.isOrganisation')) {
          this.get('_orgLookupComponent').reset();
        }
      }, 200);
    },
    //endregion

    //region TASKS
    //region Validations
    //PAOB
    _validatePAOBEmail: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('_requestModel.personActingOnBehalf.email'))) {
          let response = yield this.get('validationRepo.validateEmail').perform({
            emailAddress: this.get('_requestModel.personActingOnBehalf.email')
          });
          if (response.isValid) {
            this.set('_paobEmailError', '');
          } else {
            this.set('_paobEmailError', response.validationErrorMessage);
          }
        } else {
          this.set('_paobEmailError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-debtor-modal._validatePAOBEmail', error);
      }
    }),
    _validatePAOBPhone: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('_requestModel.personActingOnBehalf.phoneNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('_requestModel.personActingOnBehalf.phoneNumber')
          });
          if (response.isValid) {
            this.set('_paobPhoneError', '');
          } else {
            // this.set('_paobPhoneError', response.validationErrorMessage);
            this.set('_paobPhoneError', this.get('constants.nzPhoneValidationMessage'));
          }
        } else {
          this.set('_paobPhoneError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-debtor-modal._validatePAOBPhone', error);
      }
    }),
    _validatePAOBFax: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('_requestModel.personActingOnBehalf.faxNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('_requestModel.personActingOnBehalf.faxNumber')
          });
          if (response.isValid) {
            this.set('_paobFaxError', '');
          } else {
            // this.set('_paobFaxError', response.validationErrorMessage);
            this.set('_paobFaxError', this.get('constants.nzFaxValidationMessage'));
          }
        } else {
          this.set('_paobFaxError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-debtor-modal._validatePAOBFax', error);
      }
    }),
    //Person
    _validatePersonEmail: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('_requestModel.email'))) {
          let response = yield this.get('validationRepo.validateEmail').perform({
            emailAddress: this.get('_requestModel.email')
          });
          if (response.isValid) {
            this.set('_personEmailError', '');
          } else {
            this.set('_personEmailError', response.validationErrorMessage);
          }
        } else {
          this.set('_personEmailError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-debtor-modal._validatePersonEmail', error);
      }
    }),
    _validatePersonPhone: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('_requestModel.phoneNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('_requestModel.phoneNumber')
          });
          if (response.isValid) {
            this.set('_personPhoneError', '');
          } else {
            // this.set('_personPhoneError', response.validationErrorMessage);
            this.set('_personPhoneError', this.get('constants.nzPhoneValidationMessage'));
          }
        } else {
          this.set('_personPhoneError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-debtor-modal._validatePersonPhone', error);
      }
    }),
    _validatePersonFax: (0, _emberConcurrency.task)(function* () {
      try {
        if (!(0, _lodash.isEmpty)(this.get('_requestModel.faxNumber'))) {
          let response = yield this.get('validationRepo.validateNzPhoneNumber').perform({
            phoneNumber: this.get('_requestModel.faxNumber')
          });
          if (response.isValid) {
            this.set('_personFaxError', '');
          } else {
            // this.set('_personFaxError', response.validationErrorMessage);
            this.set('_personFaxError', this.get('constants.nzFaxValidationMessage'));
          }
        } else {
          this.set('_personFaxError', '');
        }
      } catch (error) {
        this.get('logger').logError('nz-debtor-modal._validatePersonFax', error);
      }
    }),
    //endregion
    _save: (0, _emberConcurrency.task)(function* () {
      yield this._validatePAOBEmail.perform();
      // yield this._validatePAOBPhone.perform();
      // yield this._validatePAOBFax.perform();
      yield this._validatePersonEmail.perform();
      // yield this._validatePersonPhone.perform();
      // yield this._validatePersonFax.perform();

      let valid = this.get('_requestModel').validateModel();
      let emailHasError = !(0, _lodash.isEmpty)(this.get('_paobEmailError')) || !(0, _lodash.isEmpty)(this.get('_personEmailError'));
      if (valid && !emailHasError && !(0, _lodash.isEmpty)(this.get('onSave'))) {
        this.get('onSave')(this.get('_requestModel'));
        this.get('_dialog').hide();
      }
    }),
    //endregion

    //region ACTIONS
    actions: {
      //region BUTTON ACTIONS
      _onManageDetailMailingAddressClick() {
        this.toggleProperty('_detailHasMailingAddress');
        if (this.get('_detailHasMailingAddress')) {
          this.set('_requestModel.mailingAddress', this.createModel('address-nz'));
        } else {
          this.set('_requestModel.mailingAddress', null);
        }
      },
      _onManagePersonOnBehalfMailingAddressClick() {
        this.toggleProperty('_personOnBehalfHasMailingAddress');
        if (this.get('_personOnBehalfHasMailingAddress')) {
          this.set('_requestModel.personActingOnBehalf.mailingAddress', this.createModel('address-nz'));
        } else {
          this.set('_requestModel.personActingOnBehalf.mailingAddress', null);
        }
      },
      _copyAddress: function _copyAddress() {
        let model = this.get('_requestModel');
        this.get('_mailingAddress').copyAddress(model.contactAddress);
      },
      _copyPersonOnBehalfAddress: function _copyPersonOnBehalfAddress() {
        let model = this.get('_requestModel.personActingOnBehalf');
        this.get('_personActingOnBehalfMailingAddress').copyAddress(model.contactAddress);
      },
      _onSave: function _onSave() {
        this._save.perform();
      },
      _onDobChanged(value) {
        this.set('_requestModel.personDateOfBirth', value);
      },
      _onAddOrgTypeChange(value) {
        this.set('_addOrgType', value);

        //reset Model
        this.set('_selectedOrgType', null);
        this.get('_orgLookupComponent').reset();
        let selectDefaultCountry = true;
        this._loadModel(this.get('model'), selectDefaultCountry);
      },
      _onChangeOrgType(selected) {
        this.set('_selectedOrgType', selected);
        this.set('_requestModel.organisationType', selected.value);
        this.set('_requestModel.organisationTypeTitle', selected.title);
      },
      _onOrgLookedup(orgModel) {
        this._loadOrgModelToRequestModel(orgModel);
      },
      //endregion

      //region email phone fax validations
      //person
      _validatePersonEmail() {
        this._validatePersonEmail.perform();
      },
      _validatePersonPhone() {
        this._validatePersonPhone.perform();
      },
      _validatePersonFax() {
        this._validatePersonFax.perform();
      },
      //PAOB
      _validatePaobEmail() {
        this._validatePAOBEmail.perform();
      },
      _validatePaobPhone() {
        this._validatePAOBPhone.perform();
      },
      _validatePaobFax() {
        this._validatePAOBFax.perform();
      }
      //endregion
    }
    //endregion
  });
  _exports.default = _default;
});