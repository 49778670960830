define("esis-ui/components/areas/shared/buttons/help-icon/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: 'span',
    classNames: ['d-inline-block'],
    actions: {
      click: function click() {
        //this.get('onClick')();
        this.get('_helpDlg').show();
      }
    }
  });
  _exports.default = _default;
});