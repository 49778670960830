define("esis-ui/components/areas/shell/concurrency-error-modal/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    _message: '',
    init() {
      this._super(...arguments);
    },
    show(message) {
      this.set('_message', message);
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm() {
        this.get('_dialog').hide();
      }
    }
  });
  _exports.default = _default;
});