define("esis-ui/components/areas/shared/picker/address-book-picker/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION

    init() {
      this._super(...arguments);
    },
    //endregion

    //region HOOKS
    //endregion

    //region METHODS
    show() {
      // this._reset();
      this.get('_dialog').show();
    },
    //endregion

    //region TASK

    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        this.set('_selectedAddress', data);
      },
      //endregion

      //region FILTER ACTIONS
      _onSearch: function _onSearch(value) {
        this.set('_search', value);
      }
      //endregion
    }

    //endregion
  });
  _exports.default = _default;
});