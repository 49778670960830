define("esis-ui/components/areas/bulk-file/nz/list/nz-file-uploader-modal/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency"], function (_exports, _sharedComponentBase, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALIZATION ---
    modalTitle: 'Upload Bulk File',
    _fileUpload: null,
    onConfirm: function onConfirm() {},
    //region TASK
    _uploadFile: (0, _emberConcurrency.task)(function* () {
      try {
        let data = this.get('_fileInfo');
        if (Ember.isNone(data) || Ember.isBlank(this.get('_description')) || Ember.isBlank(data.fileKey)) {
          if (Ember.isBlank(this.get('_description'))) {
            this.set('_blankDescription', true);
          }
          if (Ember.isNone(data) || Ember.isBlank(data.fileKey)) {
            this.set('_noFile', true);
          }
          return;
        }
        data.description = this.get('_description');
        data.fileType = this.get('_selectedFileType.value');
        data.esisEntityGuid = this.get('credential.currentBusinessEntityGuid');
        yield this.get('fileRepo.saveNzBulkFile').perform(data);
        if (Ember.isPresent(this.get('_fileUpload'))) {
          this.get('_fileUpload').resetDropzone();
        }
        this.set('description', '');
        this.set('fileInfo', null);
        this.onConfirm(this.get('_selectedFileType.value'));
        this.get('_dialog').hide();
      } catch (error) {
        this.notifyError('Fail to upload bulk file error', error);
        this.get('logger').logError('nz-fil-uploader-modal._uploadFile', error);
      }
    }),
    _loadPicklist: (0, _emberConcurrency.task)(function* () {
      try {
        let fileTypePicklist = yield this.get('picklistRepo.loadNzBulkFileTypes').perform();
        this.set('_fileTypes', fileTypePicklist);
      } catch (error) {
        this.get('logger').logError('nz-fil-uploader-modal._loadPicklist', error);
      }
    }),
    //endregion

    _reset() {
      this.setProperties({
        _description: '',
        _fileInfo: null,
        _blankDescription: false,
        _noFile: false,
        _selectedFileType: null
      });
    },
    show() {
      this._reset();
      this._loadPicklist.perform();
      this.get('_dialog').show();
    },
    actions: {
      _onConfirm: function _onConfirm() {
        this._uploadFile.perform();
      },
      _onClose() {
        this._reset();
        if (Ember.isPresent(this.get('_fileUpload'))) {
          this.get('_fileUpload').resetDropzone();
        }
      },
      _discardFile() {
        this.set('_fileInfo', null);
        this.set('_noFile', false);
      },
      _fileUploaded(data) {
        this.set('_fileInfo', data);
      },
      _onChangeFileType(option) {
        this.set('_selectedFileType', option);
      }
    }
  });
  _exports.default = _default;
});