define("esis-ui/components/shared/ember-bootstrap/static-tab/panel-base", ["exports", "esis-ui/components/shared/shared-component-base", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: '',
    // do not generate own div tab
    parentTab: null,
    panelId: null,
    // this is required
    panelTitle: '',
    // this is required
    _recalcVisibility: null,
    _cachedParentTabModelGuid: null,
    _cachedParentActiveTabId: null,
    _cachedParentTabModelInstanceGuid: '',
    /*
    trigger to refresh tab panel,
    when parent tab model changes
    when active tab changes, and panel model is not loaded
    returns, parent tab model, tab panel model, and tab id
     */
    refreshTab: function refreshTab() {},
    init() {
      this._super(...arguments);
      (0, _emberLifeline.runTask)(this, () => {
        if (this.get('parentTab.activeTabId') === this.get('panelInternalId')) {
          this._refreshTab();
        }
      });
    },
    _refreshTab() {
      const internalId = this.get('panelInternalId');
      if (this.get('_cachedParentActiveTabId') !== internalId || this.get('parentTab.tabModel.guid') !== this.get('_cachedParentTabModelGuid') || Ember.isPresent(this.get('parentTab.tabModel.instanceGuid')) && this.get('parentTab.tabModel.instanceGuid') !== this.get('_cachedParentTabModelInstanceGuid')) {
        this.set('_cachedParentActiveTabId', internalId);
        this.set('_cachedParentTabModelGuid', this.get('parentTab.tabModel.guid'));
        if (Ember.isPresent(this.get('parentTab.tabModel.instanceGuid'))) {
          this.set('_cachedParentTabModelInstanceGuid', this.get('parentTab.tabModel.instanceGuid'));
        }
        //this.get('logger').logDebug(`Activate tab id: ${internalId} title: ${this.get('panelTitle')} guid: ${this.get('parentTab.tabModel.guid')}`);
        this.get('refreshTab')(this.convertToEmberObject(this.get('parentTab.tabModel')), this.get('tabId'));
        this.set('_recalcVisibility', this.get('app').guid());
      }
    },
    get screenId() {
      return this.get('parentTab.screenId');
    },
    get panelInternalId() {
      if (Ember.isPresent(this.get('parentTab.uuid'))) {
        return `${this.get('parentTab.uuid')}_${this.get('panelId')}`;
      }
      return this.get('panelId');
    },
    get isVisible() {
      const app = this.get('app');
      const internalId = this.get('panelInternalId');
      return app.screenStackContains(this.get('screenId'), internalId) && this.get('parentTab.activeTabId') === internalId;
    },
    _refreshOnTabModelChange: Ember.observer('parentTab.tabModel', function () {
      if (this.get('parentTab.tabModel.guid') !== this.get('_cachedParentTabModelGuid') || Ember.isPresent(this.get('parentTab.tabModel.instanceGuid')) && this.get('parentTab.tabModel.instanceGuid') !== this.get('_cachedParentTabModelInstanceGuid')) {
        if (this.get('parentTab.activeTabId') === this.get('panelInternalId')) {
          // the tab is the current showing tab, therefore refresh immediate when tab model change
          (0, _emberLifeline.runTask)(this, '_refreshTab');
        }
      }
    }),
    _refreshOnActiveTabChange: Ember.observer('parentTab.activeTabId', function () {
      /*
      trigger refresh tab when the new active tab is this tab
       */
      const internalId = this.get('panelInternalId');
      if (this.get('parentTab.activeTabId') === internalId) {
        (0, _emberLifeline.runTask)(this, '_refreshTab');
      }
      if (this.get('parentTab.activeTabId') !== internalId) {
        this.set('_cachedParentActiveTabId', this.get('parentTab.activeTabId'));
      }
    })
  });
  _exports.default = _default;
});