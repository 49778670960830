define("esis-ui/services/app-constants", ["exports", "esis-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    appName: _environment.default.appName,
    appEnvironment: _environment.default.appEnvironment,
    apiBaseUrl: _environment.default.apiBaseUrl,
    apiRoutePath: _environment.default.apiRoutePath,
    videoBaseUrl: _environment.default.videoBaseUrl,
    rootURL: _environment.default.rootURL,
    apiOdataRoutePath: Ember.computed('apiRoutePath', function () {
      return `${this.get('apiRoutePath')}/odata`;
    }),
    appVersion: _environment.default.appVersion,
    idpClientId: _environment.default.idpClientId,
    idpClientSecrete: _environment.default.idpClientSecrete,
    showWindowReloadWarning: _environment.default.showWindowReloadWarning,
    init() {
      this._super(...arguments);
      this.defaultDateFormat = 'DD/MM/YYYY';
      this.defaultDateWithMonthAbbrFormat = 'D-MMM-YYYY';
      this.defaultDateFormatIso = 'YYYY-MM-DD'; //2019-01-14T11:00:00.000Z
      this.defaultYearFormat = 'YYYY';
      this.defaultMonthFormat = 'MMMM';
      this.defaultDateTimeFormat = 'DD/MM/YYYY HH:mm';
      this.defaultTimeFormat = 'HH:mm';
      this.defaultAmPmTimeFormat = 'hh:mm A';
      this.emptyGuid = '00000000-0000-0000-0000-000000000000';
      this.authorisationQsName = 'auth';

      /* App Theme*/
      this.appTheme = {
        lightTheme: 'theme-light',
        darkTheme: 'theme-dark'
      };

      //region tab and navigation
      this.region = {
        au: 1,
        nz: 2
      };
      this.tabType = {
        error: 'error',
        pending: 'pending',
        ok: 'ok',
        new: 'new',
        edit: 'edit'
      };
      this.pagePath = {
        //GENERAL
        systemUsersList: 'areas/admin/system-user/list/system-user-list',
        systemUsersEdit: 'areas/admin/system-user/view/system-user-view',
        emailsList: 'areas/admin/email-notification/list/email-notification-list',
        businessEntitiesView: 'areas/admin/business-entity/view/business-entity-view',
        securityRoleList: 'areas/admin/security-roles/list/security-role-list',
        securityRoleView: 'areas/admin/security-roles/view/security-role-view',
        profile: 'areas/profile/user-info',
        //AU
        homeAu: 'areas/home/au/au-home-page',
        searchByOrganisationAu: 'areas/search/au/view/au-search-organisation',
        searchByIndividualAu: 'areas/search/au/view/au-search-individual',
        searchByMvAu: 'areas/search/au/view/au-search-motor-vehicle',
        searchByRegistrationNumberAu: 'areas/search/au/view/au-search-ppsr-number',
        searchBySerialAu: 'areas/search/au/view/au-search-serial-number',
        searchListAu: 'areas/search/au/list/au-search-list',
        registrationExpressAu: 'areas/registration/au/express-wizard/au-registration-express',
        registrationWizardAu: 'areas/registration/au/wizard/au-registration-wizard-container',
        registrationListAu: 'areas/registration/au/list/au-registration-list',
        registrationViewAu: 'areas/registration/au/view/au-registration-view',
        contractTypeListAu: 'areas/admin/contract-type/au/list/au-contract-type-list',
        contractTypeEditAu: 'areas/admin/contract-type/au/view/au-contract-type-view',
        bulkFileListAu: 'areas/bulk-file/au/list/au-bulk-file-list',
        reportsAu: 'areas/report/au/list/au-report-list',
        categoryListAu: 'areas/admin/category/au/list/category-list',
        spgListAu: 'areas/spg/au/list/au-spg-list',
        transactionHistoryListAu: 'areas/admin/transaction-history/au/list/au-transaction-history-list',
        searchByOrdinal: 'areas/search/search-ordinal-screen',
        searchByFile: 'areas/search/search-file-screen',
        spgWizardAu: 'areas/spg/au/wizard/au-spg-wizard',
        spgReviewAu: 'areas/spg/au/view/au-spg-view',
        registrationFile: 'areas/registration/au/registration-file-screen',
        forgot: 'forgot-cred-access-key',
        dataCleansingGrid: 'areas/admin/data-cleansing',
        ppsrCharge: 'areas/admin/ppsr-charge',
        //NZ
        homeNz: 'areas/home/nz/nz-home-page',
        searchListNz: 'areas/search/nz/list/nz-search-list',
        searchByDebtorOrgNz: 'areas/search/nz/view/nz-search-debtor-organisation',
        searchByDebtorPersonNz: 'areas/search/nz/view/nz-search-debtor-person',
        searchByMvNz: 'areas/search/nz/view/nz-search-motor-vehicle',
        searchByRegistrationNumberNz: 'areas/search/nz/view/nz-search-fs-number',
        searchViewFsNz: 'areas/search/nz/view/nz-fs-search-view',
        // searchByDebtorRefNz: 'areas/search/search-serial-number-screen',
        registrationWizardNz: 'areas/registration/nz/wizard/nz-registration-wizard-container',
        registrationListNz: 'areas/registration/nz/list/nz-registration-list',
        registrationViewNz: 'areas/registration/nz/view/nz-registration-view',
        contractTypeListNz: 'areas/admin/contract-type/nz/list/nz-collateral-profile-list',
        contractTypeViewNz: 'areas/admin/contract-type/nz/view/nz-collateral-profile-view',
        bulkFileListNz: 'areas/bulk-file/nz/list/nz-bulk-file-list',
        spgListNz: 'areas/spg/nz/list/nz-spg-list',
        transactionHistoryListNz: 'areas/admin/transaction-history/nz/list/nz-transaction-history-list',
        spgWizardNz: 'areas/spg/nz/wizard/nz-spg-wizard',
        spgReviewNz: 'areas/spg/nz/view/nz-spg-view',
        branchCodeListNz: 'areas/admin/branch-code/nz/list/nz-branch-code-list'
      };
      this.pageTitle = {
        //GENERAL
        systemUsersList: 'System Users',
        systemUsersEdit: 'System User (NEW)',
        emailsList: 'Emails',
        businessEntitiesView: 'Business Entities',
        securityRoleList: 'Security Roles',
        securityRoleView: 'New Security Role',
        profile: 'Profile',
        //AU
        homeAu: 'Home',
        searchByOrganisationAu: 'Organisation Search',
        searchByIndividualAu: 'Individual Search',
        searchByMvAu: 'MV Search',
        searchByRegistrationNumberAu: 'Reg Num Search',
        searchBySerialAu: 'Serial Num Search',
        searchListAu: 'Searches',
        registrationExpressAu: 'Registration Express',
        registrationWizardAu: 'Registration Wizard (NEW)',
        registrationListAu: 'Registrations',
        registrationViewAu: 'Registration',
        contractTypeListAu: 'Contract Types',
        contractTypeEditAu: 'Contract Type',
        bulkFileListAu: 'File Uploads',
        reportsAu: 'Reports',
        categoryListAu: 'Categories',
        spgListAu: 'Secured Party Groups',
        transactionHistoryListAu: 'Transaction History',
        clientView: 'Anon',
        clinicianList: 'Clinicians',
        dashboard: 'Dashboard',
        datasetList: 'Datasets',
        datasetView: 'Dataset Detail',
        groupCommsList: 'Group Comms',
        searchByOrdinal: 'Ordinal Search',
        searchByFile: '',
        spgWizardAu: 'SPG Wizard',
        spgReviewAu: '',
        registrationFile: '',
        securityRoleEdit: '',
        forgotCredAccessKey: '',
        dataCleansingGrid: '',
        ppsrCharge: '',
        //NZ
        homeNz: 'Home',
        searchListNz: 'Search History',
        searchByDebtorOrgNz: 'New Debtor Organisation Search',
        searchByDebtorPersonNz: 'New Debtor Person Search',
        searchByMvNz: 'New Motor Vehicle Search',
        searchByRegistrationNumberNz: 'New FS Number Search',
        searchViewFsNz: '',
        // searchByDebtorRefNz: 'Debtor Ref Search',
        registrationWizardNz: 'New Financing Statement',
        registrationListNz: 'Financing Statements',
        registrationViewNz: 'Registration',
        contractTypeListNz: 'Collateral Profiles',
        contractTypeViewNz: 'Collateral Profile',
        bulkFileListNz: 'Bulk Transactions',
        spgListNz: 'Secured Party Groups',
        transactionHistoryListNz: 'Transaction History',
        spgWizardNz: 'SPG (New)',
        spgReviewNz: '',
        branchCodeListNz: 'Branch Code'
      };
      this.wizardStepPath = {
        // AU registration wizard
        registrationSpgStepAu: 'areas/registration/au/wizard/au-registration-spg-step',
        registrationCollateralStepAu: 'areas/registration/au/wizard/au-registration-collateral-step',
        registrationGrantorStepAu: 'areas/registration/au/wizard/au-registration-grantor-step',
        registrationUdfStepAu: 'areas/registration/au/wizard/au-registration-udf-step',
        registrationReviewStepAu: 'areas/registration/au/wizard/au-registration-review-step',
        // NZ registration wizard
        registrationSpgStepNz: 'areas/registration/nz/wizard/nz-registration-spg-step',
        registrationCollateralStepNz: 'areas/registration/nz/wizard/nz-registration-collateral-step',
        registrationDebtorsStepNz: 'areas/registration/nz/wizard/nz-registration-debtor-step',
        registrationReviewStepNz: 'areas/registration/nz/wizard/nz-registration-review-step'
      };
      this.pageTabType = {
        dashboard: null,
        overview: null
      };
      this.pageTabIcon = {
        sessionView: 'comments',
        clientView: 'user-cog',
        systemUserView: 'user-tie',
        taskView: 'tasks',
        taskWizard: 'tasks'
      };
      this.screenElementType = {
        dynamicTab: 'dynamicTab',
        staticTab: 'staticTab',
        modal: 'modal',
        generalModal: 'generalModal'
      };
      //endregion

      //region GRIDS
      this.statusValue = {
        creating: 1,
        error: 2,
        pending: 3,
        review: 4,
        processing: 5,
        processed: 6,
        transferred: 7,
        onHold: 8,
        confirmed: 9,
        rejected: 10,
        submitted: 11,
        partSubmitted: 12,
        fullySubmitted: 13,
        preloadGenerated: 14,
        cancelled: 15,
        uploaded: 16,
        waitingOnFunds: 17,
        expired: 18,
        delegate: 19,
        partiallySubmitted: 20,
        validated: 21,
        partialError: 22,
        pendingAccountingTransaction: 23,
        pendingDischarge: 24
      };
      this.registrationGridViewFilter = {
        //Under Review::review,Pending::pending,Errors::error,Registered::registered,Discharged/Expired::discharged,Due to Expire::dueToExpire,All::all,Waiting On Funds::waitingOnFunds
        approval: 'approval',
        error: 'error',
        registered: 'registered',
        discharged: 'discharged',
        expired: 'expired',
        dueToExpire: 'dueToExpire',
        all: 'all',
        unfinished: 'unfinished'
      };
      this.createAccountingRecordResultStatus = {
        unknown: 0,
        allGood: 1,
        allErrored: 2,
        partialErrored: 3,
        shortfall: 4,
        partialShortfall: 5,
        exceedLimit: 6,
        particalExceedLimit: 7
      };
      this.collateralType = {
        unsupported: 0,
        unknown: 1,
        consumer: 2,
        commercial: 3
      };
      this.actionType = {
        add: 1,
        remove: 2,
        esisOnly: 3,
        expired: 4,
        amend: 5
      };
      this.registrationReportStatusFilter = {
        registration: 1,
        discharged: 2,
        dueToExpire: 3
      };
      this.registrationReportStatusFilterOptions = [{
        title: 'Registration',
        id: 1
      }, {
        title: 'Discharged and Expired',
        id: 2
      }, {
        title: 'Due To Expire',
        id: 3
      }];
      this.grantorType = {
        organisation: 1,
        individual: 2,
        orgLookup: 3
      };
      this.searchType = {
        searchByRegistrationNumber: 1,
        searchBySerialNumber: 2,
        searchByGrantor: 3,
        searchByGrantorAndEventDateRange: 4,
        ordinalSearch: 5,
        requestSearchCertificate: 6
      };
      this.auSearchPageType = {
        organisation: 'searchByOrganisationAu',
        individual: 'searchByIndividualAu',
        mv: 'searchByMvAu',
        serial: 'searchBySerialAu',
        registrationNumber: 'searchByRegistrationNumberAu'
      }, this.grantorSearchFilterOption = {
        none: 1,
        dateRange: 2,
        collateralClassType: 3
      };
      this.searchCollateralClassType = {
        unsupported: 0,
        unknown: 1,
        motorVehicle: 2,
        watercraft: 3,
        aircraft: 4,
        aircraftEngine: 5,
        airframe: 6,
        helicopter: 7,
        smallAircraft: 8,
        agriculture: 9,
        crops: 10,
        livestock: 11,
        otherGoods: 12,
        chattelPaper: 13,
        currency: 14,
        documentOfTitle: 15,
        intermediatedSecurity: 16,
        investmentInstrument: 17,
        negotiableInstrument: 18,
        account: 19,
        generalIntangible: 20,
        intellectualProperty: 21,
        circuitLayout: 22,
        copyright: 23,
        patent: 24,
        design: 25,
        tradeMark: 26,
        plantBreedersRight: 27,
        allPapNoExcept: 28,
        allPapWithExcept: 29
      };
      this.collateralClassTypeSerialisedStatus = [{
        label: 'Aircraft engine',
        value: 5
      }, {
        label: 'Airframe',
        value: 6
      }, {
        label: 'Helicopter',
        value: 7
      }, {
        label: 'Design',
        value: 25
      }, {
        label: 'Patent',
        value: 24
      }, {
        label: 'Plant breeder\'s right',
        value: 27
      }, {
        label: 'Trademark',
        value: 26
      }, {
        label: 'Motor vehicle',
        value: 2
      }, {
        label: 'Watercraft',
        value: 3
      }, {
        label: 'SmallAircraft',
        value: 8
      }];
      this.serialNumberType = {
        unsupported: 0,
        unknownMotorVehicleIdentifier: 1,
        VIN: 2,
        chassisNumber: 3,
        motorVehicleManufacturersNumber: 4,
        HIN: 5,
        officialNumber: 6,
        aircraftEngineManufacturersNumber: 7,
        airframeManufacturersNumber: 8,
        helicopterManufacturersNumber: 9,
        aircraftNationalityCodeAndRegistrationMark: 10,
        patentNumber: 11,
        tradeMarkNumber: 12,
        designNumber: 13,
        plantBreedersRightNumber: 14
      };
      this.organisationNumberType = {
        unsupported: 0,
        ACN: 1,
        ARBN: 2,
        ARSN: 3,
        ABN: 4,
        nameOnly: 5
      };
      this.grantorSearchFilterCollateralClassTypeAll = '2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29';
      this.serialNumberTypeOptions = [{
        title: 'Motor Vehicle VIN',
        id: 2
      }, {
        title: 'Motor Vehicle Chassis Number',
        id: 3
      }, {
        title: 'Motor Vehicle Manufacturer\'s Number',
        id: 4
      }, {
        title: 'Watercraft HIN',
        id: 5
      }, {
        title: 'Watercraft Official Number',
        id: 6
      }, {
        title: 'Aircraft Engine Manufacturer\'s Number',
        id: 7
      }, {
        title: 'Airframe Manufacturer\'s Number',
        id: 8
      }, {
        title: 'Helicopter Manufacturer\'s Number',
        id: 9
      }, {
        title: 'Aircraft Nationality Code & Reg. Mark',
        id: 10
      }, {
        title: 'Patent Number',
        id: 11
      }, {
        title: 'Trademark Number',
        id: 12
      }, {
        title: 'Design Number',
        id: 13
      }, {
        title: 'Plant Breeders Right Number',
        id: 14
      }];
      this.renewRegTimeOption = {
        sevenYear: 1,
        twentyFiveYear: 2,
        custom: 3,
        noDuration: 4
      };
      this.registrationExpiryOption = {
        notFiltered: 0,
        oneWeek: 1,
        threeWeeks: 2,
        oneMonth: 3,
        threeMonth: 4
      };
      this.regSearchByOptions = {
        searchAll: 1,
        regNumber: 10,
        serialNumber: 4,
        grantor: 3,
        collateral: 2,
        goni: 5,
        tradingName: 9,
        esisId: 8,
        fileId: 6,
        spgNumber: 12
      };
      //Search

      this.searchGridViewFilter = {
        registrationNumber: 'registrationNumber',
        grantor: 'grantor',
        serial: 'serial',
        error: 'error',
        waitingOnFunds: 'waitingOnFunds',
        all: 'all',
        unfinished: 'unfinished'
      };
      this.searchByOptions = {
        searchAll: 1,
        searchId: 3,
        criteria: 4,
        username: 8,
        reference: 9,
        fileId: 10
      };

      //Bulk File
      this.bulkFileGridViewFilter = {
        all: 'all',
        search: 'search',
        registration: 'registration',
        amendment: 'amendment',
        discharge: 'discharge'
      };
      this.fileType = {
        registrationCsv: 1,
        preloadCsv: 2,
        attachment: 3,
        attachmentBatch: 4,
        securedPartyGroupMigration: 5,
        registrationMigration: 6,
        registrationMigrationDischarge: 7,
        bulkRegistrationDischarge: 8,
        search: 9,
        registrationAmend: 10,
        invalid: 0,
        dataCleansing: 11,
        asicCompanyDataset: 12,
        registrationRenew: 16
      };

      //NZ
      this.nzFsGridSearchByList = {
        registrationNumber: 1,
        expiryDate: 2,
        registrationDate: 14
      };
      this.nzSPOrDebtorType = {
        person: 1,
        organisation: 2
      };
      this.nzFsDueToExpireFilterOption = {
        notFiltered: 0,
        oneWeek: 1,
        oneMonth: 2,
        threeMonth: 3,
        sixMonth: 4,
        customRange: 5,
        registrationDateCustomRange: 6
      };
      this.nzAddOrgDebtorType = {
        lookup: 1,
        manual: 2
      };
      this.nzFsExpiryDateOption = {
        fiveYears: 1,
        custom: 2
      };
      this.nzFsCollateralTypes = {
        aapp: 1,
        aappe: 2,
        acft: 3,
        cash: 4,
        cp: 5,
        crops: 6,
        dt: 7,
        int: 8,
        is: 9,
        ls: 10,
        mv: 11,
        ni: 12,
        oth: 13
      };
      this.nzFSStatus = {
        unknown: 0,
        registered: 100,
        discharged: 102,
        expired: 18,
        creating: 1,
        error: 2,
        amending: 4,
        pending: 3,
        mbieSuccess: 104,
        billed: 105,
        mbieSubmitted: 103
      };
      this.nzDebtorPpsrOrganisationType = {
        other: 8,
        partnership: 9,
        trust: 10
      };
      this.nzPhoneValidationMessage = 'Phone number is likely to be invalid';
      this.nzFaxValidationMessage = 'Fax is likely to be invalid';
      this.nzSearchGridViewFilter = {
        debtorPerson: 'debtorPerson',
        debtorOrganisation: 'debtorOrganisation',
        motorVehicle: 'motorVehicle',
        fsNumber: 'fsNumber',
        all: 'all'
      };
      this.nzSearchType = {
        unknown: 0,
        fsNumber: 1,
        debtorPerson: 2,
        debtorOrganisation: 3,
        aircraft: 4,
        motorVehicle: 5
      };
      this.nzSearchStatus = {
        active: 1,
        expired: 2
      };
      this.nzFileUploadGridViewFilter = {
        all: 'all',
        registration: 'registration',
        amendment: 'amendment',
        discharge: 'discharge',
        renew: 'renew'
      };
      this.nzFsDownloadFileType = {
        amend: 1,
        renew: 2,
        discharge: 3,
        extract: 4,
        create: 5
      };
      this.nzFileUploadStatus = {
        all: 0,
        processing: 5,
        processed: 6,
        partiallyProcessed: 20,
        failed: 2,
        rejected: 10
      };
      //endregion

      //region api endpoint
      this.apiEndpoint = {
        //region ACCOUNT
        logoutUser: 'logout',
        changeExpiredCredAccessKey: 'change-expired-cred-access-key',
        requestCredAccessKey: 'request-cred-access-key',
        resetCredAccessKey: 'reset-cred-access-key',
        agreeNzTc: 'agree-nz-tc',
        updateLastAccessEntity: 'update-last-access-entity',
        //endregion
        //region SYSTEM
        loadHelpTopic: 'help',
        saveHelpTopic: 'saveHelp',
        loadUserProfile: 'user-profile',
        loadBusinessEntityPicklist: 'business-entity-picklist',
        //endregion
        //region ADMIN
        loadSystemUserPreview: 'system-user-preview/{0}',
        loadSystemUserDetail: 'system-user-detail/{0}',
        updateLinkedBusinessEntitiesTreeForUser: 'update-user-linked-business-entity-tree',
        deleteUser: 'delete-user/{0}',
        transferUser: 'transfer-user/{0}/{1}/{2}',
        loadCurrentUserRoles: 'current-user-roles',
        changeCredAccessKey: 'change-cred-access-key',
        saveSystemUser: 'save-system-user',
        loadBranchCodeDigest: 'list-branch-codes',
        saveBranchCode: 'save-branch-code',
        loadEmailPreview: 'email/{0}',
        //region AU CATEGORY
        loadCategory: 'category/{0}',
        deleteCategory: 'delete-category/{0}',
        saveCategory: 'save-category',
        //endregion
        //region AU CONTRACT TYPE
        loadContractTypeDetailDigest: 'contract-type-detail-digest/{0}',
        deleteContractType: 'delete-contract-type/{0}',
        loadContractTypeListForCopying: 'contract-type-list-for-copying/{0}',
        loadContractTypeForCopying: 'contract-type-for-copying/{0}',
        loadContractType: 'contract-type/{0}',
        saveContractType: 'save-contract-type',
        //endregion
        //region NZ COLLATERAL PROFILE
        loadNzCollateralProfileDetailDigest: 'nz-contract-type-detail-digest/{0}',
        deleteNzCollateralProfile: 'delete-nz-contract-type/{0}',
        loadNzCollateralProfile: 'nz-contract-type/{0}',
        saveNzCollateralProfile: 'save-nz-contract-type',
        loadNzCollateralProfileDefaults: 'nz-contract-type-defaults/{0}',
        //endregion
        //region SECURITY ROLE
        loadSecurityRoleDetailDigest: 'security-role/{0}',
        saveSecurityRole: 'save-security-role',
        loadCurrentUserPermissions: 'current-user-permissions',
        //endregion
        //region BUSINESS ENTITY
        loadBusinessEntitiesForEditing: 'business-entity-picklist-for-editing',
        loadBusinessEntitiesTree: 'business-entities-tree/{0}',
        loadBusinessEntitiesFlat: 'business-entities-flat/{0}',
        loadBusinessEntityModel: 'business-entity/{0}',
        saveBusinessEntity: 'save-business-entity',
        updateBusinessEntityLinkedUser: 'update-business-entity-linked-user',
        grantorAbrOrgNameLookup: 'grantor-abr-lookup/{0}',
        //endregion
        //region AU TRANSACTION HISTORY
        downloadAuTransHistoryDetail: 'download-au-transaction-extract',
        downloadAuTransHistoryInvoiceSummary: 'download-au-invoice-summary-extract',
        //endregion
        //endregion
        //region AU REGISTRATION
        loadRegistrationDetailDigest: 'registration-detail-digest/{0}',
        downloadRegistrationAttachment: 'download-registration-attachment/{0}',
        downloadRegistrationVerificationReport: 'download-registration-verification-report/{0}',
        retrieveRegistration: 'retrieve-registration/{0}/{1}',
        dischargeRegistration: 'discharge-registration/{0}',
        loadRegistrationFees: 'get-registration-fees',
        createRegistrationAccountingRecord: 'create-registration-accounting-record',
        cancelDischarge: 'cancel-pending-discharge-registration/{0}',
        loadRegistration: 'registration/{0}',
        submitRegistration: 'submit-registration',
        approveDischargeRegistration: 'approve-discharge-registration/{0}',
        resubmitMyRegistrations: 'submit-waiting-on-funds-registrations-by-user',
        resubmitRegistration: 'submit-waiting-on-funds-registration/{0}',
        resubmitRegistrationByEntity: 'submit-waiting-on-funds-registrations-by-entity/{0}',
        deleteMyRegistrations: 'delete-my-registrations/{0}',
        deleteRegistrationsByEntity: 'delete-registrations-by-entity/{0}/{1}',
        deleteRegistration: 'delete-registration/{0}',
        loadExpiryMessage: 'expiry-message',
        saveRegistration: 'save-registration',
        validateAndSaveRegistration: 'validate-and-save-registration',
        loadContractTypeDefaults: 'contract-type-defaults/{0}',
        preSubmitNewRegistration: 'presubmit-new-registration/{0}',
        bulkDeleteRegistration: 'bulk-delete-registration',
        renewRegistration: 'renew-registration',
        validateRegistration: 'validate-registration',
        getRegistrationExpiryInfo: 'get-registration-expiry-info',
        loadRegistrationStatusCount: 'get-registration-status-count',
        loadRegistrationStatistics: 'registration-statistics/{0}',
        //endregion
        //region AU SEARCH
        loadSearchFees: 'get-search-fees/{0}',
        loadSearch: 'search/{0}',
        saveAndSubmitSearch: 'search',
        createSearchAccountingRecord: 'create-search-accounting-record/{0}',
        submitSearch: 'submit-search/{0}',
        searchDetailDigest: 'search-detail-digest/{0}',
        searchById: 'search/{0}',
        requestSearchCertificate: 'request-search-cert/{0}',
        requestSearchCertificateBulk: 'request-search-cert-bulk/{0}',
        downloadSearchCertificateBulk: 'download-search-cert-bulk/{0}',
        downloadSearchCertificate: 'download-search-cert/{0}',
        downloadSearchCertificateById: 'download-search-cert-by-id/{0}',
        downloadSearchExtractReport: 'download-search-result-extract-report/{0}',
        downloadSearchSummaryReport: 'download-search-result-summary-report/{0}',
        searchResultDetailDigest: 'search-result-detail-digest/{0}',
        searchRetrievalProgress: 'search-retrieval-progress/{0}',
        searchCertificateRetrievalProgress: 'search-cert-retrieval-progress/{0}',
        downloadSearchCertAttachment: 'download-search-cert-attachment/{0}',
        grantorSearchResultFilters: 'grantor-search-result-filters/{0}',
        resubmitMySearches: 'submit-waiting-on-funds-searches-by-user',
        resubmitSearch: 'submit-waiting-on-funds-search/{0}',
        resubmitSearchByEntity: 'submit-waiting-on-funds-searches-by-entity/{0}',
        deleteSearchByFileUploadId: 'delete-search-by-fileupload-id/{0}',
        deleteMySearches: 'delete-my-search/{0}',
        deleteSearchByEntity: 'delete-search-by-entity/{0}/{1}',
        deleteSearch: 'delete-search/{0}',
        downloadBulkSearchCertificateBundleByFile: 'download-bulk-search-certificate-bundle-by-file/{0}',
        bulkSearchCertificateBundleProgressByFile: 'bulk-search-certificate-bundle-progress-by-file/{0}',
        //endregion
        //region VALIDATION
        reverifyOrganisation: 'reverify-organisation/{0}',
        validateOrganisation: 'validate-organisation',
        validateEmail: 'validate-email',
        validateNzPhoneNumber: 'validate-nz-phone-number',
        //endregion
        //region PICKLISTS
        loadCategories: 'categories/{0}',
        loadPicklistGuid: 'picklists-guid/{0}',
        loadPicklist: 'picklists/{0}',
        loadPicklistDigest: 'picklist-digests/{0}',
        loadContractTypes: 'contract-types/{0}',
        loadNzContractTypes: 'nz-contract-types/{0}',
        loadSpgListForRegistration: 'secured-party-group-list-for-registration/{0}',
        loadSpgListForNzFs: 'nz-secured-party-group-by-entity/{0}',
        loadNzBranchCode: 'nz-branch-code/{0}',
        loadNzSpgListFromEntityDown: 'nz-spg-from-entity-down/{0}',
        loadFileTypes: 'bulk-file-types',
        loadNzBulkFileTypes: 'nz-bulk-file-type',
        loadNzBranchCodeByEntity: 'nz-branch-code-by-entity/{0}',
        //endregion
        //region AU SPG
        loadSpgModel: 'secured-party-group/{0}',
        loadSpgListByEntity: 'secured-party-group-list/{0}',
        loadSpgListForCopying: 'secured-party-group-list-for-copying/{0}',
        loadSpgPreview: 'secured-party-group-detail-digest/{0}',
        createSpgAccountingRecord: 'create-spg-accounting-record/{0}',
        saveSpg: 'save-secured-party-group',
        copySpg: 'copy-au-secured-party-group',
        submitSpg: 'submit-spg/{0}',
        retrieveSpg: 'retrieve-spg/{0}/{1}',
        validateAndSaveSpg: 'validate-and-save-spg',
        validateWizardSpg: 'validate-wizard-spg',
        deleteSpg: 'delete-spg/{0}',
        transferSpg: 'transfer-secured-party-group/{0}/{1}',
        convertSpgToDelegate: 'convert-secured-party-group-to-delegate/{0}',
        updatePpsrAccessCode: 'update-ppsr-access-code/{0}/{1}',
        //endregion
        //region NZ TRANSACTION HISTORY
        downloadNzTransHistoryDetail: 'download-nz-transaction-extract',
        downloadNzTransHistoryInvoiceSummary: 'download-nz-invoice-summary-extract',
        //endregion
        //region NZ SPG
        loadNzSpgList: 'list-nz-secured-party-groups',
        loadNzSpgModel: 'get-nz-secured-party-group/{0}',
        saveNzSpg: 'save-create-nz-secured-party-group',
        validateCreateNzSpg: 'validate-create-nz-secured-party-group',
        submitCreateNzSpg: 'submit-create-nz-secured-party-group',
        submitUpdateNzSpg: 'submit-update-nz-secured-party-group',
        nzAddressSearch: 'nzlookup/nz-address-search',
        nzAddressLookup: 'nzlookup/nz-address-lookup/{0}',
        nzBusinessSearch: 'nzlookup/nz-business-search',
        nzBusinessLookup: 'nzlookup/nz-business/{0}',
        nzTransferFinanceStatements: 'submit-transfer-nz-financing-statement',
        importNzSpg: 'import-nz-secured-party-group',
        //endregion
        //region NZ Financing Statement
        nzFsList: 'list-nz-fs',
        loadNzFsStatistics: 'nz-fs-statistics/{0}',
        saveNzDraftFs: 'save-nz-draft-fs',
        saveAmendNzFsDraft: 'save-amend-nz-fs-draft',
        loadNzDraftFs: 'get-nz-draft-fs',
        submitNewNzFs: 'submit-new-nz-fs',
        submitAmendNzFs: 'submit-amend-nz-fs',
        dischargeNzFs: 'discharge-nz-fs',
        loadNzFsModel: 'get-nz-fs',
        renewNzFs: 'renew-nz-fs',
        validateCreateNzFs: 'validate-create-nz-fs',
        loadNzExpiryDateDisplay: 'nz-expiry-date-display',
        loadNzDraftAmendFs: 'get-nz-draft-amend-fs',
        deleteNzDraftFs: 'delete-nz-draft-fs',
        //endregion
        //region NZ Search
        nzSearchList: 'nz-searches',
        submitSearchNzDebtorOrganisation: 'nz-debtor-organisation-search',
        submitSearchNzDebtorPerson: 'nz-debtor-person-search',
        submitSearchNzRegistrationNumber: 'nz-registration-number-search',
        submitSearchNzMV: 'nz-mv-search',
        loadNzSearchResult: 'nz-search-result',
        //endregion
        //region NZ File Upload
        saveNzBulkFile: 'save-nz-bulk-file',
        downloadNzFs: 'download-nz-fs',
        downloadNzFsV2: 'download-nz-fs-v2',
        loadDownloadNzFsProgress: 'load-download-nz-fs-progress-digest',
        downloadNzFsFile: 'download-nz-fs-file/{0}',
        cancelDownloadNzFs: 'cancel-download-nz-fs/{0}',
        loadNzBulkResult: 'nz-bulk-result',
        downloadNzBulkException: 'download-nz-bulk-exception/{0}',
        deleteNzBulkFile: 'delete-nz-bulk-file/{0}',
        //endregion
        //region GENERAL
        logError: 'logerror',
        uploadFile: 'uploadfile',
        clearServerCache: 'clearcache',
        deleteFile: 'delete-file/{0}',
        //endregion
        //region REPORT
        downloadRegistrationExtractReport: 'download-registration-extract-report',
        downloadAuRegistrationCountExtractReport: 'download-au-registration-count-extract',
        downloadRegistrationBySpgExtractReport: 'download-registration-by-spg-extract-report',
        downloadGrantorWithNoOrgNameExtract: 'download-grantor-with-no-org-name-extract/{0}',
        downloadSearchGrantorDiffExtractReport: 'download-search-grantor-diff-extract/{0}/{1}',
        downloadAccountEntityOverrideExtract: 'download-account-entity-override-extract/{0}',
        //endregion
        //region AU BULK FILE
        fileUpload: 'upload-file',
        saveFile: 'save-upload-file',
        loadFileUploadDigest: 'file-upload-detail-digest/{0}',
        loadFileUploadLineDigest: 'file-line-digest/{0}',
        getBulkFileLinkedIds: 'bulk-file-linked-ids/{0}',
        downloadFileUploaded: 'download-file-uploaded/{0}',
        downloadFileErrorDetails: 'download-file-uploaded-exception/{0}',
        downloadBulkExtractReport: 'download-bulk-extract-report/{0}',
        deleteFileUpload: 'delete-file-upload/{0}'
        //endregion
      };
      //endregion

      //region Enums
      this.transactionType = {
        registration: 1,
        discharge: 2,
        amendment: 3,
        transfer: 4,
        expired: 5
      };
      this.picklistType = {
        systemUserRolePicklist: 1,
        countryPicklist: 2,
        organisationNumberTypePicklist: 3,
        collateralTypePicklist: 4,
        collateralClassTypePicklist: 5,
        serialNumberTypePicklist: 6,
        entityClassesPicklist: 7,
        entityTypePicklist: 8,
        b2GAccountPicklist: 9,
        permissionPicklist: 10,
        nzFsFilterField: 11,
        nzFsCollateralType: 12,
        nzAircraftClass: 15,
        nzSpgFromEntityDownPicklist: 16,
        nzSearchFilterField: 17,
        fileType: 18,
        systemUserPicklist: 19
      };
      this.securedPartyGroupType = {
        standard: 1,
        delegate: 2,
        copyFromGroup: 3
      };
      this.securedPartyType = {
        organisation: 1,
        individual: 2
      };
      this.nzSecuredPartyType = {
        person: 1,
        organisation: 2
      };
      this.nzSearchFilterField = {
        searchExpiryDate: 2
      };
      this.permissions = {
        accessB2BApi: 'AccessB2BApi',
        admin: 'Admin',
        manageContractTypes: 'ManageContractTypes',
        manageEntity: 'ManageEntity',
        searchRecurring: 'SearchRecurring',
        viewAccounting: 'ViewAccounting',
        manageEntitySetting: 'ManageEntitySetting',
        viewSystemReport: 'ViewSystemReport',
        viewContractTypes: 'ViewContractTypes',
        viewEncryptedData: 'ViewEncryptedData',
        preload: 'Preload',
        abrLookup: 'AbrLookup',
        viewEntityPricing: 'ViewEntityPricing',
        manageEntityPricing: 'ManageEntityPricing',
        dataCleansing: 'DataCleansing',
        applyBulkCharge: 'ApplyBulkCharge',
        copyContract: 'CopyContract',
        manageUsers: 'ManageUsers',
        manageUserCredAccessKeyExpiry: 'ManageUserCredAccessKeyExpiry',
        viewEntity: 'ViewEntity',
        approveDischarge: 'ApproveDischarge',
        //AU
        bulkloadAu: 'BulkloadAU',
        dischargeAu: 'DischargeAU',
        manageSPGAu: 'ManageSPGAU',
        registerAu: 'RegisterAU',
        searchAu: 'SearchAU',
        viewAllSearchesAu: 'ViewAllSearchesAU',
        viewBulkloadAu: 'ViewBulkloadAU',
        viewMySearchesAu: 'ViewMySearchesAU',
        viewRegistrationsAu: 'ViewRegistrationsAU',
        viewSPGAu: 'ViewSPGAU',
        uploadMigrationFileAu: 'UploadMigrationFileAU',
        //NZ
        amendNz: "AmendNZ",
        bulkAmendNz: "BulkAmendNZ",
        bulkDischargeNz: "BulkDischargeNZ",
        bulkloadNz: "BulkloadNZ",
        bulkRegisterNz: "BulkRegisterNZ",
        bulkRenewNz: "BulkRenewNZ",
        dischargeNz: "DischargeNZ",
        manageBranchCodeNz: "ManageBranchcodeNZ",
        manageLinkedAccountsNz: "ManageLinkedAccountsNZ",
        manageSPGNz: "ManageSPGNZ",
        registerNz: "RegisterNZ",
        renewNz: "RenewNZ",
        searchNz: "SearchNZ",
        viewAndUseBranchCodeNz: "ViewAndUseBranchcodeNZ",
        viewBulkloadNz: "ViewBulkloadNZ",
        viewRegistrationsNz: "ViewRegistrationsNZ",
        viewSearchNz: "ViewSearchNZ",
        viewSPGNz: "ViewSPGNZ"
      };
      this.cacheType = {
        entityLookupCache: 'entityLookupCache',
        lookupCache: 'lookupCache',
        settingCache: 'settingCache',
        translationCache: 'translationCache'
      };
      this.connectionStatus = {
        unknown: 0,
        checking: 1,
        good: 2,
        bad: 3,
        broken: 4
      };
      //endregion

      //region events and notifications and chat message
      this.events = {
        addNewTab: 'addNewTab',
        tabResize: 'tabResize',
        splitterMoved: 'splitterMoved',
        queryParamsChanged: 'queryParamsChanged',
        notification: 'newNotification',
        chatMessage: 'newChatMessage',
        appVersionOutdated: 'applicationVersionOutdated',
        connectionStatusChanged: 'connectionStatusChanged',
        ppsrConnectionStatusChanged: 'ppsrConnectionStatusChanged',
        logout: 'userLogout',
        windowUnload: 'windowUnload',
        saveNote: 'saveNote',
        screenElementChanged: 'screenElementChanged',
        requestSearchCertificate: 'requestSearchCertificate',
        newTabNotification: 'newTabNotification',
        dismissTabNotification: 'dismissTabNotification',
        concurrencyError: 'concurrencyError',
        authenticationSessionUpdated: 'authenticationSessionUpdated',
        restoreTabs: 'restoreTabs'
      };
      this.notificationIdentifier = {
        //AU
        searchRequested: 100,
        searchSubmitted: 101,
        searchResultRetrieved: 102,
        searchCertificateRequested: 103,
        searchCertificateRetrieved: 104,
        searchCertificateRequestedBulk: 105,
        searchCertificateRetrievedBulk: 106,
        spgSubmitted: 107,
        spgConfirmed: 108,
        registrationCreateSubmitted: 109,
        registrationCreateConfirmed: 110,
        registrationAmendmentSubmitted: 111,
        registrationDischargeSubmitted: 112,
        registrationAmendmentConfirmed: 113,
        spgAmendmentSubmitted: 114,
        spgAmendmentConfirmed: 115,
        bulkSearchFileProcessed: 116,
        bulkRegistrationCreateFileProcessed: 117,
        bulkRegistrationAmendmentFileProcessed: 118,
        bulkRegistrationDischargeProcessed: 119,
        registrationDischargeConfirmed: 120,
        dataCleansing: 130,
        cgrAsicFileProcessed: 131,
        cgrAbrFileProcessed: 132,
        securedPartyGroupMigrateProcessed: 133,
        registrationMigrateProcessed: 134,
        registrationMigrateDischargeProcessed: 135,
        vinDataCleaseFileProcessed: 136,
        //NZ
        nzFinancingStatementCreateSubmitted: 137,
        nzFinancingStatementAmendSubmitted: 138,
        nzFinancingStatementCreateFailed: 139,
        nzFinancingStatementAmendFailed: 140,
        nzFsCreateBulkSubmitted: 145,
        nzFsAmendBulkSubmitted: 146,
        nzFsRenewBulkSubumitted: 147,
        nzFsDischargeBulkSubmitted: 148,
        nzFsCreateBulkProcessed: 149,
        nzFsAmendBulkProcessed: 150,
        nzFsRenewBulkProcessed: 151,
        nzFsDischargeBulkProcessed: 152,
        nzFsBulkFileRejected: 153
      };
      this.chatHubResponseCode = {
        ok: 0,
        errorCodeSystemError: 1,
        errorSubscribeToSession: 2,
        errorCodeJoiningChatSession: 3,
        errorUnsubscribeSession: 4,
        errorCodeSendMessage: 5,
        errorCodeListMessages: 6,
        errorCodeStartTypingNotification: 7,
        errorCodeStopTypingNotification: 8,
        errorCodeConnectionError: 9,
        errorCodeRequestSessionOutsideServiceHours: 10,
        errorCodeSessionEnded: 11,
        errorCancelChatSession: 12,
        errorAcknowledgeMessage: 13,
        errorSessionNotAvailable: 14
      };
      this.gridNotificationType = {
        add: 1,
        update: 2,
        delete: 3,
        requestAssign: 4,
        cancelChat: 5,
        requestPriorityChanged: 6
      };
      this.tabNotificationSeverity = {
        info: 1,
        warning: 2,
        error: 3
      };
      //endregion

      //region global error codes
      this.backendErrorCodes = {
        concurrencyFail: 2010
      };
      this.backendErrorSeverity = {
        verbose: 0,
        info: 1,
        warning: 2,
        error: 3,
        critical: 4
      };
      //endregion

      //region collateral defaults
      this.defaultAreProceedClaimedDescription = 'All present and after acquired property';
      //endregion

      //region system setting
      this.systemSetting = {
        timeDiscrepancy: 'timeDiscrepancy',
        connectionStatusPollingInterval: 'connectionStatusPollingInterval',
        maxTextCharacterCount: 'maxTextCharacterCount',
        archiveMode: 'archiveMode',
        maxFileUploadSizeBytes: 'maxFileUploadSizeBytes',
        maxFileCount: 'maxFileCount',
        allowedFileExtensions: 'allowedFileExtensions',
        showConnectionStatus: 'showConnectionStatus',
        showClientNote: 'showClientNote',
        allowAddEditClientNote: 'allowAddEditClientNote',
        supportAnonymousSessions: 'supportAnonymousSessions',
        enableMhrFunctions: 'enableMhrFunctions',
        enableWhiteboard: 'enableWhiteboard',
        allowEditSystemHelp: 'allowEditSystemHelp',
        countryCode: 'countryCode',
        calendarEndTime: 'calendarEndTime',
        calendarStartTime: 'calendarStartTime',
        calendarMinSlotInMinutes: 'calendarMinSlotInMinutes',
        helpDeploymentTarget: 'helpDeploymentTarget',
        datasetDeploymentTarget: 'datasetDeploymentTarget'
      };
      //endregion

      //region svg icons
      this.fIcon = {
        questionCircle: '<i><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="question-circle" class="svg-inline--fa fa-question-circle fa-w-16" role="img" viewBox="0 0 512 512">' + '<path fill="currentColor" d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z"/>' + '</svg></i>',
        exclamationTriangle: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation-triangle" class="svg-inline--fa fa-exclamation-triangle fa-w-18" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"/>' + '</svg></i>',
        check: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"/>' + '</svg></i>',
        play: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play" class="svg-inline--fa fa-play fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"/>' + '</svg></i>',
        user: '<i><svg viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" data-icon="user" data-prefix="fas" class="svg-inline--fa fa-user fa-w-14  ember-view">' + '<path fill="currentColor" d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>\n' + '</svg></i>',
        userShield: "<i><svg viewBox='0 0 640 512' xmlns='http://www.w3.org/2000/svg' role='img' aria-hidden='true' data-icon='user-shield' data-prefix='fas' class='treeViewIcon svg-inline--fa fa-user-shield fa-w-20 treeViewIcon ember-view'>" + "<path fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" d=\"M622.3 271.1l-115.2-45c-4.1-1.6-12.6-3.7-22.2 0l-115.2 45c-10.7 4.2-17.7 14-17.7 24.9 0 111.6 68.7 188.8 132.9 213.9 9.6 3.7 18 1.6 22.2 0C558.4 489.9 640 420.5 640 296c0-10.9-7-20.7-17.7-24.9zM496 462.4V273.3l95.5 37.3c-5.6 87.1-60.9 135.4-95.5 151.8zM224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm96 40c0-2.5.8-4.8 1.1-7.2-2.5-.1-4.9-.8-7.5-.8h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c6.8 0 13.3-1.5 19.2-4-54-42.9-99.2-116.7-99.2-212z\"/>\n" + "\n" + "</svg></i>",
        userTag: "<i>" + "<svg viewBox=\"0 0 640 512\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" aria-hidden=\"true\" data-icon=\"user-tag\" data-prefix=\"fas\" class=\"treeViewIcon svg-inline--fa fa-user-tag fa-w-20 treeViewIcon ember-view\">" + "<path fill=\"currentColor\" d=\"M630.6 364.9l-90.3-90.2c-12-12-28.3-18.7-45.3-18.7h-79.3c-17.7 0-32 14.3-32 32v79.2c0 17 6.7 33.2 18.7 45.2l90.3 90.2c12.5 12.5 32.8 12.5 45.3 0l92.5-92.5c12.6-12.5 12.6-32.7.1-45.2zm-182.8-21c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24c0 13.2-10.7 24-24 24zm-223.8-88c70.7 0 128-57.3 128-128C352 57.3 294.7 0 224 0S96 57.3 96 128c0 70.6 57.3 127.9 128 127.9zm127.8 111.2V294c-12.2-3.6-24.9-6.2-38.2-6.2h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 287.9 0 348.1 0 422.3v41.6c0 26.5 21.5 48 48 48h352c15.5 0 29.1-7.5 37.9-18.9l-58-58c-18.1-18.1-28.1-42.2-28.1-67.9z\"/>\n" + "</svg></i>",
        productHunt: "<i>" + "<svg viewBox=\"0 0 640 512\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" aria-hidden=\"true\" data-icon=\"product-hunt\" data-prefix=\"fab\" class=\"treeViewIcon svg-inline--fa fa-product-hunt fa-w-20 treeViewIcon ember-view\">" + "<path fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" d=\"M326.3 218.8c0 20.5-16.7 37.2-37.2 37.2h-70.3v-74.4h70.3c20.5 0 37.2 16.7 37.2 37.2zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-128.1-37.2c0-47.9-38.9-86.8-86.8-86.8H169.2v248h49.6v-74.4h70.3c47.9 0 86.8-38.9 86.8-86.8z\"/>\n" + "</svg></i>",
        home: "<i>" + "<svg viewBox=\"0 0 576 512\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" aria-hidden=\"true\" data-icon=\"home\" data-prefix=\"fas\" class=\"treeViewIcon svg-inline--fa fa-home fa-w-18 treeViewIcon ember-view\"><path fill=\"currentColor\" d=\"M488 312.7V456c0 13.3-10.7 24-24 24H348c-6.6 0-12-5.4-12-12V356c0-6.6-5.4-12-12-12h-72c-6.6 0-12 5.4-12 12v112c0 6.6-5.4 12-12 12H112c-13.3 0-24-10.7-24-24V312.7c0-3.6 1.6-7 4.4-9.3l188-154.8c4.4-3.6 10.8-3.6 15.3 0l188 154.8c2.7 2.3 4.3 5.7 4.3 9.3zm83.6-60.9L488 182.9V44.4c0-6.6-5.4-12-12-12h-56c-6.6 0-12 5.4-12 12V117l-89.5-73.7c-17.7-14.6-43.3-14.6-61 0L4.4 251.8c-5.1 4.2-5.8 11.8-1.6 16.9l25.5 31c4.2 5.1 11.8 5.8 16.9 1.6l235.2-193.7c4.4-3.6 10.8-3.6 15.3 0l235.2 193.7c5.1 4.2 12.7 3.5 16.9-1.6l25.5-31c4.2-5.2 3.4-12.7-1.7-16.9z\"></path>" + "</svg></i>",
        paw: "<i>" + "<svg viewBox=\"0 0 512 512\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" aria-hidden=\"true\" data-icon=\"paw\" data-prefix=\"fas\" class=\"treeViewIcon svg-inline--fa fa-paw fa-w-16 ember-view\">" + "<path fill=\"currentColor\" d=\"M85.231 330.958C36 330.958 0 273.792 0 231.5c0-28.292 16-58.042 49.538-58.042 49.231 0 85.231 57.458 85.231 99.75 0 28.292-15.692 57.75-49.538 57.75zm348 106.167c0 37.042-32 42.875-63.385 42.875-41.231 0-74.462-26.25-113.846-26.25-41.231 0-76.308 25.958-120.923 25.958-29.847 0-56.308-9.625-56.308-42.583C78.769 368 180.616 265.333 256 265.333s177.231 102.959 177.231 171.792zM182.462 203.792c-49.847 0-80-59.5-80-100.333C102.462 70.792 120.308 32 160 32c50.154 0 80 59.5 80 100.333 0 32.667-17.846 71.459-57.538 71.459zM272 132.333C272 91.5 301.846 32 352 32c39.692 0 57.539 38.792 57.539 71.458 0 40.833-30.154 100.333-80.001 100.333C289.846 203.792 272 165 272 132.333zM512 231.5c0 42.292-36 99.458-85.231 99.458-33.847 0-49.538-29.458-49.538-57.75 0-42.291 35.999-99.75 85.231-99.75C496 173.458 512 203.208 512 231.5z\"></path>\n" + "</svg></i>",
        caretDown: "<i>" + "<svg viewBox=\"0 0 320 512\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" aria-hidden=\"true\" data-icon=\"caret-down\" data-prefix=\"fas\" class=\"svg-inline--fa fa-caret-down fa-w-10  ember-view\"><path fill=\"currentColor\" d=\"M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z\"></path>\n" + "</svg></i>",
        bars: "<i>" + "<svg viewBox=\"0 0 448 512\" xmlns=\"http://www.w3.org/2000/svg\" aria-hidden=\"true\" data-prefix=\"fas\" data-icon=\"bars\" class=\"svg-inline--fa fa-bars fa-w-14\" role=\"img\" ><path fill=\"currentColor\" d=\"M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z\"/>" + "</svg></i>",
        assignToMe: "<i>" + "<svg viewBox=\"0 0 512 512\" xmlns=\"http://www.w3.org/2000/svg\" role=\"img\" aria-hidden=\"true\" data-icon=\"share\" data-prefix=\"fas\" class=\"smallIcon fa-flip-horizontal svg-inline--fa fa-share fa-w-16 smallIcon ember-view\"><path fill=\"currentColor\" d=\"M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z\"></path>\n" + "</svg></i>",
        assignToOther: '<i>' + '<svg id="ember953" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" role="img" aria-hidden="true" data-icon="share" data-prefix="fas" class="smallIcon svg-inline--fa fa-share fa-w-16 smallIcon ember-view"><path fill="currentColor" d="M503.691 189.836L327.687 37.851C312.281 24.546 288 35.347 288 56.015v80.053C127.371 137.907 0 170.1 0 322.326c0 61.441 39.581 122.309 83.333 154.132 13.653 9.931 33.111-2.533 28.077-18.631C66.066 312.814 132.917 274.316 288 272.085V360c0 20.7 24.3 31.453 39.687 18.164l176.004-152c11.071-9.562 11.086-26.753 0-36.328z"></path>\n' + '</svg></i>',
        release: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="external-link-alt" class="svg-inline--fa fa-external-link-alt fa-w-18" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M576 24v127.984c0 21.461-25.96 31.98-40.971 16.971l-35.707-35.709-243.523 243.523c-9.373 9.373-24.568 9.373-33.941 0l-22.627-22.627c-9.373-9.373-9.373-24.569 0-33.941L442.756 76.676l-35.703-35.705C391.982 25.9 402.656 0 424.024 0H552c13.255 0 24 10.745 24 24zM407.029 270.794l-16 16A23.999 23.999 0 0 0 384 303.765V448H64V128h264a24.003 24.003 0 0 0 16.97-7.029l16-16C376.089 89.851 365.381 64 344 64H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V287.764c0-21.382-25.852-32.09-40.971-16.97z"/>' + '</svg></i>',
        file: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="file" class="svg-inline--fa fa-file fa-w-12" role="img" viewBox="0 0 384 512"><path fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"/>' + '</svg></i>',
        task: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="tasks" class="svg-inline--fa fa-tasks fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M208 132h288c8.8 0 16-7.2 16-16V76c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16zm0 160h288c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16zm0 160h288c8.8 0 16-7.2 16-16v-40c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16v40c0 8.8 7.2 16 16 16zM64 368c-26.5 0-48.6 21.5-48.6 48s22.1 48 48.6 48 48-21.5 48-48-21.5-48-48-48zm92.5-299l-72.2 72.2-15.6 15.6c-4.7 4.7-12.9 4.7-17.6 0L3.5 109.4c-4.7-4.7-4.7-12.3 0-17l15.7-15.7c4.7-4.7 12.3-4.7 17 0l22.7 22.1 63.7-63.3c4.7-4.7 12.3-4.7 17 0l17 16.5c4.6 4.7 4.6 12.3-.1 17zm0 159.6l-72.2 72.2-15.7 15.7c-4.7 4.7-12.9 4.7-17.6 0L3.5 269c-4.7-4.7-4.7-12.3 0-17l15.7-15.7c4.7-4.7 12.3-4.7 17 0l22.7 22.1 63.7-63.7c4.7-4.7 12.3-4.7 17 0l17 17c4.6 4.6 4.6 12.2-.1 16.9z"/>' + '</svg></i>',
        squareFull: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="square-full" class="svg-inline--fa fa-square-full fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M512 512H0V0h512v512z"/>' + '</svg></i>',
        clock: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="far" data-icon="clock" class="svg-inline--fa fa-clock fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"/>' + '</svg></i>',
        signIn: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="sign-in-alt" class="svg-inline--fa fa-sign-in-alt fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M416 448h-84c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h84c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32h-84c-6.6 0-12-5.4-12-12V76c0-6.6 5.4-12 12-12h84c53 0 96 43 96 96v192c0 53-43 96-96 96zm-47-201L201 79c-15-15-41-4.5-41 17v96H24c-13.3 0-24 10.7-24 24v96c0 13.3 10.7 24 24 24h136v96c0 21.5 26 32 41 17l168-168c9.3-9.4 9.3-24.6 0-34z"/>' + '</svg></i>',
        edit: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="edit" class="svg-inline--fa fa-edit fa-w-18" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"/>' + '</svg></i>',
        arrowCircleRight: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" data-prefix="fas" data-icon="arrow-circle-right" class="svg-inline--fa fa-arrow-circle-right fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8c137 0 248 111 248 248S393 504 256 504 8 393 8 256 119 8 256 8zm-28.9 143.6l75.5 72.4H120c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24h182.6l-75.5 72.4c-9.7 9.3-9.9 24.8-.4 34.3l11 10.9c9.4 9.4 24.6 9.4 33.9 0L404.3 273c9.4-9.4 9.4-24.6 0-33.9L271.6 106.3c-9.4-9.4-24.6-9.4-33.9 0l-11 10.9c-9.5 9.6-9.3 25.1.4 34.4z"/>' + '</svg></i>',
        delete: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14" role="img" viewBox="0 0 448 512"><path fill="currentColor" d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"/>' + '</svg></i>',
        ellipsisV: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ellipsis-v" class="svg-inline--fa fa-ellipsis-v fa-w-6" role="img" viewBox="0 0 192 512"><path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"/>' + '</svg></i>',
        signOutAltLeft: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sign-out-alt" class="fa-rotate-180 svg-inline--fa fa-sign-out-alt fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M497 273L329 441c-15 15-41 4.5-41-17v-96H152c-13.3 0-24-10.7-24-24v-96c0-13.3 10.7-24 24-24h136V88c0-21.4 25.9-32 41-17l168 168c9.3 9.4 9.3 24.6 0 34zM192 436v-40c0-6.6-5.4-12-12-12H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h84c6.6 0 12-5.4 12-12V76c0-6.6-5.4-12-12-12H96c-53 0-96 43-96 96v192c0 53 43 96 96 96h84c6.6 0 12-5.4 12-12z"/>' + '</svg></i>',
        bullhorn: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="bullhorn" class="svg-inline--fa fa-bullhorn fa-w-18" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M576 240c0-23.63-12.95-44.04-32-55.12V32.01C544 23.26 537.02 0 512 0c-7.12 0-14.19 2.38-19.98 7.02l-85.03 68.03C364.28 109.19 310.66 128 256 128H64c-35.35 0-64 28.65-64 64v96c0 35.35 28.65 64 64 64h33.7c-1.39 10.48-2.18 21.14-2.18 32 0 39.77 9.26 77.35 25.56 110.94 5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56-16.4-21.52-26.15-48.36-26.15-77.44 0-11.11 1.62-21.79 4.41-32H256c54.66 0 108.28 18.81 150.98 52.95l85.03 68.03a32.023 32.023 0 0 0 19.98 7.02c24.92 0 32-22.78 32-32V295.13C563.05 284.04 576 263.63 576 240zm-96 141.42l-33.05-26.44C392.95 311.78 325.12 288 256 288v-96c69.12 0 136.95-23.78 190.95-66.98L480 98.58v282.84z"/>' + '</svg></i>',
        times: '<i>' + '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>' + '</svg></i>'
      };
      //endregion
    }
  });
  _exports.default = _default;
});