define("esis-ui/components/areas/shared/nz/nz-address-form/component", ["exports", "lodash", "ember-concurrency", "esis-ui/components/shared/shared-component-base"], function (_exports, _lodash, _emberConcurrency, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    address: null,
    showLarge: false,
    reviewMode: true,
    showClearBtn: false,
    _countries: null,
    init() {
      this._super(...arguments);
    },
    mandatory: false,
    required: Ember.computed('mandatory', function () {
      return this.get('mandatory') ? 'required' : '';
    }),
    loadPicklistData() {
      this._loadPicklists.perform();
    },
    copyAddress(sourceAddress) {
      this.get('address').setProperties({
        'addressLine1': sourceAddress.addressLine1,
        'suburb': sourceAddress.suburb,
        'cityTown': sourceAddress.cityTown,
        'postCode': sourceAddress.postCode,
        'countryCode': sourceAddress.countryCode,
        'countryDescription': sourceAddress.countryDescription
      });
      this.setSelectedCountry(sourceAddress.countryCode);
    },
    setSelectedCountry(countryCode) {
      this.set('_selectedCountry', (0, _lodash.find)(this.get('_countries'), item => {
        return item.item.countryCode === countryCode;
      }));
      return this.get('_selectedCountry');
    },
    selectDefaultCountry() {
      this.setSelectedCountry("NZ");
      this.set('address.countryDescription', this.get('_selectedCountry.title'));
      this.set('address.countryCode', "NZ");
    },
    //region Task
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        const constants = this.get('constants');
        const countries = yield this.get('picklistRepo.loadPicklistDigest').perform(constants.get('picklistType.countryPicklist'), false);
        this.set('_countries', countries);
        if (!(0, _lodash.isEmpty)(this.get('_countries'))) {
          const option = (0, _lodash.find)(this.get('_countries'), item => {
            return item.item.countryCode === this.get('address.countryCode');
          });
          if (!(0, _lodash.isEmpty)(option)) {
            this.set('address.countryDescription', option.title);
            this.set('_selectedCountry', option);
          } else {
            this.selectDefaultCountry();
          }
          this.get('address').save();
        }
      } catch (error) {
        this.get('logger').logError('nz-address-form._loadPicklists', error);
      }
    }),
    _loadAddressList: (0, _emberConcurrency.task)(function* () {
      try {
        let response = yield this.get('spgRepo.nzAddressSearch').perform({
          searchTerm: this.get('address.addressLine1')
        });
        if (!(0, _lodash.isEmpty)(this.get('loadTimeoutTaskInstance'))) {
          this.get('loadTimeoutTaskInstance').cancel();
          this.set('loadTimeoutTaskInstance', null);
        }
        this.set('_options', response);
      } catch (error) {
        if (!(0, _lodash.isEmpty)(this.get('loadTimeoutTaskInstance'))) {
          this.get('loadTimeoutTaskInstance').cancel();
          this.set('loadTimeoutTaskInstance', null);
        }
        this.set('_options', null);
        this.get('logger').logError('nz-address-form._loadAddressList', error);
      }
    }),
    _loadAddressDetails: (0, _emberConcurrency.task)(function* (addressId) {
      try {
        let response = yield this.get('spgRepo.nzAddressLookup').perform(addressId);
        this.get('address').setProperties({
          addressLine1: response.addressLine1,
          postCode: response.postCode,
          suburb: response.suburb,
          countryCode: response.countryCode,
          countryDescription: response.countryDescription,
          cityTown: response.cityTown
        });
        this.setSelectedCountry(response.countryCode);
      } catch (error) {
        this.get('logger').logError('nz-address-form._loadAddressDetails', error);
      }
    }),
    _runTimeout: (0, _emberConcurrency.task)(function* (taskInstance) {
      yield (0, _emberConcurrency.timeout)(10000);
      if (!(0, _lodash.isEmpty)(this.get(taskInstance))) {
        this.get(taskInstance).cancel();
        this.set(taskInstance, null);
      }
      this.set('_options', null);
      this.notifyError('Loading address timed out, please try again');
    }),
    //endregion

    actions: {
      _onCountryChanged: function _onCountryChanged(option) {
        this.set('address.countryCode', option.item.countryCode);
        this.set('address.countryDescription', option.title);
        this.set('_selectedCountry', option);
      },
      _clearAddress: function _clearAddress() {
        this.clearAddress();
      },
      _onAddressLookup() {
        let loadAddressTask = this.get('_loadAddressList');
        let timeoutTask = this.get('_runTimeout');
        let timeoutTaskInstance = timeoutTask.perform('loadAddressTaskInstance');
        this.set('loadTimeoutTaskInstance', timeoutTaskInstance);
        let loadAddressTaskInstance = loadAddressTask.perform();
        this.set('loadAddressTaskInstance', loadAddressTaskInstance);
      },
      _onSelectAddress(option) {
        this._loadAddressDetails.perform(option.value);
      }
    }
  });
  _exports.default = _default;
});