define("esis-ui/components/shared/command-bar-bottom/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jgcY4S7g",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"nav\",true],[10,\"role\",\"navigation\"],[10,\"class\",\"navbar navbar-default navbar-fixed-bottom\"],[8],[0,\"\\n    \"],[7,\"hr\",true],[10,\"class\",\"separator-line\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"style\",\"margin-top: 3px;\"],[8],[0,\"\\n\"],[0,\"            \"],[7,\"ul\",true],[10,\"class\",\"col-lg-11 col-md-11 nav navbar-nav navbar-right\"],[10,\"style\",\"padding-bottom:8px\"],[8],[0,\"\\n                \"],[7,\"div\",true],[10,\"style\",\"padding-right:5px;float: right;\"],[10,\"class\",\"navbar-button\"],[8],[0,\"\\n                    \"],[14,1],[0,\"\\n                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/command-bar-bottom/template.hbs"
    }
  });
  _exports.default = _default;
});