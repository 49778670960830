define("esis-ui/routes/sso", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    model(params) {
      this._super(...arguments);
      try {
        this.set('_errorMessage', 'Log in, please wait ...');
        this.get('session').authenticate('authenticator:esis-authenticator', this.get('credential.ssoId'), params.nonce, ['useraccess', 'offline_access']).catch(reason => {
          if (Ember.isPresent(reason)) {
            if (reason.error === 'InvalidSsoNonce') {
              this.set('_errorMessage', 'Invalid single sign on token. Please try again.');
            } else {
              this.set('_errorMessage', reason.error_description);
            }
          } else {
            this.set('_errorMessage', 'Failed to login. Unknown Error. Please try again.');
          }
        });
      } catch (error) {
        this.set('_errorMessage', error.error_description);
        this.get('logger').logError('reset-expired-cred-access-key._resetCredAccessKey', error);
      }
    }
  });
  _exports.default = _default;
});