define("esis-ui/components/areas/admin/business-entity/view/business-entity-detail-with-user/component", ["exports", "esis-ui/components/areas/shared/view-page-base", "ember-concurrency", "ember-lifeline"], function (_exports, _viewPageBase, _emberConcurrency, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _viewPageBase.default.extend({
    //region INITIALIZATION
    panelId: 'currentBusinessEntityPanel',
    detailsModel: null,
    //endregion

    //region TASKS
    _updateBusinessEntityLinkedUser: (0, _emberConcurrency.task)(function* (requestModel) {
      try {
        yield this.get('adminRepo.updateBusinessEntityLinkedUser').perform(requestModel);
        const constants = this.get('constants');
        this.get('cache').deleteLookup(this.getCacheKey(constants.cacheType.lookupCache, constants.picklistType.systemUserPicklist));
        this.notifySuccess(`Linked system users have been updated successfully`);
        this.get('onRefresh')();
      } catch (error) {
        this.get('logger').logError('business-entity-list._updateBusinessEntityLinkedUser', error);
      }
    }),
    //endregion

    //region METHODS
    loadData() {
      this.set('detailsModel', null);
      this.get('refreshCurrentBusinessEntityView')();
      this._loadLinkedUser();
    },
    _loadLinkedUser() {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('detailsModel'))) {
          this.get('_linkedUserList').loadListData(this.get('detailsModel.users'));
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._loadLinkedUser();
          }, 20);
        }
      });
    },
    //endregion

    //region ACTIONS
    actions: {
      _onSaveBusinessEntity() {
        this.get('onRefresh')();
      },
      _onEditClick() {
        this.get('_businessEntityEditModal').show(this.get('detailsModel'));
      },
      _onRemoveUserClick(selectedUser) {
        this.set('_userToRemove', selectedUser);
        this.set('_deleteLinkedUserConfirmMsg', "Are you sure you want to remove this user’s access to this Business Entity?");
        this.get('_linkedUserDeleteDlg').show();
      },
      _onConfirmRemoveEntity() {
        this.get('_updateBusinessEntityLinkedUser').perform({
          businessEntityGuid: this.get('detailsModel.guid'),
          systemUserGuid: this.get('_userToRemove.guid'),
          isAdding: false,
          recordVersion: this.get('detailsModel.recordVersion')
        });
        this.get('onRefresh')();
      },
      _onAddUserClick() {
        this.get('_businessEntityAddUserModal').show();
      },
      _onSaveAddedUser(model) {
        this.get('_updateBusinessEntityLinkedUser').perform({
          businessEntityGuid: this.get('detailsModel.guid'),
          systemUserGuid: model.value,
          isAdding: true,
          recordVersion: this.get('detailsModel.recordVersion')
        });
      }
    }
    //endregion
  });
  _exports.default = _default;
});