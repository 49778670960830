define("esis-ui/components/areas/registration/nz/wizard/nz-edit-collateral-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WePZMbrp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"registerAs\",\"modalTitle\",\"name\",\"renderInPlace\",\"onConfirm\",\"dismiss\"],[[24,[\"_dialog\"]],[24,[\"_modalTitle\"]],\"nzEditCollWizardModal\",[24,[\"renderInPlace\"]],[28,\"action\",[[23,0,[]],\"_onConfirm\"],null],false]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"role\",\"form\"],[10,\"class\",\"form-horizontal mt-3\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n      \"],[7,\"label\",true],[10,\"class\",\"control-label field-label col-3\"],[8],[0,\"Collateral Type\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-9 pl-0 mt-1\"],[8],[0,\"\\n        \"],[1,[24,[\"_collateral\",\"collateralTypeTitle\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"_collateral\",\"canHaveCollTypeDescription\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"form-group\"],[8],[0,\"\\n        \"],[7,\"label\",true],[11,\"class\",[29,[\"control-label field-label col-3 \",[28,\"if\",[[24,[\"_collateral\",\"mustHaveCollTypeDescription\"]],\"required\"],null]]]],[8],[0,\"Description\"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-9 pl-0\"],[8],[0,\"\\n          \"],[1,[28,\"textarea\",null,[[\"type\",\"rows\",\"value\",\"class\"],[\"text\",\"5\",[24,[\"_collateral\",\"collDescription\"]],\"form-control text-field\"]]],false],[0,\"\\n          \"],[7,\"small\",true],[10,\"class\",\"validationErrorMessage\"],[8],[1,[24,[\"_collateral\",\"errors\",\"collDescription\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/registration/nz/wizard/nz-edit-collateral-modal/template.hbs"
    }
  });
  _exports.default = _default;
});