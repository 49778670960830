define("esis-ui/components/areas/shared/page-base", ["exports", "esis-ui/components/shared/shared-component-base", "ember-lifeline"], function (_exports, _sharedComponentBase, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
  base component for all pages.
  Please NOTE there is diference between page and component, if a component is shared by multiple components then it is not a page.
  A page should be compnent who's direct parent is the tab container, and is not reused by any other page.
   */
  var _default = _sharedComponentBase.default.extend({
    classNames: ['page-base'],
    model: null,
    isDirty: Ember.computed('model.isDirty', 'pageIsDirty', function () {
      let isDirty = false;
      if (Ember.isPresent(this.get('model.isDirty'))) {
        isDirty = this.get('model.isDirty') === true;
      }
      if (!isDirty && Ember.isPresent(this.get('pageIsDirty'))) {
        isDirty = this.get('pageIsDirty') === true;
      }
      return isDirty;
    }),
    tabModel: null,
    // holder property to reference the parent tab container in type tab-model
    pageIsDirty: false,
    dirtyMessage: '',
    onScreenChange: function onScreenChange() {},
    isVisible: Ember.computed.alias('tabModel.isVisible'),
    init: function init() {
      this._super(...arguments);
      if (Ember.isPresent(this.get('tabModel'))) {
        /*
        assign the current page object to be the inner page object of the tab
        this is very important to tab base dirty page checking
         */
        let tabModel = this.get('tabModel');
        tabModel.set('innerPage', this);
      }
    },
    updateTabProperty(prop, value) {
      if (Ember.isPresent(this.get('tabModel'))) {
        (0, _emberLifeline.scheduleTask)(this, 'render', () => {
          this.set(`tabModel.${prop}`, value);
        });
      } else {
        this.get('logger').logWarning(`Update Dynamic Tab ${prop} failed, TabModel is not available`);
      }
    }
  });
  _exports.default = _default;
});