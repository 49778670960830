define("esis-ui/components/areas/registration/au/view/au-grantor-edit/component", ["exports", "esis-ui/components/shared/shared-component-base", "ember-concurrency", "lodash"], function (_exports, _sharedComponentBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    model: null,
    isConsumerCollateral: false,
    isExpress: false,
    init() {
      this._super(...arguments);
      this._randomGuid = this.get('app').guid();
      this._loadPicklists.perform();
    },
    _loadPicklists: (0, _emberConcurrency.task)(function* () {
      try {
        let organizationPicklist = yield this.get('picklistRepo.loadPicklist').perform(this.get('constants.picklistType.organisationNumberTypePicklist'), false);
        this.set('_organisationNumberTypeList', organizationPicklist);
        this.set('_selectedOrgNumberType', (0, _lodash.find)(this.get('_organisationNumberTypeList'), {
          'value': this.get('model.organisationNumberType')
        }));
      } catch (error) {
        this.get('logger').logError('au-grantor-edit._loadPicklist', error);
      }
    }),
    resetGrantor() {
      this.setProperties({
        _selectedOrgNumberType: null
        // model: null
      });
    },

    setOrgNumberTypeTitle(preferredOrganisationNumberType) {
      let numberType = (0, _lodash.find)(this.get('_organisationNumberTypeList'), {
        'value': preferredOrganisationNumberType
      });
      let organisationNumberTypeTitle = numberType ? numberType.title : '';
      this.set('model.organisationNumberTypeTitle', organisationNumberTypeTitle);
      // this.set('_selectedOrgNumberType', find(this.get('_organisationNumberTypeList'), {'value': this.get('model.organisationNumberType')}));
    },

    actions: {
      _onNumberTypeChange(selected) {
        this.set('_selectedOrgNumberType', selected);
        this.set('model.organisationNumberType', selected.value);
        this.set('model.organisationNumberTypeTitle', selected.title);
      },
      _onDobChanged(value) {
        this.set('model.individualDateOfBirth', value);
      },
      _onGrantorTypeChange(value) {
        this.set('model.grantorType', value);
      }
    }
  });
  _exports.default = _default;
});