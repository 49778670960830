define("esis-ui/models/nz-collateral-create-request", ["exports", "esis-ui/utils/model-base", "esis-ui/models/nz-collateral-details-create-request", "lodash"], function (_exports, _modelBase, _nzCollateralDetailsCreateRequest, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _nzCollateralDetailsCreateRequest.default.extend({
    collateralItems: (0, _modelBase.attr)(null, 'array'),
    canNotHaveCollItem: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.aapp || this.get('collateralType') === constants.nzFsCollateralTypes.cash || this.get('collateralType') === constants.nzFsCollateralTypes.crops || this.get('collateralType') === constants.nzFsCollateralTypes.dt || this.get('collateralType') === constants.nzFsCollateralTypes.int || this.get('collateralType') === constants.nzFsCollateralTypes.is || this.get('collateralType') === constants.nzFsCollateralTypes.ni || this.get('collateralType') === constants.nzFsCollateralTypes.ls || this.get('collateralType') === constants.nzFsCollateralTypes.cp;
    }),
    mustHaveCollItem: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.acft || this.get('collateralType') === constants.nzFsCollateralTypes.mv;
    }),
    optionalCollItem: Ember.computed('collateralType', function () {
      const constants = this.get('constants');
      return this.get('collateralType') === constants.nzFsCollateralTypes.aappe || this.get('collateralType') === constants.nzFsCollateralTypes.oth;
    }),
    validateModel() {
      if (this.get('action') === this.get('constants.actionType.remove')) {
        return true;
      }
      let validate = this.validate();
      if (!(0, _lodash.isEmpty)(this.get('collateralItems'))) {
        (0, _lodash.forEach)(this.get('collateralItems'), item => {
          if (!item.validate()) {
            validate = item.validate();
          }
        });
      }
      return validate;
    },
    saveModel: function saveModel() {
      this.save();
      const colItems = this.get('collateralItems');
      if (Ember.isPresent(colItems)) {
        (0, _lodash.forEach)(colItems, item => {
          item.save();
        });
      }
    },
    init() {
      this._super(...arguments);

      //Extending parent model validations
      this.validations.collateralItems = {
        custom: {
          validation: (key, value, model) => {
            if (model.get('action') !== this.get('constants.actionType.remove') && this.get('mustHaveCollItem')) {
              return Ember.isPresent((0, _lodash.find)(value, collItem => {
                return collItem.action !== this.get('constants.actionType.remove');
              }));
            }
            return true;
          },
          message: 'At least one collateral item should be added'
        }
      };
    }
  });
  _exports.default = _default;
});