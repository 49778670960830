define("esis-ui/services/event-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
      event service for pub - sub behaviour
      see following articles for examples
      https://emberigniter.com/communication-between-distant-components/
      https://www.netguru.co/blog/data-down-action-in-emberjs
      https://ember-twiddle.com/dc85ac9d49e49c5663a1?numColumns=3&openFiles=application.controller.js%2Ctodos-list.component.js%2Ckeyboard-dispatcher.service.js
   */
  var _default = Ember.Service.extend(Ember.Evented, {});
  _exports.default = _default;
});