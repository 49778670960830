define("esis-ui/components/shared/buttons/share-link-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iH1Bfwth",
    "block": "{\"symbols\":[],\"statements\":[[7,\"i\",true],[8],[1,[28,\"shared/copy-text\",null,[[\"icon\",\"copyValue\",\"title\"],[\"share-alt\",[24,[\"sharedLink\"]],\"Click to Copy the Link\"]]],false],[4,\"bs-popover\",null,[[\"title\",\"placement\"],[\"Shared Link\",\"bottom\"]],{\"statements\":[[1,[22,\"sharedLink\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/buttons/share-link-button/template.hbs"
    }
  });
  _exports.default = _default;
});