define("esis-ui/components/areas/shared/au-page-base", ["exports", "esis-ui/components/areas/shared/page-base"], function (_exports, _pageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _pageBase.default.extend({
    init() {
      this._super(...arguments);
      this._isCitecUser = this.get('credential.currentUser.isCitecUser') === true;
    }
  });
  _exports.default = _default;
});