define("esis-ui/app", ["exports", "esis-ui/resolver", "ember-load-initializers", "esis-ui/config/environment", "ember-ajax/request"], function (_exports, _resolver, _emberLoadInitializers, _environment, _request) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  //region setup kendo UI culture
  // set kendo culture hard code to Australia always
  kendo.culture("en-AU");
  //endregion

  //region Ember Application Bootstrap
  class App extends Ember.Application {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "modulePrefix", _environment.default.modulePrefix);
      _defineProperty(this, "podModulePrefix", _environment.default.podModulePrefix);
      _defineProperty(this, "Resolver", _resolver.default);
    }
  }
  _exports.default = App;
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  //endregion

  //region handle ember error globally
  Ember.onerror = function (error) {
    console.error('ESIS ember application error, unexpected :', error);
    try {
      let options = {};
      /*
      use withCredentials option to true to allow server response to set cookie value
      https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/withCredentials
       */
      options.crossDomain = true;
      options.xhrFields = {
        withCredentials: true
      };
      options.method = 'POST';
      options.contentType = "application/json";
      options.data = JSON.stringify({
        data: {
          error: error.stack,
          stack: error.stack ? error.stack : 'null',
          title: 'ESIS UI App Error : unhandled',
          props: ''
        }
      });
      let {
        beforeSend
      } = options;
      options.beforeSend = xhr => {
        if (beforeSend) {
          beforeSend(xhr);
        }
      };
      (0, _request.default)(`${_environment.default.apiBaseUrl}/${_environment.default.apiRoutePath}/logerror`, options).then(function () {
        console.debug('Error log submitted to server successfully');
      }, function (error) {
        console.error('ESIS Application : Server communication error while submitting error report', error.errors || error);
      });
    } catch (e) {
      console.error('Minerva Application Error - Cannot post error log back to server', e);
    }
  };
  //endregion
});