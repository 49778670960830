define("esis-ui/components/shared/kendo/spreadsheet-view/component", ["exports", "esis-ui/components/shared/shared-component-base", "lodash", "ember-lifeline"], function (_exports, _sharedComponentBase, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    attributeBindings: ['style'],
    style: Ember.String.htmlSafe("clear:both;width:99%;"),
    _spreadsheet: null,
    // internal reference to the jquery kendo treeview object
    _waitModal: null,
    // reference to a waiting modal dialog
    options: null,
    init() {
      this._super(...arguments);
      //region default options
      this._defaultOptions = {
        /*
        the spreadsheet is set ot fix height based on the current tab container height, if there are additional controls on the page, then should set offset
        to make sure the fix height calculation do not push the spreadsheet control beyond bottom of page.
         */
        heightOffset: 0,
        /*
        hide standard set of audi type fields, see _auditFields definition below
         */
        hideAuditFields: true,
        /*
        custom column header mapping, see translation page for example
         */
        colHeaderMap: null,
        spreadsheetOptions: {
          render: e => {
            const height = self.get('app.tabContainerHeight') - this.get('options.heightOffset');
            e.sender.element.innerHeight(height);
            e.sender.resize();
          },
          rowHeight: 25,
          rows: 500,
          toolbar: true,
          sheetsbar: false,
          sheets: [{
            name: 'activesheet',
            dataSource: {
              transport: {
                read: {
                  dataType: 'json',
                  contentType: "application/json",
                  beforeSend: xhr => {
                    xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
                  }
                },
                update: {
                  dataType: 'json',
                  type: 'POST',
                  contentType: "application/json",
                  beforeSend: xhr => {
                    xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
                  }
                },
                destroy: {
                  dataType: 'json',
                  type: 'POST',
                  contentType: "application/json",
                  beforeSend: xhr => {
                    xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
                  }
                },
                create: {
                  dataType: 'json',
                  type: 'POST',
                  contentType: "application/json",
                  beforeSend: xhr => {
                    xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
                  }
                },
                parameterMap: function parameterMap(data, operation) {
                  if (operation !== "read" && Ember.isPresent(data)) {
                    return kendo.stringify(data);
                  }
                }
              },
              batch: false,
              schema: {
                parse: data => {
                  if (data.isSuccess) {
                    return data.data;
                  }
                  this.notifyError(data.error.errorMessage);
                  throw data.error;
                },
                model: {
                  /*
                  the incoming model should have guid property to allow update insert delete to work
                  this is to allow spreadsheet control to determine which operation if data changed
                  if guid is not available, make sure to override this option
                   */
                  id: 'guid'
                }
              },
              /*
              https://docs.telerik.com/kendo-ui/api/javascript/data/datasource/events/requestend
               */
              requestEnd: () => {
                if (Ember.isPresent(this.get('_spreadsheet'))) {
                  this._styleHeaderRow();
                } else {
                  (0, _emberLifeline.runTask)(this, '_styleHeaderRow', 100);
                }
              }
            },
            rows: [{
              height: 30
            }]
          }]
        }
      };
      this._mergedOptions = this._defaultOptions;
      //endregion
      //region subscribe to resize events
      this.get('event').on(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').on(this.get('constants.events.splitterMoved'), this, 'resize');
      //endregion

      //region column character array
      this.set('_colArray', ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ']);
      /*
      these fields will be hidden if options.hideAuditField is set to true
       */
      this.set('_auditFields', ['id', 'guid', 'rowVersion', 'dateCreated', 'dateEdited', 'userCreated', 'userEdited']);
      //endregion
    },

    didInsertElement: function didInsertElement() {
      this._super(...arguments);
      let mergedOptions = {};
      (0, _lodash.merge)(mergedOptions, this.get('_defaultOptions'), this.get('options'));
      this.set('_mergedOptions', mergedOptions);
      const spreadsheet = this.$().kendoSpreadsheet(mergedOptions.spreadsheetOptions).data('kendoSpreadsheet');
      this.set('_spreadsheet', spreadsheet);
    },
    willDestroyElement() {
      this.get('event').off(this.get('constants.events.tabResize'), this, 'resize');
      this.get('event').off(this.get('constants.events.splitterMoved'), this, 'resize');
      if (Ember.isPresent(this.get('_spreadsheet'))) {
        this.get('_spreadsheet').destroy();
      }
      this._super(...arguments);
    },
    resize() {
      const spreadsheet = this.get('_spreadsheet');
      if (Ember.isPresent(spreadsheet)) {
        spreadsheet.resize();
      }
    },
    jsonValue() {
      const spreadsheet = this.get('_spreadsheet');
      if (Ember.isPresent(spreadsheet)) {
        spreadsheet.activeSheet().toJSON();
      }
      return null;
    },
    saveChanges() {
      const spreadsheet = this.get('_spreadsheet');
      if (Ember.isPresent(spreadsheet)) {
        this.get('logger').logDebug('Kendo spreadsheet save changes');
        this.get('_waitModal').show();
        spreadsheet.activeSheet().dataSource.sync().then(() => {
          this.notifySuccess('Spreadsheet saved successfully');
          this.refresh();
        }).always(() => {
          this.get('_waitModal').hide();
        });
      }
    },
    refresh() {
      const spreadsheet = this.get('_spreadsheet');
      if (Ember.isPresent(spreadsheet)) {
        this.get('logger').logDebug('Kendo spreadsheet refresh');
        spreadsheet.activeSheet().dataSource.read();
      }
    },
    _styleHeaderRow() {
      if (Ember.isPresent(this.get('_spreadsheet'))) {
        const colArray = this.get('_colArray');
        const activeSheet = this.get('_spreadsheet').activeSheet();
        const data = activeSheet.dataSource.data();
        if (Ember.isPresent(data)) {
          const dataColumnCount = Object.keys(data[0]).length - 4; // there are 4 built in property, _events, _handlers, uid, parent
          const header = activeSheet.range(`A1:${colArray[dataColumnCount - 1]}1`);
          header.background("#F7F7F9");
          header.textAlign("center");
          header.bold(true);
          activeSheet.frozenRows(1);

          /*
          set custom row header
          https://docs.telerik.com/kendo-ui/knowledge-base/spreadsheet-rename-headers-from-datasource
           */
          const colHeaderMap = this.get('_mergedOptions.colHeaderMap');
          if (Ember.isPresent(colHeaderMap)) {
            const colHeaderKeys = Object.keys(colHeaderMap);
            activeSheet.batch(function () {
              for (let i = 0; i < dataColumnCount; i++) {
                const cell = activeSheet.range(`${colArray[i]}1`);
                if (colHeaderKeys.includes(cell.value())) {
                  cell.value(colHeaderMap.get(cell.value()));
                }
              }
            }, {
              recalc: true
            });
          }
          let hiddenCount = 0;
          if (this.get('_mergedOptions.hideAuditFields')) {
            for (let i = 0; i < dataColumnCount; i++) {
              const cell = activeSheet.range(`${colArray[i]}1`);
              if (this.get('_auditFields').includes(cell.value())) {
                activeSheet.hideColumn(i);
                hiddenCount++;
              }
            }
          }

          /* set filter range
          https://docs.telerik.com/kendo-ui/api/javascript/spreadsheet/range/methods/filter
           */
          activeSheet.range(`A1:${colArray[dataColumnCount - hiddenCount - 1]}${data.length + 1}`).filter(true);
        }
      }
    }
  });
  _exports.default = _default;
});