define("esis-ui/repositories/spg-repository", ["exports", "esis-ui/repositories/communication-base", "ember-concurrency", "lodash"], function (_exports, _communicationBase, _emberConcurrency, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _communicationBase.default.extend({
    //region REPO
    loadSpgPreview: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSpgPreview'), guid));
    }),
    loadSpgModel: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSpgModel'), guid));
    }),
    loadSpPreview: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadSpgPreview'), guid));
    }),
    _cloneSpgSaveRequest(spgSaveRequest) {
      let copy = spgSaveRequest.clone();
      copy.hasChange = spgSaveRequest.get('isDirty');
      if (!(0, _lodash.isEmpty)(spgSaveRequest.get('addressForServiceMailingAddress'))) {
        copy.addressForServiceMailingAddress = spgSaveRequest.get('addressForServiceMailingAddress').clone();
        copy.addressForServiceMailingAddress.id = spgSaveRequest.get('addressForServiceMailingAddress.id');
        copy.addressForServiceMailingAddress.hasChange = spgSaveRequest.get('addressForServiceMailingAddress.isDirty');
      }
      if (!(0, _lodash.isEmpty)(spgSaveRequest.get('addressForServicePhysicalAddress'))) {
        copy.addressForServicePhysicalAddress = spgSaveRequest.get('addressForServicePhysicalAddress').clone();
        copy.addressForServicePhysicalAddress.id = spgSaveRequest.get('addressForServicePhysicalAddress.id');
        copy.addressForServicePhysicalAddress.hasChange = spgSaveRequest.get('addressForServicePhysicalAddress.isDirty');
      }
      if (!(0, _lodash.isEmpty)(spgSaveRequest.get('securedParties'))) {
        copy.securedParties = spgSaveRequest.get('securedParties').map(function (item) {
          let param = item.clone();
          param.id = item.get('id');
          param.hasChange = item.get('securedPartyIsDirty');
          if (!(0, _lodash.isEmpty)(item.get('mailingAddress'))) {
            param.mailingAddress = item.get('mailingAddress').clone();
            param.mailingAddress.id = item.get('mailingAddress.id');
            param.mailingAddress.hasChange = item.get('mailingAddress.isDirty');
          }
          return param;
        });
      }
      return copy;
    },
    saveSecuredPartyGroup: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      let copy = this._cloneSpgSaveRequest(spgSaveRequest);
      return yield this.apiPost(this.get('constants.apiEndpoint.saveSpg'), copy, false);
    }).drop(),
    validateAndSaveSpg: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      let copy = this._cloneSpgSaveRequest(spgSaveRequest);
      return yield this.apiPost(this.get('constants.apiEndpoint.validateAndSaveSpg'), copy, true);
    }).drop(),
    validateWizardSpg: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      let copy = this._cloneSpgSaveRequest(spgSaveRequest);
      return yield this.apiPost(this.get('constants.apiEndpoint.validateWizardSpg'), copy, false);
    }).drop(),
    updatePpsrAccessCode: (0, _emberConcurrency.task)(function* (guid, ppsrAccessCode) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.updatePpsrAccessCode'), guid, ppsrAccessCode));
    }).drop(),
    //region NZ
    loadNzSpgList: (0, _emberConcurrency.task)(function* (filterModel) {
      return yield this.apiPost(this.get('constants.apiEndpoint.loadNzSpgList'), filterModel, false);
    }),
    loadNzSpgModel: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.loadNzSpgModel'), guid));
    }),
    saveNzSpg: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.saveNzSpg'), spgSaveRequest.clone(), false);
    }),
    _cloneNzSpgSaveRequest(spgSaveRequest) {
      let copy = spgSaveRequest.clone();
      if (!(0, _lodash.isEmpty)(spgSaveRequest.get('configSettings'))) {
        copy.configSettings = spgSaveRequest.get('configSettings').clone();
      }
      if (!(0, _lodash.isEmpty)(spgSaveRequest.get('securedParties'))) {
        copy.securedParties = spgSaveRequest.get('securedParties').map(function (item) {
          let param = item.clone();
          if (!(0, _lodash.isEmpty)(item.get('mailingAddress'))) {
            param.mailingAddress = item.get('mailingAddress').clone();
          }
          if (!(0, _lodash.isEmpty)(item.get('contactAddress'))) {
            param.contactAddress = item.get('contactAddress').clone();
          }
          if (!(0, _lodash.isEmpty)(item.get('personActingOnBehalf'))) {
            param.personActingOnBehalf = item.get('personActingOnBehalf').clone();
            if (!(0, _lodash.isEmpty)(item.get('personActingOnBehalf.contactAddress'))) {
              param.personActingOnBehalf.contactAddress = item.get('personActingOnBehalf.contactAddress').clone();
            }
            if (!(0, _lodash.isEmpty)(item.get('personActingOnBehalf.mailingAddress'))) {
              param.personActingOnBehalf.mailingAddress = item.get('personActingOnBehalf.mailingAddress').clone();
            }
          }
          return param;
        });
      }
      return copy;
    },
    validateCreateNzSpg: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      let copy = this._cloneNzSpgSaveRequest(spgSaveRequest);
      return yield this.apiPost(this.get('constants.apiEndpoint.validateCreateNzSpg'), copy, true);
    }),
    submitCreateNzSpg: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      let copy = this._cloneNzSpgSaveRequest(spgSaveRequest);
      return yield this.apiPost(this.get('constants.apiEndpoint.submitCreateNzSpg'), copy, true);
    }),
    submitUpdateNzSpg: (0, _emberConcurrency.task)(function* (spgSaveRequest) {
      let copy = this._cloneNzSpgSaveRequest(spgSaveRequest);
      return yield this.apiPost(this.get('constants.apiEndpoint.submitUpdateNzSpg'), copy, true);
    }),
    nzAddressSearch: (0, _emberConcurrency.task)(function* (nzAddressSearchRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.nzAddressSearch'), nzAddressSearchRequest, true);
    }),
    nzAddressLookup: (0, _emberConcurrency.task)(function* (addressId) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.nzAddressLookup'), addressId));
    }),
    nzBusinessSearch: (0, _emberConcurrency.task)(function* (nzBusinessSearchRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.nzBusinessSearch'), nzBusinessSearchRequest, true);
    }),
    nzBusinessLookup: (0, _emberConcurrency.task)(function* (nzbn) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.nzBusinessLookup'), nzbn));
    }),
    nzTransferFinanceStatements: (0, _emberConcurrency.task)(function* (nzTransferFinanceStatementsRequest) {
      return yield this.apiPost(this.get('constants.apiEndpoint.nzTransferFinanceStatements'), nzTransferFinanceStatementsRequest.clone(), true);
    }),
    importNzSpg: (0, _emberConcurrency.task)(function* (request) {
      return yield this.apiPost(this.get('constants.apiEndpoint.importNzSpg'), request.clone(), true);
    }),
    //endregion
    //endregion

    //region Command
    createSpgAccountingRecord: (0, _emberConcurrency.task)(function* (id) {
      // expect id as Secured Party Group Id
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.createSpgAccountingRecord'), id));
    }),
    submitSpg: (0, _emberConcurrency.task)(function* (guid) {
      // expect id as Secured Party Group Id
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.submitSpg'), guid), true);
    }),
    retrieveSpg: (0, _emberConcurrency.task)(function* (guid, forceRefresh) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.retrieveSpg'), guid, forceRefresh));
    }),
    deleteSpg: (0, _emberConcurrency.task)(function* (guid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.deleteSpg'), guid));
    }),
    transferSpg: (0, _emberConcurrency.task)(function* (spgGuid, targetBusinessEntity) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.transferSpg'), spgGuid, targetBusinessEntity));
    }),
    convertSpgToDelegate: (0, _emberConcurrency.task)(function* (spgGuid) {
      return yield this.apiGet(this.fmt(this.get('constants.apiEndpoint.convertSpgToDelegate'), spgGuid));
    }),
    copySpg: (0, _emberConcurrency.task)(function* (request) {
      return yield this.apiPost(this.get('constants.apiEndpoint.copySpg'), request, true);
    })
    //endregion
  });
  _exports.default = _default;
});