define("esis-ui/components/areas/registration/au/wizard/au-registration-grantor-step/component", ["exports", "esis-ui/components/shared/ember-bootstrap/wizard-control/wizard-step-base", "lodash"], function (_exports, _wizardStepBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _wizardStepBase.default.extend({
    onActivate() {
      if (!this.get('model.noGrantor') && (0, _lodash.isEmpty)(this.get('model.grantors'))) {
        this.get('_grantorAddModal').show();
      }
    },
    _grantorsLastIndex: Ember.computed('model.grantors.@each', function () {
      return this.get('model.grantors').length - 1;
    }),
    validateStep() {
      let model = this.get('model');
      model.set('errors', []);
      let errors = this.get('model.errors');
      if (!model.get('noGrantor') && (0, _lodash.isEmpty)(this.get('model.grantors'))) {
        errors.set('grantor', 'At least one grantor should be added');
        return false;
      } else if (!model.get('noGrantor') && !(0, _lodash.isNil)(this.get('grantor'))) {
        errors.set('grantor', "Grantor is not added/updated");
        return false;
      }
      errors.set('grantor', '');
      return true;
    },
    actions: {
      _onClickEdit(grantor, index) {
        grantor.set('indexId', index);
        this.get('_grantorAddModal').show(grantor);
      },
      _onClickAdd() {
        this.get('_grantorAddModal').show();
      },
      _onClickDelete(grantor) {
        this.get('model.grantors').removeObject(grantor);
        // this.set('grantor', null);
        // this.set('model.errors', []);
        this.set('model.itemDeleted', true);
      },
      cancel() {
        // this.set('grantor', null);
        // this.set('model.errors', []);
      },
      _onUpdateGrantor(grantor) {
        if (!(0, _lodash.isEmpty)(this.get('model.grantors'))) {
          let updatedGrantor = this.get('model.grantors').objectAt(grantor.indexId);
          this.mapObject(grantor, updatedGrantor);
        }
      },
      _onAddGrantor(grantor) {
        if ((0, _lodash.isNil)(this.get('model.grantors'))) {
          this.set('model.grantors', []);
        }
        grantor.set('sequenceNumber', this.get('model.grantors').length);
        this.get('model.grantors').unshiftObject(grantor);
      }
    }
  });
  _exports.default = _default;
});