define("esis-ui/components/areas/search/au/list/au-search-list/component", ["exports", "esis-ui/components/areas/shared/au-page-base", "ember-concurrency", "lodash", "ember-lifeline"], function (_exports, _auPageBase, _emberConcurrency, _lodash, _emberLifeline) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _auPageBase.default.extend({
    //region properties
    _searchGrid: null,
    _selectedSearch: null,
    _mySearches: true,
    //region INIT
    init() {
      this._super(...arguments);
      const app = this.get('app');
      const constants = this.get('constants');

      // add split panels
      this.set('_splitterPanels', ['#topPanel', '#bottomPanel']);
      this.set('_splitterOptions', {
        sizes: [45, 55],
        minSize: [350, 250]
      });
      this._loadPicklists();

      //region REGISTRATION GRID
      this.set('_gridOption', {
        dataSource: {
          transport: {
            read: {
              url: app.getOdataApiUrl('searches')
            },
            parameterMap: (options, operation) => {
              options.viewName = this.get('_view');
              options.businessEntityGuid = this.get('credential.currentBusinessEntity.value');
              options.mySearch = this.get('_mySearches');
              options.statusId = this.get('_selectedStatus.value');
              let keyword = this.get('_filterCriteria');
              if (keyword) {
                options.filterScope = this.get('_searchBy.value');
                options.filterKeyword = keyword;
              }
              options._ = this.newGuid();
              return kendo.data.transports["odata-v4"].parameterMap(options, operation);
            }
          },
          schema: {
            data: _data => {
              let realData = _data.value;
              for (let i = 0; i < realData.length; i++) {
                if (realData[i].status === constants.statusValue.submitted || realData[i].status === constants.statusValue.confirmed) {
                  realData[i].class = 'submittedStatusIconColor';
                  realData[i].iconName = 'check';
                } else if (realData[i].status === constants.statusValue.pending || realData[i].status === constants.statusValue.pendingAccountingTransaction) {
                  realData[i].class = 'pendingStatusIconColor';
                  realData[i].iconName = 'play';
                } else if (realData[i].status === constants.statusValue.expired) {
                  realData[i].class = 'expiredStatusIconColor';
                  realData[i].iconName = 'exclamationTriangle';
                } else {
                  realData[i].class = 'otherStatusIconColor';
                  realData[i].iconName = 'exclamationTriangle';
                }
                if (realData[i].isScheduledSearch) {
                  realData[i].showScheduledSearch = 'inline';
                } else {
                  realData[i].showScheduledSearch = 'none';
                }
                realData[i].searchDateDisplay = this.dateTimeAsStr(realData[i].searchDate);

                //context menu hide logic
                let enableResumbit = realData[i].status === constants.statusValue.waitingOnFunds && this.get('_view') === constants.searchGridViewFilter.waitingOnFunds;
                let enableDelete = realData[i].canDelete;
                // order is important. It must be the same as action options order (_searchGridContextMenu below)
                realData[i].hideActionStates = [!enableResumbit, false];
                realData[i].disableActionStates = [!enableResumbit, !enableDelete];
              }
              return realData;
            }
          },
          sort: [{
            field: "esisId",
            dir: "desc"
          }]
        },
        columns: [{
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "20px",
          attributes: {
            "class": 'actionMenuFieldStyle',
            "style": "text-align: center"
          },
          headerAttributes: {
            "class": "actionMenuFieldStyle"
          },
          template: `<span class="actionMenuStyle">${this.get('constants.fIcon.ellipsisV')}</span>`
        }, {
          field: "id",
          title: ' ',
          filterable: false,
          sortable: false,
          width: "45px",
          template: dataItem => {
            return `<span class="${(0, _lodash.isEmpty)(dataItem.class) ? 'unKnownStatusIconColor' : dataItem.class}">` + this.get('constants.fIcon')[dataItem.iconName] + `</span>`;
          }
        }, {
          field: "esisId",
          title: "Search ID",
          filterable: false,
          width: "130px",
          template: '<a data-id="#:guid#" data-multi="#:isMultiSearch#" data-type="#:searchTypeId#" data-org="#:isOrganisationSearch#" class="grid-link-id">#: esisId #</a>'
        }, {
          field: "searchTypeTitle",
          title: "Type",
          width: "140px",
          filterable: false
        }, {
          field: "searchCriteria",
          title: "Criteria",
          filterable: false
        }, {
          field: "reference",
          title: "Reference",
          filterable: false
        }, {
          field: "resultCount",
          title: "Result Count",
          width: "80px",
          filterable: false
        }, {
          field: "userName",
          title: "User",
          width: "100px",
          filterable: false
        }, {
          field: "searchDate",
          title: "Date",
          width: "150px",
          filterable: false,
          template: '#: searchDateDisplay #'
        }, {
          field: "statusTitle",
          title: "Status",
          width: "130px",
          filterable: false
        }, {
          field: "certificateStatusTitle",
          title: "Certificates",
          width: "100px",
          filterable: false
        }]
      });

      //Context Menu Action Ids
      this._resubmitActionId = 'resubmitSearchId';
      this._deleteActionId = 'deleteSearchId';
      this._searchGridContextMenu = [{
        title: 'Resubmit',
        id: this.get('_resubmitActionId'),
        icon: 'square-full',
        iconClass: 'primaryCtxMenuOpt'
      }, {
        title: 'Delete',
        id: this.get('_deleteActionId'),
        icon: 'trash-alt',
        iconClass: 'dangerCtxMenuOpt'
      }];
      //endregion
      // this._refreshGrid();
    },

    //endregion

    //region COMPUTED PROPERTIES
    _showResubmitAllCommand: Ember.computed('_view', '_isCitecUser', function () {
      return this.get('_isCitecUser') && this.get('_view') === this.get('constants.searchGridViewFilter.waitingOnFunds');
    }),
    _showDeleteAllCommand: Ember.computed('_view', function () {
      const constants = this.get('constants');
      return this.get('_view') === constants.searchGridViewFilter.waitingOnFunds || this.get('_view') === constants.searchGridViewFilter.error || this.get('_view') === constants.searchGridViewFilter.all;
    }),
    _displayViewName: Ember.computed('_view', function () {
      const constants = this.get('constants');
      return this.get('_view') === constants.searchGridViewFilter.all ? '' : (0, _lodash.find)(this.get('_viewFilterOptions'), {
        value: this.get('_view')
      }).title;
    }),
    _disabledStatusPicklist: Ember.computed('_view', function () {
      const constants = this.get('constants');
      return this.get('_view') !== constants.searchGridViewFilter.all && this.get('_view') !== constants.searchGridViewFilter.unfinished;
    }),
    //endregion

    //region METHODS
    _refreshGrid(reset = false) {
      (0, _emberLifeline.runTask)(this, () => {
        if (Ember.isPresent(this.get('_searchGrid'))) {
          if (reset === true) {
            this.set('_selectedSearch', null);
          }
          this.get('_searchGrid').refreshGrid(reset);
        } else {
          (0, _emberLifeline.runTask)(this, () => {
            this._refreshGrid(reset);
          }, 20);
        }
      });
    },
    _onDischarged() {
      this._refreshGrid();
    },
    _openSearchTab: function _openSearchTab(guid, searchType, isMultiSearch, isOrganisationSearch) {
      const constants = this.get('constants');
      let tabName = '';
      if (searchType === constants.searchType.searchByRegistrationNumber) {
        tabName = 'searchByRegistrationNumberAu'; //constants.pagePath.searchByRegistrationNumberAu;
      } else if (searchType === constants.searchType.searchBySerialNumber) {
        if (isMultiSearch) {
          tabName = 'searchByMvAu'; //constants.pagePath.searchByMvAu;
        } else {
          tabName = 'searchBySerialAu'; //constants.pagePath.searchBySerialAu;
        }
      } else if (searchType === constants.searchType.searchByGrantor || searchType === constants.searchType.searchByGrantorAndEventDateRange) {
        if (isOrganisationSearch) {
          tabName = 'searchByOrganisationAu'; //constants.pagePath.searchByOrganisationAu;
        } else {
          tabName = 'searchByIndividualAu'; //constants.pagePath.searchByIndividualAu;
        }
      } else {
        this.get('_searchTypeDlg').show('Search type is not supported');
        return;
      }
      this.addTab(tabName, guid);
    },
    _loadPicklists() {
      const constants = this.get('constants');
      //SEARCH LIST
      this.set('_searchByOptions', [{
        title: 'Search All',
        value: 1
      }, {
        title: 'Search ID',
        value: 3
      }, {
        title: 'Criteria',
        value: 4
      }, {
        title: 'Username',
        value: 8
      }, {
        title: 'Reference',
        value: 9
      }, {
        title: 'File ID',
        value: 10
      }]);
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.searchBy'))) {
        this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
          value: this.get('tabModel.options.searchBy')
        }));
      } else {
        this.set('_searchBy', (0, _lodash.find)(this.get('_searchByOptions'), {
          value: 1
        }));
      }

      //FILTER CRITERIA
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.filterCriteria'))) {
        this.set('_filterCriteria', this.get('tabModel.options.filterCriteria'));
      }
      //VIEW FILTERS
      const options = [{
        title: 'Unfinished',
        value: constants.searchGridViewFilter.unfinished
      }, {
        title: 'Error',
        value: constants.searchGridViewFilter.error
      }, {
        title: 'Registration Number',
        value: constants.searchGridViewFilter.registrationNumber
      }, {
        title: 'Grantor',
        value: constants.searchGridViewFilter.grantor
      }, {
        title: 'Serialised',
        value: constants.searchGridViewFilter.serial
      }, {
        title: 'All',
        value: constants.searchGridViewFilter.all
      }];
      this.set('_viewFilterOptions', options);
      if (Ember.isPresent(this.get('tabModel.options')) && Ember.isPresent(this.get('tabModel.options.viewName'))) {
        this.set('_view', this.get('tabModel.options.viewName'));
      } else {
        this.set('_view', constants.searchGridViewFilter.all);
      }

      //STATUS
      this.set('_statusList', [{
        title: 'All Statuses',
        value: 0
      }, {
        title: 'Creating',
        value: constants.statusValue.creating
      }, {
        title: 'Pending',
        value: constants.statusValue.pending
      }, {
        title: 'Waiting On Funds',
        value: constants.statusValue.waitingOnFunds
      }]);
      this.set('_selectedStatus', this.get('_statusList')[0]);
    },
    //endregion

    //region TASK

    _loadPreviewTask: (0, _emberConcurrency.task)(function* () {
      try {
        const response = yield this.get('searchRepo.loadSearchDigest').linked().perform(this.get('_selectedSearch.guid'));
        this.set('_detailDigest', response);
      } catch (error) {
        this.get('logger').logError('au-search-list._loadPreviewTask', error);
      }
    }),
    _resubmitMySearches: (0, _emberConcurrency.task)(function* () {
      try {
        const submitResponse = yield this.get('searchRepo.resubmitMySearches').linked().perform();
        if (submitResponse.failedCount === 0) {
          this.notifySuccess(`All your Waiting On Funds searches has submitted successfully`);
          this._refreshGrid(true);
        } else {
          if (submitResponse.successCount === 0) {
            this.notifyError('Fail to re-submit any searches');
          } else {
            let resubmitMessage = Ember.String.htmlSafe(`${this.get('submitResponse.successCount')} searches submitted successfully.<br>
                                                    ${this.get('submitResponse.failedCount')} searches failed`);
            this.notifyWarn(resubmitMessage);
            this._refreshGrid(true);
          }
        }
      } catch (error) {
        this.get('logger').logError('au-search-list._resubmitMySearches', error);
        this.notifyError('Fail to re-submit any searches');
      }
    }),
    _resubmitSearch: (0, _emberConcurrency.task)(function* () {
      try {
        let submitResponse = yield this.get('searchRepo.resubmitSearch').linked().perform(this.get('_selectedSearch.guid'));
        if (submitResponse.failedCount === 0) {
          this.notifySuccess(`Search ${this.get('_selectedSearch.id')} submitted successfully`);
          this._refreshGrid(true);
        } else {
          this.notifyError(`Fail to re-submit search ${this.get('_selectedSearch.esisId')}`);
        }
      } catch (error) {
        this.get('logger').logError('au-search-list._resubmitSearch', error);
        this.notifyError(`Fail to re-submit the search ${this.get('_selectedSearch.esisId')}`);
      }
    }),
    _resubmitSearchByEntityTask: (0, _emberConcurrency.task)(function* () {
      try {
        let submitResponse = yield this.get('searchRepo.resubmitSearchByEntity').perform(this.get('credential.currentBusinessEntityGuid'));
        if (submitResponse.failedCount === 0) {
          this.notifySuccess(`All Waiting On Funds searches for ${this.get('credential.currentBusinessEntity.title')} have been submitted`);
          this._refreshGrid(true);
        } else {
          if (submitResponse.successCount === 0) {
            this.notifyError('Fail to re-submit any searches');
          } else {
            let resubmitMessage = Ember.String.htmlSafe(`${submitResponse.successCount} searches submitted successfully.<br>
                                                    ${submitResponse.failedCount} searches failed`);
            this.notifyWarn(resubmitMessage);
            this._refreshGrid(true);
          }
        }
      } catch (error) {
        this.get('logger').logError('au-search-list._resubmitAllSearchByEntityTask', error);
        this.notifyError('Fail to re-submit any searches');
      }
    }),
    _deleteMySearchesTask: (0, _emberConcurrency.task)(function* (isMine = false) {
      try {
        yield this.get('searchRepo.deleteMySearches').perform(this.get('_view'));
        let successMessage = isMine ? `All your ${this.get('_displayViewName')} searches have been deleted` : `All your ${this.get('_displayViewName')} searches for ${this.get('credential.currentBusinessEntity.item.id')} have been deleted`;
        this.notifySuccess(successMessage);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-search-list._deleteMySearchesTask', error);
        this.notifyError('Fail to delete all my searches');
      }
    }),
    _deleteSearchesByEntityTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.deleteSearchesByEntity').perform(this.get('credential.currentBusinessEntityGuid'), this.get('_view'));
        this.notifySuccess(`All ${this.get('_displayViewName')} searches for ${this.get('credential.currentBusinessEntity.title')} have been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-search-list._deleteSearchesByEntityTask', error);
        this.notifyError(`Fail to delete draft searches for ${this.get('credential.currentBusinessEntity.title')}`);
      }
    }),
    _deleteSearchTask: (0, _emberConcurrency.task)(function* () {
      try {
        yield this.get('searchRepo.deleteSearch').perform(this.get('_selectedSearch.guid'));
        this.notifySuccess(`Search ${this.get('_selectedSearch.esisId')} has been deleted`);
        this._refreshGrid(true);
      } catch (error) {
        this.get('logger').logError('au-search-list._deleteSearchTask', error);
        this.notifyError('Fail to delete search');
      }
    }),
    //endregion

    //region when tab activate
    onScreenChange: function onScreenChange(optionChanged, options) {
      if (optionChanged === true && Ember.isPresent(options)) {
        this.setProperties({
          _searchBy: Ember.isPresent(options.searchBy) ? (0, _lodash.find)(this.get('_searchByOptions'), {
            value: options.searchBy
          }) : this.get('_searchByOptions')[0],
          _mySearches: true,
          _selectedStatus: this.get('_statusList')[0],
          _view: Ember.isPresent(options.viewName) ? options.viewName : this.get('constants.searchGridViewFilter.all'),
          _filterCriteria: Ember.isPresent(options.filterCriteria) ? options.filterCriteria : ''
        });
        this._refreshGrid(true);
        return;
      }
      this._refreshGrid(); // soft refresh
    },

    //endregion

    //region ACTIONS
    actions: {
      //region GRID ACTIONS
      _onItemSelectionChange: function _onItemSelectionChange(data) {
        if ((0, _lodash.isNil)(this.get('_selectedSearch')) && (0, _lodash.isNil)(data)) {
          return; // no need to set selected row if no row selected.
        }

        this.set('_selectedSearch', data);
        if (!(0, _lodash.isNil)(this.get('_selectedSearch'))) {
          this.get('_loadPreviewTask').perform();
        }
      },
      _onLinkClick: function _onLinkClick(selectedItem) {
        const guid = selectedItem.data('id');
        const isMultiSearch = selectedItem.data('multi');
        const searchType = selectedItem.data('type');
        const isOrganisationSearch = selectedItem.data('org');
        this._openSearchTab(guid, searchType, isMultiSearch, isOrganisationSearch);
      },
      _openDetailTab: function _openDetailTab(guid) {
        let model = this.get('_selectedSearch');
        if (model.get('guid') === guid) {
          this._openSearchTab(model.get('guid'), model.get('searchTypeId'), model.get('isMultiSearch'), model.get('isOrganisationSearch'));
        }
      },
      _onRefreshData() {
        this._refreshGrid(true);
      },
      _noticeGridIsLoadingData(isGridLoadingData) {
        this.set('_isGridLoadingData', isGridLoadingData);
      },
      _onMenuSelect(selectedOption, selectedRow) {
        if (Ember.isPresent(selectedRow)) {
          this.set('_selectedSearch', selectedRow);
          if (selectedOption === this.get('_resubmitActionId')) {
            this.set('_resubmitConfirmMsg', `Are you sure you want to resubmit search ${this.get('_selectedSearch.esisId')}?`);
            this.get('_resubmitConfirmDlg').show();
          } else if (selectedOption === this.get('_deleteActionId')) {
            if (!(0, _lodash.isNil)(this.get('_selectedSearch.guid'))) {
              this.set('_deleteConfirmMsg', `Are you sure you want to delete search ${this.get('_selectedSearch.esisId')}? This action is not reversible`);
              this.get('_deleteConfirmDlg').show();
            }
          }
        }
      },
      _onCreateClick() {
        this.get('_getCreateSearchTypeModal').show();
      },
      _onConfirmCreateSearch(searchType) {
        this.addTab(searchType);
      },
      //endregion

      //region FILTER ACTIONS
      _searchByChanged: function _searchByChanged(option) {
        this.set('_searchBy', option);
        if (this.get('_filterCriteria')) {
          this._refreshGrid(true);
        }
      },
      _onViewFilterChange: function _onViewFilterChange(view) {
        if (!(0, _lodash.isEmpty)(view.value)) {
          this.set('_view', view.value);
          const constants = this.get('constants');
          if (view.value !== constants.searchGridViewFilter.unfinished && view.value !== constants.searchGridViewFilter.all) {
            this.set('_selectedStatus', this.get('_statusList')[0]);
          }
          this._refreshGrid(true);
        }
      },
      _onSearch: function _onSearch(value) {
        this.set('_filterCriteria', value);
        this.set('_view', 'all');
        this.set('_selectedStatus', this.get('_statusList')[0]);
        this._refreshGrid(true);
      },
      _onMySearchChange(value) {
        this.set('_mySearches', value);
        this._refreshGrid();
      },
      _onChangeStatus(selected) {
        this.set('_selectedStatus', selected);
        this._refreshGrid();
      },
      //endregion

      //region BUTTON ACTIONS

      _resubmitAll: function _resubmitAll() {
        if (this.get('credential.hasViewAllSearchPermissionAu')) {
          if (!(0, _lodash.isEmpty)(this.get('_getResubmitTypeModal'))) {
            this.set('_resubmitModalMessage', Ember.String.htmlSafe(`You are about to resubmit all <strong>Waiting On Funds</strong> searches to PPSR.
                   Please select from options below:`));
            this.get('_getResubmitTypeModal').show();
          }
        } else {
          this.notifyInfo('Submitting all your Waiting On Funds searches to PPSR ... this may take up to a minute');
          this.get('_resubmitMySearches').perform();
        }
      },
      _onGetResubmitType: function _onGetResubmitType(actionType) {
        if ((0, _lodash.isEmpty)(actionType)) {
          this.set('_resubmitTypeError', 'Please select resubmit option');
        } else {
          this.get('_getResubmitTypeModal').hide();
          this.set('_resubmitTypeError', '');
          if (actionType === 'mine') {
            this.notifyInfo('Submitting all your Waiting On Funds searches to PPSR ... this may take up to a minute');
            this.get('_resubmitMySearches').perform();
          } else {
            this.notifyInfo('Submitting all Waiting On Funds searches to PPSR ... this may take up to a minute');
            this.get('_resubmitSearchByEntityTask').perform();
          }
        }
      },
      _deleteAll: function _deleteAll() {
        if (this.get('credential.hasViewAllSearchPermissionAu')) {
          if (!(0, _lodash.isEmpty)(this.get('_getDeleteTypeModal'))) {
            this.set('_deleteModalMessage', Ember.String.htmlSafe(`Are you sure you want to delete all <strong>${this.get('_displayViewName')}</strong> searches that were not yet submitted to PPSR? This action is not reversible. Please select from options below:`));
            this.get('_getDeleteTypeModal').show();
          }
        } else {
          this.set('_deleteAllConfirmMsg', 'Are you sure you want to delete all your searches?');
          this.get('_deleteAllSearchesConfirmDlg').show();
        }
      },
      _onGetDeleteType: function _onGetDeleteType(actionType) {
        if ((0, _lodash.isEmpty)(actionType)) {
          this.set('_deleteTypeError', 'Please select delete option');
        } else {
          this.get('_getDeleteTypeModal').hide();
          this.set('_deleteTypeError', '');
          if (actionType === 'mine') {
            this.get('_deleteMySearchesTask').perform(true);
          } else {
            this.get('_deleteSearchesByEntityTask').perform();
          }
        }
      },
      _onConfirmDeleteAllMySearch() {
        this.get('_deleteMySearchesTask').perform();
      },
      _onConfirmResubmit() {
        this.notifyInfo('Submitting search to PPSR ... this may take up to a minute');
        this.get('_resubmitSearch').perform();
      },
      _onConfirmDelete() {
        this.get('_deleteSearchTask').perform();
      }
      //endregion

      //endregion
    }

    //endregion
  });
  _exports.default = _default;
});