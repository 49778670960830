define("esis-ui/repositories/communication-base", ["exports", "esis-ui/mixins/esis-utils", "file-saver", "ember-ajax/request", "ember-ajax/raw"], function (_exports, _esisUtils, _fileSaver, _request, _raw) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Object.extend(_esisUtils.default, {
    event: Ember.inject.service('event-service'),
    // <editor-fold desc="Base API GET / POST Call">
    /*
    call server api endpoint to load information of issue a command
    return ember concurrency task
     */
    apiGet: function apiGet(endpoint, suppressValidationError = false) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        (0, _request.default)(this.get('app').getApiUrl(endpoint), this._authorizedAjaxOptions('GET')).then(response => {
          this._processServerResponse(resolve, reject, response, suppressValidationError);
        }, error => {
          this._onServerCommunicationError(reject, error, endpoint, null);
        });
      });
    },
    /*
    call server api endpoint and send information for insert or update
    return ember concurrency task
     */
    apiPost(endpoint, data, suppressValidationError) {
      const postData = this.prepareDataForPost(data);
      return new Ember.RSVP.Promise((resolve, reject) => {
        (0, _request.default)(this.get('app').getApiUrl(endpoint), this._authorizedAjaxOptions('POST', {
          data: postData
        })).then(response => {
          this._processServerResponse(resolve, reject, response, suppressValidationError);
        }, error => {
          this._onServerCommunicationError(reject, error, endpoint, postData);
        });
      });
    },
    _processServerResponse(resolve, reject, response, suppressValidationError) {
      const constants = this.get('constants');
      if (response.isSuccess) {
        resolve(response.data);
      } else {
        if (response.errorCode === constants.backendErrorCodes.concurrencyFail) {
          // concurrency error
          this.get('event').trigger(constants.events.concurrencyError, 'Your update was not successful as the data has been updated by another user. Please refresh your data before attempting to update it again.');
        } else if (response.errorSeverity && response.errorSeverity === constants.backendErrorSeverity.critical) {
          this.notifyError(response.errors.join('|'));
        } else if (!suppressValidationError) {
          switch (response.errorSeverity || constants.backendErrorSeverity.error) {
            case constants.backendErrorSeverity.error:
              {
                this.notifyError(response.errors.join('|'));
                break;
              }
            case constants.backendErrorSeverity.warning:
              {
                this.notifyWarn(response.errors.join('|'));
                break;
              }
            case constants.backendErrorSeverity.info:
              {
                this.notifyInfo(response.errors.join('|'));
                break;
              }
          }
        }
        reject({
          errorSeverity: response.errorSeverity || constants.backendErrorSeverity.error,
          errorCode: response.errorCode,
          errorMessage: response.errors
        });
      }
    },
    _onServerCommunicationError(reject, error, endpoint, postData) {
      this.get('logger').logError(`Server api call failed, ${endpoint}`, error, postData);
      this.notifyError('Server communication failed, please check your internet connection. If problem persists, please contact your network administrator');
      reject(error.errors || error);
    },
    /*
      */
    _authorizedAjaxOptions: function _authorizedAjaxOptions(method, args) {
      let hash = {};
      let {
        beforeSend
      } = hash;
      hash.method = method || "GET";
      hash.dataType = "json";
      hash.cache = false;
      if (method === "POST" && args) {
        hash.contentType = "application/json";
        hash.data = JSON.stringify(args.data);
      }
      hash.beforeSend = xhr => {
        xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
        if (beforeSend) {
          beforeSend(xhr);
        }
      };
      hash.statusCode = {
        401: () => {
          if (this.get('isAuthenticated')) {
            this.get('session').invalidate();
          }
        }
      };
      return hash;
    },
    /*
         used to prepare data object for submission to server
         we wanted to fix date issue here.
     */
    prepareDataForPost: function prepareDataForPost(data) {
      data = this.prepareDataForPostInner(data);
      return data;
    },
    /*
      */
    prepareDataForPostInner: function prepareDataForPostInner(data) {
      // recursive function to walk the object tree.
      const propType = Ember.typeOf(data);
      if (propType === 'date') {
        // console.log('prepareDataForPost reformat date object', data, this.serialiseDateOnly(data));
        data = this.serialiseDateOnly(data);
      } else if (propType === 'object' || propType === 'instance' || propType === 'class') {
        for (let key in data) {
          // data[key] = this.prepareDataForPostInner(data[key]); // recursively go through the complex object
          Ember.set(data, key, this.prepareDataForPostInner(data[key]));
        }
      } else if (propType === 'array') {
        // eslint-disable-next-line no-unused-vars
        data.forEach(item => {
          this.prepareDataForPostInner(item);
        });
      }
      return data;
    },
    //region DOWNLOAD FILE
    downloadFile: function downloadFile(endpoint, suppressValidationError = false) {
      const result = new Ember.RSVP.Promise((resolve, reject) => {
        (0, _raw.default)(`${this.get('app').getApiUrl(endpoint)}`, this.ajaxDownloadOptions(`${this.get('app').getApiUrl(endpoint)}`, 'GET')).then(response => {
          _fileSaver.default.saveAs(new Blob([response.response], {
            type: this.getMimeTypeFromXhr(response.jqXHR)
          }), this.getFileNameFromXhr(response.jqXHR));
          resolve(true);
        }, error => {
          this.get('logger').logError(`Failed to download file, ${endpoint}`, endpoint);
          if (!suppressValidationError) {
            let message = '';
            if (Ember.isPresent(error.payload)) {
              message = error.payload.message;
            }
            this.notifyError(`Failed to download file. ${message}`);
          }
          reject(error.errors || error);
        });
      });
      return result;
    },
    downloadFilePost: function downloadFilePost(endpoint, args, suppressValidationError = false) {
      const postData = this.prepareDataForPost(args);
      return new Ember.RSVP.Promise((resolve, reject) => {
        (0, _raw.default)(`${this.get('app').getApiUrl(endpoint)}`, this.ajaxDownloadOptions(`${this.get('app').getApiUrl(endpoint)}`, 'POST', postData)).then(response => {
          _fileSaver.default.saveAs(new Blob([response.response], {
            type: this.getMimeTypeFromXhr(response.jqXHR)
          }), this.getFileNameFromXhr(response.jqXHR));
          resolve(true);
        }, error => {
          this.get('logger').logError(`Failed to download file, ${endpoint}`, endpoint);
          if (!suppressValidationError) {
            let message = '';
            if (Ember.isPresent(error.payload)) {
              message = error.payload.message;
            }
            this.notifyError(`Failed to download file. ${message}`);
          }
          reject(error.errors || error);
        });
      });
    },
    previewFile: function previewFile(endpoint) {
      const result = new Ember.RSVP.Promise((resolve, reject) => {
        (0, _raw.default)(`${this.get('app').getApiUrl(endpoint)}`, this.ajaxDownloadOptions(`${this.get('app').getApiUrl(endpoint)}`, 'GET')).then(response => {
          //FileSaver.saveAs(new Blob([response.response], {type: this.getMimeTypeFromXhr(response.jqXHR)}), this.getFileNameFromXhr(response.jqXHR));
          //resolve(true);
          const responseBlob = new Blob([response.response], {
            type: this.getMimeTypeFromXhr(response.jqXHR)
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(responseBlob, this.getFileNameFromXhr(response.jqXHR));
          } else {
            const url = URL.createObjectURL(responseBlob, this.getFileNameFromXhr(response.jqXHR));
            window.open(url);
          }
        }, error => {
          this.get('logger').logError(`Failed to download PDF document, ${endpoint}`, endpoint);
          let message = '';
          if (Ember.isPresent(error.payload)) {
            message = error.payload.message;
          }
          this.notifyError(`Failed to download file. ${message}`);
          reject(error.errors || error);
        });
      });
      return result;
    },
    ajaxDownloadOptions: function ajaxDownloadOptions(url, method, args) {
      let hash = {};
      let {
        beforeSend
      } = hash;
      hash.url = url;
      hash.method = method;
      if (method === "POST" && args) {
        hash.contentType = "application/json";
        hash.data = JSON.stringify(args);
      }
      hash.dataType = 'arraybuffer';
      hash.processData = false;
      hash.cache = false;
      hash.beforeSend = xhr => {
        xhr.setRequestHeader('Authorization', `Bearer ${this.getCurrentAccessToken()}`);
        if (beforeSend) {
          beforeSend(xhr);
        }
      };
      hash.statusCode = {
        401: () => {
          if (this.get('session.isAuthenticated')) {
            this.get('session').invalidate();
          }
        }
      };
      return hash;
    },
    getFileNameFromXhr: function getFileNameFromXhr(xhr) {
      let filename = "file";
      const disposition = xhr.getResponseHeader('Content-Disposition');
      if (disposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '');
        }
      }
      return filename;
    },
    getMimeTypeFromXhr: function getMimeTypeFromXhr(xhr) {
      const mimeType = xhr.getResponseHeader('Content-Type');
      return mimeType;
    }
    //region
    // </editor-fold>
  });
  _exports.default = _default;
});