define("esis-ui/components/shared/ember-bootstrap/modal/confirm-deletion-dialog/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZCA9c8VN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"shared/ember-bootstrap/modal/modal-dialog\",null,[[\"modalTitle\",\"onConfirm\",\"onCancel\",\"registerAs\",\"cancelBtnLabel\",\"confirmBtnLabel\",\"renderInPlace\"],[[24,[\"modalTitle\"]],[28,\"action\",[[23,0,[]],\"confirm\"],null],[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"_dialog\"]],[24,[\"cancelBtnLabel\"]],[24,[\"confirmBtnLabel\"]],[24,[\"renderInPlace\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-1\"],[8],[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"exclamation-triangle\"],[[\"fixedWidth\",\"class\",\"size\"],[true,\"warnIcon\",\"lg\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-11\"],[8],[0,\"\\n      \"],[1,[22,\"confirmMsg\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/ember-bootstrap/modal/confirm-deletion-dialog/template.hbs"
    }
  });
  _exports.default = _default;
});