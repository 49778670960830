define("esis-ui/models/file-upload-line-digest", ["exports", "esis-ui/utils/model-base"], function (_exports, _modelBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modelBase.Model.extend({
    id: (0, _modelBase.attr)(),
    lineNumber: (0, _modelBase.attr)(),
    status: (0, _modelBase.attr)(),
    statusTitle: (0, _modelBase.attr)(),
    statusMessage: (0, _modelBase.attr)(),
    linkedInstanceIds: (0, _modelBase.attr)(),
    rowColor: Ember.computed('status', function () {
      const constants = this.get('constants');
      if (Ember.isPresent(this.get('status')) && Ember.isEqual(this.get('status'), constants.statusValue.processed)) {
        return Ember.String.htmlSafe('successColor');
      } else {
        return Ember.String.htmlSafe('errorColor');
      }
    }),
    isProcessed: Ember.computed('status', function () {
      const constants = this.get('constants');
      return Ember.isPresent(this.get('status')) && Ember.isEqual(this.get('status'), constants.statusValue.processed);
    })
  });
  _exports.default = _default;
});