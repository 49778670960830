define("esis-ui/components/shared/preview/preview-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wV94Rsv9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"previewStyle\"],[8],[1,[22,\"loadingPreviewMessage\"],false],[9],[0,\"\\n    \"],[7,\"br\",true],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"previewStyle spinningContainer\"],[8],[0,\"\\n\"],[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"waitingSpinner \",[28,\"if\",[[24,[\"large\"]],\"largeSize\",[28,\"if\",[[24,[\"medium\"]],\"mediumSize\",[28,\"if\",[[24,[\"small\"]],\"smallSize\"],null]],null]],null]]]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/shared/preview/preview-loading/template.hbs"
    }
  });
  _exports.default = _default;
});