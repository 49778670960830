define("esis-ui/helpers/display-boolean", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.displayBoolean = displayBoolean;
  function displayBoolean([value]) {
    return value ? 'Yes' : 'No';
  }
  var _default = Ember.Helper.helper(displayBoolean);
  _exports.default = _default;
});