define("esis-ui/components/areas/spg/nz/wizard/nz-spg-wizard/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "DmTpPM7Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mx-3\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"wizardContainer\"],[8],[0,\"\\n    \"],[1,[28,\"shared/ember-bootstrap/wizard-control\",null,[[\"steps\",\"model\",\"options\",\"onSubmit\",\"onSave\"],[[24,[\"steps\"]],[24,[\"model\"]],[24,[\"_options\"]],[28,\"action\",[[23,0,[]],\"submit\"],null],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[1,[28,\"areas/shared/waiting-modal\",null,[[\"registerAs\",\"renderInPlace\"],[[24,[\"_waitingModal\"]],false]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "esis-ui/components/areas/spg/nz/wizard/nz-spg-wizard/template.hbs"
    }
  });
  _exports.default = _default;
});