define("esis-ui/components/areas/shared/scroll-bottom-icon/component", ["exports", "esis-ui/components/shared/shared-component-base", "jquery"], function (_exports, _sharedComponentBase, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    tagName: '',
    actions: {
      _scrollToBottom() {
        let dynamicTab = this.get('app').getRootScreen();
        (0, _jquery.default)(`#${dynamicTab.uuid} .tab-content-container`)[0].scrollTop = 9999999999; // according to doc setting a number larger than max height will scrol to max height, so no need to calculate
      }
    }
  });
  _exports.default = _default;
});