define("esis-ui/models/search-result-detail-digest", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base"], function (_exports, _modelBase, _requestBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    esisId: (0, _modelBase.attr)(),
    searchId: (0, _modelBase.attr)(),
    isCertificateRetrieved: (0, _modelBase.attr)(),
    changeNumber: (0, _modelBase.attr)(),
    collateralClassDescription: (0, _modelBase.attr)(),
    collateralDescription: (0, _modelBase.attr)(),
    givingOfNoticeIdentifier: (0, _modelBase.attr)(),
    isMigrated: (0, _modelBase.attr)(),
    isSecurityInterestRegistrationKind: (0, _modelBase.attr)(),
    isSubordinate: (0, _modelBase.attr)(),
    isTransitional: (0, _modelBase.attr)(),
    proceedsClaimedDescription: (0, _modelBase.attr)(),
    registrationEndTime: (0, _modelBase.attr)(),
    registrationKind: (0, _modelBase.attr)(),
    registrationNumber: (0, _modelBase.attr)(),
    registrationStartTime: (0, _modelBase.attr)(),
    areAssetsSubjectToControl: (0, _modelBase.attr)(),
    areProceedsClaimed: (0, _modelBase.attr)(),
    isInventory: (0, _modelBase.attr)(),
    isPmsi: (0, _modelBase.attr)(),
    collateralTypeTitle: (0, _modelBase.attr)(),
    collateralClassTypeTitle: (0, _modelBase.attr)(),
    hasCertificateAttachment: (0, _modelBase.attr)(),
    certificateAttachments: (0, _modelBase.attr)(),
    securedParties: (0, _modelBase.attr)(),
    hasGrantors: (0, _modelBase.attr)(),
    grantors: (0, _modelBase.attr)(),
    certificateNotRequested: Ember.computed('isCertificateRetrieved', function () {
      return Ember.isNone(this.get('isCertificateRetrieved'));
    })
  });
  _exports.default = _default;
});