define("esis-ui/components/areas/registration/nz/view/nz-fs-debtor-display/component", ["exports", "esis-ui/components/shared/shared-component-base"], function (_exports, _sharedComponentBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _sharedComponentBase.default.extend({
    //region INITIALISATION
    onEditDebtor() {},
    onDeleteDebtor() {},
    //endregion

    //region COMPUTED PROPERTIES
    _debtorsLastIndex: Ember.computed('debtors.@each', function () {
      return this.get('debtors').length - 1;
    }),
    //endregion

    actions: {
      _onEditDebtor(item, index) {
        this.get('onEditDebtor')(item, index);
      },
      _onDeleteDebtor(item) {
        this.get('onDeleteDebtor')(item);
      }
    }
  });
  _exports.default = _default;
});