define("esis-ui/models/secured-party-group-nz", ["exports", "esis-ui/utils/model-base", "esis-ui/models/request-base", "lodash"], function (_exports, _modelBase, _requestBase, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _requestBase.default.extend({
    spgId: (0, _modelBase.attr)(),
    spgName: (0, _modelBase.attr)(),
    spgReference: (0, _modelBase.attr)(),
    spgVersion: (0, _modelBase.attr)(),
    eTag: (0, _modelBase.attr)(),
    esisSpgGuid: (0, _modelBase.attr)(),
    esisStatus: (0, _modelBase.attr)(),
    esisStatusMessage: (0, _modelBase.attr)(),
    esisTransactionType: (0, _modelBase.attr)(),
    esisEntityGuid: (0, _modelBase.attr)(),
    configSettings: (0, _modelBase.attr)(),
    securedParties: (0, _modelBase.attr)(null, 'array'),
    authorisedToCreate: (0, _modelBase.attr)(),
    hasSecuredParty: Ember.computed('securedParties', function () {
      return !(0, _lodash.isEmpty)(this.get('securedParties'));
    }),
    saveModel: function saveModel() {
      this.save();
      if (Ember.isPresent(this.get('configSettings'))) {
        this.get('configSettings').save();
      }
      const sp = this.get('securedParties');
      if (!(0, _lodash.isEmpty)(sp)) {
        sp.forEach(function (item) {
          item.save();
          item.contactAddress.save();
          if (item.hasMailingAddress) item.mailingAddress.save();
          if (item.isOrganisation) {
            item.personActingOnBehalf.save();
            item.personActingOnBehalf.contactAddress.save();
            if (item.personActingOnBehalf.hasMailingAddress) item.personActingOnBehalf.mailingAddress.save();
          }
        });
      }
    },
    init() {
      this._super(...arguments);
      this.validations = {
        spgName: {
          custom: {
            validation: function validation(key, value) {
              return Ember.isPresent(value);
            },
            message: 'Secured Party Group Name can not be blank'
          }
        }
      };
    }
  });
  _exports.default = _default;
});